/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';

// rct theme provider
import RctThemeProvider from './RctThemeProvider';
import RoutePaths       from "Constants/RoutePaths";
//Main App
import MainApp from 'Routes';

import {AsyncNotFoundComponent} from 'Components/AsyncComponent/AsyncComponent';
// app signin
import AppSignIn from './SignIn';
import AppSignUp from './SignUp';
import AppScanCode from './ScanCode'

import OAuth from 'OAuth';
const oauth = new OAuth();

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, ...rest, authUser }) =>
	<Route
		{...rest}
		render={props =>
			authUser
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: RoutePaths.AUTH.SIGN_IN,
						state: { from: props.location }
					}}
				/>}
	/>;

class App extends Component {
	render() {
		const { location, match, history } = this.props;
		if (location.pathname === '/') {
			if (oauth.isLoggedIn()) {
				if (oauth.obtainAccessToken()) {
					return (<Redirect to={RoutePaths.CALENDAR.WEEKLY} />)
				}
			}
			return (<Redirect to={RoutePaths.AUTH.SIGN_IN} />);
		} else if (location.pathname === RoutePaths.AUTH.SIGN_IN) {
			if (oauth.isLoggedIn()) {
				if (oauth.obtainAccessToken()) {
					return (<Redirect to={RoutePaths.CALENDAR.WEEKLY} />)
				}
			}
		} else if (location.pathname === RoutePaths.AUTH.SIGN_UP) {
			if (oauth.isLoggedIn()) {
				if (oauth.obtainAccessToken()) {
					oauth.logout();
				}
			}
		}

		return (
			<RctThemeProvider
                history={history}
            >
				<InitialPath
					path={`${match.url}app`}
					authUser={oauth.obtainAccessToken()}
					component={MainApp}
				/>
                <Switch>
                    <Route exact path={RoutePaths.AUTH.SIGN_IN} component={AppSignIn} />
                    <Route exact path="/" component={AppSignIn} />
					<Route exact path="/scan" component={AppScanCode} />

                    <Route exact path={RoutePaths.CALENDAR.WEEKLY} />
                    <Route exact path={RoutePaths.AUTH.SIGN_UP} component={AppSignUp} />
                    <Route path="*" component={AsyncNotFoundComponent} />
                </Switch>
			</RctThemeProvider>
		);
	}
}

// map state to props
const mapStateToProps = ({ authUser }) => {
	const { user } = authUser;
	return { user };
};

export default connect(mapStateToProps)(App);

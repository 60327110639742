/**
 * Direct Message Reducer
 */
import {
    DIRECT_MESSAGE_OPEN_MODAL,
    DIRECT_MESSAGE_CLOSE_MODAL,
    DIRECT_MESSAGE_OPEN_SEARCH,
    DIRECT_MESSAGE_CLOSE_SEARCH,
    GET_DIRECT_MESSAGE,
    GET_DIRECT_MESSAGE_SUCCESS,
    GET_DIRECT_MESSAGE_FAILURE,
    CREATE_DIRECT_MESSAGE,
    CREATE_DIRECT_MESSAGE_SUCCESS,
} from 'Actions/types';

// initial state
const INIT_STATE = {
    isOpenModal: false,
    isOpenSearch: false,
    messages: [],
    conversationId: undefined,
    isLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DIRECT_MESSAGE_OPEN_MODAL:
            return { ...state, isOpenModal: true };

        case DIRECT_MESSAGE_CLOSE_MODAL:
            return { ...state, isOpenModal: false };

        case DIRECT_MESSAGE_OPEN_SEARCH:
            return { ...state, isOpenSearch: true };

        case DIRECT_MESSAGE_CLOSE_SEARCH:
            return { ...state, isOpenSearch: false };

        case GET_DIRECT_MESSAGE:
            state.conversation;
            return { ...state, conversationId: action.payload.conversationId, isLoading: true};

        case GET_DIRECT_MESSAGE_SUCCESS:
            return { ...state,  messages: action.payload.response, isLoading: false};

        case GET_DIRECT_MESSAGE_FAILURE:
            return {...state, isLoading: false}

        case CREATE_DIRECT_MESSAGE:
            return {...state, isLoading: true}

        case CREATE_DIRECT_MESSAGE_SUCCESS:
            return { ...state, isLoading: false }
        default: return { ...state};
    }
};

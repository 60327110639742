/**
 * Invoice Reducer
 */
import {
    GET_INVOICE_PDF_LINK_SUCCESS,
    GET_INVOICES, GET_INVOICES_SUCCESS,
    RESET_INVOICE_POPUP,
    SEND_INVOICE_EMAILL_SUCCESS,
    SEND_INVOICE_MAIL,
    EXPORT_PDF,
    UNMOUNT_INVOICE,
    DONE_LOADING_INVOICE,
    GET_ALL_INVOICES,
} from 'Actions/types';

const INIT_STATE = {
    allInvoices: [],
    loading: true,
    fetchData: false,
    hrefInvoice: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INVOICES:
            return { ...state, payload: action.payload, loading: true, fetchData: false };
            
        case GET_INVOICES_SUCCESS:
            return { ...state, allInvoices: action.payload.data, loading: false };

        case GET_INVOICE_PDF_LINK_SUCCESS:
            return { ...state, url: action.payload.data.url, loading: false };

        case EXPORT_PDF:
            return { ...state, loading: true };
        
        case SEND_INVOICE_MAIL:
            return { ...state, loading: true };
            
        case SEND_INVOICE_EMAILL_SUCCESS:
            return { ...state, loading: false, hrefInvoice: action.payload.data };

        case RESET_INVOICE_POPUP:
            return { ...state, url: null, hrefInvoice: {}, loading: false };

        case UNMOUNT_INVOICE:
            return { ...state, allInvoices:[], loading: true};

        case DONE_LOADING_INVOICE:
            return {...state, fetchData: true };

        case GET_ALL_INVOICES:
            return { ...state, payload: action.payload, loading: true, fetchData: false };

        default: return { ...state };
    }
};

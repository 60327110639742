/**
 * Charge Rate By Pay Rate Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_CHARGERATE_BY_PAYRATE } from "Actions/types";

import {
  showErrorResponse,
  getChargeRateByPayRateSuccess,
  getChargeRateByPayRateFailure,
} from "Actions";

import { getChargeRateByPayRate } from "Services/employers/WageService";

function* getChargeRateByPayRateFromServer({ payload }) {
  try {
    const response = yield call(getChargeRateByPayRate, payload.payRate);

    yield put(getChargeRateByPayRateSuccess(response));
  } catch (error) {
    yield put(getChargeRateByPayRateFailure(error));
    yield put(showErrorResponse(error));
  }
}

export function* takeChargeRate() {
  yield takeEvery(GET_CHARGERATE_BY_PAYRATE, getChargeRateByPayRateFromServer);
}

export default function* rootSaga() {
  yield all([fork(takeChargeRate)]);
}

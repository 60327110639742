import React, { Component } from "react";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";

import TimeAgo from "Components/TimeAgo";

import { markNotificationAsRead, removeNofitication } from "Actions";

import UserDefault from "Assets/img/default-user.png";

class NotificationItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            read: false,
        };
    }

    clickToNotification(notificationObject) {
        const { id, read } = notificationObject;

        if (!read && !this.state.read) {
            this.props.markNotificationAsRead(id);
        }

        this.setState({ read: true });
        this.props.handleClicked(notificationObject);
    }

    render() {
        const { notification, markedAsRead } = this.props;
        const isRead =
            notification.read ||
            this.state.read ||
            markedAsRead.includes(notification.id);

        return (
            <li
                className={`notification-item ${isRead ? "" : "unread"}`}
                onClick={() => this.clickToNotification(notification)}
            >
                <div className="media">
                    <div className="mr-10">
                        <Avatar
                            alt="user profile"
                            src={
                                notification.senderThumbnail !== null
                                    ? notification.senderThumbnail
                                    : UserDefault
                            }
                            className="img-fluid rounded-circle align-self-center"
                        />
                    </div>

                    <div className="media-body">
                        <div className="text-black fs-10 d-block font-bold">
                            <TimeAgo
                                time={notification.createdAt}
                                formatTime={"DD MMM HH:mm "}
                            />
                        </div>

                        <span
                            className="text-muted fs-11 d-block"
                            dangerouslySetInnerHTML={{
                                __html: notification.messageHTML,
                            }}
                        />

                        <i
                            className="material-icons remove-notification"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.removeNofitication(
                                    notification.id,
                                    notification.read
                                );
                            }}
                        >
                            close
                        </i>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = ({ notificationReducer }) => notificationReducer;

export default connect(mapStateToProps, {
    markNotificationAsRead,
    removeNofitication,
})(NotificationItem);

import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import SeekerInfo from "Components/Model/SeekerInfo";
import UserDefault from "Assets/img/default-user.png";

import IntlMessages from "Util/IntlMessages";
import InfoTab from "Components/JobCard/InfoTab";

import AcoholIcon from "Assets/license/acohol-licence-yellow.png";
import DrivingIcon from "Assets/license/driving-licence-yellow.png";
import ScooterIcon from "Assets/license/scooter-licence-yellow.png";
import HGVIcon from "Assets/license/hgv-licence-yellow.png";
import DbsCheckIcon from "Assets/qualifications/dbs-check-yellow.png";

import FoodSafetyIcon from "Assets/qualifications/food-safety-yellow.png";
import ForkliftIcon from "Assets/qualifications/forklift-driver-yellow.png";
import ManualIcon from "Assets/qualifications/manual-handling-yellow.png";

class SeekerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      seekerId: null,
      currentSeekerPopupId: 0,
      open: false,
      employee: null,
    };
  }

  handleGetSeekerInfo(seeker) {
    this.setState({
      employee: seeker,
      currentSeekerPopupId: seeker.id,
      open: true,
    });
  }

  handleClickInfo(event, seekerId, seeker) {
    this.setState({
      anchorEl: event.currentTarget,
      seekerId,
      currentSeekerPopupId: seeker.employee_id,
      open: true,
    });
  }

  closePopoverOptions = () => this.setState({ anchorEl: null, seekerId: null });

  markAsAdded() {
    const { isAddTimeCard } = this.props;
    const { overlaps } = this.props.seeker;
    if (isAddTimeCard) {
      this.props.onSubmit();
      return;
    }
    if (!overlaps) this.props.onSubmitAddSeeker();
  }

  markAsNudged() {
    if (
      this.props.nudged === false && !this.props.seeker.overlaps &&
      !this.props.seeker.isConfirmed
    ) {
      this.props.onSubmit();
    }
  }

  renderButton() {
    const { isAddSeekerButton, isSeekerSignal } = this.props;
    if (isSeekerSignal) return this.renderPhoneNumber();

    if (isAddSeekerButton) return this.renderAddSeekerButton();

    return this.renderNudgeButtons();
  }

  formatPhoneNumber(phoneNumber = "") {
    if (phoneNumber !== undefined && phoneNumber !== null) {
      return phoneNumber
        .replace(/\s+/g, "")
        .replace(/(.)(\d{4})(\d{3})(\d)/, "$1$2 $3 $4");
    }
    return "";
  }

  renderPhoneNumber() {
    const { seeker } = this.props;
    return (
      <div
        style={{ color: "black" }}
        className="nudge-seeker-button gig-button bigger-font font-bold pull-right"
      >
        {this.formatPhoneNumber(seeker.phone)}
      </div>
    );
  }

  renderAddSeekerButton() {
    const { overlaps, isAdded } = this.props.seeker;
    const { added } = this.props;

    return (
      <Fragment>
        {!overlaps && !added && !isAdded
          ? (
            <Button
              className="nudge-seeker-button gig-button ok bigger-font pull-right"
              onClick={() => this.markAsAdded()}
            >
              <IntlMessages id="seekerList.buttons.addSeekerToTimeCard" />
              <Icon>chevron_right</Icon>
            </Button>
          )
          : null}

        {overlaps
          ? (
            <Button className="nudge-seeker-button gig-button red bigger-font pull-right">
              <IntlMessages id="seekerList.buttons.addUnavailable" />
            </Button>
          )
          : null}

        {(added || isAdded)
          ? (
            <Button className="nudge-seeker-button gig-button ok bigger-font pull-right">
              <Icon>check</Icon>
            </Button>
          )
          : null}
      </Fragment>
    );
  }

  renderNudgeButtons() {
    const { nudged } = this.props;
    const { overlaps, isConfirmed } = this.props.seeker;
    return (
      <Button
        className="nudge-seeker-button gig-button red bigger-font pull-right"
        onClick={() => this.markAsNudged()}
      >
        {(!nudged && !overlaps)
          ? <IntlMessages id="seekerList.buttons.nudgeSeeker" />
          : null}
        {overlaps || isConfirmed
          ? <IntlMessages id="seekerList.buttons.nudgeUnavailable" />
          : null}
        {nudged ? <Icon>check</Icon> : null}
        {(!nudged && !overlaps) ? <Icon>chevron_right</Icon> : null}
      </Button>
    );
  }

  renderYearExperience() {
    const { seeker } = this.props;

    return (
      <div className="year-experience">
        <IntlMessages
          id="common.yearExperiences"
          values={{ years: seeker.yearExperiences }}
        />
      </div>
    );
  }

  getLicences(index, licences) {
    const licenceDefault = [
      {
        id: 0,
        name: "alcohol-licence",
        icon: AcoholIcon,
      },
      {
        id: 1,
        name: "driving-licence",
        icon: DrivingIcon,
      },
      {
        id: 2,
        name: "scooter-licence",
        icon: ScooterIcon,
      },
      {
        id: 3,
        name: "hgv-licence",
        icon: HGVIcon,
      },
      {
        id: 4,
        name: "dbs-c",
      },
    ];

    if (index !== 0) {
      return licenceDefault.splice(0, index);
    }
    return licenceDefault.filter((q) => licences.includes(q.name));
  }

  closeSeekerInfoPopup = () => {
    this.setState({
      open: false,
    });
  };

  renderLicences() {
    const { seeker } = this.props;
    return (
      <div className="row ml-0 h-100 align-items-center">
        {seeker.driving_licence && seeker.driving_licence != "" &&
              seeker.driving_licence != "0" ||
            seeker.hasQualification === 3 || seeker.hasQualification === 2 ||
            seeker.hasQualification === 1
          ? (
            <img
              src={DrivingIcon}
              className="gig-icon smaller bottom no-pointer"
            />
          )
          : null}
        {seeker.alcohol_licence && seeker.alcohol_licence != "" &&
              seeker.alcohol_licence != "0" ||
            seeker.hasQualification === 3 || seeker.hasQualification === 2
          ? (
            <img
              src={AcoholIcon}
              className="gig-icon smaller bottom no-pointer"
            />
          )
          : null}
        {seeker.scooter_licence && seeker.scooter_licence != "" &&
              seeker.scooter_licence != "0" || seeker.hasQualification === 3
          ? (
            <img
              src={ScooterIcon}
              className="gig-icon smaller bottom no-pointer"
            />
          )
          : null}
        {seeker.hgv_licence && seeker.hgv_licence != "" &&
            seeker.hgv_licence != "0"
          ? (
            <img
              src={HGVIcon}
              className="gig-icon smaller bottom no-pointer ml-1"
            />
          )
          : null}
        {seeker.food_safety_allergens && seeker.food_safety_allergens != "" &&
            seeker.food_safety_allergens != "0"
          ? (
            <img
              src={FoodSafetyIcon}
              width="20em"
              className="ml-1"
            />
          )
          : null}
        {seeker.manual_handing && seeker.manual_handing != "" &&
            seeker.manual_handing != "0"
          ? (
            <img
              src={ManualIcon}
              width="11em"
              className="ml-1"
            />
          )
          : null}
        {seeker.fork_lift_truck && seeker.fork_lift_truck != "" &&
            seeker.fork_lift_truck != "0"
          ? (
            <img
              src={ForkliftIcon}
              width="25em"
              className="ml-1"
            />
          )
          : null}
        {seeker.dbs_check && seeker.dbs_check != "" && seeker.dbs_check != "0"
          ? (
            <div className="row ml-0 h-100 align-items-center">
              <img
                src={DbsCheckIcon}
                width="15em"
                className="ml-1"
              />
              {
                /* <label className="text-secondary pt-2 small"
                         style={{'color':'#c7c7c7'}}>DBS Check</label> */
              }
            </div>
          )
          : null}
      </div>
    );
  }

  render() {
    const { anchorEl, seekerId, currentSeekerPopupId, open } = this.state;
    const {
      seeker,
      added,
      isAdd,
      isAddSeekerButton,
      isNudge,
      isSeekerSignal,
    } = this.props;
    return (
      <div className="seeker-item">
        <div className="avatar">
          <Avatar
            alt="user profile"
            src={seeker.profileThumbnail || UserDefault}
            className="img-fluid rounded-circle mr-15 align-self-center"
          />
        </div>
        <div className="col-right w-100">
          <div
            className="d-flex align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div className="d-flex align-items-center">
              <div>
                <div className="seeker-name uppercase font-bolder">
                  <p className="m-0">
                    {seeker.seekerName}
                    {
                      /* <div
                                className="btn-info-seek-signal"
                                style={{ marginLeft: "5px" }}
                            > */
                    }

                    {
                      /* {seeker.seekerId === seekerId && (
                                    <InfoTab
                                        employee={seeker}
                                        showProfile={false}
                                        anchorEl={anchorEl}
                                        closePopoverOptions={
                                            this.closePopoverOptions
                                        }
                                    />
                                )} */
                    }
                    {/* </div> */}
                  </p>
                  <div>
                    <i
                      className="material-icons"
                      onClick={(event) =>
                        this.handleClickInfo(
                          event,
                          seeker.seekerId,
                          seeker,
                        )}
                    >
                      info_outline
                    </i>
                  </div>

                  {
                    /* {isAdd && (
                                        <Tooltip
                                            title={
                                                <FormattedMessage id="seekerList.buttons.employeeDetails" />
                                            }
                                            placement="bottom"
                                        >
                                            <IconButton
                                                className="toggle-detail"
                                                onClick={(e) =>
                                                    this.props.onShowSeekerTabs(
                                                        e.currentTarget,
                                                        seeker
                                                    )
                                                }
                                            >
                                                <i className="material-icons">
                                                    more_horiz
                                                </i>
                                            </IconButton>
                                        </Tooltip>
                                    )} */
                  }
                </div>

                {isNudge && (
                  <div className="seeker-detail-stats position-relative clearfix">
                    {this.renderYearExperience()}

                    {seeker.isNonEmployee === false && (
                      <div className="stat-item">
                        <i className="gig-icon icon-seeker-profile icon-non-employee" />
                        <IntlMessages id="internalEmployee.none" />
                      </div>
                    )}

                    <div className="stat-item">
                      <i className="gig-icon icon-seeker-profile icon-jobs" />
                      {seeker.totalAssignments}
                    </div>

                    <div className="stat-item">
                      <i className="gig-icon icon-seeker-profile icon-thumbs-up" />
                      {seeker.totalPositiveReviews}
                    </div>

                    {seeker.gigApproved && (
                      <div className="stat-item">
                        <i className="gig-icon icon-seeker-profile icon-gig-approved" />
                      </div>
                    )}

                    {isAddSeekerButton &&
                      seeker.hasSmartPhone && (
                      <Tooltip
                        title={
                          <FormattedMessage id="seekerList.hasSmartPhone" />
                        }
                        placement="bottom"
                      >
                        <div className="stat-item">
                          <i className="material-icons smart-phone">
                            stay_current_portrait
                          </i>
                        </div>
                      </Tooltip>
                    )}
                    <div className="stat-item">
                      <i
                        onClick={() =>
                          this.handleGetSeekerInfo(
                            seeker,
                          )}
                        className={"gig-icon info-circle icon-info-size"}
                        aria-hidden="true"
                      />
                      &nbsp;
                    </div>
                  </div>
                )}
                {this.renderLicences()}
                {isSeekerSignal && (
                  <div className="seeker-detail-stats position-relative clearfix">
                    {this.renderYearExperience()}

                    <div className="stat-item">
                      <i className="gig-icon icon-seeker-profile icon-jobs" />
                      {seeker.totalAssignments}
                    </div>

                    <div className="stat-item">
                      <i className="gig-icon icon-seeker-profile icon-thumbs-up" />
                      {seeker.totalPositiveReviews}
                    </div>

                    {seeker.gigApproved && (
                      <div className="stat-item">
                        <i className="gig-icon icon-seeker-profile icon-gig-approved" />
                        &nbsp;
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.renderButton()}
          </div>
          {isAdd
            ? (
              <div className="buttons-wrapper">
                <Tooltip
                  title={
                    <FormattedMessage
                      id={added
                        ? "seekerList.buttons.alreadyAdded"
                        : "seekerList.buttons.addSeekerToJob"}
                    />
                  }
                  placement="bottom"
                >
                  <IconButton
                    onClick={() =>
                      this.props.onSubmit(
                        seeker.seekerId,
                        seeker.seekerName,
                      )}
                  >
                    <i className="material-icons added-icon">
                      {added ? "check_circle" : "add_circle"}
                    </i>
                  </IconButton>
                </Tooltip>

                {seeker.hasSmartPhone
                  ? (
                    <Tooltip
                      title={<FormattedMessage id="seekerList.hasSmartPhone" />}
                      placement="bottom"
                    >
                      <i className="material-icons smart-phone">
                        stay_current_portrait
                      </i>
                    </Tooltip>
                  )
                  : null}
              </div>
            )
            : null}
        </div>
        <SeekerInfo
          skillId={12}
          // employee={seeker}
          currentSeekerPopupId={currentSeekerPopupId}
          open={open}
          closeSeekerInfoPopup={this.closeSeekerInfoPopup.bind(this)}
        />
      </div>
    );
  }
}

export default SeekerItem;

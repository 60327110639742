/**
 * App Routes
 */
import React, { Component } from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

// app default layout
import RctAppLayout from "Components/RctAppLayout";

// routes
import Reports from "./reports";

// async component
import {
    AsyncTimesheetInternalComponent,
    AsyncLocationComponent,
    AsyncTimesheetWeeklyComponent,
    AsyncTimesheetDailyComponent,
    AsyncTimesheetApprovedComponent,
    AsyncMyDashboardComponent,
    AsyncWeeklyCalendarComponent,
    AsyncMonthlyCalendarComponent,
    AsyncUserComponent,
    AsyncUpcomingComponents,
    AsyncPostedMyJobsComponents,
    AsyncFilledMyJobsComponents,
    AsyncPendingMyJobsComponents,
    AsyncCompletedMyJobsComponents,
    AsyncCalendarJobsComponent,
    AsyncCalendarPostedComponent,
    AsyncCalendarFilledComponent,
    AsyncCalendarPendingComponent,
    AsyncInvoiceComponent,
} from "Components/AsyncComponent/AsyncComponent";

// Services
import { logInAsRequest } from "Services/omni/EmployerService";

// Constants
import StoredData from "Constants/StoredData";

import OAuth from "OAuth";

const oauth = new OAuth();

class MainApp extends Component {
    UNSAFE_componentWillMount = async () => {
        if (
            oauth.checkCurrentIsOmniStaff() &&
            StoredData.isKeyExist(StoredData.KEYS.LOGGED_IN_AS_UNIVERSE)
        ) {
            const tempLogInAs = StoredData.get(
                StoredData.KEYS.LOGGED_IN_AS_UNIVERSE
            );
            const email = StoredData.get(StoredData.KEYS.LOGGED_IN_AS);

            if (tempLogInAs && email) {
                await logInAsRequest({ email });
                StoredData.remove(StoredData.KEYS.LOGGED_IN_AS_UNIVERSE);
            }
        }
    };

    render() {
        const { match } = this.props;
        return (
            <RctAppLayout>
                <Switch>
                    <Route
                        exact
                        path={`${match.url}/calendar/weekly`}
                        component={AsyncWeeklyCalendarComponent}
                    />
                    <Route
                        exact
                        path={`${match.url}/calendar/monthly`}
                        component={AsyncMonthlyCalendarComponent}
                    />

                    <Route
                        exact
                        path={`${match.url}/my-gigs/upcoming`}
                        component={AsyncUpcomingComponents}
                    />


                    <Route
                        exact
                        path={`${match.url}/my-gigs/pending`}
                        component={AsyncPendingMyJobsComponents}
                    />
                    <Route
                        exact
                        path={`${match.url}/my-gigs/calendar-pending`}
                        component={AsyncCalendarPendingComponent}
                    />

                    <Route
                        exact
                        path={`${match.url}/my-gigs/completed`}
                        component={AsyncCompletedMyJobsComponents}
                    />

                    <Route
                        exact
                        path={`${match.url}/my-gigs/posted/:positionId([0-9]*)`}
                        component={AsyncPostedMyJobsComponents}
                    />
                    <Route
                        exact
                        path={`${match.url}/my-gigs/filled/:positionId([0-9]*)`}
                        component={AsyncFilledMyJobsComponents}
                    />
                    <Route
                        exact
                        path={`${match.url}/my-gigs/pending/:positionId([0-9]*)`}
                        component={AsyncPendingMyJobsComponents}
                    />
                    <Route
                        exact
                        path={`${match.url}/my-gigs/completed/:positionId([0-9]*)`}
                        component={AsyncCompletedMyJobsComponents}
                    />

                    <Route
                        exact
                        path={`${match.url}/locations`}
                        component={AsyncLocationComponent}
                    />
                    <Route
                        exact
                        path={`${match.url}/reports`}
                        component={Reports}
                    />
                    <Route
                        exact
                        path={`${match.url}/timesheets/pending/weekly`}
                        component={AsyncTimesheetWeeklyComponent}
                    />
                     <Route
                        exact
                        path={`${match.url}/timesheets/pending/internal`}
                        component={AsyncTimesheetInternalComponent}
                    />
                    <Route
                        exact
                        path={`${match.url}/timesheets/pending/daily`}
                        component={AsyncTimesheetDailyComponent}
                    />
                    <Route
                        exact
                        path={`${match.url}/timesheets/approved`}
                        component={AsyncTimesheetApprovedComponent}
                    />
                    {/* <Route
                        exact
                        path={`${match.url}/billing/invoicing`}
                        component={AsyncInvoiceComponent}
                    /> */}

                    <Route
                        exact
                        path={`${match.url}/users`}
                        component={AsyncUserComponent}
                    />
                    <Redirect from="*" to={"/404-page"} />
                </Switch>
            </RctAppLayout>
        );
    }
}

export default withRouter(connect(null)(MainApp));

/**
 * Vacancy Location Reducer
 */
import {
    GET_VACANCY_LOCATIONS,
    GET_VACANCY_LOCATIONS_SUCCESS,
    GET_VACANCY_LOCATIONS_FAILURE,
} from 'Actions/types';

// initial state
const INIT_STATE = {
    vacancyLocations: [],
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VACANCY_LOCATIONS:
            return { ...state };

        case GET_VACANCY_LOCATIONS_SUCCESS:
            return { ...state, vacancyLocations: action.payload.data.data };

        case GET_VACANCY_LOCATIONS_FAILURE:
            return { ...state, error: action.payload.response.data };

        default: return { ...state };
    }
};

/**
 * Auth Sagas
 */
import {
    all,
    call,
    fork,
    put,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import OAuth from "OAuth";
import { isExpectedError, isSuccess } from "Helpers/ResponseStatus";
import { isUnacceptTermEmployer } from "Helpers/helpers";

import {
    CHECK_EMAIL_VERIFIED,
    LOGIN_USER,
    LOGOUT_USER,
    SIGN_UP_USER,
    GET_EMPLOYER_DETAIL,
    POST_REAUTHORISE,
    GET_SHIFT_OPTIONS,
    CHECK_ACCESS_CODE,
    CHECK_EMPLOYER_EMAIL_EXIST,
    STORE_DATA_PROTECTION,
} from "Actions/types";

import {
    postReauthoriseSuccess,
    postReauthoriseFailure,
    showErrorResponse,
    signinUserSuccess,
    signinUserFailure,
    signoutUserSuccess,
    signoutUserFailure,
    checkEmailVerifiedSuccess,
    checkEmailVerifiedFailure,
    getEmployerDetailSuccess,
    getEmployerDetailFailure,
    removeReducer,
    signUpUserFailure,
    signUpUserSuccess,
    checkEmployerEmailExistSuccess,
    checkEmployerEmailExistFail,
    getShiftOptionsSuccess,
    checkAccessCodeValid,
    checkAccessCodeInvalid,
    showFormGPDR,
    hideFormGPDR,
    storeDataProtectionSuccess,
    storeDataProtectionFail,
} from "Actions";

import {
    signUpUserRequest,
    logInRequest,
    logOutRequest,
    getEmployerDetailsRequest,
    checkEmployerEmailVerifiedRequest,
    verifyEmail,
    refreshTokenGrantRequest,
    getShiftOptionsRequest,
} from "Services/auth/AuthenticateService";

import { userExistRequest } from "Services/employers/UserService";
import { checkAccessCodeRequest } from "Services/employers/AccessCodeService";
import { storeDataProtectionRequest } from "Services/employers/EmployerService";

const oauth = new OAuth();

/**
 * hanlde store data proetction employer
 * @param {} dataProtection
 */
function* hanldeStoreDataProtection({ payload }) {
    try {
        const response = yield call(storeDataProtectionRequest, payload);
        if (isSuccess(response)) {
            yield put(storeDataProtectionSuccess());
        }
    } catch (error) {
        yield put(storeDataProtectionFail(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Call reauthenrise API
 */
function* handlePostAuthenrise({ payload }) {
    try {
        const refreshToken = payload;
        const response = yield call(refreshTokenGrantRequest, {
            refresh_token: refreshToken,
        });
        if (isSuccess(response)) {
            oauth.storeLocalStorageUserDetail(response.data);
            yield put(postReauthoriseSuccess());
        }
    } catch (error) {
        yield put(postReauthoriseFailure());
    }
}
/*
 * call check email existed in database
 */
function* handleCheckEmailExist({ payload }) {
    const { email } = payload;
    try {
        const response = yield call(userExistRequest, { email });
        if (isSuccess(response)) {
            const { exists } = response.data.data;
            if (!exists) yield put(checkEmployerEmailExistSuccess());
            else yield put(checkEmployerEmailExistFail());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Call Check Email Verified API
 */
function* handleCheckEmailVefiried({ payload }) {
    const { email, successCallback, errorCallback } = payload;
    try {
        const response = yield call(checkEmployerEmailVerifiedRequest, {
            email: email,
        });
        const isVerified = response.data.data;

        if (isVerified) {
            yield put(checkEmailVerifiedSuccess(isVerified));
            successCallback();
        } else {
            yield put(checkEmailVerifiedFailure(isVerified));
            errorCallback();
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Call Sign In API
 */
function* handleSignInUser({ payload }) {
    const { successCallback, errorCallback } = payload;
    const { email, password } = payload.user;
    try {
        const response = yield call(logInRequest, {
            username: email,
            password: password,
        });
        const isAuthenticated = !isExpectedError(response);

        if (isAuthenticated) {
            const storeData = response.data.data;
            storeData.email = email;
            oauth.storeLocalStorageAuthenticate(storeData);
            yield put(signinUserSuccess(response));
            successCallback();
        } else {
            yield put(signinUserFailure(isAuthenticated));
            errorCallback();
        }
    } catch (error) {
        yield put(signinUserFailure(error.response));
        errorCallback();
    }
}

/**
 * Call Get Employer Detail API
 */
function* handleGetEmployerDetail({ payload }) {
    try {
        const history = payload.history;
        const response = yield call(getEmployerDetailsRequest);

        if (isSuccess(response)) {
            oauth.storeLocalStorageUserDetail(response.data);
            yield put(getEmployerDetailSuccess(response.data.data));

            if (isUnacceptTermEmployer(response.data)) {
                history.push("/app/calendar/weekly");
                yield put(showFormGPDR());
            } else {
                yield put(hideFormGPDR());
                history.push("/app/calendar/weekly");
            }
        } else {
            yield put(getEmployerDetailFailure(response));
            history.push("/app/calendar/weekly");
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Call Sign Out API
 */
function* handleSignOut({ payload }) {
    try {
        const redirectTo = payload.redirectTo;
        const response = yield call(logOutRequest, { device_type: "desktop" });

        if (isSuccess(response)) {
            oauth.logout();
            yield put(signoutUserSuccess());
            yield put(removeReducer());
            redirectTo.push("/signin");
        } else {
            yield put(signoutUserFailure());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Call Sign Up API
 */
function* handleSignUp(payload) {
    try {
        const { user } = payload.payload;
        const response = yield call(signUpUserRequest, user);
        if (isSuccess(response)) {
            const response = yield call(logInRequest, {
                username: user.email,
                password: user.password,
            });
            const isAuthenticated = !isExpectedError(response);

            if (isAuthenticated) {
                const storeData = response.data.data;
                storeData.email = user.email;
                oauth.storeLocalStorageAuthenticate(storeData);
                const responseVerifyEmail = yield call(verifyEmail);

                if (isSuccess(responseVerifyEmail)) {
                    yield put(signUpUserSuccess(responseVerifyEmail));
                    oauth.logout();
                }
            }
        } else {
            const responseFailure =
                typeof response.response !== "undefined"
                    ? response.response
                    : response;
            yield put(signUpUserFailure(responseFailure));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleGetShiftOption() {
    try {
        const response = yield call(getShiftOptionsRequest);
        if (isSuccess(response)) {
            yield put(getShiftOptionsSuccess(response.data.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleCheckAccessCode({ payload }) {
    try {
        const { code, gigPremium } = payload;
        const response = yield call(checkAccessCodeRequest, code, gigPremium);

        if (isSuccess(response)) {
            const { data } = response.data;

            if (data.valid) {
                yield put(checkAccessCodeValid());
            } else {
                yield put(checkAccessCodeInvalid());
            }
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * store data protection
 */
export function* watchStoreDataProtection() {
    yield takeEvery(STORE_DATA_PROTECTION, hanldeStoreDataProtection);
}

/**
 * Reauthorise
 */
export function* watchPostReauthorise() {
    yield takeEvery(POST_REAUTHORISE, handlePostAuthenrise);
}

/**
 * check email
 */
export function* watchCheckEmailExist() {
    yield takeLatest(CHECK_EMPLOYER_EMAIL_EXIST, handleCheckEmailExist);
}

/**
 * Sign In
 */
export function* watchSignInUser() {
    yield takeEvery(LOGIN_USER, handleSignInUser);
}

/**
 * Get Employer Detail
 */
export function* watchGetEmployerDetail() {
    yield takeEvery(GET_EMPLOYER_DETAIL, handleGetEmployerDetail);
}

/**
 * Sign Out
 */
export function* watchSignOutUser() {
    yield takeEvery(LOGOUT_USER, handleSignOut);
}

/**
 * Sign Up
 */
export function* watchSignUpUser() {
    yield takeEvery(SIGN_UP_USER, handleSignUp);
}

/**
 * Check Employer Verifired
 */
export function* watchCheckEmailVerified() {
    yield takeEvery(CHECK_EMAIL_VERIFIED, handleCheckEmailVefiried);
}

export function* watchGetShiftOption() {
    yield takeEvery(GET_SHIFT_OPTIONS, handleGetShiftOption);
}

export function* watchCheckAccessCode() {
    yield takeEvery(CHECK_ACCESS_CODE, handleCheckAccessCode);
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchSignInUser),
        fork(watchSignUpUser),
        fork(watchSignOutUser),
        fork(watchCheckEmailVerified),
        fork(watchGetEmployerDetail),
        fork(watchPostReauthorise),
        fork(watchCheckEmailExist),
        fork(watchGetShiftOption),
        fork(watchCheckAccessCode),
        fork(watchStoreDataProtection),
    ]);
}

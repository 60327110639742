import API from 'Api';

export const getInternalEmployeeListRequest = async () =>
    await API.get('/employers/internal-employee')
        .then(response => response)
        .catch(error => error);

export const checkInternalEmployeeExistenceRequest = async email =>
    await API.post('/employees/exists', { email })
        .then(response => response)
        .catch(error => error);

export const addInternalEmployeeRequest = async internalEmployeeParam =>
    await API.post('/employers/internal-employee', internalEmployeeParam)
        .then(response => response)
        .catch(error => error);

export const updateInternalEmployeeRequest = async params => 
    await API.put(`/employers/internal-employee/${params.internalEmployeeId}`, params)
        .then(response => response)
        .catch(error => error);

export const deleteInternalEmployeeRequest = async internalEmployeeId =>
    await API.remove(`/employers/internal-employee/${internalEmployeeId}`)
        .then(response => response)
        .catch(error => error);
        
export const csvImportInternalEmployeesRequest = async data =>
    await API.post('/employers/internal-employee/importEmployeesByCSV', data)
        .then(response => response)
        .catch(error => error);

/**
 * Omni Employer Actions
 */

import {
    GET_EMPLOYER_LIST,
    GET_EMPLOYER_LIST_SUCCESS,
    GET_EMPLOYER_LIST_FAILURE,
    LOGIN_AS,
    LOGIN_AS_SUCCESS,
    LOGIN_AS_FAILURE
} from './types';

/**
 * Action Get Employers List
 */
export const getEmployerList = () => ({
    type: GET_EMPLOYER_LIST
});

/**
 * Action Get Employers List Success
 */
export const getEmployerListSuccess = (response) => ({
    type: GET_EMPLOYER_LIST_SUCCESS,
    payload: response
});

/**
 * Action Get Employers List Failure
 */
export const getEmployerListFailure = (error) => ({
    type: GET_EMPLOYER_LIST_FAILURE,
    payload: error
});

/**
 * Action Login As
 */
export const loginAs = (email, history) => ({
    type: LOGIN_AS,
    payload: { email, history }
});

/**
 * Action Login As Success
 */
export const loginAsSuccess = (response) => ({
    type: LOGIN_AS_SUCCESS,
    payload: response
});

/**
 * Action Login As Failure
 */
export const loginAsFailure = (error) => ({
    type: LOGIN_AS_FAILURE,
    payload: error
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

import KeyCodes from 'Constants/KeyCodes';
import SeekerItem from 'Components/Model/DirectMessage/SearchSeeker/SeekerItem';

import {
    searchSeekers,
    openSearchSeeker,
    closeSearchSeeker
} from 'Actions';

class SearchSeeker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            activeFormID: null,
            validationError: false
        };
    }

    UNSAFE_componentWillReceiveProps() {
        const  { resetForm } = this.props;
        if (resetForm) {
            this.setState({activeFormID: 0});
        }
    }

    handleSearchInput = (event) => {
        const key = event.which || event.keyCode;

        if (this.state.validationError) {
            this.setState({ validationError: false });
        }

        if (key === KeyCodes.ENTER) {
            if (this.state.searchText.length < 3) {
                this.showValidationError('createTimecard.validate.searchMinLength');
            } else {
                this.props.openSearchSeeker();
                this.props.searchSeekers(this.state.searchText, 0);
            }
        }
    }

    setActiveForm = (seekerId) => {
        this.setState({ activeFormID: seekerId });
    }

    showValidationError(validationError) {
        this.setState({ validationError });
    }

    renderSearchForm() {
        const { searchText, validationError } = this.state;
        const { onLoading } = this.props;

        return (
            <div className="row">
                <FormGroup className="col-sm-5 col-xs-12">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <i className="material-icons">search</i>
                        </InputGroupAddon>

                        <FormattedMessage id="directMessage.placeholder.search">
                            {(placeholder) => (
                                <Input
                                    placeholder={placeholder}
                                    value={searchText}
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    onKeyPress={this.handleSearchInput}
                                />
                            )}
                        </FormattedMessage>
                    </InputGroup>

                    {onLoading && (
                        <CircularProgress className="progress-warning spinner-loading" size={28} mode="determinate" />
                    )}

                    {validationError !== false && (
                        <FormHelperText className="field-error">
                            <FormattedMessage id={validationError} />
                        </FormHelperText>
                    )}
                </FormGroup>

                <FormGroup className="col-sm-7 col-xs-12">
                    {this.props.open && (
                        <div className="btn-back-conversation" onClick={() => this.props.closeSearchSeeker()}>
                            <i className="zmdi zmdi-chevron-left" />
                            <FormattedMessage id="directMessage.button.back" />
                        </div>
                    )}
                </FormGroup>
            </div>
        );
    }

    renderListSeekers(listSeekers) {
        const { activeFormID } = this.state;

        return (
            <div className="searched-seekers">
                {listSeekers.map((seeker) =>
                    <SeekerItem
                        key={seeker.id}
                        seeker={seeker}
                        onSelect={this.setActiveForm}
                        showForm={activeFormID === seeker.id}
                    />
                )}
            </div>
        );
    }

    render() {
        const { listSeekers, onLoading, open } = this.props;

        return (
            <div className="search-form">
                {this.renderSearchForm()}

                {listSeekers.length > 0 && !onLoading && open && this.renderListSeekers(listSeekers)}
            </div>
        );
    }
};

const mapStateToProps = ({ searchSeekerReducer, conversationReducer }) => ({
    ...searchSeekerReducer,
    ...conversationReducer
});

const mapActionToProps = {
    searchSeekers,
    openSearchSeeker,
    closeSearchSeeker
};

export default connect(mapStateToProps, mapActionToProps)(SearchSeeker);

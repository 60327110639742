import {
  GET_LIST_PROVIDER_REQUEST, GET_LIST_PROVIDER_SUCCESS, GET_LIST_PROVIDER_FAILURE
} from './types';

export const getListProvider = (params) => ({
  type: GET_LIST_PROVIDER_REQUEST,
  payload: params
});

export const getListProviderSuccess = (listPrivider) => ({
  type: GET_LIST_PROVIDER_SUCCESS,
  payload: listPrivider,
});

export const getListProviderFailure = (error) => ({
  type: GET_LIST_PROVIDER_FAILURE,
  payload: error
});
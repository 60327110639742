import moment from 'moment';

const ScheduleHelper = {};

ScheduleHelper.removePastSchedules = function (schedules) {
    const unixMidnightToday = moment().startOf('day').unix();

    return schedules.filter(schedule => schedule.end > unixMidnightToday);
};

ScheduleHelper.removePastSchedulesMoment = function (schedules) {
    const unixMidnightToday = moment().unix();

    return schedules.filter(schedule => schedule.start > unixMidnightToday);
};

export default ScheduleHelper;

/**
 * Jobs App Actions
 */
import {
    OPEN_CREATE_JOB_MODAL,
    CLOSE_CREATE_JOB_MODAL,
    OPEN_EDIT_JOB,
    CLOSE_EDIT_JOB,
    OPEN_REPOST_JOB,
    CLOSE_REPOST_JOB,
    GET_UPCOMING_JOBS,
    GET_UPCOMING_JOBS_SUCCESS,
    GET_UPCOMING_JOBS_FAILURE,
    UPDATE_UPCOMING_JOBS,
    GET_POSTED_JOBS,
    GET_POSTED_JOBS_SUCCESS,
    GET_POSTED_JOBS_FAILURE,
    GET_FILLED_JOBS,
    GET_FILLED_JOBS_SUCCESS,
    GET_FILLED_JOBS_FAILURE,
    GET_COMPLETED_JOBS,
    GET_COMPLETED_JOBS_SUCCUESS,
    GET_COMPLETED_JOBS_FAILURE,
    GET_PENDING_JOBS,
    GET_PENDING_JOBS_SUCCESS,
    GET_PENDING_JOBS_FAILURE,
    GET_JOB_INFORMATION_BY_ID,
    GET_JOB_INFORMATION_BY_ID_SUCCESS,
    GET_JOB_INFORMATION_BY_ID_FAILURE,
    CLOSE_JOB_INFORMATION,
    GET_JOB_BY_ID,
    GET_JOB_BY_ID_SUCCESS,
    GET_JOB_BY_ID_FAILURE,
    GET_JOB_MORE_INFO,
    GET_JOB_MORE_INFO_SUCCESS,
    GET_JOB_MORE_INFO_FAILURE,
    GET_ALL_JOBS,
    GET_ALL_JOBS_SUCCESS,
    GET_NUDGABLE_SEEKER_FOR_POSITION,
    GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS,
    GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE,
    CANCEL_JOB,
    CANCEL_JOB_SUCCESS,
    CANCEL_JOB_FAILURE,
    GET_JOB_CANDIDATES,
    GET_JOB_CANDIDATES_SUCCESS,
    GET_JOB_CANDIDATES_FAILURE,
    GET_EMPLOYEE,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAILURE,
    GET_CALENDAR_JOBS,
    GET_CALENDAR_JOBS_SUCCESS,
    GET_CALENDAR_JOBS_FAILURE,
    SEARCH_JOB,
    SET_JOB_FORM,
    UPDATE_PROMOTED_STATUS,
    UPDATE_PROMOTED_STATUS_SUCCESS,
    GET_EMPLOYEE_OF_JOB,
    GET_EMPLOYEE_OF_JOB_SUCCESS,
    GET_EMPLOYEE_OF_JOB_FAILURE,
    GET_DOCUMENT_OF_EMPLOYEE,
    GET_DOCUMENT_OF_EMPLOYEE_SUCCESS,
    GET_DOCUMENT_OF_EMPLOYEE_FAILURE,
    CHANGE_DOCUMENT_LOADING,
    GET_INIT_JOB,
    REMOVE_JOB_FROM_LIST,
    TOGGLE_HIGHLIGHT_JOB,
    ADD_CALENDAR_JOBS_SUCCESS,
    UPDATE_JOB_CALENDAR_AFTER_EDIT_JOB,
    CLOSE_ALL_JOB_MODAL,
} from "./types";

import MyGig from "Constants/MyGig";
import { currentPage } from "Helpers/RouterChecker";

/**
 * Acton set field form to job
 * @param {*} field
 * @param {*} value
 */
export const setJobForm = (field, value) => ({
    type: SET_JOB_FORM,
    payload: {
        field,
        value,
    },
});

/**
 * Action search job base on current gig page
 */
export const searchJob = (keyword, currentPage) => ({
    type: SEARCH_JOB,
    payload: {
        keyword,
        currentPage,
    },
});
/*
 * Action open create job form
 */
export const openCreateJobModal = () => ({
    type: OPEN_CREATE_JOB_MODAL,
});

export const closeCreateJobModal = () => ({
    type: CLOSE_CREATE_JOB_MODAL,
});

/*
Action open edit job form
*/
export const openEditJob = () => ({
    type: OPEN_EDIT_JOB,
});

export const closeEditJob = () => ({
    type: CLOSE_EDIT_JOB,
});

/*
Action open repost job form
*/
export const openRepostJob = () => ({
    type: OPEN_REPOST_JOB,
});

export const closeRepostJob = () => ({
    type: CLOSE_REPOST_JOB,
});

/**
 * Action Upcoming Jobs
 */
export const getUpcomingJobs = function (
    locationId,
    page = 1,
    jobType = "allUpcoming",
    start,
    end,
) {
    let payload = {
        paged: page,
        jobType: jobType,
    };

    if (locationId) {
        payload.locationUpcom = locationId;
    }

    if (start) {
        payload.start = start;
        payload.end = end;
    }

    return {
        type: GET_UPCOMING_JOBS,
        payload,
    };
};

export const getUpcomingJobsSuccess = (response) => ({
    type: GET_UPCOMING_JOBS_SUCCESS,
    payload: response,
});

export const getUpcomingJobsFailure = (error) => ({
    type: GET_UPCOMING_JOBS_FAILURE,
    payload: error,
});

export const updateUpcomingJobs = (position) => ({
    type: UPDATE_UPCOMING_JOBS,
    payload: position,
});

export const toggleHighlightJob = (
    positionId = undefined,
    isHighlight = false,
) => ({
    type: TOGGLE_HIGHLIGHT_JOB,
    payload: { positionId, isHighlight },
});

export const removeJobFromList = (positionId, currentPage) => ({
    type: REMOVE_JOB_FROM_LIST,
    payload: { positionId, currentPage },
});
/**
 * Action Posted Jobs
 */
export const getPostedJobs = (page, startDate, endDate, locationId) => ({
    type: GET_POSTED_JOBS,
    payload: { page, startDate, endDate, locationId },
});

export const getPostedJobsSuccess = (response) => ({
    type: GET_POSTED_JOBS_SUCCESS,
    payload: response,
});

export const getPostedJobsFailure = (error) => ({
    type: GET_POSTED_JOBS_FAILURE,
    payload: error,
});

/**
 * Action Filled Jobs
 */
export const getFilledJobs = (page, startDate, endDate, locationId) => ({
    type: GET_FILLED_JOBS,
    payload: { page, startDate, endDate, locationId },
});

export const getFilledJobsSuccess = (response) => ({
    type: GET_FILLED_JOBS_SUCCESS,
    payload: response,
});

export const getFilledJobsFailure = (error) => ({
    type: GET_FILLED_JOBS_FAILURE,
    payload: error,
});

/**
 * Action Completed Jobs
 */
export const getCompletedJobs = (locationId, page = 1, startDate, endDate) => ({
    type: GET_COMPLETED_JOBS,
    payload: { locationId, page, startDate, endDate },
});

export const getCompletedJobsSuccess = (response) => ({
    type: GET_COMPLETED_JOBS_SUCCUESS,
    payload: response,
});

export const getCompletedJobsFailure = (error) => ({
    type: GET_COMPLETED_JOBS_FAILURE,
    payload: error,
});

/**
 * Action Pending Jobs
 */
export const getPendingJobs = (locationId, page = 1, startDate, endDate) => ({
    type: GET_PENDING_JOBS,
    payload: { locationId, page, startDate, endDate },
});

export const getPendingJobsSuccess = (response) => ({
    type: GET_PENDING_JOBS_SUCCESS,
    payload: response,
});

export const getPendingJobsFailure = (error) => ({
    type: GET_PENDING_JOBS_FAILURE,
    payload: error,
});

/**
 * Action Get All Jobs
 */
export const getAllJobs = (
    status = null,
    start = null,
    end = null,
    location = null,
    paged = 1,
) => ({
    type: GET_ALL_JOBS,
    payload: { status, start, end, location, paged },
});

export const getAllJobsSuccess = (response) => ({
    type: GET_ALL_JOBS_SUCCESS,
    payload: response,
});

/**
 * Action Job By Id
 */
export const getJobInformationById = (positionId) => ({
    type: GET_JOB_INFORMATION_BY_ID,
    payload: { positionId },
});

export const getJobInformationByIdSuccess = (response) => ({
    type: GET_JOB_INFORMATION_BY_ID_SUCCESS,
    payload: { response },
});

export const getJobInformationByIdFailure = (error) => ({
    type: GET_JOB_INFORMATION_BY_ID_FAILURE,
    payload: { error },
});

export const closeJobInformation = () => ({
    type: CLOSE_JOB_INFORMATION,
});

export const getJobById = (positionId) => ({
    type: GET_JOB_BY_ID,
    payload: { positionId },
});

export const getJobByIdSuccess = (response) => ({
    type: GET_JOB_BY_ID_SUCCESS,
    payload: response,
});

export const getJobByIdFailure = (error) => ({
    type: GET_JOB_BY_ID_FAILURE,
    payload: error,
});

export const getJobMoreInfo = (positionId) => ({
    type: GET_JOB_MORE_INFO,
    payload: { positionId },
});

export const getJobMoreInfoSuccess = (response) => ({
    type: GET_JOB_MORE_INFO_SUCCESS,
    payload: { response },
});

export const getJobMoreInfoFailure = (error) => ({
    type: GET_JOB_MORE_INFO,
    payload: { error },
});

/**
 * Action Get nudgable seeker for job
 */
export const getNudgableSeekerForPosition = (positionId) => ({
    type: GET_NUDGABLE_SEEKER_FOR_POSITION,
    positionId,
});

export const getNudgableSeekerForPositionSuccess = (response) => ({
    type: GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS,
    payload: response,
});

export const getNudgableSeekerForPositionFailure = (error) => ({
    type: GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE,
    payload: error,
});

/**
 * Cancel job
 */
export const cancelJob = (job) => ({
    type: CANCEL_JOB,
    job,
});

export const cancelJobSuccess = (response) => ({
    type: CANCEL_JOB_SUCCESS,
    payload: response,
});

export const cancelJobFailure = (error) => ({
    type: CANCEL_JOB_FAILURE,
    payload: error,
});

/**
 * Get Job Candidates
 */
export const getJobCandidates = (payload) => ({
    type: GET_JOB_CANDIDATES,
    jobId: payload,
});

export const getJobCandidatesSuccess = (response) => ({
    type: GET_JOB_CANDIDATES_SUCCESS,
    payload: response,
});

export const getJobCandidatesFailure = (error) => ({
    type: GET_JOB_CANDIDATES_FAILURE,
    payload: error,
});

/**
 * Get job candidates
 */
export const getEmployee = (payload) => ({
    type: GET_EMPLOYEE,
    employeeId: payload,
});

export const getEmployeeSuccess = (response) => ({
    type: GET_EMPLOYEE_SUCCESS,
    payload: response,
});

export const getEmployeeFailure = (error) => ({
    type: GET_EMPLOYEE_FAILURE,
    payload: error,
});

/**
 * Action Calendar Jobs
 */
export const getCalendarJobs = (startDate, endDate, locationId) => ({
    type: GET_CALENDAR_JOBS,
    payload: { startDate, endDate, locationId },
});

export const getCalendarJobsSuccess = (response) => ({
    type: GET_CALENDAR_JOBS_SUCCESS,
    payload: response,
});

export const addJobCalendarAfterReposted = (response) => ({
    type: ADD_CALENDAR_JOBS_SUCCESS,
    payload: response,
});

export const getCalendarJobsFailure = (error) => ({
    type: GET_CALENDAR_JOBS_FAILURE,
    payload: error,
});

/**
 * Actions for promote/unpromote job
 */
export const doPromoteJob = (positionId) => ({
    type: UPDATE_PROMOTED_STATUS,
    payload: { positionId, status: MyGig.status.POSITION_PROMOTED },
});

export const doUnpromoteJob = (positionId) => ({
    type: UPDATE_PROMOTED_STATUS,
    payload: { positionId, status: MyGig.status.POSITION_UNPROMOTED },
});

export const updatePromotedStatusSuccess = (positionId, status) => ({
    type: UPDATE_PROMOTED_STATUS_SUCCESS,
    payload: { positionId, status },
});

export const getJobOfEmployee = (employeeId, params) => ({
    type: GET_EMPLOYEE_OF_JOB,
    payload: { employeeId, params },
});

export const getJobOfEmployeeSuccess = (data) => ({
    type: GET_EMPLOYEE_OF_JOB_SUCCESS,
    payload: { data },
});

export const getJobOfEmployeeFailure = (data) => ({
    type: GET_EMPLOYEE_OF_JOB_FAILURE,
    payload: { data },
});

export const getDocumentOfEmployee = (omniEmployeeId) => ({
    type: GET_DOCUMENT_OF_EMPLOYEE,
    payload: { omniEmployeeId },
});

export const getDocumentOfEmployeeSuccess = (data) => ({
    type: GET_DOCUMENT_OF_EMPLOYEE_SUCCESS,
    payload: { data },
});

export const getDocumentOfEmployeeFailure = (data) => ({
    type: GET_DOCUMENT_OF_EMPLOYEE_FAILURE,
    payload: { data },
});

export const changeDocumentLoading = () => ({
    type: CHANGE_DOCUMENT_LOADING,
});

export const getInitJob = () => ({
    type: GET_INIT_JOB,
});

export const updateJobCalendarAfterEdit = (positiondata) => ({
    type: UPDATE_JOB_CALENDAR_AFTER_EDIT_JOB,
    payload: positiondata,
});

export const closeAllJobModal = () => ({
    type: CLOSE_ALL_JOB_MODAL,
});

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";

import { Input, InputGroup, InputGroupAddon } from "reactstrap";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MessageBlock from "./MessageBlock";
import KeyCodes from "Constants/KeyCodes";
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
class DetailConversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageText: "",
            selectedFile: null
        };
        this.fileInput = null
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ messageText: "" });
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    onFileChange(event) {
        if (event.target.files[0]) {
            this.setState({ selectedFile: event.target.files[0] })
        }
    }

    
    scrollToBottom = () => {
        if (!this.props.ignoreAutoToBottom) {
            this.dummyMessage.scrollIntoView({ behavior: "instant" });
        }
    };

    handleKeyPress(e) {
        const key = e.which || e.keyCode;

        if (key === KeyCodes.ENTER) {
            this.saveMessage();
        }
    }

    handleScrollConversation(e) {
        // Scroll to top of list messages
        if (typeof e.originalTarget !== "undefined") {
            if (e.originalTarget.scrollTop === 0) {
                this.props.onScrollLoadmore();
            }
        }
    }

    async saveMessage() {
        // const messageText = this.fileInput.value
        const { messageText, selectedFile } = this.state
        const { positionId } = this.props
        const sendFile = {
            name: '',
            file: ''
        }
        if (selectedFile) {
            sendFile.name = selectedFile.name
            const type = selectedFile.name.split('.')
            const typeFile = type.length > 1 ? type[1] : ""
            const uploadData = {
                key:  `messages/notes-${positionId}-${Number(new Date())}.${typeFile}`,  // path file
                fileContent: selectedFile //file
            }
            const result = await this.props.onUploadFile(uploadData)
            if (result) {
                sendFile.file = result.Location
            }
        }
        const formatedParams = {
            note_text:  messageText,
            type_message: selectedFile ? 1 : 0,
            name_file: sendFile.name,
            file: sendFile.file
        }
        if (messageText.trim() !== "" || selectedFile) {
           this.props.saveMessage(formatedParams);
           this.setState({ selectedFile: null })
        }
    }

    render() {
        const { messages, onLoading, onSaving } = this.props;
        const { messageText, selectedFile } = this.state;

        return (
            <div className="chat-wrapper">
                <div className="chat-main-body">
                    <Scrollbars
                        className="rct-scroll"
                        // autoHide
                        onScroll={(e) => this.handleScrollConversation(e)}
                        style={{ height: "calc(100vh - 148px)" }}
                    >
                        <div className="chat-body">
                            {onLoading && (
                                <div className="center vertical-space-smaller">
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                    />
                                </div>
                            )}
                           
                                {!onLoading && messages.length &&
                                    messages.map((message) => (
                                    <div>
                                        <MessageBlock
                                            isEmployer={ message.author_employee_id === null }
                                            key={`message-block-${message.note_id}`}
                                            messageObject={message}
                                        />
                                     </div>
                                    ))}
                            <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                    this.dummyMessage = el;
                                }}
                            />
                            {!onLoading && (
                                <div className="chat-input-wrapper">
                                    <FormattedMessage id="messages.inputMessage.placeholder">
                                        {(placeholder) => (
                                            <Input
                                                autoFocus
                                                type="textarea"
                                                placeholder={placeholder}
                                                disabled={onLoading || onSaving}
                                                value={messageText}
                                                onKeyPress={(e) =>
                                                    this.handleKeyPress(e)
                                                }
                                                onChange={(e) =>
                                                    this.setState({
                                                        messageText:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FormattedMessage>
                                    <p className="my-2">{selectedFile ? selectedFile.name : ''}</p>

                                    <div className="d-flex mt-3">
                                        <button className="mx-2 send-file">
                                            <label className="send-file-label">
                                                <input
                                                    ref={(ref) => this.fileInput = ref}
                                                    onChange={(e) => this.onFileChange(e)}
                                                    className="input-file"
                                                    type="file"
                                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,video/*"
                                                />
                                                <AttachFileIcon className="text-light icon-attach-file" />
                                            </label>
                                        </button> 

                                        <IconButton
                                            className="btn-warning send-message"
                                            disabled={onSaving}
                                            onClick={() => this.saveMessage()}
                                        >
                                            {onSaving ? (
                                                <CircularProgress
                                                    className="progress-warning"
                                                    size={40}
                                                    mode="determinate"
                                                    value={40}
                                                    min={0}
                                                    max={50}
                                                />
                                            ) : (
                                                <i className="material-icons">
                                                    send
                                                </i>
                                            )}
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                </div>

                {/* <div className="chat-input-wrapper">
                    <InputGroup>
                        <FormattedMessage id="messages.inputMessage.placeholder">
                            {(placeholder) => (
                                <Input
                                    autoFocus
                                    type="text"
                                    placeholder={placeholder}
                                    disabled={onLoading || onSaving}
                                    value={messageText}
                                    onKeyPress={(e) => this.handleKeyPress(e)}
                                    onChange={(e) =>
                                        this.setState({
                                            messageText: e.target.value,
                                        })
                                    }
                                />
                            )}
                        </FormattedMessage>

                        <InputGroupAddon addonType="append">
                            <IconButton
                                className="btn-warning send-message"
                                disabled={onSaving}
                                onClick={() => this.saveMessage()}
                            >
                                {onSaving ? (
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                        mode="determinate"
                                        value={40}
                                        min={0}
                                        max={50}
                                    />
                                ) : (
                                    <i className="material-icons">send</i>
                                )}
                            </IconButton>
                        </InputGroupAddon>
                    </InputGroup>
                </div> */}
            </div>
        );
    }
}

export default DetailConversation;

/**
 * Footer
 */
import React from 'react';
import Button from '@material-ui/core/Button';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// app config
import AppConfig from 'Constants/AppConfig';

const Footer = () => (
	<div className="rct-footer d-flex justify-content-between align-items-center">
		<ul className="list-inline footer-menus mb-0">
			<li className="list-inline-item">
				<Button href={AppConfig.URL_TOP_TIPS} target="_blank"><IntlMessages id="footer.topTips" /></Button>
			</li>
			<li className="list-inline-item">
				<Button href={AppConfig.URL_TERMS_AND_CONDITIONS} target="_blank"><IntlMessages id="footer.termsAndConditions" /></Button>
			</li>
			<li className="list-inline-item">
				<Button href="mailto:support@gigtogig.co.uk"><IntlMessages id="footer.contactUs" /></Button>
			</li>
			<li className="list-inline-item">
				<Button href={AppConfig.URL_PRIVACY_POLICY} target="_blank"><IntlMessages id="footer.privacyPolicy" /></Button>
			</li>
		</ul>
		<h5 className="mb-0">{AppConfig.copyRightText}</h5>
	</div>
);

export default Footer;

/**
 * Uniform Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import { GET_UNIFORMS } from 'Actions/types';
import { getUniformsSuccess, getUniformsFailure } from 'Actions';
import { getUniformsRequest } from "Services/employers/UniformService";

/**
 * Get Uniforms from server
 */
function* handleGetAllUniforms() {
    try {
        const response = yield call(getUniformsRequest);

        if (response.data && isSuccess(response)) {
            yield put(getUniformsSuccess(response.data.data));
        } else {
            yield put(getUniformsFailure());
        }
    } catch (error) {
        yield put(getUniformsFailure(error));
    }
}

/**
 * Taking uniforms list
 */
export function* watchAllUniforms() {
    yield takeEvery(GET_UNIFORMS, handleGetAllUniforms);
}

/**
 * Uniform Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchAllUniforms),
    ]);
}

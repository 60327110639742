/**
 * Report Sagas
 */
import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_BLOCKED_SEEKERS_REQUEST,
    GET_RATING_OF_SEEKERS_REQUEST,
    GET_TOP_SEEKERS_BY_SHIFTS_REQUEST,
    GET_WEEKLY_TIMECARD_REPORT,
    GET_WEEKLY_FULFILMENT_REPORT,
    GET_MONTHLY_TIMECARD_REPORT,
    GET_BILLING_REPORT,
    GET_POSITIONS_STATISTICS_REQUEST,
    EXCEL_DOWNLOAD_WEEKLY_HOUR
} from 'Actions/types';

import { 
    hideRootSpinner
} from 'Actions';

import DownloadHelper from 'Helpers/Download';

import {
    
    getWeeklyHourReports
} from 'Services/employers/EmployerExcelReports';

/**
 * Service API
 */
import {
    getBlockedSeekersRequest,
    getRatingOfSeekersRequest,
    getTopSeekersByShiftsRequest,
    getTimecardReportRequest,
    getFulfilmentReportRequest,
    getBillingReportRequest,
    getPositionsStatisticsRequest
} from 'Services/employers/ReportService';

/**
 * Handle Success & Failure Actions
 */
import {
    showErrorResponse,

    getBlockedSeekersSuccess,
    getBlockedSeekersFailure,

    getRatingOfSeekersFailure,
    getRatingOfSeekersSuccess,

    getTopSeekersByShiftsFailure,
    getTopSeekersByShiftsSuccess,
    getTopSeekersMonthByShiftsSuccess,

    getWeeklyFulfilmentReportSuccess,
    getWeeklyFulfilmentReportFailure,

    getBillingReportSuccess,
    getBillingReportFailure,

    getWeeklyTimecardReportSuccess,
    getWeeklyTimecardReportFailure,
    getMonthlyTimecardReportSuccess,
    getMonthlyTimecardReportFailure,

    getPositionsStatisticsSuccess,
    getPositionsStatisticsFailure
} from '../actions/ReportActions';

/**
 * Get Blocked Seekers Saga
 */
function* handleGetBlockedSeekers() {
    try {
        const response = yield call(getBlockedSeekersRequest);

        if (response.data && isSuccess(response)) {
            yield put(getBlockedSeekersSuccess(response));
        } else {
            yield put(showErrorResponse(response));
        }

    } catch (error) {
        yield put(getBlockedSeekersFailure(error));
    }
}

export function* watchGetBlockedSeekers() {
    yield takeLatest(GET_BLOCKED_SEEKERS_REQUEST, handleGetBlockedSeekers);
}

/**
 * Get Rating of Seekers Saga
 */
function* handleGetRatingOfSeekers() {
    try {
        const response = yield call(getRatingOfSeekersRequest);

        if (response.data && isSuccess(response)) {
            yield put(getRatingOfSeekersSuccess(response));
        } else {
            yield put(getRatingOfSeekersFailure(response));
        }
    } catch (error) {
        yield put(getRatingOfSeekersFailure(error));
    }
}

export function* watchGetRatingOfSeekers() {
    yield takeLatest(GET_RATING_OF_SEEKERS_REQUEST, handleGetRatingOfSeekers);
}

/**
 * Get Top Seekers Saga
 */
function* handleGetTopSeekersByShifts({ payload }) {
    try {
        let response;
        if (payload === "all") {
            response = yield call(getTopSeekersByShiftsRequest);
        } else {
            const m = (Object.keys(payload).length && payload.hasOwnProperty('month')) ? payload.month : 1;
            const l = (Object.keys(payload).length && payload.hasOwnProperty('location_id')) ? payload.location_id : 0;
            response = yield call(getTopSeekersByShiftsRequest, { month: m, location_id: l });
        }

        if (response.data && isSuccess(response)) {
            if (payload === "all") {
                yield put(getTopSeekersByShiftsSuccess(response));
            } else {
                yield put(getTopSeekersMonthByShiftsSuccess(response));
            }
        } else {
            yield put(getTopSeekersByShiftsFailure(response));
        }
    } catch (error) {
        yield put(getTopSeekersByShiftsFailure(error));
    }
}

function* watchGetTopSeekersByShifts() {
    yield takeEvery(GET_TOP_SEEKERS_BY_SHIFTS_REQUEST, handleGetTopSeekersByShifts);
}

/**
 * Get Weekly Fulfilment Report Saga
 */
 function* handleGetWeeklyFulfilmentReport(payload) {
    try {
        payload.params.orderby = "desc";
        const response = yield call(getFulfilmentReportRequest, payload.params);

        if (response.data && isSuccess(response)) {
            yield put(getWeeklyFulfilmentReportSuccess(response.data.data));
        } else {
            yield put(getWeeklyFulfilmentReportFailure(response.data.code));
        }

    } catch (error) {
        yield put(getWeeklyFulfilmentReportFailure(error));
    }
}
function* watchWeeklyFulfilmentReport() {
    yield takeLatest(GET_WEEKLY_FULFILMENT_REPORT, handleGetWeeklyFulfilmentReport);
}

/**
 * Get Billing Report Saga
 */
 function* handleGetBillingReport(payload) {
    try {
        payload.params.orderby = "desc";
        const response = yield call(getBillingReportRequest, payload.params);

        if (response.data && isSuccess(response)) {
            yield put(getBillingReportSuccess(response.data.data));
        } else {
            yield put(getBillingReportFailure(response.data.code));
        }

    } catch (error) {
        yield put(getBillingReportFailure(error));
    }
}
function* watchBillingReport() {
    yield takeLatest(GET_BILLING_REPORT, handleGetBillingReport);
}

/**
 * Get Weekly Timecard Report Saga
 */
function* handleGetWeeklyTimecardReport(payload) {
    try {
        payload.params.orderby = "desc";
        const response = yield call(getTimecardReportRequest, payload.params);

        if (response.data && isSuccess(response)) {
            yield put(getWeeklyTimecardReportSuccess(response.data.data));
        } else {
            yield put(getWeeklyTimecardReportFailure(response.data.code));
        }

    } catch (error) {
        yield put(getWeeklyTimecardReportFailure(error));
    }
}

function* watchWeeklyTimecardReport() {
    yield takeLatest(GET_WEEKLY_TIMECARD_REPORT, handleGetWeeklyTimecardReport);
}

/**
 * Get Monthly Timecard Report Saga
 */
function* handleGetMonthlyTimecardReport(payload) {
    try {
        const response = yield call(getTimecardReportRequest, payload.params);

        if (response.data && isSuccess(response)) {
            yield put(getMonthlyTimecardReportSuccess(response.data.data));
        } else {
            yield put(getMonthlyTimecardReportFailure(response.data.code));
        }

    } catch (error) {
        yield put(getMonthlyTimecardReportFailure(error));
    }
}

function* watchMonthlyTimecardReport() {
    yield takeLatest(GET_MONTHLY_TIMECARD_REPORT, handleGetMonthlyTimecardReport);
}

/**
 * Get Position Statistics Saga
 */
function* handleGetPositionsStatistics() {
    try {
        const response = yield call(getPositionsStatisticsRequest);

        if (response.data && isSuccess(response)) {
            yield put(getPositionsStatisticsSuccess(response.data.data));
        } else {
            yield put(getPositionsStatisticsFailure(response));
        }

    } catch (error) {
        yield put(getPositionsStatisticsFailure(error));
    }
}

function*  handleDownloadExcelWeekly({payload}) {
    const { week, locationId } = payload;
    const response               =yield call(getWeeklyHourReports, week, locationId)
    
    const { data }               = response.data;

    yield put(hideRootSpinner());

    DownloadHelper.forceDownload(data.content, data.file);
}

function* watchGetPositionsStatistics() {
    yield takeLatest(GET_POSITIONS_STATISTICS_REQUEST, handleGetPositionsStatistics);
}

function* watchDownloadTopSeekers() {
    yield takeEvery(EXCEL_DOWNLOAD_WEEKLY_HOUR, handleDownloadExcelWeekly )
}

export default function* rootSaga() {
    yield all([
        fork(watchGetBlockedSeekers),
        fork(watchGetRatingOfSeekers),
        fork(watchGetTopSeekersByShifts),
        fork(watchWeeklyFulfilmentReport),
        fork(watchWeeklyTimecardReport),
        fork(watchMonthlyTimecardReport),
        fork(watchBillingReport),
        fork(watchGetPositionsStatistics),
        fork(watchDownloadTopSeekers)
    ]);
}

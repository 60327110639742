import {GET_EMPLOYEE_OF_JOB_SUCCESS} from "Actions/types";


const INIT_STATE = {
    listJobOfEmployee: []
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_OF_JOB_SUCCESS:
            return {
                ...status,
                listJobOfEmployee: action.payload.data
            }
        default:
            return {...state};
    }
}
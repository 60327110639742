import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import MyGig from "Constants/MyGig";
import PushNotification from "PushNotification";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListOfPositions from "Components/Model/DirectMessage/ListOfPositions";
import DetailConversation from "Components/Model/DirectMessage/DetailConversation";
import SearchSeeker from "Components/Model/DirectMessage/SearchSeeker";
import Spinner from "Components/Model/Spinner";
import StoredData from "Constants/StoredData";
import IntlMessages from "Util/IntlMessages";

import { closeDirectMessageModal, getDirectMessage } from "Actions";

const MESSAGES_PER_PAGE = 20;

const pushnotification = new PushNotification();
class DirectMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiCalled: false,
            positionsWithMessages: [],
            messagesOfPosition: [],
            openConversation: null,
            openConversationPage: 1,
            onSavingMessage: false,
        };
    }

    closeModal() {
        this.setState({
            apiCalled: false,
        });
        this.props.closeDirectMessageModal();
        this.props.onCloseModal();
    }

    formatPositionObject(position) {
        return {
            positionId: Number(position.position_id),
            skillName: position.skill_name,
            skillType: position.skill_type,
            schedules: position.schedule,
            locationName: "",
            numberUnread: Number(position.total_unread),
            opened: false,
            totalPage: Math.ceil(position.total_messages / MESSAGES_PER_PAGE),
            messages: [],
        };
    }

    getPositionIndexFromId(positionId) {
        const { positionsWithMessages } = this.state;

        return positionsWithMessages.findIndex(
            (position) => position.positionId === positionId
        );
    }

    getPositionDataFromId(positionId) {
        const { positionsWithMessages } = this.state;
        const values = positionsWithMessages.filter(
            (position) => position.positionId === positionId
        );

        return values.length > 0 ? values[0] : null;
    }

    markMessagesAsRead(positionId) {
        const index = this.getPositionIndexFromId(positionId);
        const { positionsWithMessages } = this.state;

        if (positionsWithMessages[index]) {
            positionsWithMessages[index].numberUnread = 0;
            this.setState({ positionsWithMessages });
        }
    }

    switchConversation(positionId) {
        this.setState({
            openConversation: positionId,
            openConversationPage: 1,
        });
    }

    loadMoreMessages(positionId) {
        if (!this.props.onLoadingMessages) {
            const page = this.state.openConversationPage + 1;
            const position = this.getPositionDataFromId(positionId);

            if (position && position.totalPage >= page) {
                this.setState({
                    openConversationPage: page,
                });
            }
        }
    }

    doSaveMessage(positionId, messageText) {
        this.setState({ onSavingMessage: true });
        this.props.sendMessageToPosition(
            positionId,
            messageText,
            (dataResponse) =>
                this.storeNewMessageImmediately(
                    dataResponse.id,
                    positionId,
                    messageText
                ),
            (error) => this.handleSendMessageError(error)
        );
    }

    storeNewMessageImmediately(noteId, positionId, messageText) {
        const { messagesOfPosition } = this.state;

        messagesOfPosition.push({
            note_id: noteId,
            note_text: messageText,
            created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            author_employee_id: null,
            profile_thumbnail: null,
        });

        this.setState({
            messagesOfPosition,
            onSavingMessage: false,
        });
    }

    componentDidMount() {
        // if (!window.directMessage) {
        //     pushnotification.withPusherConnection();
        //     pushnotification.subscribeDirectMessage(
        //         StoredData.get(StoredData.KEYS.LOGGED_IN_AS)
        //     );
        // }
        // let self = this;

        // window.directMessage.bind(MyGig.evenDirectMessage, (data) => {
        //     self.props.getDirectMessage(
        //         data.actionParams.conversation_id,
        //         false
        //     );
        // });
    }

    render() {
        const { openConversation } = this.state;

        const {
            isOpenModal,
            isOpenSearch,
            messages,
            conversations,
            conversationId,
        } = this.props;

        return (
            <SwipeableDrawer
                anchor={"right"}
                open={isOpenModal}
                onOpen={() => false}
                onClose={() => this.closeModal()}
                className="MuiDrawer-modal-direct-message-gig-container"
            >
                <div className="drawer-wrapper">
                    <Spinner
                        spinnerWidth={70}
                        show={this.props.onLoadingPositions}
                    />

                    <div className="direct-message-modal-wrapper">
                        {/* <div className="message-modal-header">
                            <h1 className="message-modal-title thin">
                                <FormattedMessage id="directMessage.title" />
                                <i className="zmdi zmdi-close black font-2x pull-right on-hover" onClick={() => this.closeModal()} />
                            </h1>
                        </div> */}
                        <h1 className="message-gig-title">
                            <IntlMessages id="directMessage.title" />
                            <i
                                className="zmdi zmdi-close black font-2x pull-right on-hover"
                                onClick={() => this.closeModal()}
                            />
                        </h1>

                        {!isOpenSearch && (
                            <Fragment>
                                {conversations.length > 0 && (
                                    <div className="message-modal-body clearfix">
                                        <div
                                            className="p-0 pull-left left-bar"
                                            style={{ width: "300px" }}
                                        >
                                            {/* <SearchSeeker open={isOpenSearch} /> */}
                                            <ListOfPositions
                                                lists={conversations}
                                                openPosition={conversationId}
                                                onOpenConversation={(
                                                    positionId
                                                ) =>
                                                    this.switchConversation(
                                                        positionId
                                                    )
                                                }
                                            />
                                        </div>

                                        <div
                                            className="p-0 pull-left"
                                            style={{
                                                width: "calc(100% - 300px)",
                                            }}
                                        >
                                            {messages && (
                                                <DetailConversation
                                                    onSaving={
                                                        this.state
                                                            .onSavingMessage
                                                    }
                                                    onLoading={
                                                        this.props
                                                            .onLoadingMessages
                                                    }
                                                    onScrollLoadmore={() =>
                                                        this.loadMoreMessages(
                                                            openConversation
                                                        )
                                                    }
                                                    ignoreAutoToBottom={
                                                        this.state
                                                            .openConversationPage >
                                                        1
                                                    }
                                                    messages={messages}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}

                                {conversations.length === 0 && (
                                    <div className="text-uppercase page-blank-message">
                                        <p className="orange">
                                            <IntlMessages id="directMessage.emptyConversations" />
                                        </p>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>
            </SwipeableDrawer>
        );
    }
}

const mapStateToProps = ({ directMessageReducer, conversationReducer }) => {
    const { conversations } = conversationReducer;
    return {
        conversations,
        ...directMessageReducer,
    };
};

const mapActionToProps = {
    closeDirectMessageModal,
    getDirectMessage,
};

export default connect(mapStateToProps, mapActionToProps)(DirectMessageModal);

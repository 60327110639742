import MyGig from 'Constants/MyGig';

export const currentPage = (location) => {
  const { pathname } = location;
  const {
    postedPage, filledPage, upcomingPage, calendarPage,
    pendingPage, completedPage, weeklyPage, monthlyPage
  } = MyGig.currentPage;

  switch (pathname) {
    case "/app/my-gigs/posted": return postedPage;
    case "/app/my-gigs/filled": return filledPage;
    case "/app/my-gigs/upcoming": return upcomingPage;
    case "/app/calendar/weekly": return calendarPage;
    case "/app/calendar/monthly": return calendarPage;
    //case "/app/dashboard/calendar-job": return calendarPage;
    case "/app/my-gigs/pending": return pendingPage;
    case "/app/my-gigs/completed": return completedPage;
    default: return false;
  }
};

export default { currentPage };

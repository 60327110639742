import {
  RESET_TEXT_FORGOT,
  CHECK_EMAIL_EXIST,
  CHECK_EMAIL_EXIST_SUCCESS,
  CHECK_EMAIL_EXIST_FAILURE,
  SEND_FORGOT_PASSWORD,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_FAILURE,
} from "./types";

export const resetTextForgot = () => ({
  type: RESET_TEXT_FORGOT,
});

export const checkEmailExist = (email) => ({
  email,
  type: CHECK_EMAIL_EXIST
});

export const checkEmailExistSuccess = (responseForgotPassword) => ({
  responseForgotPassword,
  type: CHECK_EMAIL_EXIST_SUCCESS
});

export const checkEmailExistFailure = (error) => ({
  error,
  type: CHECK_EMAIL_EXIST_FAILURE
});

export const sendForgotPassword = (email) => ({
  email,
  type: SEND_FORGOT_PASSWORD
});

export const sendForgotPasswordSuccess = (responseForgotPassword) => ({
  responseForgotPassword,
  type: SEND_FORGOT_PASSWORD_SUCCESS
});

export const sendForgotPasswordFailure = (error) => ({
  error,
  type: SEND_FORGOT_PASSWORD_FAILURE
});

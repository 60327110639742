import API from "Api";

/**
 * Create shift
 *
 * @param  {Object} params
 * @typedef {{
        location_id: number,
        description: string,
    }}
 * @return async
 */
export const createShiftRequest = async (params) =>
    await API.post("/employers/shifts", {
        location_id: params.location_id,
        description: params.description,
        is_privated: params.is_privated,
    })
        .then((response) => response)
        .catch((err) => err);

/**
 * Edit shift
 *
 * @param  {Object} params
 * @typedef {{
        location_id: number,
        description: string,
    }}
 * @return async
 */
export const editShiftRequest = async (params) =>
    await API.put(`/employers/shifts/updateShift/${params.shift_id}`, {
        location_id: params.location_id,
        description: params.description,
        is_privated: params.is_privated,
    })
        .then((response) => response)
        .catch((err) => err);

/**
 * check overlapped schedule request
 * @param  {number} positionid
 * @param  {Timeranges} schedule
 * @return async
 */

export const checkOverllapedRequest = async (params) =>
    API.post(
        `/employers/positions/${params.position_id}/checkOverlapOnEditing`,
        params
    )
        .then((response) => response)
        .catch((err) => err);

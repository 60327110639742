import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { isSuccess } from "Helpers/ResponseStatus";

import {
  GET_LIST_SEEKER_REQUEST,
  GET_EMPLOYEE_DETAIL,
  UPDATE_EMPLOYEE_DETAIL,
  HANDLE_BLOCK_SEEKER,
  HANDLE_UNBLOCK_SEEKER,
  GET_JOB_TYPE,
  GET_EMPLOYEE_LIST_JOB_APPLY,
  GET_INFO_SEEKER_IN_POSITION,
  ADD_NOTE,
} from "Actions/types";
import {
  getListSeekerSuccess,
  getListSeekerFailure,
  getSeekerDetailSuccess,
  getSeekerDetailError,
  updateSeekerDetailSuccess,
  updateSeekerDetailFailure,
  hideRootSpinner,
  blockUnlockSuccess,
  blockUnlockFailure,
  deleteSeekerAfterBlock,
  getSeekerDetail,
  getJobTypeSuccess,
  getEmployeeListJobApplySuccess,
  getEmployeeListJobApplyFailure,
  getInfoSeekerInPositionSuccess,
  getInfoSeekerInPositionFailure,
  addNoteFailure,
  addNoteSuccess,
} from "Actions";
import {
  getListSeekerRequest as getListSeekerService,
  getEmployeeDetailRequest,
  getInfoSeekerRequest,
  getEmployeeListJobApplyRequest,
  updateEmployeeDetailRequest,
  blockEmployeeDetailRequest,
  unblockEmployeeDetailRequest,
  handleGetJobTypeRequest,
  addNoteRequest,
} from "Services/employers/SeekerService";

/**
 * handle get list provider
 */
function* handleGetListSeeker({ payload }) {
  try {
    const response = yield call(getListSeekerService, payload);

    if (response.data && isSuccess(response)) {
      yield put(getListSeekerSuccess(response.data.data));
    } else {
      yield put(getListSeekerFailure(response));
    }
  } catch (error) {
    yield put(getListSeekerFailure(error));
  }
}

function* handleGetEmployeeDetail({ payload }) {
  try {
    const { employeeId } = payload;
    const response = yield call(getEmployeeDetailRequest, employeeId);

    if (response.data && isSuccess(response)) {
      yield put(getSeekerDetailSuccess(response.data.data));
      yield put(hideRootSpinner());
    } else {
      yield put(getSeekerDetailError(response));
    }
  } catch (e) {}
}

function* handleGetInfoSeekerInPostion({ payload }) {
  try {
    const { employeeId } = payload;
    const response = yield call(getInfoSeekerRequest, employeeId);

    if (response.data && isSuccess(response)) {
      yield put(getInfoSeekerInPositionSuccess(response.data.data));
      yield put(hideRootSpinner());
    } else {
      yield put(getInfoSeekerInPositionFailure(response));
    }
  } catch (e) {}
}

function* handleGetEmployeeListJobApply({ payload }) {
  try {
    const { employeeId } = payload;
    const response = yield call(getEmployeeListJobApplyRequest, employeeId);

    if (response.data && isSuccess(response)) {
      yield put(getEmployeeListJobApplySuccess(response.data.data));
    } else {
      yield put(getEmployeeListJobApplyFailure(response));
    }
  } catch (e) {
    yield put(getEmployeeListJobApplyFailure(response));
  }
}

function* handleAddNote({ payload }) {
  try {
    const { employeeId, note } = payload;
    const response = yield call(addNoteRequest, {
      employee_id: employeeId,
      note,
    });

    if (response.data && isSuccess(response)) {
      yield put(addNoteSuccess(response.data.data));
    } else {
      yield put(addNoteFailure(response));
    }
  } catch (e) {
    yield put(addNoteFailure());
  }
}

function* handleUpdateEmployeeDetail({ payload }) {
  try {
    const { data, employee_id, showPopup } = payload;

    const response = yield call(updateEmployeeDetailRequest, employee_id, data);

    if (response.data && isSuccess(response)) {
      yield put(
        updateSeekerDetailSuccess({
          data: response.data.data,
          showPopup: showPopup,
        })
      );
    } else {
      yield put(updateSeekerDetailFailure(response));
    }
    yield put(hideRootSpinner());
  } catch (e) {
    yield put(hideRootSpinner());
  }
}

function* handleGetJobType() {
  try {
    const response = yield call(handleGetJobTypeRequest);
    if (isSuccess(response)) {
      yield put(getJobTypeSuccess(response.data.data));
    }
  } catch (e) {
    yield put(showErrorResponse(e));
  }
}

function* handleBlockSeekerDetail({ payload }) {
  try {
    const { employeeId } = payload;
    const response = yield call(blockEmployeeDetailRequest, employeeId);
    if (response.data && isSuccess(response)) {
      yield put(blockUnlockSuccess());
      yield put(getSeekerDetail(employeeId));
    } else {
      yield put(blockUnlockFailure());
    }
    yield put(hideRootSpinner());
  } catch (e) {
    yield put(hideRootSpinner());
  }
}

function* handleUnblockSeekerDetail({ payload }) {
  try {
    const { employeeId } = payload;
    const response = yield call(unblockEmployeeDetailRequest, employeeId);
    if (response.data && isSuccess(response)) {
      yield put(blockUnlockSuccess());
      yield put(getSeekerDetail(employeeId));
    } else {
      yield put(blockUnlockFailure());
    }
    yield put(hideRootSpinner());
  } catch (e) {
    yield put(hideRootSpinner());
  }
}

export function* watchgetSeekerDetail() {
  yield takeEvery(GET_LIST_SEEKER_REQUEST, handleGetListSeeker);
}

export function* watchListSeeker() {
  yield takeEvery(GET_EMPLOYEE_DETAIL, handleGetEmployeeDetail);
}

export function* watchInfoSeekerInPostion() {
  yield takeEvery(GET_INFO_SEEKER_IN_POSITION, handleGetInfoSeekerInPostion);
}

export function* watchEmployeeListJobApply() {
  yield takeEvery(GET_EMPLOYEE_LIST_JOB_APPLY, handleGetEmployeeListJobApply);
}

export function* watchAddNote() {
  yield takeEvery(ADD_NOTE, handleAddNote);
}

export function* watchUpdateEmployeeDetail() {
  yield takeEvery(UPDATE_EMPLOYEE_DETAIL, handleUpdateEmployeeDetail);
}

export function* watchBlockSeeker() {
  yield takeEvery(HANDLE_BLOCK_SEEKER, handleBlockSeekerDetail);
}

export function* watchUnblockSeeker() {
  yield takeEvery(HANDLE_UNBLOCK_SEEKER, handleUnblockSeekerDetail);
}

export function* watchGetJobType() {
  yield takeEvery(GET_JOB_TYPE, handleGetJobType);
}

export default function* rootSaga() {
  yield all([
    fork(watchListSeeker),
    fork(watchgetSeekerDetail),
    fork(watchUpdateEmployeeDetail),
    fork(watchBlockSeeker),
    fork(watchUnblockSeeker),
    fork(watchGetJobType),
    fork(watchEmployeeListJobApply),
    fork(watchAddNote),
    fork(watchInfoSeekerInPostion),
  ]);
}

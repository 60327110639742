/**
 * Charge Rate Reducer
 */

import {
    GET_CHARGERATES,
    GET_CHARGERATES_SUCCESS,
    GET_CHARGERATES_FAILURE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    chargeRate: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHARGERATES:
            return { ...state };

        case GET_CHARGERATES_SUCCESS:
            return { ...state, chargeRate: action.payload };

        case GET_CHARGERATES_FAILURE:
            return { ...state };

        default: return { ...state};
    }
};

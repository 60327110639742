/**
 * Reports charts & partials
 */
 import React, { Component, Fragment } from "react";
 import { connect } from "react-redux";
 import { FormattedMessage } from "react-intl";
 import SweetAlert from "react-bootstrap-sweetalert";
 
 import Doughnut from "Routes/reports/doughnut";
 import FakeRows from "Routes/reports/partials/FakeRows";
 import MonthlyChart from "Routes/reports/partials/MonthlyChart";
 import MonthlyYearPicker from "Routes/reports/partials/MonthlyYearPicker";
 import Rating from "Routes/reports/partials/Rating";
 import WeeklyReport from "Routes/reports/partials/WeeklyReport";
 import BillingReport from "Routes/reports/partials/BillingReport";
 import FulfilmentReport from "Routes/reports/partials/FulfilmentReport";
 import AWRReport from "Routes/reports/partials/AWRReport";
 import TimecardReport from "Routes/reports/partials/TimecardReport";
 import Warning from "Assets/img/SweetAlert/warning.png";
 import Grid from "@material-ui/core/Grid";
 import List from "@material-ui/core/List";
 import ListItem from "@material-ui/core/ListItem";
 import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
 import ListItemText from "@material-ui/core/ListItemText";
 import Avatar from "@material-ui/core/Avatar";
 import MatButton from "@material-ui/core/Button";
 import Tooltip from "@material-ui/core/Tooltip";
 
 import ModalSearchSeeker from "./Modal";
 import CircularProgress from '@material-ui/core/CircularProgress';
 
 import { injectIntl } from "react-intl";
 import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
 import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
 import { validNumberOrZero, roundByDecimals } from "Helpers/helpers";
 import IntlMessages from "Util/IntlMessages";
 import UserDefault from "Assets/img/default-user.png";
 import { Input } from "reactstrap";
 import StoredData from "Constants/StoredData";
 import MyGig from 'Constants/MyGig';
 import DownloadIcon from '../../assets/img/gig-icons/DownloadIcon.png';
 
 import moment from "moment";
 
 import {
     getBlockedSeekers,
     getRatingOfSeekers,
     getTopSeekersByShifts,
     getWeeklyTimecardReport,
     getMonthlyTimecardReport,
     getPositionsStatistics,
     excelDownloadWeeklyHours,
     getWeeklyFulfilmentReport,
     getBillingReport,
 } from "Actions/ReportActions";
 
 import { unblockEmployee } from "Actions/BlockEmployeeActions";
 
 import { showRootSpinner, getLocation } from "Actions";
 
 const INTERVALS = {
     WEEK: "1 week",
     MONTH: "1 month",
 };
 
 class Report extends Component {
     constructor(props) {
         super(props);
         this.state = {
             listPositions: [],
             unblockEmployeeId: null,
             alertConfirmation: {
                 show: false,
                 messageKey: "",
                 onConfirm: null,
             },
             alertSuccessful: {
                 show: false,
                 messageKey: "",
                 onConfirm: null,
             },
             alertFailure: {
                 show: false,
                 messageKey: "",
                 onConfirm: null,
             },
             isModalVisible: false,
             idMsg: 'reports.seekers.topSeekersOfMonth'
         };
 
         this.renderBlockedSeekers = this.renderBlockedSeekers.bind(this);
         this.renderTopSeekers = this.renderTopSeekers.bind(this);
         this.doUnblockSeeker = this.doUnblockSeeker.bind(this);
     }
 
     componentDidMount() {
         // initialize data
         this.props.getBlockedSeekers();
         this.props.getTopSeekersByShifts();
         this.props.getTopSeekersByShifts("week");
         this.props.getRatingOfSeekers();
         this.props.getWeeklyFulfilmentReport(
             this.getTimecardParams(INTERVALS.WEEK)
         );
         this.props.getWeeklyTimecardReport(
             this.getTimecardParams(INTERVALS.WEEK)
         );
         // this.props.getMonthlyTimecardReport(
         //     this.getTimecardParams(INTERVALS.MONTH)
         // );
         this.props.getBillingReport(
             this.getTimecardParams(INTERVALS.WEEK)
         );
         this.props.getPositionsStatistics();
         this.props.getLocation();
     }
 
     UNSAFE_componentWillReceiveProps(nextProps) {
         this.setState({
             listPositions: nextProps.listPositions,
         });
     }
 
     getTimecardParams(interval, year) {
         let start;
         let end;
         let selected;
 
         if (!year) {
             year = moment().year();
         }
 
         if (interval === INTERVALS.WEEK) {
             start = moment()
                 .year(year)
                 .startOf("year")
                 .startOf("isoWeek")
                 .format("DD.MM.YYYY");
             end = moment()
                 .year(year)
                 .endOf("year")
                 .endOf("isoWeek")
                 .format("DD.MM.YYYY");
         } else if (interval === INTERVALS.MONTH) {
             start = moment().year(year).startOf("year").format("DD.MM.YYYY");
             end = moment().year(year).endOf("year").format("DD.MM.YYYY");
         }
 
         selected = moment().year(year).startOf("isoWeek").format("DD.MM.YYYY");
 
         return {
             startDate: {
                 time: start,
                 format: "d.m.Y",
             },
             endDate: {
                 time: end,
                 format: "d.m.Y",
             },
             interval: interval,
             selectedDate: {
                 time: selected,
                 format: "d.m.Y",
             },
         };
     }
 
     updateChartsByYear(year) {
         this.props.getMonthlyTimecardReport(
             this.getTimecardParams(INTERVALS.MONTH, year)
         );
     }
 
     doUnblockSeeker() {
         const { unblockEmployeeId } = this.state;
         this.hideAlertConfirmation();
 
         this.props.unblockEmployee(
             unblockEmployeeId,
             () =>
                 this.showAlertSuccessFailure(
                     "alertSuccessful",
                     "unBlockEmployee.success.message",
                     () => {
                         this.hideAlertSuccessFailure("alertSuccessful");
                         this.props.getBlockedSeekers();
                     }
                 ),
             () =>
                 this.showAlertSuccessFailure(
                     "alertFailure",
                     "unBlockEmployee.failure.message",
                     this.hideAlertSuccessFailure.bind(this, "alertFailure")
                 )
         );
     }
 
     showAlertSuccessFailure(stateKey, messageKey, confirmCallback) {
         this.setState({
             [stateKey]: {
                 show: true,
                 messageKey,
                 onConfirm: confirmCallback,
             },
         });
     }
 
     hideAlertSuccessFailure(stateKey) {
         this.setState({
             ...this.state[stateKey],
             [stateKey]: {
                 show: false,
             },
         });
     }
 
     showAlertConfirmation(messageKey, confirmCallback) {
         this.setState({
             alertConfirmation: {
                 show: true,
                 messageKey,
                 onConfirm: confirmCallback,
             },
         });
     }
 
     hideAlertConfirmation() {
         const { alertConfirmation } = this.state;
         alertConfirmation.show = false;
         this.setState({ alertConfirmation });
     }
 
     setIdMsg(id) {
         this.setState({ idMsg: id });
     }
 
     renderBlockedSeekers() {
         const { blockedSeekers } = this.props;
         return blockedSeekers.map((seeker, index) => {
             const avatar = seeker.profileThumbnail
                 ? seeker.profileThumbnail
                 : UserDefault;
             return (
                 <ListItem
                     className="no-padding"
                     key={`blocked-seeker-${seeker.employeeId}`}
                 >
                     {/* <span className="list-numerical-order">{index + 1}</span> */}
                     <Avatar
                         alt={seeker.employeeName}
                         className=""
                         style={{
                             width: "40px",
                             height: "40px",
                             borderRadius: "50%",
                         }}
                         src={avatar}
                     />
                     <div className="report-seeker-name">
                         <div className="seeker-name-size">
                             <p
                                 className="m-0"
                                 style={{ wordBreak: "break-word" }}
                             >
                                 {seeker.employeeName}
                             </p>
                         </div>
                     </div>
                     <ListItemSecondaryAction>
                         <MatButton
 
                             color="secondary"
                             // className="mb-10"
                             style={{ borderRadius: "8px" }}
                             className="gig-button black bigger-font pull-right mb-10"
                             onClick={() => {
                                 this.setState({
                                     unblockEmployeeId: seeker.employeeId,
                                 });
                                 this.showAlertConfirmation(
                                     "confirmation.unBlockEmployee",
                                     this.doUnblockSeeker
                                 );
                             }}
                         >
                             <FormattedMessage id="reports.unblock" />
                         </MatButton>
                     </ListItemSecondaryAction>
                 </ListItem>
             );
         });
     }
 
     handleModalVisible = () => {
         const { isModalVisible } = this.state;
         this.setState({ isModalVisible: !isModalVisible });
     };
 
     checkIsWincanton = () => {
         const providerId = Number(StoredData.get("employer-id"));
         return MyGig.WINCANTON_GROUPS_IDS.includes(providerId);
     }
 
     renderTopSeekers(month = false) {
         const { topSeekers, topSeekersOfMonth, intl, topSeekerLoading } = this.props;
         let data = topSeekers;
         if (month) {
             data = topSeekersOfMonth;
         }
         if (topSeekerLoading && month) {
             return (
                 <div className="wrapper" style={{ textAlign: 'center' }}>
                     <CircularProgress className="progress-warning" size={70} mode="determinate" value={40} min={0} max={50} />
                 </div>
             );
         }
 
         return data.map((seeker, index) => {
             const avatar = seeker.profileThumbnail
                 ? seeker.profileThumbnail
                 : UserDefault;
             return (
 
                 <ListItem
                     className="no-padding"
                     key={`top-seeker-${seeker.employeeId}`}
                 >
                     <div className="report-seeker-name">
                         <div
                             style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                             }}
                         >
                             <Avatar
                                 alt={avatar}
                                 className=""
                                 style={{
                                     width: "40px",
                                     height: "40px",
                                     borderRadius: "50%",
                                 }}
                                 src={avatar}
                             />
                             {/* <Tooltip
                                     title={seeker.userFullname}
                                     placement="bottom"
                                 > */}
                             <div className="seeker-name-size">
                                 <p
                                     className="m-0"
                                     style={{ wordBreak: "break-word" }}
                                 >
                                     {seeker.userFullname.length > 10
                                         ? `${seeker.userFullname.substr(
                                             0,
                                             10
                                         )}...`
                                         : seeker.userFullname}
                                 </p>
                                 {/* <div className="job-tabs__item--summary--statistic">
                                     <p className=" d-flex m-0">
                                         <i
                                             className="gig-icon icon-jobs smaller bottom no-pointer"
                                             style={{
                                                 width: "15px",
                                                 height: "15px",
                                             }}
                                         />
                                         <span style={{ marginTop: "-1px" }}>
                                             {seeker.total_assignments || 0}
                                         </span>
                                     </p>
                                     <p
                                         className="d-flex"
                                         style={{ margin: 0, marginLeft: "8px" }}
                                     >
                                         <i
                                             className="gig-icon icon-thumbs-up bottom no-pointer"
                                             style={{
                                                 width: "15px",
                                                 height: "15px",
                                             }}
                                         />
                                         <span style={{ marginTop: "-1px" }}>
                                             {seeker.total_positive_reviews || 0}
                                         </span>
                                     </p>
                                     {seeker.employer_favourite > 0 && (
                                         <span>
                                             <i
                                                 className="gig-icon icon-favourite smaller bottom no-pointer"
                                                 style={{
                                                     width: "15px",
                                                     height: "15px",
                                                 }}
                                             />
                                         </span>
                                     )}
                                 </div> */}
                             </div>
                             {/* </Tooltip> */}
                             {/* {seeker.employerFavourite !== 1 && (
                                 <i className="material-icons">stars</i>
                             )} */}
                         </div>
 
                         <div className="shift-last-shift-section">
                             <p className="m-0">
                                 {intl.formatMessage({
                                     id: "reports.topseeker.totalShifts",
                                 })}{" "}
                                 {seeker.totalShifts}
                             </p>
                             <p className="m-0">
                                 {intl.formatMessage({
                                     id: "reports.topseeker.lastshift",
                                 })}{" "}
                                 {moment
                                     .unix(seeker.unix_starttime)
                                     .format("DD MMM YYYY")}
                             </p>
                         </div>
                     </div>
                 </ListItem>
             );
         });
     }
 
     render() {
         const {
             alertConfirmation,
             alertSuccessful,
             alertFailure,
             listPositions,
             isModalVisible,
             idMsg
         } = this.state;
         const {
             match,
             history,
             spentTotal,
             hourTotal,
             fulfilmentStatistics,
             weeklyStatistics,
             billingStatistics,
             billingStatsLoading,
             fulfilmentStatsLoading,
             weeklyStatsLoading,
             monthlyStatistics,
             monthlyChartLoading,
             listOfLocationDatas,
             rating,
         } = this.props;
 
         let listOfLocations = [];
         if (listOfLocationDatas) {
             listOfLocations = listOfLocationDatas.data;
         }
 
         const isWincantonGroups = this.checkIsWincanton();
 
         return (
             <div className="re-chart-wrapper report-page-wrapper">
                 <PageTitleBar
                     // title={"Reports"}
                     enableBreadCrumb={false}
                     match={match}
                     myGig={"Reports"}
                     history={history}
                 />
                 <div style={{ padding: "0 24px 24px" }}>
 
                     <div className="row monthly-report-outer-container">
                         <div className="col-sm-12 col-md-6 col-xl-6">
                             <RctCollapsibleCard
                                 heading="Billing"
                                 headingCustomClasses="report-card-header"
                             >
                                 <BillingReport
                                     billingStatistics={billingStatistics}
                                     loading={billingStatsLoading}
                                 />
                             </RctCollapsibleCard>
                         </div>
                         <div className="col-sm-12 col-md-6 col-xl-6">
                             <RctCollapsibleCard
                                 heading="Fulfilment Report"
                                 headingCustomClasses="report-card-header"
                             >
                                 <FulfilmentReport
                                     fulfilmentStatistics={fulfilmentStatistics}
                                     loading={fulfilmentStatsLoading}
                                 />
                             </RctCollapsibleCard>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-sm-12 col-md-6 col-xl-6">
                             <RctCollapsibleCard
                                 heading="Most Popular Positions"
                                 headingCustomClasses="report-card-header"
                             >
                                 <div className="report-doughnut-container">
                                     {listPositions.length > 0 && (
                                         <Doughnut
                                             listPositions={listPositions}
                                         />
                                     )}
                                     {listPositions.length === 0 && (
                                         <div className="">
                                             <p
                                                 className="empty-data-report"
                                                 style={{ marginTop: -18.75 }}
                                             >
                                                 <IntlMessages id="reports.empty.popularPositions" />
                                             </p>
                                         </div>
                                     )}
                                 </div>
                             </RctCollapsibleCard>
                         </div>
                         <div className="col-sm-12 col-md-6 col-xl-6">
                             <RctCollapsibleCard
                                 heading="Worker Pool"
                                 headingCustomClasses="report-card-header"
                                 dropDownDate
                                 weeklyDownloadHours={
                                     this.props.excelDownloadWeeklyHours
                                 }
                                 showRootSpinner={this.props.showRootSpinner}
                                 idMsg={(id) => {
                                     this.setIdMsg(id)
                                 }}
                                 getMonthWithWeeks={
                                     this.props.getTopSeekersByShifts
                                 }
                                 listOfLocations={listOfLocations}
                             >
                                 <div className="report-topseeker-container  report-list-seekers-container">
                                     <div className="d-flex justify-content-between">
                                         <div className="report-topseeker-container__left">
                                             <h2 className="report-seekers-subheader">
                                                 <FormattedMessage id="reports.seekers.topSeekers" />
                                             </h2>
                                             <List className="top-seeker-year">
                                                 {this.renderTopSeekers()}
                                             </List>
                                         </div>
                                         <div className="report-topseeker-container__right">
                                             <h2 className="report-seekers-subheader">
                                                 <FormattedMessage id={idMsg} />
                                             </h2>
                                             <List className="top-seeker-year">
                                                 {this.renderTopSeekers(true)}
                                             </List>
                                         </div>
                                     </div>
                                 </div>
                             </RctCollapsibleCard>
                         </div>
                     </div>
                     <div className="row ">
                         <div className="col-sm-12 col-md-6 col-xl-6">
                             <RctCollapsibleCard headingCustomClasses="report-card-header">
                                 <div className="report-blockseeker-container report-list-seekers-container">
                                     <Grid container>
                                         <Grid item xs={12} md={12}>
                                             <div className="d-flex justify-content-between align-items-center mb-2">
                                                 <h2
                                                     className="report-seekers-subheader m-0"
                                                     style={{
                                                         fontSize: "1.9rem",
                                                     }}
                                                 >
                                                     <FormattedMessage id="reports.seekers.blockedSeekers" />
                                                 </h2>
                                                 <MatButton
                                                     className="gig-button black  bigger-font pull-right mb-10"
 
                                                     onClick={
                                                         this.handleModalVisible
                                                     }
                                                     style={{
                                                         fontSize: "1.1rem",
                                                     }}
                                                 >
                                                     Add to List +
                                                 </MatButton>
                                             </div>
                                             <List className="report-list-blocked-seekers">
                                                 {this.renderBlockedSeekers()}
                                                 <FakeRows
                                                     keyPrefix="fake-top-seeker-"
                                                     number={
                                                         5 -
                                                         this.props
                                                             .blockedSeekers
                                                             .length
                                                     }
                                                 />
                                             </List>
                                         </Grid>
                                     </Grid>
                                 </div>
                             </RctCollapsibleCard>
                         </div>
                         <div className="col-sm-12 col-md-6 col-xl-6 monthly-report-outer-container">
                             <RctCollapsibleCard
                                 heading="Timecards"
                                 headingCustomClasses="report-card-header"
                             >
                                 <TimecardReport
                                     weeklyStatistics={weeklyStatistics}
                                     loading={weeklyStatsLoading}
                                 />
                             </RctCollapsibleCard>
                         </div>
                     </div>
                     {isWincantonGroups && (
                         <div className="row ">
                             <div className="col-sm-12 col-md-6 col-xl-6">
                                 <RctCollapsibleCard headingCustomClasses="report-card-header" >
                                    <AWRReport />
                                 </RctCollapsibleCard>
                             </div>
                         </div>
                     )}
 
                 </div>
                 <SweetAlert
                     success
                     confirmBtnCssClass={"btn-orange"}
                     cancelBtnCssClass={"btn-black"}
                     show={alertSuccessful.show}
                     title=""
                     btnSize="sm"
                     onConfirm={() => alertSuccessful.onConfirm()}
                 >
                     <FormattedMessage id={alertSuccessful.messageKey} />
                 </SweetAlert>
 
                 <SweetAlert
                     error
                     confirmBtnCssClass={"btn-orange"}
                     cancelBtnCssClass={"btn-black"}
                     show={alertFailure.show}
                     title=""
                     btnSize="sm"
                     onConfirm={() => alertFailure.onConfirm()}
                 >
                     <FormattedMessage id={alertFailure.messageKey} />
                 </SweetAlert>
 
                 <FormattedMessage id="common.button.yes">
                     {(confirmButtonText) => (
                         <SweetAlert
                             custom
                             customIcon={Warning}
                             confirmBtnCssClass={"btn-orange"}
                             cancelBtnCssClass={"btn-black"}
                             btnSize="sm"
                             show={alertConfirmation.show}
                             showCancel
                             confirmBtnText={confirmButtonText}
                             title=""
                             onConfirm={() => alertConfirmation.onConfirm()}
                             onCancel={() => this.hideAlertConfirmation()}
                         >
                             <FormattedMessage
                                 id={alertConfirmation.messageKey}
                             />
                         </SweetAlert>
                     )}
                 </FormattedMessage>
 
                 {isModalVisible && (
                     <ModalSearchSeeker
                         isModalVisible={isModalVisible}
                         onClose={this.handleModalVisible}
                     />
                 )}
             </div>
         );
     }
 }
 
 const mapStateToProps = ({ report, location }) => ({
     blockedSeekers: report.blockedSeekers,
     topSeekers: report.topSeekers,
     topSeekersOfMonth: report.topSeekersOfMonth,
     rating: report.rating,
     spentTotal: report.spentTotal,
     hourTotal: report.hourTotal,
     listPositions: report.listPositions,
     billingStatistics: report.billingStatistics,
     fulfilmentStatistics: report.fulfilmentStatistics,
     weeklyStatistics: report.weeklyStatistics,
     weeklyStatsLoading: report.weeklyStatsLoading,
     monthlyStatistics: report.monthlyStatistics,
     monthlyChartLoading: report.monthlyChartLoading,
     fulfilmentStatsLoading: report.fulfilmentStatsLoading,
     billingStatsLoading: report.billingStatsLoading,
     listOfLocationDatas: location.allLocation,
     topSeekerLoading: report.topSeekerLoading
 
 });
 
 const mapActionToProps = {
     getBlockedSeekers,
     getRatingOfSeekers,
     getTopSeekersByShifts,
     getBillingReport,
     getWeeklyFulfilmentReport,
     getWeeklyTimecardReport,
     getMonthlyTimecardReport,
     getPositionsStatistics,
     unblockEmployee,
     excelDownloadWeeklyHours,
     showRootSpinner,
     getLocation,
 
 };
 
 export default injectIntl(connect(mapStateToProps, mapActionToProps)(Report));
 
import React, { Component } from "react";
import moment from "moment";
import IntlMessages from "Util/IntlMessages";

import UserDefault from "Assets/img/default-user.png";
import Under18Icon from "Assets/img/image_under_18.png";

class SeekerProfile extends Component {
  constructor(props) {
    super(props);

    this.handleCheckUnder18 = this.handleCheckUnder18.bind(this);
  }

  handleCheckUnder18() {
    const { seeker } = this.props;
    return (
      seeker.date_of_birth &&
      moment().diff(seeker.date_of_birth, "years", true) < 18
    );
  }

  render() {
    const { seeker, showSeekerProfile } = this.props;
    return (
      <div id="profile-tab" className="d-flex">
        {showSeekerProfile && (
          <div className="job-tabs__item--avatar">
            <img
              className="img-fluid"
              src={
                !seeker.profile_thumbnail
                  ? UserDefault
                  : seeker.profile_thumbnail
              }
              alt={seeker.user_fullname}
            />
          </div>
        )}
        <div className="d-flex align-items-center">
          {showSeekerProfile && (
            <div className="job-tabs__item--summary--title">
              <span className="bold font-lg">{seeker.user_fullname}</span>
            </div>
          )}

          <div className="smaller-font">
            <IntlMessages
              id="common.yearExperiences"
              values={{
                years: Number(seeker.experience_years || 0),
              }}
            />
          </div>
          <div className="job-tabs__item--summary--statistic">
            <p className=" d-flex m-0">
              <i
                className="gig-icon icon-jobs smaller bottom no-pointer"
                style={{ width: "15px", height: "15px" }}
              />
              <span style={{ marginTop: "-1px" }}>
                {seeker.total_assignments || 0}
              </span>
            </p>
            <p className="d-flex" style={{ margin: 0, marginLeft: "8px" }}>
              <i
                className="gig-icon icon-thumbs-up bottom no-pointer"
                style={{ width: "15px", height: "15px" }}
              />
              <span style={{ marginTop: "-1px" }}>
                {seeker.total_positive_reviews || 0}
              </span>
            </p>
            {seeker.employer_favourite > 0 && (
              <span>
                <i
                  className="gig-icon icon-favourite smaller bottom no-pointer"
                  style={{ width: "15px", height: "15px" }}
                />
              </span>
            )}
            {seeker.gig_approved > 0 && (
              <span>
                <i
                  className="gig-icon icon-gig-approved smaller bottom no-pointer"
                  style={{ width: "15px", height: "15px" }}
                />
              </span>
            )}
            {this.handleCheckUnder18() && (
              <img
                src={Under18Icon}
                className="gig-icon smaller bottom no-pointer ml-2"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SeekerProfile;

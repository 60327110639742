/**
 * Omni Employer Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import PushNotification from 'PushNotification';
import StoredData from 'Constants/StoredData';
import OAuth from "OAuth";
const oauth = new OAuth();

import { GET_EMPLOYER_LIST, LOGIN_AS } from 'Actions/types';

import {
    getEmployerListSuccess,
    getEmployerListFailure,
    loginAsSuccess,
    loginAsFailure,
    getEmployerDetail,
    removeReducer
} from 'Actions';

import {
    getEmployersListRequest,
    getChosenEmployersListRequest,
    logInAsRequest,
    logInAsChosenProviderRequest,
} from "Services/omni/EmployerService";

const pushnotification = new PushNotification();
/**
 * Get Employer List From Server
 */
function* hanldeGetEmployerList() {
    try {
    const employerRole = Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID));
        
        //case super admin can see all of employers
        if(employerRole == oauth.isOmniStaff()) {
            const response = yield call(getEmployersListRequest);
            yield put(getEmployerListSuccess(response));
        }

        //case employer admin only show list of chosen employers
        if(employerRole == oauth.isEmployerAdmin()) {
            const response = yield call(getChosenEmployersListRequest);
            yield put(getEmployerListSuccess(response));
        }
    } catch (error) {
        yield put(getEmployerListFailure(error));
    }
}

/**
 * Login As From Server
 */
function* handlelogInAs({ payload }) {
    try {
        const employerRole = Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID));
        const { email, history } = payload;  
        
        yield put(removeReducer());
        const response = employerRole == oauth.isOmniStaff() ? yield call(logInAsRequest, {email})
                                                             : yield call(logInAsChosenProviderRequest, {email});
        pushnotification.unsubscribeJobResponseChanel(StoredData.get(StoredData.KEYS.LOGGED_IN_AS));
        pushnotification.subscribeJobResponseChanel(email);
        yield put(loginAsSuccess(response));
        yield put(getEmployerDetail(history));
        history.push('/');
    } catch (error) {
        yield put(loginAsFailure(error));
    }
}
 

/**
 * Get Employer List
 */
export function* watchGetEmployerList() {
    yield takeEvery(GET_EMPLOYER_LIST, hanldeGetEmployerList);
}

/** 
 * Login As
 */
export function* watchLoginAs() {
    yield takeEvery(LOGIN_AS, handlelogInAs);
}

/**
 * Employer List Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchGetEmployerList),
        fork(watchLoginAs),
    ]);
}

import React from 'react'
import DetailConversation from './DetailConversation'
import ListOfPositions from './ListOfPositions'
import Button from "@material-ui/core/Button";
import MyGig from "Constants/MyGig";

const DELETE_STATUS = 3 
const DirectMessage = ({
    openConversation,
    newMessage,
    conversations,
    messages,
    openConversationPage,
    isLoading,
    onUploadFile,
    switchConversation,
    senderId,
    getAllJobDetail,
}) => {
    let checkEmptyList = true;
    conversations.map(conversation => {
        if(conversation.status != DELETE_STATUS) {
            checkEmptyList = false
        } 
    })
    if(checkEmptyList) openConversation = "new-message"
    return (
        <div className="message-modal-body clearfix">

            <div className="p-0 pull-left left-bar" style={{ width: "300px" }}>
                <Button
                    onClick={() => {
                        if (openConversation === 'new-message') {
                            return switchConversation(messages[0].conversation_id || 'new-message')
                        }
                        return switchConversation("new-message")
                    }}
                    className="btn-new-message">
                    New message +
                </Button>

                <ListOfPositions
                    switchConversation={switchConversation}
                    openConversation={openConversation}
                    newMessage={newMessage}
                    lists={conversations}
                    getAllJobDetail={getAllJobDetail}
                />
            </div>
            <div className="p-0 pull-left" style={{ width: "calc(100% - 300px)" }}
            >   
                {messages && (
                
                    <DetailConversation
                        senderId={senderId}
                        switchConversation={switchConversation}
                        list={conversations}
                        openConversation={openConversation}
                        onLoading={isLoading}
                        ignoreAutoToBottom={openConversationPage > 1}
                        messages={messages}
                        onUploadFile={onUploadFile}
                        checkEmptyList = {checkEmptyList}
                    />
                )}
            </div>
        </div>
    )
}

export default DirectMessage
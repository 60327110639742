import React, { Component } from "react";
import { injectIntl } from "react-intl";

import Popover from "@material-ui/core/Popover";

import { getImageOfUniform } from "Helpers/helpers";
import moment from 'moment'

class JobDetails extends Component {
    getUniformsData() {
        const { job } = this.props;
        let images = [];
        let names = [];
        const other = job.uniform_other;
        if (job.uniform_image && job.uniform_name) {
            images = job.uniform_image
                .split(",")
                .map((image) => image.trim())
                .filter((image) => Boolean(image) === true);
            names = job.uniform_name
                .split(",")
                .map((name) => name.trim())
                .filter((name) => Boolean(name) === true);
        }
        return { images, names, other };
    }

    render() {
        const { isOpen, anchorEl, intl, job } = this.props;
        const uniforms = this.getUniformsData();
        return (
            <Popover
                disableEnforceFocus
                open={isOpen}
                anchorEl={anchorEl}
                onClose={() => this.props.onClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                classes={{
                    paper: "MuiPopover-job-detail",
                }}
            >
                <div className="job-details-wrapper">
                    
                    <div className="job-details-wrapper__header">
                        <i className="material-icons">info_outline</i>
                    </div>

                    <div className="clearfix">
                        <div
                            className="col-half border-right"
                            style={{ padding: "0 20px" }}
                        >
                            <p className="font-bold m-0 mb-5">
                                {intl.formatMessage({
                                    id: "jobDetails.jobDescription",
                                })}
                            </p>
                            <div className="text-content">
                                {job.position_description || job.description}
                            </div>
                        </div>

                        <div className="col-half" style={{ padding: "0 20px" }}>
                            <p className="font-bold m-0 mb-5">
                                {intl.formatMessage({
                                    id: "jobDetails.specialRequirements",
                                })}
                            </p>
                            <div className="text-content">
                                {Boolean(job.special_requirements) === true && (
                                    <span>{job.special_requirements}</span>
                                )}

                                {Boolean(job.special_requirements) ===
                                    false && (
                                    <span>
                                        {intl.formatMessage({
                                            id:
                                                "jobDetails.empty.specialRequirements",
                                        })}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="clearfix vertical-space-small">
                        <div className="col-full">
                            <div className="row">
                                <div className="col-sm-5 d-block p-0">
                                    <p className="font-bold m-0 mb-5">
                                        {intl.formatMessage({
                                            id: "jobDetails.uniforms",
                                        })}
                                    </p>
                                    <div className="text-content">
                                        {!uniforms.other &&
                                            uniforms.names.length === 0 && (
                                                <span>
                                                    {intl.formatMessage({
                                                        id:
                                                            "jobDetails.empty.uniforms",
                                                    })}
                                                </span>
                                            )}

                                        {uniforms.other && (
                                            <span>
                                                <strong>Other: </strong>
                                                {uniforms.other}
                                            </span>
                                        )}

                                        {uniforms.names.length > 0 && (
                                            <div className="uniform-names">
                                                {uniforms.names.map(
                                                    (name, index) => (
                                                        <div
                                                            className="item"
                                                            key={`uniform-name-${index}`}
                                                        >
                                                            {`${
                                                                index + 1
                                                            }. ${name}`}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-7 d-block">
                                    {uniforms.images.length > 0 && (
                                        <div className="row uniform-images">
                                            {uniforms.images.map(
                                                (image, index) => (
                                                    <div
                                                        className="col-sm-4 item"
                                                        key={`uniform-image-${index}`}
                                                    >
                                                        {getImageOfUniform(
                                                            image
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-3 text-secondary text-right">
                        <small>Created at: {moment(job.createdAt).format('DD MMM YYYY hh:mm:ss')} by {job.createdName}</small>
                        <small>Updated at: {moment(job.updatedAt).format('DD MMM YYYY hh:mm:ss')} by {job.updatedName}</small>
                    </div>
                </div>
            </Popover>
        );
    }
}

export default injectIntl(JobDetails);

/**
 * Excel actions (get, export, download, etc)
 */
 import {
    EXCEL_DOWNLOAD_REPORTS,
    EXCEL_DOWNLOAD_FULFILMENT_REPORTS,
    EXCEL_DOWNLOAD_BILLING_REPORTS,
    EXCEL_DOWNLOAD_AWR_REPORTS,
    EXCEL_DOWNLOAD_TIMESHEET_PENDING,
    EXCEL_DOWNLOAD_TIMESHEET_PENDING_SUCCESS,
    EXCEL_DOWNLOAD_TIMESHEET_APPROVED,
    EXCEL_DOWNLOAD_TIMESHEET_APPROVED_SUCCESS,
    EXCEL_DOWNLOAD_TIMESHEET_WEEKLY,
    EXCEL_DOWNLOAD_TIMESHEET_WEEKLY_SUCCESS,
    EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY,
    EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY_SUCCESS,
    EXCEL_DOWNLOAD_PENDING_JOB_DAILY,
    EXCEL_DOWNLOAD_PENDING_JOB_DAILY_SUCCESS,
    EXCEL_DOWNLOAD_CONFIRMED,
    EXCEL_DOWNLOAD_CONFIRMED_SUCCESS,
    EXCEL_DOWNLOAD_APPLIED,
    EXCEL_DOWNLOAD_APPLIED_SUCCESS,
} from './types';

export const excelDownloadTimesheetPending = (startDate, endDate, internal, locationId) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_PENDING,
    payload: {startDate, endDate, internal, locationId}
});

export const excelDownloadTimesheetPendingSuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_PENDING_SUCCESS,
    payload: response
});
export const excelDownloadTimesheetApproved = (startDate, endDate, locationId) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_APPROVED,
    payload: {startDate, endDate, locationId}
});

export const excelDownloadTimesheetApprovedSuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_APPROVED_SUCCESS,
    payload: response
});

export const excelDownloadTimesheetWeekly = (startDate, endDate) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_WEEKLY,
    payload: {startDate, endDate}
});

export const excelDownloadTimesheetWeeklySuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_TIMESHEET_WEEKLY_SUCCESS,
    payload: response
});

export const excelDownloadUpcomingJobDaily = (date, jobType) => ({
    type   : EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY,
    payload: {date, jobType}
});

export const excelDownloadUpcomingJobDailySuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY_SUCCESS,
    payload: response
});

export const excelDownloadPendingJobDaily = (date) => ({
    type   : EXCEL_DOWNLOAD_PENDING_JOB_DAILY,
    payload: {date}
});

export const excelDownloadPendingJobDailySuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_PENDING_JOB_DAILY_SUCCESS,
    payload: response
});

export const excelDownloadApplied = (positionId, type) => ({
    type   : EXCEL_DOWNLOAD_APPLIED,
    payload: {positionId, type}
});

export const excelDownloadAppliedSuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_APPLIED_SUCCESS,
    payload: response
});

export const excelDownloadConfirmed = (positionId, type) => ({
    type   : EXCEL_DOWNLOAD_CONFIRMED,
    payload: {positionId, type}
});

export const excelDownloadConfirmedSuccess = (response) => ({
    type   : EXCEL_DOWNLOAD_CONFIRMED_SUCCESS,
    payload: response
});

export const excelDownloadReports = (startDate, endDate, locationId) => ({
    type   : EXCEL_DOWNLOAD_REPORTS,
    payload: {startDate, endDate, locationId}
});

export const excelDownloadFulfilmentReports = (startDate, endDate) => ({
    type   : EXCEL_DOWNLOAD_FULFILMENT_REPORTS,
    payload: {startDate, endDate}
});

export const excelDownloadBillingReports = (startDate, endDate) => ({
    type   : EXCEL_DOWNLOAD_BILLING_REPORTS,
    payload: {startDate, endDate}
});

export const excelDownloadAWRReports = () => ({
    type   : EXCEL_DOWNLOAD_AWR_REPORTS,
});


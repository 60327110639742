import React, {Component} from 'react';
import {injectIntl}       from 'react-intl';
import {Doughnut}         from 'react-chartjs-2';
import ChartConfig        from 'Constants/chart-config';
import AppConfig          from 'Constants/AppConfig';

const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        position: 'left',
        labels: {
            fontColor: ChartConfig.legendFontColor
        }
    }
};

class DoughnutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listPositions: [],
            listLabels: [],
            dataSets: [{
                data: [],
                backgroundColor: [
                    AppConfig.appColors.purple,
                    AppConfig.appColors.orange,
                    AppConfig.appColors.turquoise,
                    AppConfig.appColors.salmon,
                    AppConfig.appColors.green
                ],
                hoverBackgroundColor: [
                    AppConfig.appColors.purple,
                    AppConfig.appColors.orange,
                    AppConfig.appColors.turquoise,
                    AppConfig.appColors.salmon,
                    AppConfig.appColors.green
                ]
            }]
        };
        options.tooltips = {
            callbacks: {
                label(tooltipItem, data) {
                    const itemIndex = tooltipItem.index;
                    return `${data.labels[tooltipItem.index]  }: ${  props.intl.formatMessage({id: 'common.hoursWithoutSpace'}, {
                        hours: data.datasets[0].data[itemIndex]
                    })}`;
                }
            }
        };
    }

    UNSAFE_componentWillMount() {
        this.updateChartData(this.props.listPositions);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateChartData(nextProps.listPositions);
    }

    updateChartData(listPositions) {
        const dataSets   = [];
        const listLabels = [];

        listPositions.map((position, index) => {  // eslint-disable-line
            const hours = Math.round((position.totalSeconds / 3600) * 100) / 100;

            if (index <= 4) {
                dataSets.push(hours);
                listLabels.push(position.skillName);
            }
        });

        this.state.listLabels       = listLabels; // eslint-disable-line
        this.state.dataSets[0].data = dataSets;  // eslint-disable-line

        this.forceUpdate();
    }

    render() {
        const { listLabels, dataSets } = this.state;

        return (
            <Doughnut data={{labels: listLabels, datasets: dataSets}} options={options} />
        );
    }
}

export default injectIntl(DoughnutComponent);

/**
 * Location Sagas
 */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    CLOSE_CONVERSAION,
    OPEN_CONVERSATION,
    DIRECT_MESSAGE_CREATE_CONVERSATION, GET_CONVERSATION,
    DELETE_CONVERSAION
} from 'Actions/types';

import {
    showErrorResponse,
    openDeleteConversationSuccess,
    createConversationSuccess,
    createConversationFailure,
    getConversationSuccess,
    getConversationList,
    closeConversationSuccess, 
    getDirectMessage,
    deleteConversationSuccess,
} from 'Actions';

import {
    createConversationRequest,
    getConversationRequest,
    closeConversationRequest,
    deleteConversationRequest,
    openConversationRequest
} from 'Services/omni/ConversationService';


import { sortConversations } from "Helpers/helpers"

/**
 * Get Location From Server
 */
function* handleAddConversation({ payload }) {
    try {
        const { params } = payload;
        const response = yield call(createConversationRequest, params);
        if (isSuccess(response)) {
            yield put(createConversationSuccess(response));
            yield put(getDirectMessage(response.data.data.id, true))
        } else {
            yield put(createConversationFailure(response.response.data.error.message));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}


function* handleGetConversation({ payload }) {
    try {
        const {isNotSameConversation, initial, page } = payload;
        let params = {
            page: page
        }
        const response = yield call(getConversationRequest, params);
        if (isSuccess(response)) {
            yield put(getConversationSuccess(response.data.data, page, isNotSameConversation));
            const conversations = sortConversations(response.data.data)
            const firstConversation = conversations[0];
            if (initial && firstConversation) {
                yield put(getDirectMessage(firstConversation.id));
            }

        }
    } catch (error) {
        yield put(showErrorResponse(error));

    }

}

function* handleCloseConversation({ payload }) {
    try {
        const { conversationId } = payload;
        const response = yield call(closeConversationRequest, conversationId);
        if (isSuccess(response)) {
            yield put(closeConversationSuccess());
            yield put(getConversationList());
        }

    } catch (error) {
        yield put(showErrorResponse(error));

    }
}
function* handleOpenConversation({ payload}) {
    try {
        const { conversationId} = payload;
        const response = yield call(openConversationRequest, conversationId);
        if (isSuccess(response)) {
            yield put(getConversationList());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDeleteConversation({ payload }) {
    try {
        const { conversationId } = payload;
        const response = yield call(deleteConversationRequest, conversationId);
        if (isSuccess(response)) {
            yield put(deleteConversationSuccess());
            yield put(openDeleteConversationSuccess());
            yield put(getConversationList(true));
        }

    } catch (error) {
        yield put(showErrorResponse(error));

    }
}

/**
 * Handle Add conversation
 */
export function* watchAddConversation() {
    yield takeEvery(DIRECT_MESSAGE_CREATE_CONVERSATION, handleAddConversation);
}

export function* watchGetConversation() {
    yield takeEvery(GET_CONVERSATION, handleGetConversation);
}

export function* watchCloseConversation() {
    yield takeEvery(CLOSE_CONVERSAION, handleCloseConversation);
}

export function* watchDeletConversation() {
    yield takeEvery(DELETE_CONVERSAION, handleDeleteConversation);
}
export function* watchOpenConversation() {
    yield takeEvery(OPEN_CONVERSATION, handleOpenConversation);

}

export default function* rootSaga() {
    yield all([
        fork(watchAddConversation),
        fork(watchGetConversation),
        fork(watchCloseConversation),
        fork(watchDeletConversation),
        fork(watchOpenConversation)
    ]);
}

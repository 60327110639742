/**
 * Location Sagas
 */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    CREATE_NEW_EMPLOYER,
    UPDATE_EMPLOYER

} from 'Actions/types';

import {
    createNewEmployerSuccess,
    createNewEmployerFailure,
    updateEmployerFailure,
    updateEmployerSuccess,
    updateListProviderSuccess
    

} from 'Actions';

import {
    
    newEmployerRequest,
    updateEmployerRequest

} from 'Services/omni/EmployerService';


function* handleCreateEmployer({ payload }) {
    try {
    
        const { params } = payload;
        const response = yield call(newEmployerRequest, params);
    
        if (isSuccess(response)) {
            yield put(createNewEmployerSuccess(response.data.data));
    
        }
    } catch (error) {
        yield put(createNewEmployerFailure(error));
    }
}

function* handleUpdateEmployer({ payload }) {
    try {

        const { params } = payload;
        const response = yield call(updateEmployerRequest, params);

        if (isSuccess(response)) {
            yield put(updateEmployerSuccess(response.data));
            yield put(updateListProviderSuccess(params));

        }
    } catch (error) {
        yield put(updateEmployerFailure(error));
    }
}



export function* watchCreateEmployer() {
    yield takeLatest(CREATE_NEW_EMPLOYER, handleCreateEmployer);
}

export function* watchUpdateEmployer() {
    yield takeLatest(UPDATE_EMPLOYER, handleUpdateEmployer);
}

export default function* rootSaga() {
    yield all([
        
        fork(watchCreateEmployer),
        fork(watchUpdateEmployer)
    ]);
}

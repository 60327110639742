import API from 'Api';

/**
 * Get card infor of employer
 *
 * @return async
 */
export const getCardRequest = async () =>
    await API.get('/employers/card')
        .then(response => response)
        .catch(error => error);

/**
 * Get Stripe key
 *
 * @return async
 * @public
 */
export const getStripeKeyRequest = async () =>
    await API.get('/employers/stripe/key')
        .then(response => response)
        .catch(error => error);

/**
 * Add new card for employer
 *
 * @param  {String} cardToken
 * @typedef {
       card_token: string
    }
 * @return async
 */
export const addCardRequest = async  (cardToken) =>
    await API.put('/employers/create', cardToken);

/**
 * Update card of employer
 * @typedef {
       card_token: string
    }
 * @param  {String} cardToken
 * @return async
 */
export const updateCardRequest = async  (cardToken) =>
    await API.put('/employers/update', cardToken);

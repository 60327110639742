/**
 * Check Card Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';
import MyGig from 'Constants/MyGig';
import {
    CARD_CHECK_VALID,
    GET_CARD,
    CARD_CREATE,
    CARD_UPDATE,
} from 'Actions/types';

import {
    showErrorResponse,
    checkCardValidSuccess,
    checkCardValidFailure,
    createInvalidaCard,
    getCardDetailFailure,
    createCardSuccess,
    updateCardSuccess
} from 'Actions';

import {
    getWagesInstantPayment
} from "Services/employers/WageService";

import {
    getCardRequest,
    getStripeKeyRequest,
    addCardRequest,
    updateCardRequest
} from "Services/employers/CardService";

function* handleCheckCardPayment() {
    try {
        const response = yield call(getWagesInstantPayment);
        let instantPayment;
        if (response.data && isSuccess(response)) {
            instantPayment = Number(response.data.data.wages_instant_payment);
        } else {
            instantPayment = 1;
        }
        if (instantPayment === 1) {
            const checkCardResponse = yield call(getCardRequest);
            if (checkCardResponse.data && isSuccess(checkCardResponse)) {
                const cardData = checkCardResponse.data.data;
                if (!cardData.last_4) {
                    yield put(checkCardValidSuccess({cardData, isValid: false}));
                } else {
                    yield put(checkCardValidSuccess({cardData, isValid: true}));
                }
            } else {
                yield put(checkCardValidFailure(response));
                yield put(showErrorResponse(response));
            }
        } else {
            yield put(checkCardValidSuccess({cardData: null, isValid: true}));
        }
    } catch (error) {
        yield put(checkCardValidFailure(error));
        yield put(showErrorResponse(error));
    }
}

function* handleGetCard() {
    const checkCardResponse = yield call(getCardRequest);
    try {
        if (checkCardResponse.data && isSuccess(checkCardResponse)) {
            const cardData = checkCardResponse.data.data;
            if (!cardData.last_4) {
                yield put(checkCardValidSuccess({cardData, isValid: false}));
            } else {
                yield put(checkCardValidSuccess({cardData, isValid: true}));
            }
        } else {
            yield put(getCardDetailFailure(MyGig.cardStatus.COULD_NOT_GET_CARD_DETAIL));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleCreateCardPayment({payload}) {
    const getStripeApiKey = yield call(getStripeKeyRequest);
    try {
        if (getStripeApiKey.data && isSuccess(getStripeApiKey)) {
            const key = getStripeApiKey.data.data.key;
            window.Stripe.setPublishableKey(key);
            const card = {
                name: payload.cardName,
                cvc: payload.cvc,
                exp_month: payload.exp_month,
                exp_year: payload.exp_year,
                number: payload.cardNumber1 + payload.cardNumber2 + payload.cardNumber3 + payload.cardNumber4
            };
            const promise = new Promise(((resolve, reject) => {
                window.Stripe.card.createToken(card, (status, response) => {
                    resolve(response);
                });
            }));

            const resolvePromise = yield Promise.all([promise]);
            if (resolvePromise[0].error) {
                const error = resolvePromise[0].error;
                yield put(createInvalidaCard(error.code));
            }
            else {
                try {
                    const response = yield call(addCardRequest, {card_token: resolvePromise[0].id});
                    if (response.data && isSuccess(response)) {
                        yield put(createCardSuccess());
                    } else {
                        yield put(createInvalidaCard(MyGig.cardStatus.COULD_NOT_ADD_CARD));
                    }
                } catch (e) {
                    yield put(createInvalidaCard(MyGig.cardStatus.COULD_NOT_ADD_CARD));
                }
            }
        } 
    } catch(error) {
        yield put(showErrorResponse(error));
    }
}

function* handleUpdateCardInfo({payload}) {
    const getStripeApiKey = yield call(getStripeKeyRequest);
    const { cardName, cvc, exp_month, exp_year, cardNumber1, cardNumber2, cardNumber3, cardNumber4 } = payload;

    try {
        if (getStripeApiKey.data && isSuccess(getStripeApiKey)) {
            const key = getStripeApiKey.data.data.key;
            window.Stripe.setPublishableKey(key);

            const card = {
                name: cardName,
                cvc,
                exp_month,
                exp_year,
                number: cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4
            };
            const promise = new Promise(((resolve, reject) => {
                window.Stripe.card.createToken(card, (status, response) => {
                    resolve(response);
                });
            }));
            const resolvePromise = yield Promise.all([promise]);
            if (resolvePromise[0].error) {
                const error = resolvePromise[0].error;
                yield put(createInvalidaCard(error.code));
            }
            else {
                const cardToken = resolvePromise[0].id;
                try {
                    const response = yield call(updateCardRequest, {card_token: cardToken});
                    if (response.data && isSuccess(response)) {
                        yield put(updateCardSuccess());
                    } else {
                        yield put(createInvalidaCard(MyGig.cardStatus.COULD_NOT_ADD_CARD));
                    }
                } catch (e) {
                    yield put(createInvalidaCard(MyGig.cardStatus.COULD_NOT_ADD_CARD));
                }
            }
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

export function* watchAddCardPayment() {
    yield takeEvery(CARD_CREATE, handleCreateCardPayment);
}

export function* watchCheckCardPaymentValid() {
    yield takeEvery(CARD_CHECK_VALID, handleCheckCardPayment);
}

export function* watchGetCardPayment() {
    yield takeEvery(GET_CARD, handleGetCard);
}

export function* watchUpdateCardPayment() {
    yield takeEvery(CARD_UPDATE, handleUpdateCardInfo);
}

export default function* rootSaga() {
    yield all([
        fork(watchCheckCardPaymentValid),
        fork(watchGetCardPayment),
        fork(watchAddCardPayment),
        fork(watchUpdateCardPayment),
    ]);
}

/**
 * BusinessDetail Sagas
 */
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_BUSINESS_DETAIL_SETTING, 
    UPDATE_BUSINESS_DETAIL_SETTING,
} from 'Actions/types';

import {
    showErrorResponse,
    getBusinessDetailSuccess,
    getLoginDetailSuccess,
    updateBusinessDetailSuccess,
    updateBusinessDetailFail,
} from 'Actions';

import {
    getBusinessDetailInfoRequest, 
    updateBusinessDetailInfoRequest
} from 'Services/employers/BusinessDetailService';

import { 
    getLoginDetailInfoRequest
} from 'Services/employers/LoginDetailService';


function* handleGetBusinessDetail() {
    const response = yield call(getBusinessDetailInfoRequest);
    try {
        if (isSuccess(response)) {
            yield put(getBusinessDetailSuccess(response.data));
            const responseUserData = yield call(getLoginDetailInfoRequest);
            if (isSuccess(responseUserData)) {
                yield put(getLoginDetailSuccess(responseUserData.data));
            }
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleUpdateBusinessDetail({data}) {
    const {businessDetail} = data;
    const response = yield call(updateBusinessDetailInfoRequest, businessDetail);
    try {
        if (isSuccess(response)) {
            yield put(updateBusinessDetailSuccess(response));
        }
        else {
            yield put(updateBusinessDetailFail(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(updateBusinessDetailFail(response));
        yield put(showErrorResponse(error));
    }

}

export function* watchGetBusinessDetail() {
    yield takeEvery(GET_BUSINESS_DETAIL_SETTING, handleGetBusinessDetail);
}

export function* watchUpdateBusinessDetail() {
    yield takeEvery(UPDATE_BUSINESS_DETAIL_SETTING, handleUpdateBusinessDetail);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetBusinessDetail),
        fork(watchUpdateBusinessDetail)
    ]);
}

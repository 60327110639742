/**
 * Notification actions
 */
import {
    NOTIFICATION_SHOW_PANEL,
    NOTIFICATION_HIDE_PANEL,
    NOTIFICATION_LOAD_NOTIFICATIONS,
    NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE,
    NOTIFICATION_MARK_AS_READ,
    NOTIFICATION_MARK_AS_READ_SUCCESS,
    NOTIFICATION_MARK_AS_READ_FAILURE,
    NOTIFICATION_REMOVE_NOTIFICATION,
    NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS,
    NOTIFICATION_REMOVE_NOTIFICATION_FAILURE,
    NOTIFICATION_GET_NUMBER_UNREAD,
    NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS,
    NOTIFICATION_GET_NUMBER_UNREAD_FAILURE,
    NOTIFICATION_LOAD_JOB_DETAILS,
    NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS,
    NOTIFICATION_LOAD_JOB_DETAILS_FAILURE,
    NOTIFICATION_RESET_JOB_DETAILS
} from "./types";

export const showNotificationPanel = () => ({
    type: NOTIFICATION_SHOW_PANEL,
});

export const hideNotificationPanel = () => ({
    type: NOTIFICATION_HIDE_PANEL,
});

export const getNotifications = (perPage, lastId) => ({
    type: NOTIFICATION_LOAD_NOTIFICATIONS,
    payload: { perPage, lastId },
});

export const getNotificationsSuccess = (response) => ({
    type: NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS,
    payload: response,
});

export const getNotificationsFailure = (error) => ({
    type: NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE,
    payload: error,
});

export const markNotificationAsRead = (notificationId) => ({
    type: NOTIFICATION_MARK_AS_READ,
    payload: { notificationId },
});

export const markNotificationAsReadSuccess = (notificationId) => ({
    type: NOTIFICATION_MARK_AS_READ_SUCCESS,
    payload: notificationId,
});

export const markNotificationAsReadFailure = (error) => ({
    type: NOTIFICATION_MARK_AS_READ_FAILURE,
    payload: error,
});

export const removeNofitication = (notificationId, isRead) => ({
    type: NOTIFICATION_REMOVE_NOTIFICATION,
    payload: { notificationId, isRead },
});

export const removeNofiticationSuccess = (notificationId, isRead) => ({
    type: NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS,
    payload: { notificationId, isRead },
});

export const removeNofiticationFailure = (error) => ({
    type: NOTIFICATION_REMOVE_NOTIFICATION_FAILURE,
    payload: error,
});

export const getNumberUnread = (notificationId) => ({
    type: NOTIFICATION_GET_NUMBER_UNREAD,
});

export const getNumberUnreadSuccess = (response) => ({
    type: NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS,
    payload: response,
});

export const getNumberUnreadFailure = (error) => ({
    type: NOTIFICATION_GET_NUMBER_UNREAD_FAILURE,
    payload: error,
});

export const resetJobDetails = () => ({
    type: NOTIFICATION_RESET_JOB_DETAILS,
});

export const getJobDetailsAfterClicked = (positionId, history) => ({
    type: NOTIFICATION_LOAD_JOB_DETAILS,
    payload: { positionId, history },
});

export const getJobDetailsAfterClickedSuccess = (response) => ({
    type: NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS,
    payload: response,
});

export const getJobDetailsAfterClickedFailure = (error) => ({
    type: NOTIFICATION_LOAD_JOB_DETAILS_FAILURE,
    payload: error,
});

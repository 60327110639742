import React, { Component, Fragment } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { roundByDecimals } from "Helpers/helpers";
import IntlMessages from "Util/IntlMessages";
import DownloadExcelReport from "Routes/reports/partials/DownloadExcelReport";

class TimecardReport extends Component {
  renderRows() {
    const { weeklyStatistics } = this.props;
    const rows = [];

    if (Object.keys(weeklyStatistics).length > 0) {
      weeklyStatistics.unixWeeks.map((unixWeek, index) => {
        const weekStart = moment(unixWeek.startWeek);
        const weekEnd = moment(unixWeek.endWeek);
        // console.log(unixWeek.startWeek, unixWeek.endWeek);
        const weekPickerRange = {};

        const locationId = null;
        rows.push(
          <TableRow hover key={`weekly-report-row-${index}`}>
            <TableCell className="flex-group">
              <span className="week-range">
                {weekStart.format("DD MMM")} - {weekEnd.format("DD MMM")}
              </span>
            </TableCell>

            <TableCell className="revenue">{/* empty column */}</TableCell>
            <TableCell className="">
              <DownloadExcelReport
                start={weekStart}
                end={weekEnd}
                locationId={locationId}
                reportType={"timecard"}
              />
            </TableCell>
          </TableRow>
        );

        return true;
      });
    }

    if (rows.length === 0 && this.props.loading === false) {
      return (
        <div className="">
          <p className="empty-data-report">
            <IntlMessages id="reports.empty.weeklyReports" />
          </p>
        </div>
      );
    }

    if (this.props.loading === true) {
      return (
        <div className="wrapper">
          <CircularProgress
            className="progress-warning"
            size={70}
            mode="determinate"
            value={40}
            min={0}
            max={50}
          />
        </div>
      );
    }

    return (
      <Table>
        <TableBody className="report-table">
          <Fragment>{rows}</Fragment>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div className="report-table-container weekly-report-wrapper">
        {this.renderRows()}
      </div>
    );
  }
}

export default TimecardReport;

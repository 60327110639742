/**
 * Helper class support get color & class from job skill role
 */

import AppConfig from 'Constants/AppConfig';
export default {
    getColor: (skillRole) => {
        if(skillRole === 21) {
            return '#000';
        }
        return AppConfig.appColors.orange;
        switch (skillRole) {
            // Old skill types
            case 10: return '#db6f5c'; // Front of House
            case 11: return '#db6f5c'; // Back of House
            case 12: return '#edb14f';
            case 13: return '#db6f5c'; // Back of House
            case 14: return '#db6f5c'; // Back of House
            case 16: return '#db6f5c'; // Back of House
             // Housekeeping/Cleaning
            
            // New skill types
            
            case 17: return '#88609d'; 
            case 19: return '#79c2c9';
            case 23: return '#79c2c9';

            case 20: return '#88609d'; // Retail
            case 21: return '#000';
            // Other types
            default: return '#6192c9';
        }
    },
    getClass: (skillRole) => {
        switch (skillRole) {
            case 1: return 'gig-skill-role-1';
            case 2: return 'gig-skill-role-2';
            case 3: return 'gig-skill-role-3';
            case 5: return 'gig-skill-role-5';
            case 6: return 'gig-skill-role-6';
            case 7: return 'gig-skill-role-7';
            case 8: return 'gig-skill-role-8';
            case 9: return 'gig-skill-role-9';
            default: return 'gig-skill-role-other';
        }
    }
};

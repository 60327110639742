/**
 * Definition key codes
 */
export default {
    BACKSPACE: 8,
    ENTER    : 13,
    SHIFT    : 16,
    CONTROL  : 17,
    ALT      : 18,
    ESCAPE   : 27,
    SPACE    : 32
};

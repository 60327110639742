import API from 'Api';

/**
 * Get list of all messages from all positions
 *
 * @return async
 */
export const getAllMessagesRequest = async () =>
    await API.get('/employers/notes')
        .then(response => response)
        .catch(err => err);

/**
 * Get list of read messages from all positions
 *
 * @return async
 */
export const getAllReadMessagesRequest = async () =>
    await API.get('/employers/read_notes')
        .then(response => response)
        .catch(err => err);

/**
 * Get list of unread messages from all positions
 *
 * @return async
 */
export const getAllUnreadMessagesRequest = async () =>
    await API.get('/employers/unread_notes')
        .then(response => response)
        .catch(err => err);

/**
 * Get list of messages by specific position
 *
 * @param  {Number} positionId
 * @return async
 */
export const getMessagesOfPositionRequest = async (positionId) =>
    await API.get(`/employers/positions/${  positionId  }/conversations`)
        .then(response => response)
        .catch(err => err);

/**
 * Send new message to position
 *
 * @param  {Number} positionId
 * @param  {String} messageText
 * @return async
 */
export const addMessageToPositionRequest = async (positionId, messageData) =>
    await API.post(`/mywork/positions/${  positionId  }/notes`, messageData)
        .then(response => response)
        .catch(err => err);

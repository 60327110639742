/**
 * Invoice Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_INVOICES,
    EXPORT_PDF,
    SEND_INVOICE_MAIL,
    GET_ALL_INVOICES,
} from 'Actions/types';

import { getInvoicesSuccess, getPdfInvoiceSuccess, sendEmailInvoiceSuccess, showErrorResponse } from 'Actions';

import { getInvoicesRequest, exportInvoiceRequest, sendInvoiceRequest } from 'Services/employers/InvoicesService';

function* handleGetInvoices(action) {
    const { duration } = action.payload;
    const response = yield call(getInvoicesRequest, duration);
    try {
        if (isSuccess(response)) {
            yield put(getInvoicesSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleGetExportInvoices(action) {
    const { invoiceId } = action.payload;
    const response = yield call(exportInvoiceRequest, invoiceId);
    try {
        if (isSuccess(response)) {
            yield put(getPdfInvoiceSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleSendInvoiceMail (action) {
    const { invoiceId } = action.payload;
    const response = yield call(sendInvoiceRequest, invoiceId);
    try {
        if (isSuccess(response)) {
            yield put(sendEmailInvoiceSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleGetAllInvoice() {
    const response = yield call(getInvoicesRequest, { allInvoice: 1 });
    try {
        if (isSuccess(response)) {
            yield put(getInvoicesSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

export function* watchGetInvoices() {
    yield takeEvery(GET_INVOICES, handleGetInvoices);
}

export function* watchExportPdf() {
    yield takeEvery(EXPORT_PDF, handleGetExportInvoices);
}

export function* watchSendInvoiceMail() {
    yield takeEvery(SEND_INVOICE_MAIL, handleSendInvoiceMail);
}

export function* watchGetAllInvoices() {
    yield takeEvery(GET_ALL_INVOICES, handleGetAllInvoice);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetInvoices),
        fork(watchExportPdf),
        fork(watchSendInvoiceMail),
        fork(watchGetAllInvoices)
    ]);
}

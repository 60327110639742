/**
 * Master Rate Actions
 */
import {
    GET_MASTER_RATE,
    GET_MASTER_RATE_SUCCESS,
    GET_MASTER_RATE_FAILURE,
    EDIT_MASTER_RATE,
    EDIT_MASTER_RATE_SUCCESS,
    EDIT_MASTER_RATE_FAILURE
} from './types';


/**
 * Action get master rate to server
 */
export const getMasterRate = (masterRate) => ({
    type: GET_MASTER_RATE,
    payload: masterRate,
});

export const getMasterRateSuccess = (response) => ({
    type: GET_MASTER_RATE_SUCCESS,
    payload: response
});

export const getMasterRateFailure = (error) => ({
    type: GET_MASTER_RATE_FAILURE,
    payload: error
});

/**
 * Action edit master rate to server
 */
export const editMasterRate = () => ({
    type: EDIT_MASTER_RATE
});

export const editMasterRateSuccess = (response) => ({
    type: EDIT_MASTER_RATE_SUCCESS,
    payload: response
});

export const editMasterRateFailure = (error) => ({
    type: EDIT_MASTER_RATE_FAILURE,
    payload: error
});
/**
 * Redux Modal All Jobs Actions
 */
import {
    MODAL_ALL_JOBS_OPEN,
    MODAL_ALL_JOBS_CLOSE
} from './types';

export const openModalAllJobs = () => ({
    type: MODAL_ALL_JOBS_OPEN
});

export const closeModalAllJobs = () => ({
    type: MODAL_ALL_JOBS_CLOSE
});

/**
 * Split shifts Reducer
 */
import {
    SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP,
    SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP,
    SPLIT_SHIFTS_DO_SPLIT,
    SPLIT_SHIFTS_DO_SPLIT_SUCCESS,
    SPLIT_SHIFTS_DO_SPLIT_FAILURE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    showConfirmPopup : false,
    positionIdToSplit: null,
    jobType : null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP:
            return {
                ...state,
                showConfirmPopup : true,
                positionIdToSplit: action.payload.positionId,
                jobType: action.payload.jobType
            };

        case SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP:
            return {
                ...state,
                showConfirmPopup : false,
                positionIdToSplit: null,
                jobType : null
            };

        case SPLIT_SHIFTS_DO_SPLIT:
            return {
                ...state,
                showConfirmPopup : false,
                positionIdToSplit: null,
                jobType : null
            };

        case SPLIT_SHIFTS_DO_SPLIT_SUCCESS:
            return {
                ...state,
                showConfirmPopup: false
            };

        case SPLIT_SHIFTS_DO_SPLIT_FAILURE:
            return {
                ...state,
                showConfirmPopup: false
            };

        default: return { ...state};
    }
};

const DownloadHelper = {
    forceDownload (contentOrUrlFile, fileName) {
        const linkElement = document.createElement('a');
        linkElement.href = contentOrUrlFile;
        linkElement.download = fileName;
        document.body.appendChild(linkElement); // Make download function works in Firefox
        linkElement.click();
        linkElement.remove();
    }
};

export default DownloadHelper;

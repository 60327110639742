/**
 * App Language Provider
 * Add more locales here
 */
import { addLocaleData } from 'react-intl';

import enLang from './entries/en-GB';

const AppLocale = {
    en: enLang,
};

addLocaleData(AppLocale.en.data);

export default AppLocale;

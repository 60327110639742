/**
 * Notification sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { isSuccess } from "Helpers/ResponseStatus";
import RoutePaths from "Constants/RoutePaths";

import {
    NOTIFICATION_LOAD_NOTIFICATIONS,
    NOTIFICATION_MARK_AS_READ,
    NOTIFICATION_REMOVE_NOTIFICATION,
    NOTIFICATION_GET_NUMBER_UNREAD,
    NOTIFICATION_LOAD_JOB_DETAILS,
} from "Actions/types";

import {
    showErrorResponse,
    getNotificationsSuccess,
    getNotificationsFailure,
    markNotificationAsReadSuccess,
    markNotificationAsReadFailure,
    removeNofiticationSuccess,
    removeNofiticationFailure,
    getNumberUnreadSuccess,
    getNumberUnreadFailure,
    getJobDetailsAfterClickedSuccess,
    getJobDetailsAfterClickedFailure,
    hideRootSpinner,
    toggleHighlightJob
} from "Actions";

import {
    getNofiticationsRequest,
    markNotificationAsReadRequest,
    getNumberOfUnreadNotificationsRequest,
    deleteNotificationRequest,
} from "Services/employers/EmployerService";

import { getJobDetailRequest } from "Services/employers/JobService";

const detectNotificationData = (response) => ({
    listOfNotifications: response.data,
    paginator: {
        total: Number(response.total),
        perPage: Number(response.per_page),
        currentPage: Number(response.current_page),
        lastPage: Number(response.last_page),
    },
});

/**
 * Get list of notifications from server
 */
function* handleGetNotifications({ payload }) {
    const { perPage, lastId } = payload;

    try {
        const response = yield call(getNofiticationsRequest, perPage, lastId);

        if (response.data && isSuccess(response)) {
            yield put(
                getNotificationsSuccess(
                    detectNotificationData(response.data.data)
                )
            );
        } else {
            yield put(getNotificationsFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getNotificationsFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch list of notifications action
 */
export function* watchGetNotifications() {
    yield takeEvery(NOTIFICATION_LOAD_NOTIFICATIONS, handleGetNotifications);
}

/**
 * Request delete a notification to server
 */
function* handleDeleteNotification({ payload }) {
    const { notificationId, isRead } = payload;

    try {
        const response = yield call(deleteNotificationRequest, notificationId);

        if (response.data && isSuccess(response)) {
            yield put(removeNofiticationSuccess(notificationId, isRead));
        } else {
            yield put(removeNofiticationFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(removeNofiticationFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch delete a notification
 */
export function* watchDeleteNotification() {
    yield takeEvery(NOTIFICATION_REMOVE_NOTIFICATION, handleDeleteNotification);
}

/**
 * Request mark a notification as read to server
 */
function* handleMarkNotificationAsRead({ payload }) {
    const { notificationId } = payload;

    try {
        const response = yield call(
            markNotificationAsReadRequest,
            notificationId
        );

        if (response.data && isSuccess(response)) {
            yield put(markNotificationAsReadSuccess(notificationId));
        } else {
            yield put(markNotificationAsReadFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(markNotificationAsReadFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch mark a notification as read
 */
export function* watchMarkNotificationAsRead() {
    yield takeEvery(NOTIFICATION_MARK_AS_READ, handleMarkNotificationAsRead);
}

/**
 * Request get number of unread notifications from server
 */
function* handleGetNumberUnreadNotification() {
    try {
        const response = yield call(getNumberOfUnreadNotificationsRequest);

        if (response.data && isSuccess(response)) {
            yield put(getNumberUnreadSuccess(response.data.data));
        } else {
            yield put(getNumberUnreadFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getNumberUnreadFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch get number of unread notifications
 */
export function* watchGetNumberUnreadNotification() {
    yield takeEvery(
        NOTIFICATION_GET_NUMBER_UNREAD,
        handleGetNumberUnreadNotification
    );
}

/**
 * Request get job details from ID after clicked notification
 */
function* handleGetJobDetailsAfterClickedNotification({ payload }) {
    const { history, positionId } = payload;

    try {
        const response = yield call(getJobDetailRequest, payload);

        if (response.data && isSuccess(response)) {
            const positionObject = response.data.data;
            let path = "";

            if (!!positionObject.open === true) {
                // path = RoutePaths.byPositionId(
                //     RoutePaths.MY_GIGS.UPCOMING,
                //     positionId
                // );
                path = RoutePaths.MY_GIGS.UPCOMING
            } else if (!!positionObject.filled === true) {
                // path = RoutePaths.byPositionId(
                //     RoutePaths.MY_GIGS.UPCOMING,
                //     positionId
                // );
                path = RoutePaths.MY_GIGS.UPCOMING
            } else if (!!positionObject.completed === true) {
                // path = RoutePaths.byPositionId(
                //     RoutePaths.MY_GIGS.COMPLETED,
                //     positionId
                // );
                path = RoutePaths.MY_GIGS.COMPLETED
            } else {
                // path = RoutePaths.byPositionId(
                //     RoutePaths.MY_GIGS.PENDING,
                //     positionId
                // );
                path= RoutePaths.MY_GIGS.PENDING
            }
            yield call(history.push, path);
            yield put(hideRootSpinner())
            yield put(toggleHighlightJob(positionObject.position_id, true))
            yield put(getJobDetailsAfterClickedSuccess(positionObject));
        } else {
            yield put(getJobDetailsAfterClickedFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getJobDetailsAfterClickedFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch get job details
 */
export function* watchGetJobDetails() {
    yield takeEvery(
        NOTIFICATION_LOAD_JOB_DETAILS,
        handleGetJobDetailsAfterClickedNotification
    );
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchGetNotifications),
        fork(watchDeleteNotification),
        fork(watchMarkNotificationAsRead),
        fork(watchGetNumberUnreadNotification),
        fork(watchGetJobDetails),
    ]);
}

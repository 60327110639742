/**
 * Signin
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim/lib";
import LogoLogin from "Assets/img/login-logo.png";
import * as validators from "Helpers/validators";
import { isEmail } from "Helpers/validators";
// intl messages
import AppLogo from "Assets/img/gig-logo-black.png";
import IntlMessages from "Util/IntlMessages";
import { Scanner } from "@material-ui/icons";
import AppConfig from 'Constants/AppConfig';
// redux action
import {
    resetTextForgot,
    checkEmailExist,
    checkEmailVerified,
    signinUser,
    getEmployerDetail,
    getEmployerList,
} from "Actions";

import KeyCodes from "Constants/KeyCodes";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValidation: {},
            error: false,
            isVerified: true,
            isAuthenticated: true,
            apiCall: false,
            email: "",
            password: "",
        };
        this.onClickForgotPassword = this.onClickForgotPassword.bind(this);
    }

    /**
     * On User Login
     */
    onUserLogin() {
        const formValidation = {};
        const { email, password } = this.state;
        const validEmail = validators.notEmpty(email);
        const validPassword = validators.notEmpty(password);

        if (!validEmail) {
            formValidation.email = "loginEmailRequired";
        } else {
            // const emailIsValid = validators.isEmail(email);
            //
            // if (!emailIsValid) {
            // 	formValidation.email = "loginEmailValid";
            // }
        }

        if (!validPassword) {
            formValidation.password = "loginPasswordRequired";
        }

        this.setState({ formValidation });

        if (Object.keys(formValidation).length === 0) {
            this.setState({ apiCall: true });
            this.props.checkEmailVerified(
                email,
                this.props.history,
                () => this.successCheckEmailVerified(),
                () => this.errorCheckEmailVerified()
            );
        }
    }

    successCheckEmailVerified() {
        this.setState({ isVerified: true });
        this.props.signinUser(
            this.state,
            this.state.history,
            () => this.successAuthenticated(),
            () => this.errorAuthenticated()
        );
    }

    errorCheckEmailVerified() {
        this.setState({ apiCall: false });
        this.setState({ isVerified: false });
    }

    successAuthenticated() {
        this.setState({ apiCall: false });
        this.setState({ isAuthenticated: true });
        this.props.getEmployerDetail(this.props.history);
    }

    errorAuthenticated() {
        this.props.resetTextForgot();
        this.setState({ apiCall: false });
        this.setState({ isAuthenticated: false });
    }

    /* handle click forgot password */
    onClickForgotPassword() {
        const formValidation = {};
        const { email } = this.state;
        if (!email) {
            formValidation.email = "loginEmailRequired";
        } else {
            const emailIsValid = !!isEmail(email);
            if (!emailIsValid) {
                formValidation.email = "loginEmailValid";
            }
        }

        this.setState({ formValidation });

        if (Object.keys(formValidation).length === 0) {
            this.props.checkEmailExist(email);
        }
    }

    /**
     * On User Sign Up
     */
    onUserSignUp() {
        this.props.history.push("/signup");
    }

    renderForgotPasswordError() {
        const { responseForgotPassword, successForgotPassword } = this.props;

        return (
            <FormHelperText
                className={
                    !successForgotPassword
                        ? "field-error lbl-password-error"
                        : "field-success lbl-password-error"
                }
                id="forgot-error-text"
            >
                <FormattedMessage
                    id={responseForgotPassword}
                    defaultMessage=""
                />
            </FormHelperText>
        );
    }

    renderLoginValidError(isVerified) {
        return (
            <FormHelperText
                className="field-error lbl-password-error"
                id="pwd-error-text"
            >
                <FormattedMessage
                    id={
                        isVerified === false
                            ? "loginEmailVerifyFailed"
                            : "loginValid"
                    }
                    defaultMessage=""
                />
            </FormHelperText>
        );
    }

    handleKeyPress(e) {
        const key = e.which || e.keyCode;

        if (key === KeyCodes.ENTER) {
            this.onUserLogin();
        }
    }

    render() {
        const {
            email,
            password,
            requirePassword,
            isAuthenticated,
            isVerified,
            formValidation,
            apiCall,
        } = this.state;
        const { loading, responseForgotPassword } = this.props;

        return (
            <div className="page-login">
                <QueueAnim type="bottom" duration={2000}>
                    <div className="rct-session-wrapper">
                        {loading && <LinearProgress />}
                        <AppBar position="static" className="session-header">
                            <Toolbar>
                                <div className="container">
                                    <div className="d-flex justify-content-center page-login__logo">
                                        <div className="session-logo">
                                            <Link to="/">
                                                <img
                                                    src={AppLogo}
                                                    alt="session-logo"
                                                    className="img-fluid"
                                                    width={120}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <div className="session-inner-wrapper">
                            <div className="container">
                                <div className="login-form">
                                    <div className="session-body">
                                        <Form>
                                            <FormGroup
                                                error={requirePassword}
                                                className="has-wrapper has-wrapper--v2"
                                            >
                                                <div className="lbl-email lbl-email--v2">
                                                    Email
                                                </div>
                                                <FormattedMessage id="enterEmailAddress">
                                                    {(text) => (
                                                        <Input
                                                            type="mail"
                                                            id="user-mail"
                                                            name="user-mail"
                                                            className="has-input has-input--v2 input-lg--v2"
                                                            placeholder={text}
                                                            value={email}
                                                            onChange={(event) =>
                                                                this.setState({
                                                                    email:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                })
                                                            }
                                                            onKeyPress={(
                                                                event
                                                            ) =>
                                                                this.handleKeyPress(
                                                                    event
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </FormattedMessage>

                                                {responseForgotPassword !==
                                                    "emptyText" &&
                                                    this.renderForgotPasswordError()}

                                                {responseForgotPassword ===
                                                    "emptyText" &&
                                                    apiCall === false &&
                                                    isVerified === false &&
                                                    this.renderLoginValidError(
                                                        isVerified
                                                    )}

                                                {responseForgotPassword ===
                                                    "emptyText" &&
                                                    apiCall === false &&
                                                    isAuthenticated === false &&
                                                    isVerified === true &&
                                                    this.renderLoginValidError(
                                                        isVerified
                                                    )}

                                                {Object.keys(formValidation)
                                                    .length !== 0 && (
                                                    <FormHelperText
                                                        className="field-error lbl-password-error"
                                                        id="pwd-error-text"
                                                    >
                                                        {formValidation.email && (
                                                            <FormattedMessage
                                                                id={
                                                                    formValidation.email
                                                                }
                                                                defaultMessage=""
                                                            />
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </FormGroup>

                                            <FormGroup className="has-wrapper has-wrapper--v2">
                                                <div className="lbl-password lbl-password--v2">
                                                    Password
                                                </div>
                                                <FormattedMessage id="enterPassword">
                                                    {(text) => (
                                                        <Input
                                                            type="Password"
                                                            id="user-pwd"
                                                            name="user-pwd"
                                                            className="has-input has-input--v2 input-lg input-lg--v2"
                                                            placeholder={text}
                                                            value={password}
                                                            onChange={(event) =>
                                                                this.setState({
                                                                    password:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                })
                                                            }
                                                            onKeyPress={(
                                                                event
                                                            ) =>
                                                                this.handleKeyPress(
                                                                    event
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </FormattedMessage>

                                                {Object.keys(formValidation)
                                                    .length !== 0 && (
                                                    <FormHelperText
                                                        className="field-error lbl-password-error"
                                                        id="pwd-error-text"
                                                    >
                                                        {formValidation.password && (
                                                            <FormattedMessage
                                                                id={
                                                                    formValidation.password
                                                                }
                                                                defaultMessage=""
                                                            />
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </FormGroup>

                                            <FormGroup className="mb-15 btn-group-login btn-group-login--v2">
                                                <Button
                                                    id="login-signin"
                                                    color="primary"
                                                    className="text-white btn btn--v2 login"
                                                    
                                                    size="large"
                                                    onClick={() =>
                                                        this.onUserLogin()
                                                    }
                                                    disabled={apiCall}
                                                >
                                                    <IntlMessages id="btnStartlogin" />
                                                </Button>
                                                <Button
                                                    id="btn-forgot-passsword"
                                                    size="medium"
                                                    className="btn-forgot-password btn-forgot-password--v2"
                                                    onClick={
                                                        this
                                                            .onClickForgotPassword
                                                    }
                                                    disabled={apiCall}
                                                >
                                                    <IntlMessages id="loginForgetPassword" />
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                        <div className="version-web">
                                            {AppConfig.appVersionNumber}
                                        </div>
                                    </div>
                                    <div className="login-form__footer d-flex justify-content-between flex-direction-column">
                                        <span id={"not-registerd"}>
                                            Not Registered?
                                        </span>
                                        <Button
                                            id="login-signup"
                                            // color="white"
                                            className="text-black btn btn--v2 signup signup--v2 bold"
                                            
                                            size="large"
                                            onClick={() => this.onUserSignUp()}
                                        >
                                            <IntlMessages id="loginSignup" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </QueueAnim>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ authUser, forgotPasswordReducer }) => ({
    respondCheckVerified: authUser.respondCheckVerified,
    respondeCheckAuthenticated: authUser.respondeCheckAuthenticated,
    responseForgotPassword: forgotPasswordReducer.responseForgotPassword,
    successForgotPassword: forgotPasswordReducer.successForgotPassword,
});

const mapActionToProps = {
    resetTextForgot,
    signinUser,
    checkEmailVerified,
    getEmployerDetail,
    getEmployerList,
    checkEmailExist,
};

export default connect(mapStateToProps, mapActionToProps)(SignIn);

/**
 * Card Actions
 */
import {
    GET_CARD,
    CARD_CHECK_VALID,
    CARD_CHECK_VALID_SUCCESS,
    CARD_CHECK_VALID_FAILURE,
    CARD_CREATE,
    CREATE_INVALID_CARD,
    GET_CARD_FAILURE,
    CARD_UPDATE,
    CREATE_CARD_SUCCESS,
    UPDATE_CARD_SUCCESS,
    ADD_CARD_INIT,
    DONE_CARD_INIT,
    FIRST_TIME_GET_CARD

} from './types';

export const getCard = () => ({
    type: GET_CARD
});

export const checkCardValid = () => ({
    type: CARD_CHECK_VALID
});

export const checkCardValidSuccess = (response) => ({
    type: CARD_CHECK_VALID_SUCCESS,
    payload: response
});

export const getCardDetailFailure = (error) => ({
    type: GET_CARD_FAILURE,
    payload: error
});

export const checkCardValidFailure = (error) => ({
    type: CARD_CHECK_VALID_FAILURE,
    payload: error
});

export const initAddCard = () => ({
    type: ADD_CARD_INIT
});

export const addCard = ( params ) => ({
    type: CARD_CREATE,
    payload: params
});

export const createInvalidaCard = (error) => ({
    type: CREATE_INVALID_CARD,
    payload: error
});

export const updateCard = ( params ) => ({
    type: CARD_UPDATE,
    payload: params
});

export const createCardSuccess = (response) =>({
    type: CREATE_CARD_SUCCESS,
    payload: response
});

export const updateCardSuccess = (response) =>({
    type: UPDATE_CARD_SUCCESS,
    payload: response
});

export const doneCardInit = () => ({
    type: DONE_CARD_INIT
});

export const firstTimeGetCard = () => ({
    type: FIRST_TIME_GET_CARD
});

/**
 * "Success" API response code base.
 *
 * @const {number}
 * @protected
 */
const RESPONSE_BASE_SUCCESS = 1000;

/**
 * "Expected error" API response code base.
 *
 * @const {number}
 * @protected
 */
const RESPONSE_BASE_EXPECTED = 2000;

/**
 * "Unexpected error (bug)" API response code base.
 *
 * @const {number}
 * @protected
 */
const RESPONSE_BASE_UNEXPECTED = 3000;

const ApiCodes = {
  OK: RESPONSE_BASE_SUCCESS + 0,
  CREATED: RESPONSE_BASE_SUCCESS + 1,
  UPDATED: RESPONSE_BASE_SUCCESS + 2,
  DELETED: RESPONSE_BASE_SUCCESS + 3,

  // Expected error
  INVALID_LOGIN: RESPONSE_BASE_EXPECTED + 0,
  AUTHENTICATION_FAILED: RESPONSE_BASE_EXPECTED + 1,
  UNSUPPORTED_APP_VERSION: RESPONSE_BASE_EXPECTED + 2,
  STATE_CHANGE_FORBIDDEN: RESPONSE_BASE_EXPECTED + 3,

  // Unexpected error (bug)
  SERVER_ERROR: RESPONSE_BASE_UNEXPECTED + 0,
  BAD_REQUEST: RESPONSE_BASE_UNEXPECTED + 1,
  AUTHORISATION_FAILED: RESPONSE_BASE_UNEXPECTED + 2,
  NOT_FOUND: RESPONSE_BASE_UNEXPECTED + 3,
  INVALID_SIGNATURE: RESPONSE_BASE_UNEXPECTED + 4,
  INVALID_RELATIONSHIP: RESPONSE_BASE_UNEXPECTED + 5,
  INVALID_API_VERSION: RESPONSE_BASE_UNEXPECTED + 6,
  OVER_18: RESPONSE_BASE_UNEXPECTED + 10,
  UNKNOWN_ERROR: RESPONSE_BASE_UNEXPECTED + 999, // Will always be the final code

  STATUS_VISA_STUDENT: 501,
  STATUS_WORKING_TIME: 502,
  STATUS_OVER_8_HOURS: 301,
  STATUS_OVER_6_DAYS: 302,
  RESPONSE_OK: 1000,
};

export default ApiCodes;

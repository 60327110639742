/**
 * Nudge Seekers Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';
import ApiCodes from 'Constants/ApiCodes';

import {
    NUDGE_SEEKER_GET_NUDGABLE_LIST,
    NUDGE_SEEKER_FOR_POSITION
} from 'Actions/types';

import {
    getNudgableSeekersSuccess,
    getNudgableSeekersFailure,
    nudgeSeekerForPositionSuccess,
    nudgeSeekerForPositionFailure,
    nudgeSeekerForPositionUnavailable,
    nudgeSeekerForOver18,
    nudgeSeekerForPositionRestriction48hours
} from 'Actions';

import {
    getNudgableSeekersRequest,
    nudgeSeekerForPositionRequest
} from "Services/employers/JobService";

import MyGig from 'Constants/MyGig';

/**
 * Get list of nudgable seekers from server
 */
function* handleGetNudgableSeekers({payload}) {
    const { positionId, filterParams, page } = payload;

    try {
        const response = yield call(getNudgableSeekersRequest, positionId, filterParams, page);

        if (response.data && isSuccess(response)) {
            yield put(getNudgableSeekersSuccess(response.data.data));
        } else {
            yield put(getNudgableSeekersFailure(response));
        }
    } catch (error) {
        yield put(getNudgableSeekersFailure(error));
    }
}

/**
 * Watch list of nudgable seekers action
 */
export function* watchListNudgableSeekers() {
    yield takeEvery(NUDGE_SEEKER_GET_NUDGABLE_LIST, handleGetNudgableSeekers);
}

/**
 * Nudge seeker for position to server
 */
function* handleNudgeSeekerForPosition({payload}) {
    const {positionId, seekerId} = payload;

    try {
        const response = yield call(nudgeSeekerForPositionRequest, positionId, seekerId);

        if (response.data && isSuccess(response)) {
            yield put(nudgeSeekerForPositionSuccess(response.data.data));
        } else {
            const { code, error} = response.response.data;
            const message = error.message
            if (code === ApiCodes.INVALID_SIGNATURE) {
                yield put(nudgeSeekerForPositionUnavailable());
            }  else if(code === ApiCodes.OVER_18) {
                yield put(nudgeSeekerForOver18());
            } else if((code === ApiCodes.SERVER_ERROR) && (message == MyGig.EXCEED_MAXIMUM_HOURS_REACHED)) {
                yield put(nudgeSeekerForPositionRestriction48hours())
            } else {
                yield put(nudgeSeekerForPositionFailure(error));
            }
        }
    } catch (error) {
        yield put(nudgeSeekerForPositionFailure(error));
    }
}

/**
 * Watch nudge seeker for position
 */
export function* watchNudgeSeekerForPosition() {
    yield takeEvery(NUDGE_SEEKER_FOR_POSITION, handleNudgeSeekerForPosition);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchListNudgableSeekers),
        fork(watchNudgeSeekerForPosition),
    ]);
}

/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import SweetAlert from "react-bootstrap-sweetalert";
import ApiCodes from 'Constants/ApiCodes';
import RoutePaths from 'Constants/RoutePaths';
import IntlMessages from "Util/IntlMessages";
import { hideErrorResponse, storeDataProtection } from 'Actions';
import { FormGroup } from "reactstrap";
import AppConfig from "Constants/AppConfig";

// App locale
import AppLocale from '../lang';

// themes
import primaryTheme from './themes/primaryTheme';

class RctThemeProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVerifiedReadTermsConditions: false,
            isVerifiedStoreAndUseDataConditions: false,
            isAcceptedReceivingUpdatesConditions: false,
        };
    }

    onCheckboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    };

    onStoreDataProtection = () => {
        const { isVerifiedReadTermsConditions, isVerifiedStoreAndUseDataConditions, isAcceptedReceivingUpdatesConditions } = this.state;
        const { history } = this.props;
        this.props.storeDataProtection({
            isVerifiedReadTermsConditions,
            isVerifiedStoreAndUseDataConditions,
            isAcceptedReceivingUpdatesConditions,
            history
        });
    }

    isAuthenticationFailed = () => {
        const { error } = this.props;

        return (error.response.data.code === ApiCodes.AUTHENTICATION_FAILED);
    }

    closeErrorResponse = () => {
        const { history, hideErrorResponse } = this.props;

        hideErrorResponse();

        if (this.isAuthenticationFailed()) {
            localStorage.clear();
            history.push(RoutePaths.AUTH.SIGN_IN);
        }
    }

    renderErrorResponse() {
        const { error } = this.props;
        let respondMessage = <IntlMessages id="common.errorServer" />;

        if (error.response) {
            if (this.isAuthenticationFailed()) {
                return <IntlMessages id="common.errorSessionExpired" />;
            }

            return error.response.data.error.message;
        } else if (error.data && error.data.error && error.data.error.message) {
            return error.data.error.message;
        }

        return respondMessage;
    }

    renderAlertAcceptTerms() {
        const { isShowFormGPDR, loading } = this.props;
        const { isVerifiedReadTermsConditions, isVerifiedStoreAndUseDataConditions, isAcceptedReceivingUpdatesConditions } = this.state;
        return (
            <SweetAlert
                style={{ width: '50vw', marginLeft: '-24vw', marginTop: '-250px' }}
                warning
                title=''
                show={isShowFormGPDR}
                onConfirm={() => false}
                showConfirm={false}
            >
                <div className="sweet-alert-inner">
                    <div className="font-normal italic left">
                        <IntlMessages id="signUp.guideForGPDR" />
                        <span className="red">*</span>
                    </div>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={this.onCheckboxChange}
                            type="checkbox"
                            checked={isVerifiedStoreAndUseDataConditions}
                            className="checkbox-custom"
                            name="isVerifiedStoreAndUseDataConditions"
                            id="isVerifiedStoreAndUseDataConditions"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isVerifiedStoreAndUseDataConditions">
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptDataCollectedPrefix" />
                                &nbsp;
                                <a href={AppConfig.URL_PRIVACY_POLICY} target="_blank">
                                    <IntlMessages id="widgets.here" />.
                                </a>
                                &nbsp;
                                <IntlMessages id="signUp.acceptDataCollectedSuffix" />
                            </span>
                        </label>
                    </FormGroup>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={this.onCheckboxChange}
                            type="checkbox"
                            checked={isVerifiedReadTermsConditions}
                            className="checkbox-custom"
                            name="isVerifiedReadTermsConditions"
                            id="isVerifiedReadTermsConditions"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isVerifiedReadTermsConditions"
                        >
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptTermPrefix" />
                                &nbsp;
                                <a href={AppConfig.URL_TERMS_AND_CONDITIONS} target="_blank">
                                    <IntlMessages id="widgets.here" />.
                                </a>
                                &nbsp;
                                <IntlMessages id="signUp.acceptTermSuffix" />
                            </span>
                        </label>
                    </FormGroup>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={this.onCheckboxChange}
                            type="checkbox"
                            checked={isAcceptedReceivingUpdatesConditions}
                            className="checkbox-custom"
                            name="isAcceptedReceivingUpdatesConditions"
                            id="isAcceptedReceivingUpdatesConditions"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isAcceptedReceivingUpdatesConditions"
                        >
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptReceiveUpdateProduct" />
                            </span>
                        </label>
                    </FormGroup>
                    {
                        isVerifiedReadTermsConditions && isVerifiedStoreAndUseDataConditions && (
                            <Button
                                onClick={this.onStoreDataProtection}
                                className="form-auth__submit"
                                disabled={loading}
                            >

                                <IntlMessages id="signUp.button.startPosting" />
                            </Button>
                        )
                    }

                </div>
            </SweetAlert>
        );
    }

    render() {
        const { locale, rtlLayout, activeTheme, children, isShowErrorPopup } = this.props;
        const currentAppLocale = AppLocale[locale.locale];


        // theme changes
        let theme = '';
        switch (activeTheme.id) {
            case 1:
                theme = primaryTheme;
                break;
            default:
                break;
        }

        if (rtlLayout) {
            theme.direction = 'rtl';
        } else {
            theme.direction = 'ltr';
        }
        return (
            <MuiThemeProvider theme={theme}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <Fragment>
                        {children}
                        <div className="rct-page">
                            {this.renderAlertAcceptTerms()}
                        </div>
                        <SweetAlert
                            error
                            show={isShowErrorPopup}
                            confirmBtnText={
                                <IntlMessages id="btn.ok" />
                            }
                            title={<IntlMessages id="button.error" />}
                            onConfirm={this.closeErrorResponse}
                        >
                            {this.renderErrorResponse()}
                        </SweetAlert>
                    </Fragment>
                </IntlProvider>
            </MuiThemeProvider>
        );
    }
}

// map state to props
const mapStateToProps = ({ settings }) => settings;

const mapActionToProps = {
    hideErrorResponse,
    storeDataProtection
};

export default connect(mapStateToProps, mapActionToProps)(RctThemeProvider);

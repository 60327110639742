/**
 * Shift Actions
 */

import {
    CREATE_NEW_SHIFT,
    CREATE_NEW_SHIFT_SUCCESS,
    CREATE_NEW_SHIFT_FAILURE,
    EDIT_SHIFT,
    EDIT_SHIFT_SUCCESS,
    EDIT_SHIFT_FAILURE,
    SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP,
    SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP,
    SPLIT_SHIFTS_DO_SPLIT,
    SPLIT_SHIFTS_DO_SPLIT_SUCCESS,
    SPLIT_SHIFTS_DO_SPLIT_FAILURE,
    CHECK_OVERLAPPED,
    SHOW_OVERLAPPED_POPUP,
    UPDATE_JOB_AFTER_EDITED,
    ADD_JOB_AFTER_CREATED
} from './types';


/**
 * Action Get All Skills
 */
export const createNewShift = (positionData, history, successCallback, errorCallback) => ({
    type: CREATE_NEW_SHIFT,
    payload: { positionData, history, successCallback, errorCallback }
});

/**
 * Action Get Skills Success
 */
export const createNewShiftSuccess = (response) => ({
    type: CREATE_NEW_SHIFT_SUCCESS,
    payload: response.data
});

/**
 * Action Get Skills Failure
 */
export const createNewShiftFailure = (error) => ({
    type: CREATE_NEW_SHIFT_FAILURE,
    payload: error
});

export const editShift = (positionData, history, successCallback, errorCallback, goToPostedPage = true ) => ({
    type: EDIT_SHIFT,
    payload: { positionData, history, successCallback, errorCallback, goToPostedPage  }
});

export const checkOverllaped = (params, history, successCallback, errorCallback, confirmCallback, goToPostedPage = true ) => ({
    type: CHECK_OVERLAPPED,
    payload: { params, history, successCallback, errorCallback, confirmCallback, goToPostedPage }
});

export const showOverllapedPopup = (params) => ({
    type: SHOW_OVERLAPPED_POPUP,
    payload: { params }
});

export const editShiftSuccess = (response) => ({
    type: EDIT_SHIFT_SUCCESS,
    payload: response.data
});

export const editShiftFailure = (error) => ({
    type: EDIT_SHIFT_FAILURE,
    payload: error
});

/**
 * Split shifts actions
 */
export const showSplitShiftsConfirmPopup = (positionId, jobType) => ({
    type   : SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP,
    payload: {positionId, jobType}
});

export const hideSplitShiftsConfirmPopup = () => ({
    type: SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP
});

export const splitShifts = (positionId, jobType) => ({
    type   : SPLIT_SHIFTS_DO_SPLIT,
    payload: {positionId, jobType}
});

export const splitShiftsSuccess = (response) => ({
    type   : SPLIT_SHIFTS_DO_SPLIT_SUCCESS,
    payload: response
});

export const splitShiftsFailure = (error) => ({
    type   : SPLIT_SHIFTS_DO_SPLIT_FAILURE,
    payload: error
});

export const updateJobsAfterEdited = positionEdited => ({
    type   : UPDATE_JOB_AFTER_EDITED,
    payload: { positionEdited }
});

export const addJobAfterReposted = positionData => ({
    type   : ADD_JOB_AFTER_CREATED,
    payload: { positionData }
});

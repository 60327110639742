/**
 * Rct Collapsible Card
 */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Collapse, Badge, Input } from "reactstrap";
import classnames from "classnames";
import DownloadFile from 'Assets/img/DownloadFile.png'

// rct section loader
import RctSectionLoader from "../RctSectionLoader/RctSectionLoader";

class RctCollapsibleCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false,
            collapse: true,
            close: false,
            monthDownLoad: 12,
            disabled: false,
            locationId: 0
        };
    }

    // downloadExcel(e) {
    //     this.props.showRootSpinner();
    //     this.props.weeklyDownloadHours(e.target.value);
    // }

    downloadExcelByMonth() {
        this.props.showRootSpinner();
        this.props.weeklyDownloadHours(this.state.monthDownLoad, this.state.locationId);
    }

    setMonthDownLoadExcel(e) {
        let weeks = e.target.value;
        let idMsg = 'reports.seekers.topSeekersOf12Week';
        this.setState({ monthDownLoad: weeks });
        this.props.getMonthWithWeeks({ month: (weeks / 4), location_id: this.state.locationId });

        switch (parseInt(weeks)) {
            case 24:
                {
                    idMsg = "reports.seekers.topSeekersOf6Month";
                    break;
                }
            case 36:
                {
                    idMsg = "reports.seekers.topSeekersOf9Month";
                    break;
                }
            case 48:
                {
                    idMsg = "reports.seekers.topSeekersOf12Month";
                    break;
                }
        }
        this.props.idMsg(idMsg);
    }

    setLocationDownLoadExcel(e) {
        let location = e.target.value
        this.setState({ locationId: e.target.value });
        this.props.getMonthWithWeeks({ month: (this.state.monthDownLoad / 4), location_id: location});

    }

    dateAgo() {
        const {
            disabled
        } = this.state;
        const {listOfLocations} = this.props
        return (
            <div>
                <div className="float-right timesheet-wrapper">
                    <button
                        className='print-icon-container pointer'
                        onClick={() => {
                            this.downloadExcelByMonth();
                        }}
                        disabled={disabled}
                    >
                        <img style={{ verticalAlign: 'baseline', width: '36px', height: '41px', marginTop: '-7px' }} src={DownloadFile} height={25}></img>
                    </button>
                </div>

                <div className="float-right dropdown-month">
                    <Input
                        type="select"
                        className="Job-Location"
                        // onChange={(e) => this.downloadExcel(e)}
                        onChange={(e) => this.setMonthDownLoadExcel(e)}
                    >
                        <option value="12"> 12 week </option>
                        <option value="24"> 6 Months </option>
                        <option value="36"> 9 Months </option>
                        <option value="48"> 12 Months </option>
                    </Input>
                </div>

                <div className="float-right dropdown-month mr-2" style={{width: '20%'}}>
                    <Input
                        type="select"
                        className="Job-Location"
                        onChange={(e) => this.setLocationDownLoadExcel(e)}

                    >
                        <option key={`location-${0}`} value={0}>
                            Select All
                        </option>
                        {listOfLocations.map((location) => (
                            <option
                                key={`location-${location.id}`}
                                value={Number(location.id)}
                            >
                                {location.name}, {location.city}
                            </option>
                        ))}
                    </Input>


                </div>
            </div>
        );
    }

    onCollapse() {
        this.setState({ collapse: !this.state.collapse });
    }

    onReload() {
        this.setState({ reload: true });
        const self = this;
        setTimeout(() => {
            self.setState({ reload: false });
        }, 1500);
    }

    onCloseSection() {
        this.setState({ close: true });
    }

    render() {
        const { close, reload, collapse } = this.state;
        const {
            children,
            collapsible,
            closeable,
            reloadable,
            heading,
            fullBlock,
            colClasses,
            customClasses,
            headingCustomClasses,
            contentCustomClasses,
            badge,
            buttons,
            dropDownDate,
            userTab,
        } = this.props;
        return (
            <div
                className={classnames(colClasses || "", {
                    "d-block": !collapse,
                    "panel-collapsible": userTab,
                })}
            >
                <div
                    className={classnames(`rct-block ${customClasses || ""}`, {
                        "d-none": close,
                    })}
                >
                    {heading && (
                        <div
                            className={`rct-block-title ${headingCustomClasses || ""
                                }`}
                        >
                            {dropDownDate && this.dateAgo()}
                            {buttons && (
                                <div
                                    className={`float-right ${this.props.flexEnd
                                            ? "d-flex-align-end"
                                            : ""
                                        }`}
                                >
                                    {buttons.map((button, index) => (
                                        <Fragment
                                            key={`collapsible-card-button-${index}`}
                                        >
                                            {button}
                                        </Fragment>
                                    ))}
                                </div>
                            )}

                            <h4>
                                {Array.isArray(heading)
                                    ? heading.map((headingObject, index) => (
                                        <span
                                            key={`header-${index}`}
                                            className={
                                                headingObject.active
                                                    ? "header-multi active"
                                                    : "header-multi inactive"
                                            }
                                        >
                                            {headingObject.linkTo ? (
                                                <Link
                                                    to={headingObject.linkTo}
                                                >
                                                    {headingObject.text}
                                                </Link>
                                            ) : (
                                                headingObject.text
                                            )}
                                        </span>
                                    ))
                                    : heading}
                                {badge && (
                                    <Badge
                                        className="p-1 ml-10"
                                        color={badge.class}
                                    >
                                        {badge.name}
                                    </Badge>
                                )}
                            </h4>
                            {(collapsible || reloadable || closeable) && (
                                <div className="contextual-link">
                                    {collapsible && (
                                        <button
                                            onClick={() => this.onCollapse()}
                                        >
                                            <i className="ti-minus" />
                                        </button>
                                    )}
                                    {reloadable && (
                                        <button onClick={() => this.onReload()}>
                                            <i className="ti-reload" />
                                        </button>
                                    )}
                                    {closeable && (
                                        <button
                                            onClick={() =>
                                                this.onCloseSection()
                                            }
                                        >
                                            <i className="ti-close" />
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <Collapse isOpen={collapse}>
                        <div
                            className={classnames(contentCustomClasses || "", {
                                "rct-block-content": !fullBlock,
                                "rct-full-block": fullBlock,
                            })}
                        >
                            {children}
                        </div>
                    </Collapse>
                    {reload && <RctSectionLoader />}
                </div>
            </div>
        );
    }
}

export default RctCollapsibleCard;

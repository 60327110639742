import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import MyGig from "Constants/MyGig";
import gigRoles from "Helpers/gigroles";
import ScheduleHelper from "Helpers/Schedule";
import IntlMessages from "Util/IntlMessages";
import Popover from "@material-ui/core/es/Popover/Popover";
import moment from "moment";
import DatePickerImage from "Assets/img/datepicker.png";

export default class ModalShift extends Component {
    renderAllShifts(shift, index, lastEle) {
        const dateShift = moment.unix(shift.start).format("DD MMM");
        const startShift = moment
            .unix(shift.start)
            .format("H:mm ")
            .replace(" ", "")
            .toLowerCase();
        const endShift = moment
            .unix(shift.end)
            .format("H:mm ")
            .replace(" ", "")
            .toLowerCase();

        return (
            <div
                key={index}
                className="show-shift"
                style={
                    lastEle
                        ? { color: this.props.color }
                        : { color: this.props.color, paddingBottom: "15px" }
                }
            >
                <div className="show-date big-font font-bold">{dateShift}</div>
                <div className="show-time smaller-font">
                    {startShift}
                    {" - "}
                    {endShift}
                </div>
            </div>
        );
    }

    render() {
        const { openModalShift, job, onClose, jobType, iconModal } = this.props;
        let color = job.is_privated
            ? gigRoles.getColor(21)
            : gigRoles.getColor(job.skill_type);
        let { schedule } = job;

        if (
            jobType !== MyGig.jobType.COMPLETED &&
            jobType !== MyGig.jobType.PENDING
        ) {
            schedule = ScheduleHelper.removePastSchedules(job.schedule) || [];

            if (!schedule.length) {
                schedule = job.schedule;
            }
        }

        const totalHours = schedule.reduce(
            (total, time) =>
                total +
                moment.unix(time.end).diff(moment.unix(time.start), "hours"),
            0
        );
        const startDate = moment.unix(schedule[0].start).format("DD MMM");
        let endDate = null;
        if (schedule.length > 1) {
            endDate = moment
                .unix(schedule[schedule.length - 1].start)
                .format("DD MMM");
        }

        return (
            <Popover
                classes={{ paper: "MuiPopover-modal-shift" }}
                open={openModalShift}
                onClose={onClose}
                // anchorReference="anchorPosition"
                // anchorPosition={{ top: 291, left: 368 }}
                anchorEl={this.props.anchorcalendarmodal}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <div className="modal-shift">
                    {iconModal && iconModal !== "calendar" ? (
                        <i
                            className="material-icons"
                            style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                            {iconModal}
                        </i>
                    ) : (
                        <img
                            src={DatePickerImage}
                            style={{
                                width: 28,
                                marginTop: -3,
                                marginLeft: -5,
                                paddingBottom: 5,
                            }}
                        />
                    )}
                    <div className="modal-body">
                        {schedule.map((ele, index) =>
                            this.renderAllShifts(
                                ele,
                                index,
                                index === schedule.length - 1
                            )
                        )}
                    </div>
                </div>
            </Popover>
            // <Dialog
            //     open={openModalShift}
            //     onClose={onClose}
            //     className="modal-shift-wrapper"
            //     maxWidth={false}
            // >
            //     <div className="modal-shift">

            //         <div className="modal-body">
            //             {schedule.map((ele, index) => this.renderAllShifts(ele, index))}
            //         </div>
            //     </div>
            // </Dialog>
        );
    }
}

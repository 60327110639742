import API from 'Api';

export const getPdfPendingRemoteRequest = async(startDate, endDate, internal, locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        internal : internal,
        locationId
    };
    if(!locationId) {
        delete params.locationId
    }

    return await API.getBlob('/employers/pdf/exportTimesheetPending', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getPdfApprovedRemoteRequest = async(startDate, endDate,locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        locationId
    };

    if(!locationId) {
        delete params.locationId
    }

    return await API.getBlob('/employers/pdf/exportTimesheetApproved', params, true)
        .then(response => response)
        .catch(error => error);
};

export const exportPdfAppliedRequest = (positionId, jobType) => API.getBlob(`/employers/pdf/exportPdfApplied`, {
    'positionId' : positionId,
    'type': jobType
})
.then(response => response)
.catch(error => error);

export const exportPdfConfirmedRequest = (positionId, jobType) => API.getBlob(`/employers/pdf/exportPdfConfirmed`, {
    'positionId' : positionId,
    'type': jobType
})
.then(response => response)
.catch(error => error);

export const getPdfWeeklyTimesheetRemoteRequest = (startDate, endDate) => API.getBlob(`/employers/pdf/exportWeeklyTimesheet`, {
    start_date: startDate,
    end_date  : endDate
})
.then(response => response)
.catch(error => error);



export const getPdfCalendarRequest = (startDate, endDate) => API.getBlob(`/employers/exportCalendarView`, {
    start: startDate,
    end  : endDate,
    type: "Pdf"
})
.then(response => response)
.catch(error => error);

export const getPdfUpcomingRequest = (date, jobType) => API.getBlob(`/employers/exportUpcomingView`, {
    start: date,
    end  : date,
    type: "Pdf",
    jobType: jobType
})
.then(response => response)
.catch(error => error);

export const getPdfPendingRequest = (date) => API.getBlob(`/employers/exportPendingJobsView`, {
    start: date,
    end  : date,
    type: "Pdf",
})
.then(response => response)
.catch(error => error);

export const getExcelCalendarRequest = (startDate, endDate) => API.getBlob(`/employers/exportCalendarView`, {
    start: startDate,
    end  : endDate,
    type: "Excel"
})
.then(response => response)
.catch(error => error);


import {
    OPEN_POPUP_QR_CODE,
    CLOSE_POPUP_QR_CODE,
    GET_QR_CODE_EMPLOYER,
    REFRESH_QR_CODE_EMPLOYER,
    REFRESH_QR_CODE_EMPLOYER_SUCCESS,
    GET_QR_CODE_EMPLOYER_SUCCESS,
} from './types';

export const openQrCode = () => ({
    type: OPEN_POPUP_QR_CODE,
});

export const closeQrCode = () => ({
    type:  CLOSE_POPUP_QR_CODE
});

export const getQrCodeEmployer = () => ({
    type:  GET_QR_CODE_EMPLOYER
});
export const getQrCodeEmployerSuccess = (response) => ({
    type:  GET_QR_CODE_EMPLOYER_SUCCESS,
    payload: response
})
export const refreshQrCodeEmployer = () =>({
    type: REFRESH_QR_CODE_EMPLOYER,
})
export const refreshQrCodeEmployerSuccess = (response) =>({
    type: REFRESH_QR_CODE_EMPLOYER_SUCCESS,
    payload: response
})

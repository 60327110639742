/**
 * Block Employee Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import {
    BLOCK_EMPLOYEE_REQUEST,
    UNBLOCK_EMPLOYEE_REQUEST
} from 'Actions/types';

import {
    blockEmployeeSuccess,
    blockEmployeeFailure,
    unblockEmployeeSuccess,
    unblockEmployeeFailure
} from 'Actions';

import {
    blockEmployee,
    unblockEmployee
} from "Services/employers/EmployerService";

/**
 * Request server to block an employee
 */
function* blockEmployeeRequestServer({payload}) {
    const { employeeId, successCallback, errorCallback}  = payload;

    try {
        const response = yield call(blockEmployee, employeeId);

        if (response.data.data && response.data.data.id) {
            yield put(blockEmployeeSuccess(response));
            successCallback();
        } else {
            yield put(blockEmployeeFailure(response));
            errorCallback();
        }

    } catch (error) {
        yield put(blockEmployeeFailure(error));
        errorCallback();
    }
}

/**
 * Request server to unblock an employee
 */
function* unblockEmployeeRequestServer({payload}) {
    const {employeeId, successCallback, errorCallback} = payload;

    try {
        const response = yield call(unblockEmployee, employeeId);

        if (response.data && isSuccess(response)) {
            yield put(unblockEmployeeSuccess(response));
            successCallback();
        } else {
            yield put(unblockEmployeeFailure(response));
            errorCallback();
        }


    } catch (error) {
        yield put(unblockEmployeeFailure(error));
        errorCallback();
    }
}

/**
 * Taking block employee
 */
export function* takeBlockEmployee() {
    yield takeEvery(BLOCK_EMPLOYEE_REQUEST, blockEmployeeRequestServer);
}

/**
 * Taking unblock employee
 */
export function* takeUnblockEmployee() {
    yield takeEvery(UNBLOCK_EMPLOYEE_REQUEST, unblockEmployeeRequestServer);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(takeBlockEmployee),
        fork(takeUnblockEmployee)
    ]);
}

import API from 'Api';

/**
 * Check validate for access code in Sign up process
 *
 * @param {String}  code
 * @param {Boolean} gigPremium
 * @return async
 */
export const checkAccessCodeRequest = async (code, gigPremium) => {
    const params = {
        code,
        gig_premium: !!gigPremium
    };

    return await API.post('/register/employer/checkAccessCode', params, false)
        .then(response => response)
        .catch(error => error);
};

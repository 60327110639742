/**
 * PDF actions (get, export, download, etc)G
 */
import {
    PDF_OPEN_VIEWER,
    PDF_CLOSE_VIEWER,
    PDF_GET_TIMESHEET_APPROVED,
    PDF_GET_TIMESHEET_APPROVED_SUCCESS,
    PDF_GET_TIMESHEET_APPROVED_FAILURE,
    PDF_GET_TIMESHEET_PENDING,
    PDF_GET_TIMESHEET_PENDING_SUCCESS,
    PDF_GET_TIMESHEET_PENDING_FAILURE,
    PDF_GET_APPLIED,
    PDF_GET_APPLIED_SUCCESS,
    PDF_GET_APPLIED_FAILURE,
    PDF_GET_CONFIRMED,
    PDF_GET_CONFIRMED_SUCCESS,
    PDF_GET_CONFIRMED_FAILURE,
    PDF_GET_WEEKLY_TIMESHEET,
    PDF_GET_WEEKLY_TIMESHEET_FAILURE,
    PDF_GET_WEEKLY_TIMESHEET_SUCCESS,
    EXCEL_GET_WEEKLY_TIMESHEET_FALIURE,
    EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS,
    EXCEL_GET_WEEKLY_TIMESHEET,
    PDF_GET_DAILY_UPCOMING_JOB,
    PDF_GET_DAILY_UPCOMING_JOB_FAILURE,
    PDF_GET_DAILY_UPCOMING_JOB_SUCCESS,
    PDF_GET_DAILY_PENDING_JOB,
    PDF_GET_DAILY_PENDING_JOB_FAILURE,
    PDF_GET_DAILY_PENDING_JOB_SUCCESS,
} from './types';

export const pdfOpenViewer = () => ({
    type: PDF_OPEN_VIEWER
});

export const pdfCloseViewer = (viewerKey) => ({
    type   : PDF_CLOSE_VIEWER,
    payload: { key: viewerKey }
});

export const pdfGetTimesheetApproved = (startDate, endDate,locationId) => ({
    type   : PDF_GET_TIMESHEET_APPROVED,
    payload: {startDate, endDate,locationId}
});

export const pdfGetTimesheetApprovedSuccess = (response) => ({
    type   : PDF_GET_TIMESHEET_APPROVED_SUCCESS,
    payload: response
});

export const pdfGetTimesheetApprovedFailure = (error) => ({
    type   : PDF_GET_TIMESHEET_APPROVED_FAILURE,
    payload: error
});

export const pdfGetTimesheetPending = (startDate, endDate, internal, locationId) => ({
    type   : PDF_GET_TIMESHEET_PENDING,
    payload: {startDate, endDate, internal, locationId}
});

export const pdfGetTimesheetPendingSuccess = (response) => ({
    type   : PDF_GET_TIMESHEET_PENDING_SUCCESS,
    payload: response
});

export const pdfGetTimesheetPendingFailure = (error) => ({
    type   : PDF_GET_TIMESHEET_PENDING_FAILURE,
    payload: error
});

export const pdfGetApplied = (positionId, jobType) => ({
    type    : PDF_GET_APPLIED,
    payload : { positionId, jobType  }
});

export const pdfGetAppliedSuccess = (response) => ({
    type    : PDF_GET_APPLIED_SUCCESS,
    payload: response
});

export const pdfGetAppliedFailure = (error) => ({
    type    : PDF_GET_APPLIED_FAILURE,
    payload: error
});

export const pdfGetConfirmed = (positionId, jobType) => ({
    type    : PDF_GET_CONFIRMED,
    payload : { positionId, jobType  }
});

export const pdfGetConfirmedSuccess = (response) => ({
    type    : PDF_GET_CONFIRMED_SUCCESS,
    payload: response
});

export const pdfGetConfirmedFailure = (error) => ({
    type    : PDF_GET_CONFIRMED_FAILURE,
    payload: error
});

export const pdfGetWeeklyTimesheet = (startDate, endDate) => ({
    type    : PDF_GET_WEEKLY_TIMESHEET,
    payload : {startDate, endDate}
});

export const pdfGetWeeklyTimesheetSuccess = (response) => ({
    type    : PDF_GET_WEEKLY_TIMESHEET_SUCCESS,
    payload : response
});

export const pdfGetWeeklyTimesheetFailure = (error) => ({
    type    : PDF_GET_WEEKLY_TIMESHEET_FAILURE,
    payload : error
});

export const excelGetWeeklyTimesheet = (startDate, endDate) => ({
    type    : EXCEL_GET_WEEKLY_TIMESHEET,
    payload : {startDate, endDate}
});

export const excelGetWeeklyTimesheetSuccess = (response) => ({
    type    : EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS,
    payload : response
});

export const excelGetWeeklyTimesheetFailure = (error) => ({
    type    : EXCEL_GET_WEEKLY_TIMESHEET_FALIURE,
    payload : error,
});

export const pdfGetDailyUpcomingJob = (date, jobType) => ({
    type    : PDF_GET_DAILY_UPCOMING_JOB,
    payload : {date, jobType}
});

export const pdfGetDailyUpcomingJobSuccess = (response) => ({
    type    : PDF_GET_DAILY_UPCOMING_JOB_SUCCESS,
    payload : response
});

export const pdfGetDailyUpcomingJobFailure = (error) => ({
    type    : PDF_GET_DAILY_UPCOMING_JOB_FAILURE,
    payload : error
});

export const pdfGetDailyPendingJob = (date) => ({
    type    : PDF_GET_DAILY_PENDING_JOB,
    payload : {date}
});

export const pdfGetDailyPendingJobSuccess = (response) => ({
    type    : PDF_GET_DAILY_PENDING_JOB_SUCCESS,
    payload : response
});

export const pdfGetDailyPendingJobFailure = (error) => ({
    type    : PDF_GET_DAILY_PENDING_JOB_FAILURE,
    payload : error
});

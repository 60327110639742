export default class Util {

    /**
     * Parse a URL. The individual components of the URL will be available
     * as properties on the returned HTMLAnchorElement object. For example:
     *
     *     protocol = http:
     *     hostname = example.com
     *     port     = 3000
     *     pathname = /pathname/
     *     search   = ?search=test
     *     hash     = #hash
     *
     * @param  {string} url
     * @return {!HTMLAnchorElement}
     * @see    developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement
     * @see    gist.github.com/jlong/2428561
     * @public
     */
    parseUrl(url) {

        const parser = /** @type {!HTMLAnchorElement} */ (document.createElement('a'));

        parser.href = url;

        return parser;

    };
}
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import {
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';

import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import {
    showNudgeSeekerModal,
    hideNudgeSeekerModal,
    getNudgableSeekers,
    getAllSkills,
    nudgeSeekerForPosition,
    hideNudgeFailurePopup,
    getAllSkillsByGroup,
    checkWorkingSixDay,
    hideWarningWorkingSixDay,
    checkSeekerNotToJob,
    hideWarningSeeker48h,
    hideWarningSeekerIsStudent,
    hideWarningSeekerIn8hours
} from 'Actions';

import ListSeekers from 'Components/ListSeekers/ListSeekers';
import Spinner from "Components/Model/Spinner";
import IntlMessages from "Util/IntlMessages";
import MyGig from "Constants/MyGig";
import { currentPage } from 'Helpers/RouterChecker';
import Warning from 'Assets/img/SweetAlert/warning.png';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const NumberGigThresholds = [
    { min: 0 },
    { min: 10 },
    { min: 20 },
    { min: 30 },
    { min: 50 },
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: 'black',
                },
            },
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: 'black',
                },
            }
        }
    },
});

class NudgeSeeker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiCalled: false,
            filterForm: {
                searchText: '',
                isGigApproved: false,
                thresholdIndex: '',
                skillIds: [],
                // filterDistance: MyGig.distance._10_MILE
                seekerTarget: null,


            },
            paginator: {
                page: 1
            },
            skillDropdown: {
                open: false
            }
        };
    }

    UNSAFE_componentWillMount() {
        this.getAllNecessaryDataFromApi(this.props);
    }

    componentWillUnmount() {
        this.setState({ apiCalled: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getAllNecessaryDataFromApi(nextProps);
    }

    setCurrentPaginatorPage(page) {
        if (this.state.paginator.page !== page) {
            this.setState({
                paginator: { page }
            });
        }
    }

    getCurrentPaginatorPage() {
        return this.state.paginator.page;
    }

    getFilterDataForAPI(filterForm) {
        const params = {};

        if (!filterForm) {
            filterForm = this.state.filterForm;
        }

        if (filterForm.skillIds.length > 0) {
            params.skillIds = filterForm.skillIds;
        }

        if (filterForm.filterDistance !== '') {
            params.distance = filterForm.filterDistance;
        }

        if (filterForm.searchText !== '') {
            params.searchText = filterForm.searchText;
        }

        return params;
    }

    getAllNecessaryDataFromApi(props) {

        const { apiCalled, filterForm } = this.state;
        if (props.positionId && props.showNudgeSeeker === true && apiCalled === false) {
            this.setState({ apiCalled: true });
            this.props.getNudgableSeekers(props.positionId, this.getFilterDataForAPI(filterForm));
            this.props.getAllSkillsByGroup()
            this.props.getAllSkills();
        }
    }

    filterData(seekers) {
        const { filterForm } = this.state;
        let responseData = seekers;

        if (filterForm.isGigApproved) {
            responseData = responseData.filter(seeker => Number(seeker.gig_approved) === 1);
        }

        if (filterForm.thresholdIndex !== '' && !filterForm.searchText) {
            responseData = responseData.filter((seeker) => {
                const totalAssignments = Number(seeker.total_assignments);
                const thresholdRange = NumberGigThresholds[filterForm.thresholdIndex];

                return !isNaN(totalAssignments)
                    && (
                        (thresholdRange.min && totalAssignments >= thresholdRange.min)
                        || !thresholdRange.min
                    )
                    && (
                        (thresholdRange.max && totalAssignments <= thresholdRange.max)
                        || !thresholdRange.max
                    );
            });
        }

        return responseData;
    }

    toggleGigApproved() {
        const { filterForm } = this.state;
        filterForm.isGigApproved = !filterForm.isGigApproved;

        this.setState({ filterForm });
    }

    openSkillDropdown(forceOpen) {
        this.setState({
            skillDropdown: {
                ...this.state.skillDropdown,
                open: Boolean(forceOpen)
            }
        });
    }

    handleCloseModal() {
        this.setState({ apiCalled: false });
        this.props.hideNudgeSeekerModal();
    }

    filterSeekerName(searchText) {
        const resetPage = 1;
        const { positionId } = this.props;
        const filterForm = {
            ...this.state.filterForm,
            searchText
        };
        this.setState({ filterForm });
    }

    callFilterSeeker(e) {
        const resetPage = 1;
        const { positionId } = this.props;
        const { filterForm } = this.state;
        const code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            this.props.getNudgableSeekers(
                positionId,
                this.getFilterDataForAPI(filterForm),
                resetPage
            );
        }
    }

    filterDistance(e) {
        const resetPage = 1;
        const { positionId } = this.props;
        const filterForm = {
            ...this.state.filterForm,
            filterDistance: e.target.value
        };
        this.setState({ filterForm });
        this.setCurrentPaginatorPage(resetPage);
        this.props.getNudgableSeekers(
            positionId,
            this.getFilterDataForAPI(filterForm),
            resetPage
        );
    }

    filterSeekersBySkill(skillIds) {
        const resetPage = 1;
        const { positionId } = this.props;
        const { filterForm } = this.state;

        filterForm.skillIds = skillIds;

        this.setState({ filterForm });
        this.setCurrentPaginatorPage(resetPage);
        this.openSkillDropdown(false);

        this.props.getNudgableSeekers(
            positionId,
            this.getFilterDataForAPI(filterForm),
            resetPage
        );
    }

    filterSeekersByNumberGig(thresholdIndex) {
        const filterForm = {
            ...this.state.filterForm,
            thresholdIndex
        };

        this.setState({ filterForm });
    }

    handleScrollLoadMoreSeekers(e) {
        const isBottom = (e) => e.scrollHeight - 15 >= e.scrollTop;

        const nextPage = this.getCurrentPaginatorPage() + 1;
        const { positionId, onLoadNextPage, nextPageAvailable } = this.props;

        if (isBottom(e.target) && onLoadNextPage === false && nextPageAvailable === true) {
            this.setCurrentPaginatorPage(nextPage);
            this.props.getNudgableSeekers(
                positionId,
                this.getFilterDataForAPI(null),
                nextPage
            );
        }
    }

    renderAllAlerts() {
        const { nudgeSuccess, positionId, nudgeFailure, nudgeUnavailable, nudgeGotExceeded, addSeekerForOver18, nudgeFailure48h } = this.props;

        return (
            <Fragment>
                <SweetAlert
                    success
                    show={nudgeSuccess}
                    title=""
                    btnSize="sm"
                    onConfirm={() => {
                        this.setCurrentPaginatorPage(1);
                        this.props.getNudgableSeekers(positionId, this.getFilterDataForAPI(null), 1);
                    }}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="nudgeSeeker.doNudge.success" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={nudgeGotExceeded}
                    title=""
                    btnSize="sm"
                    onConfirm={() => this.props.hideNudgeFailurePopup()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.exceeded" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={nudgeFailure && !nudgeGotExceeded}
                    title=""
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => this.props.hideNudgeFailurePopup()}>
                    <IntlMessages id="nudgeSeeker.doNudge.failure" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.warningSeeker48h}
                    title={<IntlMessages id="addSeeker.doAdd.restriction48hours.title" />}
                    btnSize="sm"
                    onConfirm={() => this.props.hideWarningSeeker48h()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.restriction48hours.message" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={nudgeUnavailable}
                    title=""
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => this.props.hideNudgeFailurePopup()}>
                    <IntlMessages id="nudgeSeeker.doAdd.unavailable" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={addSeekerForOver18}
                    title="Over 18's Only"
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => this.props.hideNudgeFailurePopup()}>
                    <IntlMessages id="nudgeSeeker.doAdd.over18" />
                </SweetAlert>

                <SweetAlert
                    show={this.props.warningSeekerOverSixDay}
                    custom
                    customIcon={Warning}
                    btnSize="sm"
                    showCancel
                    cancelBtnText={<IntlMessages id="button.cancel" />}
                    title=""
                    onConfirm={() => {
                        this.props.hideWarningWorkingSixDay();
                        this.props.nudgeSeekerForPosition(this.props.positionId, this.state.seekerTarget)
                    }}
                    onCancel={() => this.props.hideWarningWorkingSixDay()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <FormattedMessage
                        id="nudgeSeeker.doAdd.working6Day"
                    />
                </SweetAlert>

                <SweetAlert
                    show={this.props.warningSeekerIn8h}
                    custom
                    customIcon={Warning}
                    btnSize="sm"
                    showCancel
                    cancelBtnText={<IntlMessages id="button.cancel" />}
                    title=""
                    onConfirm={() => {
                        this.props.hideWarningSeekerIn8hours();
                        this.props.nudgeSeekerForPosition(this.props.positionId, this.state.seekerTarget);
                    }}
                    onCancel={() => this.props.hideWarningSeekerIn8hours()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <FormattedMessage
                        id="addSeeker.doAdd.in8h"
                    />
                </SweetAlert>
            </Fragment>
        );
    }

    render() {
        const { filterForm, skillDropdown } = this.state;

        const milesArray = [5, 10, 20, 30, 40];
        const {
            nudgableSeekers,
            onLoadNextPage,
            onLoadingSeekers,
            onNudging,
            showNudgeSeeker,
            history,
            listSkillType,
            classes,
            checkWorkingSixDay,
            loadingCheckSeekerSixDay,
            checkSeekerNotToJob
        } = this.props;

        const isCalendarPage = currentPage(history.location) === MyGig.currentPage.calendarPage;
        return (
            <div className="nudge-seeker-outer-wrapper">
                <SwipeableDrawer
                    id="modal-nudge-right"
                    anchor={'right'}
                    open={showNudgeSeeker}
                    onOpen={() => false}
                    onClose={() => this.handleCloseModal()}>
                    <div className="drawer-wrapper">
                        <Spinner
                            spinnerWidth={70}
                            show={onLoadingSeekers || onNudging || loadingCheckSeekerSixDay}
                        />

                        <div
                            className="nudge-seeker-inner-wrapper"
                            onScroll={(e) => this.handleScrollLoadMoreSeekers(e)}
                        >

                            <h1 className="nudge-seeker-title orange thin">
                                <IntlMessages id="nudgeSeeker.title" />
                                <i
                                    className="zmdi zmdi-close-circle orange font-2x pull-right on-hover"
                                    onClick={() => this.props.hideNudgeSeekerModal()}
                                />
                            </h1>

                            <div className="nudge-filter-form">
                                <div className="row">
                                    <FormGroup className="col-sm-5 col-xs-12 search-filter-box">
                                        <InputGroup>
                                            <FormattedMessage id="nudgeSeeker.placeholders.searchInput">
                                                {(placeholderText) => (
                                                    <Input
                                                        placeholder={placeholderText}
                                                        value={filterForm.searchText}
                                                        onChange={(e) => this.filterSeekerName(e.target.value)}
                                                        onKeyPress={(e) => this.callFilterSeeker(e)} />
                                                )}
                                            </FormattedMessage>

                                            <InputGroupAddon addonType="prepend">
                                                <i className="material-icons">search</i>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup className="col-sm-5 col-xs-12 favourite-check-box">
                                        <label
                                            className={`checkbox-custom-label ${  filterForm.isGigApproved ? 'checked' : ''}`}
                                            htmlFor="checkbox-gig-approved">
                                            <IntlMessages id="nudgeSeeker.gigFavourite" />
                                        </label>
                                        <input
                                            onChange={() => this.toggleGigApproved()}

                                            type="checkbox"
                                            checked={filterForm.isGigApproved}
                                            className="checkbox-custom"
                                            id="checkbox-gig-approved"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="row">
                                    <FormGroup className="col-sm-3 col-xs-12">
                                        <FormControl fullWidth>
                                            <MuiThemeProvider theme={theme}>
                                                <InputLabel
                                                    htmlFor="dropdown-skill"
                                                >
                                                    <FormattedMessage id="nudgeSeeker.placeholders.experience" />
                                                </InputLabel>
                                            </MuiThemeProvider>
                                            <MuiThemeProvider theme={theme}>
                                                <Select
                                                    multiple
                                                    value={filterForm.skillIds}
                                                    open={skillDropdown.open}
                                                    onOpen={() => this.openSkillDropdown(true)}
                                                    onClose={() => this.openSkillDropdown(false)}
                                                    onChange={(e) => this.filterSeekersBySkill(e.target.value)}
                                                    className="filter-control"
                                                    MenuProps={MenuProps}
                                                    inputProps={{ id: 'dropdown-skill' }}
                                                >
                                                    {this.props.listSkillType.map((skillType) => (
                                                        <MenuItem value={skillType.type_id} key={`select-skill-${skillType.type_id}`}>
                                                            {skillType.type_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </MuiThemeProvider>
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup className="col-sm-5 col-xs-12">

                                    </FormGroup>

                                    <FormGroup className="col-sm-4 col-xs-12">

                                    </FormGroup>
                                </div>
                            </div>

                            <div className="nudge-seeker-lists">
                                {!onLoadingSeekers && nudgableSeekers.length === 0 && (
                                    <div className="font-bold center vertical-space-small">
                                        <FormattedMessage id="nudgeSeeker.emptySeeker" />
                                    </div>
                                )}

                                <ListSeekers
                                    listSeekers={this.filterData(nudgableSeekers)}
                                    isNudge
                                    isAdd={false}
                                    onSubmit={(seekerId, seekerName) => {
                                        this.setState({seekerTarget: seekerId})
                                        checkSeekerNotToJob(this.props.positionId, seekerId, () => this.props.nudgeSeekerForPosition(this.props.positionId, seekerId))
                                    }} />
                            </div>

                            {onLoadNextPage && (
                                <div className="pd-10 center">
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                        mode="determinate"
                                        value={40}
                                        min={0}
                                        max={50}
                                    />
                                </div>
                            )}
                        </div>
                        {isCalendarPage && this.renderAllAlerts()}
                    </div>
                </SwipeableDrawer>
                {!isCalendarPage && this.renderAllAlerts()}
            </div >
        );
    }
}

const mapStateToProps = ({ nudgeSeekerReducer, skillReducer, positionReducer }) => ({
        ...nudgeSeekerReducer,
        listOfSkills: skillReducer.skills,
        listSkillType: skillReducer.skillGroup,
        onLoadingSeekers: nudgeSeekerReducer.onLoading === true || skillReducer.loading === true,
        warningSeekerOverSixDay: positionReducer.warningSeekerOverSixDay,
        loadingCheckSeekerSixDay: positionReducer.loadingCheckWarningSixDay,
        warningSeeker48h: positionReducer.warningSeeker48h,
        warningSeekerStudent: positionReducer.warningSeekerStudent,
        warningSeekerIn8h:  positionReducer.warningSeekerIn8h
    });

const mapActionToProps = {
    showNudgeSeekerModal,
    hideNudgeSeekerModal,
    getNudgableSeekers,
    getAllSkills,
    nudgeSeekerForPosition,
    hideNudgeFailurePopup,
    getAllSkillsByGroup,
    checkWorkingSixDay,
    hideWarningWorkingSixDay,
    checkSeekerNotToJob,
    hideWarningSeeker48h,
    hideWarningSeekerIsStudent,
    hideWarningSeekerIn8hours
};

export default connect(mapStateToProps, mapActionToProps)(NudgeSeeker);

import {
  GET_SEEKER_SIGNAL,
  GET_SEEKER_SIGNAL_SUCCESS,
  GET_SEEKER_SIGNAL_FAIL,
  CLEAR_LIST_SEEKER_SIGNAL,
} from './types';

export const getSeekerSignal = (skillId, page) => ({
  type: GET_SEEKER_SIGNAL,
  payload: {
    page,
    skillId
  }
});

export const getSeekerSignalSuccess = (seekers) => ({
  type: GET_SEEKER_SIGNAL_SUCCESS,
  payload: { seekers }
});

export const getSeekerSignalFail = (error) => ({
  type: GET_SEEKER_SIGNAL_FAIL,
  payload: error,
});

export const clearListSeekerSignal = () => ({
  type: CLEAR_LIST_SEEKER_SIGNAL
});

/**
 * Block Employee Reducer
 */

import {
    BLOCK_EMPLOYEE_REQUEST,
    BLOCK_EMPLOYEE_SUCCESS,
    BLOCK_EMPLOYEE_FAILURE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    response: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BLOCK_EMPLOYEE_REQUEST:
            return { ...state };

        case BLOCK_EMPLOYEE_SUCCESS:
            return { ...state, response: action.payload.data };

        case BLOCK_EMPLOYEE_FAILURE:
            return { ...state };

        default: return { ...state};
    }
};

import BaseModel from "Model/BaseModel";
import {isEmail, isValidPostcode, matchCharacter, matchCompanyNumber, maxLength, minLength} from "Helpers/validators";

export default class LoginDetailModel extends BaseModel {

    static createFromApiResponseData(data) {
        const model = new LoginDetailModel;
        model.fullname = data.fullname;
        model.email = data.email;
        return model;
    }

    static  validateFields (params) {
        const errors = {};
        const { fullname, email, currentPassword, newPassword, confirmPassword, id } = params;

        if (!fullname) {
            errors.name = 'loginDetail.name.empty';
        }

        if (!email) {
            errors.email = 'loginDetail.email.empty';
        }

        if (!errors.email && !isEmail(email)) {
            errors.email = 'loginDetail.emai.invalid';
        }

        if (!errors.currentPassword && !!currentPassword && minLength(currentPassword,2) && maxLength(currentPassword, 5)) {
            errors.currentPassword = 'loginDetail.minimum.currentPassword.length';
        }

        if (!!currentPassword && !newPassword) {
            errors.newPassword = "loginDetail.newPassword.empty";
        }

        if (!!currentPassword && !confirmPassword) {
            errors.confirmPassword = "loginDetail.confirmPassword.empty";
        }

        if (!!newPassword && (!errors.newPassword)) {
            if ( maxLength(newPassword,5) ) {
                errors.newPassword = 'loginDetail.minimum.password.length';
            }
            if (!!confirmPassword && maxLength(confirmPassword , 5) && errors.confirmPassword) {
                errors.confirmPassword = 'loginDetail.minimum.password.confirmation.length';
            }
        }

        if (!!newPassword && !errors.newPassword && !errors.confirmPassword) {
            if (newPassword !== confirmPassword) {
                errors.newPassword = 'loginDetail.password.not.match';
            }
        }

        return errors;
    }


}

import API from 'Api';

/**
 * Get list of blocked seekers
 *  @return async
 */
export const getBlockedSeekersRequest = async () => await API.get('/employers/blocked_seekers')
    .then(response => response)
    .catch(err => err);

/**
 * Get list of seekers who was rating provider
 * @return async
 */
export const getRatingOfSeekersRequest = async () => await API.get('/employers/ratings_of_seekers')
    .then(response => response)
    .catch(err => err);

/**
 * Get list of top seekers by shifts
 *
 * @return async
 */
export const getTopSeekersByShiftsRequest = async (param) => await API.get('/employers/top_seekers/by_shifts', param)
    .then(response => response)
    .catch(err => err);

/**
* Get list fulfilment by week
* @param object
* @return async
*/

export const getFulfilmentReportRequest = async (params) => await API.get('/employers/get_fulfilment_report', params)
    .then(response => response)
    .catch(err => err);

/**
* Get list  billing by week
* @param object
* @return async
*/

export const getBillingReportRequest = async (params) => await API.get('/employers/get_billing_report', params)
.then(response => response)
.catch(err => err);

/**
 * Get list timecard by date rang
 * @param object
 * @typedef [{
        startDate         : date - d.m.Y start week
        endDate           : date - d.m.Y end week
        interval          : date - d.m.y date selected
    }]
 * @return async
 */

export const getTimecardReportRequest = async (params) => await API.get('/employers/get_timecard_report', params)
    .then(response => response)
    .catch(err => err);


/**
 * Calculate total hours for all positions, group by skills
 *
 * @return async
 */
export const getPositionsStatisticsRequest = async () => await API.get('/employers/positions_statistics_hours')
    .then(response => response)
    .catch(err => err);

import {
  GET_LIST_SEEKER_REQUEST,
  GET_LIST_SEEKER_FAILURE,
  GET_LIST_SEEKER_SUCCESS,
  GET_EMPLOYEE_DETAIL,
  GET_EMPLOYEE_DETAIL_SUCCESS,
  GET_EMPLOYEE_LIST_JOB_APPLY,
  GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS,
  GET_EMPLOYEE_LIST_JOB_APPLY_FAILURE,
  GET_DOCUMENT_OF_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_DETAIL,
  UPDATE_EMPLOYEE_DETAIL_SUCCESS,
  UPDATE_EMPLOYEE_DETAIL_FAILURE,
  RESET_LOADING_DETAIL,
  HANDLE_BLOCK_SEEKER,
  HANDLE_UNBLOCK_SEEKER,
  RESET_UPDATE_POPUP,
  BLOCK_UNLOCK_SUCCESS,
  BLOCK_UNLOCK_FAILURE,
  DELETE_SEEKER_BLOCK,
  DELETE_EMPLOYEE_EXPERIENCE,
  DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
  ADD_HISTORY,
  ADD_HISTORY_SUCCESS,
  ADD_HISTORY_FAILURE,
  DELETE_HISTORY,
  OPEN_SEEKER_DETAIL,
  SET_SEARCH_TEXT,
  GET_JOB_TYPE,
  GET_JOB_TYPE_SUCCESS,
  GET_INFO_SEEKER_IN_POSITION,
  GET_INFO_SEEKER_IN_POSITION_SUCCESS,
  GET_INFO_SEEKER_IN_POSITION_FAILURE,
  ADD_NOTE,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,
} from "./types";

export const getListSeeker = (params) => ({
  type: GET_LIST_SEEKER_REQUEST,
  payload: params,
});

export const getSeekerDetail = (employeeId) => ({
  type: GET_EMPLOYEE_DETAIL,
  payload: { employeeId },
});

export const getListSeekerSuccess = (listPrivider) => ({
  type: GET_LIST_SEEKER_SUCCESS,
  payload: listPrivider,
});

export const getListSeekerFailure = (error) => ({
  type: GET_LIST_SEEKER_FAILURE,
  payload: error,
});

export const getInfoSeekerInPosition = (employeeId) => ({
  type: GET_INFO_SEEKER_IN_POSITION,
  payload: { employeeId },
});

export const getInfoSeekerInPositionSuccess = (data) => ({
  type: GET_INFO_SEEKER_IN_POSITION_SUCCESS,
  payload: data,
});

export const getInfoSeekerInPositionFailure = (error) => ({
  type: GET_INFO_SEEKER_IN_POSITION_FAILURE,
  payload: error,
});

export const getJobType = () => ({
  type: GET_JOB_TYPE,
});

export const getJobTypeSuccess = (data) => ({
  type: GET_JOB_TYPE_SUCCESS,
  payload: { data },
});

export const getEmployeeListJobApply = (employeeId) => {
  return {
    type: GET_EMPLOYEE_LIST_JOB_APPLY,
    payload: { employeeId },
  };
};

export const getEmployeeListJobApplySuccess = (data) => ({
  type: GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS,
  payload: { data },
});

export const getEmployeeListJobApplyFailure = (error) => ({
  type: GET_EMPLOYEE_LIST_JOB_APPLY_FAILURE,
  payload: error,
});

export const addNote = (employeeId, note) => ({
  type: ADD_NOTE,
  payload: { employeeId, note },
});

export const addNoteSuccess = (data) => ({
  type: ADD_NOTE_SUCCESS,
  payload: data,
});

export const addNoteFailure = (error) => ({
  type: ADD_NOTE_FAILURE,
  payload: error,
});

export const getSeekerDetailSuccess = (seekerDetail) => ({
  type: GET_EMPLOYEE_DETAIL_SUCCESS,
  payload: seekerDetail,
});

export const getSeekerDetailError = (error) => ({
  type: GET_DOCUMENT_OF_EMPLOYEE_FAILURE,
  payload: error,
});

export const updateSeekerDetail = (data, employee_id, showPopup = false) => {
  return {
    type: UPDATE_EMPLOYEE_DETAIL,
    payload: { data, employee_id, showPopup },
  };
};

export const updateSeekerDetailSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_DETAIL_SUCCESS,
  payload: { data },
});

export const resetInitLoading = () => ({
  type: RESET_LOADING_DETAIL,
});

export const resetUpdatePopup = () => ({
  type: RESET_UPDATE_POPUP,
});

export const updateSeekerDetailFailure = (seekerDetail) => ({
  type: UPDATE_EMPLOYEE_DETAIL_FAILURE,
});

export const handleBlockSeeker = (employeeId) => ({
  type: HANDLE_BLOCK_SEEKER,
  payload: { employeeId },
});

export const handleUnblockSeeker = (employeeId) => ({
  type: HANDLE_UNBLOCK_SEEKER,
  payload: { employeeId },
});

export const blockUnlockSuccess = () => ({
  type: BLOCK_UNLOCK_SUCCESS,
});

export const blockUnlockFailure = () => ({
  type: BLOCK_UNLOCK_FAILURE,
});

export const deleteSeekerAfterBlock = (employeeId) => ({
  type: DELETE_SEEKER_BLOCK,
  payload: { employeeId },
});

export const deleteEmployeeExperience = (params) => ({
  type: DELETE_EMPLOYEE_EXPERIENCE,
  payload: { params },
});

export const deleteEmployeeExperienceSuccess = (response) => ({
  type: DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  payload: { response },
});

export const deleteEmployeeExperienceFailure = (error) => ({
  type: DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
  payload: { error },
});

export const addHistoryEmployee = (params) => ({
  type: ADD_HISTORY,
  payload: params,
});

export const addHistorySuccess = (response) => ({
  type: ADD_HISTORY_SUCCESS,
  payload: { response },
});

export const addHistoryFailure = (response) => ({
  type: ADD_HISTORY_FAILURE,
  payload: { response },
});

export const deleteHistory = (note_id, employee_id) => ({
  type: DELETE_HISTORY,
  payload: { note_id, employee_id },
});

export const openSeekerDetail = (employeeId) => ({
  type: OPEN_SEEKER_DETAIL,
  payload: { employeeId },
});

export const setSearchText = (value) => ({
  type: SET_SEARCH_TEXT,
  payload: { value },
});

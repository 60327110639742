import {
    CREATE_NEW_EMPLOYER,
    UPDATE_EMPLOYER,
    CREATE_NEW_EMPLOYER_SUCCESS,
    CREATE_NEW_EMPLOYER_FAILURE,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_FAILURE,
    UPDATE_LIST_PROVIDER_SUCCESS,
    CLOSE_POPUP
} from './types';

export const createNewEmployer = (params) => ({
    type: CREATE_NEW_EMPLOYER,
    payload: {params}
});


export const updateEmployer = (params) => ({
    type: UPDATE_EMPLOYER,
    payload: {params}
});


export const updateEmployerSuccess = (response) => ({
    type: UPDATE_EMPLOYER_SUCCESS,
    payload: {response}
});

export const updateEmployerFailure = (response) => ({
    type: UPDATE_EMPLOYER_FAILURE,
    payload: {response}
});


export const createNewEmployerSuccess = (response) => ({
    type: CREATE_NEW_EMPLOYER_SUCCESS,
    payload: {response}
});

export const createNewEmployerFailure = (response) => ({
    type: CREATE_NEW_EMPLOYER_FAILURE,
    payload: {response}
});

export const closePopup = () => ({
    type: CLOSE_POPUP
});

export const updateListProviderSuccess = (params) => ({
    type: UPDATE_LIST_PROVIDER_SUCCESS,
    payload: {params}
});

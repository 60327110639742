/**
 * Jobs App Actions
 */

import {
    UPDATE_MASTER_RATE,
    UPDATE_MASTER_RATE_SUCCESS,
    UPDATE_MASTER_RATE_FAIL,
    HANDLE_CHANGE_MASTER_RATE,
    CLOSE_ALL_ALERT,
    SEND_SKILLS_CHARGE_RATE,
    SEND_SKILLS_CHARGE_RATE_SUCCESS,
    SEND_SKILLS_CHARGE_RATE_FAIL,
    SET_ERROR_TO_SKILL,
    SET_FIELD_SKILL_FORM,
    GET_ALL_SKILLS,
    GET_SKILLS_SUCCESS,
    GET_SKILLS_FAILURE,
    GET_ALL_SKILLS_BY_GROUP,
    GET_ALL_SKILLS_BY_GROUP_SUCCESS,
    GET_ALL_SKILLS_BY_GROUP_FAIL,
    GET_SKILL_BY_SKILL_TYPE,
    GET_SKILL_BY_SKILL_TYPE_SUCCESS
} from './types';

/**
 * Action handle Master Rate
 */

export const handleChangeMasterRate = (index, value) => ({
    type: HANDLE_CHANGE_MASTER_RATE,
    payload: {
        index,
        value
    }
});

export const updateMasterRate = (masterRate) => ({
    type: UPDATE_MASTER_RATE,
    payload: masterRate,
});

export const updateMasterRateSuccess = () => ({
    type: UPDATE_MASTER_RATE_SUCCESS,
});

export const updateMasterRateFail = () => ({
    type: UPDATE_MASTER_RATE_FAIL,
});


/**
 * Action handle show success alert, or error alert
 */

export const closeAllAlert = () => ({
    type: CLOSE_ALL_ALERT
});

/**
 * Action hanlde set field skill to form
 */
export const setFieldSkillForm = (typeId, skillId, rate, value) => ({
    type: SET_FIELD_SKILL_FORM,
    payload: {
        typeId,
        skillId,
        rate,
        value,
    }
});

/**
 * Action set error to skill if form error
 */

export const setErrorToSkill = (typeSkill) => ({
    type: SET_ERROR_TO_SKILL,
    payload: typeSkill,
});


/**
 * Action send skills charge rate to server
 */

export const sendSkillsChargeRate = (skillsChargeRate, typeId) => ({
    type: SEND_SKILLS_CHARGE_RATE,
    payload: {skillsChargeRate, typeId},
});

export const sendSkillsChargeRateSuccess = (payload) => ({
    type: SEND_SKILLS_CHARGE_RATE_SUCCESS,
    payload: payload
});

export const sendSkillsChargeRateFail = (error) => ({
    type: SEND_SKILLS_CHARGE_RATE_FAIL,
    payload: error
});

/**
 * Action Get All Skill By Group
 */
export const getAllSkillsByGroup = () => ({
    type: GET_ALL_SKILLS_BY_GROUP,
});

export const getAllSkillsByGroupSuccess = (skillGroup) => ({
    type: GET_ALL_SKILLS_BY_GROUP_SUCCESS,
    payload: skillGroup,
});

export const getAllSkillsByGroupFail = () => ({
    type: GET_ALL_SKILLS_BY_GROUP_FAIL,
});

/**
 * Action Get All Skills
 */
export const getAllSkills = (onEditJob) => ({
    type   : GET_ALL_SKILLS,
    payload: { onEditJob }
});

/**
 * Action Get Skills Success
 */
export const getSkillsSuccess = (response) => ({
    type: GET_SKILLS_SUCCESS,
    payload: response
});

/**
 * Action Get Skills Failure
 */
export const getSkillsFailure = (error) => ({
    type: GET_SKILLS_FAILURE,
    payload: error
});

export const getSkillBySkillType = (type_id) => ({
    type: GET_SKILL_BY_SKILL_TYPE,
    payload: {type_id}
})

export const getSkillBySkillTypeSuccess = (data) => ({
    type: GET_SKILL_BY_SKILL_TYPE_SUCCESS,
    payload: {data}
})

import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MyGig from "Constants/MyGig";
import PushNotification from "PushNotification";

import Spinner from "Components/Model/Spinner";
import IntlMessages from "Util/IntlMessages";
import StoredData from "Constants/StoredData";
import DirectMessage from "./DirectMessage";
import BroadcastMessage from "./BroadcastMessage";
import moment from "moment";
import S3Helper from "Util/s3Helper";

import {
    getDirectMessage,
    getConversationList,
    closeMessageModal,
    changeMessageTab,
    getAllMessages,
    getMessagesOfPosition,
    sendMessageToPosition,
    sendCurrentPosition,
    getJobById,
    getCandidatesByPosition,
    getAssignmentsByPosition,
} from "Actions";

const MESSAGES_PER_PAGE = 20;
const pushnotification = new PushNotification();

class MessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiCalled: false,
            positionsWithMessages: [],
            messagesOfPosition: [],
            positionsWithConversation: [],
            openConversation: null,
            openConversationPage: 1,
            onSavingMessage: false,
            tabMessage: true,
            newMessage: false,
            senderId: null,
        };
        this.s3 = new S3Helper();
    }

    componentDidMount() {
        if (!window.directMessage) {
            pushnotification.withPusherConnection();
            pushnotification.subscribeDirectMessage(
                StoredData.get(StoredData.KEYS.LOGGED_IN_AS)
            );
        }
        let self = this;

        window.directMessage.bind(MyGig.evenDirectMessage, (data) => {
            self.props.getConversationList(true);
            if (
                self.props.messagePositionId ==
                data.actionParams.conversation_id
            ) {
                self.props.getDirectMessage(
                    data.actionParams.conversation_id,
                    false
                );
            }
        });
    }
    componentWillUnmount() {
        this.props.sendCurrentPosition(null);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { senderId } = this.state;
        const { jobForMessage, listPositions, conversations, conversationId } =
            nextProps;
        if (senderId === null) {
            const newSenderId = StoredData.get(
                StoredData.KEYS.EMPLOYER_STAFF_ID
            );
            this.setState({ senderId: newSenderId });
        }
        if (listPositions && listPositions.length) {
            this.setState({
                positionsWithMessages: listPositions.map((position) =>
                    this.formatPositionObject(position)
                ),
            });
        }
        if (conversations && conversations.length) {
            this.setState({
                positionsWithConversation: conversations.map((conversation) =>
                    this.formatPositionObject(conversation.position)
                ),
            });
        }

        if (nextProps.isOpenModal && !this.state.apiCalled) {
            if (jobForMessage) {
                this.setState({
                    positionsWithMessages: [
                        this.formatPositionObject(jobForMessage),
                    ],
                    tabMessage: false,
                });
                this.switchConversation(jobForMessage.position_id);
            } else {
                this.props.getAllMessages();
            }
            this.setState({ apiCalled: true });
        }

        if (nextProps.messagesOfPosition && !nextProps.onLoadingMessages) {
            let messages = nextProps.messagesOfPosition;

            if (this.state.openConversationPage > 1) {
                messages = messages.concat(this.state.messagesOfPosition);
            }

            this.setState({
                messagesOfPosition: messages,
            });
        }
        if (conversationId !== null) {
            // this.setState({
            //     newMessage: false,
            //     openConversation: conversationId,
            //     openConversationPage: 1,
            // });
            this.props.getDirectMessage(conversationId, true);
        }
        if (
            nextProps.messagePositionId !== null &&
            !nextProps.onLoadingPositions
        ) {
            this.markMessagesAsRead(nextProps.messagePositionId);
            this.setState({
                newMessage: false,
                openConversation: nextProps.messagePositionId,
                openConversationPage: 1,
            });
        }
    }

    closeModal() {
        this.setState({
            apiCalled: false,
        });
        this.props.closeMessageModal();
        this.props.onCloseModal();
    }

    formatPositionObject(position) {
        return {
            positionId: Number(position.position_id),
            skill_name: position.skill_name || position.job_type,
            skill_type: position.skill_type,
            schedule: position.schedule,
            locationName: "",
            location_name: position.location_name,
            numberUnread: Number(position.total_unread),
            opened: false,
            totalPage: Math.ceil(position.total_messages / MESSAGES_PER_PAGE),
            messages: [],
            uniform_image: position.uniform_image,
            uniform_name: position.uniform_name,
            uniform_other: position.uniform_other,
            position_description: position.position_description,
            special_requirements: position.special_requirements,
            payrate: position.payrate,
            charge_rate: position.charge_rate,
            chargeout_rate: position.charge_rate,
            location_building: position.location_building,
            location_city: position.location_city,
            location_street: position.location_street,
            location_postcode: position.location_postcode,
            assignments_total: position.assignments_total,
            assignments_filled: position.assignments_filled,
            responses_pending:
                position.responses_pending !== null
                    ? position.responses_pending
                    : 0,
            position_id: Number(position.position_id),
            start_unix_timestamp: position.start_unix_timestamp,
        };
    }

    getPositionIndexFromId(positionId) {
        const { positionsWithMessages } = this.state;

        return positionsWithMessages.findIndex(
            (position) => position.positionId === positionId
        );
    }

    getPositionDataFromId(positionId) {
        const { positionsWithMessages } = this.state;
        const values = positionsWithMessages.filter(
            (position) => position.positionId === positionId
        );

        return values.length > 0 ? values[0] : null;
    }

    markMessagesAsRead(positionId) {
        const index = this.getPositionIndexFromId(positionId);
        const { positionsWithMessages } = this.state;

        if (positionsWithMessages[index]) {
            positionsWithMessages[index].numberUnread = 0;
            this.setState({ positionsWithMessages });
        }
    }

    switchConversation(positionId) {
        if (positionId === "new-message") {
            return this.setState({
                newMessage: !this.state.newMessage,
                openConversation: "new-message",
            });
        }
        this.setState({
            newMessage: false,
            openConversation: positionId,
            openConversationPage: 1,
        });
        this.props.getMessagesOfPosition(positionId, 1);
    }

    loadMoreMessages(positionId) {
        if (!this.props.onLoadingMessages) {
            const page = this.state.openConversationPage + 1;
            const position = this.getPositionDataFromId(positionId);

            if (position && position.totalPage >= page) {
                this.setState({
                    openConversationPage: page,
                });
                this.props.getMessagesOfPosition(positionId, page);
            }
        }
    }

    doSaveMessage(positionId, messageData) {
        this.setState({ onSavingMessage: true });
        this.props.sendMessageToPosition(
            positionId,
            messageData,
            (dataResponse) => {
                this.storeNewMessageImmediately(dataResponse);
            },
            (error) => {
                this.handleSendMessageError(error);
            }
        );
    }

    storeNewMessageImmediately(messageData) {
        const { messagesOfPosition } = this.state;
        this.props.getAllMessages();
        const newNote = {
            ...messageData,
            created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            note_id: messageData.id,
            author_employee_id: null,
        };
        const newListMess = messagesOfPosition.push(newNote);
        this.setState({
            messagesOfPosition: newListMess,
            onSavingMessage: false,
        });
    }

    handleSendMessageError(error) {
        // Todo: Handle error on send message
    }

    handleLoadingAllJobDetails(position_id) {
        //const { position_id } = position;
        this.props.sendCurrentPosition(position_id);
        //this.props.getJobById(position_id);
        this.props.getCandidatesByPosition(position_id);
        this.props.getAssignmentsByPosition(position_id);
    }

    handleChangeTabMessage() {
        this.props.changeMessageTab();
        this.setState({
            tabMessage: !this.state.tabMessage,
        });
    }

    /**
     * @param  Array listConversations
     * @description Sorting list conversations by status and send_at.
     * Expected result is the list will be divied in to 2 parts: normal part and blocked part
     * Each element of each part will be sorted by send_at (Newer message will be on top)
     */
    sortListConversations(listConversations = []) {
        return listConversations.sort((itemA, itemB) => {
            /*
                Status enum [1, 2] (1: normal, 2: blocked)
                -Case 1: compareByStatus is 1 (itemA.status = 2, itemB.status = 1) => sort Item B before Item A
                -Case 2: compareByStatus is -1 (itemA.status = 1, itemB.status = 2) => sort Item A before Item B
                -Case 3: compareByStatus is 0 (itemA.status equal itemB.status) => sort by send_at (Newer will be on top)
                */
            const compareByStatus = itemA.status - itemB.status;
            if (compareByStatus === 0) {
                return itemB.send_at - itemA.send_at;
            }
            return compareByStatus;
        });
    }
    render() {
        const {
            directMessages: { conversationId, messages, isLoading },
            onLoadingMessages,
            conversationLoading,
        } = this.props;

        const {
            positionsWithMessages,
            messagesOfPosition,
            tabMessage,
            newMessage,
            openConversation,
            openConversationPage,
            senderId,
        } = this.state;

        const conversations = this.props.conversations.filter(
            (conversation) => conversation.status !== 3
        );

        return (
            <SwipeableDrawer
                anchor={"right"}
                open={this.props.isOpenModal}
                onOpen={() => false}
                onClose={() => this.closeModal()}
                className="MuiDrawer-modal-message-gig-container"
            >
                <div className="drawer-wrapper">
                    <Spinner
                        spinnerWidth={70}
                        show={this.props.onLoadingPositions}
                    />

                    <div className="message-modal-wrapper">
                        {/* <div className="message-modal-header">
                            <h1 className="message-modal-title orange thin">
                                <FormattedMessage id="messages.title" />
                                <i
                                    className="zmdi zmdi-close-circle orange font-2x pull-right on-hover"
                                    onClick={() => this.closeModal()}
                                />
                            </h1>
                        </div> */}

                        <div
                            className="message-gig-title"
                            style={{ padding: tabMessage ? "30px" : null }}
                        >
                            <div
                                className=" d-flex justify-content-between"
                                // style={{ alignItems: center }}
                            >
                                <h1 className="m-0">
                                    <IntlMessages id="messages.title" />
                                </h1>
                                <i
                                    className="zmdi zmdi-close black font-2x pull-right on-hover"
                                    onClick={() => this.closeModal()}
                                />
                            </div>
                            <ul className="message-gig__list-tab">
                                <li
                                    className={classNames([
                                        "message-gig__tab",
                                        { active: this.state.tabMessage },
                                    ])}
                                    onClick={() =>
                                        !this.state.tabMessage &&
                                        this.handleChangeTabMessage()
                                    }
                                >
                                    Direct
                                </li>

                                <li
                                    className={classNames([
                                        "message-gig__tab",
                                        { active: !this.state.tabMessage },
                                    ])}
                                    onClick={() =>
                                        this.state.tabMessage &&
                                        this.handleChangeTabMessage()
                                    }
                                >
                                    Broadcast
                                </li>
                            </ul>
                        </div>

                        {tabMessage ? (
                            <DirectMessage
                                openConversation={
                                    openConversation || conversationId
                                }
                                switchConversation={(positionId) =>
                                    this.switchConversation(positionId)
                                }
                                newMessage={newMessage}
                                isLoading={isLoading || conversationLoading}
                                conversations={this.sortListConversations(
                                    conversations
                                )}
                                messages={messages}
                                openConversationPage={openConversationPage}
                                onUploadFile={(params) =>
                                    this.s3.handleUploadS3(params)
                                }
                                senderId={senderId}
                                getAllJobDetail={(positionsWithConversation) =>
                                    this.handleLoadingAllJobDetails(
                                        positionsWithConversation
                                    )
                                }
                            />
                        ) : (
                            <BroadcastMessage
                                openConversation={(positionId) =>
                                    this.props.getMessagesOfPosition(
                                        positionId,
                                        1
                                    )
                                }
                                positionsWithMessages={positionsWithMessages}
                                messagesOfPosition={messagesOfPosition}
                                onLoadingMessages={onLoadingMessages}
                                doSaveMessage={(positionId, messageText) =>
                                    this.doSaveMessage(positionId, messageText)
                                }
                                positionId={this.props.messagePositionId}
                                onUploadFile={(params) =>
                                    this.s3.handleUploadS3(params)
                                }
                                getAllJobDetail={(positionsWithMessages) =>
                                    this.handleLoadingAllJobDetails(
                                        positionsWithMessages
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </SwipeableDrawer>
        );
    }
}

const mapStateToProps = ({
    messageReducer,
    directMessageReducer: directMessages,
    conversationReducer,
    positionReducer,
}) => {
    const { conversations, loadingJobById, loading } = conversationReducer;
    return {
        ...messageReducer,
        ...positionReducer,
        loadingJobById,
        directMessages,
        conversations,
        conversationLoading: loading,
    };
};

const mapActionToProps = {
    closeMessageModal,
    changeMessageTab,
    getAllMessages,
    getMessagesOfPosition,
    sendMessageToPosition,
    getDirectMessage,
    getConversationList,
    sendCurrentPosition,
    getJobById,
    getCandidatesByPosition,
    getAssignmentsByPosition,
};

export default connect(mapStateToProps, mapActionToProps)(MessageModal);

/**
 * Charge Rates Action
 */
import {
    GET_CHARGERATES,
    GET_CHARGERATES_SUCCESS,
    GET_CHARGERATES_FAILURE,
    POST_CHARGERATES,
    POST_CHARGERATES_SUCCESS,
    POST_CHARGERATES_FAILURE
} from './types';

/**
 * Action Get Charge Rates
 */
export const getChargeRates = () => ({
    type: GET_CHARGERATES
});

export const getChargeRatesSuccess = (response) => ({
    type: GET_CHARGERATES_SUCCESS,
    payload: response
});

export const getChargeRatesFailure = (error) => ({
    type: GET_CHARGERATES_FAILURE,
    payload: error
});

/**
 * Action Post Charge Rates
 */
export const postChargeRates = () => ({
    type: POST_CHARGERATES
});

export const postChargeRatesSuccess = (response) => ({
    type: POST_CHARGERATES_SUCCESS,
    payload: response
});

export const postChargeRatesFailure = (error) => ({
    type: POST_CHARGERATES_FAILURE,
    payload: error
});

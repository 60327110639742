/**
 * Create Shift & Position Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { CREATE_NEW_SHIFT, EDIT_SHIFT, CHECK_OVERLAPPED } from "Actions/types";

import {
  createNewShiftSuccess,
  createNewShiftFailure,
  editShiftSuccess,
  editShiftFailure,
  getPostedJobs,
  getUpcomingJobs,
  editShift,
  showErrorResponse,
  getAssignmentsByPosition,
  updateJobsAfterEdited,
  addJobAfterReposted,
  addJobCalendarAfterReposted,
  updateJobCalendarAfterEdit,
} from "Actions";

import { isSuccess } from "Helpers/ResponseStatus";
import RoutePaths from "Constants/RoutePaths";

import {
  createShiftRequest,
  editShiftRequest,
  checkOverllapedRequest,
} from "Services/employers/ShiftService";
import {
  createPositionRequest,
  editPositionRequest,
} from "Services/employers/PositionService";

const reloadPostedPage = 1;
const isNeedToRedirect = (history, callbackCallAction) => {
  if (
    !history.location ||
    history.location.pathname === RoutePaths.CALENDAR.WEEKLY ||
    history.location.pathname === RoutePaths.CALENDAR.MONTHLY
  ) {
    return false;
  }
  if (
    !history.location ||
    history.location.pathname !== RoutePaths.MY_GIGS.UPCOMING
  ) {
    history.push(RoutePaths.MY_GIGS.UPCOMING);
    return true;
  }

  return false;
};

/**
 * Request to create new shift
 */
function* handleCreateNewShift({ payload }) {
  const { successCallback, errorCallback, history } = payload;

  try {
    const createdShift = yield call(createShiftRequest, payload.positionData);

    if (createdShift.data.data && createdShift.data.data.id) {
      payload.positionData.shift_id = createdShift.data.data.id;
      const createdPosition = yield call(
        createPositionRequest,
        payload.positionData
      );

      if (createdPosition.data.data && createdPosition.data.data.id) {
        yield put(createNewShiftSuccess(createdShift));
        successCallback();

        if (!isNeedToRedirect(history)) {
          const positionData = {
            ...payload.positionData,
            ...createdPosition.data.data,
          };
          if (
            history.location.pathname === RoutePaths.CALENDAR.WEEKLY ||
            history.location.pathname === RoutePaths.CALENDAR.MONTHLY
          ) {
            yield put(addJobCalendarAfterReposted(positionData));
          } else {
            yield put(addJobAfterReposted(positionData));
          }
          // yield put(getPostedJobs(reloadPostedPage));
          // yield put(addJobAfterReposted(positionData));
        }
      } else {
        yield put(createNewShiftFailure(createdShift));
        errorCallback();
      }
    } else {
      yield put(createNewShiftFailure(createdShift));
      errorCallback();
    }
  } catch (error) {
    yield put(createNewShiftFailure(error));
    errorCallback();
  }
}

function* handleCheckOverllaped({ payload }) {
  const {
    params,
    confirmCallback,
    errorCallback,
    history,
    successCallback,
    goToPostedPage,
  } = payload;

  try {
    if (!params.hasScheduleChanged) {
      yield put(
        editShift(
          params,
          history,
          successCallback,
          errorCallback,
          goToPostedPage
        )
      );
    } else {
      const response = yield call(checkOverllapedRequest, {
        schedules: params.schedule,
        position_id: params.position_id,
      });

      if (isSuccess(response)) {
        if (response.data.data.length > 0) {
          const employeeName = response.data.data.join(" , ");
          confirmCallback(
            employeeName,
            params,
            history,
            successCallback,
            errorCallback
          );
        } else {
          yield put(editShift(params, history, successCallback, errorCallback));
        }
      }
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

function* handleEditShift({ payload }) {
  const {
    successCallback,
    errorCallback,
    history,
    positionData,
    goToPostedPage,
  } = payload;
  try {
    const editedShift = yield call(editShiftRequest, positionData);

    if (isSuccess(editedShift)) {
      const editedPosition = yield call(
        editPositionRequest,
        payload.positionData
      );

      if (isSuccess(editedPosition)) {
        yield put(editShiftSuccess(editedPosition));
        yield put(updateJobCalendarAfterEdit(positionData));
        //yield put(getAssignmentsByPosition(positionData.position_id));

        successCallback();

        if (goToPostedPage && !isNeedToRedirect(history)) {
          // yield put(getPostedJobs(reloadPostedPage));
          yield put(updateJobsAfterEdited(positionData));
        }
      } else {
        yield put(editShiftFailure(editedShift));
        errorCallback();
      }
    } else {
      yield put(editShiftFailure(editedShift));
      errorCallback();
    }
  } catch (error) {
    yield put(editShiftFailure(error));
    errorCallback();
  }
}

/**
 * Create shift
 */
export function* watchCreateShift() {
  yield takeEvery(CREATE_NEW_SHIFT, handleCreateNewShift);
}

export function* watchCheckOverllaped() {
  yield takeEvery(CHECK_OVERLAPPED, handleCheckOverllaped);
}

export function* watchEditShift() {
  yield takeEvery(EDIT_SHIFT, handleEditShift);
}

/**
 * Shift Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(watchCreateShift),
    fork(watchEditShift),
    fork(watchCheckOverllaped),
  ]);
}

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import Grid from "@material-ui/core/Grid";

class Rating extends Component {
    renderAverageRating() {
        const { average } = this.props;
        const average_component = [];

        for (let i = 1; i <= 5; i++) {
            if (average > 0 && i <= average + 1) {
                average_component.push(
                    <i
                        key={`rating-${i}`}
                        className="material-icons report-rating-icon"
                    >
                        star
                    </i>
                );
            } else {
                average_component.push(
                    <i
                        key={`rating-${i}`}
                        className="material-icons report-rating-icon inactive"
                    >
                        star
                    </i>
                );
            }
        }

        return average_component;
    }

    render() {
        const { totalRatings } = this.props;
        return (
            <RctCollapsibleCard>
                <h1 className="report-total-header">
                    <FormattedMessage id="reports.panelHeader.totalRatings" />
                </h1>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="report-rating-wrapper"
                >
                    <Grid item xs={12}>
                        <div className="report-rating-counter">
                            {totalRatings}{" "}
                            <FormattedMessage id="reports.ratings" />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderAverageRating()}
                    </Grid>
                </Grid>
            </RctCollapsibleCard>
        );
    }
}

export default Rating;

import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import IntlMessages from 'Util/IntlMessages';
import Warning from 'Assets/img/SweetAlert/warning.png';

class DeleteInternalEmployeeDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { openDeleteDialog } = this.props;
        return (
            <div className="sweet-alert-wrapper-delete">
                <SweetAlert
                         custom
                         customIcon={Warning}
                    btnSize="sm"
                    show={openDeleteDialog}
                    showCancel
                    confirmBtnText={<span className="text-uppercase-ok"><IntlMessages id="txt.confirm"/></span>}
                  
                    cancelBtnText={<span className="text-uppercase-ok"><IntlMessages id="button.cancel"/></span>}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    title={<IntlMessages id="txt.confirm"/>}
                    onConfirm={() => this.props.confirmDelete()}
                    onCancel={() => this.props.closeDeleteDialog()}>
                    <IntlMessages id="internalEmployee.message.popup.delete"/>
                </SweetAlert>
            </div>
        );
    }
}

export default DeleteInternalEmployeeDialog;

/**
 * Job Sagas
 */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { isSuccess } from "Helpers/ResponseStatus";

import {
  GET_UPCOMING_JOBS,
  GET_POSTED_JOBS,
  GET_FILLED_JOBS,
  GET_COMPLETED_JOBS,
  GET_PENDING_JOBS,
  GET_ALL_JOBS,
  GET_JOB_BY_ID,
  GET_JOB_MORE_INFO,
  GET_JOB_INFORMATION_BY_ID,
  GET_NUDGABLE_SEEKER_FOR_POSITION,
  CANCEL_JOB,
  GET_CALENDAR_JOBS,
  GET_EMPLOYEE_OF_JOB,
  UPDATE_PROMOTED_STATUS,
  GET_DOCUMENT_OF_EMPLOYEE,
  GET_DOCUMENT_OF_EMPLOYEE_SUCCESS,
  GET_DOCUMENT_OF_EMPLOYEE_FAILURE,
  ADD_HISTORY,
  DELETE_EMPLOYEE_EXPERIENCE,
  DELETE_HISTORY,
} from "Actions/types";

import {
  showErrorResponse,
  getUpcomingJobsSuccess,
  getUpcomingJobsFailure,
  getPostedJobs,
  getPostedJobsSuccess,
  getPostedJobsFailure,
  getFilledJobsSuccess,
  getFilledJobsFailure,
  getCompletedJobsSuccess,
  getCompletedJobsFailure,
  getPendingJobsSuccess,
  getPendingJobsFailure,
  getAllJobsSuccess,
  getNudgableSeekerForPositionSuccess,
  getNudgableSeekerForPositionFailure,
  getJobInformationByIdSuccess,
  getJobInformationByIdFailure,
  getJobByIdSuccess,
  getJobByIdFailure,
  getJobMoreInfoSuccess,
  getJobInfFailure,
  cancelJobSuccess,
  cancelJobFailure,
  getCalendarJobsSuccess,
  getCalendarJobsFailure,
  hideRootSpinner,
  updatePromotedStatusSuccess,
  getJobOfEmployeeSuccess,
  getJobOfEmployeeFailure,
  getDocumentOfEmployeeSuccess,
  getDocumentOfEmployeeFailure,
  deleteEmployeeExperienceSuccess,
  deleteEmployeeExperienceFailure,
  addHistoryFailure,
  addHistorySuccess,
  getSeekerDetail,
} from "Actions";

import {
  getUpcomingJobRequest,
  getPostedJobsRequest,
  getFilledJobRequest,
  getCompletedJobRequest,
  getPendingJobRequest,
  getJobByIdRequest,
  getJobMoreInfoRequest,
  cancelJobRequest,
  getCalendarViewJobsRequest,
  getJobInformationRequest,
} from "Services/employers/JobService";

import {
  getAllJobsRequest,
  updatePromotedStatusRequest,
  getJobOfEmployeeRequest,
  getDocumentOfEmployeeRequest,
  deleteEmployeeExperienceRequest,
  addHistoryRequest,
  deleteHistoryRequest,
} from "Services/omni/JobService";

/**
 * Action Get Jobs From Server
 */
function* handleGetUpcomingJobs({ payload }) {
  try {
    const response = yield call(getUpcomingJobRequest, payload);
    //let jobs       = [];
    let data = {};
    if (response.data && isSuccess(response)) {
      data = {
        jobs: response.data.data,
        page: payload.paged,
      };
    }
    yield put(getUpcomingJobsSuccess(data));
  } catch (error) {
    yield put(getUpcomingJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetPostedJobs({ payload }) {
  try {
    const { page, startDate, endDate, locationId } = payload;
    const response = yield call(
      getPostedJobsRequest,
      page,
      startDate,
      endDate,
      locationId
    );
    yield put(hideRootSpinner());
    yield put(getPostedJobsSuccess(response.data.data));
  } catch (error) {
    yield put(hideRootSpinner());
    yield put(getPostedJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetFilledJobs({ payload }) {
  try {
    const { page, startDate, endDate, locationId } = payload;
    const response = yield call(
      getFilledJobRequest,
      page,
      startDate,
      endDate,
      locationId
    );

    yield put(hideRootSpinner());
    yield put(getFilledJobsSuccess(response.data.data));
  } catch (error) {
    yield put(hideRootSpinner());
    yield put(getFilledJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetCompletedJobs({ payload }) {
  try {
    const { page, startDate, endDate, locationId } = payload;
    const response = yield call(
      getCompletedJobRequest,
      page,
      startDate,
      endDate,
      locationId
    );

    yield put(hideRootSpinner());
    yield put(getCompletedJobsSuccess(response.data.data));
  } catch (error) {
    yield put(hideRootSpinner());
    yield put(getCompletedJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetPendingJobs({ payload }) {
  try {
    const { page, startDate, endDate, locationId } = payload;
    const response = yield call(
      getPendingJobRequest,
      page,
      startDate,
      endDate,
      locationId
    );
    let data = {};

    if (response.data && isSuccess(response)) {
      data = {
        jobs: response.data.data,
        page: payload.page,
      };
    }

    yield put(hideRootSpinner());
    yield put(getPendingJobsSuccess(data));
  } catch (error) {
    yield put(hideRootSpinner());
    yield put(getPendingJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetAllJobs({ payload }) {
  try {
    const response = yield call(getAllJobsRequest, payload);

    yield put(
      getAllJobsSuccess({
        listJobs: response.data.data,
        pagination: response.data.pagination,
      })
    );
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

function* handleGetJobInformationById({ payload }) {
  try {
    const response = yield call(getJobInformationRequest, {
      positionId: payload.positionId,
    });
    yield put(getJobInformationByIdSuccess(response.data.data));
  } catch (error) {
    yield put(getJobInformationByIdFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetJobById({ payload }) {
  try {
    const response = yield call(getJobByIdRequest, {
      positionId: payload.positionId,
    });
    yield put(getJobByIdSuccess(response.data.data));
  } catch (error) {
    yield put(getJobByIdFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetJobMoreInfo({ payload }) {
  try {
    const response = yield call(getJobMoreInfoRequest, {
      positionId: payload.positionId,
    });
    yield put(getJobMoreInfoSuccess(response.data[0]));
  } catch (error) {
    yield put(getJobMoreInfoFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetNudgableSeekerForPosition(payload) {
  try {
    const response = yield call(getNudgableSeekersForPositionRequest, {
      positionId: payload.positionId,
    });
    yield put(getNudgableSeekerForPositionSuccess(response.data.data));
  } catch (error) {
    yield put(getNudgableSeekerForPositionFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleCancelJobWorker({ job }) {
  try {
    const response = yield call(cancelJobRequest, job);

    if (isSuccess(response)) {
      yield put(cancelJobSuccess({ jobId: job.position_id }));
    } else {
      yield put(cancelJobFailure(response.data.error.message));
    }
  } catch (error) {
    yield put(cancelJobFailure());
    yield put(showErrorResponse(error));
  }
}

function* handleGetCalendarJobs({ payload }) {
  try {
    const { startDate, endDate, locationId } = payload;
    const response = yield call(
      getCalendarViewJobsRequest,
      startDate,
      endDate,
      locationId
    );

    if (response.data && isSuccess(response)) {
      yield put(getCalendarJobsSuccess(response.data.data));
    } else {
      yield put(getCalendarJobsFailure(response));
      yield put(showErrorResponse(response));
    }
  } catch (error) {
    yield put(getCalendarJobsFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleUpdatePromotedStatus({ payload }) {
  try {
    const { positionId, status } = payload;
    const response = yield call(
      updatePromotedStatusRequest,
      positionId,
      status
    );

    if (response.data && isSuccess(response)) {
      yield put(updatePromotedStatusSuccess(positionId, status));
      yield put(getPostedJobs(1));
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

function* handleGetJobOfEmployee({ payload }) {
  try {
    const { employeeId, params } = payload;
    const response = yield call(getJobOfEmployeeRequest, employeeId, params);
    if (response) {
      yield put(getJobOfEmployeeSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getJobOfEmployeeFailure(error));
  }
}

function* handleGetDocumentOfEmployee({ payload }) {
  try {
    const { omniEmployeeId } = payload;
    const response = yield call(getDocumentOfEmployeeRequest, omniEmployeeId);
    if (response) {
      yield put(getDocumentOfEmployeeSuccess(response.data.data));
      yield put(hideRootSpinner());
    }
  } catch (error) {
    yield put(getDocumentOfEmployeeFailure(error));
  }
}

function* handleDeleteExperience({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(deleteEmployeeExperienceRequest, params);
    if (response) {
      yield put(deleteEmployeeExperienceSuccess(response));
    }
  } catch (error) {
    yield put(deleteEmployeeExperienceFailure(error));
  }
}

function* handleAddHitory({ payload }) {
  try {
    const response = yield call(addHistoryRequest, payload);
    if (response) {
      yield put(getSeekerDetail(payload.employee_id));

      yield put(hideRootSpinner());
    }
  } catch (error) {
    yield put(addHistoryFailure(error));
  }
}

function* handleDeleteHistory({ payload }) {
  try {
    const response = yield call(deleteHistoryRequest, payload);
    if (response.data && isSuccess(response)) {
      yield put(hideRootSpinner());
      yield put(getSeekerDetail(payload.employee_id));
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

/**
 * Action Get Job
 */
export function* watchGetUpcomingJobs() {
  yield takeEvery(GET_UPCOMING_JOBS, handleGetUpcomingJobs);
}

export function* watchGetPostedJobs() {
  yield takeLatest(GET_POSTED_JOBS, handleGetPostedJobs);
}

export function* watchGetFilledJobs() {
  yield takeLatest(GET_FILLED_JOBS, handleGetFilledJobs);
}

export function* watchGetCompletedJobs() {
  yield takeLatest(GET_COMPLETED_JOBS, handleGetCompletedJobs);
}

export function* watchGetPendingJobs() {
  yield takeLatest(GET_PENDING_JOBS, handleGetPendingJobs);
}

export function* watchGetAllJobs() {
  yield takeLatest(GET_ALL_JOBS, handleGetAllJobs);
}

export function* watchGetJobInformationById() {
  yield takeEvery(GET_JOB_INFORMATION_BY_ID, handleGetJobInformationById);
}

export function* watchGetJobById() {
  yield takeEvery(GET_JOB_BY_ID, handleGetJobById);
}

export function* watchGetJobMoreInfo() {
  yield takeEvery(GET_JOB_MORE_INFO, handleGetJobMoreInfo);
}

export function* watchGetNudgeSeekerForPositionById() {
  yield takeEvery(
    GET_NUDGABLE_SEEKER_FOR_POSITION,
    handleGetNudgableSeekerForPosition
  );
}

export function* watchCancelJobById() {
  yield takeEvery(CANCEL_JOB, handleCancelJobWorker);
}

export function* watchCalendarJobs() {
  yield takeEvery(GET_CALENDAR_JOBS, handleGetCalendarJobs);
}

export function* watchUpdatePromotedStatus() {
  yield takeLatest(UPDATE_PROMOTED_STATUS, handleUpdatePromotedStatus);
}

export function* watchGetJobOfEmployee() {
  yield takeLatest(GET_EMPLOYEE_OF_JOB, handleGetJobOfEmployee);
}

export function* watchGetDocumentOfEmployee() {
  yield takeLatest(GET_DOCUMENT_OF_EMPLOYEE, handleGetDocumentOfEmployee);
}

export function* watchDeleteEmployeeExperience() {
  yield takeLatest(DELETE_EMPLOYEE_EXPERIENCE, handleDeleteExperience);
}

export function* watchAddHistory() {
  yield takeLatest(ADD_HISTORY, handleAddHitory);
}

export function* watchDeleteHitory() {
  yield takeLatest(DELETE_HISTORY, handleDeleteHistory);
}

/**
 * Jobs Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(watchGetUpcomingJobs),
    fork(watchGetPostedJobs),
    fork(watchGetFilledJobs),
    fork(watchGetCompletedJobs),
    fork(watchGetPendingJobs),
    fork(watchGetAllJobs),
    fork(watchGetJobInformationById),
    fork(watchGetJobById),
    fork(watchGetJobMoreInfo),
    fork(watchGetNudgeSeekerForPositionById),
    fork(watchCancelJobById),
    fork(watchCalendarJobs),
    fork(watchUpdatePromotedStatus),
    fork(watchGetJobOfEmployee),
    fork(watchGetDocumentOfEmployee),
    fork(watchDeleteEmployeeExperience),
    fork(watchAddHistory),
    fork(watchDeleteHitory),
  ]);
}

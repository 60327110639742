/**
 * Position Sagas
 */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { isSuccess } from "Helpers/ResponseStatus";
import MyGig from "Constants/MyGig";

import {
  NO_SHOW,
  DECLINE_ASSIGNMENT,
  DECLINE_CANDIDATE,
  CHECK_IN_ASSIGNMENT,
  CHECK_OUT_ASSIGNMENT,
  CHECK_OVERLAPS,
  ACCEPT_CANDIDATE,
  CREATE_NEW_POSITION,
  EDIT_POSITION,
  TIMECARD_CREATE,
  GET_CANDIDATES_BY_POSITION,
  GET_DECLINED_CANDIDATES_BY_POSITION,
  GET_ASSIGNMENTS_BY_POSITION,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT,
  SWITCH_JOB_STATUS,
  CHECK_WORKING_OVER_SIX_DAY,
  CHECK_SEEKER_ACCEPT_TO_JOB,
} from "Actions/types";

import {
  getCalendarJobs,
  updateNumberOfSeeker,
  showErrorResponse,
  noShowSuccess,
  noShowFail,
  getAssignmentsByPosition,
  isOverlaps,
  notOverlaps,
  declineAssignmentSuccess,
  declineAssignmentFail,
  declineCandidateSuccess,
  declineCandidateFail,
  acceptCandidateSuccess,
  acceptCandidateFail,
  createNewPositionSuccess,
  createNewPositionFailure,
  editPositionSuccess,
  editPositionFailure,
  createTimecardSuccess,
  createTimecardFailure,
  getCandidatesByPositionSuccess,
  getCandidatesByPositionFailure,
  checkAreAllAssignmentsCheckedIn,
  checkAreAllAssignmentsCheckedInSuccess,
  checkAreAllAssignmentsCheckedInFailure,
  checkAreAllAssignmentsCheckedOut,
  checkAreAllAssignmentsCheckedOutSuccess,
  checkAreAllAssignmentsCheckedOutFailure,
  getDeclinedCandidatesByPosition,
  getDeclinedCandidatesByPositionSuccess,
  getDeclinedCandidatesByPositionFailure,
  getAssignmentsByPositionSuccess,
  getAssignmentsByPositionFailure,
  switchJobStatusSuccess,
  getPostedJobs,
  checkInAssignmentSuccess,
  checkInAssignmentFail,
  checkOutAssignmentSuccess,
  checkOutAssignmentFail,
  removeJobFromList,
  warningSeekerOverSixDay,
  checkWorkingSixDaySuccess,
  addSeekerForPosition,
  checkSeekerAcceptToJobSuccess,
  showWarningAcceptSeekerToJob,
} from "Actions";
import {
  acceptAssignmentsRequest,
  getCheckOverlapsEmployeeRequest,
  createPositionRequest,
  editPositionRequest,
  getCandidatesByPositionRequest,
  getDeclinedCandidatesByPositionRequest,
  updatePrivateJobRequest,
  checkSeekerWorkSixdayRequest,
  checkSeekerNotToJob,
  checkSeekerAcceptToJobRequest,
} from "Services/employers/PositionService";

import { declineCandidateRequest } from "Services/employers/CandidateService";

import {
  noShowAssignmentsRequest,
  declineAssignmentsRequest,
  getAssignmentsByPositionRequest,
  checkInAssignmentsRequest,
  checkOutAssignmentsRequest,
  checkAreAllAssignmentsCheckedInRequest,
  checkAreAllAssignmentsCheckedOutRequest,
} from "Services/employers/AssignmentService";

import { createTimecard } from "Services/employers/TimecardService";
import { CHECK_SEEKER_NOT_TO_JOB } from "../actions/types";
import {
  acceptCandidate,
  checkSeekerNotToJobFailure,
  checkSeekerNotToJobSuccess,
  warningSeeker48h,
  warningSeekerIn8hours,
  warningSeekerIsStudent,
  warningSeekerOverLap,
} from "../actions/PositionActions";
import ApiCodes from "../constants/ApiCodes";

/*
 * Check employee working 6 day
 */
function* handleCheckWorkingSixDay({ payload }) {
  const { positionId, isAll, seekerId, callBackSuccess } = payload;
  try {
    const response = yield call(
      checkSeekerWorkSixdayRequest,
      seekerId,
      isAll,
      positionId
    );
    const listCheckReponse = response.data.data;
    if (isSuccess(response)) {
      if (listCheckReponse.every((seeker) => seeker.isOver === false)) {
        if (callBackSuccess) {
          yield put(checkWorkingSixDaySuccess());
          callBackSuccess(positionId);
        }
      } else {
        const data = [];
        listCheckReponse.forEach((element) => {
          if (element.isOver) {
            data.push(element.fullName);
          }
        });
        yield put(warningSeekerOverSixDay(data));
      }
    } else {
      yield put(showErrorResponse());
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

/*
 * Check employee overlaps
 */
function* handleCheckOverlaps({ payload }) {
  const { positionId, employee, employeeId, checkFromJobCard } = payload;
  try {
    const response = yield call(getCheckOverlapsEmployeeRequest, {
      positionId,
      employeeId,
    });
    if (isSuccess(response)) {
      const isOverlap = response.data.data;
      if (isOverlap === true) {
        yield put(isOverlaps());
      } else {
        yield put(notOverlaps(employee, checkFromJobCard));
      }
    } else {
      yield put(isOverlaps());
    }
  } catch (error) {
    yield put(isOverlaps());
  }
}

function* handleAcceptCandidate({ payload }) {
  const { positionId, candidateIds, currentPage, isSelectAll, dateRange } =
    payload;
  const { calendarPage } = MyGig.currentPage;
  try {
    const response = yield call(acceptAssignmentsRequest, {
      positionId,
      responseId: candidateIds,
      isSelectAll,
    });
    if (isSuccess(response)) {
      yield put(
        updateNumberOfSeeker({
          jobPositionId: positionId,
          typeOfAction: MyGig.typeOfAction.acceptCandidateSuccess,
          currentPage,
          seekerList: response.data.data.response_ids,
        })
      );

      yield put(getCandidatesByPositionRequest(positionId));

      yield put(acceptCandidateSuccess(response.data.data));

      if (currentPage === calendarPage) {
        yield put(getCalendarJobs(dateRange.start, dateRange.end));
      }
    } else {
      yield put(acceptCandidateFail(response.data));
    }
  } catch (error) {
    yield put(acceptCandidateFail(error));
  }
}

function* handleDeclineAssignment({ payload }) {
  const { assignmentIds, positionId, currentPage, isSelectAll, dateRange } =
    payload;
  const { calendarPage } = MyGig.currentPage;

  try {
    const response = yield call(
      declineAssignmentsRequest,
      positionId,
      assignmentIds,
      isSelectAll
    );
    if (isSuccess(response)) {
      yield put(
        updateNumberOfSeeker({
          jobPositionId: positionId,
          typeOfAction: MyGig.typeOfAction.declineAssignmentSuccess,
          currentPage,
          seekerList: assignmentIds,
        })
      );

      yield put(declineAssignmentSuccess(assignmentIds));

      if (currentPage === calendarPage) {
        yield put(getCalendarJobs(dateRange.start, dateRange.end));
      }
    } else {
      const {
        error: { message },
      } = response.data;
      yield put(declineAssignmentFail(message));
    }
  } catch (error) {
    yield put(declineAssignmentFail(error.message));
  }
}

function* handleDeclineCandidate({ payload }) {
  const { candidateIds, positionId, currentPage, isSelectAll, dateRange } =
    payload;
  const { calendarPage } = MyGig.currentPage;
  try {
    const response = yield call(
      declineCandidateRequest,
      candidateIds,
      isSelectAll,
      positionId
    );
    if (isSuccess(response)) {
      yield put(declineCandidateSuccess(candidateIds, positionId));
      yield put(
        updateNumberOfSeeker({
          jobPositionId: positionId,
          typeOfAction: MyGig.typeOfAction.declineCandidateSuccess,
          currentPage,
          seekerList: candidateIds,
        })
      );

      //yield put(getCandidatesByPosition(positionId));

      if (currentPage === calendarPage) {
        yield put(getCalendarJobs(dateRange.start, dateRange.end));
      }
    } else {
      yield put(declineCandidateFail());
    }
  } catch (error) {
    yield put(declineCandidateFail(error));
  }
}

function* handleCheckInAssignment({ payload }) {
  const { assignmentIds, positionId, isSelectAll, dateTimeRequest } = payload;

  try {
    const response = yield call(
      checkInAssignmentsRequest,
      positionId,
      assignmentIds,
      isSelectAll,
      dateTimeRequest
    );
    if (isSuccess(response)) {
      yield put(removeJobFromList(positionId, "upcomingPage"));
      yield put(checkInAssignmentSuccess());
      yield put(getAssignmentsByPosition(positionId));
      yield put(checkAreAllAssignmentsCheckedIn(positionId));
      yield put(checkAreAllAssignmentsCheckedOut(positionId));
    } else {
      yield put(checkInAssignmentFail());
    }
  } catch (error) {
    yield put(checkInAssignmentFail());
  }
}

function* handleCheckOutAssignment({ payload }) {
  const { assignmentIds, positionId, isSelectAll, dateTimeRequest } = payload;

  try {
    const response = yield call(
      checkOutAssignmentsRequest,
      positionId,
      assignmentIds,
      isSelectAll,
      dateTimeRequest
    );
    if (isSuccess(response)) {
      yield put(checkOutAssignmentSuccess());
      yield put(getAssignmentsByPosition(positionId));
      yield put(checkAreAllAssignmentsCheckedOut(positionId));
    } else {
      yield put(checkOutAssignmentFail());
    }
  } catch (error) {
    yield put(checkOutAssignmentFail(error));
  }
}
/**
 * Request to create new position
 */
function* handleCreateNewPosition({ payload }) {
  try {
    const response = yield call(createPositionRequest, payload);
    yield put(createNewPositionSuccess(response));
  } catch (error) {
    yield put(createNewPositionFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleEditPosition({ payload }) {
  try {
    const response = yield call(editPositionRequest, payload);
    if (isSuccess(response)) {
      yield put(editPositionSuccess(response));
    } else {
      yield put(editPositionFailure(response));
    }
  } catch (error) {
    yield put(editPositionFailure(error));
    yield put(showErrorResponse(error));
  }
}

export function* handleNoShowAssignment({ payload }) {
  const { assignedScheduleId, positionId } = payload;
  try {
    const response = yield call(noShowAssignmentsRequest, assignedScheduleId);

    if (isSuccess(response)) {
      yield put(noShowSuccess(response));
      yield put(getAssignmentsByPosition(positionId));
    } else {
      yield put(noShowFail());
    }
  } catch (error) {
    yield put(noShowFail(error));
    yield put(showErrorResponse(error));
  }
}

/**
 * Request to create new timecard
 */
function* handleCreateTimecard({ payload }) {
  const { positionObject, seekerIds, successCallback, failureCallback } =
    payload;

  try {
    const response = yield call(createTimecard, positionObject, seekerIds);

    if (response.data && isSuccess(response)) {
      yield put(createTimecardSuccess(response));

      successCallback();
    } else {
      yield put(createTimecardFailure());
      let errorMessage = "";
      if (
        response.data &&
        !!response.data.error &&
        !!response.data.error.message
      ) {
        errorMessage = response.data.error.message;
      }

      failureCallback(errorMessage);
    }
  } catch (error) {
    yield put(createTimecardFailure(error));
    failureCallback();
  }
}

function* handleGetCandidatesByPosition({ payload }) {
  const { positionId, page, keySearch } = payload;
  try {
    const response = yield call(
      getCandidatesByPositionRequest,
      positionId,
      page,
      keySearch
    );
    if (response.data && isSuccess(response)) {
      yield put(getCandidatesByPositionSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getCandidatesByPositionFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetDeclinedCandidatesByPosition({ payload }) {
  const { positionId, page, keySearch } = payload;

  try {
    const response = yield call(
      getDeclinedCandidatesByPositionRequest,
      positionId,
      page,
      keySearch
    );

    if (response.data && isSuccess(response)) {
      yield put(getDeclinedCandidatesByPositionSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getDeclinedCandidatesByPositionFailure(error));
    yield put(showErrorResponse(error));
  }
}

function* handleGetAssignmentsByPosition({ payload }) {
  const { positionId, page, searchKey } = payload;
  try {
    const response = yield call(
      getAssignmentsByPositionRequest,
      positionId,
      page,
      searchKey
    );
    if (response.data && isSuccess(response)) {
      yield put(getAssignmentsByPositionSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getAssignmentsByPositionFailure(error));
  }
}

function* handleCheckAllAssignmentsAreCheckedIn({ payload }) {
  const { positionId } = payload;
  try {
    const response = yield call(
      checkAreAllAssignmentsCheckedInRequest,
      positionId
    );
    if (response.data) {
      yield put(
        checkAreAllAssignmentsCheckedInSuccess(response.data.data.status)
      );
    }
  } catch (error) {
    yield put(checkAreAllAssignmentsCheckedInFailure(error));
  }
}

function* handleCheckAllAssignmentsAreCheckedOut({ payload }) {
  const { positionId } = payload;
  try {
    const response = yield call(
      checkAreAllAssignmentsCheckedOutRequest,
      positionId
    );
    if (response.data) {
      yield put(
        checkAreAllAssignmentsCheckedOutSuccess(response.data.data.status)
      );
    }
  } catch (error) {
    yield put(checkAreAllAssignmentsCheckedOutFailure(error));
  }
}

function* handleSwitchJobStatus({ payload }) {
  try {
    const response = yield call(updatePrivateJobRequest, payload);
    if (response) {
      yield put(
        switchJobStatusSuccess({ ...{ data: response.data.data }, payload })
      );
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

function* handleCheckSeekerNotToJob({ payload }) {
  const { employeeId, positionId, callBackSuccess } = payload;

  try {
    const response = yield call(checkSeekerNotToJob, employeeId, positionId);
    const { status } = response.data.data || {
      status: false,
    };

    if (status) {
      if (status === ApiCodes.STATUS_OVER_6_DAYS) {
        yield put(warningSeekerOverSixDay(response.data));
      }
      if (status === ApiCodes.STATUS_OVER_8_HOURS) {
        yield put(warningSeekerIn8hours(response.data));
      }
    } else {
      if (
        response.data.code === ApiCodes.RESPONSE_OK &&
        response.data.message === "OK"
      ) {
        callBackSuccess();
      }
    }

    yield put(checkSeekerNotToJobSuccess());
  } catch (error) {
    const { status, message } = error.response.data.error;
    if (status === ApiCodes.STATUS_WORKING_TIME) {
      yield put(warningSeeker48h(message));
    } else if (status === ApiCodes.STATUS_VISA_STUDENT) {
      yield put(warningSeekerIsStudent(message));
    }
    yield put(checkSeekerNotToJobFailure(error));
  }
}

function* handleCheckSeekerAcceptToJob({ payload }) {
  const { positionId, currentPage, seekerList } = payload;

  const seekerIds = seekerList.map((e) => e.id);

  try {
    const response = yield call(
      checkSeekerAcceptToJobRequest,
      seekerIds,
      positionId
    );

    const { successes, errors, warnings } = response.data.data;

    const totalSeeker = successes.length + errors.length + warnings.length;

    const listSeeker = [...successes, ...errors, ...warnings];

    if (totalSeeker === 1) {
      const firstSeeker = listSeeker[0];

      if (firstSeeker.hasOwnProperty("type")) {
        switch (firstSeeker.type) {
          case "overlaps":
            yield put(warningSeekerOverLap("overlaps"));
            break;
          case "visaStudent":
            yield put(warningSeekerIsStudent("visaStudent"));
            break;
          case "8Hours":
            yield put(warningSeekerIn8hours("8Hours"));
            break;
          case "Over6Days":
            yield put(warningSeekerOverSixDay("Over6Days"));
            break;
          case "optIn":
            yield put(warningSeeker48h("optIn"));
            break;
          default:
            break;
        }
      } else {
        yield put(
          acceptCandidate({
            positionId,
            candidateIds: [seekerList[0].job_response_id],
            currentPage,
          })
        );
      }
    } else {
      const errorList = [
        ...response.data.data.errors,
        ...response.data.data.warnings,
      ];

      const successListIds = successes.map((e) => e.employee_id);

      if (successes.length > 0) {
        const filter_seeker = seekerList.filter((e) =>
          successListIds.includes(e.id)
        );

        const fil_job_res_id = filter_seeker.map((e) => e.job_response_id);
        yield put(
          acceptCandidate({
            positionId,
            candidateIds: fil_job_res_id,
            currentPage,
          })
        );
      }

      yield put(showWarningAcceptSeekerToJob(successes, errorList));
    }
  } catch (error) {
    yield put(checkSeekerNotToJobFailure(error));
  }
}

export function* watchNoShowAssignment() {
  yield takeEvery(NO_SHOW, handleNoShowAssignment);
}

export function* watchCheckOverlaps() {
  yield takeLatest(CHECK_OVERLAPS, handleCheckOverlaps);
}

export function* watchAcceptCandidate() {
  yield takeEvery(ACCEPT_CANDIDATE, handleAcceptCandidate);
}

export function* watchDeclineAssignment() {
  yield takeEvery(DECLINE_ASSIGNMENT, handleDeclineAssignment);
}

export function* watchDeclineCandidate() {
  yield takeEvery(DECLINE_CANDIDATE, handleDeclineCandidate);
}

export function* watchCheckInAssignment() {
  yield takeEvery(CHECK_IN_ASSIGNMENT, handleCheckInAssignment);
}

export function* watchCheckOutAssignment() {
  yield takeEvery(CHECK_OUT_ASSIGNMENT, handleCheckOutAssignment);
}
/**
 * Create position
 */
export function* watchCreatePosition() {
  yield takeEvery(CREATE_NEW_POSITION, handleCreateNewPosition);
}

/**
 * Edit position
 */
export function* watchEditPosition() {
  yield takeEvery(EDIT_POSITION, handleEditPosition);
}

/**
 * Watch create timecard action
 */
export function* watchCreateTimecard() {
  yield takeEvery(TIMECARD_CREATE, handleCreateTimecard);
}

/**
 * Watch get candidates by position
 */
export function* watchGetCandidatesByPosition() {
  yield takeLatest(GET_CANDIDATES_BY_POSITION, handleGetCandidatesByPosition);
}

/**
 * Watch get declined candidates by position
 */
export function* watchGetDeclinedCandidatesByPosition() {
  yield takeLatest(
    GET_DECLINED_CANDIDATES_BY_POSITION,
    handleGetDeclinedCandidatesByPosition
  );
}

/**
 * Watch get assignments by position
 */
export function* watchGetAssignmentsByPosition() {
  yield takeLatest(GET_ASSIGNMENTS_BY_POSITION, handleGetAssignmentsByPosition);
}
/**
 * Watch check all assignments are checked in
 */
export function* watchCheckAllAssignmentsAreCheckedIn() {
  yield takeLatest(
    CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN,
    handleCheckAllAssignmentsAreCheckedIn
  );
}

/**
 * Watch check all assignments are checked out
 */
export function* watchCheckAllAssignmentsAreCheckedOut() {
  yield takeLatest(
    CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT,
    handleCheckAllAssignmentsAreCheckedOut
  );
}

export function* watchSwitchJobStatus() {
  yield takeLatest(SWITCH_JOB_STATUS, handleSwitchJobStatus);
}
/**
 * Watch check working six day
 */
export function* watchCheckWorkingSixDay() {
  yield takeEvery(CHECK_WORKING_OVER_SIX_DAY, handleCheckWorkingSixDay);
}

/**
 * Watch check working six day
 */
export function* watchCheckSeekerNotToJob() {
  yield takeEvery(CHECK_SEEKER_NOT_TO_JOB, handleCheckSeekerNotToJob);
}

export function* watchCheckSeekerAcceptToJob() {
  yield takeEvery(CHECK_SEEKER_ACCEPT_TO_JOB, handleCheckSeekerAcceptToJob);
}

/**
 * Position Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(watchCreatePosition),
    fork(watchEditPosition),
    fork(watchCreateTimecard),
    fork(watchGetCandidatesByPosition),
    fork(watchGetDeclinedCandidatesByPosition),
    fork(watchGetAssignmentsByPosition),
    fork(watchCheckOverlaps),
    fork(watchAcceptCandidate),
    fork(watchDeclineAssignment),
    fork(watchDeclineCandidate),
    fork(watchCheckInAssignment),
    fork(watchCheckOutAssignment),
    fork(watchNoShowAssignment),
    fork(watchSwitchJobStatus),
    fork(watchCheckAllAssignmentsAreCheckedIn),
    fork(watchCheckAllAssignmentsAreCheckedOut),
    fork(watchCheckWorkingSixDay),
    fork(watchCheckSeekerNotToJob),
    fork(watchCheckSeekerAcceptToJob),
  ]);
}

import React, { Component, Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { roundByDecimals } from 'Helpers/helpers';
import IntlMessages from 'Util/IntlMessages';
import DownloadExcelReport from 'Routes/reports/partials/DownloadExcelReport';

class AWRReport extends Component {
    renderRows() {
        return (
            <Table>
                <TableBody className="report-table">
                    <TableRow hover>
                        <TableCell className="flex-group">
                            <h1 style={{fontFamily: 'museosans-500'}}>AWR - Agency Workers Regulations</h1>
                        </TableCell>
                        <TableCell className=''>
                            <DownloadExcelReport
                                reportType={'awr'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    render() {
        return (
            <div className="report-table-container weekly-report-wrapper" style={{height: 'fit-content'}}>
                {this.renderRows()}
            </div>
        );
    }
}

export default AWRReport;

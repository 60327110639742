/**
 * Confirm password actions
 */
import {
    CONFIRM_PASSWORD_SHOW_FORM,
    CONFIRM_PASSWORD_HIDE_FORM,
    CONFIRM_PASSWORD_VERIFY_PASSWORD,
    CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE,
    CONFIRM_PASSWORD_WRONG_PASSWORD
} from './types';


export const showConfirmPasswordForm = (onConfirmSuccessFn) => ({
    type   : CONFIRM_PASSWORD_SHOW_FORM,
    payload: {onConfirmSuccessFn}
});

export const hideConfirmPasswordForm = () => ({
    type: CONFIRM_PASSWORD_HIDE_FORM
});

export const confirmPassword = (password) => ({
    type   : CONFIRM_PASSWORD_VERIFY_PASSWORD,
    payload: {password}
});

export const confirmPasswordSuccess = (response) => ({
    type   : CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS,
    payload: response
});

export const confirmPasswordFailure = (error) => ({
    type   : CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE,
    payload: error
});

export const confirmPasswordWrongPassword = () => ({
    type   : CONFIRM_PASSWORD_WRONG_PASSWORD
});

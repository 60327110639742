import React, { Component } from 'react';
import IntlMessages from 'Util/IntlMessages';

class NoResult extends Component {
    render() {
        return (
            <div className="tab-circle-wrapper">
                <IntlMessages id="modalAllJobs.emptyResults" />
            </div>
        );
    }
};

export default NoResult;

import React, { Component } from "react";
import IntlMessages from 'Util/IntlMessages';
import Tabs from '@material-ui/core/es/Tabs/Tabs';
import Tab from '@material-ui/core/es/Tab/Tab';
import Table from '@material-ui/core/es/Table/Table';
import TableHead from '@material-ui/core/es/TableHead/TableHead';
import TableRow from '@material-ui/core/es/TableRow/TableRow';
import TableCell from '@material-ui/core/es/TableCell/TableCell';
import TableBody from '@material-ui/core/es/TableBody/TableBody';
import moment from 'moment';
import NoneGig from 'Assets/img/none-gig.png';
import NoneComment from 'Assets/img/none-comments.png';
import NoneExperience from 'Assets/img/none-experience.png';

class SeekerTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabHistoryIndex: 0,
        };
    }

    componentWillUnmount() {
        this.setState({
            tabHistoryIndex: 0,
        });
    }

    handleHistoryTabChange = (event, value) => {
        this.setState({ tabHistoryIndex: value });
    }

    getGigDateFormat = (unixTime) => {
        if (!unixTime) return "";
        return moment.unix(unixTime).format('DD/MM/YYYY');
    }

    renderTabGig(hasHistory) {
        const { seeker } = this.props;
        return (
            <div className="gigs-table-wrapper max-height-250 auto-scroll-y only">
                {
                    !hasHistory && (
                        <div className="tab-none">
                            <img
                                src={NoneGig}
                                className="tab-none-image"
                                alt={<IntlMessages id="tab.noGig" />}
                            />
                            <h3><IntlMessages id="tab.noGig" /></h3>
                        </div>
                    )
                }
                {
                    hasHistory && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{<IntlMessages id="widgets.date" />}</TableCell>
                                    <TableCell>{<IntlMessages id="words.role" />}</TableCell>
                                    <TableCell>{<IntlMessages id="words.provider" />}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (seeker.employment_history || []).map(element => (
                                        <TableRow key={Math.random()}>
                                            <TableCell component="th" scope="row">
                                                {this.getGigDateFormat(element.start_unix_timestamp)}
                                            </TableCell>
                                            <TableCell>{element.skill_name}</TableCell>
                                            <TableCell>{element.provider_name}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </div>
        );
    }

    renderTabExperience() {
        const { seeker } = this.props;
        const experiences = [];

        (seeker.experiences || []).forEach(ex => {
            if (ex.employment_position !== "")
                experiences.push(ex);
        });

        const hasExperience = !!experiences.length;

        return (
            <ul className="list-group list-group-flush list-experience max-height-150 auto-scroll-y only">
                {
                    !hasExperience && (
                        <div className="tab-none">
                            <img
                                src={NoneExperience}
                                className="tab-none-image"
                                alt={<IntlMessages id="tab.noExperience" />}
                            />
                            <h3><IntlMessages id="tab.noExperience" /></h3>
                        </div>
                    )
                }
                {
                    hasExperience && experiences.map((ele, index) => (
                        <li
                            key={index}
                            className="list-group-item smaller-font"
                            style={{ paddingBottom: 5, paddingTop: 5 }}

                        >
                            {!!ele.employment_position && (
                                <div>
                                    <IntlMessages
                                        id="tab.experienceAt"
                                        values={{
                                            position: ele.employment_position,
                                            company : ele.employment_company
                                        }}
                                    />
                                </div>
                            )}
                            {
                                !!ele.start_unix_timestamp && (
                                    <div>{this.getGigDateFormat(ele.start_unix_timestamp)} - <IntlMessages id="common.hoursWithSpace" values={{ hours: (ele.duration_hours) }} /></div>
                                )
                            }
                            <div>{ele.employment_leave}</div>
                        </li>
                    ))
                }
            </ul>
        );
    }

    renderTabComment() {
        const { seeker } = this.props;
        const listComment = [];

        (seeker.employment_history || []).forEach(element => {
            if (element.employer_comment)
                listComment.push(element);
        });


        const hasComment = !!listComment.length;

        return (
            <ul className="list-group list-group-flush list-comments max-height-150 auto-scroll-y only">
                {
                    !hasComment && (
                        <div className="tab-none">
                            <img src={NoneComment}
                                className="tab-none-image"
                                alt={<IntlMessages id="tab.noComment" />}
                            />
                            <h3><IntlMessages id="tab.noComment" /></h3>
                        </div>
                    )
                }
                {
                    hasComment && listComment.map((ele, index) => (
                        <li
                            key={index}
                            className="list-group-item smaller-font"
                            style={{ paddingBottom: 5, paddingTop: 5 }}
                        >
                            <div>{ele.employer_comment}</div>
                            <div><strong>{ele.provider_name}</strong></div>
                        </li>
                    ))
                }
            </ul>
        );
    }

    render() {
        const { tabHistoryIndex } = this.state;
        const { seeker } = this.props;
        const hasHistory = !!((seeker.employment_history || []).length);
        return (
            <div>
                <Tabs className="tabs" value={tabHistoryIndex}
                    onChange={this.handleHistoryTabChange}>
                    <Tab label={<IntlMessages id="words.gigs" />} />
                    <Tab label={<IntlMessages id="words.experience" />} />
                    <Tab label={<IntlMessages id="words.comments" />} />
                </Tabs>
                {
                    tabHistoryIndex === 0 && this.renderTabGig(hasHistory)
                }
                {
                    tabHistoryIndex === 1 && this.renderTabExperience()
                }
                {
                    tabHistoryIndex === 2 && this.renderTabComment()
                }
            </div>
        );
    }
}

export default SeekerTab;

/**
 * Internal Employee Actions
 */
import {
    GET_INTERNAL_EMPLOYEE_LIST,
    GET_INTERNAL_EMPLOYEE_LIST_SUCCESS,
    GET_INTERNAL_EMPLOYEE_LIST_FAILURE,
    ADD_INTERNAL_EMPLOYEE,
    ADD_INTERNAL_EMPLOYEE_SUCCESS,
    ADD_INTERNAL_EMPLOYEE_FAILURE,
    UPDATE_INTERNAL_EMPLOYEE,
    UPDATE_INTERNAL_EMPLOYEE_SUCCESS,
    UPDATE_INTERNAL_EMPLOYEE_FAILURE,
    DELETE_INTERNAL_EMPLOYEE,
    DELETE_INTERNAL_EMPLOYEE_SUCCESS,
    DELETE_INTERNAL_EMPLOYEE_FAILURE,
    CSV_IMPORT_INTERNAL_EMPLOYEES,
    CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS,
    CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE
} from './types';

export const getInternalEmployeeList = () => ({
    type: GET_INTERNAL_EMPLOYEE_LIST
});

export const getInternalEmployeeListSuccess = (response) => ({
    type: GET_INTERNAL_EMPLOYEE_LIST_SUCCESS,
    payload: response
});

export const getInternalEmployeeListFailure = (error) => ({
    type: GET_INTERNAL_EMPLOYEE_LIST_FAILURE,
    payload: error
});

export const addInternalEmployee = (newInternalEmployee, successCallback, errorCallback) => ({
    type: ADD_INTERNAL_EMPLOYEE,
    payload: { newInternalEmployee, successCallback, errorCallback },
});

export const addInternalEmployeeSuccess = (response) => ({
    type: ADD_INTERNAL_EMPLOYEE_SUCCESS,
    payload: response.data
});

export const addInternalEmployeeFailure = (error) => ({
    type: ADD_INTERNAL_EMPLOYEE_FAILURE,
    payload: error
});

export const updateInternalEmployee = (updateInternalEmployee, successCallback, errorCallback) =>  ({
    type: UPDATE_INTERNAL_EMPLOYEE,
    payload: { updateInternalEmployee, successCallback, errorCallback }
});

export const updateInternalEmployeeSuccess = (response) => ({
    type: UPDATE_INTERNAL_EMPLOYEE_SUCCESS,
    payload: response
});

export const updateInternalEmployeeFailure = (error) => ({
    type: UPDATE_INTERNAL_EMPLOYEE_FAILURE,
    payload: error
});

export const deleteInternalEmployee = (internalEmployeeId) => ({
    type: DELETE_INTERNAL_EMPLOYEE,
    payload: { internalEmployeeId }
});

export const deleteInternalEmployeeSuccess = (response) => ({
    type: DELETE_INTERNAL_EMPLOYEE_SUCCESS,
    payload: response
});

export const deleteInternalEmployeeFailure = (error) => ({
    type: DELETE_INTERNAL_EMPLOYEE_FAILURE,
});

export const csvImportInternalEmployees = (data, successCallback, errorCallback) => ({
    type: CSV_IMPORT_INTERNAL_EMPLOYEES,
    payload: { data, successCallback, errorCallback }
});

export const csvImportInternalEmployeesSuccess = (response) => ({
    type: CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS,
    payload: response.data
});

export const csvImportInternalEmployeesFailure = (error) => ({
    type: CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE,
    payload: error
});

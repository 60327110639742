/**
 * Confirm password Reducer
 */
import {
    CONFIRM_PASSWORD_SHOW_FORM,
    CONFIRM_PASSWORD_HIDE_FORM,
    CONFIRM_PASSWORD_VERIFY_PASSWORD,
    CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE,
    CONFIRM_PASSWORD_WRONG_PASSWORD
} from "Actions/types";

// initial state
const INIT_STATE = {
    isShowForm     : false,
    isWrongPassword: false,
    onLoading      : false,
    confirmSuccess : false,
    callbackSuccess: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CONFIRM_PASSWORD_SHOW_FORM:
            return {
                ...state,
                isShowForm: true,
                callbackSuccess: action.payload.onConfirmSuccessFn
            };

        case CONFIRM_PASSWORD_HIDE_FORM:
            return {
                ...state,
                isShowForm     : false,
                callbackSuccess: null,
                isWrongPassword: false,
                confirmSuccess : false
            };

        case CONFIRM_PASSWORD_VERIFY_PASSWORD:
            return {
                ...state,
                onLoading: true
            };

        case CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS:
            return {
                ...state,
                onLoading: false,
                confirmSuccess: true,
                isWrongPassword: false
            };

        case CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE:
            return {
                ...state,
                onLoading: false
            };

        case CONFIRM_PASSWORD_WRONG_PASSWORD:
            return {
                ...state,
                onLoading: false,
                isWrongPassword: true
            };

        default:
            return { ...state };
    }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import Warning from "Assets/img/SweetAlert/warning.png";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";

import { createDirectMessage, closeConversation } from "Actions";
import MessageBlock from "Components/Model/DirectMessage/MessageBlock";
import KeyCodes from "Constants/KeyCodes";
import MyGig from "Constants/MyGig";

class DetailConversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageText: "",
            confirmClose: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ messageText: "" });
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (!this.props.ignoreAutoToBottom) {
            this.dummyMessage.scrollIntoView({ behavior: "instant" });
        }
    };

    handleKeyPress(e) {
        const key = e.which || e.keyCode;
        const { conversationId } = this.props;
        if (key === KeyCodes.ENTER) {
            this.saveMessage(conversationId);
        }
    }

    handleScrollConversation(e) {
        // Scroll to top of list messages
        if (typeof e.originalTarget !== "undefined") {
            if (e.originalTarget.scrollTop === 0) {
                this.props.onScrollLoadmore();
            }
        }
    }

    toggleConfirmationClose = () => {
        this.setState({ confirmClose: !this.state.confirmClose });
    };

    saveMessage(conversationId) {
        const { messageText } = this.state;

        if (messageText.trim() !== "") {
            this.props.createDirectMessage({
                message: messageText,
                conversation_id: conversationId,
            });
        }
    }

    render() {
        const {
            messages,
            onLoading,
            onSaving,
            conversationId,
            block,
        } = this.props;
        const { messageText, confirmClose } = this.state;
        const isOpen = block === MyGig.conversation.OPEN_CONVERSATION;
        return (
            <div className="chat-wrapper">
                <div className="chat-main-body">
                    <Scrollbars
                        className="rct-scroll"
                        autoHide
                        onScroll={(e) => this.handleScrollConversation(e)}
                        style={{
                            height: "calc(100vh - 148px)",
                        }}
                    >
                        <div className="chat-body">
                            {onLoading && (
                                <div className="center vertical-space-smaller">
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                    />
                                </div>
                            )}

                            {!onLoading &&
                                messages.map((message) => (
                                    <MessageBlock
                                        isEmployer={
                                            message.author_employee_id === null
                                        }
                                        key={`message-block-${message.id}`}
                                        messageObject={message}
                                    />
                                ))}

                            <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                    this.dummyMessage = el;
                                }}
                            />
                            {isOpen && (
                                <div className="chat-input-wrapper">
                                    {/* <InputGroup> */}
                                    <FormattedMessage id="messages.inputMessage.placeholder">
                                        {(placeholder) => (
                                            <Input
                                                autoFocus
                                                type="textarea"
                                                placeholder={placeholder}
                                                disabled={onLoading || onSaving}
                                                value={messageText}
                                                onKeyPress={(e) =>
                                                    this.handleKeyPress(e)
                                                }
                                                onChange={(e) =>
                                                    this.setState({
                                                        messageText:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FormattedMessage>

                                    <InputGroupAddon addonType="append">
                                        <Button
                                            onClick={
                                                this.toggleConfirmationClose
                                            }
                                            className="close-conversation-button gig-button"
                                        >
                                            <FormattedMessage id="directMessage.button.close" />
                                        </Button>
                                        <IconButton
                                            disabled={
                                                block ===
                                                MyGig.conversation
                                                    .CLOSE_CONVERSATION
                                            }
                                            className="btn-warning send-message"
                                            onClick={() =>
                                                this.saveMessage(conversationId)
                                            }
                                        >
                                            {onSaving ? (
                                                <CircularProgress
                                                    className="progress-warning"
                                                    size={40}
                                                    mode="determinaeight: calc(75vh - 50px);te"
                                                    value={40}
                                                    min={0}
                                                    max={50}
                                                />
                                            ) : (
                                                <i className="material-icons">
                                                    {block ===
                                                    MyGig.conversation
                                                        .OPEN_CONVERSATION
                                                        ? "send"
                                                        : "block"}
                                                </i>
                                            )}
                                        </IconButton>
                                    </InputGroupAddon>
                                    {/* </InputGroup> */}
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                </div>

                <Modal hideBackdrop open={confirmClose}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        showCancel
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        cancelBtnText={
                            <FormattedMessage id="common.button.no" />
                        }
                        title={<FormattedMessage id="common.button.confirm" />}
                        onConfirm={() => {
                            this.toggleConfirmationClose();
                            this.props.closeConversation(conversationId);
                        }}
                        onCancel={this.toggleConfirmationClose}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <FormattedMessage id="directMessage.confirmClose" />
                    </SweetAlert>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ directMessageReducer, conversationReducer }) => {
    const { conversationId } = directMessageReducer;
    const { conversations } = conversationReducer;
    const currentConversation = conversations.filter(
        (conversation) => conversation.id === conversationId
    )[0];

    return {
        conversationId,
        block: currentConversation
            ? currentConversation.status
            : MyGig.conversation.OPEN_CONVERSATION,
    };
};

const mapActionToProps = {
    createDirectMessage,
    closeConversation,
};

export default connect(mapStateToProps, mapActionToProps)(DetailConversation);

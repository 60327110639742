import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { FormGroup, InputGroup, InputGroupAddon, Input } from "reactstrap";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import SeekerSignalImage from "Assets/img/gig-icons/seeker-signal-icon.png";

import { getSeekerSignal, clearListSeekerSignal,getCalendarJobs} from "Actions";

import ListSeekers from "Components/ListSeekers/ListSeekers";
import Spinner from "Components/Model/Spinner";
import IntlMessages from "Util/IntlMessages";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const NumberGigThresholds = [
    { min: 1, max: 10 },
    { min: 11, max: 30 },
    { min: 31, max: 50 },
    { min: 50 },
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SeekerSignal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterForm: {
                searchText: "",
                isGigApproved: false,
                thresholdIndex: "",
                skillIds: [],
            },
            paginator: {
                page: 1,
            },
            skillDropdown: {
                open: false,
            },
        };
    }

    setCurrentPaginatorPage(page) {
        if (this.state.paginator.page !== page) {
            this.setState({
                paginator: { page },
            });
        }
    }

    getCurrentPaginatorPage() {
        return this.state.paginator.page;
    }

    getFilterDataForAPI(filterForm) {
        const params = {};

        if (!filterForm) {
            filterForm = this.state.filterForm;
        }

        if (filterForm.skillIds.length > 0) {
            params.skillIds = filterForm.skillIds;
        }

        return params;
    }

    filterData(seekers) {
        const { filterForm } = this.state;
        let responseData = seekers;

        if (filterForm.isGigApproved) {
            responseData = responseData.filter(
                (seeker) => Number(seeker.gig_approved) === 1
            );
        }

        if (filterForm.searchText.length > 0) {
            const reg = new RegExp(`.*${filterForm.searchText}.*`, "gi");

            responseData = responseData.filter((seeker) =>
                seeker.fullname.match(reg)
            );
        }

        if (filterForm.thresholdIndex !== "") {
            responseData = responseData.filter((seeker) => {
                const totalAssignments = Number(seeker.total_assignments);
                const thresholdRange =
                    NumberGigThresholds[filterForm.thresholdIndex];

                return (
                    !isNaN(totalAssignments) &&
                    ((thresholdRange.min &&
                        totalAssignments >= thresholdRange.min) ||
                        !thresholdRange.min) &&
                    ((thresholdRange.max &&
                        totalAssignments <= thresholdRange.max) ||
                        !thresholdRange.max)
                );
            });
        }

        return responseData;
    }

    toggleGigApproved() {
        const { filterForm } = this.state;
        filterForm.isGigApproved = !filterForm.isGigApproved;

        this.setState({ filterForm });
    }

    openSkillDropdown(forceOpen) {
        this.setState({
            skillDropdown: {
                ...this.state.skillDropdown,
                open: Boolean(forceOpen),
            },
        });
    }

    handleCloseModal = () => {
        this.setState({
            filterForm: {
                searchText: "",
                isGigApproved: false,
                thresholdIndex: "",
                skillIds: [],
            },
            paginator: {
                page: 1,
            },
        });
        this.props.onClose();
        this.props.clearListSeekerSignal();
    };

    filterSeekerName(searchText) {
        const filterForm = {
            ...this.state.filterForm,
            searchText,
        };

        this.setState({ filterForm });
    }

    filterSeekersBySkill(skillIds) {
        const resetPage = 1;
        const { filterForm } = this.state;

        filterForm.skillIds = skillIds;

        this.setState({ filterForm });
        this.setCurrentPaginatorPage(resetPage);
        this.openSkillDropdown(false);
        this.props.getSeekerSignal(skillIds, 1);
    }

    filterSeekersByNumberGig(thresholdIndex) {
        const filterForm = {
            ...this.state.filterForm,
            thresholdIndex,
        };

        this.setState({ filterForm });
    }

    handleScrollLoadMoreSeekers(e) {
        const isBottom = (e) => e.scrollHeight - e.scrollTop === e.clientHeight;
        const nextPage = this.getCurrentPaginatorPage() + 1;
        const { skillIds } = this.state.filterForm;
        const { onLoadNextPage, nextPageAvailable } = this.props;

        if (
            isBottom(e.target) &&
            onLoadNextPage === false &&
            nextPageAvailable === true
        ) {
            this.setCurrentPaginatorPage(nextPage);
            this.props.getSeekerSignal(skillIds, nextPage);
        }
    }

    renderForm() {
        const { filterForm, skillDropdown } = this.state;

        const { listSkillType } = this.props;

        return (
            <div className="nudge-filter-form">
                <div className="row">
                    <FormGroup className="col-sm-5 col-xs-12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <i className="material-icons">search</i>
                            </InputGroupAddon>

                            <FormattedMessage id="nudgeSeeker.placeholders.searchInput">
                                {(placeholderText) => (
                                    <Input
                                        placeholder={placeholderText}
                                        value={filterForm.searchText}
                                        onChange={(e) =>
                                            this.filterSeekerName(
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                            </FormattedMessage>
                        </InputGroup>
                    </FormGroup>
                </div>

                <div className="row">
                    <FormGroup className="col-sm-3 col-xs-12">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="dropdown-skill">
                                <FormattedMessage id="nudgeSeeker.placeholders.experience" />
                            </InputLabel>
                            <Select
                                value={filterForm.skillIds}
                                open={skillDropdown.open}
                                onOpen={() => this.openSkillDropdown(true)}
                                onClose={() => this.openSkillDropdown(false)}
                                onChange={(e) =>
                                    this.filterSeekersBySkill(e.target.value)
                                }
                                className="filter-control"
                                MenuProps={MenuProps}
                                inputProps={{
                                    id: "dropdown-skill",
                                }}
                            >
                                {listSkillType.map((skill) => (
                                    <MenuItem
                                        value={skill.type_id}
                                        key={`select-skill-${skill.type_id}`}
                                    >
                                        {skill.type_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </div>
            </div>
        );
    }

    render() {
        const { listSeeker, onLoadNextPage, onLoading, isOpen } = this.props;

        return (
            <div className="nudge-seeker-outer-wrapper">
                <SwipeableDrawer
                    disableEnforceFocus
                    anchor={"right"}
                    open={isOpen}
                    onOpen={() => false}
                    onClose={this.handleCloseModal}
                    className="MuiDrawer-paper-seeker-signal"
                    // classes={{ page: "MuiDrawer-paper-seeker-signal" }}
                >
                    <div className="drawer-wrapper">
                        <Spinner spinnerWidth={70} show={onLoading} />

                        <div
                            className="nudge-seeker-inner-wrapper"
                            onScroll={(e) =>
                                this.handleScrollLoadMoreSeekers(e)
                            }
                        >
                            <h1 className="nudge-seeker-title">
                                <Fragment>
                                    {/* <img
                                        src={SeekerSignalImage}
                                        style={{ width: 40, marginTop: -3, marginRight: 5 }}
                                        alt={<IntlMessages id="settings.seekerSignal" />}
                                    /> */}
                                    <FormattedHTMLMessage id="seekerSignal.title" />
                                </Fragment>
                                <i
                                    style={{ marginTop: 4 }}
                                    className="zmdi zmdi-close font-2x pull-right on-hover"
                                    onClick={this.handleCloseModal}
                                />
                            </h1>

                            {this.renderForm()}

                            <div className="nudge-seeker-lists">
                                {!onLoading && listSeeker.length === 0 && (
                                    <div className="uppercase font-bold center vertical-space-small">
                                        <FormattedMessage id="internalEmployee.empty" />
                                    </div>
                                )}

                                <ListSeekers
                                    isSeekerSignal
                                    listSeekers={this.filterData(listSeeker)}
                                    isNudge={false}
                                    isAdd={false}
                                />
                            </div>

                            {onLoadNextPage && (
                                <div className="pd-10 center">
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                        mode="determinate"
                                        value={40}
                                        min={0}
                                        max={50}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = ({ seekerSignalReducer, skillReducer }) => ({
    listSeeker: seekerSignalReducer.listSeeker,
    onLoading: seekerSignalReducer.onLoading,
    listSkillType: skillReducer.skillGroup,
    onLoadNextPage: seekerSignalReducer.onLoadNextPage,
    nextPageAvailable: seekerSignalReducer.nextPageAvailable,
    listSkill: skillReducer.skills,
});

const mapActionToProps = {
    getCalendarJobs,
    getSeekerSignal,
    clearListSeekerSignal,
};

export default connect(mapStateToProps, mapActionToProps)(SeekerSignal);

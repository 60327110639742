import {
    OPEN_MESSAGE_MODAL,
    OPEN_DIRECT_MESSAGE_MODAL,
    CLOSE_MESSAGE_MODAL,
    CHANGE_MESSAGE_TAB,
    GET_MESSAGES,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILURE,
    GET_MESSAGES_BY_POSITION,
    GET_MESSAGES_BY_POSITION_SUCCESS,
    GET_MESSAGES_BY_POSITION_FAILURE,
    SEND_MESSAGE_TO_POSITION,
    SEND_MESSAGE_TO_POSITION_SUCCESS,
    SEND_MESSAGE_TO_POSITION_FAILURE
} from './types';

export const changeMessageTab = () => ({
    type   : CHANGE_MESSAGE_TAB
});

export const openMessageModal = (jobObject) => ({
    type   : OPEN_MESSAGE_MODAL,
    payload: {jobObject}
});

export const openDirectMessageModal = (conversationId) => ({
    type   : OPEN_DIRECT_MESSAGE_MODAL,
    payload: {conversationId}
});

export const closeMessageModal = () => ({
    type: CLOSE_MESSAGE_MODAL
});

/**
 * Get all message
 */
export const getAllMessages = (page = 1) => ({
    type: GET_MESSAGES,
    payload: {page}
});

/**
 * Get all message success
 */
export const getAllMessagesSuccess = (response, page) => ({
    type   : GET_MESSAGES_SUCCESS,
    payload: {response, page}
});

/**
 * Get all message failure
 */
export const getAllMessagesFailure = (error) => ({
    type   : GET_MESSAGES_FAILURE,
    payload: error
});

/**
 * Get all message of position
 */
export const getMessagesOfPosition = (positionId, page) => ({
    type   : GET_MESSAGES_BY_POSITION,
    payload: {positionId, page}
});

/**
 * Get all message of position success
 */
export const getMessagesOfPositionSuccess = (response) => ({
    type   : GET_MESSAGES_BY_POSITION_SUCCESS,
    payload: response
});

/**
 * Get all message of position failure
 */
export const getMessagesOfPositionFailure = (error) => ({
    type   : GET_MESSAGES_BY_POSITION_FAILURE,
    payload: error
});

/**
 * Send new message to position
 */
export const sendMessageToPosition = (positionId, messageData, callbackSuccess, callbackFailure) => ({
    type: SEND_MESSAGE_TO_POSITION,
    payload: {
        positionId,
        messageData,
        callbackSuccess,
        callbackFailure
    }
});

/**
 * Send new message to position success
 */
export const sendMessageToPositionSuccess = (response) => ({
    type   : SEND_MESSAGE_TO_POSITION_SUCCESS,
    payload: response
});

/**
 * Send new message to position failure
 */
export const sendMessageToPositionFailure = (error) => ({
    type   : SEND_MESSAGE_TO_POSITION_FAILURE,
    payload: error
});

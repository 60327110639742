/**
 * Excel Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    SEND_MAIL_TIMESHEET_PENDING
} from 'Actions/types';

import {
    sendMailTimesheetPendingSuccess,
    showErrorResponse,
    hideRootSpinner,
} from 'Actions';

import {
    sendMailPendingRemoteRequest
} from 'Services/employers/EmployerSendMailService';

function* handleSendMailTimesheetPending({ payload }) {
    try {
        const { startDate, endDate, locationId } = payload;
        const response               = +locationId ? yield call(sendMailPendingRemoteRequest, startDate, endDate, locationId)
                                                   : yield call(sendMailPendingRemoteRequest, startDate, endDate);
        const { data }               = response.data;

        yield put(hideRootSpinner());
        yield put(sendMailTimesheetPendingSuccess(response));
    } catch (error) {
        yield put(hideRootSpinner());
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch download excel timesheet pending
 */
export function* watchSendMailTimesheetPending() {
    yield takeEvery( SEND_MAIL_TIMESHEET_PENDING, handleSendMailTimesheetPending);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchSendMailTimesheetPending)
    ]);
}

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_INTERNAL_EMPLOYEE_LIST,
    ADD_INTERNAL_EMPLOYEE,
    UPDATE_INTERNAL_EMPLOYEE, 
    DELETE_INTERNAL_EMPLOYEE,
    CSV_IMPORT_INTERNAL_EMPLOYEES
} from 'Actions/types';

import {
    getInternalEmployeeList,
    getInternalEmployeeListSuccess,
    getInternalEmployeeListFailure,
    addInternalEmployeeSuccess,
    addInternalEmployeeFailure,
    updateInternalEmployeeSuccess,
    updateInternalEmployeeFailure,
    deleteInternalEmployeeSuccess,
    deleteInternalEmployeeFailure,
    csvImportInternalEmployeesSuccess,
    csvImportInternalEmployeesFailure,
    showErrorResponse,
} from 'Actions';

import {
    checkInternalEmployeeExistenceRequest,
    addInternalEmployeeRequest,
    updateInternalEmployeeRequest,
    deleteInternalEmployeeRequest,
    csvImportInternalEmployeesRequest,
    getInternalEmployeeListRequest
} from 'Services/employers/InternalEmployeeService';

function* handleGetInternalEmployeeList() {

    try {
        const response = yield call(getInternalEmployeeListRequest);
        if (isSuccess(response)) {
            yield put(getInternalEmployeeListSuccess(response.data.data));
        } else {
            yield put(getInternalEmployeeListFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getInternalEmployeeListFailure(error));
        yield put(showErrorResponse(response));
    }
}

function* handleAddInternalEmployee({ payload }) {
    const { newInternalEmployee, successCallback, errorCallback } = payload;
    const { firstName, secondName, email, phone, skills, location } = newInternalEmployee;

    try {
        const internalEmployeeExists = yield call(checkInternalEmployeeExistenceRequest, email);

        if (isSuccess(internalEmployeeExists)) {
            if (internalEmployeeExists.data.data.exists === false) {
                const createdInternalEmployee = yield call(addInternalEmployeeRequest, {
                    territory_id: 1,
                    employee_fname: firstName,
                    employee_sname: secondName,
                    email,
                    phone,
                    skills,
                    location ,
                });
                if (isSuccess(createdInternalEmployee) && createdInternalEmployee.data.data.id) {
                    yield put(addInternalEmployeeSuccess(createdInternalEmployee));
                    return successCallback();
                } 
                    yield put(addInternalEmployeeFailure(createdInternalEmployee));
                    return errorCallback();
                
                
            } 
                yield put(addInternalEmployeeFailure(internalEmployeeExists));
                return errorCallback("internalEmployee.add.failure.exist");
               
        } 
            yield put(addInternalEmployeeFailure(internalEmployeeExists));
            return errorCallback();
        
       
    } catch (error) {
        yield put(addInternalEmployeeFailure(error));
        errorCallback();
    }
}

function* handleUpdateInternalEmployee({ payload }) {
    const { updateInternalEmployee, successCallback, errorCallback } = payload;
    const { email, oldEmail } = updateInternalEmployee;
    try {
        if (email !== oldEmail) {
            const responseCheckExist = yield call(checkInternalEmployeeExistenceRequest, {email});
            if (isSuccess(responseCheckExist)) {
                const internalEmployeeExists = responseCheckExist.data.data.exists;
                if  (internalEmployeeExists === false) {
                    const responseUpdate = yield call(updateInternalEmployeeRequest, updateInternalEmployee);
                    if (isSuccess(responseUpdate)) {
                        yield put(updateInternalEmployeeSuccess(responseUpdate));
                        return successCallback();
                    } 
                        yield put(updateInternalEmployeeFailure(responseUpdate));
                        return errorCallback();
                    
                } 
                    yield put(updateInternalEmployeeFailure(internalEmployeeExists));
                    return errorCallback("internalEmployee.add.failure.exist");
                    
            } 
                yield put(updateInternalEmployeeFailure(responseCheckExist));
                return errorCallback();
            
        } 
            const responseUpdate = yield call(updateInternalEmployeeRequest, updateInternalEmployee);
            if (isSuccess(responseUpdate)) {
                yield put(updateInternalEmployeeSuccess(responseUpdate));
                return successCallback();
            } 
                yield put(updateInternalEmployeeFailure(responseUpdate));
                return errorCallback();
            
        
        
    } catch (error) {
        yield put(updateInternalEmployeeFailure(error));
        errorCallback();
    }
}


function* handleCsvImportInternalEmployees({ payload }) {
    const { data, successCallback, errorCallback } = payload;
    try {
        const response = yield call(csvImportInternalEmployeesRequest, {
            territory_id: 1,
            data
        });
        if (isSuccess(response)) {
            yield put(csvImportInternalEmployeesSuccess(response));
            return successCallback();
        } 
            yield put(csvImportInternalEmployeesFailure(response));
            return errorCallback();
        
    } catch (error) {
        yield put(csvImportInternalEmployeesFailure(error));
        errorCallback();
    }

}

function* handleDeleteInternalEmployee({ payload }) {
    const { internalEmployeeId } = payload;

    try {
        const response = yield call(deleteInternalEmployeeRequest, internalEmployeeId);
        if (isSuccess(response)) {
            yield put(deleteInternalEmployeeSuccess(response));
            yield put(getInternalEmployeeList());
        } else {
            yield put(deleteInternalEmployeeFailure(response));
        }
    } catch (error) {
        yield put(deleteInternalEmployeeFailure(error));
    }
}

export function* watchGetInternalEmployeeList() {
    yield takeEvery(GET_INTERNAL_EMPLOYEE_LIST, handleGetInternalEmployeeList);
}

export function* watchAddInternalEmployee() {
    yield takeEvery(ADD_INTERNAL_EMPLOYEE, handleAddInternalEmployee);
}

export function* watchUpdateInternalEmployee() {
    yield takeEvery(UPDATE_INTERNAL_EMPLOYEE, handleUpdateInternalEmployee);
}

export function* watchDeleteInternalEmployee() {
    yield takeEvery(DELETE_INTERNAL_EMPLOYEE, handleDeleteInternalEmployee);
}

export function* watchCsvImportInternalEmployees() {
    yield takeEvery(CSV_IMPORT_INTERNAL_EMPLOYEES, handleCsvImportInternalEmployees);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchGetInternalEmployeeList),
        fork(watchAddInternalEmployee),
        fork(watchUpdateInternalEmployee),
        fork(watchDeleteInternalEmployee),
        fork(watchCsvImportInternalEmployees),
    ]);
}

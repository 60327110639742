/**
 * User Block Component
 */
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import Popover from "@material-ui/core/es/Popover/Popover";
import LogOutIcon from "Assets/img/gig-icons/logout.png";
// components
import SupportPage from "../Support/Support";
import UserSetting from "Components/UserSetting";

import UserDefault from "Assets/img/default-user.png";
// redux action
import { signoutUser } from "Actions";

// intl messages
import IntlMessages from "Util/IntlMessages";
import StoredData from "Constants/StoredData";
import MyGig from "Constants/MyGig";

import OAuth from "OAuth";

const oauth = new OAuth();

class UserBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDropdownMenu: false,
            isSupportModal: false,
            anchorEl: null,
            fullName: StoredData.get(StoredData.KEYS.EMPLOYER_NAME),
        };
    }

    /**
     * Logout User
     */
    handleLogout() {
        this.props.signoutUser(this.props.redirectTo);
    }

    /**
     * Toggle User Dropdown Menu
     */
    toggleUserDropdownMenu(e = null) {
        const {
            openPopUp: loginDetailPopup,
        } = this.props.loginDetailSettingReducer;
        const {
            openPopUp: businessDetailPopup,
        } = this.props.businessDetailSettingReducer;

        if (!loginDetailPopup && !businessDetailPopup) {
            this.setState({
                userDropdownMenu: !this.state.userDropdownMenu,
                anchorEl: e && e.target ? e.target : null,
            });
        }
    }

    /**
     * Open Support Modal
     */
    openSupportModal() {
        this.setState({ isSupportModal: true });
    }

    /**
     * On Close Support Page
     */
    onCloseSupportPage() {
        this.setState({ isSupportModal: false });
    }

    /**
     * On Submit Support Page
     */
    onSubmitSupport() {
        this.setState({ isSupportModal: false });
        NotificationManager.success("Message has been sent successfully!");
    }

    render() {
        const storedThumbnail = StoredData.get(StoredData.KEYS.EMPLOYER_LOGO);
        const fullName = StoredData.get(StoredData.KEYS.EMPLOYER_NAME);
        const { isSupportModal } = this.state;

        const logoThumbnail =
            storedThumbnail && storedThumbnail.length !== 4
                ? storedThumbnail
                : UserDefault;
                
        return (
            <div className="top-sidebar">
                <div className="sidebar-user-block media">
                    <div className="user-profile">
                        <div className="d-flex align-items-center">
                            <img
                                src={logoThumbnail}
                                alt=""
                                // alt={fullName}
                                className="rounded-circle"
                                width="60"
                                height="60"
                            />

                            <span className="ml-10 font-bold"> {fullName}</span>
                        </div>
                        <i
                            className={"material-icons ml-10 pointer"}
                            onClick={(e) => this.toggleUserDropdownMenu(e)}
                        >
                            expand_more
                        </i>
                    </div>

                    <Popover
                        open={this.state.userDropdownMenu}
                        anchorEl={this.state.anchorEl}
                        onClose={() => this.toggleUserDropdownMenu()}
                        classes={{ paper: "MuiPopover-user-profile" }}
                        // anchorReference="anchorPosition"
                        // anchorPosition={{ top: 98, left: 133 }}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                    >
                        <div className="user-menu-container">
                            <div className="logout-container pointer">
                                <i
                                    className={"material-icons pointer"}
                                    onClick={(e) =>
                                        this.toggleUserDropdownMenu(e)
                                    }
                                >
                                    expand_more
                                </i>

                                <div
                                    className="logout-container__item d-flex"
                                    onClick={() => this.handleLogout()}
                                >
                                    <span className="logout-container__item__title">
                                        Logout
                                    </span>
                                    <img
                                        src={LogOutIcon}
                                        width={20}
                                        height={20}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </Popover>

                    {/* <Dropdown isOpen={this.state.userDropdownMenu} toggle={() => this.toggleUserDropdownMenu()} className="rct-dropdown media-body pt-10">
                        <DropdownToggle nav className="d-flex align-items-center justify-content-between">
                            {fullName}
                            <i className="ti-angle-down pull-right" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <ul className="list-unstyled mb-0">
                                <li className="p-15 border-bottom user-profile-top rounded-top">
                                    <p className="text-white mb-0 fs-14">{fullName}</p>
                                </li>
                                { !oauth.checkCurrentIsOmniStaff() && (
                                <li className="border-top">
                                    <UserSetting className="ti-settings"
                                                 popUpName={<IntlMessages id="widgets.loginDetail"/>}
                                                 popupType={MyGig.LoginDetailPopUp} />
                                </li>
                                )}
                                { !oauth.checkCurrentIsOmniStaff() && (
                                <li className="border-top">
                                    <UserSetting className="ti-briefcase" popUpName={<IntlMessages id="widgets.bussinessDetail"/>} popupType={MyGig.BusinessDetailPopUp} />
                                </li>
                                )}
                                <li className="border-top">
                                    <a href={null} onClick={() => this.handleLogout()}>
                                        <i className="ti ti-power-off" />
                                        <IntlMessages id="widgets.logOut" />
                                    </a>
                                </li>
                            </ul>
                        </DropdownMenu>
                    </Dropdown> */}
                </div>
                <SupportPage
                    isOpen={isSupportModal}
                    onCloseSupportPage={() => this.onCloseSupportPage()}
                    onSubmit={() => this.onSubmitSupport()}
                />
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({
    settings,
    loginDetailSettingReducer,
    businessDetailSettingReducer,
    authUser,
}) => ({
    settings,
    loginDetailSettingReducer,
    businessDetailSettingReducer,
});

const mapActionToProps = {
    signoutUser,
};

export default connect(mapStateToProps, mapActionToProps)(UserBlock);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";

import { getDirectMessage } from "Actions";
import MyGig from "Constants/MyGig";
import UserDefault from "Assets/img/default-user.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class ListOfPositions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPosition: null,
        };
    }

    openConversation(positionId) {
        this.setState({ openPosition: positionId });
        this.props.onOpenConversation(positionId);
    }

    render() {
        const { lists, openPosition } = this.props;

        return (
            <Scrollbars
                className="rct-scroll"
                autoHide
                style={{ height: "calc(100vh - 148px)" }}
            >
                <List className="list-positions">
                    {lists.map((conversation) => {
                        const { id } = conversation;
                        return (
                            <ListItem
                                className={`messages-item ${
                                    openPosition == id ? "open" : ""
                                }`}
                                key={`messages-item-${id}`}
                                onClick={() => {
                                    this.props.getDirectMessage(id, true);
                                }}
                            >
                                <div className="w-100">
                                    <div className="userProfileOuter">
                                        <div className="user-profile-block">
                                            {conversation.status ===
                                            MyGig.conversation
                                                .CLOSE_CONVERSATION ? (
                                                <i className="material-icons">
                                                    block
                                                </i>
                                            ) : (
                                                <i
                                                    style={{
                                                        width: 24,
                                                        height: 24,
                                                    }}
                                                ></i>
                                            )}
                                        </div>
                                        <div className="user-profile">
                                            <img
                                                src={
                                                    conversation.profile_thumbnail &&
                                                    conversation
                                                        .profile_thumbnail
                                                        .length !== 4
                                                        ? conversation.profile_thumbnail
                                                        : UserDefault
                                                }
                                                className="rounded-circle"
                                                width="60"
                                                height="60"
                                            />
                                        </div>
                                        <div className="user-profile-text">
                                            <div className="name">
                                                {conversation.seeker_name}
                                            </div>
                                            <div className="title">
                                                {/* {conversation.title} */}
                                                <div className="dateTime">
                                                    {moment
                                                        .unix(
                                                            conversation.send_at
                                                        )
                                                        .format("DD MMM")}
                                                </div>

                                                {conversation.count_unread !==
                                                    0 && (
                                                    <div className="unreadMessage">
                                                        {" "}
                                                        {
                                                            conversation.count_unread
                                                        }{" "}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className='user-profile-datetime w-30'>
                                            <div className='dateTime'>{moment.unix(conversation.send_at).format('MM-DD-YYYY')}</div>

                                            {conversation.count_unread !== 0 && <div className='unreadMessage'> {conversation.count_unread}  </div>}
                                        </div> */}
                                    </div>
                                </div>
                            </ListItem>
                        );
                    })}
                </List>
            </Scrollbars>
        );
    }
}

const mapActionToProps = {
    getDirectMessage,
};

export default connect(null, mapActionToProps)(ListOfPositions);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import { maxNumberPlus, getTypeOfJobObject } from "Helpers/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getDirectMessage,
  getJobInformationById,
  getJobById,
  getConversationList,
  closeJobInformation,
} from "Actions";
import MyGig from "Constants/MyGig";
import UserDefault from "Assets/img/default-user.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import classNames from "classnames";
import Popover from "@material-ui/core/Popover";
import Information from "Assets/img/Information.png";
import JobDetails from "Components/JobCard/JobDetails";
import JobCard from "Components/JobCard";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "Util/IntlMessages";
class ListOfPositionsDirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorJobDetailsEl: null,
      position: {
        id: null,
      },
      dialogJob: {
        show: false,
      },
      error: false,
      page: 1,
      onClickShowJob: false,
    };
  }

  componentDidMount() {
    const { getAllJobDetail } = this.props;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { openPosition } = nextProps;
    if (
      nextProps.openPosition.id &&
      nextProps.openPosition.id !== this.state.position.id &&
      nextProps.openConversation !== null &&
      nextProps.loadingJobById === false
    ) {
      this.setState({
        position: openPosition,
        anchorJobDetailsEl: openPosition,
      });
    }

    if (
      this.state.onClickShowJob &&
      openPosition.id === null &&
      nextProps.loadingJobById === false
    ) {
      this.setState({
        error: true,
      });
    }
  }

  formatPosition(position) {
    return {
      ...position,
      chargeout_rate: position.charge_rate,
      location_building: position.building,
      location_city: position.city,
      location_postcode: position.postcode,
      location_street: position.street,
      skill_name: position.skill,
    };
  }
  changePagination(e, loadingMoreConversation, isLastPage) {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
        e.target.clientHeight &&
      !loadingMoreConversation &&
      !isLastPage
    ) {
      let page = this.state.page + 1;
      this.props.getConversationList(false, false, page);
      this.setState({
        page: page,
      });
    }
  }
  componentWillUnmount() {
    this.props.closeJobInformation();
    this.setState({
      position: null,
      anchorJobDetailsEl: null,
      error: false,
    });
  }
  formatJobDetail(position) {
    const jobType = getTypeOfJobObject(position);
    return {
      jobType,
      addSeekerEnable: jobType === MyGig.jobType.POSTED,
      nudgeSeekerEnable: jobType === MyGig.jobType.POSTED,
      editJobEnable:
        jobType !== MyGig.jobType.COMPLETED &&
        jobType !== MyGig.jobType.PENDING,
      deleteJobEnable: jobType !== MyGig.jobType.COMPLETED,
    };
  }
  openJobDetail = (event, position) => {
    const { getAllJobDetail } = this.props;
    this.props.getJobInformationById(position);

    const { dialogJob } = this.state;
    dialogJob.show = true;
    getAllJobDetail(position);
    //if (position.responses_pending === null) position.responses_pending = 0;
    this.setState({
      anchorJobDetailsEl: null,
      dialogJob,
      position,
      onClickShowJob: true,
    });
  };

  closeJobDetail = () => {
    this.setState({ anchorJobDetailsEl: null, onClickShowJob: false });
  };
  render() {
    const {
      lists,
      openConversation,
      newMessage,
      switchConversation,
      loadingJobById,
      loadingMoreConversation,
      isLastPage,
    } = this.props;
    const { anchorJobDetailsEl, position } = this.state;
    const isShowJobDetails = Boolean(anchorJobDetailsEl);
    const jobDetails = this.formatJobDetail(position);
    return (
      <div>
        <SweetAlert
          show={this.state.error}
          danger
          cancelBtnText={<IntlMessages id="common.button.no" />}
          confirmBtnText={<IntlMessages id="common.button.yes" />}
          title="Error"
          confirmBtnCssClass={"btn-orange"}
          onConfirm={() => {
            this.setState({ error: false, onClickShowJob: false });
          }}
        >
          <IntlMessages id="jobHasBeenDeleted" />
        </SweetAlert>
        <Scrollbars
          className="rct-scroll"
          autoHide
          style={{ height: "calc(100vh - 254px)" }}
          onScroll={(event) =>
            this.changePagination(event, loadingMoreConversation, isLastPage)
          }
        >
          <List className="list-positions">
            {newMessage && (
              <ListItem
                style={{ padding: 0 }}
                key={`messages-item-new-message`}
              >
                <div
                  className={classNames([
                    "new-message-item",
                    { active: openConversation === "new-message" },
                  ])}
                >
                  <p>New message</p>
                </div>
              </ListItem>
            )}
            {lists.map((conversation) => {
              const { id } = conversation;
              return (
                <ListItem
                  className={`messages-item ${
                    openConversation == id ? "open" : ""
                  }`}
                  style={{
                    background: conversation.count_unread != 0 ? "#f0f0f0" : "",
                  }}
                  key={`messages-item-${id}`}
                  onClick={() => {
                    if (id !== openConversation) {
                      switchConversation(id);
                      this.props.getDirectMessage(id, true);
                    }
                  }}
                >
                  <div
                    className="userProfileOuter d-flex"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <div className="user-profile-block">
                      {conversation.status ===
                      MyGig.conversation.CLOSE_CONVERSATION ? (
                        <i className="material-icons">block</i>
                      ) : (
                        <i
                          style={{
                            width: 24,
                            height: 24,
                          }}
                        ></i>
                      )}
                    </div>
                    <div className="user-profile mx-2">
                      <img
                        src={
                          conversation.profile_thumbnail &&
                          conversation.profile_thumbnail.length !== 4
                            ? conversation.profile_thumbnail
                            : UserDefault
                        }
                        className="rounded-circle"
                        width="60"
                        height="60"
                      />
                    </div>
                    <div className="user-profile-text">
                      <div className="name font-weight-bold h4 mb-1">
                        {conversation.seeker_name}
                      </div>
                      {/* {conversation.title} */}
                      <div className="dateTime h5">
                        {moment.unix(conversation.send_at).format("DD MMM")}
                      </div>
                    </div>
                    {/* <div className='user-profile-datetime w-30'>
                                    <div className='dateTime'>{moment.unix(conversation.send_at).format('MM-DD-YYYY')}</div>

                                    {conversation.count_unread !== 0 && <div className='unreadMessage'> {conversation.count_unread}  </div>}
                                </div> */}
                    {conversation.position_id !== 0 && (
                      <img
                        src={Information}
                        width={35}
                        height={35}
                        onClick={(event) =>
                          this.openJobDetail(event, conversation.position_id)
                        }
                        open={isShowJobDetails}
                      />
                    )}
                    <div className="number-unread text-right">
                      <span
                        className={`badge badge-${
                          conversation.count_unread != 0 ? "orange" : "gray"
                        } badge-pill`}
                      >
                        <span>
                          {maxNumberPlus(conversation.count_unread, 9)}
                        </span>
                      </span>
                    </div>
                  </div>
                </ListItem>
              );
            })}
            {/* <ListItem
                        style={{ padding: 0 }}
                        key={`messages-item-new-message`}
                    >
                        <div className={classNames(["new-message-item" ])}>
                            <p>Load more</p>
                        </div>
                    </ListItem> */}
            {loadingMoreConversation && (
              <div className="tab-circle-wrapper">
                <CircularProgress
                  className="progress-warning"
                  size={40}
                  mode="determinate"
                  value={40}
                  min={0}
                  max={50}
                />
              </div>
            )}
          </List>
          {isShowJobDetails && (
            <Popover
              open={isShowJobDetails}
              onClose={this.closeJobDetail}
              className="gig-popover message-job-detail-popover"
              classes={{ paper: "MuiPaper-rounded-message-job-card" }}
            >
              <JobCard
                disableClickTitle
                isOpen={isShowJobDetails}
                job={position}
                jobType={jobDetails.jobType}
                disableDelete={!jobDetails.deleteJobEnable}
                disableNudge={!jobDetails.nudgeSeekerEnable}
                disableAdd={!jobDetails.addSeekerEnable}
                disableEdit={!jobDetails.editJobEnable}
                isPendingPage
              />
              {loadingJobById && (
                <div className="center pd-20">
                  <CircularProgress
                    className="progress-warning"
                    size={40}
                    mode="determinate"
                  />
                </div>
              )}
            </Popover>
          )}
        </Scrollbars>
      </div>
    );
  }
}
const mapStateToProps = ({ jobApp, conversationReducer }) => {
  return {
    openPosition: jobApp.jobInfo,
    loadingJobById: jobApp.loadingJobById,
    loadingMoreConversation: conversationReducer.loading,
    isLastPage: conversationReducer.isLast,
  };
};
const mapActionToProps = {
  getDirectMessage,
  getConversationList,
  getJobById,
  closeJobInformation,
  getJobInformationById,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ListOfPositionsDirect);

import React, { Component, Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Button } from 'reactstrap';

const beforeAfterNumber = 3;

class Paginator extends Component {
    static POSITION = {
        LEFT: 1,
        RIGHT: 2
    };

    doChangePage(page) {
        if (page !== null) {
            this.props.onChange(page);
        }
    }

    renderPrevButton() {
        if (this.props.showPrevButton && this.props.currentPage > 1) {
            return (
                <Button
                    size="sm"
                    className="outline"
                    color="primary"
                    outline
                    onClick={() => this.doChangePage(this.props.currentPage - 1)}
                >
                    <FormattedHTMLMessage id="pagination.prevIcon" />
                </Button>
            );
        }

        return null;
    }

    renderNextButton() {
        if (this.props.showNextButton && this.props.currentPage < this.props.totalPage) {
            return (
                <Button
                    size="sm"
                    className="outline"
                    color="primary"
                    outline
                    onClick={() => this.doChangePage(this.props.currentPage + 1)}
                >
                    <FormattedHTMLMessage id="pagination.nextIcon" />
                </Button>
            );
        }

        return null;
    }

    renderPageNumberButtons() {
        const { currentPage, totalPage } = this.props;
        const listNumbers = [];
        let beforeWrap  = currentPage - beforeAfterNumber;
        let afterWrap   = currentPage + beforeAfterNumber;

        if (beforeWrap <= 1) {
            beforeWrap = 1;
        }

        if (afterWrap >= totalPage) {
            afterWrap = totalPage;
        }

        for (let i = beforeWrap; i <= afterWrap; i++) {
            listNumbers.push(i);
        }

        if (!listNumbers.includes(1)) {
            listNumbers.unshift(1, null);
        }

        if (!listNumbers.includes(totalPage)) {
            listNumbers.push(null, totalPage);
        }

        return (
            <Fragment>
                {listNumbers.map((page) => (
                    <Button
                        className={`number ${  page !== this.props.currentPage ? 'outline' : ''}`}
                        key={page}
                        onClick={() => this.doChangePage(page)}
                        size="sm"
                        color="primary"
                    >
                        {page !== null ? page : '...'}
                    </Button>
                ))}
            </Fragment>
        );
    }

    render() {
        const layoutClass = this.props.position === Paginator.POSITION.LEFT
            ? 'pull-left'
            : 'pull-right';

        return (
            <div className="app-paginator clearfix">
                <div className={`paginator-layout ${layoutClass}`}>
                    { this.renderPrevButton() }
                    { this.renderPageNumberButtons() }
                    { this.renderNextButton() }
                </div>
            </div>
        );
    }
};

export default Paginator;

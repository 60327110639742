/**
 * Employer Staff Existence Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { CHECK_EMAIL_EXIST } from "Actions/types";

import { checkEmailExistFailure, sendForgotPassword, showErrorResponse } from "Actions";

import { checkStaffExist } from "Services/employers/EmployerStaffExistenceService";

/**
 * Get Skills From Server
 */
function* getStaffExistFromServer({ email }) {
  try {
    const response = yield call(checkStaffExist, email);
    const isVerified = response.data.data.exists;

    if (isVerified) {
      yield put(sendForgotPassword(email));
    } else {
      yield put(checkEmailExistFailure("loginEmailVerifyFailed"));
    }
  } catch (error) {
    yield put(checkEmailExistFailure(error));
    yield put(showErrorResponse(error));
  }
}

/**
 * Get Job
 */
export function* takeStaffExist() {
  yield takeEvery(CHECK_EMAIL_EXIST, getStaffExistFromServer);
}

/**
 * Jobs Root Saga
 */
export default function* rootSaga() {
  yield all([fork(takeStaffExist)]);
}

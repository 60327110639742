/**
 * Reactify Sidebar
 */
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";
import AppLogoText from "Assets/img/appLogoText.png";
import AppLogo from "Assets/img/gig-logo-black.png";

// redux actions
import { collapsedSidebarAction, getEmployerDetail } from "Actions";

// components
import UserBlock from "./UserBlock";
import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  shouldComponentUpdate(nextProps) {
    const {
      enableSidebarBackgroundImage,
      selectedSidebarImage,
      isDarkSidenav,
    } = this.props;
    if (
      enableSidebarBackgroundImage !== nextProps.enableSidebarBackgroundImage ||
      selectedSidebarImage !== nextProps.selectedSidebarImage ||
      isDarkSidenav !== nextProps.isDarkSidenav
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;

    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: $(window).width(),
      windowHeight: $(window).height(),
    });
  };

  render() {
    const { isDarkSidenav } = this.props;

    return (
      <div className={classNames("rct-sidebar")}>
        <div className="site-logo">
          <Link to="/" className="logo-mini">
            <img src={AppLogo} className="mr-15" alt="site logo" width="50" />
          </Link>
        </div>
        <div
          className={classNames("rct-sidebar-wrap", {
            "sidebar-overlay-dark": isDarkSidenav,
            "sidebar-overlay-light": !isDarkSidenav,
          })}
        >
          <Scrollbars
            className="rct-scroll"
            autoHide
            autoHideDuration={100}
            style={{ height: "calc(100vh - 60px)" }}
          >
            <UserBlock redirectTo={this.props.history} />
            <SidebarContent />
          </Scrollbars>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings, authUser }) => {
  const {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
  } = settings;
  const { employerDetail } = authUser;
  return {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
    employerDetail,
  };
};

const mapActionToProps = {
  collapsedSidebarAction,
  getEmployerDetail,
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Sidebar));

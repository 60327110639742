import API from 'Api';

/**
 * Get master rate value of employer
 *
 * @return async
 */
export const getMasterRateRequest = async () => await API.get('/employers/getChargeRate')
  .then(response => response)
  .catch(error => error);

/**
 * Update master rate value
 *
 * @param  {Number} masterRate
 * @return async
 */
export const editMasterRateRequest = masterRate => API.put('/employers/updateChargeRate', {
  other_cost: masterRate.other_cost,
  pension_and_levy: masterRate.pension_and_levy,
  is_margin_fixed: masterRate.is_margin_fixed,
  margin_percent: masterRate.margin_percent,
  margin_fixed: masterRate.margin_fixed,
})
  .then(response => response)
  .catch(error => error);

import { createMuiTheme } from "@material-ui/core";

const InternalEmployeeTheme = createMuiTheme({
    overrides: {
        MuiModal: {
            root: {
                zIndex: "800",
                background: "transparent",
            },
        },
    },
});

export default InternalEmployeeTheme;

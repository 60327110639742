import API from 'Api';

/**
 * Update employer unaccpet term
 * @param {String} is_verified_store_and_use_data_conditions
 * @param {String} is_verified_read_terms_conditions
 * @param {String} is_accepted_receiving_updates_conditions
 * @return async
 */
export const storeDataProtectionRequest = async (dataProtection) =>
    await API.post('/employers/storeDataProtection', {
        is_verified_store_and_use_data_conditions: dataProtection.isVerifiedStoreAndUseDataConditions,
        is_verified_read_terms_conditions: dataProtection.isVerifiedReadTermsConditions,
        is_accepted_receiving_updates_conditions: dataProtection.isAcceptedReceivingUpdatesConditions,
    })
        .then(response => response)
        .catch(err => err);

/**
 * Call API to block an employee
 * @param  {Number} employeeId
 * @return async
 */
export const blockEmployee = async (employeeId) =>
    await API.post('/employers/block-employee', {
        employee_id: employeeId
    })
        .then(response => response)
        .catch(err => err);

/**
 * Call API to unblock an employee
 * @param  {Number} employeeId
 * @return async
 */
export const unblockEmployee = async (employeeId) =>
    await API.post('/employers/unblock-employee', {
        employee_id: employeeId
    })
        .then(response => response)
        .catch(err => err);

/**
 * Get list of positions which have at least one message
 *
 * @return async
 */
export const getPositionsHaveNotesRequest = async (params) =>
    await API.get('/employers/get_positions_have_notes', params)
        .then(response => response)
        .catch(err => err);

/**
 * Get list of notes by position ID
 *
 * @param {Number} positionid
 * @param {Number} page
 * @return async
 */
export const getConversationByPositionRequest = async (positionId, page) =>
    await API.get(`/employers/get_conversation_by_position/${positionId}`, {
        page: Number(page)
    })
        .then(response => response)
        .catch(err => err);

/**
 * Get list of notifications
 *
 * @param  {Number} perPage - number of notifications to respond
 * @param  {Number} lastId - latest notification ID in previous response data
 * @return async
 */
export const getNofiticationsRequest = async (perPage, lastId) => {
    let params = {
        perPage: Number(perPage)
    };

    if (lastId) {
        params = { ...params, lastId };
    }

    return await API.get('/employers/notifications', params)
        .then(response => response)
        .catch(err => err);
};

/**
 * Delete a specific notification
 *
 * @param  {Number} notificationId - The ID of notification to delete
 * @return async
 */
export const deleteNotificationRequest = async (notificationId) =>
    await API.remove(`/employers/notifications/${Number(notificationId)}`)
        .then(response => response)
        .catch(err => err);

/**
 * Get number of unread notifications
 *
 * @return async
 */
export const getNumberOfUnreadNotificationsRequest = async () =>
    await API.get('/employers/unread_notifications')
        .then(response => response)
        .catch(err => err);

/**
 * Mark a specific notification as read
 *
 * @param  {Number} notificationId - The ID of notification to mark as read
 * @return async
 */
export const markNotificationAsReadRequest = async (notificationId) =>
    await API.put(`/employers/mark_notification_as_read/${Number(notificationId)}`)
        .then(response => response)
        .catch(err => err);

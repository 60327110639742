import AppConfig  from 'Constants/AppConfig';
import StoredData from 'Constants/StoredData';

import moment from 'moment';

const ConfirmPasswordHelper = function () {};

/**
 * Get latest confirm password unix timestamp
 *
 * @return {Number|null}
 */
ConfirmPasswordHelper.getLatestConfirmUnixTime = function () {
    const latestUnixTime = StoredData.get(StoredData.KEYS.LAST_TIME_CHECK_PASSWORD);

    if (latestUnixTime !== null) {
        return Number(latestUnixTime);
    }

    return null;
};

ConfirmPasswordHelper.storeConfirmedUnixTime = function () {
    StoredData.set(StoredData.KEYS.LAST_TIME_CHECK_PASSWORD, moment().unix());
};

/**
 * Check the provider already confirmed password
 *
 * @return {Boolean}
 */
ConfirmPasswordHelper.isPasswordConfirmed = function () {
    const latestUnixTime  = ConfirmPasswordHelper.getLatestConfirmUnixTime();
    const currentUnixTime = moment().unix();

    if (!latestUnixTime) {
        return false;
    }

    return currentUnixTime - latestUnixTime <= AppConfig.minSecondsCheckPassword;
};

export default ConfirmPasswordHelper;

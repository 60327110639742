/**
 * Location Sagas
 */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_VACANCY_LOCATIONS,
} from 'Actions/types';

import {
    showErrorResponse,
    getVacancyLocationsSuccess,
    getVacancyLocationsFailure,
} from 'Actions';

import {
    getVacancyLocationsRequest,
} from 'Services/employers/VacancyService';


/**
 * Get Vacancy Locations From Server
 */
function* handleGetVacancyLocations() {
  try {
    const response = yield call(getVacancyLocationsRequest);
    if (isSuccess(response)) {
      yield put(getVacancyLocationsSuccess(response));
    } else {
      yield put(getVacancyLocationsFailure(response));
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

/**
 * Get Location
 */
export function* watchGetVacancyLocations() {
  yield takeEvery(GET_VACANCY_LOCATIONS, handleGetVacancyLocations);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetVacancyLocations),
  ]);
}

/**
 * Search seeker actions
 */
import {
  SEEKER_SEARCH,
  SEEKER_SEARCH_SUCCESS,
  SEEKER_SEARCH_FOR_ADD_SUCCESS,
  SEEKER_SEARCH_FAILURE,
  SEEKER_SEARCH_RESET_RESULTS
} from './types';

export const searchSeekers = (searchText, positionId, page, isPostTimeCard) => ({
  type   : SEEKER_SEARCH,
  payload: {searchText, positionId, page, isPostTimeCard}
});

export const searchSeekersSuccess = (response) => ({
  type   : SEEKER_SEARCH_SUCCESS,
  payload: response
});

export const searchSeekersForAddSuccess = (response) => ({
  type   : SEEKER_SEARCH_FOR_ADD_SUCCESS,
  payload: response
});

export const searchSeekersFailure = (error) => ({
  type   : SEEKER_SEARCH_FAILURE,
  payload: error
});

export const searchSeekersResetResults = () => ({
    type: SEEKER_SEARCH_RESET_RESULTS
});

/**
 * Omni Employer Reducer
 */

import { copy } from "Helpers/helpers";

import {
  GET_LIST_SEEKER_REQUEST,
  GET_LIST_SEEKER_SUCCESS,
  GET_LIST_SEEKER_FAILURE,
  GET_EMPLOYEE_DETAIL_SUCCESS,
  UPDATE_EMPLOYEE_DETAIL,
  UPDATE_EMPLOYEE_DETAIL_SUCCESS,
  SET_SEARCH_TEXT,
  UPDATE_EMPLOYEE_DETAIL_FAILURE,
  RESET_LOADING_DETAIL,
  RESET_UPDATE_POPUP,
  DELETE_SEEKER_BLOCK,
  OPEN_SEEKER_DETAIL,
  GET_JOB_TYPE_SUCCESS,
  GET_EMPLOYEE_LIST_JOB_APPLY,
  GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS,
  GET_INFO_SEEKER_IN_POSITION,
  GET_INFO_SEEKER_IN_POSITION_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_SUCCESS,
} from "Actions/types";

// initial state
const INIT_STATE = {
  listSeeker: [],
  loading: false,
  currentSelect: 0,
  seekerDetail: [],
  loadingDetail: false,
  loadingListJob: false,
  updateSuccess: undefined,
  searchText: "",
  listAll: [],
  jobTypes: [],
  listJobApply: [],
  seekerInfo: {},
  loadingInfo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_SEEKER_REQUEST:
      return { ...state, loading: true };

    case GET_LIST_SEEKER_SUCCESS:
      return {
        ...state,
        listAll: action.payload,
        listSeeker: action.payload,
        loading: false,
      };

    case GET_EMPLOYEE_DETAIL_SUCCESS:
      return {
        ...state,
        seekerDetail: action.payload,
        loadingDetail: true,
      };
    case GET_LIST_SEEKER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_EMPLOYEE_DETAIL:
      return {
        ...state,
      };

    case UPDATE_EMPLOYEE_DETAIL_SUCCESS:
      return {
        ...state,
        updateSuccess: action.payload.data.showPopup,
      };

    case GET_INFO_SEEKER_IN_POSITION:
      return {
        ...state,
        loadingInfo: true,
      };

    case GET_INFO_SEEKER_IN_POSITION_SUCCESS:
      return {
        ...state,
        seekerInfo: action.payload,
        loadingInfo: false,
      };

    case RESET_LOADING_DETAIL:
      return {
        ...state,
        loadingDetail: false,
      };

    case RESET_UPDATE_POPUP:
      return {
        ...state,
        updateSuccess: undefined,
      };

    case DELETE_SEEKER_BLOCK:
      let listSeekerCopy = copy(state.listSeeker);
      listSeekerCopy = listSeekerCopy.filter(
        (seeker) => seeker.employee_id !== action.payload.employeeId
      );
      return {
        ...state,
        listSeeker: listSeekerCopy,
      };

    case OPEN_SEEKER_DETAIL:
      return {
        ...state,
        currentSelect: action.payload.employeeId,
      };

    case SET_SEARCH_TEXT:
      listSeekerCopy = copy(state.listAll);
      if (action.payload.value.trim().length > 0) {
        listSeekerCopy = listSeekerCopy.filter(
          (seeker) =>
            seeker.fullname
              .toLowerCase()
              .indexOf(action.payload.value.toLowerCase()) !== -1 ||
            seeker.email
              .toLowerCase()
              .indexOf(action.payload.value.toLowerCase()) !== -1
        );
      }
      return {
        ...state,
        searchText: action.payload.value,
        listSeeker: listSeekerCopy,
      };
    case GET_JOB_TYPE_SUCCESS:
      return {
        ...state,
        jobTypes: action.payload.data,
      };
    case GET_EMPLOYEE_LIST_JOB_APPLY:
      return { ...state, loadingListJob: true };
    case GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS:
      return {
        ...state,
        listJobApply: action.payload.data,
        loadingListJob: false,
      };
    case ADD_NOTE:
      return { ...state };
    case ADD_NOTE_SUCCESS:
      const {
        id,
        created_name: fullname,
        created_at,
        note: content,
      } = action.payload;

      return {
        ...state,
        seekerInfo: {
          ...state.seekerInfo,
          admin_notes: [
            {
              id,
              fullname,
              created_at,
              content,
            },
            ...state.seekerInfo.admin_notes
            
          ],
        },
      };
    default:
      return { ...state };
  }
};

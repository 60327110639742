import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {Scrollbars}       from 'react-custom-scrollbars';

import ActionCodes      from 'Components/Notification/ActionCodes';
import NotificationItem from 'Components/Notification/NotificationItem';
import RoutePaths       from 'Constants/RoutePaths';
import {momentTz}       from 'Helpers/helpers';
import IntlMessages     from 'Util/IntlMessages';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    showRootSpinner,
    hideNotificationPanel,
    getJobDetailsAfterClicked,
    openDirectMessageModal,
} from 'Actions';

class NotificationWrapper extends Component {
    constructor(props) {
        super(props);

        this.handleNotificationActions = this.handleNotificationActions.bind(this);
    }

    handleNotificationActions(notificationObject) {
        const { actionCode, actionParams } = notificationObject;
        const { history } = this.props;

        switch (actionCode) {
            case ActionCodes.REMOTE_REQUEST_RECEIVED:
                history.push(RoutePaths.TIMESHEET.PENDING_WEEKLY);
                break;

            case ActionCodes.JOB_RESPONSE_RECEIVED:
                this.redirectToJobPage(actionParams);
                break;

            case ActionCodes.JOB_NUDGE_DECLINED:
                this.redirectToJobPage(actionParams);
                break;
            case ActionCodes.SEND_DIRECT_MESSAGE:
                this.props.openDirectMessageModal(actionParams.conversation_id)
            default: break;
        }

        this.props.hideNotificationPanel();
    }

    redirectToJobPage({positionId}) {
        this.props.showRootSpinner('notification.onRedirecting');
        this.props.getJobDetailsAfterClicked(positionId, this.props.history);
    }

    parseActionParams(actionParams) {
        try {
            return JSON.parse(actionParams);
        } catch (e) {
            return null;
        }
    }

    formatData(notifications) {
        return notifications.map((notification) => ({
            id             : Number(notification.id),
            actionCode     : notification.action_code,
            actionParams   : this.parseActionParams(notification.action_params),
            createdAt      : momentTz(notification.created_at),
            messageHTML    : notification.message,
            read           : Boolean(notification.read),
            senderId       : Number(notification.sender_id),
            senderFullname : notification.sender_fullname,
            senderThumbnail: notification.sender_profile_thumbnail
        }));
    }

    getLastId(notifications) {
        if (notifications.length > 0) {
            return notifications[notifications.length - 1].id;
        }

        return null;
    }

    render() {
        const notifications = this.formatData(this.props.notifications);
        const lastId      = this.getLastId(notifications);
        const {
            showOlderAvailable,
            onLoadingOlder
        } = this.props;

        return (
            <Scrollbars className="rct-scroll list-notifications" autoHeight autoHeightMin={100} autoHeightMax={280}>
                <ul className="list-unstyled dropdown-body">
                    {notifications.map((notification) => (
                        <NotificationItem
                            key={`notification-${notification.id}`}
                            notification={notification}
                            handleClicked={this.handleNotificationActions}
                        />
                    ))}
                </ul>

                {onLoadingOlder && (
                    <div className="center">
                        <CircularProgress className="progress-warning" size={20} mode="determinate" />
                    </div>
                )}

                {showOlderAvailable && !onLoadingOlder && (
                    <div className="show-older" onClick={() => this.props.onShowOlder(lastId)}>
                        <IntlMessages id="notification.button.showOlder" />
                    </div>
                )}
            </Scrollbars>
        );
    }
}

const mapStateToProps = ({notificationReducer}) => notificationReducer;

const mapActionToProps = {
    showRootSpinner,
    hideNotificationPanel,
    getJobDetailsAfterClicked,
    openDirectMessageModal,
};

export default connect(mapStateToProps, mapActionToProps)(NotificationWrapper);

/**
 * Auth Employer Actions
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    SIGN_UP_USER,
    SIGN_UP_USER_SUCCESS,
    SIGN_UP_USER_FAILURE,
    SIGN_UP_RESET_STATUS,
    GET_EMPLOYER_DETAIL,
    GET_EMPLOYER_DETAIL_SUCCESS,
    GET_EMPLOYER_DETAIL_FAILURE,
    CHECK_EMAIL_VERIFIED,
    CHECK_EMAIL_VERIFIED_SUCCESS,
    CHECK_EMAIL_VERIFIED_FAILURE,
    REMOVE_REDUCER,
    ON_CHANGE_USER_REGISTER,
    GET_SHIFT_OPTION,
    CHECK_EMPLOYER_EMAIL_EXIST,
    CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS,
    CHECK_EMPLOYER_EMAIL_EXIST_FAIL,
    GET_SHIFT_OPTIONS,
    GET_SHIFT_OPTIONS_SUCCESS,
    GET_SHIFT_OPTIONS_FAIL,
    CHECK_ACCESS_CODE_RESET_PROCESS,
    CHECK_ACCESS_CODE,
    CHECK_ACCESS_CODE_VALID,
    CHECK_ACCESS_CODE_INVALID,
    CHECK_ACCESS_CODE_FAILURE,
    RESET_PROPS_SIGN_UP,
    POST_REAUTHORISE,
    POST_REAUTHORISE_SUCCESS,
    POST_REAUTHORISE_FAILURE,
    RESET_EMAIL_TRACKING_SIGN_UP,
} from './types';

// action for reset flag email after backstep
export const resetEmailTrackingSignUp = () => ({
    type: RESET_EMAIL_TRACKING_SIGN_UP
});

// action reset prop of sign up form when unmount
export const resetPropsSignUp = () => ({
    type: RESET_PROPS_SIGN_UP
});

// action for shift deatil register
export const getShiftOptions = () => ({
    type: GET_SHIFT_OPTIONS,
});

export const getShiftOptionsSuccess = (shifts) => ({
    type: GET_SHIFT_OPTIONS_SUCCESS,
    payload: {
        shifts
    }
});

export const getShiftOptionsFail = (error) => ({
    type: GET_SHIFT_OPTIONS_FAIL,
    payload: error
});

export const resetCheckAccessCodeProcess = () => ({
    type: CHECK_ACCESS_CODE_RESET_PROCESS
});

export const checkAccessCode = (code, gigPremium) => ({
    type   : CHECK_ACCESS_CODE,
    payload: { code, gigPremium }
});

export const checkAccessCodeValid = () => ({
    type: CHECK_ACCESS_CODE_VALID
});

export const checkAccessCodeInvalid = () => ({
    type: CHECK_ACCESS_CODE_INVALID
});

export const checkAccessCodeFailure = (error) => ({
    type   : CHECK_ACCESS_CODE_FAILURE,
    payload: error
});

/**
 * Action verified email provider when sign up
 */
export const checkEmployerEmailExist = (email) => ({
    type: CHECK_EMPLOYER_EMAIL_EXIST,
    payload: { email }
});

export const checkEmployerEmailExistSuccess = () => ({
    type: CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS
});

export const checkEmployerEmailExistFail = (error) => ({
    type: CHECK_EMPLOYER_EMAIL_EXIST_FAIL,
    payload: error
});

/**
 * Action Reauthorise
 */
export const postReauthorise = ({ refresh_token }) => ({
    type: POST_REAUTHORISE,
    payload: refresh_token
});

export const postReauthoriseSuccess = (response) => ({
    type: POST_REAUTHORISE_SUCCESS,
    payload: response
});

export const postReauthoriseFailure = () => ({
    type: POST_REAUTHORISE_FAILURE
});

/**
 * Action Sigin User
 */
export const signinUser = (user, history, successCallback, errorCallback) => ({
    type: LOGIN_USER,
    payload: { user, history, successCallback, errorCallback }
});

/**
 * Action Signin User Success
 */
export const signinUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

/**
 * Action Signin User Failure
 */
export const signinUserFailure = (error) => ({
    type: LOGIN_USER_FAILURE,
    payload: error
});

/**
 * Action Get Employer Detail
 */
export const getEmployerDetail = (history) => ({
    type: GET_EMPLOYER_DETAIL,
    payload: { history }
});

/**
 * Action Employer Detail Success
 */
export const getEmployerDetailSuccess = (response) => ({
    type: GET_EMPLOYER_DETAIL_SUCCESS,
    payload: response
});

/**
 * Action Get Employer Detail Failure
 */
export const getEmployerDetailFailure = (error) => ({
    type: GET_EMPLOYER_DETAIL_FAILURE,
    payload: error
});

/**
 * Action Signout User
 */
export const signoutUser = (redirectTo) => ({
    type: LOGOUT_USER,
    payload: { redirectTo }
});

/**
 * Action Signout User Success
 */
export const signoutUserSuccess = () => ({
    type: LOGOUT_USER_SUCCESS
});

/**
 * Action Signout User Failure
 */
export const signoutUserFailure = () => ({
    type: LOGOUT_USER_FAILURE
});

/**
 * Action Signin User
 */
export const signUpUser = user => ({
    type: SIGN_UP_USER,
    payload: { user },
});

/**
 * Action Signin User Success
 */
export const signUpUserSuccess = payload => ({
    type: SIGN_UP_USER_SUCCESS,
    payload,
});

/**
 * Action Signin User Failure
 */
export const signUpUserFailure = payload => ({
    type: SIGN_UP_USER_FAILURE,
    payload,
});

export const resetSignUpStatus = () => ({
    type: SIGN_UP_RESET_STATUS,
});
/**
 * Action Check Email Verified
 */
export const checkEmailVerified = (email, history, successCallback, errorCallback) => ({
    type: CHECK_EMAIL_VERIFIED,
    payload: { email, history, successCallback, errorCallback }
});

/**
 * Action Check Email Verified Success
 */
export const checkEmailVerifiedSuccess = (response) => ({
    type: CHECK_EMAIL_VERIFIED_SUCCESS,
    payload: response
});

/**
 * Action Check Email Verified Failure
 */
export const checkEmailVerifiedFailure = (error) => ({
    type: CHECK_EMAIL_VERIFIED_FAILURE,
    payload: error
});

export const removeReducer = () => ({
    type: REMOVE_REDUCER
});

export const onChangeUserRegister = (formData) => ({
    type: ON_CHANGE_USER_REGISTER,
    payload: { formData }
});

export const getShiftAction = () => ({
    type: GET_SHIFT_OPTION
});

import API from 'Api';
import axios        from 'axios';
import AppConfig    from 'Constants/AppConfig';



 /**
   * Scan a QR code (find and return details of the corresponding assignment).
   *
   * @param  {string} qrCodeToken
   */
  
export const scanQrCode = async (params) => await axios.post(`${AppConfig.apiURL}/employers/scanQrCodeWithoutAuth`, params)
  .then(response => response.data.data)
  .catch(error => error);

export const checkInWithoutAuth = async (assignedScheduleId) => await axios.post(`${AppConfig.apiURL}/employers/assignments/${assignedScheduleId}/checkinWithoutAuth`)
  .then(response => response.data)
  .catch(error => error);

export const checkOutWithoutAuth = async (assignedScheduleId,params) => await axios.post(`${AppConfig.apiURL}/employers/assignments/${assignedScheduleId}/checkoutWithoutAuth`, params)
  .then(response => response.data)
  .catch(error => error);
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import GigRoles from "Helpers/gigroles";
import { maxNumberPlus, getTypeOfJobObject} from "Helpers/helpers";
import JobCard from "Components/JobCard";
import MyGig from "Constants/MyGig";
import { getJobById, getAllMessages, getJobInformationById, closeJobInformation } from "Actions";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Information from "Assets/img/Information.png";
import JobDetails from "Components/JobCard/JobDetails";
import Popover from "@material-ui/core/Popover";

import moment from "moment";

class ListOfPositions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogJob: {
                show: false,
            },
            openPosition: null,
            anchorJobDetailsEl: null,
            position: {
                id: null
            },
            page: 1
        };
    }

    componentDidMount() {
        const { lists, getAllJobDetail } = this.props;
        const { openPosition } = this.state;

        if (lists.length > 0) {
            const firstPosition = lists[0].positionId;
            if (!openPosition && this.props.positionId == null) {
                this.openConversation(firstPosition)
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //if no chosen position, choose the first conversation to open
        let openPosition = nextProps.positionId != null ? nextProps.positionId : nextProps.lists[0].positionId
        
        if(this.state.openPosition != nextProps.positionId) {
            this.setState({ openPosition: openPosition }); 
        }

        if(nextProps.openPosition.id && nextProps.openPosition.id !== this.state.position.id) {
            this.setState({
                position: nextProps.openPosition,
                anchorJobDetailsEl : nextProps.openPosition,
            })
        }
    }
    componentWillUnmount() {
        this.props.closeJobInformation()
    }

    formatJobDetail(position) {
        const jobType = getTypeOfJobObject(position);
        return  {
            jobType,
            addSeekerEnable: jobType === MyGig.jobType.POSTED,
            nudgeSeekerEnable: jobType === MyGig.jobType.POSTED,
            editJobEnable:
                jobType !== MyGig.jobType.COMPLETED &&
                jobType !== MyGig.jobType.PENDING,
            deleteJobEnable: jobType !== MyGig.jobType.COMPLETED,
        }
    }

    changePagination(e, loadingMoreMessages, isLastPage) {
        if (e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight && !loadingMoreMessages && !isLastPage) {
            let page = this.state.page + 1
            this.props.getAllMessages(page)
            this.setState({
                page: page
            })
        }
    }

    openJobDetailsPopover = (event, position) => {
        const {getAllJobDetail} = this.props;
        this.props.getJobInformationById(position)
        const jobType = getTypeOfJobObject(position);
        const { dialogJob } = this.state;
        dialogJob.show = true;
        getAllJobDetail(position);
        this.setState({ 
            dialogJob,
            anchorJobDetailsEl: null, 
            position ,
        });
    };

    closeJobDetailsPopover = () => {
        this.setState({ anchorJobDetailsEl: null });
    }

    openConversation(positionId) {
        this.setState({ openPosition: positionId });
        this.props.onOpenConversation(positionId);
    }

    renderScheduleDetails(schedules) {
        const numberOfSchedules = schedules.length;

        const startMoment = moment.unix(Number(schedules[0].start));
        let endMoment;

        if (numberOfSchedules === 1) {
            endMoment = moment.unix(Number(schedules[0].end));

            return (
                <div>
                    <div className="schedule-date">
                        {startMoment.format("DD MMMM")}
                    </div>
                    {/* <div className="schedule-time">
                        {startMoment.format("HH:mm")}-
                        {endMoment.format("HH:mm")}
                    </div> */}
                </div>
            );
        } else if (numberOfSchedules > 1) {
            endMoment = moment.unix(
                Number(schedules[numberOfSchedules - 1].end)
            );

            return (
                <div>
                    <div className="schedule-date">
                        {startMoment.format("DD MMM")} -{" "}
                        {endMoment.format("DD MMM")}
                    </div>
                </div>
            );
        }

        return null;
    }

    render() {
        const { lists } = this.props;
        const { openPosition, anchorJobDetailsEl, position } = this.state;
        const isShowJobDetails = Boolean(anchorJobDetailsEl);
        const { loadingJobById, loadingMoreMessages, isLastPage } = this.props;
        const style = loadingJobById ? { display: "none" } : {};
        const jobDetails = this.formatJobDetail(position)
        return (
            <Scrollbars
                className="rct-scroll"
                autoHide
                style={{ height: "calc(100vh - 148px)" }}
                onScroll={(event) => this.changePagination(event, loadingMoreMessages, isLastPage)}
            >
                <List className="list-positions">
                    {lists.map((position) => {
                        const { positionId } = position;
                        const gigRoleClass = GigRoles.getClass(
                            position.skillType
                        );

                        return (
                            <ListItem
                                className={`messages-item ${gigRoleClass} ${
                                    openPosition === positionId ? "open" : ""
                                }`}
                                key={`messages-item-${positionId}`}
                                onClick={() => {
                                    if (openPosition !== positionId) {
                                        this.openConversation(positionId);
                                    }
                                }}
                                // disabled
                            >
                                <div className="w-100 text-black">
                                    <div
                                        className="d-flex"
                                        style={{
                                            alignItems: "center",
                                            width: "calc(100% - 45px)",
                                        }}
                                    >
                                        <div style={{ paddingRight: 10 }}>
                                            <p className="skill-name m-0 font-normal">
                                                {position.skill_name}
                                            </p>
                                            {/* <div className="schedule m-0">
                                                {this.renderScheduleDetails(
                                                    position.schedules
                                                )}
                                            </div> */}
                                        </div>
                                        {/* <img
                                            src={Information}
                                            width={35}
                                            height={35}
                                        /> */}
                                        <div>
                                            <img
                                                src={Information}
                                                width={35}
                                                height={35}
                                                onClick={
                                                    (event) => this.openJobDetailsPopover(event, position.position_id)
                                                    
                                                }
                                                open = {isShowJobDetails}
                                                    
                                                
                                            />
                                        </div>
                                    {/* {isShowJobDetails && ( */}
                                        {/* //<JobDetails
                                            //     job={position}
                                            //     isOpen={
                                            //         isShowJobDetails
                                            //     }
                                            //     anchorEl={
                                            //         anchorJobDetailsEl
                                            //     }
                                            //     onClose={
                                            //         this
                                            //             .closeJobDetailsPopover
                                            //     }
                                            // />
                                            // <Popover
                                            //     open = {isShowJobDetails}
                                            //     onclose = { this.closeJobDetailsPopover} 
                                            // >
                                            // <div className="job-details-wrapper">
                                            //     <JobCard
                                            //         disableClickTitle
                                            //         isOpen={
                                            //                 isShowJobDetails
                                            //         }
                                            //         job={position}
                                            //         jobType={jobDetails.jobType}
                                            //         disableNudge={!jobDetails.nudgeSeekerEnable}
                                            //         disableAdd={!jobDetails.addSeekerEnable}
                                            //         disableEdit={!jobDetails.editJobEnable}
                                            //         isPendingPage
                                            //         disableDelete={!jobDetails.deleteJobEnable}
                                            //     /> 
                                            // </div>
                                            // </Popover>
                                           
                                                             //   )} */}
                                    </div>

                                    {/* {position.numberUnread !== 0 && ( */}
                                    {/* <div className="number-unread text-right">
                                        <span
                                            className={`badge badge-${
                                                position.numberUnread !== 0
                                                    ? "orange"
                                                    : "gray"
                                            } badge-pill`}
                                        >
                                            <span>
                                                {maxNumberPlus(
                                                    position.numberUnread,
                                                    9
                                                )}
                                            </span>
                                        </span>
                                    </div> */}
                                    {/* )} */}
                                </div>
                            </ListItem>
                        );
                    })}
                    {loadingMoreMessages && (
                        <div className="tab-circle-wrapper">
                            <CircularProgress
                                className="progress-warning"
                                size={40}
                                mode="determinate"
                                value={40}
                                min={0}
                                max={50}
                            />
                        </div>
                    )}
                </List>
                {isShowJobDetails && (
                       <Popover
                            open = {isShowJobDetails}
                            onClose = { this.closeJobDetailsPopover} 
                            className="gig-popover message-job-detail-popover"
                            classes={{ paper : "MuiPaper-rounded-message-job-card"}}
                            >   
                            <JobCard
                                disableClickTitle
                                isOpen={
                                        isShowJobDetails
                                }
                                job={position}
                                jobType={jobDetails.jobType}
                                disableNudge={!jobDetails.nudgeSeekerEnable}
                                disableAdd={!jobDetails.addSeekerEnable}
                                disableEdit={!jobDetails.editJobEnable}
                                isPendingPage
                                disableDelete={!jobDetails.deleteJobEnable}
                            /> 
                        {loadingJobById && (
                            <div className="center pd-20">
                                <CircularProgress
                                    className="progress-warning"
                                    size={40}
                                    mode="determinate"
                                />
                            </div>
                        )}
                   </Popover>
                )}
                
            </Scrollbars>
        );
    }
}
const mapStateToProps =({
    jobApp,
    messageReducer
}) => {
    return {
        openPosition: jobApp.jobInfo,
        loadingMoreMessages: messageReducer.onLoadingPositions,
        isLastPage: messageReducer.isLast
    }
}
const mapActionToProps = {
    getJobById,
    getAllMessages,
    getJobInformationById,
    closeJobInformation
};

export default connect(mapStateToProps, mapActionToProps)(ListOfPositions);

/**
 * Login Detail Setting Reducer
 */
import {
    OPEN_POPUP_QR_CODE,
    CLOSE_POPUP_QR_CODE,
    GET_QR_CODE_EMPLOYER,
    GET_QR_CODE_EMPLOYER_SUCCESS,
    REFRESH_QR_CODE_EMPLOYER,
    REFRESH_QR_CODE_EMPLOYER_SUCCESS 

} from 'Actions/types';

const INIT_STATE = {
    openPopUp: false,
    loading: false,
    data:  {
        id: "",
        employerId : "",
        qrCode: "",
        created_ad : ""
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CLOSE_POPUP_QR_CODE:
            return {...state, openPopUp: false };

        case OPEN_POPUP_QR_CODE: 
            return {...state, openPopUp: true };

        case GET_QR_CODE_EMPLOYER:
            return {...state, loading: true, updated: false , error: false };

        case GET_QR_CODE_EMPLOYER_SUCCESS:
            return {...state, data: action.payload.data, loading: false, fetchData: true };
        case REFRESH_QR_CODE_EMPLOYER:
            return {...state, loading: true };
        case REFRESH_QR_CODE_EMPLOYER_SUCCESS:
            return {...state, data: action.payload.data, loading: false, fetchData: true };
            
        default: return { ...state };

    }
};

/**
 * Auth User Reducers
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    GET_EMPLOYER_DETAIL,
    GET_EMPLOYER_DETAIL_SUCCESS,
    GET_EMPLOYER_DETAIL_FAILURE,
    LOGOUT_USER,
    CHECK_EMAIL_VERIFIED,
    CHECK_EMAIL_VERIFIED_SUCCESS,
    CHECK_EMAIL_VERIFIED_FAILURE,
    SIGN_UP_USER,
    SIGN_UP_USER_SUCCESS,
    SIGN_UP_USER_FAILURE,
    SIGN_UP_RESET_STATUS,
    ON_CHANGE_USER_REGISTER,
    CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS,
    CHECK_EMPLOYER_EMAIL_EXIST_FAIL,
    GET_SHIFT_OPTIONS_SUCCESS,
    CHECK_ACCESS_CODE_RESET_PROCESS,
    CHECK_ACCESS_CODE,
    CHECK_ACCESS_CODE_VALID,
    CHECK_ACCESS_CODE_INVALID,
    RESET_PROPS_SIGN_UP,
    RESET_EMAIL_TRACKING_SIGN_UP
} from 'Actions/types';
import moment from "moment";

/**
* initial auth user
*/
const INIT_STATE = {
    respondCheckVerified: false,
    respondeCheckAuthenticated: false,
    employerDetail: null,
    emailExisted: true,
    attempToCheckEmailExist: false,
    loading: false,
    checkingCodeProcess: {
        onChecking: false,
        verified: null
    },
    shifts: {
        naturals_of_business: [],
        number_staffs_thresholds: [],
        types_of_staff: [],
        gig_durations: [],
        gig_hear: []
    },
    signUp: {
        signUpSuccess: false,
        signUpFail: false,
        responseError: null,
    },
    form: {
        form1: {
            forename: '',
            surename: '',
            email: '',
            password: '',
            passwordConfirm: '',
            isAcceptTerms: false,
            isAcceptDataCollected: false,
            isAcceptedReceiveUpdateProduct: false,
            isValidate: false
        },
        form2: {
            business_name: "",
            business_email: "",
            business_street: "",
            business_city: "",
            business_postcode: "",
            business_phone: "",
            business_logo: ""
        },
        form3: {
            natural_of_business: "",
            number_staffs: "",
            type_of_staff: "",
            gig_duration: "",
            gig_hear: ""
        },
        form4: {
            isPremium: false,
            isValidate: false,
            accessCode: ''
        },
        form5: {
            card_name: "",
            cardNumber1: "",
            cardNumber2: "",
            cardNumber3: "",
            cardNumber4: "",
            exp_month: "",
            exp_year: "",
            cvc: "",
            card_token: "",
            isValidate: false
        },
        form7: {
            dateCall: moment().add(1, 'days'),
            callAtFlag: false,
            phoneName: "",
            phoneNumber: ""
        }
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_EMAIL_TRACKING_SIGN_UP:
            return { ...state, emailExisted: true, attempToCheckEmailExist: false };

        case RESET_PROPS_SIGN_UP: {
            return {
                ...state,
                emailExisted: INIT_STATE.emailExisted,
                attempToCheckEmailExist: INIT_STATE.attempToCheckEmailExist,
                checkingCodeProcess: INIT_STATE.checkingCodeProcess,
                form: INIT_STATE.form,
            };
        }
        case GET_SHIFT_OPTIONS_SUCCESS: {
            const { shifts } = action.payload;
            let form3 = {};
            form3 = state.form.form3;
            form3.natural_of_business = shifts.naturals_of_business[0].id;
            form3.number_staffs = shifts.number_staffs_thresholds[0].id;
            form3.type_of_staff = shifts.types_of_staff[0].id;
            form3.gig_duration = shifts.gig_durations[0].id;
            form3.gig_hear = shifts.gig_hear[0].id;
            let form = {};
            form = state.form;
            form.form3 = form3;
            return { ...state, shifts, form };
        }

        case CHECK_ACCESS_CODE_RESET_PROCESS: {
            return {
                ...state,
                checkingCodeProcess: INIT_STATE.checkingCodeProcess
            };
        }

        case CHECK_ACCESS_CODE: {
            return {
                ...state,
                checkingCodeProcess: {
                    onChecking: true,
                    verified  : null
                }
            };
        }

        case CHECK_ACCESS_CODE_VALID: {
            return {
                ...state,
                checkingCodeProcess: {
                    onChecking: false,
                    verified  : true
                }
            };
        }

        case CHECK_ACCESS_CODE_INVALID: {
            return {
                ...state,
                checkingCodeProcess: {
                    onChecking: false,
                    verified  : false
                }
            };
        }

        case CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS:
            return { ...state, emailExisted: false, attempToCheckEmailExist: true };

        case CHECK_EMPLOYER_EMAIL_EXIST_FAIL:
            return { ...state, emailExisted: true, attempToCheckEmailExist: true };

        case LOGIN_USER:
            return { ...state };

        case LOGIN_USER_SUCCESS:
            return { ...state, respondeCheckAuthenticated: action.payload };

        case LOGIN_USER_FAILURE:
            return { ...state };

        case LOGOUT_USER:
            return { ...state };

        case CHECK_EMAIL_VERIFIED:
            return { ...state };

        case CHECK_EMAIL_VERIFIED_SUCCESS:
            return { ...state, respondCheckVerified: action.payload };

        case CHECK_EMAIL_VERIFIED_FAILURE:
            return { ...state };

        case GET_EMPLOYER_DETAIL:
            return { ...state };

        case GET_EMPLOYER_DETAIL_SUCCESS:
            return { ...state, employerDetail: action.payload };

        case GET_EMPLOYER_DETAIL_FAILURE:
            return { ...state };

        case SIGN_UP_USER:
            return { ...state, loading: true };

        case SIGN_UP_USER_SUCCESS:
            return { ...state, loading: false, signUp: { signUpSuccess: true, signUpFail: false } };

        case SIGN_UP_USER_FAILURE:
            return {
                ...state, loading: false, signUp: {
                    signUpSuccess: false,
                    signUpFail: true,
                    responseError: action.payload.data.error,
                }
            };

        case ON_CHANGE_USER_REGISTER:
            return { ...state, form: action.payload.formData };

        case SIGN_UP_RESET_STATUS:
            return { ...state, signUp: INIT_STATE.signUp };

        default: return { ...state };
    }
};

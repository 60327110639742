import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import CSVReader from "react-csv-reader";
import { CSVLink, CSVDownload } from "react-csv";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "Util/IntlMessages";
import ValidatorMessage from "Components/ValidatorMessage";
import ListSeekers from "Components/InternalEmployee";
import Spinner from "Components/Model/Spinner";
import InternalEmployee from "Model/internalEmployee";
import { MuiThemeProvider } from "@material-ui/core";
import {
    isEmail,
    matchCharacter,
    matchNumber,
    minLength,
    maxLength,
} from "Helpers/validators";

import {
    getAllSkills,
    getInternalEmployeeList,
    addInternalEmployee,
    csvImportInternalEmployees,
    getLocation,
} from "Actions";

import DownloadCsvIcon from "Assets/img/gig-icons/download_csv.png";

import InternalEmployeeTheme from "Components/ThemeOptions/CustomMaterial/InternalEmployeeTheme";

class InternalEmployeeModal extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {
            locationId:0,
            errors: {},
            apiCalled: false,
            firstName: "",
            secondName: "",
            email: "",
            phone: "",
            headers: "",
            dataCSV: "",
            selectedSkills: [],
            showUploadCsvValidationError: false,
            csvValidationErrorRow: 0,
            csvValidationErrors: [],
        };
    }

    componentDidMount() {
        this.props.getLocation();
    }

    handleCloseModal = () => {
        this.setState({
            errors: {},
            firstName: "",
            secondName: "",
            email: "",
            phone: "",
            selectedSkills: this.props.defaultSkillId
                ? [this.props.defaultSkillId]
                : [],
            locationId: 0,
        });
        this.props.onClose();
    };

    handleOpenCSVFileSelect = () => {
        document.getElementById("csv-file-input").click();
    };
    handleDownloadCSVFile = () => {
        document.getElementById("csv-file-download").click();
    };

    handleCSVData = (data) => {
        if (data.length < 2)
            return this.showErrorAlert("internalEmployee.uploadCSV.empty");
        const allowedFields = [
            {
                name: "First Name",
                manipulator: (value) => {
                    value = value.trim();
                    if (!value)
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.firstName.empty",
                                }),
                            ],
                        };
                    return { employee_fname: value };
                },
            },
            {
                name: "Second Name",
                manipulator: (value) => {
                    value = value.trim();
                    if (!value)
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.secondName.empty",
                                }),
                            ],
                        };
                    return { employee_sname: value };
                },
            },
            {
                name: "Email",
                manipulator: (value) => {
                    value = value.trim();
                    if (!value) {
                        return {
                            errors: [formatMessage({ id: "user.email.empty" })],
                        };
                    }

                    const emailIsValid = !!isEmail(value);
                    if (!emailIsValid) {
                        return {
                            errors: [formatMessage({ id: "loginEmailValid" })],
                        };
                    }

                    return { email: value };
                },
            },
            {
                name: "Phone Number",
                manipulator: (value) => {
                    value = value.trim();
                    if (!value || value.trim() === "") {
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.phone.empty",
                                }),
                            ],
                        };
                    }

                    if (matchCharacter(value)) {
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.phone.must.be.number",
                                }),
                            ],
                        };
                    }

                    const phoneIsValid =
                        matchNumber(value) &&
                        maxLength(value, 50) &&
                        minLength(value, 10);
                    if (!phoneIsValid) {
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.phone.valid",
                                }),
                            ],
                        };
                    }

                    return { phone: value };
                },
            },
            {
                name: "Job Roles",
                manipulator: (value) => {
                    let skills = value.trim().split("|");
                    const errors = [];
                    skills = skills.map((skillName) => {
                        const matchedSkill = this.props.listOfSkills.find(
                            (skill) => skill.name === skillName
                        );
                        if (!matchedSkill) {
                            errors.push(
                                formatMessage(
                                    {
                                        id:
                                            "internalEmployee.uploadCSV.invalidRole",
                                    },
                                    { role: skillName }
                                )
                            );
                            return null;
                        }
                        return matchedSkill.id;
                    });
                    if (errors.length) return { errors };
                    if (!skills.length)
                        return {
                            errors: [
                                formatMessage({
                                    id: "internalEmployee.uploadCSV.emptyRoles",
                                }),
                            ],
                        };
                    return { skills };
                },
            },
        ];

        // validate header
        const header = data[0];
        const validateHeader = header.every(
            (field, index) => field === allowedFields[index].name
        );
        if (!validateHeader)
            return this.showErrorAlert(
                "internalEmployee.uploadCSV.invalidHeader"
            );
        // strip header
        data.splice(0, 1);

        const employees = [];
        const errors = [];
        const { formatMessage } = this.props.intl;
        data.forEach((row, rowIndex) => {
            // ignore empty rows
            if (row.join("")) {
                let employee = {};
                const rowErrors = [];

                row.forEach((field, fieldIndex) => {
                    const matchedField = allowedFields[fieldIndex];
                    const value = matchedField.manipulator(field);
                    if (value === null) {
                        return;
                    }
                    if (value.hasOwnProperty("errors")) {
                        rowErrors.push({
                            field: matchedField.name,
                            errors: value.errors,
                        });
                        return;
                    }
                    employee = { ...employee, ...value };
                });

                if (rowErrors.length) {
                    errors.push({ row: rowIndex + 2, errors: rowErrors });
                    return;
                }
                employees.push(employee);
            }
        });

        document.getElementById("csv-file-input").value = "";

        if (errors.length) {
            const error = errors[0];
            this.setState({
                showUploadCsvValidationError: true,
                csvValidationErrorRow: error.row,
                csvValidationErrors: error.errors.map((err) => err.errors[0]),
            });
            return;
        }
        // start bulk import
        this.props.csvImportInternalEmployees(
            employees,
            (messageKey) =>
                this.showSuccessAlert(
                    messageKey || "internalEmployee.uploadCSV.success"
                ),
            (messageKey) =>
                this.showErrorAlert(
                    messageKey || "internalEmployee.uploadCSV.failure"
                )
        );
    };

    showSuccessAlert(messageKey) {
        this.props.onSuccess(messageKey);
        this.setState({
            errors: {},
            firstName: "",
            secondName: "",
            email: "",
            phone: "",
            selectedSkills: this.props.defaultSkillId
                ? [this.props.defaultSkillId]
                : [],
            locationId:0,
        });
        this.props.getInternalEmployeeList();
    }

    showErrorAlert(messageKey) {
        this.props.onError(messageKey);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.apiCalled) {
            if (props.listOfSkills.length) {
                return {
                    apiCalled: true,
                    selectedSkills: [props.defaultSkillId],
                };
            }
            if (props.isOpen) props.getAllSkills();
        }
        return null;
    }

    addSkill = () => {
        const skillToAdd = this.props.listOfSkills.find(
            (skill) => !this.state.selectedSkills.includes(skill.id)
        );
        if (skillToAdd) {
            this.setState({
                selectedSkills: this.state.selectedSkills.concat(skillToAdd.id),
            });
        }
    };

    removeSkill = (index) => {
        const selectedSkills = this.state.selectedSkills;
        this.setState({
            selectedSkills: selectedSkills
                .slice(0, index)
                .concat(selectedSkills.slice(index + 1)),
        });
    };

    addInternalEmployee = () => {
        const {
            firstName,
            secondName,
            email,
            phone,
            selectedSkills,
            locationId,
        } = this.state;
        const errors = InternalEmployee.validateFields(this.state);

        if (Object.keys(errors).length === 0) {
            this.props.addInternalEmployee(
                {
                    firstName: firstName.trim(),
                    secondName: secondName.trim(),
                    email: email.trim(),
                    phone: phone.trim(),
                    skills: selectedSkills,
                    location: parseInt(locationId)
                },
                (messageKey) =>
                    this.showSuccessAlert(
                        messageKey || "internalEmployee.add.success"
                    ),
                (messageKey) =>
                    this.showErrorAlert(
                        messageKey || "internalEmployee.add.failure"
                    )
            );
        }

        this.setState({ errors });
    };

    render() {
        const {
            firstName,
            secondName,
            email,
            phone,
            selectedSkills,
            locationId,
            errors,
            showUploadCsvValidationError,
            csvValidationErrorRow,
            csvValidationErrors,
        } = this.state;
        const {
            title,
            isOpen,
            listOfSkills,
            onLoading,
            listInternalEmployees,
            listOfLocations
        } = this.props;
        const headers = [
            { label: "First Name", key: "firstname" },
            { label: "Second Name", key: "secondname" },
            { label: "Email", key: "email" },
            { label: "Phone Number", key: "phonenumber" },
            { label: "Job Roles", key: "jobrole" },
        ];

        const dataCSV = [
            {
                firstname: "",
                secondname: "",
                email: "",
                phonenumber: "",
                jobrole: "",
            },
        ];
        return (
            <div
                className="post-gig-outer-wrapper"
                style={{
                    display: isOpen ? "block" : "none",
                }}
            >
                {/* <MuiThemeProvider theme={InternalEmployeeTheme}> */}
                <SweetAlert
                    error
                    show={showUploadCsvValidationError}
                    title=""
                    btnSize="sm"
                    onConfirm={() =>
                        this.setState({
                            showUploadCsvValidationError: false,
                            csvValidationErrorRow: 0,
                            csvValidationErrors: [],
                        })
                    }
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <FormattedMessage
                        id="internalEmployee.uploadCSV.rowError"
                        values={{ row: csvValidationErrorRow }}
                    />
                    {csvValidationErrors.map((error, index) => (
                        <div key={index}>{error}</div>
                    ))}
                </SweetAlert>

                <SwipeableDrawer
                    anchor={"right"}
                    open={isOpen}
                    onOpen={() => false}
                    onClose={() => this.handleCloseModal()}
                    className="MuiDrawer-modal-employee-gig-container"
                >
                    <div className="drawer-wrapper">
                        <Spinner spinnerWidth={70} show={onLoading} />
                        <div className="d-none">
                            <CSVReader
                                onFileLoaded={this.handleCSVData}
                                onError={null}
                                inputId="csv-file-input"
                            />
                        </div>
                        <div></div>
                        <div className="post-gig-wrapper">
                            <div className="d-flex mb-20 justify-content-between">
                                <h1 className="mb-0 mr-20 d-flex align-items-center post-gig-title  font-bold">
                                    {title}
                                </h1>
                                <div className="d-flex">
                                    <div
                                        className="mr-20 d-flex align-items-center  on-hover"
                                        onClick={() =>
                                            this.handleOpenCSVFileSelect()
                                        }
                                    >
                                        <i className="ml-5 material-icons">
                                            cloud_upload
                                        </i>
                                        <span style={{ width: 10 }}></span>
                                        <IntlMessages id="internalEmployee.uploadCSV" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={DownloadCsvIcon}
                                            alt=""
                                            style={{ height: "24px" }}
                                        />{" "}
                                        <span style={{ width: 10 }}></span>
                                        {/* <a
                                            className="text-black"
                                            href="https://s3-eu-west-1.amazonaws.com/staging-webportal.myworkglobal.co.uk/bulk_import_sample/myagency_bulk_import_sample.csv"
                                        ></a> */}
                                    </div>
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ marginRight: "50px" }}
                                    >
                                        <CSVLink
                                            data={dataCSV}
                                            headers={headers}
                                            filename={"GIG-SampleCSV.csv"}
                                            // inputId="csv-file-download"
                                            className="text-black"
                                        >
                                            <IntlMessages id="internalEmployee.uploadCSV.sample" />
                                        </CSVLink>
                                    </div>

                                    <div className="text-right">
                                        <i
                                            className="zmdi zmdi-close font-2x on-hover"
                                            onClick={() =>
                                                this.handleCloseModal()
                                            }
                                        />
                                    </div>
                                </div>{" "}
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="First-Name">
                                            <IntlMessages id="internalEmployee.firstName" />
                                        </Label>
                                        <Input
                                            type="text"
                                            id="First-Name"
                                            value={firstName}
                                            onChange={(event) =>
                                                this.setState({
                                                    firstName:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                        {errors.firstName && (
                                            <ValidatorMessage
                                                message={errors.firstName}
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="Second-Name">
                                            <IntlMessages id="internalEmployee.secondName" />
                                        </Label>
                                        <Input
                                            type="text"
                                            id="Second-Name"
                                            value={secondName}
                                            onChange={(event) =>
                                                this.setState({
                                                    secondName:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                        {errors.secondName && (
                                            <ValidatorMessage
                                                message={errors.secondName}
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="Email">
                                            <IntlMessages id="internalEmployee.email" />
                                        </Label>
                                        <Input
                                            type="text"
                                            id="Email"
                                            value={email}
                                            onChange={(event) =>
                                                this.setState({
                                                    email: event.target.value,
                                                })
                                            }
                                        />
                                        {errors.email && (
                                            <ValidatorMessage
                                                message={errors.email}
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="Phone-Number">
                                            <IntlMessages id="internalEmployee.phone" />
                                        </Label>
                                        <Input
                                            type="text"
                                            id="Phone-Number"
                                            value={phone}
                                            onChange={(event) =>
                                                this.setState({
                                                    phone: event.target.value,
                                                })
                                            }
                                        />
                                        {errors.phone && (
                                            <ValidatorMessage
                                                message={errors.phone}
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 ">
                                    <Label className="mb-0" for="Roles">
                                        <IntlMessages id="dashboard.location" />
                                    </Label>
                                </div>
                                    <div
                                        className="mt-10 col-sm-12 col-md-12 col-xl-6"
                                    >
                                        <FormGroup className="d-flex flex-row align-items-center">
                                            <Input
                                                className="d-flex "
                                                type="select"
                                                id="Job-Location"
                                                value={locationId}
                                                onChange={(event) =>
                                                    this.setState({
                                                        locationId: event.target.value,
                                                    })
                                                }
                                            >
                                                <option
                                                    key={`location-${0}`}
                                                    value={0}
                                                >
                                                    Select All
                                                </option>
                                                {
                                                   listOfLocations && listOfLocations.map(
                                                        location => (
                                                            <option
                                                                key={`location-${location.id}`}
                                                                value={Number(location.id)}
                                                            >
                                                                {location.name},{" "}{location.city}
                                                            </option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>   
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <Label className="mb-0" for="Roles">
                                        <IntlMessages id="internalEmployee.roles" />
                                    </Label>
                                </div>
                                {selectedSkills.map((skillId, index) => (
                                    <div
                                        key={skillId}
                                        className="mt-10 col-sm-12 col-md-12 col-xl-6"
                                    >
                                        <FormGroup className="d-flex flex-row align-items-center">
                                            {index !== 0 && (
                                                <i
                                                    className="mr-5 zmdi zmdi-close-circle orange font-2x on-hover"
                                                    onClick={() =>
                                                        this.removeSkill(index)
                                                    }
                                                />
                                            )}
                                            <Input
                                                className="d-flex "
                                                type="select"
                                                id="Roles"
                                                value={skillId}
                                                onChange={(event) =>
                                                    this.setState({
                                                        selectedSkills: selectedSkills
                                                            .slice(0, index)
                                                            .concat(
                                                                Number(
                                                                    event.target
                                                                        .value
                                                                )
                                                            )
                                                            .concat(
                                                                selectedSkills.slice(
                                                                    index + 1
                                                                )
                                                            ),
                                                    })
                                                }
                                            >
                                                {listOfSkills.map((skill) => (
                                                    <option
                                                        key={`skill-${skill.id}`}
                                                        value={skill.id}
                                                        disabled={selectedSkills.includes(
                                                            skill.id
                                                        )}
                                                    >
                                                        {skill.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                ))}

                                <div className="mb-3 pl-0 col-sm-12 col-md-12 col-xl-6">
                                    <div
                                        className="d-flex align-items-center"
                                        id="add-role"
                                    >
                                        <IconButton onClick={this.addSkill}>
                                            <div className="add-role-container">
                                                <span className="">
                                                    <IntlMessages id="internalEmployee.addRole" />
                                                </span>
                                                <i className=" material-icons">
                                                    navigate_next
                                                </i>
                                            </div>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-xl-3 col-md-12">
                                        <div className="pull-right text-center">
                                            <Button
                                                fullWidth
                                                className="gig-button orange text-black bigger-font pull-right"
                                                onClick={
                                                    this.addInternalEmployee
                                                }
                                            >
                                                <IntlMessages id="internalEmployee.button.add" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    {!onLoading &&
                                    listInternalEmployees &&
                                    listInternalEmployees.length === 0 ? (
                                        <div className="uppercase center vertical-space-small">
                                            <FormattedMessage id="internalEmployee.empty" />
                                        </div>
                                    ) : (
                                        <ListSeekers
                                            listSeekers={listInternalEmployees}
                                            listOfSkills={listOfSkills}
                                            handleCloseParentPopup={() =>
                                                this.handleCloseModal()
                                            }
                                            onEdit={() => this.props.onClose()}
                                            onDelete={() => {}}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>
                {/* </MuiThemeProvider> */}
            </div>
        );
    }
}

const mapStateToProps = ({ skillReducer, internalEmployeeReducer, location }) => ({    
    listOfSkills: skillReducer.skills,
    defaultSkillId:
        skillReducer.skills.length > 0 ? skillReducer.skills[0].id : 0,
    listInternalEmployees: internalEmployeeReducer.listInternalEmployees,
    onLoading: internalEmployeeReducer.onLoading,
    listOfLocations: location.allLocation ?  location.allLocation.data : null ,
});

const mapActionToProps = {
    getAllSkills,
    getInternalEmployeeList,
    addInternalEmployee,
    csvImportInternalEmployees,
    getLocation,
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(injectIntl(InternalEmployeeModal));

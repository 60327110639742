/**
 * Root Sagas
 */
import { all } from 'redux-saga/effects';

// sagas
import authSagas           from './Auth';
import jobSagas            from './Job';
import uniformSagas        from './Uniform';
import locationSagas       from './Location';
import skillSagas          from './Skill';
import chargeRateByPayRateSagas  from './ChargeRateByPayRate';
import chargeRateSagas     from './ChargeRate';
import shiftSagas          from './Shift';
import positionSagas       from './Position';
import timesheetPending    from './TimesheetPending';
import timesheetApproved   from './TimesheetApproved';
import reportSagas         from './Report';
import blockEmployeeSagas  from './BlockEmployee';
import userSagas           from './User';
import omniEmployerSaga    from './OmniEmployer';
import messageSaga         from './Message';
import checkCardSaga       from './CheckCard';
import searchSeekerSaga    from './SearchSeeker';
import nudgeSeekerSaga     from './NudgeSeeker';
import staffSaga           from './EmployerStaffExistence';
import forgotPasswordSaga  from './ForgotPassword';
import invoicesSaga        from './Invoice';
import confirmPasswordSaga from './ConfirmPassword';
import amendingHourCommentSaga from './AmendingHourComment';
import notificationSaga    from './Notification';
import loginDetailSaga     from './LoginDetail';
import splitShiftsSaga     from './SplitShifts';
import businessDetailSaga  from './BusinessDetail';
import addSeekerSaga       from './AddSeeker';
import seekerSignalSaga    from './SeekerSignal';
import pdfSaga             from './Pdf';
import internalEmployeeSaga from './InternalEmployee';
import vacancySaga         from './Vacancy';
import conversationSaga    from './Conversation';
import excelSaga           from './Excel';
import directMessageSaga    from './DirectMessage';
import favouriteSaga from './Favourite';
import providerSaga from './Provider';
import employerSaga        from './Employer';
import employeeSaga from './Seeker';
import sendMailSaga           from './SendMail';
import excelReportSaga from './ExcelReports';
import qrCodeEmployer from './QrCodeEmployer';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        jobSagas(),
        uniformSagas(),
        locationSagas(),
        skillSagas(),
        chargeRateByPayRateSagas(),
        chargeRateSagas(),
        shiftSagas(),
        positionSagas(),
        timesheetPending(),
        timesheetApproved(),
        reportSagas(),
        blockEmployeeSagas(),
        userSagas(),
        omniEmployerSaga(),
        messageSaga(),
        checkCardSaga(),
        searchSeekerSaga(),
        nudgeSeekerSaga(),
        staffSaga(),
        forgotPasswordSaga(),
        invoicesSaga(),
        confirmPasswordSaga(),
        amendingHourCommentSaga(),
        notificationSaga(),
        loginDetailSaga(),
        splitShiftsSaga(),
        businessDetailSaga(),
        addSeekerSaga(),
        seekerSignalSaga(),
        pdfSaga(),
        internalEmployeeSaga(),
        vacancySaga(),
        excelSaga(),
        conversationSaga(),
        directMessageSaga(),
        favouriteSaga(),
        providerSaga(),
        employerSaga(),
        employeeSaga(),
        sendMailSaga(),
        excelReportSaga(),
        qrCodeEmployer()
    ]);
}

import React, { Component, Fragment } from "react";

class ListOfLocations extends Component {
    render() {
        const { locations } = this.props;
        return (
            locations.map(
                (location, index) => (
                    <option
                        key={`location-${location.id}`}
                        value={Number(location.id)}
                        defaultValue={!index}
                    >
                        {location.name},{" "}{location.city}
                    </option>
                )
            )
        )
    }


}

export default ListOfLocations;

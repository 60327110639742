/**
 * Messages Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    GET_MESSAGES,
    GET_MESSAGES_BY_POSITION,
    SEND_MESSAGE_TO_POSITION
} from 'Actions/types';

import {
    showErrorResponse,
    getAllMessagesSuccess,
    getAllMessagesFailure,
    getMessagesOfPositionSuccess,
    getMessagesOfPositionFailure,
    sendMessageToPositionSuccess,
    sendMessageToPositionFailure
} from 'Actions';

import {
    addMessageToPositionRequest
} from 'Services/employers/MessageService';

import {
    getPositionsHaveNotesRequest,
    getConversationByPositionRequest
} from 'Services/employers/EmployerService';

function* handleGetMessages({payload}) {
    const params = {
        page: payload.page
    }
    try {
        const response = yield call(getPositionsHaveNotesRequest, params);

        if (response.data && isSuccess(response)) {
            yield put(getAllMessagesSuccess(response.data.data, params.page));
        } else {
            yield put(getAllMessagesFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getAllMessagesFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Take get messages
 */
export function* watchGetMessages() {
  yield takeEvery(GET_MESSAGES, handleGetMessages);
}

function* handleGetMessagesOfPosition({payload}) {
    const {positionId, page} = payload;

    try {
        const response = yield call(getConversationByPositionRequest, positionId, page);

        if (response.data && isSuccess(response)) {
            yield put(getMessagesOfPositionSuccess({
                messages  : response.data.data,
                positionId
            }));
        } else {
            yield put(getMessagesOfPositionFailure(response));
            yield put(showErrorResponse(response));
        }

    } catch (error) {
        yield put(getMessagesOfPositionFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Take get messages of position
 */
export function* watchGetMessagesOfPosition() {
  yield takeEvery(GET_MESSAGES_BY_POSITION, handleGetMessagesOfPosition);
}

function* handleSendMessageToPosition({payload}) {
    const {
        positionId,
        messageData,
        callbackSuccess,
        callbackFailure
    } = payload;

    try {
        const response = yield call(addMessageToPositionRequest, positionId, messageData);

        if (response.data && isSuccess(response)) {
            yield put(sendMessageToPositionSuccess(response.data.data));
            callbackSuccess(response.data.data);
        } else {
            yield put(sendMessageToPositionFailure(response));
            callbackFailure();
        }
    } catch (error) {
        yield put(sendMessageToPositionFailure(error));
        callbackFailure(error);
    }
}

export function* watchSendMessageToPosition() {
  yield takeEvery(SEND_MESSAGE_TO_POSITION, handleSendMessageToPosition);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchGetMessages),
        fork(watchGetMessagesOfPosition),
        fork(watchSendMessageToPosition)
    ]);
}

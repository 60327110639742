import SecureLS from 'secure-ls';
import AppConfig from 'Constants/AppConfig';

const ls = new SecureLS(AppConfig.secureLocalStorage);

/**
 * Stored Data File
 */
const StoredData = {
    KEYS: {
        LOCALE: 'locale',
        TIMEZONE: 'timezone',
        USER_ID: 'user-id',
        EMPLOYER_EMAIL: 'employer-email',
        EMPLOYER_CHOSEN_ID: 'employer-chosen-id',
        EMPLOYER_ID: 'employer-id',
        EMPLOYER_NAME: 'employer-name',
        EMPLOYER_LOGO: 'logo_thumbnail',
        GIG_PREMIUM: 'employer-gig-premium',
        EMPLOYER_STAFF: 'employer-staff',
        EMPLOYER_STAFF_ID: 'employer-staff-id',
        EMPLOYER_ROLE_ID: 'employer-role-id',
        EMPLOYER_LEVEL_ID: 'employer-level-id',
        EMPLOYER_LIST: 'employer-list',
        NOTIFICATION_TOKEN: 'notification-token',
        UNREAD_NOTIFICATIONS: 'unread-notification',
        UNREAD_MESSAGES: 'unread-message',
        WAGES_INSTANT_PAYMENT: 'wages-instant-payment',
        EMPLOYER_LEVEL: 'employer-level',
        LOGGED_IN_AS: 'logged_in_as',
        LAST_TIME_CHECK_PASSWORD: 'last-time-check-password',
        DATA_PROTECTION: {
            VERIFIED_STORE_USE_DATA: 'privacy-verified-store-use-data',
            VERIFIED_READ_TERMS: 'privacy-verified-read-terms'
        },
        OAUTH: {
            ACCESS_TOKEN: 'oauth-access-token',
            ACCESS_TOKEN_EXPIRY_TIMESTAMP: 'oauth-access-token-expiry-timestamp',
            REFRESH_TOKEN: 'oauth-refresh-token',
            HMAC_KEY: 'oauth-hmac-key'
        },
        /**
         * Contain temp email when Account manager
         * take job actions in Upcoming Job Modal
         */
        LOGGED_IN_AS_UNIVERSE: 'logged_in_as_universe'
    }
};

const shouldEncrypt = (key) => {
    const {
        USER_ID,
        EMPLOYER_EMAIL,
        EMPLOYER_STAFF,
        EMPLOYER_ROLE_ID,
        LOGGED_IN_AS,
        GIG_PREMIUM,
        LOGGED_IN_AS_UNIVERSE
    } = StoredData.KEYS;

    const listShouldBeEncrypted = [
        USER_ID,
        EMPLOYER_EMAIL,
        EMPLOYER_STAFF,
        EMPLOYER_ROLE_ID,
        LOGGED_IN_AS,
        GIG_PREMIUM,
        LOGGED_IN_AS_UNIVERSE
    ];

    if (listShouldBeEncrypted.includes(key)) return true;
    return false;
};

/**
 * Check if a key exists in localStorage
 *
 * @param  {string} key
 * @return {boolean}
 * @public
 */
StoredData.isKeyExist = (key) => localStorage.getItem(key) !== null;

/**
 * Is an item set?
 *
 * @param  {string} key
 * @return {boolean}
 * @throws {Error} if localStorage is not available
 * @public
 */
StoredData.isSet = (key) => (StoredData.get(key) !== null);

/**
 * Set an item.
 *
 * @param  {string} key
 * @param  {*}      value - Will be serialised for storage
 * @throws {Error} if localStorage is not available, or if stringify fails
 * @see    www.w3.org/TR/webstorage/#dom-storage-setitem
 * @public
 */
StoredData.get = (key) => {
    const rawValue = localStorage.getItem(key);
    
    if (shouldEncrypt(key)) {
        if (rawValue && rawValue.startsWith('U2')) return ls.get(key);
        // return localStorage.clear();
    }

    return rawValue;
};

StoredData.getAll = () => {
    const keys = Object.values(StoredData.KEYS)
    let arr = [];
    keys.forEach(item => {
        arr.push(StoredData.get(item, "hihi"));
    })
    return arr;
}
/**
 * Get an item.
 *
 * @param  {string} key
 * @return {*} - If the key doesn't exist, null is returned
 * @throws {Error} if localStorage is not available
 * @see    www.w3.org/TR/webstorage/#dom-storage-getitem
 * @public
 */
StoredData.set = (key, value) => {
    if (shouldEncrypt(key)) return ls.set(key, value);
    localStorage.setItem(key, value);
};

/**
* Remove an item.
*
* @param  {string} key
* @throws {Error} if localStorage is not available
* @see    www.w3.org/TR/webstorage/#dom-storage-removeitem
* @public
*/
StoredData.remove = (key) => {
    if (shouldEncrypt(key)) return ls.remove(key);
    localStorage.removeItem(key);
};

export default StoredData;

import React from 'react'
import DetailConversation from './DetailConversation'
import ListOfPositions from './ListOfPositions'
import IntlMessages from "Util/IntlMessages";

const BroadcastMessage = ({ 
    positionsWithMessages, 
    openConversation, 
    messagesOfPosition, 
    onLoadingMessages,
    doSaveMessage,
    positionId,
    onUploadFile,
    getAllJobDetail,
}) => {
    return (
        positionsWithMessages.length ? (
            <div className="message-modal-body clearfix">
                <div
                    className="p-0 pull-left left-bar"
                    style={{ width: 300 }}
                >
                    <ListOfPositions
                        lists={positionsWithMessages}
                        onOpenConversation={openConversation}
                        getAllJobDetail = {getAllJobDetail}
                        positionId={positionId}
                    />
                </div>

                <div
                    className="p-0 pull-left"
                    style={{ width: "calc(100% - 300px)" }}
                >
                    {openConversation !== null &&
                        messagesOfPosition && (
                            <DetailConversation
                                // onSaving={this.state.onSavingMessage}
                                onUploadFile={onUploadFile}
                                onLoading={onLoadingMessages}
                                // onScrollLoadmore={() => this.loadMoreMessages(openConversation)}
                                // ignoreAutoToBottom={this.state.openConversationPage > 1}
                                saveMessage={(messageText) => doSaveMessage(positionId, messageText)}
                                positionId={positionId}
                                messages={messagesOfPosition}
                            />
                        )}
                </div>
            </div>
        ) : (
            <div className=" p-10 page-blank-message">
                <p className="p-0">
                    <IntlMessages id="messages.emptyMessages.firstLine" />
                </p>
                <p className="p-0">
                    <IntlMessages id="messages.emptyMessages.secondLine" />
                </p>
            </div>
        )
    )
}

export default BroadcastMessage
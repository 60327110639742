/**
 * Client Select Dropdown
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { DropdownToggle, DropdownMenu, Dropdown, Input } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import StoredData from "Constants/StoredData";
import MyGig from "Constants/MyGig";

// actions
import { getEmployerList, loginAs } from "Actions";

class ClientProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langDropdownOpen: false,
            email: "",
        };
    }

    // function to toggle dropdown menu
    toggle = () => {
        this.setState({
            langDropdownOpen: !this.state.langDropdownOpen,
        });
    };

    componentDidMount = () => {
        this.props.getEmployerList();
    };

    // on change language
    handleChangeEmployer(event) {
        const id = event.nativeEvent.target.selectedIndex;
        const email = event.nativeEvent.target[id].value;
        const employerName = event.nativeEvent.target[id].text;
        const gigPremium = event.nativeEvent.target[id].getAttribute(
            "data-wages_instant_payment"
        );

        StoredData.set(StoredData.KEYS.LOGGED_IN_AS, email);
        StoredData.set(StoredData.KEYS.EMPLOYER_NAME, employerName);
        StoredData.set(StoredData.KEYS.GIG_PREMIUM, gigPremium);

        this.setState({
            langDropdownOpen: false,
        });

        this.props.loginAs(email, this.props.history);
    }

    selectedEmployer(employers) {
        let selected = "";
        if (employers.length > 0) {
            for (const employer of employers) {
                if (
                    Number(employer.id) ===
                    Number(StoredData.get(StoredData.KEYS.EMPLOYER_ID))
                )
                    selected = employer.email;
            }
        }
        return selected;
    }

    render() {
        const { employerList } = this.props;
        const hasResponse = !!employerList && !!employerList.data;

        return (
            <Dropdown
                nav
                className="list-inline-item list-inline-item--v2 rct-dropdown clients-icon dropdown-section"
                isOpen={this.state.langDropdownOpen}
                toggle={this.toggle}
                // style={{ marginRight: 10 }}
            >
                <DropdownToggle
                    nav
                    className="dropdown-group-link dropdown-client-title"
                >
                    {/* <Tooltip title="Clients" placement="bottom"> */}
                    <div className="header-icon-container">
                        <IconButton className="header-icon-container-height">
                            <i
                                className="material-icons header-icon-container-icon "
                                style={{ position: "fixed", marginRight: 0 }}
                            >
                                person_pin
                            </i>
                        </IconButton>

                        <p className="header-icon-container-content">Client</p>
                    </div>
                    {/* </Tooltip> */}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-client">
                    <Input
                        id="log-in-as"
                        type="select"
                        value={
                            hasResponse &&
                            this.selectedEmployer(employerList.data.data)
                        }
                        className="list-unstyled mb-0"
                        onChange={(e) => this.handleChangeEmployer(e)}
                    >
                        {hasResponse &&
                            employerList.data.data.length > 0 &&
                            employerList.data.data.map((employer, key) => (
                                <option
                                    key={`client-${key}`}
                                    data-wages_instant_payment={
                                        employer.wages_instant_payment
                                    }
                                    data-gig_premium={employer.gig_premium}
                                    value={employer.email}
                                >
                                    {employer.name}{" "}
                                    {employer.gig_premium ? "*" : ""}
                                </option>
                            ))}
                    </Input>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

// map state to props
const mapStateToProps = ({ settings, omniEmployerReducer }) => ({
    settings,
    employerList: omniEmployerReducer.employerList,
    something: omniEmployerReducer,
});

const mapActionToProps = {
    getEmployerList,
    loginAs,
};

export default connect(mapStateToProps, mapActionToProps)(ClientProvider);

/**
 * UserAction
 */
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  EDIT_USER,
  ADD_USER,
  REMOVE_USER,
  CHECK_USER_EXIST_FAIL,
  DELETE_USER_FAILURE,
  REMOVE_ALL_USER,
  LOADING_USER_PAGE,
  INIT_STATE_USER,
  CREATE_UPDATE_USER_SUCCESS,
  CONFIRM_USER_SUCCESS_POPUP
} from './types';


export const getUsers = page => ({
  type: GET_USERS,
  payload: { page },
});

export const getUsersSuccess = response => ({
  type: GET_USERS_SUCCESS,
  payload: response,
});

export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

export const addUser = (newUser, history) => ({
  type: ADD_USER,
  payload: { newUser, history },
});

export const editUser = (updateUser, history) => ({
  type: EDIT_USER,
  payload: { updateUser, history },
});

export const removeUser = (id, history) => ({
  type: REMOVE_USER,
  payload: { id, history },
});

export const removeAllUsers = (users, history) => ({
  type: REMOVE_ALL_USER,
  payload: { users, history },
});

export const checkExistUserFail = () => ({
  type: CHECK_USER_EXIST_FAIL,
});

export const loadingUserPage = () => ({
  type: LOADING_USER_PAGE,
});

export const deleteUserFailure = () => ({
    type: DELETE_USER_FAILURE,
});

export const initStateForUser = () => ({
    type: INIT_STATE_USER
});

export const createupdateUserSuccess = (message) => ({
    type: CREATE_UPDATE_USER_SUCCESS,
    payload: { message }
});

export const confirmUserSuccessPopup =  () => ({
    type: CONFIRM_USER_SUCCESS_POPUP
});

/**
 * Job App Reducer
 */
import {
    UPDATE_NUMBER_OF_SEEKER,
    OPEN_CREATE_JOB_MODAL,
    CLOSE_CREATE_JOB_MODAL,
    OPEN_EDIT_JOB,
    CLOSE_EDIT_JOB,
    OPEN_REPOST_JOB,
    CLOSE_REPOST_JOB,
    GET_UPCOMING_JOBS,
    GET_UPCOMING_JOBS_SUCCESS,
    GET_UPCOMING_JOBS_FAILURE,
    UPDATE_UPCOMING_JOBS,
    REMOVE_JOB_FROM_LIST,
    GET_POSTED_JOBS,
    GET_POSTED_JOBS_SUCCESS,
    GET_POSTED_JOBS_FAILURE,
    GET_FILLED_JOBS,
    GET_FILLED_JOBS_SUCCESS,
    GET_FILLED_JOBS_FAILURE,
    GET_COMPLETED_JOBS,
    GET_COMPLETED_JOBS_SUCCUESS,
    GET_COMPLETED_JOBS_FAILURE,
    GET_PENDING_JOBS,
    GET_PENDING_JOBS_SUCCESS,
    GET_PENDING_JOBS_FAILURE,
    GET_NUDGABLE_SEEKER_FOR_POSITION,
    GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS,
    GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE,
    GET_JOB_INFORMATION_BY_ID,
    GET_JOB_INFORMATION_BY_ID_SUCCESS,
    GET_JOB_INFORMATION_BY_ID_FAILURE,
    CLOSE_JOB_INFORMATION,
    GET_JOB_BY_ID,
    GET_JOB_BY_ID_SUCCESS,
    GET_JOB_BY_ID_FAILURE,
    GET_JOB_MORE_INFO,
    GET_JOB_MORE_INFO_SUCCESS,
    GET_JOB_MORE_INFO_FAILURE,
    CANCEL_JOB,
    CANCEL_JOB_SUCCESS,
    CANCEL_JOB_FAILURE,
    GET_CALENDAR_JOBS,
    GET_CALENDAR_JOBS_SUCCESS,
    GET_CALENDAR_JOBS_FAILURE,
    SEARCH_JOB,
    SET_JOB_FORM,
    CHANGE_CHARGERATE,
    GET_CHARGERATE_BY_PAYRATE_SUCCESS,
    UPDATE_PROMOTED_STATUS_SUCCESS,
    UPDATE_JOB_AFTER_EDITED,
    ADD_JOB_AFTER_CREATED,
    SWITCH_JOB_STATUS_SUCCESS,
    TOGGLE_HIGHLIGHT_JOB,
    ADD_CALENDAR_JOBS_SUCCESS,
    SWITCH_JOB_STATUS,
    CLOSE_ALL_JOB_MODAL,
    UPDATE_JOB_CALENDAR_AFTER_EDIT_JOB,
} from "Actions/types";
import { copy, validNumberOrZero } from "Helpers/helpers";
import MyGig from "Constants/MyGig";
import PostJobConstants from "Components/Model/CreateEditJob/PostJobConstants";
import moment from "moment";

// initial state
const INIT_STATE = {
    showCreateJob: false,
    showEditJob: false,
    showRepostJob: false,
    upcomingJobs: [],
    postedJobs: [],
    filledJobs: [],
    completedJobs: [],
    pendingJobs: [],
    keyword: "",
    currentPage: "",
    isLastPage: false,
    postById: {
        shift: {
            location_id: 0,
        },
    },
    jobInfo: {
        id: null,
    },
    nudgableSeekerForPosition: [],
    calendarJobs: [],
    loadingJobById: false,
    loading: false,
    onLoadNextPage: false,
    nextPageAvailable: false,
    jobEdit: {
        uniforms: [],
        uniform_other: "",
        payrate: 0,
        charge_rate: 0,
        description: "",
        special_requirements: "",
        assignments_total: 1,
        requested_number: 1,
        skill_id: 0,
        shift: {
            location_id: 0,
        },
        schedule: [],
    },
    highlighJobId: undefined,
    errorCancelJob: "",
};

const DETECT_ACTIONS = {
    GET_DATA: 1,
    GET_SUCCESS: 2,
    GET_FAILURE: 3,
};

const RECORDS_PER_PAGE = 12;

const detectLoadingStates = (actionDetected, page, responseRows) => {
    let loading = false;
    let onLoadNextPage = false;
    let nextPageAvailable = false;

    if (actionDetected === DETECT_ACTIONS.GET_DATA) {
        if (page <= 1) {
            loading = true;
            onLoadNextPage = false;
        } else {
            loading = false;
            onLoadNextPage = true;
        }

        return { loading, onLoadNextPage };
    } else if (actionDetected === DETECT_ACTIONS.GET_SUCCESS) {
        if (responseRows >= RECORDS_PER_PAGE) {
            nextPageAvailable = true;
        }

        return { loading, onLoadNextPage, nextPageAvailable };
    }
    return { loading, onLoadNextPage, nextPageAvailable };
};

export const typeOfJob = (state, currentPage) => {
    const { postedPage, filledPage, upcomingPage, calendarPage } =
        MyGig.currentPage;
    const jobs = [];

    switch (currentPage) {
        case postedPage: {
            state.postedJobs.map((element) => jobs.push(element));
            break;
        }

        case filledPage: {
            state.filledJobs.map((element) => jobs.push(element));
            break;
        }

        case upcomingPage: {
            state.upcomingJobs.map((element) => jobs.push(element));
            break;
        }

        case calendarPage: {
            //jobs.push(state.postById);
            state.calendarJobs.map((element) => jobs.push(element));
            break;
        }

        default:
            break;
    }
    return jobs;
};

export default (state = INIT_STATE, action) => {
    let detectStates;
    let page;

    switch (action.type) {
        case UPDATE_NUMBER_OF_SEEKER: {
            const {
                jobPositionId, //id of target position to update number
                typeOfAction, //action
                currentPage, //the page that handled action
                seekerList, //list id of seeker to be accepted/declined/denied
            } = action.payload;
            const jobs = typeOfJob(state, currentPage);
            const {
                acceptCandidateSuccess,
                declineCandidateSuccess,
                declineAssignmentSuccess,
                addSeekerSuccess,
                appliedSuccess,
            } = MyGig.typeOfAction;

            let postById; // eslint-disable-line
            postById = state.postById;

            //get the job to update number seeker
            const currentJob = jobs.find(
                (element) => element.position_id === jobPositionId,
            );

            //get number of applied, confirmed, declined
            let {
                responses_pending: applied,
                assignments_filled: confirmed,
                responses_declined: declined,
            } = currentJob;
            applied = parseInt(applied);
            confirmed = parseInt(confirmed);
            declined = parseInt(declined);
            switch (typeOfAction) {
                case appliedSuccess: {
                    currentJob.responses_pending = applied + 1;
                    if (currentPage === MyGig.currentPage.calendarPage) {
                        postById = currentJob;
                    }
                    break;
                }

                case acceptCandidateSuccess: {
                    //minus total applied with the total number of accepted candidates
                    if (applied > 0)
                        currentJob.responses_pending =
                            applied - seekerList.length;

                    //plus total assignments with the total number of accepted candidates
                    currentJob.assignments_filled =
                        confirmed + seekerList.length;
                    if (currentPage === MyGig.currentPage.calendarPage) {
                        postById = currentJob;
                    }
                    break;
                }

                case declineCandidateSuccess: {
                    //minus total applied with the total number of declined candidates
                    if (applied > 0)
                        currentJob.responses_pending =
                            applied - seekerList.length;

                    //plus total declined with the total number of declined candidates
                    currentJob.responses_declined =
                        declined + seekerList.length;
                    if (
                        currentPage === MyGig.currentPage.calendarPage &&
                        currentJob
                    ) {
                        postById = currentJob;
                    }
                    break;
                }

                case declineAssignmentSuccess: {
                    //minus total confirmed with the total number of denied assignments
                    if (confirmed > 0)
                        currentJob.assignments_filled =
                            confirmed - seekerList.length;

                    //plus total declined with the total number of denied assignments
                    currentJob.responses_declined =
                        declined + seekerList.length;
                    if (currentPage === MyGig.currentPage.calendarPage) {
                        postById = currentJob;
                    }
                    break;
                }

                case addSeekerSuccess: {
                    currentJob.assignments_filled = confirmed + 1;
                    if (currentPage === MyGig.currentPage.calendarPage) {
                        postById = currentJob;
                    }
                    break;
                }

                default:
                    break;
            }

            for (let job of jobs) {
                if (job.position_id === jobPositionId) {
                    job = currentJob;
                }
            }

            return {
                ...state,
                calendarJobs:
                    currentPage == MyGig.currentPage.calendarPage
                        ? jobs
                        : state.calendarJobs,
                upcomingJobs:
                    currentPage == MyGig.currentPage.upcomingPage
                        ? jobs
                        : state.upcomingJobs,
            };
        }

        case SEARCH_JOB: {
            const { keyword, currentPage } = action.payload;
            return { ...state, keyword, currentPage };
        }

        case SET_JOB_FORM: {
            const { field, value } = action.payload;
            const { jobEdit } = state;
            jobEdit[field] = value;
            return { ...state, jobEdit };
        }

        case CHANGE_CHARGERATE:
            const { jobEdit } = state;
            jobEdit.charge_rate = action.payload.chargeRate;
            return {
                ...state,
                jobEdit,
            };

        case OPEN_CREATE_JOB_MODAL:
            return { ...state, showCreateJob: true };

        case CLOSE_CREATE_JOB_MODAL:
            return { ...state, showCreateJob: false };

        case OPEN_EDIT_JOB:
            return { ...state, showEditJob: true };

        case CLOSE_EDIT_JOB:
            return {
                ...state,
                showEditJob: false,
                jobEdit: {
                    uniforms: [],
                    uniform_other: "",
                    payrate: 0,
                    charge_rate: 0,
                    description: "",
                    special_requirements: "",
                    assignments_total: 1,
                    skill_id: 0,
                    shift: {
                        location_id: 0,
                    },
                    schedule: [],
                },
            };

        case OPEN_REPOST_JOB:
            return {
                ...state,
                postById: {
                    shift: {},
                },
                showRepostJob: true,
            };

        case CLOSE_REPOST_JOB:
            return {
                ...state,
                postById: {
                    shift: {},
                },
                showRepostJob: false,
            };

        case GET_UPCOMING_JOBS:
            return { ...state, loading: true };

        case GET_UPCOMING_JOBS_SUCCESS:
            let page = action.payload.page;
            let jobs = action.payload.jobs;
            let isLast = jobs.length < 15;
            let upcoming = { ...state.upcomingJobs };

            if (page == 1) {
                upcoming = jobs;
            } else {
                upcoming = Object.values(upcoming);
                upcoming = upcoming.concat(jobs);
            }
            return {
                ...state,
                loading: false,
                upcomingJobs: upcoming,
                isLastPage: isLast,
            };

        case GET_UPCOMING_JOBS_FAILURE:
            return { ...state };

        case UPDATE_UPCOMING_JOBS:
            let upcomings = [...state.upcomingJobs];

            //the job after updated
            let updated = action.payload;

            //find the index of updated job in upcoming list
            let index = upcomings.findIndex(
                (p) => p.position_id == updated.position_id,
            );
            let position = upcomings[index];

            //clone missing data of updated
            updated.assignments_filled = position.assignments_filled;
            updated.responses_declined = position.responses_declined;
            updated.responses_pending = position.responses_pending;
            updated.responses_total = position.responses_total;
            updated.createdAt = position.createdAt;
            updated.createdName = position.createdName;
            updated.updatedName = position.updatedName;

            //update new updated time
            updated.updatedAt = moment().format("YYYY-MM-DD HH:mm:ss ");

            //update the list with the new updated job
            upcomings[index] = updated;
            return {
                ...state,
                highlighJobId: updated.position_id,
                upcomingJobs: upcomings,
            };

        case REMOVE_JOB_FROM_LIST:
            let jobLists = typeOfJob(state, action.payload.currentPage);
            jobLists = jobLists.filter(
                (p) => p.position_id != action.payload.positionId,
            );
            if (action.payload.currentPage == MyGig.currentPage.upcomingPage) {
                return { ...state, upcomingJobs: jobLists };
            }
            return { ...state };

        case UPDATE_JOB_CALENDAR_AFTER_EDIT_JOB:
            const calendarJobs = copy(state.calendarJobs);
            calendarJobs.map((job) => {
                if (job.position_id == action.payload.position_id) {
                    for (const key in action.payload) {
                        if (job.hasOwnProperty(key)) {
                            job[key] = action.payload[key];
                        }
                    }
                }

                return job;
            });

            return { ...state, calendarJobs: calendarJobs };
        case GET_POSTED_JOBS:
            page = validNumberOrZero(action.payload.page);
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_DATA,
                page,
                null,
            );

            return {
                ...state,
                ...detectStates,
            };

        case GET_POSTED_JOBS_SUCCESS:
            let postedJobs = action.payload;
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_SUCCESS,
                null,
                postedJobs.length,
            );

            if (state.onLoadNextPage === true) {
                postedJobs = state.postedJobs.concat(postedJobs);
            }

            return {
                ...state,
                ...detectStates,
                postedJobs,
            };

        case GET_POSTED_JOBS_FAILURE:
            detectStates = detectLoadingStates(DETECT_ACTIONS.GET_FAILURE);
            return { ...state, ...detectStates };

        case GET_FILLED_JOBS:
            page = validNumberOrZero(action.payload.page);
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_DATA,
                page,
                null,
            );

            return {
                ...state,
                ...detectStates,
            };

        case GET_FILLED_JOBS_SUCCESS:
            let filledJobs = action.payload;
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_SUCCESS,
                null,
                filledJobs.length,
            );

            if (state.onLoadNextPage === true) {
                filledJobs = state.filledJobs.concat(filledJobs);
            }

            return {
                ...state,
                ...detectStates,
                filledJobs,
            };

        case GET_FILLED_JOBS_FAILURE:
            detectStates = detectLoadingStates(DETECT_ACTIONS.GET_FAILURE);
            return { ...state, ...detectStates };

        case GET_COMPLETED_JOBS:
            page = validNumberOrZero(action.payload.page);
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_DATA,
                page,
                null,
            );

            return {
                ...state,
                ...detectStates,
                loading: true,
            };

        case GET_COMPLETED_JOBS_SUCCUESS:
            let completedJobs = action.payload;
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_SUCCESS,
                null,
                completedJobs.length,
            );

            if (state.onLoadNextPage === true) {
                completedJobs = state.completedJobs.concat(completedJobs);
            }

            return {
                ...state,
                ...detectStates,
                loading: false,
                completedJobs,
            };

        case GET_COMPLETED_JOBS_FAILURE:
            detectStates = detectLoadingStates(DETECT_ACTIONS.GET_FAILURE);
            return { ...state, ...detectStates };

        case GET_PENDING_JOBS:
            page = action.payload.page;
            detectStates = detectLoadingStates(
                DETECT_ACTIONS.GET_DATA,
                page,
                null,
            );

            return {
                ...state,
                ...detectStates,
                loading: true,
            };

        case GET_PENDING_JOBS_SUCCESS:
            // let pendingJobs = action.payload;
            // detectStates = detectLoadingStates(DETECT_ACTIONS.GET_SUCCESS, null, pendingJobs.length);
            let pendingPage = action.payload.page;
            let pendingJobs = action.payload.jobs;
            let isLastPending = pendingJobs.length < 15;
            let pending = { ...state.pendingJobs };

            if (pendingPage == 1) {
                pending = pendingJobs;
            } else {
                pending = Object.values(pending);
                pending = pending.concat(pendingJobs);
            }
            return {
                ...state,
                loading: false,
                pendingJobs: pending,
                isLastPage: isLastPending,
            };

        case GET_PENDING_JOBS_FAILURE:
            detectStates = detectLoadingStates(DETECT_ACTIONS.GET_FAILURE);
            return { ...state, ...detectStates };

        case GET_NUDGABLE_SEEKER_FOR_POSITION:
            return { ...state };

        case GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS:
            return { ...state, nudgableSeekerForPosition: action.payload };

        case GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE:
            return { ...state };

        case GET_JOB_INFORMATION_BY_ID:
            return { ...state, loadingJobById: true };

        case GET_JOB_INFORMATION_BY_ID_SUCCESS: {
            const jobEdit = action.payload.response;
            if (jobEdit === null) {
                return {
                    ...state,
                    loadingJobById: false,
                    jobInfo: { id: null },
                };
            }
            return { ...state, loadingJobById: false, jobInfo: jobEdit };
        }

        case GET_JOB_INFORMATION_BY_ID_FAILURE:
            return { ...state, loadingJobById: false };

        case CLOSE_JOB_INFORMATION:
            return {
                ...state,
                jobInfo: {
                    id: null,
                },
            };
        case GET_JOB_BY_ID:
            return { ...state, loadingJobById: true };

        case GET_JOB_BY_ID_SUCCESS: {
            const jobEdit = action.payload;
            const { uniforms } = jobEdit;
            const { SHIRT, JEANS, SHOES } = PostJobConstants.UNIFORM_TYPES;
            const { postedJobs } = state;
            if (postedJobs.length) {
                let check = postedJobs.find(
                    (item) => item.position_id === jobEdit.id,
                );
                if (check) {
                    jobEdit.is_privated = check.is_privated;
                }
            }
            if (!uniforms.length) {
                jobEdit.uniforms = [
                    { style_id: SHIRT, id: 0 },
                    { style_id: JEANS, id: 0 },
                    { style_id: SHOES, id: 0 },
                ];
            } else {
                const tempStyleIds = [];
                let missingStyleIds = [];

                uniforms.forEach((uni) => {
                    tempStyleIds.push(uni.style_id);
                });

                if (tempStyleIds.length) {
                    missingStyleIds = [SHIRT, JEANS, SHOES].filter(
                        (element) => !tempStyleIds.includes(element),
                    );

                    if (missingStyleIds.length) {
                        missingStyleIds.forEach((missId) => {
                            jobEdit.uniforms.push({
                                style_id: missId,
                                id: 0,
                            });
                        });
                    }
                }
            }

            return {
                ...state,
                loadingJobById: false,
                postById: action.payload,
                jobEdit,
            };
        }

        case GET_JOB_BY_ID_FAILURE:
            return { ...state, loadingJobById: false };

        case GET_JOB_MORE_INFO:
            return { ...state, loadingJobById: true };

        case GET_JOB_MORE_INFO_SUCCESS:
            const data = action.payload.response;
            const listJob = [...state.calendarJobs];
            const infoIndex = listJob.findIndex(
                (p) => p.position_id == data.position_id,
            );
            listJob[infoIndex] = { ...listJob[infoIndex], ...data };
            return { ...state, calendarJobs: listJob };
        case CANCEL_JOB:
            return { ...state };

        case CANCEL_JOB_SUCCESS:
            return {
                ...state,
                postedJobs: state.postedJobs.filter(
                    (e) => e.position_id !== action.payload.jobId,
                ),
                upcomingJobs: state.upcomingJobs.filter(
                    (e) => e.position_id !== action.payload.jobId,
                ),
                filledJobs: state.filledJobs.filter(
                    (e) => e.position_id !== action.payload.jobId,
                ),
                calendarJobs: state.calendarJobs.filter(
                    (e) => e.position_id !== action.payload.jobId,
                ),
            };

        case CANCEL_JOB_FAILURE:
            return { ...state, errorCancelJob: action.payload };

        case GET_CALENDAR_JOBS:
            return { ...state, loading: true };

        case GET_CALENDAR_JOBS_SUCCESS:
            return { ...state, calendarJobs: action.payload, loading: false };

        case GET_CALENDAR_JOBS_FAILURE:
            return { ...state };

        case ADD_CALENDAR_JOBS_SUCCESS:
            action.payload.open = 1;
            return {
                ...state,
                calendarJobs: [...state.calendarJobs, action.payload].sort(
                    (a, b) => a.schedule[0].start - b.schedule[0].start,
                ),
            };

        case UPDATE_PROMOTED_STATUS_SUCCESS:
            const { positionId, status } = action.payload;

            return {
                ...state,
                postedJobs: state.postedJobs.map((job) => {
                    if (job.position_id === positionId) {
                        job.is_promoted = status;
                    }

                    return job;
                }),
                upcomingJobs: state.upcomingJobs.map((job) => {
                    if (job.position_id === positionId) {
                        job.is_promoted = status;
                    }

                    return job;
                }),
            };

        case UPDATE_JOB_AFTER_EDITED:
            const { positionEdited } = action.payload;
            return {
                ...state,
                highlighJobId: positionEdited.position_id,
                upcomingJobs: state.upcomingJobs.map((job) => {
                    if (job.position_id === positionEdited.position_id) {
                        job = { ...job, ...positionEdited };
                    }
                    return job;
                }),
            };

        case ADD_JOB_AFTER_CREATED:
            const { positionData } = action.payload;
            return {
                ...state,
                highlighJobId: positionData.position_id,
                upcomingJobs: [...state.upcomingJobs, positionData].sort(
                    (a, b) => a.schedule[0].start - b.schedule[0].start,
                ),
            };

        case SWITCH_JOB_STATUS:
            return {
                ...state,
                loadingJobById: true,
            };

        case SWITCH_JOB_STATUS_SUCCESS:
            const dataJobs = action.payload.data;
            const payload = action.payload.payload;
            if (payload.screenName === MyGig.currentPage.calendarPage) {
                const newJobs = copy(state.calendarJobs);

                const jobIndex = newJobs.findIndex(
                    (x) => x.shift_id === dataJobs[0].shift_id,
                );

                const internalJob = copy(newJobs[jobIndex]);

                dataJobs.forEach((position, index) => {
                    const indexCalendarJob =
                        index == 0 ? jobIndex : newJobs.length;
                    if (index == 1) {
                        newJobs[indexCalendarJob] = internalJob;
                    }
                    newJobs[indexCalendarJob].is_privated =
                        position.is_privated;
                    newJobs[indexCalendarJob].filled =
                        index == 0 && internalJob.assignments_filled > 0
                            ? 1
                            : 0;
                    newJobs[indexCalendarJob].convert_internal_flag =
                        position.convert_internal_flag;
                    newJobs[indexCalendarJob].payrate = position.payrate;
                    newJobs[indexCalendarJob].charge_rate =
                        position.charge_rate;
                    newJobs[indexCalendarJob].position_id = position.postion_id;
                    newJobs[indexCalendarJob].shift_id = position.shift_id;
                    newJobs[indexCalendarJob].assignments_total =
                        index == 0 && internalJob.assignments_filled > 0
                            ? internalJob.assignments_filled
                            : internalJob.assignments_total -
                              internalJob.assignments_filled;
                    newJobs[indexCalendarJob].assignments_filled =
                        index == 0 ? internalJob.assignments_filled : 0;
                });

                return {
                    ...state,
                    calendarJobs: newJobs,
                    loadingJobById: false,
                };
            }

            if (action.payload.screenName === "JobCard") {
                var newPostedJobs = copy(state.jobInfo);

                newPostedJobs.is_privated = payload.is_private;
                newPostedJobs.convert_internal_flag = 1;

                return {
                    ...state,
                    jobInfo: newPostedJobs,
                    loadingJobById: false,
                };
            }
            if (
                payload.jobType === MyGig.jobType.POSTED ||
                payload.jobType === MyGig.jobType.FILLED
            ) {
                var newPostedJobs = copy(state.upcomingJobs);
                var jobIndex = newPostedJobs.findIndex(
                    (x) => x.shift_id === payload.shift_id,
                );

                newPostedJobs[jobIndex].is_privated = payload.is_private;
                newPostedJobs[jobIndex].convert_internal_flag = 1;

                return {
                    ...state,
                    upcomingJobs: newPostedJobs,
                    loadingJobById: false,
                };
            }
            if (payload.jobType === MyGig.jobType.COMPLETED) {
                var newJobs = copy(state.completedJobs);
                var jobIndex = newJobs.findIndex(
                    (x) => x.shift_id === payload.shift_id,
                );

                newJobs[jobIndex].is_privated = payload.is_private;
                newJobs[jobIndex].convert_internal_flag = 1;

                return {
                    ...state,
                    completedJobs: newJobs,
                    loadingJobById: false,
                };
            }

            return state;
        case TOGGLE_HIGHLIGHT_JOB:
            if (action.payload.isHighlight) {
                return { ...state, highlighJobId: action.payload.positionId };
            } else {
                return { ...state, highlighJobId: undefined };
            }
        case CLOSE_ALL_JOB_MODAL:
            return {
                ...state,
                showCreateJob: false,
                showEditJob: false,
                showRepostJob: false,
                errorCancelJob: "",
            };

        default:
            return state;
    }
};

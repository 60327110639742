import React from 'react';
import {
  Form, FormGroup, Label, Input,
} from 'reactstrap';
import IconButton from "@material-ui/core/IconButton";
import { injectIntl } from 'react-intl';
import IntlMessages from 'Util/IntlMessages';

import ValidatorMessage from "Components/ValidatorMessage";

const UpdateInternalEmployeeForm = ({
  employee,
  onUpdateInternalEmployeeDetail,
  errors,
  selectedSkills,
  listOfSkills,
  onChangeSelectedSkills,
  onChangeSelectedLocation,
  locationId,
  addSkill,
  removeSkill,
  listOfLocations
}) => (
    <Form>
        <Form>
            <div className="row">
                <div className="col">
                    <Label for="firstName">
                        <IntlMessages id="internalEmployee.firstName" />
                    </Label>
                    <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        defaultValue={employee.firstName}
                        onChange={e => onUpdateInternalEmployeeDetail('firstName', e.target.value)}
                    />
                    {errors.firstName && (
                        <ValidatorMessage message={errors.firstName}/>
                    )}
                </div>
                <div className="col">
                    <Label for="second_name">
                        <IntlMessages id="internalEmployee.secondName" />
                    </Label>
                    <Input
                        type="text"
                        name="secondName"
                        id="second_name"
                        defaultValue={employee.secondName}
                        onChange={e => onUpdateInternalEmployeeDetail('secondName', e.target.value)}
                    />
                    {errors.secondName && (
                        <ValidatorMessage message={errors.secondName}/>
                    )}
                </div>
            </div>
        </Form>

        <FormGroup>
          
        </FormGroup>

        <FormGroup>
            <Label for="email">
                <IntlMessages id="internalEmployee.email" />
            </Label>
            <Input
                type="text"
                name="email"
                id="email"
                defaultValue={employee.email}
                onChange={e => onUpdateInternalEmployeeDetail('email', e.target.value)}
            />
            {errors.email && (
                <ValidatorMessage message={errors.email}/>
            )}
        </FormGroup>

        <FormGroup>
            <Label for="phone_number">
                <IntlMessages id="internalEmployee.phone" />
            </Label>
            <Input
                type="text"
                name="phone"
                id="phone_number"
                value={employee.phone}
                onChange={e => onUpdateInternalEmployeeDetail('phone', e.target.value)}
            />
            {errors.phone && (
                <ValidatorMessage message={errors.phone}/>
            )}
        </FormGroup>
        
        <div className="row">
            <div className="col-sm-12">
                <Label className="mb-0" for="Roles">
                    <IntlMessages id="internalEmployee.roles" />
                </Label>
            </div>
            {selectedSkills.map((skillId, index) => (
                <div key={`skill-${skillId}-${index}`} className="mt-10 col-sm-12 col-md-12 col-xl-6">
                    <FormGroup style={{ display: "inline-flex" }}>
                        {index !== 0 && (
                            <i
                                className="d-flex align-self-center mr-5 zmdi zmdi-close-circle orange font-2x on-hover"
                                onClick={() => removeSkill(index)}
                            />
                        )}
                        <Input
                            className='d-flex '
                            type="select"
                            id="Roles"
                            value={skillId}
                            onChange={e => onChangeSelectedSkills(e, index)}
                        >
                            {listOfSkills.map(
                                skillItem => (
                                    <option
                                        key={`skill-${skillItem.id}`}
                                        value={skillItem.id}
                                        disabled={selectedSkills.includes(skillItem.id)}
                                    >
                                        {skillItem.name}
                                    </option>
                                )
                            )}
                        </Input>
                    </FormGroup>
                </div>
            ))}
            <div className="mb-3 pl-0 col-sm-12 col-md-12 col-xl-6">
                <div className="d-flex align-items-center" id="add-role">
                    <IconButton
                        onClick={addSkill}
                    >
                        <i className="text-gray material-icons">add_circle</i>
                    </IconButton>
                    <span className="text-gray">
                        <IntlMessages id="internalEmployee.addRole" />
                    </span>
                    </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12 ">
                <Label className="mb-0" for="Roles">
                    <IntlMessages id="dashboard.location" />
                </Label>
            </div>
            <div
                className="mt-10 col-sm-12 col-md-12 col-xl-6"
            >
                <FormGroup className="d-flex flex-row align-items-center">
                    <Input
                        className="d-flex "
                        type="select"
                        id="Job-Location"
                        value={locationId}
                        onChange={e => onChangeSelectedLocation(e)}

                    >
                        <option
                            key={`location-${0}`}
                            value={0}
                        >
                            Select All
                        </option>
                        {
                            listOfLocations && listOfLocations.map(
                                location => (
                                    <option
                                        key={`location-${location.id}`}
                                        value={Number(location.id)}
                                    >
                                        {location.name},{" "}{location.city}
                                    </option>
                            ))
                        }
                    </Input>
                </FormGroup>
            </div>   
        </div>
    </Form>
);

export default injectIntl(UpdateInternalEmployeeForm);

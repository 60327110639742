/**
 * Add seeker actions
 */
import {
  ADD_SEEKER_SHOW_MODAL,
  ADD_SEEKER_HIDE_MODAL,
  ADD_SEEKER_FOR_POSITION,
  ADD_SEEKER_FOR_POSITION_SUCCESS,
  ADD_SEEKER_FOR_POSITION_FAILURE,
  ADD_SEEKER_FOR_POSITION_EXCEEDED,
  ADD_SEEKER_FOR_POSITION_UNAVAILABLE,
  ADD_SEEKER_HIDE_FAILURE_POPUP,
  ADD_SEEKER_OVER_18,
  ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS
} from './types';

export const hideFailurePopup = () => ({
  type: ADD_SEEKER_HIDE_FAILURE_POPUP
});

export const showAddSeekerModal = (positionId) => ({
  type   : ADD_SEEKER_SHOW_MODAL,
  payload: positionId
});

export const hideAddSeekerModal = () => ({
  type: ADD_SEEKER_HIDE_MODAL
});

export const addSeekerForPosition = (positionId, seekerId, currentPage, dateRange) => ({
  type   : ADD_SEEKER_FOR_POSITION,
  payload: {
    positionId,
    seekerId,
    currentPage,
    dateRange
  }
});

export const addSeekerForPositionSuccess = (response) => ({
  type   : ADD_SEEKER_FOR_POSITION_SUCCESS,
  payload: response
});

export const addSeekerForPositionFailure = (error) => ({
  type   : ADD_SEEKER_FOR_POSITION_FAILURE,
  payload: error
});

export const addSeekerForPositionExceeded = () => ({
  type: ADD_SEEKER_FOR_POSITION_EXCEEDED
});

export const addSeekerForPositionUnavailable = () => ({
    type: ADD_SEEKER_FOR_POSITION_UNAVAILABLE
  });

export const addSeekerForOver18 = () => ({
   type: ADD_SEEKER_OVER_18
})

export const addSeekerForPositionRestriction48hours = (error) => ({
  type   : ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS,
  payload: error
});

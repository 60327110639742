/**
 * Timecard actions
 */
import {
  TIMECARD_CREATE,
  TIMECARD_CREATE_SUCCESS,
  TIMECARD_CREATE_FAILURE
} from './types';

export const createTimecard = (positionObject, seekerIds, successCallback, failureCallback) => ({
  type   : TIMECARD_CREATE,
  payload: {positionObject, seekerIds, successCallback, failureCallback}
});

export const createTimecardSuccess = (response) => ({
  type   : TIMECARD_CREATE_SUCCESS,
  payload: response
});

export const createTimecardFailure = (error) => ({
  type   : TIMECARD_CREATE_FAILURE,
  payload: error
});

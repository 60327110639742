/**
 * Skill Reducer
 */

import {
    UPDATE_MASTER_RATE,
    UPDATE_MASTER_RATE_SUCCESS,
    UPDATE_MASTER_RATE_FAIL,
    HANDLE_CHANGE_MASTER_RATE,
    CLOSE_ALL_ALERT,
    SEND_SKILLS_CHARGE_RATE,
    SEND_SKILLS_CHARGE_RATE_SUCCESS,
    SEND_SKILLS_CHARGE_RATE_FAIL,
    SET_ERROR_TO_SKILL,
    SET_FIELD_SKILL_FORM,
    GET_ALL_SKILLS,
    GET_SKILLS_SUCCESS,
    GET_SKILLS_FAILURE,
    GET_ALL_SKILLS_BY_GROUP,
    GET_ALL_SKILLS_BY_GROUP_SUCCESS,
    GET_ALL_SKILLS_BY_GROUP_FAIL,
    GET_MASTER_RATE,
    GET_MASTER_RATE_SUCCESS,
    GET_MASTER_RATE_FAILURE,
} from 'Actions/types';

// initial state
const INIT_STATE = {
    skills: [],
    skillGroup: [],
    loading: false,
    showSuccess: false,
    showError: false,
    showSuccessMasterRate: false,
    showErrorMasterRate: false,
    masterRate: {
        holiday_pay: 0,
        pension_and_levy:0,
        employersNi: 0,
        margin_pay:0,
        other_cost:0
    },
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_ERROR_TO_SKILL: {
            const currentType = action.payload;
            const skillGroup = [];
            state.skillGroup.map(element => skillGroup.push(element));

            for (let element of skillGroup) {
                if (element.type_id === currentType.type_id) {
                    element = currentType;
                }
            }

            return { ...state, skillGroup };
        }

        case SET_FIELD_SKILL_FORM: {
            const { typeId, skillId, rate, value } = action.payload;

            /** update nested object to find down more this code */

            const skillGroup = [];
            state.skillGroup.map(element => skillGroup.push(element));

            const currentType = skillGroup.find(element => element.type_id === typeId);

            for (const skill of currentType.skills) {
                if (skill.skill_id === skillId) {
                    skill[rate] = value;
                }
            }

            for (let type of skillGroup) {
                if (type.type_id === typeId) {
                    type = currentType;
                }
            }

            return { ...state, skillGroup };
        }

        case GET_ALL_SKILLS:
            return { ...state, loading: true };

        case GET_SKILLS_SUCCESS:
            return { ...state, loading: false, skills: action.payload };

        case GET_SKILLS_FAILURE:
            return { ...state, skills: [] };

        case GET_ALL_SKILLS_BY_GROUP:
            return { ...state, loading: true };

        case GET_ALL_SKILLS_BY_GROUP_SUCCESS:
            return { ...state, loading: false, skillGroup: action.payload };

        case GET_ALL_SKILLS_BY_GROUP_FAIL:
            return { ...state, loading: false };

        case SEND_SKILLS_CHARGE_RATE:
            return { ...state, loading: true };

        case SEND_SKILLS_CHARGE_RATE_SUCCESS:
            const skillGroup = state.skillGroup;
            const {skillsChargeRate,typeId} = action.payload
            const index = skillGroup.findIndex((element)=> element.type_id === typeId)
            skillGroup[index].skillsChargeRate = skillsChargeRate
            return { ...state, skillGroup: skillGroup, loading: false, showSuccess: true };

        case SEND_SKILLS_CHARGE_RATE_FAIL:
            return { ...state, loading: false, showError: true };

        case CLOSE_ALL_ALERT:
            return { 
                ...state, 
                showSuccess: false, 
                showError: false, 
                showErrorMasterRate: false, 
                showSuccessMasterRate: false 
            };

        case HANDLE_CHANGE_MASTER_RATE: {

            const { masterRate } = state;

            const masterRatePercent = (parseFloat(masterRate) * 100).toString();

            const arrMasterRate = masterRatePercent.split('.');

            const { index, value } = action.payload;

            arrMasterRate[index] = value;

            const beforePart = parseInt(arrMasterRate[0]); // eslint-disable-line
            const afterPart = arrMasterRate[1]; // eslint-disable-line

            let newMasterRate = `${beforePart}.${afterPart}`;

            newMasterRate = parseFloat(newMasterRate) / 100;

            newMasterRate = newMasterRate.toString();

            return { ...state, masterRate: newMasterRate };
        }

        case GET_MASTER_RATE:
            return { ...state };

        case GET_MASTER_RATE_SUCCESS:
            return { ...state, masterRate: action.payload };

        case GET_MASTER_RATE_FAILURE:
            return { ...state };

        case UPDATE_MASTER_RATE:
            return { ...state };

        case UPDATE_MASTER_RATE_SUCCESS:
            return { ...state, showSuccessMasterRate: true };

        case UPDATE_MASTER_RATE_FAIL:
            return { ...state, showErrorMasterRate: true };

        default: return { ...state};
    }
};

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import IntlMessages from 'Util/IntlMessages';
import { Input } from "reactstrap";
import moment from 'moment';

import MyGig from 'Constants/MyGig';
import { validNumberOrZero } from 'Helpers/helpers';

const ListFilterDateOptions = [
    { text: 12, type: 'hour', value: 12 },
    { text: 1, type: 'day', value: 24 },
    { text: 2, type: 'day', value: 48 },
    { text: 3, type: 'day', value: 72 },
    { text: 4, type: 'day', value: 96 },
    { text: 5, type: 'day', value: 120 },
    { text: 6, type: 'day', value: 144 },
    { text: 7, type: 'day', value: 168 }
];

const FilterValues = {
    status: MyGig.jobType.POSTED,
    hour: 48
};

class FilterJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCustomLocationTextBox: false,
            customLocation: ''
        };
    }

    applyFilterHour(hour) {
        FilterValues.hour = hour;

        this.doApplyFilterValues();
    }

    applyFilterStatus(status) {
        FilterValues.status = status;

        this.doApplyFilterValues();
    }

    applyFilterLocation(location) {
        // Check if Other option is selected
        if (location == -1) {
            this.setState({
                showCustomLocationTextBox: true,
                customLocation: ''
            });
            return;
        }
        this.setState({
            showCustomLocationTextBox: false,
            customLocation: ''
        });
        FilterValues.location = location;
        this.doApplyFilterValues();
    }

    doApplyFilterValues() {
        let { status, hour, location } = FilterValues;
        const currentUnix = moment().unix();
        let start; let end;

        hour = validNumberOrZero(hour);

        if (hour) {
            if (status === MyGig.jobType.PENDING || status === MyGig.jobType.COMPLETED) {
                start = currentUnix - hour * 3600;
                end = currentUnix;
            } else {
                start = currentUnix;
                end = currentUnix + hour * 3600;
            }
        }

        this.props.onFilter(status, start, end, location);
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { showCustomLocationTextBox, customLocation } = this.state;
        return (
            <div className={`filter-job ${this.props.className}`}>
                <div className="row">
                    <div className="col-sm-2 col-xs-6">
                        <Input
                            type="select"
                            id="Filter-Date"
                            value={FilterValues.hour}
                            onChange={(e) => this.applyFilterHour(e.target.value)}
                        >
                            <option>{formatMessage({ id: "modalAllJobs.default.startIn" })}</option>

                            {ListFilterDateOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.type === 'hour'
                                        ? formatMessage({ id: "modalAllJobs.startIn.hour" }, { hour: option.text })
                                        : formatMessage({ id: "modalAllJobs.startIn.day" }, { day: option.text })
                                    }
                                </option>
                            ))}
                        </Input>
                    </div>
                    <div className="col-sm-2 col-xs-6">
                        <Input
                            type="select"
                            id="Filter-Status"
                            value={FilterValues.status}
                            onChange={(e) => this.applyFilterStatus(e.target.value)}
                        >
                            {/* <option>{formatMessage({ id: "modalAllJobs.default.status" })}</option> */}

                            {Object.keys(MyGig.jobType).map((key) => (
                                <option key={MyGig.jobType[key]} value={MyGig.jobType[key]}>{formatMessage({ id: `modalAllJobs.status.${MyGig.jobType[key]}` })}</option>
                            ))}
                        </Input>
                    </div>
                    <div className="col-sm-2 col-xs-6">
                        <Input
                            type="select"
                            id="Filter-Location"
                            value={FilterValues.location}
                            onChange={(e) => this.applyFilterLocation(e.target.value)}
                        >
                            <option value="">{formatMessage({ id: "modalAllJobs.default.location" })}</option>

                            {this.props.vacancyLocations.map(({ id, name }) => (
                                <option key={id} value={name}>{name}</option>
                            ))}

                            <option key={-1} value={-1}>{formatMessage({ id: "modalAllJobs.location.other" })}</option>
                        </Input>
                    </div>
                    {showCustomLocationTextBox &&
                        (
                            <div className="col-sm-4 col-xs-6 d-flex">
                                <Input
                                    type="text"
                                    placeholder={formatMessage({ id: "modalAllJobs.location.placeholder" })}
                                    name="Filter-Custom-Location"
                                    id="Filter-Custom-Location"
                                    onChange={e => this.setState({ customLocation: e.target.value })}
                                />
                                <Button
                                    
                                    className="ml-10 text-white gig-button ok line-height-1"
                                    onClick={() => {
                                        const customLocationTerm = customLocation.trim();
                                        if (customLocationTerm) {
                                            FilterValues.location = customLocationTerm;
                                            this.doApplyFilterValues();
                                        }
                                    }}
                                >
                                    <IntlMessages id="common.submit" />
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default injectIntl(FilterJob);

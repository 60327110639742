import React, { Component } from "react";

class NumberOfSeeker extends Component {
    render() {
        let { min } = this.props;
        const options = [];

        if (!min) {
            min = 1;
        }

        for (let i = min; i <= 200; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    }
}

export default NumberOfSeeker;

import React, { Component, Fragment } from "react";


import {
    Modal,
    ModalHeader,
    ModalBody, Form, FormGroup, Label, Input, ModalFooter,

} from 'reactstrap';
import connect from "react-redux/es/connect/connect";

import LoginDetailModel from "Model/LoginDetailModel";
import {
    openLoginDetail,
    closeLoginDetail,
    getLoginDetail,
    updateLoginDetailObject,
    updateLoginDetail,
    confirmLoginDetailErrorPopup,
    confirmLoginDetailSuccessPopup,
    loadInitDataDone
} from "Actions";
import IntlMessages from "Util/IntlMessages";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import ValidatorMessage from "Components/ValidatorMessage";
import { isEmail, minLength, maxLength } from 'Helpers/validators';

class LoginDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginDetailModal: false,
            name: '',
            email: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            errors: {},
            originEmail: ""
        };
    }

    toggleAddBussinessDetailModal = () => {
        const { openPopUp } = this.props;

        if (!openPopUp) {
            this.props.getLoginDetail();
            this.props.openLoginDetail();
        } else {
            this.props.closeLoginDetail();
        }
    }

    componentDidUpdate() {
        const { fetchData } = this.props;
        if (fetchData) {
            this.initForEdit();
            this.props.loadInitDataDone();
        }
    }

    initForEdit = () => {
        const { email } = this.props.data;
        this.setState({
            errors: {},
            originEmail: email
        });
    }

    onConfirmError = () => {
        this.props.confirmLoginDetailErrorPopup();
    }

    onConfirmSuccess = () => {
        this.props.confirmLoginDetailSuccessPopup();
    }

    onChangeLoginDetail = (e, key) => {
        const { data } = this.props;

        data[key] = e.target.value;
        this.props.updateLoginDetailObject(data);
    }

    updateLoginDetail() {
        const { data } = this.props;
        const errors = LoginDetailModel.validateFields(data);
        const { fullname, email, currentPassword, newPassword, confirmPassword, id } = data;
        const { originEmail } = this.state;

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            const loginDetail = {
                id,
                fullname,
                email,
                current_password: currentPassword || "",
                password: newPassword || "",
                password_confirmation: confirmPassword || "",
                changeEmail: originEmail !== email
            };
            this.props.updateLoginDetail(loginDetail);
        }
    }

    render() {
        const { popUpName, className, loading, openPopUp, response, error, updated, message } = this.props;
        const data = LoginDetailModel.createFromApiResponseData(this.props.data);
        const { errors } = this.state;

        return (
            <Fragment>
                <a href={null} onClick={() => this.toggleAddBussinessDetailModal()}>
                    <i className={`ti ${className}`} />
                    {popUpName}
                </a>
                <Modal isOpen={openPopUp} toggle={this.toggleAddBussinessDetailModal}>
                    <ModalHeader toggle={this.toggleAddBussinessDetailModal}><span
                        className="text-uppercase orange location-font-size-header">
                        <IntlMessages id="user.login.detail.title" />
                    </span>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="fullname"><IntlMessages id="user.login.detail.fullname" /></Label>
                                <Input type="text" id="fullname" value={data.fullname}
                                    onChange={(e) => this.onChangeLoginDetail(e, 'fullname')} />
                                {errors.name && (
                                    <ValidatorMessage message={errors.name}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="email"><IntlMessages id="user.login.detail.email" /></Label>
                                <Input type="text" value={data.email} id="email"
                                    onChange={(e) => this.onChangeLoginDetail(e, 'email')} />
                                {errors.email && (
                                    <ValidatorMessage message={errors.email}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="password"><IntlMessages
                                    id="user.login.detail.current.password" /></Label>
                                <Input
                                    type="password"
                                    id="current-password"
                                    onChange={(e) => this.onChangeLoginDetail(e, 'currentPassword')} />
                                {errors.currentPassword && (
                                    <ValidatorMessage message={errors.currentPassword}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="new-password">
                                    <IntlMessages id="user.login.detail.new.password" />
                                </Label>
                                <Input type="password"
                                    id="new-password"
                                    onChange={(e) => this.onChangeLoginDetail(e, 'newPassword')} />
                                {errors.newPassword && (
                                    <ValidatorMessage message={errors.newPassword}/>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="confirm-password">
                                    <IntlMessages id="user.login.detail.confirm.password" />
                                </Label>
                                <Input type="password" id="confirm-password"
                                    onChange={(e) => this.onChangeLoginDetail(e, 'confirmPassword')} />
                                {errors.confirmPassword && (
                                    <ValidatorMessage message={errors.confirmPassword}/>
                                )}
                            </FormGroup>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <Button  className="gig-button grey text-white line-height-1"
                            onClick={() => this.updateLoginDetail()}>
                            <IntlMessages id="button.save" />
                        </Button>
                        <Button  className="gig-button red text-white line-height-1"
                            onClick={() => this.toggleAddBussinessDetailModal()}>
                            <IntlMessages id="button.cancel" />
                        </Button>
                    </ModalFooter>
                    {loading && (
                        <div className="d-flex justify-content-center loader-overlay">
                            <CircularProgress />
                        </div>
                    )}

                    {error &&
                        <SweetAlert
                            error
                            show={response.error.message}
                            title=""
                            btnSize="sm"
                            onConfirm={() => this.onConfirmError()}>
                            <div className="text-uppercase">
                                <span>{response.error.message}</span>
                            </div>
                        </SweetAlert>
                    }
                    {updated &&
                        <SweetAlert
                            success
                            show={updated}
                            title=""
                            confirmBtnCssClass={"btn-orange"}
                            cancelBtnCssClass={"btn-black"}
                            btnSize="sm"
                            onConfirm={() => this.onConfirmSuccess()}>
                            <div className="text-uppercase">
                                <IntlMessages id={message} />
                            </div>
                        </SweetAlert>
                    }
                </Modal>
            </Fragment>
        );
    }
}


const mapStateToProps = ({ loginDetailSettingReducer }) => loginDetailSettingReducer;

const mapActionToProps = {
    getLoginDetail,
    openLoginDetail,
    closeLoginDetail,
    updateLoginDetailObject,
    updateLoginDetail,
    confirmLoginDetailErrorPopup,
    confirmLoginDetailSuccessPopup,
    loadInitDataDone
};
export default connect(mapStateToProps, mapActionToProps )(LoginDetail);

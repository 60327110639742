import { Message } from "@material-ui/icons";

// License Icon
import AcohoIcon from "Assets/license/acohol-licence-yellow.png";
import DrivingIcon from "Assets/license/driving-licence-yellow.png";
import ScooterIcon from "Assets/license/scooter-licence-yellow.png";
import HgvIcon from "Assets/license/hgv-licence-yellow.png";

// License Hover Icon

import AcohoHoverIcon from "Assets/license/acohol-licence-gray.png";
import DrivingHoverIcon from "Assets/license/driving-licence.png";
import ScooterHoverIcon from "Assets/license/scooter-licence-gray.png";
import HgvHoverIcon from "Assets/license/hgv-licence-gray.png";
import WelderHoverIcon from "Assets/qualifications/welder-icon.png";
import SiaHoverIcon from "Assets/qualifications/sia-icon.png";
import SpectatorHoverIcon from "Assets/qualifications/spectator-icon.png";

// Qualification Icon
import FoodSafetyIcon from "Assets/qualifications/food-safety-yellow.png";
import ManualHandlingIcon from "Assets/qualifications/manual-handling-yellow.png";
import DbsCheckIcon from "Assets/qualifications/dbs-check-yellow.png";
import ForkliftIcon from "Assets/qualifications/forklift-driver-yellow.png";
import WelderIcon from "Assets/qualifications/Welder-orange.png";
import SiaIcon from "Assets/qualifications/sia-orange.png";
import SpectatorIcon from "Assets/qualifications/spectator-safety-yellow.png";

// Qualification Hover Icon

import FoodSafetyHoverIcon from "Assets/qualifications/food-safety-gray.png";
import ManualHandlingHoverIcon from "Assets/qualifications/manual-handling-gray.png";
import DbsCheckHoverIcon from "Assets/qualifications/dbs-check-gray.png";
import ForkliftHoverIcon from "Assets/qualifications/forklift-driver-gray.png";

/**
 * Base status codes
 *
 */
const BASE_POSITION_STATUS = 100;
const BASE_JOB_RESPONSE_STATUS = 200;
const BASE_ASSIGNMENT_STATUS = 300;
const BASE_PAID_STATUS = 400;
const BASE_ASSIGNED_SCHEDULE_STATUS = 700;
const BASE_REMOTE_CHECK_STATUS = 500;
const BASE_REFERENCE_STATUS = 600;

const MyGig = {
  typeOfAction: {
    acceptCandidateSuccess: "acceptCandidateSuccess",
    declineCandidateSuccess: "declineCandidateSuccess",
    declineAssignmentSuccess: "declineAssignmentSuccess",
    addSeekerSuccess: "addSeekerSuccess",
    appliedSuccess: "appliedSuccess",
  },
  currentPage: {
    postedPage: "postedPage",
    filledPage: "filledPage",
    upcomingPage: "upcomingPage",
    weeklyPage: "weeklyPage",
    monthlyPage: "monthlyPage",
    calendarPage: "calendarPage",
    pendingPage: "pendingPage",
    completedPage: "completedPage",
  },
  status: {
    POSITION_OPEN: BASE_POSITION_STATUS,
    POSITION_CLOSED: BASE_POSITION_STATUS + 1,
    POSITION_CANCELLED: BASE_POSITION_STATUS + 2,
    POSITION_COMPLETED: BASE_POSITION_STATUS + 3,

    JOB_RESPONSE_PENDING: BASE_JOB_RESPONSE_STATUS,
    JOB_RESPONSE_ACCEPTED: BASE_JOB_RESPONSE_STATUS + 1,
    JOB_RESPONSE_COMPLETED: BASE_JOB_RESPONSE_STATUS + 2,
    JOB_RESPONSE_PAID: BASE_JOB_RESPONSE_STATUS + 3,
    JOB_RESPONSE_DECLINED: BASE_JOB_RESPONSE_STATUS + 4,
    JOB_RESPONSE_CANCELED: BASE_JOB_RESPONSE_STATUS + 5,
    JOB_RESPONSE_OVERLAPPED: BASE_JOB_RESPONSE_STATUS + 6,

    ASSIGNMENT_UNFILLED: BASE_ASSIGNMENT_STATUS,
    ASSIGNMENT_FILLED: BASE_ASSIGNMENT_STATUS + 1,
    ASSIGNMENT_CANCELLED: BASE_ASSIGNMENT_STATUS + 2,

    ASSIGNMENT_NOT_PAYABLE: BASE_PAID_STATUS,
    ASSIGNMENT_PAYMENT_DUE: BASE_PAID_STATUS + 1,
    ASSIGNMENT_PAID: BASE_PAID_STATUS + 2,

    ASSIGNED_SCHEDULE_UNCHECKED: BASE_ASSIGNED_SCHEDULE_STATUS,
    ASSIGNED_SCHEDULE_CHECKED_IN: BASE_ASSIGNED_SCHEDULE_STATUS + 1,
    ASSIGNED_SCHEDULE_CHECKED_OUT: BASE_ASSIGNED_SCHEDULE_STATUS + 2,
    ASSIGNED_SCHEDULE_CANCELED: BASE_ASSIGNED_SCHEDULE_STATUS + 3,

    REMOTE_CHECK_PENDING: BASE_REMOTE_CHECK_STATUS,
    REMOTE_CHECK_ACCEPTED: BASE_REMOTE_CHECK_STATUS + 1,
    REMOTE_CHECK_DECLINED: BASE_REMOTE_CHECK_STATUS + 2,

    REFERENCE_PENDING: BASE_REFERENCE_STATUS,
    REFERENCE_APPROVED: BASE_REFERENCE_STATUS + 1,
    REFERENCE_REJECTED: BASE_REFERENCE_STATUS + 2,

    SUBSCRIPTION_PLANS_TRIAL: 1,
    SUBSCRIPTION_PLANS_NOT_TRIAL: 0,
    SUBSCRIPTION_PLANS_PAYG: 1,
    SUBSCRIPTION_PLANS_NOT_PAYG: 0,

    UPDATE_SUCCESS: 1002,
    DELETE_SUCCESS: 1003,
    GET_RESPONSE_SUCCESS: 1000,
    CREATE_SUCCESS: 1001,

    RESPONSE_LOCATION_IS_IN_USE: 2021,

    POSITION_UNPROMOTED: 0,
    POSITION_PROMOTED: 1,
  },
  jobType: {
    POSTED: "job-posted",
    FILLED: "job-filled",
    COMPLETED: "job-completed",
    PENDING: "job-pending",
  },
  jobView: {
    Upcoming: 0,
    Posted: 1,
    Filled: 2,
  },
  payrate: {
    PRECISION: 8,
    SCALE: 2,
    MINIMUM_LIMIT: 11.44,
    WAITING_STAFF_MINIMUM_LIMIT: 0.01,
    GIG_DIRECT_MINIMUM_LIMIT: 8.91,
    ADMIN_SETTING_MINIMUM_LIMIT: 7.38,
  },
  employerPremium: {
    IS_PREMIUM: 1,
    NO_PREMIUM: 0,
  },
  roles: {
    EMPLOYEE: {
      ID: 1,
      NAME: "Employee",
    },
    EMPLOYER_ADMIN: {
      ID: 4,
      NAME: "Employer Admin",
    },
    OMNI_STAFF: {
      ID: 7,
      NAME: "Omni Staff",
    },
    NEW_PROVIDER_ADMIN: {
      ID: 9,
      NAME: "New Admin Provider",
    },
  },
  rolelevel: {
    SUPER_ADMIN_LEVEL: 0,
    ADMIN_LEVEL: 1,
    STANDARD_LEVEL: 2,
  },
  maxLength: {
    phone: 11,
    email: 25,
    fullname: 15,
    position: 10,
  },
  pages: {
    firstPage: 1,
  },
  cardStatus: {
    INVALID_CARD_NUMBER: 50,
    INVALID_EXPIRY_YEAR: "invalid_expiry_year",
    EMPTY_CARD_NAME: "empty_card_name",
    INCORRECT_NUMBER: "incorrect_number",
    PARAMETER_MISSING: "parameter_missing",
    CUSTOMER_ALREADY_HAS_CARD: 60,
    COULD_NOT_GET_CARD_DETAIL: 70,
    COULD_NOT_ADD_CARD: 80,
  },
  card: {
    MAX_LENGTH: 4,
    FOCUS_MONTH_INDEX: 5,
  },
  location: {
    EDIT_FLAG: 1,
    ADD_FLAG: 0,
  },
  bankAccount: {
    accountNo: "1331990",
    sortingCode: "20-37-63",
  },
  timeOut: 5000,
  channel: "job-response",
  evenDirectMessage: "message",
  channelDirectMessage: "direct-message",
  eventJobResponse: "App\\Events\\JobResponseEvent",
  eventRemoteRequest: "App\\Events\\RemoteRequestEvent",
  pusherErrorCode: 4004,
  pusheUndefinedConnection: "Undefined Pusher connection",
  LoginDetailPopUp: 1,
  BusinessDetailPopUp: 2,
  userStatus: {
    online: 1,
    offline: 2,
    inactive: 3,
  },
  signUp: {
    STEP1: 1,
    STEP2: 2,
    STEP3: 3,
    STEP4: 4,
    STEP5: 5,
    STEP6: 6,
    STEP7: 7,
    STEP8: 8,
    STEP9: 9,
    STEP10: 10,
  },
  distance: {
    _5_MILE: "5",
    _10_MILE: "10",
    _20_MILE: "20",
    _30_MILE: "30",
    _40_MILE: "40",
  },
  default: {
    invoice_flag: 1,
  },
  default_login_as: {
    id: 66,
  },
  conversation: {
    OPEN_CONVERSATION: 1,
    CLOSE_CONVERSATION: 2,
    SEEKER_DELETE_CONVERSATION: 4,
  },
  checkType: {
    checkIn: "checkin",
    checkOut: "checkout",
  },
  specialJob: {
    name: "Training",
    valueDefault: 8.72,
  },
  listTrainingLicenseIcons: [
    // Qualification Icon
    {
      name: "Alcohol",
      keyLink: "alcohol_license",
      srcIcon: AcohoIcon,
      srcHoverIcon: AcohoHoverIcon,
      qualificationValue: 1,
    },
    {
      name: "Driving",
      keyLink: "driving_license",
      srcIcon: DrivingIcon,
      srcHoverIcon: DrivingHoverIcon,
      qualificationValue: 2,
    },
    {
      name: "Scooter",
      keyLink: "scooter_license",
      srcIcon: ScooterIcon,
      srcHoverIcon: ScooterHoverIcon,
      qualificationValue: 3,
    },
    {
      name: "HGV Driver",
      keyLink: "hgv_license",
      srcIcon: HgvIcon,
      srcHoverIcon: HgvHoverIcon,
      qualificationValue: -1,
    },

    // License Icon
    {
      name: "Food Safety",
      keyLink: "food_safety_allergens",
      srcIcon: FoodSafetyIcon,
      srcHoverIcon: FoodSafetyHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "Manual Handling",
      keyLink: "manual_handing",
      srcIcon: ManualHandlingIcon,
      srcHoverIcon: ManualHandlingHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "Forklift Driver",
      keyLink: "fork_lift_truck",
      srcIcon: ForkliftIcon,
      srcHoverIcon: ForkliftHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "DBS Checked",
      keyLink: "dbs_check",
      srcIcon: DbsCheckIcon,
      srcHoverIcon: DbsCheckHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "Welder",
      keyLink: "welder",
      srcIcon: WelderIcon,
      srcHoverIcon: WelderHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "Sia",
      keyLink: "sia",
      srcIcon: SiaIcon,
      srcHoverIcon: SiaHoverIcon,
      qualificationValue: -1,
    },
    {
      name: "Spectator Safety",
      keyLink: "spectators_safety",
      srcIcon: SpectatorIcon,
      srcHoverIcon: SpectatorHoverIcon,
      qualificationValue: -1,
    },
  ],
  NO_SHOW_TYPES: {
    sick: "Sick",
    hide: "No show",
  },
  MESSAGE_TYPE: {
    FILE: 1,
    NORMAL: 0,
  },
  PLACEHOLDER_JOB_DESCRIPTION:
    "Busy high street restaurant requires an outgoing and enthusiastic waiter with at least 2 years experience to help assist with taking/placing orders as well as serving and setting/cleaning down tables",
  CHARGE_RATE_PRIVATE: 0,
  PAY_RATE_PRIVATE: 0,
  SEARCH_SEEKERS: {
    MIN_LENGTH: 3,
  },
  LIMIT_PROVIDER: 26571,
  TRAINING_SKILL_ID: 182,
  EXCEED_STUDENT_ALERT: "Exceed student working hours.",
  POSITION_FILLED_ALERT: "Position is filled.",
  EXCEED_MAXIMUM_HOURS_REACHED: "Maximum Hours Reached",
  RESTRICTION_48_HOURS: "restriction 48 hours",
  ACCEPT_SEEKER_RESULT: {
    ACCEPT_ALL: "createSuccessfully",
    NOT_ACCEPT_ALL: "canNotAcceptAll",
  },
  EMPLOYERS_NOT_AUTO_CHECKIN: [35],
  WINCANTON_GROUPS_IDS: [
    1710, 1910, 1813, 1739, 1859, 1873, 1737, 1777, 1778, 1764, 1769, 1779,
    1596, 1869, 1780, 1844, 1697, 138, 1962, 1947, 1404,
  ], //Employer Id
  WAITING_STAFF_UNDER_18_ID: 10007
};

export default MyGig;

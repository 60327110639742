import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import CreateConversation from 'Components/Model/DirectMessage/SearchSeeker/CreateConversation';

import UserDefault from 'Assets/img/default-user.png';

const SeekerItem = ({ seeker, onSelect, showForm }) =>
    (
        <div className="seeker-row">
            <div className="seeker-item">
                <Avatar
                    alt={seeker.fullname}
                    src={seeker.profile_thumbnail || UserDefault}
                    className="img-fluid rounded-circle mr-15 align-self-center"
                />

                <div className="seeker-name">{seeker.fullname}</div>

                <span
                    className="gig-icon icon-direct-message"
                    onClick={() => onSelect(seeker.id)}
                />
            </div>

            {showForm && (
                <CreateConversation userId={seeker.user_id} />
            )}
        </div>
    );

export default SeekerItem;

import { createMuiTheme } from '@material-ui/core';
import AppConfig from 'Constants/AppConfig';

const DatepickerCreateJob = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: AppConfig.themeColors.black
            }
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: AppConfig.themeColors.white,
                color          : AppConfig.themeColors.black
            },
            dayLabel: {
                color: AppConfig.themeColors.black
            }
        },
        MuiTypography: {
            body1: {
                fontFamily: AppConfig.fontWeight.bold
            }
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: AppConfig.themeColors.black
            }
        },
        MuiPickersYear: {
            root: {
                "&$selected": {
                    color: AppConfig.themeColors.black
                }
              }
        },
        MuiPickersDay: {
            day: {
                color     : AppConfig.themeColors.black,
                fontFamily: AppConfig.fontWeight.bold
            },
            daySelected: {
                backgroundColor: AppConfig.themeColors.orange,
                color          : AppConfig.themeColors.white,
                fontSize       : '10pt',
                "&:hover": {
                    backgroundColor: AppConfig.themeColors.orange,
                  },
            },
            root: {
                "&$selected": {
                    backgroundColor: AppConfig.themeColors.black,
                    color          : AppConfig.themeColors.white,
                    fontSize       : '10pt',
                }
            },
            current: {
                color: AppConfig.themeColors.black
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color     : AppConfig.themeColors.black,
                fontFamily: AppConfig.fontWeight.bold
            }
        },
        MuiPickersClock: {
            clock: {
                backgroundColor: AppConfig.themeColors.white
            },
            pin: {
                backgroundColor: AppConfig.themeColors.black
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: AppConfig.themeColors.black
            },
            thumb: {
                backgroundColor: AppConfig.themeColors.black,
                borderColor:     AppConfig.themeColors.black
            }
        },
        MuiPickersClockNumber: {
            clockNumber: {
                fontFamily: AppConfig.fontWeight.bold
            },
            root: {
                "&$selected": {
                    backgroundColor: AppConfig.themeColors.black,
                    fontSize: '12pt'
                }
            }
        },
        MuiInput: {
            input: {
                width       : '100%',
                padding     : '0.375rem 0.5rem',
                height      : 'calc(2rem + 2px)',
                fontSize    : '0.75rem',
                fontFamily  : AppConfig.fontWeight.normal,
                lineHeight  : '1.5',
                color       : '#000',
                boxSizing   : 'border-box',
                backgroundColor: '#eeeeee',
            },

            root: {
                border      : '1px solid #EBEDF2',
                borderRadius: '0.25rem',
                boxShadow   : 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
            }

        },
        MuiTabs: {
            indicator: {
                backgroundColor: AppConfig.themeColors.transparent
            }
        },
        MuiButton: {
            label: {
                color: 'black',
                fontFamily: 'museosans-700'
            }
        }

    }
});

export default DatepickerCreateJob;

/**
 * User Reducer
 */
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    CHECK_USER_EXIST_FAIL,
    ADD_USER,
    EDIT_USER,
    DELETE_USER_FAILURE,
    CONFIRM_ERROR_DELETE_POPUP,
    LOADING_USER_PAGE,
    INIT_STATE_USER,
    CREATE_UPDATE_USER_SUCCESS,
    CONFIRM_USER_SUCCESS_POPUP
} from 'Actions/types';


const INIT_STATE = {
    allUsers: [],
    loading: true,
    existUserMessage: null,
    errorsServerDelete: null,
    getUserSuccess: false,
    updated: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USERS:
            return { ...state, payload: action.payload, loading: true };

        case GET_USERS_SUCCESS:
            return { ...state, allUsers: action.payload.data.data, loading: false, getUserSuccess: true };

        case ADD_USER:
            return { ...state, existUserMessage: null };

        case EDIT_USER:
            return { ...state, existUserMessage: null };

        case GET_USERS_FAILURE:
            return { ...state };

        case CHECK_USER_EXIST_FAIL:
            return { ...state, existUserMessage: true };

        case DELETE_USER_FAILURE:
            return { ...state, errorsServerDelete: true };

        case CONFIRM_ERROR_DELETE_POPUP:
            return { ...state, errorsServerDelete: null };

        case LOADING_USER_PAGE:
            return { ...state, loading: true };

        case INIT_STATE_USER:
            return { ...state, getUserSuccess: false };

        case CREATE_UPDATE_USER_SUCCESS:
            return { ...state, updated: true, message: action.payload.message };

        case CONFIRM_USER_SUCCESS_POPUP:
            return { ...state, updated: false };
            
        default: return { ...state };
    }
};

/**
 * Confirm password Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess, isExpectedError } from 'Helpers/ResponseStatus';
import StoredData            from 'Constants/StoredData';
import ConfirmPasswordHelper from 'Helpers/confirmpassword';

import {
    AMENDING_HOURS_ADD_COMMENT
} from 'Actions/types';

import {
    showErrorResponse,
    amendingHourCommentSuccess,
    amendingHourCommentFailure,
    getPendingRemoteRequest
} from 'Actions';

import {
    addAmendingComment
} from 'Services/employers/AssignmentService';

function* handleAmendingHourComment({payload}) {
    const { comment, assignedScheduleId, locationId, start, end } = payload;
    try {
        const response = yield call(addAmendingComment, comment,assignedScheduleId);

        if (response.data) {
            if (isSuccess(response)) {
                yield put(amendingHourCommentSuccess(response.data.data));
                yield put(getPendingRemoteRequest(start, end, 0, locationId, 1, true));
            } else {
                yield put(amendingHourCommentFailure(response));
            }

        } else {
            yield put(amendingHourCommentFailure(response));
        }
    } catch (error) {
        yield put(confirmPasswordWrongPassword());
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch confirm password
 */
export function* watchAmendingHourComment() {
    yield takeEvery(AMENDING_HOURS_ADD_COMMENT, handleAmendingHourComment);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchAmendingHourComment)
    ]);
}

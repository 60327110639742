import API from 'Api';

/**
 * Get login detail of user
 * * @param {Object} request - Remote request object
 * @typedef {
        employerLevelId   : integer
    }
 *
 * @return async
 */
export const getLoginDetailInfoRequest = async(params) => await API.get('/employers/account-owner', params)
    .then(response => response)
    .catch(err => err);


/**
 * Call api to update business detail
 * * @param {Object} Login detail object
 * @typedef {
        id                      : integer,
        fullname                : string,
        email                   : string,
        current_password        : string
        password                : string
        password_confirmation   : string
        changeEmail             : integer|unsigned
    }
 *
 * @return async
 */
export const updateLoginDetailInfoRequest = async(params) => await API.put('/employers/staff/credentials', params)
    .then(response => response)
    .catch(err => err);


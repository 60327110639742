import React, {Component, Fragment} from "react";


import BusinessDetail from "./components/BussinessDetail";
import LoginDetail from "./components/LoginDetail";
import MyGig from "Constants/MyGig";


class UserSetting extends Component {

    render() {
        const {popUpName, className, popupType} = this.props;
        return (
            <Fragment>
                {popupType === MyGig.LoginDetailPopUp  ?
                    <LoginDetail popUpName={popUpName} className={className}/> :
                    <BusinessDetail popUpName={popUpName} className={className}/>
                }
            </Fragment>
        );
    }
}

export default UserSetting;

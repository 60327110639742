/**
 * Charge Rate By Pay Rate Reducer
 */

import {
    GET_CHARGERATE_BY_PAYRATE,
    GET_CHARGERATE_BY_PAYRATE_SUCCESS,
    GET_CHARGERATE_BY_PAYRATE_FAILURE,
    CHANGE_CHARGERATE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    chargeRate: null,
    loading: false,
    payRate: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHARGERATE_BY_PAYRATE:
            return { ...state, payRate: action.payload.payRate };

        case GET_CHARGERATE_BY_PAYRATE_SUCCESS:
            return { ...state, chargeRate: action.payload.data.chargeoutRate };

        case GET_CHARGERATE_BY_PAYRATE_FAILURE:
            return { ...state };

        case CHANGE_CHARGERATE: 
            return {
                ...state, chargeRate: action.payload.chargeRate
            }
        default: return { ...state};
    }
};

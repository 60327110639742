/**
 * App Settings Reducers
 */
import {
  SHOW_ERROR_RESPONSE,
  HIDE_ERROR_RESPONSE,
  COLLAPSED_SIDEBAR,
  DARK_MODE,
  BOXED_LAYOUT,
  RTL_LAYOUT,
  MINI_SIDEBAR,
  SEARCH_FORM_ENABLE,
  CHANGE_THEME_COLOR,
  TOGGLE_SIDEBAR_IMAGE,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  TOGGLE_DARK_SIDENAV,
  APP_SHOW_ROOT_SPINNER,
  APP_HIDE_ROOT_SPINNER,
  SHOW_FORM_GPDR,
  HIDE_FORM_GPDR,
  STORE_DATA_PROTECTION,
  STORE_DATA_PROTECTION_SUCCESS,
  STORE_DATA_PROTECTION_FAIL,
} from 'Actions/types';

import Sidebar1 from "../assets/img/sidebar-1.png";
// app config
import AppConfig from 'Constants/AppConfig';

/**
 * initial app settings
 */
const INIT_STATE = {
  navCollapsed: AppConfig.navCollapsed,
  darkMode: AppConfig.darkMode,
  boxLayout: AppConfig.boxLayout,
  rtlLayout: AppConfig.rtlLayout,
  miniSidebar: AppConfig.miniSidebar,
  searchFormOpen: false, // search form by default false
  startUserTour: false,
  isDarkSidenav: false,
  isShowErrorPopup: false,
  isShowFormGPDR: false,
  loading: false,
  error: {
    response: {
      data: {
        error: {
          message: ''
        }
      }
    }
  },
  themes: [
    {
      id: 1,
      name: 'primary'
    },
    {
      id: 2,
      name: 'secondary'
    },
    {
      id: 3,
      name: 'warning'
    },
    {
      id: 4,
      name: 'info'
    },
    {
      id: 5,
      name: 'danger'
    },
    {
      id: 6,
      name: 'success'
    }
  ],
  activeTheme: {
    id: 1,
    name: 'primary'
  },
  // sidebar background image
  sidebarBackgroundImages: [
    Sidebar1
  ],
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background
  selectedSidebarImage: AppConfig.sidebarImage, // default sidebar background image
  locale: AppConfig.locale,
  showRootSpinner: false,
  messageRootSpinner: null,
  mainPageScrolledBottom: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // for show on/off form GPDR for unaccpetTerm employer
    case SHOW_FORM_GPDR:
      return { ...state, isShowFormGPDR: true };

    case HIDE_FORM_GPDR:
      return { ...state, isShowErrorPopup: false };

    case STORE_DATA_PROTECTION:
      return { ...state, loading: true };

    case STORE_DATA_PROTECTION_SUCCESS:
      return { ...state, isShowFormGPDR: false, loading: false };

    case STORE_DATA_PROTECTION_FAIL:
      return { ...state, loading: false };

    // for show error form when put yield fail
    case SHOW_ERROR_RESPONSE:
      return { ...state, isShowErrorPopup: true, error: action.payload };

    case HIDE_ERROR_RESPONSE:
      return { ...state, isShowErrorPopup: false, error: {} };

    // collapse sidebar
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed };

    // change theme color
    case CHANGE_THEME_COLOR:
      return { ...state, activeTheme: action.payload };

    // dark mode
    case DARK_MODE:
      return { ...state, darkMode: action.payload };

    // boxed layout
    case BOXED_LAYOUT:
      return { ...state, boxLayout: action.payload };

    // rtl layout
    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload };

    // mini sidebar
    case MINI_SIDEBAR:
      return { ...state, miniSidebar: action.payload };

    // search form
    case SEARCH_FORM_ENABLE:
      document.body.classList.toggle('search-active', !state.searchFormOpen);
      return { ...state, searchFormOpen: !state.searchFormOpen };

    // togglw sidebar image
    case TOGGLE_SIDEBAR_IMAGE:
      return { ...state, enableSidebarBackgroundImage: !state.enableSidebarBackgroundImage };

    // set sidebar image
    case SET_SIDEBAR_IMAGE:
      return { ...state, selectedSidebarImage: action.payload };

    // set language
    case SET_LANGUAGE:
      return { ...state, locale: action.payload };

    // dark sidenav
    case TOGGLE_DARK_SIDENAV:
      return { ...state, isDarkSidenav: !state.isDarkSidenav };

    case APP_SHOW_ROOT_SPINNER:
      const { messageKey } = action.payload;

      return {
        ...state,
        showRootSpinner: true,
        messageRootSpinner: messageKey
      };

    case APP_HIDE_ROOT_SPINNER:
      if (state.showRootSpinner) {
        return {
          ...state,
          showRootSpinner: false,
          messageRootSpinner: null
        };
      }

      return { ...state };

    default: return { ...state };
  }
};

/**
 * User
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    ADD_USER,
    GET_USERS,
    EDIT_USER,
    REMOVE_USER,
} from 'Actions/types';

import {
    showErrorResponse,
    getUsersSuccess,
    checkExistUserFail,
    deleteUserFailure,
    loadingUserPage,
    getUsers,
    createupdateUserSuccess
} from 'Actions';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    getUsersRequest,
    registerUsersRequest,
    userExistRequest,
    sendVerifyEmailRequest,
    updateUsersRequest,
    deleteUserRequest,
} from 'Services/employers/UserService';

function* handleGetUsers({ payload }) {
    try {
        const { page } = payload;
        const response = yield call(getUsersRequest, { page });
        if (isSuccess(response)) {
            yield put(getUsersSuccess(response));
        } else {
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleAddUser({ payload }) {
    const { email } = payload.newUser;
    const message = "users.create.success";
    try {
        const hasVerified = yield call(userExistRequest, { email });
        if (hasVerified.data.data.exists === false) {
            const registerUser = yield call(registerUsersRequest, payload.newUser);

            if (registerUser.data.data && registerUser.data.data.user_id) {
                const userId = registerUser.data.data;
                const sendEmail = yield call(sendVerifyEmailRequest, userId);
                if (isSuccess(sendEmail)) {
                    yield put(getUsers());
                    yield put(createupdateUserSuccess(message));
                }
            }
        } else {
            yield put(checkExistUserFail());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}


function* handleEditUser({ payload }) {
    const { updateUser } = payload;
    const { update_user, checkExist } = updateUser;
    let checkDuplicate = false;
    let sendMailFlag = false;
    let message = "users.update.success";
    try {
        if (checkExist) {
            const verifyUserExist = yield call(userExistRequest, {email: update_user.email});
            if (!verifyUserExist.data.data.exists === false) {
                yield put(checkExistUserFail());
                checkDuplicate = true;
            } else {
                sendMailFlag = true;
            }
        }

        if (!checkDuplicate) {
            const updatedUser = yield call(updateUsersRequest, update_user);
            if (isSuccess(updatedUser)) {
                if (sendMailFlag) {
                    const params = {
                        user_id: update_user.user_id,
                        email: update_user.email
                    };

                    yield call(sendVerifyEmailRequest, params);
                    message = "loginDetail.sendMail.success";
                }
                yield put(loadingUserPage());
                yield put(getUsers());
                yield put(createupdateUserSuccess(message));
            }
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleRemoveUser({ payload }) {
    const { id } = payload;
    try {
        const user = yield call(deleteUserRequest, id);
        if (isSuccess(user)) {
            yield put(getUsers());
        } else {
            yield put(deleteUserFailure());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

export function* watchGetUser() {
    yield takeEvery(GET_USERS, handleGetUsers);
}

export function* watchAddUsers() {
    yield takeEvery(ADD_USER, handleAddUser);
}

export function* watchEditUser() {
    yield takeEvery(EDIT_USER, handleEditUser);
}

export function* watchRemoveUser() {
    yield takeEvery(REMOVE_USER, handleRemoveUser);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetUser),
        fork(watchAddUsers),
        fork(watchEditUser),
        fork(watchRemoveUser),
    ]);
}

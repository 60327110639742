import BaseModel from './BaseModel';

export default class ConversationModel extends BaseModel {
    static createFromApiResponseData(data) {
        const model = new LocationModel;
        model.id = data.id;
        model.employeeThumbnail = data.employee_thumnail;
        model.message = data.message;
        model.title = data.title;
        model.name = data.name;
        model.seekerName = data.seeker_name;
        model.status = data.status;
        return model;
    }

    static validateFields(params) {

        const { fullName, subject, message } = params;
        const errors = {};

        if (fullName.trim() === '') {
            errors.fullName = 'empty.fullname.conversation';
        }

        if (subject.trim() === '') {
            errors.subject = 'empty.subject.conversation';
        }

        if (message.trim() === '') {
            errors.message = 'empty.message.conversation';
        }

        return errors;

    }
}

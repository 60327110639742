/**
 * Direct Message Reducer
 */
import {
    DIRECT_MESSAGE_CREATE_CONVERSATION,
    DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS,
    DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE,
    GET_CONVERSATION,
    GET_CONVERSATION_SUCCESS,
    OPEN_ALERT_DELETE_CONVERSATION_SUCCESS,
    CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS
} from 'Actions/types';

import { sortConversations } from "Helpers/helpers"

// initial state
const INIT_STATE = {
    loading: false,
    conversations: [],
    resetForm: false,
    showAlertDeleteConversation: false,
    isLast: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DIRECT_MESSAGE_CREATE_CONVERSATION:
            return { ...state, loading: true };

        case DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS:
            return { ...state, loading: false, resetForm: true };

        case DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE:
            return { ...state, loading: false };

        case GET_CONVERSATION:
            const isNotSameConversation = action.payload.isNotSameConversation

            return { ...state, loading: !isNotSameConversation ? true : false };

        case GET_CONVERSATION_SUCCESS:
            const page = action.payload.page
            const data = action.payload.params
            const isLast = data.length < 15
            let conversation = Object.values(state.conversations) //convert object to array
            if(page > 1) {
                conversation = conversation.concat(data)
            } else {
                conversation = data
            }
            return { ...state, conversations: sortConversations(conversation), loading: false, isLast: isLast };

        case OPEN_ALERT_DELETE_CONVERSATION_SUCCESS:
            return { ...state, showAlertDeleteConversation: true}
        case CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS:
            return { ...state, showAlertDeleteConversation: false}
        default: return { ...state };
    }
};

import { createMuiTheme } from '@material-ui/core';
import { callbackify } from 'util';

const headerHeight = '64px';
const ModalAllJobsTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
                height: `calc(100vh - ${headerHeight})`,
                top: headerHeight,
                transform: 'inherit !important'
            }
        }
    }
});

export default ModalAllJobsTheme;

import {
    DIRECT_MESSAGE_OPEN_MODAL,
    DIRECT_MESSAGE_CLOSE_MODAL,
    DIRECT_MESSAGE_OPEN_SEARCH,
    DIRECT_MESSAGE_CLOSE_SEARCH,
    DIRECT_MESSAGE_CREATE_CONVERSATION,
    DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS,
    DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE,
    GET_CONVERSATION,
    GET_CONVERSATION_SUCCESS,
    GET_DIRECT_MESSAGE,
    GET_DIRECT_MESSAGE_SUCCESS,
    CREATE_DIRECT_MESSAGE,
    CREATE_DIRECT_MESSAGE_SUCCESS,
    CLOSE_CONVERSAION,
    CLOSE_CONVERSAION_SUCCESS,
    DELETE_CONVERSAION,
    DELETE_CONVERSAION_SUCCESS,
    DELETE_CONVERSAION_FAILURE,
    OPEN_ALERT_DELETE_CONVERSATION_SUCCESS,
    CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS,
    OPEN_CONVERSATION,
    OPEN_CONVERSATION_SUCCESS,
} from './types';

// export const openDirectMessageModal = () => ({
//     type: DIRECT_MESSAGE_OPEN_MODAL
// });

export const closeDirectMessageModal = () => ({
    type: DIRECT_MESSAGE_CLOSE_MODAL
});

export const openSearchSeeker = () => ({
    type: DIRECT_MESSAGE_OPEN_SEARCH
});

export const closeSearchSeeker = () => ({
    type: DIRECT_MESSAGE_CLOSE_SEARCH
});

export const createConversation = (params) => ({
    type: DIRECT_MESSAGE_CREATE_CONVERSATION,
    payload: { params }
});

export const createConversationSuccess = (params) => ({
    type: DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS,
    payload: { params }
});

export const createConversationFailure = (messages) => ({
    type: DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE,
    payload: {messages}

});


export const createDirectMessage = ( params ) => ({
    type: CREATE_DIRECT_MESSAGE,
    payload: {params}
});


export const createDirectMessageSuccess = (response) => ({
    type: CREATE_DIRECT_MESSAGE_SUCCESS,
    payload: {response}
});


export const getConversationList = (isNotSameConversation = false, initial = false, page = 1) => ({
    type: GET_CONVERSATION,
    payload: {isNotSameConversation, initial, page}

});

export const getConversationSuccess = (params, page, isNotSameConversation = false) => ({
    type: GET_CONVERSATION_SUCCESS,
    payload: {params, page, isNotSameConversation}
});


export const getDirectMessage = ( conversationId, clickMessage = false) => ({
    type: GET_DIRECT_MESSAGE,
    payload: { conversationId, clickMessage }
});

export const getDirectMessageSuccess = (response) => ({
    type: GET_DIRECT_MESSAGE_SUCCESS,
    payload: {response}
});

export const closeConversation  = (conversationId) => ({
    type: CLOSE_CONVERSAION,
    payload: {conversationId}
});
export const openConversationMessage = (conversationId) => ({
    type: OPEN_CONVERSATION,
    payload: {conversationId}
})

export const closeConversationSuccess = () => ({
    type: CLOSE_CONVERSAION_SUCCESS,
});

export const deleteConversation  = (conversationId) => ({
    type: DELETE_CONVERSAION,
    payload: {conversationId}
});

export const deleteConversationSuccess = () => ({
    type: DELETE_CONVERSAION_SUCCESS,
});

export const deleteConversationFailure = (error) => ({
    type: DELETE_CONVERSAION_FAILURE,
    error
});

export const openDeleteConversationSuccess = () => ({
    type: OPEN_ALERT_DELETE_CONVERSATION_SUCCESS
});

export const closeDeleteConversationSuccess = () => ({
    type: CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS
});
import API from 'Api';

/*
 * Get the all users
 * @param  {number} page for pagination
 * @return async
 */
export const getUsersRequest = async (page) => await API.get('/employers/getAllEmployer', page)
        .then(response => response)
        .catch(error => error);

/**
 * Create new user
 * @param
 * {text} email
 * {text} phone
 * {int}  level 1 admin 2 standard
 * {text} password
 * {text} password_confirmation
 * @return async
 */
export const registerUsersRequest = async usersParam => await API
    .post('/register/employer', usersParam)
    .then(response => response)
    .catch(error => error);

/**
 * update user
 * @param
 * {text} email
 * {text} phone
 * {int}  level 1 admin 2 standard
 * {text} password
 * {text} password_confirmation
 * @return async
 */
export const updateUsersRequest = async usersParam => await API
    .put('/employers/staff/updateCredentials', usersParam)
    .then(response => response)
    .catch(err => err);

/**
 * check user exist
 * @param
 * {text} email
 * @return async
 */
export const userExistRequest = async email => await API
    .post('/employers/exists', email, false)
    .then(response => response)
    .catch(error => error);

/**
 * send verify email
 * @param
 * {int} userId
 * @return async
 */
export const sendVerifyEmailRequest = async usersId => await API
    .post('/employers/verify/emailWithUser', usersId)
    .then(response => response)
    .catch(error => error);

/**
 * delete User
 * @param
 * {int} userId
 * @return async
 */
export const deleteUserRequest = async id => await API
    .remove(`/employers/removeEmployer/${id}`)
    .then(response => response)
    .catch(error => error);

/**
 * delete all User
 * @param
 * {array int} array id delete user
 * @return async
 */
export const deleteAllUser = async deletedUsers => await API
    .post('/employers/deleteSpecificEmployers', deletedUsers)
    .then(response => response)
    .catch(error => error);

import {GET_DOCUMENT_OF_EMPLOYEE_SUCCESS,
        CHANGE_DOCUMENT_LOADING,
        GET_SKILL_BY_SKILL_TYPE_SUCCESS
    } from "Actions/types";


const INIT_STATE = {
    listDocument: [],
    loadImage: false,
    skillByType: []
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DOCUMENT_OF_EMPLOYEE_SUCCESS:
            return {
                ...state,
                loadImage: true,
                listDocument: action.payload.data
            }
        case CHANGE_DOCUMENT_LOADING:
            return {
                ...state,
                loadImage: !state.loadImage
            }
        case GET_SKILL_BY_SKILL_TYPE_SUCCESS:
            
            return {
                ...state,
                skillByType: action.payload.data.data
            }
        default:
            return {...state};
    }
}
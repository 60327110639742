/**
 * Excel Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    EXCEL_DOWNLOAD_REPORTS,
    EXCEL_DOWNLOAD_FULFILMENT_REPORTS,
    EXCEL_DOWNLOAD_BILLING_REPORTS,
    EXCEL_DOWNLOAD_AWR_REPORTS,
} from 'Actions/types';

import {
    showErrorResponse,
    hideRootSpinner
} from 'Actions';

import DownloadHelper from 'Helpers/Download';

import {
    getExcelTimecardReports,
    getExcelFulfilmentReports,
    getExcelBillingReports,
    getExcelAWRReports,
} from 'Services/employers/EmployerExcelReports';

function* handleDownloadExcelReports({ payload }) {
    try {
        const { startDate, endDate, locationId } = payload;
        const response               = +locationId ? yield call(getExcelTimecardReports, startDate, endDate, locationId)
                                                   : yield call(getExcelTimecardReports, startDate, endDate);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelFulfilmentReports({ payload }) {
    try {
        const { startDate, endDate} = payload;
        const response               = yield call(getExcelFulfilmentReports, startDate, endDate);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelBillingReports({ payload }) {
    try {
        const { startDate, endDate} = payload;
        const response               = yield call(getExcelBillingReports, startDate, endDate);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelAWRReports({ payload }) {
    try {
        const response               = yield call(getExcelAWRReports);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch download excel timesheet pending
 */
export function* watchDownloadExcelReports() {
    yield takeEvery(EXCEL_DOWNLOAD_REPORTS, handleDownloadExcelReports);
}

/**
 * Watch download excel fulfilment report 
 */
export function* watchDownloadExcelFulfilmentReports() {
    yield takeEvery(EXCEL_DOWNLOAD_FULFILMENT_REPORTS, handleDownloadExcelFulfilmentReports);
}

/**
 * Watch download excel billing report 
 */
export function* watchDownloadExcelBillingReports() {
    yield takeEvery(EXCEL_DOWNLOAD_BILLING_REPORTS, handleDownloadExcelBillingReports);
}

/**
 * Watch download excel awr report 
 */
 export function* watchDownloadExcelAWRReports() {
    yield takeEvery(EXCEL_DOWNLOAD_AWR_REPORTS, handleDownloadExcelAWRReports);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchDownloadExcelReports),
        fork(watchDownloadExcelFulfilmentReports),
        fork(watchDownloadExcelBillingReports),
        fork(watchDownloadExcelAWRReports)
    ]);
}

/**
 * Message Reducer
 */
import {
    OPEN_MESSAGE_MODAL,
    OPEN_DIRECT_MESSAGE_MODAL,
    CLOSE_MESSAGE_MODAL,
    CHANGE_MESSAGE_TAB,
    GET_MESSAGES,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILURE,
    GET_MESSAGES_BY_POSITION,
    GET_MESSAGES_BY_POSITION_SUCCESS,
    GET_MESSAGES_BY_POSITION_FAILURE,
    SEND_MESSAGE_TO_POSITION_SUCCESS,
    SEND_MESSAGE_TO_POSITION_FAILURE,
    GET_DIRECT_MESSAGE,
} from 'Actions/types';

// initial state
const INIT_STATE = {
    isOpenModal       : false,
    jobForMessage     : null,
    onLoadingPositions: false,
    onLoadingMessages : false,
    listPositions     : [],
    messagesOfPosition: [],
    messagePositionId : null,
    conversationId    : null,
    isLast: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OPEN_MESSAGE_MODAL:
            const {jobObject} = action.payload;
            return {
                ...state,
                isOpenModal      : true,
                jobForMessage    : jobObject || null,
                messagePositionId: jobObject ? Number(jobObject.position_id) : null
            };

        case CLOSE_MESSAGE_MODAL:
            return {
                ...state,
                isOpenModal       : false,
                jobForMessage     : null,
                messagePositionId : null,
                messagesOfPosition: [],
                listPositions     : [],
                conversationId    : null,
            };
        case OPEN_DIRECT_MESSAGE_MODAL:
            const conversationId = action.payload.conversationId
            return {
                ...state,
                isOpenModal       : true,
                jobForMessage     : null,
                conversationId    : conversationId,    
            }
        case CHANGE_MESSAGE_TAB: 
            return {...state, messagePositionId: null }
        case GET_MESSAGES:
            return {...state, onLoadingPositions: true};

        case GET_MESSAGES_SUCCESS:
            const data = action.payload.response
            const page = action.payload.page
            const isLast = data.length < 15
            let message = Object.values(state.listPositions)
            if(page <= 1) {
                message = data
            } else {
                message = message.concat(data)
            }
            return {...state, onLoadingPositions: false, listPositions: message, isLast: isLast};

        case GET_MESSAGES_FAILURE:
            return {...state, onLoadingPositions: false};

        case GET_MESSAGES_BY_POSITION:
            return {...state, onLoadingMessages: true, conversationId: null,};

        case GET_MESSAGES_BY_POSITION_SUCCESS:
            return {
                ...state,
                conversationId    : null,
                onLoadingMessages : false,
                messagesOfPosition: action.payload.messages,
                messagePositionId : Number(action.payload.positionId)
            };

        case GET_MESSAGES_BY_POSITION_FAILURE:
            return {...state, onLoadingMessages: false};

        case SEND_MESSAGE_TO_POSITION_SUCCESS:
            return {...state, jobForMessage: null};

        case SEND_MESSAGE_TO_POSITION_FAILURE:
            return {...state};
        case GET_DIRECT_MESSAGE:
            return {
                ...state,
                conversationId   : null,
                messagePositionId: action.payload.conversationId,
            }
        default: return { ...state};
    }
};

import React, { Component } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FormattedMessage } from "react-intl";

class ValidatorMessage extends Component {

    render() {
        const { message, directMessage } = this.props;
        return (
            message ?
            <FormHelperText className="field-error" id="pwd-error-text">
                <FormattedMessage id={message} defaultMessage="" />
            </FormHelperText>
            :
            <FormHelperText className="field-error" id="pwd-error-text">
               <span> {directMessage} </span>
            </FormHelperText>
        );
    }
}

export default ValidatorMessage;

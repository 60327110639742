import BaseModel from './BaseModel';
import { isEmail } from 'Helpers/validators';

export default class InternalEmployee extends BaseModel {

    static createFromApiResponseData(data) {
        const model = new InternalEmployee();
        model.internalEmployeeId    = data.id;
        model.employeeId            = data.employee_id;
        model.employeeName          = data.fullname;
        model.firstName             = data.employee_fname;
        model.secondName            = data.employee_sname;
        model.employeeSkills        = data.employee_skills;
        model.profileThumbnail      = data.profile_thumbnail;
        model.phone                 = data.phone;
        model.email                 = data.email;
        model.location              = data.location;
        return model;
    }

    static validateFields(params) {

        const { firstName, secondName, email, phone } = params;
        const errors = {};

        if (firstName.trim() === '') {
            errors.firstName = 'internalEmployee.firstName.empty';
        }

        if (secondName.trim() === '') {
            errors.secondName = 'internalEmployee.secondName.empty';
        }

        if (email.trim() === '') {
            errors.email = 'user.email.empty';
        }

        if (!errors.email) {
            const emailIsValid = !!(isEmail(email));
            if (!emailIsValid) {
                errors.email = 'loginEmailValid';
            }
        }

        if (phone.trim() === '') {
            errors.phone = 'internalEmployee.phone.empty';
        }

        if (!errors.phone && phone.match(/[a-zA-Z]/g)) {
            errors.phone = 'Invalid Phone Number';
        }

        if (!errors.phone) {
            const phoneIsValid = phone.match(/\d/g) && phone.length <= 50 && phone.length >= 10;
            if (!phoneIsValid) {
                errors.phone = 'internalEmployee.phone.valid';
            }
        }

        return errors;
    }
}

import React, { Component } from 'react';
import IntlMessages from "Util/IntlMessages";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core";
//import { DateTimePicker } from "@material-ui/pickers";
import {DatePicker } from "@material-ui/pickers";
import AppConfig from 'Constants/AppConfig';
import DatepickerCreateJob from "Components/ThemeOptions/CustomMaterial/DatepickerCreateJob";
import { Label, Input } from "reactstrap";
import Popover from "@material-ui/core/es/Popover/Popover";


class PostedInternalPopup extends Component {

   
    /**
     * Check and hide past shifts (line 34), keep scheduleIndex for handle changes
     */
    render() {
        return(
            <Popover 
            open={this.props.open} 
            onClose={this.props.onClose}
            anchorEl={this.props.anchorPostedInternalModal}
            
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{
                'border-radius': "20px"
              }}
              >
                   <div className="posted-internal-popup" style={{}}>
                        <div className="gig-message">GIG posted jobs are for all GIG <br/>
                        Seekers verified on the platform.</div>
                        <div className="internal-message">Internal jobs are for your internal<br/>
                        employees only, you can let GIG<br/>
                        seekers view this job at any time.</div>
                        

                </div>
                  </Popover>
        )
    }
}

export default PostedInternalPopup;

/**
 * LoginDetail Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import { GET_LOGIN_DETAIL_SETTING, UPDATE_LOGIN_DETAIL_SETTING } from 'Actions/types';

import {
    getLoginDetailSuccess,
    updateLoginDetailSuccess,
    updateLoginDetailFail,
    updateLoginDetailSendMailSuccess,
    showErrorResponse
} from 'Actions';

import { getLoginDetailInfoRequest, updateLoginDetailInfoRequest } from 'Services/employers/LoginDetailService';
import StoredData from "Constants/StoredData";
import { sendVerifyEmailRequest } from "Services/employers/UserService";

function* handleGetLoginDetail() {
    let employerLevelId = 0;

    try {
        if (StoredData.isSet(StoredData.KEYS.EMPLOYER_LEVEL_ID)) {
            employerLevelId = StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL_ID);
        }

        const response = yield call(getLoginDetailInfoRequest, {employerLevelId});
        if (isSuccess(response)) {
            yield put(getLoginDetailSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleUpdateLoginDetail({ data }) {
    
    if (StoredData.isSet(StoredData.KEYS.EMPLOYER_LEVEL_ID)) {
        data.employerLevelId = StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL_ID);
    }

    const response = yield call(updateLoginDetailInfoRequest, data);
    try {
        if (isSuccess(response)) {
            if (data.changeEmail) {
                const responseSendMail = yield call(sendVerifyEmailRequest, {
                    user_id: data.id,
                    email: data.email
                });
                if (isSuccess(responseSendMail)) {
                    yield put(updateLoginDetailSendMailSuccess(responseSendMail));
                } else {
                    yield put(updateLoginDetailFail(responseSendMail));
                }
            } else {
                yield put(updateLoginDetailSuccess(response));
            }
        }
        else {
            yield put(updateLoginDetailFail(response));
        }
    } catch (error) {
        yield put(updateLoginDetailFail(response));
    }
}


export function* watchGetLoginDetail() {
    yield takeEvery(GET_LOGIN_DETAIL_SETTING, handleGetLoginDetail);
}

export function* watchUpdateLoginDetail() {
    yield takeEvery(UPDATE_LOGIN_DETAIL_SETTING, handleUpdateLoginDetail);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetLoginDetail),
        fork(watchUpdateLoginDetail)
    ]);
}

import React from 'react';
import LogoLogin from "Assets/img/login-logo.png";
import BACKGROUND from "Assets/img/bg-login.jpg"
// Camera
import QrReader from 'react-qr-reader'
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from 'Components/Model/Spinner';
import {
    scanQrCode,
    checkInWithoutAuth,
    checkOutWithoutAuth
} from 'Services/QrCodeService';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import MyGig from 'Constants/MyGig';
import { getTheDate } from 'Helpers/getDate';

class ScanScreen extends React.Component {
    state = {
        lastScan: new Date().getTime(),
        showAlert : false,
        message : ''
    }

    oldCode = ''
    lastScan = new Date().getTime()

    handleScan = async (data) => {
        if (!data || (data === this.oldCode && (new Date().getTime() - this.lastScan < 5000))) {
            return
        }

        this.oldCode = data;
        this.lastScan = new Date().getTime();
        let message = '';

        try{
            const result = JSON.parse(data);
             const dataScanApi = await scanQrCode(result);
            if(dataScanApi.action === MyGig.checkType.checkIn) {
                 const responseCheckIn = await checkInWithoutAuth(dataScanApi.assigned_schedule_id);
                 
                message = responseCheckIn.error ? "Failed" : "Check in Successful"

            }else if(dataScanApi.action === MyGig.checkType.checkOut) {
                const params = {
                    assigned_schedule_id: dataScanApi.assigned_schedule_id,
                    start_time:getTheDate(dataScanApi.schedule.start, 'YYYY-MM-DD HH:mm:ss'),
                    end_time:getTheDate(dataScanApi.schedule.end, 'YYYY-MM-DD HH:mm:ss'),
                    start_remote_id:0,
                    end_remote_id:0,
                    thumbs_up:1,
                    break_time:0
                }
                const responseCheckOut = await checkOutWithoutAuth(dataScanApi.assigned_schedule_id,params);
                message = responseCheckOut.error ? "Failed" : "Check out Successful"
            }
        }catch(err) {
            console.log(err)
        }
        this.setState({
            showAlert:true,
            message,
        })
    }

    handleError = err => {
    console.error(err)
    }

    onConfirm = () =>{
        this.setState({
            showAlert:false,
            message:'',
        })
    }
    render() {
        const { message } = this.state;
        return(
            <div className="w-100 h-100 d-flex justify-content-center">
                
                <img
                    style={{position:'absolute', opacity:0.7}}
                    className="w-100 h-100" 
                    src={BACKGROUND} 
                    alt="background"/>
                <div style={{position:'absolute', left:"15%", top: "10%" }}>
                    <Button component={Link} to ="/" className="text-white">
                        Back
                    </Button>
                </div>
                <div className="mt-3 w-80 d-flex align-items-center justify-content-center">
                    
                    <div className="mb-3">
                        <img
                            src={ LogoLogin }
                            alt="logo"
                            className="img-fluid"
                            style={{
                                zIndex:2, 
                                position:'relative',
                                border:"5px solid white",
                                padding:10,
                                marginRight:20
                            }}
                        />
                    </div>
                     <QrReader
                        id="camera"
                        delay={500}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        facingMode="environment"
                        style={{ width: '100%', maxWidth:'600px', border:"5px solid white" }}
                    />                    
                </div>
                {
                    this.state.loading
                    &&
                    <div className="d-flex justify-content-center align-items-center w-100 h-100"
                    style={{position:'absolute', backgroundColor: 'rgba(206, 205, 196,0.5)', zIndex:100}}>
                        <Spinner
                            spinnerWidth={70}
                        />
                </div>
                }
                
                <SweetAlert
                    success = {message.length}
                    error = {!message.length}
                    show={this.state.showAlert}
                    title={message.length >0 ? message : 'Failed'}
                    onConfirm={this.onConfirm}
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    />
            </div>
        )
    }
}

export default ScanScreen;
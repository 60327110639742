/**
 * Timesheet Approved Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getIsoWeekRangeFromDate } from 'Helpers/helpers';

import { GET_REMOTE_REQUEST_APPROVED_LIST } from 'Actions/types';

import {
    getRemoteRequestsSuccess,
    getRemoteRequestsFailure
} from 'Actions';

import {
    approvedRemoteRequests,
    groupApprovedRemoteRequestsByDate
} from "Services/employers/RemoteRequestService";

import moment from 'moment';

/**
 * Get approved remote requests from server
 */
function* handleGetApprovedRemoteRequests({ payload }) {
    try {
        if (payload === null) {
            const promise = new Promise(((resolve, reject) => {
                groupApprovedRemoteRequestsByDate().then((approvedGroupedResponse) => {
                    let dateRange;
                    let dateWithApprovedRequest = [];

                    if (approvedGroupedResponse.data && approvedGroupedResponse.data.data.length > 0) {
                        dateWithApprovedRequest = approvedGroupedResponse.data.data;
                    }
                    dateRange = getIsoWeekRangeFromDate(moment(), 'YYYY-MM-DD');
                    resolve({ dateRange, dateWithApprovedRequest });
                });
            }));

            const resolvePromise = yield Promise.all([promise]);
            const approvedLists = yield call(approvedRemoteRequests, resolvePromise[0].dateRange.start, resolvePromise[0].dateRange.end);

            yield put(getRemoteRequestsSuccess({
                isChosenDate: false,
                requests: approvedLists.data.data,
                dataDate: resolvePromise[0].dateWithApprovedRequest
            }));
        } else {
            const { startDate, endDate, locationId } = payload;
            const approvedLists = yield call(approvedRemoteRequests, startDate, endDate, locationId);

            yield put(getRemoteRequestsSuccess({
                isChosenDate: true,
                requests: approvedLists.data.data,
                dataDate: null
            }));
        }
    } catch (error) {
        yield put(getRemoteRequestsFailure(error));
    }
}

/**
 * Taking approved remote request
 */
export function* watchApprovedRemoteRequests() {
    yield takeEvery(GET_REMOTE_REQUEST_APPROVED_LIST, handleGetApprovedRemoteRequests);
}

/**
 * Remote requests Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchApprovedRemoteRequests),
    ]);
}

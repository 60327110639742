/**
 * Job Detail Components
 */
import React, {Component}  from 'react';
import IntlMessages        from 'Util/IntlMessages';
import {getImageOfUniform} from 'Helpers/helpers';

class JobDetail extends Component {
    render() {
        const {
            description,
            specialRequirements,
            uniform,
            uniformOther,
            uniformImage
        } = this.props;

        const listUniformImages = uniformImage
            ? uniformImage.split(',')
                .map(image => image.trim())
                .filter(image => Boolean(image) === true)
            : [];

        return (
             <table className="job-detail-table">
                <tbody>
                    <tr>
                        <td colSpan="3">
                            { description && (
                                <div className="job-detail-col">
                                    <h4><IntlMessages id="jobDetails.jobDescription" /></h4>
                                    <div className="job-detail-description">{description}</div>
                                </div>
                            )}
                        </td>
                        <td colSpan="3">
                            <div className="job-detail-col">
                                <h4><IntlMessages id="jobDetails.specialRequirements" /></h4>

                                { specialRequirements ? (
                                    <div className="job-detail-description">{specialRequirements}</div>
                                ) : (
                                    <div className="empty-description">
                                        <IntlMessages id="upcomingJob.empty.specialRequirements" />
                                    </div>
                                )}
                            </div>
                        </td>
                        <td colSpan="3">
                            <div className="job-detail-col">
                                <h4><IntlMessages id="jobDetails.uniforms" /></h4>

                                { (uniform || uniformOther) ? (
                                    <div className="job-detail-description">
                                        {uniformOther && (
                                            <div className="padding-bottom-smaller">{uniformOther}</div>
                                        )}

                                        { uniform && (
                                            <div className="padding-bottom-smaller">{uniform}</div>
                                        )}

                                        {!!listUniformImages.length && listUniformImages.map((image, index) => (
                                            <div className="uniform-image" key={`uniform-image-${index}`}>
                                                {getImageOfUniform(image)}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="empty-description">
                                        <IntlMessages id="upcomingJob.empty.uniforms" />
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}
export default JobDetail;

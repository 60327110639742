import React, { Component } from "react";
import IntlMessages from "Util/IntlMessages";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core";
//import { DateTimePicker } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import AppConfig from "Constants/AppConfig";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import DatepickerCreateJob from "Components/ThemeOptions/CustomMaterial/DatepickerCreateJob";
import { Label, Input } from "reactstrap";

moment.locale(AppConfig.locale.locale, {
  week: {
    dow: 1,
    doy: 1,
  },
});

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedules: [
        {
          start: {
            hour: 0,
            minute: 0,
          },
          end: {
            hour: 0,
            minute: 0,
          },
        },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.schedules &&
      nextProps.schedules.length &&
      nextProps.schedules !== undefined
    ) {
      let newSchedule = nextProps.schedules.map((item, index) => ({
        start: {
          hour: item.start.hour(),
          minute: item.start.minutes(),
        },
        end: {
          hour: item.end.hour(),
          minute: item.end.minutes(),
        },
      }));
      this.setState({
        schedules: newSchedule,
      });
    }
  }

  removeSchedule(scheduleIndex) {
    this.props.removeSchedule(scheduleIndex);
  }

  handleDateChange(dateTime, scheduleIndex, objectKey) {
    this.props.handleDateChange(dateTime, scheduleIndex, objectKey);
  }

  handleRemoveSchedule(index) {
    this.props.handleRemoveSchedule(index);
  }

  handleTimeChange(dateTime, newTime, scheduleIndex, objectKey, timeKey) {
    let dateTimeChanged;
    const maxHour = 23;
    const maxMinute = 59;

    if (newTime.target.value < 10) {
      newTime.target.value = ("0" + newTime.target.value).slice(-2);
    } else {
      newTime.target.value = parseInt(newTime.target.value, 10);
    }
    if (timeKey === "hour") {
      dateTimeChanged = moment(
        dateTime &&
          dateTime
            .hour(
              newTime.target.value <= maxHour ? newTime.target.value : maxHour
            )
            .format("MM/DD/YYYY HH:mm")
      );
    } else {
      dateTimeChanged = moment(
        dateTime &&
          dateTime
            .minutes(
              newTime.target.value > maxMinute
                ? 0
                : newTime.target.value < 0
                ? 59
                : newTime.target.value
            )
            .format("MM/DD/YYYY HH:mm")
      );
    }

    let newState = {
      ...this.state,
    };

    newState.schedules[scheduleIndex][objectKey][timeKey] =
      newTime.target.value;

    this.setState(newState);
    if (dateTime) {
      this.props.handleDateChange(dateTimeChanged, scheduleIndex, objectKey);
    }
  }

  isPastSchedule = (schedule) =>
    schedule.end.isAfter(moment().startOf("day")) === false;

  generateTimeZone(step) {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format("HH:mm "));
      for (let i = 1; i < 60 / step; i++) {
        items.push(moment({ hour: index, minute: step * i }).format("HH:mm "));
      }
    });
    return items;
  }

  handleChangeBreakTime = (value, scheduleIndex) => {
    if (value >= 0) {
      value = ("0" + value).slice(-2);
    } else if (value >= 99) {
      value = parseInt(0, 10);
    } else {
      value = value.substring(1);
    }
    this.props.handleChangeBreakTime(value, scheduleIndex);
  };

  /**
   * Check and hide past shifts (line 34), keep scheduleIndex for handle changes
   */
  render() {
    const { schedules, disablePast, showPastShift } = this.props;
    const { schedules: schedulesState } = this.state;
    let scheduleNumber = 0;
    let remaninder = 5 - (moment().minute() % 5);
    let now = moment().add(remaninder, "minute");

    return (
      <div className="schedule-wrapper gig-scrollbar">
        {schedules.map((schedule, scheduleIndex) => {
          if (schedulesState[scheduleIndex] === undefined) {
            schedulesState[scheduleIndex] = {
              start: {
                hour: schedule.start.hour(),
                minute: schedule.start.minutes(),
              },
              end: {
                hour: schedule.end.hour(),
                minute: schedule.end.minutes(),
              },
            };
          }

          if (!showPastShift && this.isPastSchedule(schedule)) {
            return null;
          }

          scheduleNumber++;

          return (
            <div
              className={`schedule-item ${scheduleIndex > 0 ? "mt-10" : ""}`}
              key={`schedule-${scheduleIndex}`}
            >
              {/* <div className="bolder orange">
                                    <IntlMessages id="createGig.gigShiftsNo" />
                                    {scheduleNumber}

                                    {scheduleNumber > 1 && (
                                        <i
                                            className="zmdi zmdi-close-circle orange ml-10 remove-schedule"
                                            onClick={() =>
                                                this.removeSchedule(
                                                    scheduleIndex
                                                )
                                            }
                                        />
                                    )}
                                </div> */}
              <div className="row">
                {schedule.editable && (
                  <div className="remove-icon">
                    <i
                      className="zmdi zmdi-close black font-1x pull-right on-hover "
                      onClick={() => this.handleRemoveSchedule(scheduleIndex)}
                    ></i>
                  </div>
                )}
                <div className="col-sm-12 col-md-12 col-xl">
                  {/* <Label for="Shift-Start">
                                            <IntlMessages id="createGig.schedule.start" />
                                        </Label> */}

                  {schedule.editable && (
                    <MuiThemeProvider theme={DatepickerCreateJob}>
                      <div className="rct-picker">
                        <DatePicker
                          format="DD MMM"
                          value={schedule.start}
                          className="gig-picker"
                          disablePast={disablePast}
                          onChange={(dateTime) =>
                            this.handleDateChange(
                              dateTime,
                              scheduleIndex,
                              "start"
                            )
                          }
                          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                          rightArrowIcon={
                            <i className="zmdi zmdi-arrow-forward" />
                          }
                          fullWidth
                        />
                      </div>
                    </MuiThemeProvider>
                  )}

                  {!schedule.editable && (
                    <div className="schedule-disabled">
                      {schedule.start && schedule.start.format("MMMM Do")}
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-xl">
                  {/* <Label for="Shift-End">
                                            <IntlMessages id="createGig.schedule.startTime" />
                                        </Label> */}

                  {schedule.editable && (
                    <MuiThemeProvider theme={DatepickerCreateJob}>
                      <div className="rct-picker time-form form-control">
                        <Input
                          className={"time-input"}
                          value={schedulesState[scheduleIndex].start.hour}
                          type="number"
                          min={0}
                          max={23}
                          onChange={(event) =>
                            this.handleTimeChange(
                              schedule.start,
                              event,
                              scheduleIndex,
                              "start",
                              "hour"
                            )
                          }
                        ></Input>
                        :
                        <Input
                          className={"time-input"}
                          value={schedulesState[scheduleIndex].start.minute}
                          type="number"
                          onChange={(event) =>
                            this.handleTimeChange(
                              schedule.start,
                              event,
                              scheduleIndex,
                              "start",
                              "minute"
                            )
                          }
                        ></Input>
                      </div>
                    </MuiThemeProvider>
                  )}

                  {!schedule.editable && (
                    <div className="schedule-disabled">
                      {schedule.start && schedule.start.format("HH:mm")}
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-xl pr-15 pr-15">
                  {/* <Label for="Shift-End">
                                            <IntlMessages id="createGig.schedule.end" />
                                        </Label> */}

                  {schedule.editable && (
                    <MuiThemeProvider theme={DatepickerCreateJob}>
                      <div className="rct-picker">
                        <DatePicker
                          format="DD MMM"
                          value={schedule.end}
                          className="gig-picker"
                          disablePast={disablePast}
                          onChange={(dateTime) =>
                            this.handleDateChange(
                              dateTime,
                              scheduleIndex,
                              "end"
                            )
                          }
                          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                          rightArrowIcon={
                            <i className="zmdi zmdi-arrow-forward" />
                          }
                          fullWidth
                        />
                      </div>
                    </MuiThemeProvider>
                  )}

                  {!schedule.editable && (
                    <div className="schedule-disabled rct-picker">
                      {schedule.end && schedule.end.format("MMMM Do")}
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-xl pr-15">
                  {/* <Label for="Shift-End">
                                            <IntlMessages id="createGig.schedule.endTime" />
                                        </Label> */}

                  {schedule.editable && (
                    <MuiThemeProvider theme={DatepickerCreateJob}>
                      <div className="rct-picker time-form form-control">
                        <Input
                          className={"time-input"}
                          value={schedulesState[scheduleIndex].end.hour}
                          type="number"
                          min={0}
                          max={23}
                          onChange={(event) =>
                            this.handleTimeChange(
                              schedule.end,
                              event,
                              scheduleIndex,
                              "end",
                              "hour"
                            )
                          }
                        ></Input>
                        :
                        <Input
                          className={"time-input"}
                          value={schedulesState[scheduleIndex].end.minute}
                          type="number"
                          onChange={(event) =>
                            this.handleTimeChange(
                              schedule.end,
                              event,
                              scheduleIndex,
                              "end",
                              "minute"
                            )
                          }
                        ></Input>
                      </div>
                    </MuiThemeProvider>
                  )}

                  {!schedule.editable && (
                    <div className="schedule-disabled">
                      {
                        // schedule.end &&
                        schedule.end.format("HH:mm")
                      }
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-xl pr-15">
                  {schedule.editable && (
                    <div className="rct-picker">
                      <Tooltip
                        title={
                          <FormattedMessage id="createGig.input.breakTime" />
                        }
                        placement="right"
                      >
                        <Input
                          className="pl-1 breaktime-input schedule-break-time"
                          type="select"
                          placeholder="Min"
                          min={0}
                          id="Job-Location"
                          value={
                            schedule.breakTime != undefined
                              ? schedule.breakTime.toString()
                              : 0
                          }
                          type="number"
                          onChange={(e) =>
                            this.handleChangeBreakTime(
                              e.target.value,
                              scheduleIndex
                            )
                          }
                        ></Input>
                      </Tooltip>
                    </div>
                  )}
                  {!schedule.editable && (
                    <div className="schedule-disabled">
                      {
                        // schedule.end &&
                        schedule.breakTime
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Schedule;

import sjcl from 'sjcl';

export default class Cryptog {

    /**
     * Generate a Base64-encoded HMAC-SHA256 hash.
     *
     * @param  {string} key
     * @param  {string} input
     * @return {string}
     * @see    bitwiseshiftleft.github.io/sjcl/doc/symbols/sjcl.misc.hmac.html
     * @see    github.com/bitwiseshiftleft/sjcl/wiki/Symmetric-Crypto#hmac
     * @see    github.com/bitwiseshiftleft/sjcl/blob/1.0.3/core/hmac.js
     * @see    gist.github.com/agrueneberg/6585680
     * @public
     */
    generateHmacSha256Base64(key, input) {

        const keyBits   = sjcl.codec.utf8String.toBits(key);
        const algorithm = sjcl.hash.sha256;
        const generator = new sjcl.misc.hmac(keyBits, algorithm);
        const hmacBits  = generator.mac(input);
        const hmacB64   = sjcl.codec.base64.fromBits(hmacBits);

        return hmacB64;

    };

    /**
     * Generate a random 40-character hex token which is hopefully cryptographically-strong.
     *
     * @return {string}
     * @see    bitwiseshiftleft.github.io/sjcl/doc/symbols/sjcl.prng.html
     * @see    github.com/bitwiseshiftleft/sjcl/wiki/Symmetric-Crypto#generating-random-bytes
     * @see    github.com/bitwiseshiftleft/sjcl/blob/1.0.3/core/random.js#L85
     * @public
    */
    generateRandomToken() {

        sjcl.random.startCollectors();

        const RANDOM_GENERATOR_NOT_READY = 0;

        if (sjcl.random.isReady() === RANDOM_GENERATOR_NOT_READY) {
            // sjcl.random hasn't collected enough entropy to generate
            // cryptographically-strong random numbers
            return this.generateWeakRandomToken();
        }

        // Each "word" is 4 bytes, which converts to 8 hex characters
        const RANDOM_WORD_COUNT = 5;  // * 8 hex chars per word = 40 hex chars

        const randomBits = sjcl.random.randomWords(RANDOM_WORD_COUNT);
        const randomHex  = sjcl.codec.hex.fromBits(randomBits);

        return randomHex;

    };

    /**
     * Generate a cryptographically-weak random 40-character hex token
     * using JavaScript's built-in PRNG.
     *
     * @return {string}
     * @private
     */
    generateWeakRandomToken() {

        const TOKEN_LENGTH = 40;
        const HEX_BASE     = 16;
        let randomHex    = '';

        while (randomHex.length < TOKEN_LENGTH) {
            randomHex += Math.random().toString(HEX_BASE).replace('0.', '');
        }

        return randomHex.substr(0, TOKEN_LENGTH);
    }
}

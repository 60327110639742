import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import PdfViewer from "Components/PdfViewer";

import IntlMessages from "Util/IntlMessages";
import { pdfOpenViewer, pdfCloseViewer, pdfGetConfirmed, pdfGetApplied } from "Actions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const VIEWER_KEY = "confirmed";

class DownloadPdf extends Component {
    constructor(props) {
        super(props);
        this.state = { openPDFViewer: false }
    }

    handlePDFViewer() {
        this.setState({ openPDFViewer: !this.state.openPDFViewer })
    }
    getPDFFile() {
        const {listType, positionId, jobType} = this.props
        if(listType == 'Posted') {
            this.props.pdfGetApplied(positionId, jobType);
        }
        if(listType == 'Filled') {
            this.props.pdfGetConfirmed(positionId, jobType);
        } 
    }
    render() {
        const {
            openViewer,
            pdfContent,
            pdfOpenViewer,
            pdfCloseViewer,
            positionId,
            jobType,
            pdfGetConfirmed,
        } = this.props;

        return (
            <div>
                <div
                    className="w-100 d-flex justify-content-center"
                    style={{ marginTop: "5px", alignItems: "center", marginRight: "10px" }}
                >
                    <IconButton
                        className="toggle-detail"
                        style={{ width: "40px", height: "40px" }}
                        onClick={() => {
                            this.handlePDFViewer();
                            this.getPDFFile();

                        }}
                    >
                        <i className="material-icons" style={{marginLeft: "45px"}}>printer</i>
                    </IconButton>
                    <div style={{ marginLeft: "5px" }}>
                        <IntlMessages id="jobCard.confirmed.print" />
                    </div>
                    {
                        <PdfViewer
                            open={this.state.openPDFViewer}
                            urlStream={pdfContent.confirmed}
                            onClose={() => this.handlePDFViewer()}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ pdfReducer }) => ({ ...pdfReducer });

const mapActionToProps = {
    pdfOpenViewer,
    pdfCloseViewer,
    pdfGetApplied,
    pdfGetConfirmed,
};

export default connect(mapStateToProps, mapActionToProps)(DownloadPdf);

/**
 * Confirm password Reducer
 */
import {
    AMENDING_HOURS_SHOW_FORM,
    AMENDING_HOURS_HIDE_FORM,
    AMENDING_HOURS_ADD_COMMENT,
    AMENDING_HOURS_ADD_COMMENT_SUCCESS,
    AMENDING_HOURS_ADD_COMMENT_FAILURE,
} from "Actions/types";

// initial state
const INIT_STATE = {
    isShowComment  : false,
    onLoading      : false,
    confirmSuccess : false,
    callbackSuccess: null,
    assignedScheduleId: 0,
    assignmentId: 0,
    comment: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case AMENDING_HOURS_SHOW_FORM:
            return {
                ...state,
                isShowComment: true,
                assignedScheduleId: action.payload.assignedScheduleId,
                assignmentId: action.payload.assignmentId,
                comment: action.payload.comment
            };

        case AMENDING_HOURS_HIDE_FORM:
            return {
                ...state,
                isShowComment  : false,
                callbackSuccess: null,
                confirmSuccess : false
            };
        case AMENDING_HOURS_ADD_COMMENT:
            return {
                ...state,
                onLoading: true
            };
        case AMENDING_HOURS_ADD_COMMENT_SUCCESS:
            return {
                ...state,
                isShowComment: false,
                onLoading: false,
            }
        case AMENDING_HOURS_ADD_COMMENT_FAILURE:
            return {
                ...state,
                onLoading: false,
            }
        default:
            return {...state}
    }
};

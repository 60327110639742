import AppConfig from 'Constants/AppConfig';
import axios from 'axios';


import { userExistRequest } from 'Services/employers/UserService';
import { checkAccessCodeRequest } from 'Services/employers/AccessCodeService';

export function isEmail (email) {
  return /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email);
}

export const notEmpty = (input) => input.length > 0;

export const isValidInsuranceNumber = (insurance) => {
    const regEx = /^([A-Za-z]){2}(\d){6}([A-Za-z]){1}$/;
    return regEx.test(insurance);
};


/**
 *  Function validate post code at UK
 * @param {String} postcode
 */
export const isValidPostcode =  async (postcode) => {

  if (!postcode) {
      return false;
  }

  resetHeader();
  const response = await  axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
      params:
      {
          address: `${postcode}-uk`,
          key: AppConfig.GoogleMaps.API_KEY
      },
  });
  const { data } = response;

  if (!data.results[0]) {
      return false;
  }

  let address;
  const addressComponent = data.results[0].address_components;

  if (addressComponent) {
      address = addressComponent.filter(address => address.long_name.replace(/ /g,'').toLowerCase() === postcode.replace(/ /g,'').toLowerCase()  && address.types.indexOf("postal_code") !== -1);
  }
  return address.length > 0 || false;
};

export const checkEmailExist = async (email) => {
    resetHeader();

    const response = await userExistRequest({ email});
    return response;
};

export const checkAccessCode = async (code,checkGigPremium ) => {
    resetHeader();
    const response = await  checkAccessCodeRequest(code, checkGigPremium);
    return response;
};


export function minLength(value, length) {
  return  value.trim().length >= length;
}

export function maxLength(value, length) {
  return  value.trim().length <= length;
}

export function matchNumber(value) {
  return value.match(/\d/g);
}

export function matchCharacter(value) {
  return value.match(/[a-zA-Z]/g);
}

export function matchCompanyNumber(value) {
    return value.match(/^([1-9]\d{6,7}|\d{6,7}|(SC|NI|AC|FC|GE|GN|GS|IC|IP|LP|NA|NF|NL|NO|NP|NR|NZ|OC|R|RC|SA|SF|SI|SL|SO|SP|SR|SZ|ZC|)\d{6,8})$/);
}


export function validateVATNumber (vatNumber) {
  const multipliers = [8, 7, 6, 5, 4, 3, 2];

  const vatDigits = vatNumber.replace(/\D+/g, '');

  if (vatDigits.length !== 9) {
      return 'businessDetail.vat_number.9.digit';
  }
  const checkDigits = parseInt(vatDigits.slice(-2), 10);
  let checkDigitTotal = 0;
  for (let i = 0; i < 7; i++) {
      checkDigitTotal += (vatDigits[i] * multipliers[i]);
  }
  const mod97check = -((checkDigitTotal % 97) - 97);
  const mod9755check = -(((checkDigitTotal + 55) % 97) - 97);

  if (!((checkDigits === mod97check) || (checkDigits === mod9755check))) {
      return 'businessDetail.vat_number.invalid';
  }
  return null;
};



export const isValidPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return false;
    }

    const regex = /^\+?\d{6,16}$/;

    return regex.test(phoneNumber.replace(' ', ''));
};


function resetHeader ()  {
  delete axios.defaults.headers.common.Accept;
  delete axios.defaults.headers.common.Authorization;
}

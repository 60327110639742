import API from 'Api';

/**
 * Decline the specified candidate's job response.
 *
 * @param  {Number} candidateId - job response ID of candidate
 * @return async
 */
export const declineCandidateRequest = (candidateIds, isSelectAll, positionId) =>
    API.put(`/employers/candidate/declines`,{response_ids: candidateIds, isSelectAll: isSelectAll, position_id: positionId})
        .then(response => response)
        .catch(err => err);

import S3 from 'aws-sdk/clients/s3'
import AppConfig from 'Constants/AppConfig';

class S3Helper {
    constructor(props) {
        this.s3 = new S3({
            accessKeyId: process.env.S3_ACESS_KEY,
            secretAccessKey: process.env.S3_SECRET_KEY,
            region: process.env.S3_REGION
        });
    }
    // async handleUploadImage(base64, path) {

    //     const {omni_employee_id} = this.props.item;
    //     const mineType = base64MimeType(base64);
    //     let fileType = [];

    //     if (mineType && mineType.length > 0) {
    //         const type = mineType.split('/');
    //         fileType = type.length > 1 ? type[1] : "";
    //     }

    //     const name = path + "/employee-" + omni_employee_id + "." + fileType;
    //     const buf = new Buffer(base64.replace(/^data:(.+)base64,/, ""), 'base64')
    //     await this.getListObject(path);
    //     await this.handleUploadS3(name, buf, mineType)
    // }

    async handleUploadS3(data) {
        const { key, fileContent } = data
        const params = {
            Bucket: AppConfig.BUCKET,
            Key: key,
            Body: fileContent,
            ACL: 'public-read',
        };

        const result = await this.s3.upload(params).promise();
        return result
    }

    async getListObject(path) {

        // const {omni_employee_id} = this.props.item;

        // const name = "employee-";
        const params = {
            Bucket: AppConfig.BUCKET,
            Prefix: path + "/"
        }
        const result = await this.s3.listObjectsV2(params).promise();
        return result
        // if (result.Contents && result.Contents.length > 0) {
        //     const filterFileName = result.Contents.filter(filename => filename.Key.includes(name))
        //     if (filterFileName.length > 0) {
        //         for (let i = 0; i < filterFileName.length; i++) {
        //             await this.handleDeleteS3File(filterFileName[i].Key, path)
        //         }

        //     }
        // }

    }

    // async handleDeleteS3File(name) {
    //     const params = {Bucket: AppConfig.BUCKET, Key: name};
    //     const result = await this.s3.deleteObject(params, (err, data) => {
    //     });

    //     return result;
    // }
}

export default S3Helper
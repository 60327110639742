/**
 * LoginDetail Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import { GET_QR_CODE_EMPLOYER,
         GET_QR_CODE_EMPLOYER_SUCCESS,
         REFRESH_QR_CODE_EMPLOYER,
         REFRESH_QR_CODE_EMPLOYER_SUCCESS } from 'Actions/types';

import {
    getQrCodeEmployer,
    getQrCodeEmployerSuccess,
    refreshQrCodeEmployer,
    refreshQrCodeEmployerSuccess,
  
} from 'Actions';

import { getQrCodeRequest, refreshQrCodeRequest} from 'Services/employers/QrCodeEmployer';
import StoredData from "Constants/StoredData";
import { sendVerifyEmailRequest } from "Services/employers/UserService";

// function* handleGetLoginDetail() {
//     //let employerLevelId = 0;
//     let employerLevelId = 0;


//     try {
//         if (StoredData.isSet(StoredData.KEYS.EMPLOYER_LEVEL_ID)) {
//             employerLevelId = StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL_ID);
//         }

//         const response = yield call(getLoginDetailInfoRequest, {employerLevelId});
//         if (isSuccess(response)) {
//             yield put(getLoginDetailSuccess(response.data));
//         }
//     } catch (error) {
//         yield put(showErrorResponse(error));
//     }
// }

function* handleQrCode() {
    //let employerLevelId = 0;
    let employerId = 0;


    try {
        if (StoredData.isSet(StoredData.KEYS.EMPLOYER_ID)) {
            employerId = StoredData.get(StoredData.KEYS.EMPLOYER_ID);
        }

        const response = yield call(getQrCodeRequest, {employerId});
        if (isSuccess(response)) {
            yield put(getQrCodeEmployerSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}
function* handleRefreshQrCode() {
    //let employerLevelId = 0;
    let employerId = 0;


    try {
        if (StoredData.isSet(StoredData.KEYS.EMPLOYER_ID)) {
            employerId = StoredData.get(StoredData.KEYS.EMPLOYER_ID);
        }

        const response = yield call(refreshQrCodeRequest, {employerId});
        if (isSuccess(response)) {
            yield put(refreshQrCodeEmployerSuccess(response.data));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

// export function* watchGetLoginDetail() {
//     yield takeEvery(GET_LOGIN_DETAIL_SETTING, handleGetLoginDetail);
// }
export function* watchGetQrCode() {
    yield takeEvery(GET_QR_CODE_EMPLOYER, handleQrCode);
}
export function* watchRefreshQrCode() {
    yield takeEvery(REFRESH_QR_CODE_EMPLOYER, handleRefreshQrCode);
}
 

export default function* rootSaga() {
    yield all([
        fork(watchGetQrCode),
        fork(watchRefreshQrCode)
    ]);
}

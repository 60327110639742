/**
 * Remote Request Reducer
 */

 import {
    GET_REMOTE_REQUEST_PENDING_LIST,
    GET_REMOTE_REQUEST_PENDING_DAILY_LIST,
    GET_REMOTE_REQUEST_APPROVED_LIST,
    ACCEPT_REMOTE_REQUEST,
    ACCEPT_LIST_REMOTE_REQUESTS,
    DECLINE_REMOTE_REQUEST,
    DECLINE_LIST_REMOTE_REQUESTS,

    GET_REMOTE_REQUEST_SUCCESS,
    GET_REMOTE_REQUEST_FAILURE,
    ACCEPT_REMOTE_REQUESTS_SUCCESS,
    ACCEPT_REMOTE_REQUESTS_FAILURE,
    DECLINE_REMOTE_REQUESTS_SUCCESS,
    DECLINE_REMOTE_REQUESTS_FAILURE,
    SEND_MAIL_TIMESHEET_PENDING_SUCCESS,
    SEARCH_SEEKER_TIMESHEETS
} from 'Actions/types';

// initial state
const INIT_STATE = {
    responseRequestsData: {},
    pendingDataDate     : [],
    onLoading           : false,
    errors              : {},
    declineStatus       : false,
    sendMailStatus      : false,
    searchSeeker    : {
        searchText: '',
        listSearch: []
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEND_MAIL_TIMESHEET_PENDING_SUCCESS:
            return {
                ...state,
                sendMailStatus: true,
            }
        case GET_REMOTE_REQUEST_PENDING_LIST:
            return {
                ...state,
                onLoading: true,
                declineStatus: false,
                pendingDataDate: [],
                searchSeeker    : {
                    searchText: '',
                    listSearch: []
                },
            };

        case GET_REMOTE_REQUEST_PENDING_DAILY_LIST:
            return {
                ...state,
                onLoading: true,
                declineStatus: false
            };

        case GET_REMOTE_REQUEST_APPROVED_LIST:
            return {
                ...state,
                onLoading: true,
                //pendingDataDate: []
            };

        case GET_REMOTE_REQUEST_SUCCESS:
            let pendingDataDate = action.payload.dataDate;
            if (pendingDataDate === null) {
                pendingDataDate = state.pendingDataDate;
            }

            return {
                ...state,
                pendingDataDate,
                responseRequestsData: action.payload,
                declineStatus: false,
                onLoading: false
            };

        case GET_REMOTE_REQUEST_FAILURE:
            return {
                ...state,
                errors: action.error,
                declineStatus: false,
                onLoading: false,
            };

        case DECLINE_REMOTE_REQUEST:
            return {
                ...state,
                declineStatus: false
            };

        case DECLINE_LIST_REMOTE_REQUESTS: {
            return {
                ...state,
                declineStatus: false,
                declineList: true
            };
        }

        case ACCEPT_REMOTE_REQUEST:
            return {
                ...state,
                declineStatus: false
            };

        case ACCEPT_LIST_REMOTE_REQUESTS:
            return {
                ...state,
                declineStatus: false
            };

        case DECLINE_REMOTE_REQUESTS_SUCCESS:
            return {
                ...state,
                declineStatus: action.payload.code
            };

        case DECLINE_REMOTE_REQUESTS_FAILURE:
            return {
                ...state,
                errors: action.error,
                declineStatus: false
            };

        case ACCEPT_REMOTE_REQUESTS_SUCCESS:
            return {
                ...state,
                acceptStatus: action.payload
            };

        case ACCEPT_REMOTE_REQUESTS_FAILURE:
            return {
                ...state,
                errors: action.error,
                acceptStatus: false
            };
        case SEARCH_SEEKER_TIMESHEETS:
            let searchText = action.payload.toLowerCase();
            let data = [...state.responseRequestsData.requests]
            let filterData = data.filter((e) => e.userFullName.toLowerCase().includes(searchText))
            
            return {
                ...state,
                searchSeeker: {
                    searchText,
                    listSearch: filterData
                }
            };
    


        default: return { ...state};
    }
};

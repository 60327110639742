/**
 * Omni Employer Reducer
 */

import {
    GET_EMPLOYER_LIST,
    GET_EMPLOYER_LIST_SUCCESS,
    GET_EMPLOYER_LIST_FAILURE,
    LOGIN_AS,
    LOGIN_AS_SUCCESS,
    LOGIN_AS_FAILURE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    employerList: [],
    loginAs: '',
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYER_LIST:
            return { ...state };

        case GET_EMPLOYER_LIST_SUCCESS:
            return { ...state, employerList: action.payload };

        case GET_EMPLOYER_LIST_FAILURE:
            return { ...state };
        
        case LOGIN_AS:
            return { ...state };
        
        case LOGIN_AS_SUCCESS:
            return { ...state, loginAs: action.payload };
        
        case LOGIN_AS_FAILURE:
            return { ...state };

        default: return { ...state};
    }
};

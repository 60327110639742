/**
 * Forgot Password Reducer
 */
import {
  RESET_TEXT_FORGOT,
  CHECK_EMAIL_EXIST_SUCCESS,
  CHECK_EMAIL_EXIST_FAILURE,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_FAILURE
} from "Actions/types";

const INIT_STATE = {
  responseForgotPassword: "emptyText",
  successForgotPassword: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_TEXT_FORGOT: return {
      ...state,
      responseForgotPassword: "emptyText",
      successForgotPassword: null,
    };
    case CHECK_EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        successForgotPassword: false,
        responseForgotPassword: action.responseForgotPassword
      };

    case CHECK_EMAIL_EXIST_FAILURE:
      return {
        ...state,
        successForgotPassword: false,
        responseForgotPassword: action.error
      };
    case SEND_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        successForgotPassword: true,
        responseForgotPassword: action.responseForgotPassword
      };

    case SEND_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        successForgotPassword: false,
        responseForgotPassword: action.error
      };
    default:
      return { ...state };
  }
};

import React from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";

import IntlMessages from "Util/IntlMessages";

import FormHelperText from "@material-ui/core/FormHelperText";


const PayChargeRate = ({
    payRate,
    chargeRate,
    handleChangePayRate,
    minPayRate,
    hasError,
    disabled
}) => {

    const formatText = e => {
        const { value } = e.target
        const NUMBER_DOT_COMMA = /^\d+(\.(\d+)?)?$/
        if (NUMBER_DOT_COMMA.test(value)) {
            return value
        }
            
        else if(value === '') {
            return value
        }
        return null
    }

    return (
        <div className="row">

            <div className="col-md-6">
                <FormGroup>
                    <Label for="Job-Pay-Rate">
                        <IntlMessages id="createGig.payRate" />
                    </Label>
                    <Input
                        type="text"
                        id="Job-Pay-Rate"
                        bsSize="sm"
                        value={payRate}
                        onChange={(event) => {
                            let value = formatText(event)
                            if(value !== null)
                                handleChangePayRate(value)
                        }}
                        disabled={disabled}
                    />

                    {hasError && (
                        <FormHelperText
                            className="field-error"
                            id="pwd-error-text"
                        >
                            <FormattedMessage
                                id="createGig.validate.payRate.minValue"
                                values={{
                                    minimumPayrate: minPayRate.toFixed(2),
                                }}
                            />
                        </FormHelperText>
                    )}
                </FormGroup>
            </div>

            <div className="col-md-6">
                <FormGroup>
                    <Label for="Job-Charge-Rate">
                        <IntlMessages id="createGig.chargeRate" />
                    </Label>
                    <div className="big-font bolder">
                        {chargeRate>=0 && Number(chargeRate).toFixed(2)}
                    </div>
                </FormGroup>
            </div>
        </div>
    );
};

export default PayChargeRate

/**
 * Position Actions
 */

import {
  OPEN_DECLINE_ASSIGNMENT_POPUP,
  UPDATE_NUMBER_OF_SEEKER,
  OPEN_NO_SHOW,
  NO_SHOW,
  NO_SHOW_SUCCESS,
  NO_SHOW_FAIL,
  OPEN_ACCEPT_POPUP,
  OPEN_DECLINE_POPUP,
  OPEN_CHECKIN_OUT_POPUP,
  DECLINE_CANDIDATE,
  DECLINE_CANDIDATE_SUCCESS,
  DECLINE_CANDIDATE_FAIL,
  DECLINE_ASSIGNMENT,
  DECLINE_ASSIGNMENT_SUCCESS,
  DECLINE_ASSIGNMENT_FAIL,
  CHECK_IN_ASSIGNMENT,
  CHECK_IN_ASSIGNMENT_SUCCESS,
  CHECK_IN_ASSIGNMENT_FAIL,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_FAILURE,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_FAILURE,
  CHECK_OUT_ASSIGNMENT,
  CHECK_OUT_ASSIGNMENT_SUCCESS,
  CHECK_OUT_ASSIGNMENT_FAIL,
  CLOSE_ALL_POPUPS,
  CHECK_OVERLAPS,
  IS_OVERLAPS,
  NOT_OVERLAPS,
  ACCEPT_CANDIDATE,
  ACCEPT_CANDIDATE_SUCCESS,
  ACCEPT_CANDIDATE_FAIL,
  SEND_CURRENT_POSITION,
  CREATE_NEW_POSITION,
  CREATE_NEW_POSITION_SUCCESS,
  CREATE_NEW_POSITION_FAILURE,
  EDIT_POSITION,
  EDIT_POSITION_SUCCESS,
  EDIT_POSITION_FAILURE,
  GET_CANDIDATES_BY_POSITION,
  GET_CANDIDATES_BY_POSITION_SUCCESS,
  GET_CANDIDATES_BY_POSITION_FAILURE,
  GET_DECLINED_CANDIDATES_BY_POSITION,
  GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS,
  GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE,
  GET_ASSIGNMENTS_BY_POSITION,
  GET_ASSIGNMENTS_BY_POSITION_SUCCESS,
  GET_ASSIGNMENTS_BY_POSITION_FAILURE,
  SWITCH_JOB_STATUS_SUCCESS,
  SWITCH_JOB_STATUS,
  CHECK_WORKING_OVER_SIX_DAY_SUCCESS,
  CHECK_WORKING_OVER_SIX_DAY_FAILURE,
  CHECK_WORKING_OVER_SIX_DAY,
  WARNING_SEEKER_OVER_SIX_DAY,
  HIDE_WARNING_SEEKER_OVER_SIX_DAY,
  CHECK_SEEKER_NOT_TO_JOB,
  CHECK_SEEKER_NOT_TO_JOB_SUCCESS,
  CHECK_SEEKER_NOT_TO_JOB_FAILURE,
  WARNING_SEEKER_48_HOURS,
  HIDE_WARNING_SEEKER_48_HOURS,
  WARNING_SEEKER_IS_STUDENT,
  HIDE_WARNING_SEEKER_IS_STUDENT,
  WARNING_SEEKER_IN_8_HOURS,
  HIDE_WARNING_SEEKER_IN_8_HOURS,
  CHECK_SEEKER_ACCEPT_TO_JOB,
  CHECK_SEEKER_ACCEPT_TO_JOB_FAILURE,
  CHECK_SEEKER_ACCEPT_TO_JOB_SUCCEESS,
  HIDE_WARNING_ACCEPT_SEEKER_TO_JOB,
  WARNING_ACCEPT_SEEKER_TO_JOB,
  WARNING_SEEKER_IS_OVERLAPS,
  HIDE_WARNING_SEEKER_IS_OVERLAPS
} from "./types";

/**
 * this is action
 * for re-update posted and filled people in job card
 * after accept/decline candidate, decline assignment, add seeker success
 * @param jobPositionId
 * @param typeOfAction
 * @param currentPage
 */

export const updateNumberOfSeeker = ({
  jobPositionId,
  typeOfAction,
  currentPage,
  seekerList = [],
}) => ({
  type: UPDATE_NUMBER_OF_SEEKER,
  payload: {
    jobPositionId,
    typeOfAction,
    currentPage,
    seekerList,
  },
});

/* MARK ASSIGNED AS NO SHOW */

export const openNoShow = () => ({
  type: OPEN_NO_SHOW,
});
export const noShow = (assignedScheduleId, positionId) => ({
  type: NO_SHOW,
  payload: {
    assignedScheduleId,
    positionId,
  },
});

export const noShowSuccess = () => ({
  type: NO_SHOW_SUCCESS,
});

export const noShowFail = () => ({
  type: NO_SHOW_FAIL,
});

export const openAcceptPopUp = () => ({
  type: OPEN_ACCEPT_POPUP,
});

/* DECLINE CANDIDATE */

export const openDeclinePopUp = (
  currentDeclineId,
  employee,
  fromJobCard = false
) => ({
  type: OPEN_DECLINE_POPUP,
  payload: { currentDeclineId, employee, fromJobCard },
});

export const openDeclineAssignmentPopUp = (
  currentDeclineAssignmentId,
  employee = null,
  fromJobCard = false
) => ({
  type: OPEN_DECLINE_ASSIGNMENT_POPUP,
  payload: { currentDeclineAssignmentId, employee, fromJobCard },
});

export const openCheckInOutPopUp = (checkType, hour, minute) => ({
  type: OPEN_CHECKIN_OUT_POPUP,
  payload: { checkType, hour, minute },
});

export const declineCandidate = (
  candidateIds,
  positionId,
  currentPage,
  isSelectAll = false,
  dateRange
) => ({
  type: DECLINE_CANDIDATE,
  payload: {
    candidateIds,
    positionId,
    currentPage,
    isSelectAll,
    dateRange,
  },
});

export const declineCandidateSuccess = (candidateIds, positionId) => ({
  type: DECLINE_CANDIDATE_SUCCESS,
  payload: { candidateIds, positionId },
});

export const declineCandidateFail = () => ({
  type: DECLINE_CANDIDATE_FAIL,
});

/* DECLINE ASSIGNMENT */

export const declineAssignment = (
  assignmentIds,
  positionId,
  currentPage,
  isSelectAll = false,
  dateRange
) => ({
  type: DECLINE_ASSIGNMENT,
  payload: {
    assignmentIds,
    positionId,
    currentPage,
    dateRange,
    isSelectAll,
  },
});

export const declineAssignmentSuccess = (assignmentIds) => ({
  type: DECLINE_ASSIGNMENT_SUCCESS,
  payload: assignmentIds,
});

export const declineAssignmentFail = (errorMessage) => ({
  type: DECLINE_ASSIGNMENT_FAIL,
  payload: errorMessage,
});

/* CHECKIN ASSIGNMENT */

export const checkInAssignment = (
  assignmentIds,
  positionId,
  isSelectAll = false,
  dateTimeRequest
) => ({
  type: CHECK_IN_ASSIGNMENT,
  payload: {
    assignmentIds,
    positionId,
    dateTimeRequest,
    isSelectAll,
  },
});

export const checkInAssignmentSuccess = (assignmentIds) => ({
  type: CHECK_IN_ASSIGNMENT_SUCCESS,
  payload: assignmentIds,
});

export const checkInAssignmentFail = () => ({
  type: CHECK_IN_ASSIGNMENT_FAIL,
});

/* CHECKOUT ASSIGNMENT */

export const checkOutAssignment = (
  assignmentIds,
  positionId,
  isSelectAll = false,
  dateTimeRequest
) => ({
  type: CHECK_OUT_ASSIGNMENT,
  payload: {
    assignmentIds,
    positionId,
    dateTimeRequest,
    isSelectAll,
  },
});

export const checkOutAssignmentSuccess = (assignmentIds) => ({
  type: CHECK_OUT_ASSIGNMENT_SUCCESS,
  payload: assignmentIds,
});

export const checkOutAssignmentFail = () => ({
  type: CHECK_OUT_ASSIGNMENT_FAIL,
});
/*
    Action close all pop up like error, form accept
*/
export const closeAllPopUps = () => ({
  type: CLOSE_ALL_POPUPS,
});

export const checkOverlaps = ({
  positionId,
  employee,
  employeeId,
  checkFromJobCard = false,
}) => ({
  type: CHECK_OVERLAPS,
  payload: { positionId, employee, employeeId, checkFromJobCard },
});

export const isOverlaps = () => ({
  type: IS_OVERLAPS,
});

export const notOverlaps = (employee, checkFromJobCard) => ({
  type: NOT_OVERLAPS,
  payload: { employee, checkFromJobCard },
});

export const acceptCandidate = ({
  positionId,
  candidateIds,
  currentPage,
  isSelectAll = false,
  dateRange,
}) => ({
  type: ACCEPT_CANDIDATE,
  payload: { positionId, candidateIds, currentPage, isSelectAll, dateRange },
});

export const acceptCandidateSuccess = (data) => ({
  type: ACCEPT_CANDIDATE_SUCCESS,
  payload: data,
});

export const acceptCandidateFail = (response) => ({
  type: ACCEPT_CANDIDATE_FAIL,
  payload: response,
});

/*
    Action handle current job title clicked
*/

export const sendCurrentPosition = (positionId, isOpen = true) => ({
  type: SEND_CURRENT_POSITION,
  payload: {
    positionId,
    isOpen,
  },
});

/**
 * Action Create position
 */
export const createNewPosition = (params) => ({
  type: CREATE_NEW_POSITION,
  payload: params,
});

/**
 * Action Create position Success
 */
export const createNewPositionSuccess = (response) => ({
  type: CREATE_NEW_POSITION_SUCCESS,
  payload: response.data,
});

/**
 * Action Create position Failure
 */
export const createNewPositionFailure = (error) => ({
  type: CREATE_NEW_POSITION_FAILURE,
  payload: error,
});

export const editPosition = (params) => ({
  type: EDIT_POSITION,
  payload: params,
});

export const editPositionSuccess = (response) => ({
  type: EDIT_POSITION_SUCCESS,
  payload: response.data,
});

export const editPositionFailure = (error) => ({
  type: EDIT_POSITION_FAILURE,
  payload: error,
});

/**
 * Get Candidates By Position
 */
export const getCandidatesByPosition = (
  positionId,
  page = 1,
  jobChanged = false,
  keySearch = ""
) => ({
  type: GET_CANDIDATES_BY_POSITION,
  payload: { positionId, page, jobChanged, keySearch },
});

export const getCandidatesByPositionSuccess = (response) => ({
  type: GET_CANDIDATES_BY_POSITION_SUCCESS,
  payload: response,
});

export const getCandidatesByPositionFailure = (error) => ({
  type: GET_CANDIDATES_BY_POSITION_FAILURE,
  payload: error,
});

/**
 * Get Declined candidates By Position
 */
export const getDeclinedCandidatesByPosition = (
  positionId,
  page = 1,
  keySearch = ""
) => ({
  type: GET_DECLINED_CANDIDATES_BY_POSITION,
  payload: { positionId, page, keySearch },
});

export const getDeclinedCandidatesByPositionSuccess = (response) => ({
  type: GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS,
  payload: response,
});

export const getDeclinedCandidatesByPositionFailure = (error) => ({
  type: GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE,
  payload: error,
});

/**
 * Get Assignments By Position
 */
export const getAssignmentsByPosition = (
  positionId,
  page = 1,
  searchKey = ""
) => ({
  type: GET_ASSIGNMENTS_BY_POSITION,
  payload: { positionId, page, searchKey },
});

export const getAssignmentsByPositionSuccess = (response) => ({
  type: GET_ASSIGNMENTS_BY_POSITION_SUCCESS,
  payload: response,
});

export const getAssignmentsByPositionFailure = (error) => ({
  type: GET_ASSIGNMENTS_BY_POSITION_FAILURE,
  payload: error,
});

export const checkAreAllAssignmentsCheckedIn = (positionId) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN,
  payload: { positionId },
});

export const checkAreAllAssignmentsCheckedInSuccess = (status) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS,
  payload: { status },
});

export const checkAreAllAssignmentsCheckedInFailure = (error) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_FAILURE,
  payload: { error },
});

export const checkAreAllAssignmentsCheckedOut = (positionId) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT,
  payload: { positionId },
});

export const checkAreAllAssignmentsCheckedOutSuccess = (status) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS,
  payload: { status },
});

export const checkAreAllAssignmentsCheckedOutFailure = (error) => ({
  type: CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_FAILURE,
  payload: { error },
});

export const switchJobStatus = (status) => ({
  type: SWITCH_JOB_STATUS,
  payload: status,
});

export const switchJobStatusSuccess = (params) => ({
  type: SWITCH_JOB_STATUS_SUCCESS,
  payload: params,
});
export const checkWorkingSixDay = (
  positionId,
  seekerId,
  isAll = false,
  callBackSuccess
) => ({
  type: CHECK_WORKING_OVER_SIX_DAY,
  payload: { positionId, isAll, seekerId, callBackSuccess },
});

export const checkWorkingSixDaySuccess = (payload) => ({
  type: CHECK_WORKING_OVER_SIX_DAY_SUCCESS,
  payload,
});

export const checkWorkingSixDayFailure = (error) => ({
  type: CHECK_WORKING_OVER_SIX_DAY_FAILURE,
  error,
});

export const warningSeekerOverSixDay = (payload) => ({
  type: WARNING_SEEKER_OVER_SIX_DAY,
  payload,
});

export const hideWarningWorkingSixDay = () => ({
  type: HIDE_WARNING_SEEKER_OVER_SIX_DAY,
});

export const warningSeeker48h = (payload) => ({
  type: WARNING_SEEKER_48_HOURS,
  payload,
});

export const hideWarningSeeker48h = () => ({
  type: HIDE_WARNING_SEEKER_48_HOURS,
});

export const warningSeekerIsStudent = (payload) => ({
  type: WARNING_SEEKER_IS_STUDENT,
  payload,
});

export const hideWarningSeekerIsStudent = () => ({
  type: HIDE_WARNING_SEEKER_IS_STUDENT,
});

export const warningSeekerIn8hours = (payload) => ({
  type: WARNING_SEEKER_IN_8_HOURS,
  payload,
});

export const hideWarningSeekerIn8hours = () => ({
  type: HIDE_WARNING_SEEKER_IN_8_HOURS,
});

export const warningSeekerOverLap = (payload) => ({
  type: WARNING_SEEKER_IS_OVERLAPS,
  payload,
});

export const hideWarningSeekerOverlap = () => ({
  type: HIDE_WARNING_SEEKER_IS_OVERLAPS,
});

export const checkSeekerNotToJob = (
  positionId,
  employeeId,
  callBackSuccess
) => ({
  type: CHECK_SEEKER_NOT_TO_JOB,
  payload: { positionId, employeeId, callBackSuccess },
});

export const checkSeekerNotToJobSuccess = (payload) => ({
  type: CHECK_SEEKER_NOT_TO_JOB_SUCCESS,
  payload,
});

export const checkSeekerNotToJobFailure = (error) => ({
  type: CHECK_SEEKER_NOT_TO_JOB_FAILURE,
  error,
});

export const checkSeekerAcceptToJob = (
  positionId,
  currentPage,
  seekerList
) => ({
  type: CHECK_SEEKER_ACCEPT_TO_JOB,
  payload: {
    positionId,
    currentPage,
    seekerList,
  },
});

export const checkSeekerAcceptToJobSuccess = (successList, errorList) => ({
  type: CHECK_SEEKER_ACCEPT_TO_JOB_SUCCEESS,
  payload: { successList, errorList },
});

export const checkSeekerAcceptToJobFailure = (error) => ({
  type: CHECK_SEEKER_ACCEPT_TO_JOB_FAILURE,
  error,
});

export const showWarningAcceptSeekerToJob = (successList, errorList) => ({
  type: WARNING_ACCEPT_SEEKER_TO_JOB,
  payload: { successList, errorList },
});

export const hideWarningSeekerToJob = () => ({
  type: HIDE_WARNING_ACCEPT_SEEKER_TO_JOB,
});

/**
 * Helpers Functions
 */
import React from "react";
import moment from "moment-timezone";
import AppConfig from "Constants/AppConfig";
import MyGig from "Constants/MyGig";

export const isUnacceptTermEmployer = (responseObject) => {
    const { employer, employer_staff: employerStaff } = responseObject.data;
    const employerRoleId = employerStaff.role_id;
    const isVerifiedReadTermsConditions = Number(
        employer.is_verified_read_terms
    );
    const isVerifiedStoreAndUseDataConditions = Number(
        employer.is_verified_store_and_use
    );

    if (employerRoleId === MyGig.roles.OMNI_STAFF.ID) {
        return false;
    }

    if (
        !isVerifiedReadTermsConditions ||
        !isVerifiedStoreAndUseDataConditions
    ) {
        return true;
    }

    return false;
};

/**
 * Function check it should re render component when scrool to bottom
 * to prevent rendering before call API =>> LAG
 * @param {*} currentJobLength
 * @param {*} nextJobLength
 * @param {*} page
 */

export const shouldRenderComponent = (
    currentJobLength,
    nextJobLength,
    page,
    currentKeyword,
    nextKeyword
) => {
    if (page === 1) {
        return true;
    } else if (currentKeyword !== nextKeyword) {
        return true;
    } else if (currentJobLength === nextJobLength && page > 1) {
        return false;
    }
    return true;
};

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join("")}`;
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
            ","
        )},${alpha})`;
    }
    throw new Error("Bad Hex");
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    }
    return str;
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    const time = timestamp * 1000;
    const formatDate = format || "MM-DD-YYYY";
    return moment(time).format(formatDate)
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
    const formatDate = format || "YYYY-MM-DD";
    return moment(date, formatDate).unix();
}

export function getIsoWeekRangeFromDate(date, format, type = 1) {
    format = format || "MM-DD-YYYY";

    if (type == 2) {
        return {
            start: moment(date)
                .startOf("isoWeek")
                .subtract(1, "d")
                .format(format),
            end: moment(date).endOf("isoWeek").subtract(1, "d").format(format),
        };
    }
    return {
        start: moment(date).startOf("isoWeek").format(format),
        end: moment(date).endOf("isoWeek").format(format),
    };
}

export function roundByDecimals(number, decimals) {
    if (!decimals) {
        return Math.round(number);
    }

    const exp = Math.pow(10, decimals);

    return Math.round(number * exp) / exp;
}

export function maxNumberPlus(number, max) {
    return number > max ? `${max}+` : `${number}`;
}

export function momentTz(dateTime) {
    return moment.tz(dateTime, "UTC").tz(AppConfig.timezone);
}

export function debounce(fn, delay) {
    let timer = null;

    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timer);

        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}

export function validNumberOrZero(value) {
    value = Number(value);
    return isNaN(value) ? 0 : value;
}

export function validStringOrEmpty(string) {
    return !string ? "" : string;
}

export function checkAndReArrayActiveJobAutomatically(
    jobs,
    activePositionId,
    autoUnshiftJob = null
) {
    if (!!activePositionId === false) {
        return jobs;
    }

    const respondJobs = [];
    let foundActiveJob = false;
    activePositionId = Number(activePositionId);

    jobs.map((job) => {
        // eslint-disable-line
        if (job.position_id === activePositionId) {
            foundActiveJob = true;
            respondJobs.unshift(job);
        } else {
            respondJobs.push(job);
        }
    });

    if (!foundActiveJob && autoUnshiftJob !== null) {
        respondJobs.unshift(autoUnshiftJob);
    }

    return respondJobs;
}

export function getTypeOfJobObject(job) {
    if (job.completed) {
        return MyGig.jobType.COMPLETED;
    }

    if (job.filled) {
        return MyGig.jobType.FILLED;
    }

    if (job.open) {
        return MyGig.jobType.POSTED;
    }

    return MyGig.jobType.PENDING;
}

export function getImageOfUniform(uniformFilename) {
    try {
        return (
            <img
                alt="uniform"
                src={require(`Assets/img/uniforms/${uniformFilename}.jpg`)}
            />
        );
    } catch (error) {
        // TO DO: Handle missing file
        return null;
    }
}

export const getLanguages = (languages) => {
    const newList = [];
    languages.forEach((item) => {
        if (item.language_name.split(", ").length > 1) {
            item.language_name.split(", ").forEach((element) => {
                if (ICONS.languageIcons[element]) {
                    newList.push({
                        language_level_id: item.language_level_id,
                        language_level_text: item.language_level_text,
                        language_name: element,
                    });
                }
            });
        } else {
            newList.push(item);
        }
    });
    return newList;
};

export const base64MimeType = (encoded) => {
    var result = null;

    if (typeof encoded !== "string") {
        return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
};

export const checkStringFormat = (dateString, format) => {
    return moment(dateString, format, true).isValid();
};

export const copy = (value) => JSON.parse(JSON.stringify(value));

export const ICONS = {
    languageIcons: {
        Arabic: require("../assets/language-icons/Arabic.png"),
        Bulgarian: require("../assets/language-icons/Bulgarian.png"),
        Chinese: require("../assets/language-icons/Chinese.png"),
        Croatian: require("../assets/language-icons/Croatian.png"),
        Czech: require("../assets/language-icons/Czech.png"),
        Danish: require("../assets/language-icons/Danish.png"),
        Dutch: require("../assets/language-icons/Dutch.png"),
        English: require("../assets/language-icons/English.png"),
        Finnish: require("../assets/language-icons/Finnish.png"),
        French: require("../assets/language-icons/French.png"),
        German: require("../assets/language-icons/German.png"),
        Greek: require("../assets/language-icons/Greek.png"),
        Hungarian: require("../assets/language-icons/Hungarian.png"),
        Italian: require("../assets/language-icons/Italian.png"),
        Japanese: require("../assets/language-icons/Japanese.png"),
        Korean: require("../assets/language-icons/Korean.png"),
        Latvian: require("../assets/language-icons/Latvian.png"),
        Lithuanian: require("../assets/language-icons/Lithuanian.png"),
        Norwegian: require("../assets/language-icons/Norwegian.png"),
        Polish: require("../assets/language-icons/Polish.png"),
        Portuguese: require("../assets/language-icons/Portuguese.png"),
        Romanian: require("../assets/language-icons/Romanian.png"),
        Russian: require("../assets/language-icons/Russian.png"),
        Slovak: require("../assets/language-icons/Slovak.png"),
        Spanish: require("../assets/language-icons/Spanish.png"),
        Swedish: require("../assets/language-icons/Swedish.png"),
        Vietnamese: require("../assets/language-icons/Vietnamese.png"),
    },
    skillIcons: {
        BARISTA: require("../assets/skill-icons/barista.png"),
        BARTENDER: require("../assets/skill-icons/bartender.png"),
        CHEF: require("../assets/skill-icons/chef.png"),
        CLEANER: require("../assets/skill-icons/cleaner.png"),
        "KITCHEN-PORTER": require("../assets/skill-icons/kitchen-porter.png"),
        "RETAIL-ASSISTANT": require("../assets/skill-icons/retail-assistant.png"),
        "ROOM-ATTENDANT": require("../assets/skill-icons/room-attendant.png"),
        SECURITY: require("../assets/skill-icons/security.png"),
        STEWARD: require("../assets/skill-icons/steward.png"),
        "WAITER-WAITRESS": require("../assets/skill-icons/waiter-waitress.png"),
    },
};

export const checkValidSrcIcon = (src) => src !== '0' && src !== '' && src !== null

export const sortConversations = (data) => {
    if (data.length === 0) return [];

    return data.sort((a, b) => {
        if (a.status > b.status) {
            return 1
        } else {
            if (a.status === b.status && a.send_at > b.send_at) {
                return -1
            }
        }
        return -1
    });
};


export const removeObjectWithId = (arr, id) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
  arr.splice(objWithIdIndex, 1);

  return arr;
};

export const convertLinks = ( input ) => {

  let text = input;
  const linksFound = text.match( /(?:www|https?)[^\s]+/g );
  const aLink = [];

  if ( linksFound != null ) {
    for ( let i=0; i<linksFound.length; i++ ) {
      let replace = linksFound[i];
      if ( !( linksFound[i].match( /(http(s?)):\/\// ) ) ) { replace = 'http://' + linksFound[i] }
      let linkText = replace.split( '/' )[2];
      if ( linkText.substring( 0, 3 ) == 'www' ) { linkText = linkText.replace( 'www.', '' ) }
    
      aLink.push( '<a href="' + replace + '" target="_blank">' + replace + '</a>' );
      
      text = text.split( linksFound[i] ).map(item => { return aLink[i].includes('iframe') ? item.trim() : item } ).join( aLink[i] );
    }
    return text;

  }
  else {
    return input;
  }
}

import React, { Component } from "react";

import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import StoredData from "Constants/StoredData";
import UserDefault from "Assets/img/default-user.png";
import MessageFileIcon from "Assets/img/messageFileIcon.png";
import ModalImage from "react-modal-image";
import ReactPlayer from 'react-player';
import { convertLinks } from "Helpers/helpers";

class MessageBlock extends Component {

    // Check file over 2 week 
    checkTimeAttachment(timeSendFile) {
        const datePast = moment.unix(timeSendFile);
        const diffDate = moment().diff(moment(datePast), 'weeks');
        return diffDate
    }

    showFile(messageObject) {
        switch (Number(messageObject.type_message)) {
            case 1 :
                const fileName = messageObject.name_file.length > 15 ? `${messageObject.name_file.slice(0,14)}...` : messageObject.name_file 
                const checkWeeds = this.checkTimeAttachment(messageObject.send_at)
                return <a href={checkWeeds < 2 ? messageObject.file : ''} title={checkWeeds < 2 ? messageObject.name_file ||  '' : ''} className="mt-2">
                        {checkWeeds < 2 ? 
                            (<img 
                                className="img-fluid" 
                                style={{maxHeight: 40, maxWidth: 40}} 
                                src={MessageFileIcon} 
                            />) : <p className="chat-note-attachment">{'file is two weeks out of date'}</p>
                        }
                        <p className="m-0 text-secondary h5">{checkWeeds > 2 ? fileName || '' : ''}</p>
                    </a>
            case 2:
                // const checkWeeds = this.checkTimeAttachment(messageObject.send_at);
                return <div>
                            { this.checkTimeAttachment(messageObject.send_at) < 2 ?
                                <ModalImage
                                    small={messageObject.file}
                                    large={messageObject.file}
                                    alt="Gig Image"
                                /> : <p className="chat-note-attachment">{'Image is two weeks out of date'}</p>
                            }
                        </div>
            case 3:
                return <div>
                            { this.checkTimeAttachment(messageObject.send_at) < 2 ?
                                <ReactPlayer
                                    url={messageObject.file}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                /> : <p className="chat-note-attachment">{'Video is two weeks out of date'}</p>
                            }
                    </div>
                break;
        
            default:
                null;
        }
    }
    
    render() {
        const {
            messageObject,
            seekerName,
            seekerThumbnail,
            
        } = this.props;
        const providerName = StoredData.get(StoredData.KEYS.EMPLOYER_NAME)
        const providerLogo = StoredData.get(StoredData.KEYS.EMPLOYER_LOGO)
        if (
            StoredData.get(StoredData.KEYS.USER_ID) ==
            messageObject.sender_id
        ) {
            return (
                <div className="d-flex flex-row-reverse pb-0">
                    <div className="col-auto p-0 ml-2">
                        <Tooltip title={providerName} placement="bottom">
                            <Avatar
                                alt="user profile"
                                src={providerLogo ? providerLogo : UserDefault}
                                className="img-fluid rounded-circle mr-15 align-self-center"
                            />
                        </Tooltip>
                    </div>
                    <div className="col-auto p-3 chat-bubble odd" style={{maxWidth: 'calc(100% - 60px)'}}>
                        <div className="flex-column">
                            <span className="text-right">
                                {moment.unix(messageObject.send_at).format("HH:mm DD MMM")}
                            </span>
                            {
                                messageObject.message.length ? <div style={{ overflowWrap: 'anywhere', whiteSpace: 'break-spaces' }} className="mb-0 "  dangerouslySetInnerHTML={{ __html: convertLinks(messageObject.message) }}></div> : null
                            }
                            {this.showFile(messageObject)}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex flex-row pb-0">
                <div className="col-auto p-0 mr-2">
                    <Tooltip title={seekerName} placement="bottom">
                        <Avatar
                            alt="user profile"
                            src={seekerThumbnail + "?" + new Date().getTime()}
                            className="img-fluid rounded-circle ml-15 align-self-center"
                        />
                    </Tooltip>
                </div>
                <div className="col-auto p-3 chat-bubble even" style={{maxWidth: 'calc(100% - 60px)'}}>
                    <div className=" flex-column" >
                        <span className="text-right">
                            {moment.unix(messageObject.send_at).format("HH:mm DD MMM")}
                        </span>
                        {
                            messageObject.message.length ? <p style={{ overflowWrap: 'break-word' }} className="mb-0">{messageObject.message}</p> : null
                        }
                        {this.showFile(messageObject)}
                    </div>
                </div>
            </div>  
        );
    }
}

export default (MessageBlock);

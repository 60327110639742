import React, { Component } from 'react';
import IntlMessages from 'Util/IntlMessages';

class TableHeader extends Component {
    render() {
        return (
            <table className = 'table-header-universe'>
                <thead>
                    <tr>
                        <th className="date-head"><IntlMessages id="dashboard.date" /></th>
                        
                        <th className="position-head"><IntlMessages id="dashboard.position" /></th>
                        <th className="location-head" style={{paddingLeft: "40px"}}><IntlMessages id="dashboard.location" /></th>
                        <th className="staff-required-head"><IntlMessages id="dashboard.staffRequired" /></th>
                        <th className="staff-allocated-head"><IntlMessages id="dashboard.staffAllocated" /></th>
                        <th className="status-head"><IntlMessages id="dashboard.status" /></th>
                        <th className="staff-applied-head"><IntlMessages id="dashboard.staffApplied" /></th>
                        <th className="view-job-head" />
                        <th className="action-job-head" />
                    </tr>
                </thead>
            </table>
        );
    }
}

export default TableHeader;

/**
 * Sign Up With
 */
import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import QueueAnim from "rc-queue-anim";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton , FormHelperText, MuiThemeProvider } from '@material-ui/core';
import Spinner from "Components/Model/Spinner";
import { FormattedMessage , injectIntl } from "react-intl";

import { isEmail, maxLength, minLength, matchCharacter, matchNumber  , isValidPostcode } from "Helpers/validators";



import {
    signUpUser,
    resetSignUpStatus,
    onChangeUserRegister,
    checkCardSignUp,
    getShiftAction,
    checkEmployerEmailExist,
    getShiftOptions,
    checkAccessCode,
    resetCheckAccessCodeProcess,
    resetPropsSignUp,
    resetEmailTrackingSignUp,
} from "Actions";
import IntlMessages from "Util/IntlMessages";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppConfig from "Constants/AppConfig";

import Logo from "Assets/img/login-logo.png";
import DateTimePickerIcon from 'Assets/img/datetimepicker-icon.png';
import MyGig from "Constants/MyGig";
import ValidatorMessage from "Components/ValidatorMessage";
import DatepickerWithIcon from "Components/ThemeOptions/CustomMaterial/DatePickerWithIcon";
import { DateTimePicker } from "@material-ui/pickers";
import GigLogoBlack from 'Assets/img/gig-logo-black.png';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            password: null,
            passwordConfirm: null,
            isAcceptTerms: false,
            isAcceptDataCollected: false,
            isAcceptedReceiveUpdateProduct: false,
            errors: {},
            openPopUp: false,
            nextStep: MyGig.signUp.STEP2,
            isPremium: false,
            currentHover: "",
        };
        this.today = new Date();
        this.props.resetSignUpStatus();
    }

    componentDidMount() {
        // call api for step 3
        this.props.getShiftOptions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ openPopUp: nextProps.openPopUp });
        const { errors } = this.state;

        // check email existed at form step 1
        const { emailExisted, attempToCheckEmailExist } = nextProps;
        const { nextStep } = this.state;
        if (!emailExisted && nextStep === MyGig.signUp.STEP1 && Object.keys(errors).length === 0) {
            this.setState({ nextStep: MyGig.signUp.STEP2, errors: {} });
        } else if (attempToCheckEmailExist && emailExisted && nextStep === MyGig.signUp.STEP1) {
            errors.email = "user.already.exist.message";
            this.setState({ errors });
        }

        if (nextStep === MyGig.signUp.STEP4) {
            if (nextProps.checkingCodeProcess.verified && !this.props.checkingCodeProcess.verified) {
                const { isPremium } = this.props.form.form4;

                if (isPremium) {
                    this.setState({ nextStep: MyGig.signUp.STEP8, errors: {} });
                } else {
                    this.setState({ nextStep: MyGig.signUp.STEP6, errors: {} });
                }

                this.forceUpdate();
            }
        }
    }

    componentWillUnmount() {
        this.setState({
            nextStep: MyGig.signUp.STEP1,
            errors: {}
        });
        this.props.resetPropsSignUp();
    }

    backStep = () => {
        const currentStep = this.state.nextStep;
        const { checkingCodeProcess } = this.props;

        if (currentStep === MyGig.signUp.STEP1) {
            return this.props.history.goBack();
        } else if (currentStep === MyGig.signUp.STEP7) {
            return this.setState({
                nextStep: currentStep - 3,
            });
        } else if (currentStep === MyGig.signUp.STEP8) {
            if (checkingCodeProcess.verified === true) {
                return this.setState({
                    nextStep: MyGig.signUp.STEP4
                });
            }
        } else if (currentStep === MyGig.signUp.STEP6) {
            if (checkingCodeProcess.verified === true) {
                return this.setState({
                    nextStep: MyGig.signUp.STEP4
                });
            }

            if (this.props.responseError !== null) {
                return this.props.history.goBack();
            }
        } else {
            if (currentStep === MyGig.signUp.STEP2) {
                this.props.resetEmailTrackingSignUp();
            }

            this.setState({
                nextStep: currentStep - 1,
            });
        }
    }

    getCurrentStep = () => {
        const { nextStep } = this.state;

        if (nextStep <= MyGig.signUp.STEP5) {
            return nextStep;
        }

        if (nextStep === MyGig.signUp.STEP7) {
            return MyGig.signUp.STEP5;
        }

        return MyGig.signUp.STEP6;
    }

    formatUserData = () => {
        const { form } = this.props;
        const { form1, form2, form3, form4, form5, form7 } = form;
        const {
            forename, surename, email, password, passwordConfirm,
            isAcceptTerms, isAcceptDataCollected, isAcceptedReceiveUpdateProduct
        } = form1;

        const {
            business_name, business_email,
            business_logo, business_street, business_city,
            business_postcode, business_phone,
        } = form2;

        const { natural_of_business, number_staffs, type_of_staff, gig_duration, gig_hear } = form3;
        const { isPremium, accessCode } = form4;
        const { card_token } = form5;
        const { dateCall, callAtFlag, phoneName, phoneNumber } = form7;

        let user = {
            email,
            password,
            password_confirmation: passwordConfirm,
            fullname: `${forename} ${surename}`,
            is_verified_store_and_use: isAcceptDataCollected,
            is_verified_read_terms: isAcceptTerms,
            is_accepted_receiving_updates: isAcceptedReceiveUpdateProduct,
            business_name,
            business_email,
            business_street,
            business_city,
            business_postcode,
            business_phone,
            business_logo,
            logo_thumbnail: business_logo,
            natural_of_business,
            number_staffs,
            type_of_staff,
            gig_duration,
            gig_hear,
            gig_premium: isPremium,
            access_code: accessCode
        };

        if (!isPremium) {
            user = { ...user, card_token };
        } else if (isPremium && !callAtFlag) {
            user = {
                ...user,
                contact_type: 1,
                contact_name: phoneName,
                contact_phone: phoneNumber
            };
        } else if (isPremium && callAtFlag) {
            user = {
                ...user,
                contact_type: 2,
                contact_name: phoneName,
                contact_phone: phoneNumber,
                contact_datetime: dateCall.unix()
            };
        }

        return user;
    }

    onSubmitForm = () => {
        this.setState({ nextStep: 6, errors: {} });
        const user = this.formatUserData();
        this.props.signUpUser(user);
    }

    onSuccess = () => {
        this.props.history.push("/");
    };

    handleClose = () => {
        this.setState({ openPopUp: false });
    };

    onInputChange = (e, form) => {
        const data = this.props.form;
        if (e.target.name === "business_logo") {
            this.convertBase64(e.target.files[0]);
        } else {
            data[form][e.target.name] = e.target.value;

            if (e.target.name === 'accessCode') {
                this.props.resetCheckAccessCodeProcess();
            }

            this.props.onChangeUserRegister(data);
            this.forceUpdate();
        }
    };

    onCheckboxChange = (e, form) => {
        const data = this.props.form;
        data[form][e.target.name] = e.target.checked;
        this.props.onChangeUserRegister(data);
        this.forceUpdate();
    };

    handleDateChange(dateTime, form) {
        const currentHour = dateTime.hour();
        const currentMinute = dateTime.minute();
        const { errors } = this.state;

        if (currentHour > 18 || currentHour < 9) {
            errors.time = "signUp.datetime.contact.error";
        } else if (currentHour === 18 && currentMinute !== 0) {
            errors.time = "signUp.datetime.contact.error";
        } else {
            delete errors.time;
        }

        this.setState({ errors });
        const formData = this.props.form;
        formData[form].dateCall = dateTime;
        this.props.onChangeUserRegister(formData);
        this.forceUpdate();
    }


    onRadioChange = (value, form, field) => {

        const data = this.props.form;
        data[form][field] = value;
        this.props.onChangeUserRegister(data);
        this.forceUpdate();
    }

    validateSignUpInfo(e) { // FIRST STEP

        const { email, password, forename, surename, passwordConfirm } = this.props.form.form1;
        const errors = {};

        if (!email) {
            errors.email = "validation.email.required";
        } else if (!isEmail(email)) {
            errors.email = "validation.email.invalid";
        } else {
            this.props.checkEmployerEmailExist(email);
        }

        if (!password) {
            errors.password = "validation.password.required";
        } else if (maxLength(password,5)) {
            errors.password = "validation.password.atLeastSixCharacters";
        }

        if (!forename) {
            errors.forename = "validation.forename.required";
        }

        if (!surename) {
            errors.surename = "validation.surename.required";
        }

        if (password !== passwordConfirm) {
            errors.passwordConfirm = "validation.passwordRepeat.invalid";
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            this.setState({ errors: {} });
            this.props.resetEmailTrackingSignUp();
        }
    };

    handleClose = () => {
        this.setState({ openPopUp: false });
    };


    onKeyPress(e) {

        const { currentHover } = this.state;
        if ((e.which < 48 || e.which > 57) || (e.target.name === "exp_month" && e.target.value.length >= 2 && currentHover.length === 0)
            || (e.target.name !== "exp_month" && e.target.value.length > 3 && currentHover.length === 0) ||
            (e.target.name === "cvc" && (e.target.value.length > 2 && currentHover.length === 0))) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }


    gText(e) {
        const text = (document.all) ? document.selection.createRange().text : document.getSelection();
        this.setState({
            "currentHover": text.toString()
        });
    }

    changeInForm(e) {

        if (e.target.value.length === MyGig.card.MAX_LENGTH || (e.target.name === 'exp_month' && e.target.value.length === 2)) {
            const index = Number(e.target.name.replace(/[^\d.]/g, ''));

            if (e.target.name === 'cardNumber4') {
                this.refs.month.focus();
            } else if (e.target.name === 'exp_month') {
                this.refs.year.focus();
            } else if (e.target.name === 'exp_year') {
                this.refs.cvc.focus();
            } else {
                this.refs[`card${index + 1}`].focus();
            }
            this.setState({ currentHover: "" });
        }
    }


    validateSubmitCard() {
        const self = this;
        const { form } = this.props;
        const { form5 } = form;
        const { card_name, cardNumber1, cardNumber2, cardNumber3, cardNumber4, exp_year, exp_month, cvc } = form5;
        const { errors } = this.state;


        window.Stripe.setPublishableKey(AppConfig.StripeKey.API_KEY);
        const card = {
            name: card_name,
            cvc,
            exp_month,
            exp_year,
            number: cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4
        };

        const promise = new Promise(((resolve, reject) => {
            window.Stripe.card.createToken(card, (status, response) => {
                resolve(response);
            });
        }));


        Promise.all([promise]).then((values) => {
            const response = values[0];

            if (response.error) {
                errors.card_name = response.error.message;
                self.setState({ errors });
            } else {
                form.form5.card_token = response.id;
                form.form5.isValidate = true;
                self.props.onChangeUserRegister(form);
                self.setState({
                    nextStep: MyGig.signUp.STEP6, errors: {}
                });

            }
        });

    }

    renderHeader() {
        return (
            <div className="sign-up-page__navigation">
                <div className="">
                    <div className="row row-no-margin padding-bottom-small">
                        <button
                            style={{ fontSize: 16, background: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={this.backStep}
                            className="col-xs-2 text-white font-bold sign-up-page__navigation--back"
                        >
                            {"< "} <IntlMessages id="widgets.back" />
                        </button>
                        <Link to="/" className="sign-up-page__logo col-xs-8">
                            <img
                                className=""
                                src={Logo}
                                alt="session-logo"
                            />
                        </Link>
                        <Link
                            to="/signin"
                            style={{ fontSize: 16 }}
                            className="col-xs-2 text-white font-bold sign-up-page__navigation--to-login"
                        >

                            <IntlMessages id="sidebar.login" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    validateThirdStep = () => {
        const { form3 } = this.props.form;
        const { errors } = this.state;
        for (const property in form3) {
            if (!form3[property]) {
                errors[property] = "error.requiredField";
            } else {
                delete errors[property];
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            this.setState({ nextStep: MyGig.signUp.STEP4, errors: {} });
        }
    }

    validateFouthStep() {
        const { isPremium, accessCode } = this.props.form.form4;

        if (accessCode) {
            return this.props.checkAccessCode(accessCode, isPremium);
        }

        if (isPremium) {
            this.setState({ nextStep: MyGig.signUp.STEP7, errors: {} });
        } else {
            this.setState({ nextStep: MyGig.signUp.STEP5, errors: {} });
        }

    }

    validateEightStep() {
        const { phoneName, phoneNumber, callAtFlag, dateCall } = this.props.form.form7;
        const { time } = this.state.errors;
        const errors = {};
        if (time) {
            errors.time = time;
        }

        if (!phoneName) {
            errors.phoneName = 'signUp.Name.empty';
        }
        if ( maxLength(phoneName,2)) {
            errors.phoneName = 'signUp.Name.min';
        }

        if (!phoneNumber) {
            errors.phoneNumber = "signUp.Phone.empty";
        }

        if (!errors.phoneNumber && minLength(phoneNumber, 17)) {
            errors.phoneNumber = "signUp.Phone.max";
        }
        if (!errors.phoneNumber && maxLength(phoneNumber, 5)) {
            errors.phoneNumber = "signUp.Phone.min";
        }
        if (!errors.phoneNumber && matchCharacter(phoneNumber)) {
            errors.phoneNumber = "signUp.Phone.invalid";
        }
        if (!phoneName) {
            errors.phoneName = "signUp.page.eighth.name.empty";
        }
        if (callAtFlag) {
            if (!dateCall) {
                errors.dateCall = "signUp.pageEight.dateCall.empty";
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            this.setState({ nextStep: MyGig.signUp.STEP8, errors: {} });
        }
    }

    validateBussinessDetail = async () => {
        const {
            business_name, business_email, business_phone,
            business_street, business_city, business_postcode
        } = this.props.form.form2;

        const errors = {};

        if (!business_street) {
            errors.business_street = "signUp.businessStreet.empty";
        }

        if (!business_city) {
            errors.business_city = "signUp.businessCity.empty";
        }


        if (!business_postcode || !(await isValidPostcode(business_postcode))) {
            errors.business_postcode = "signUp.businessPostcode.empty";
        }

        if (!business_phone) {
            errors.business_phone = "businessDetail.phone.empty";
        }
        if (!errors.business_phone && minLength(business_phone,17)) {
            errors.business_phone = "businessDetail.Phone.max";
        }
        if (!errors.business_phone && maxLength(business_phone, 5)) {
            errors.business_phone = "businessDetail.Phone.min";
        }
        if (!errors.business_phone && matchCharacter(business_phone)) {
            errors.business_phone = "businessDetail.Phone.invalid";
        }

        if (!business_name) {
            errors.business_name = "signUp.businessName.empty";
        }

        if (!business_email) {
            errors.business_email = "signUp.businessEmail.empty";
        }

        if (!errors.business_email) {
            const emailIsValid = !!(isEmail(business_email));
            if (!emailIsValid) {
                errors.business_email = 'loginEmailValid';
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
        } else {
            this.setState({ nextStep: MyGig.signUp.STEP3, errors: {} });
        }

    }


    renderSecondStep(currentForm) {
        const { errors } = this.state;
        const {
            business_name, business_email, business_street,
            business_city, business_postcode, business_phone
        } = this.props.form.form2;

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.business.name" />:
                                <span className="red">*</span>
                            </Label>
                            <Input value={business_name} type="text" name="business_name" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_name && (
                                    <ValidatorMessage message={errors.business_name} />
                                )
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="business-email">
                                <IntlMessages id="signUp.business.email" />:
                                <span className="red">*</span>
                            </Label>
                            <Input value={business_email} type="email" name="business_email" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_email && (
                                    <ValidatorMessage message={errors.business_email} />
                                )
                            }
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="business-street">
                                <IntlMessages id="signUp.business.street" />:
                                <span className="red">*</span>
                            </Label>
                            <Input value={business_street} type="text" name="business_street" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_street && (
                                    <ValidatorMessage message={errors.business_street} />
                                )
                            }
                        </FormGroup>

                        <FormGroup>
                            <Input value={business_city} type="text" name="business_city" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_city && (
                                    <ValidatorMessage message={errors.business_city} />
                                )
                            }
                        </FormGroup>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-postcode">
                                <IntlMessages id="signUp.business.postcode" />:
                                <span className="red">*</span>
                            </Label>
                            <Input value={business_postcode} type="text" name="business_postcode" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_postcode && (
                                    <ValidatorMessage message={errors.business_postcode} />
                                )
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="business-phone">
                                <IntlMessages id="signUp.business.phone" />:
                                <span className="red">*</span>
                            </Label>
                            <Input value={business_phone} type="text" name="business_phone" onChange={(e) => this.onInputChange(e, currentForm)} />
                            {
                                errors.business_phone && (
                                    <ValidatorMessage message={errors.business_phone} />
                                )
                            }
                        </FormGroup>
                    </div>
                    <div className="col-md-6 center">
                        <Label>
                            <IntlMessages id="signUp.upload.logo" />:
                        </Label>
                        <input
                            type="file"
                            id="business_logo"
                            name="business_logo"
                            accept="image/*"
                            className="inputfile"
                            onChange={(e) => this.onInputChange(e, currentForm)} />
                        <label htmlFor="business_logo">
                            <i className="material-icons">photo_camera</i>
                            <IntlMessages id="signUp.upload.chooseFile" />
                        </label>
                        {
                            errors.image && (
                                <div className="d-flex justify-content-center my-20 center">
                                    <ValidatorMessage message={errors.image} />
                                </div>
                            )
                        }

                    </div>
                    <div className="col-md-6">
                        <Button
                            onClick={this.validateBussinessDetail}
                            className="form-auth__submit margin-top-15">
                            <IntlMessages id="signUp.button.nextStep" />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }


    renderThirdStep() {
        const { shifts, form } = this.props;
        const { form3 } = form;
        const { errors } = this.state;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.shift.name.of.business" />:
                                <span className="red">*</span>
                            </Label>
                            <select
                                required
                                type="select"
                                className="form-control"
                                value={form3.natural_of_business}
                                name="natural_of_business"
                                onChange={(event) => this.onInputChange(event, 'form3')}
                            >
                                {
                                    shifts.naturals_of_business.map(business =>
                                        <option key={business.id} value={business.id}>{business.name}</option>
                                    )
                                }
                            </select>
                            <ValidatorMessage className="text-center" message={errors.natural_of_business} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.shift.type.of.staff" />:
                                <span className="red">*</span>
                            </Label>
                            <select
                                required
                                type="select"
                                className="form-control"
                                value={form3.type_of_staff}
                                name="type_of_staff"
                                onChange={(event) => this.onInputChange(event, 'form3')}
                            >
                                {
                                    shifts.types_of_staff.map(type =>
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    )
                                }
                            </select>
                            <ValidatorMessage className="text-center" message={errors.type_of_staff} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.shift.number.of.staff" />:
                                <span className="red">*</span>
                            </Label>
                            <select
                                required
                                type="select"
                                className="form-control"
                                value={form3.number_staffs}
                                name="number_staffs"
                                onChange={(event) => this.onInputChange(event, 'form3')}
                            >
                                {
                                    shifts.number_staffs_thresholds.map(number =>
                                        <option key={number.id} value={number.id}>{number.name}</option>
                                    )
                                }
                            </select>
                            <ValidatorMessage className="text-center" message={errors.number_staffs} />
                        </FormGroup></div>

                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.shift.gig.duration" />:
                                <span className="red">*</span>
                            </Label>
                            <select
                                required
                                type="select"
                                className="form-control"
                                value={form3.gig_duration}
                                name="gig_duration"
                                onChange={(event) => this.onInputChange(event, 'form3')}
                            >
                                {
                                    shifts.gig_durations.map(duration =>
                                        <option key={duration.id} value={duration.id}>{duration.name}</option>
                                    )
                                }
                            </select>
                            <ValidatorMessage className="text-center" message={errors.gig_duration} />
                        </FormGroup>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="business-name">
                                <IntlMessages id="signUp.shift.how.did.you.hear" />:
                                <span className="red">*</span>
                            </Label>
                            <select
                                required
                                type="select"
                                className="form-control"
                                value={form3.gig_hear}
                                name="gig_hear"
                                onChange={(event) => this.onInputChange(event, 'form3')}
                            >
                                {
                                    shifts.gig_hear.map(hear =>
                                        <option key={hear.id} value={hear.id}>{hear.name}</option>
                                    )
                                }
                            </select>
                            <ValidatorMessage className="text-center" message={errors.gig_hear} />
                        </FormGroup>
                    </div>

                </div>
                <div>
                    <Button
                        onClick={this.validateThirdStep}
                        className="form-auth__submit margin-top-15">
                        <IntlMessages id="signUp.button.nextStep" />
                    </Button>
                </div>
            </Fragment>
        );

    }

    renderFifthStep(currentForm) {
        const { intl } = this.props;
        const { form5 } = this.props.form;
        const { errors } = this.state;
        const {
            card_name, cardNumber1, cardNumber2, cardNumber3,
            cardNumber4, exp_month, exp_year, cvc
        } = form5;
        return (
            <Fragment>
                <div className="step-6-wrapper">
                    <div className="gig-direct step-6">
                        <div className="title step-6">
                            <p className="font-thinner"><IntlMessages id="signUp.creditcard.detail" />:</p>
                        </div>

                        <div className="content">
                            <p className="payment-description step-6"><IntlMessages id="signUp.payment.not.complete" /></p>
                        </div>

                        <div className="row card-type-1">
                            <div className="col-md-5">
                                <label>
                                    <IntlMessages id="signUp.name.on.card" />
                                    <span className="red">*</span>
                                </label>
                                <Input value={card_name} type="text" name="card_name" onChange={(e) => this.onInputChange(e, currentForm)} />
                            </div>
                            <div className="col-md-7 bg-white">
                                <label>
                                    <IntlMessages id="signUp.card.number" />
                                    <span className="red">*</span>
                                </label>
                                <div className="row" onChange={(e) => this.changeInForm(e)}>
                                    <div className="col-md-3 pr-0">
                                        <input
                                            className="form-control center"
                                            name="cardNumber1"
                                            type="text"
                                            value={cardNumber1}
                                            ref="card1"
                                            onMouseUp={(e) => this.gText(e)}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)}
                                        />
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <input
                                            className="form-control center"
                                            name="cardNumber2"
                                            type="text"
                                            ref="card2"
                                            value={cardNumber2}
                                            onMouseUp={(e) => this.gText(e)}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)} />
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <input
                                            className="form-control center"
                                            name="cardNumber3"
                                            ref="card3"
                                            type="text"
                                            value={cardNumber3}
                                            onMouseUp={(e) => this.gText(e)}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)} />
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <input
                                            className="form-control center"
                                            name="cardNumber4"
                                            type="text"
                                            ref="card4"
                                            value={cardNumber4}
                                            onMouseUp={(e) => this.gText(e)}
                                            onKeyPress={(e) => this.onKeyPress(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)} />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-5">
                                <label>
                                    <IntlMessages id="signUp.card.type" />
                                    <span className="red">*</span>
                                </label>
                                <select type="select"
                                    className="form-control"
                                >
                                    <option>
                                        {intl.formatMessage({ id: 'signUp.master.debit' })}
                                    </option>
                                    <option>
                                        {intl.formatMessage({ id: 'signUp.visa.debit' })}s
                                    </option>
                                </select>
                            </div>
                            <div className="col-md-3 bg-white">
                                <label>
                                    <IntlMessages id="signUp.Expires" />
                                    <span className="red">*</span>
                                </label>
                                <div className="row" onChange={(e) => this.changeInForm(e)}>
                                    <div className="col-md-6">
                                        <input
                                            value={exp_month}
                                            name="exp_month"
                                            type="text"
                                            ref="month"
                                            className="form-control center"
                                            onMouseUp={(e) => this.gText(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)}
                                            onKeyPress={(e) => this.onKeyPress(e)} />
                                    </div>

                                    <div className="col-md-6" onChange={(e) => this.changeInForm(e)}>
                                        <input
                                            value={exp_year}
                                            name="exp_year"
                                            onMouseUp={(e) => this.gText(e)}
                                            type="text"
                                            ref="year"
                                            className="form-control center"
                                            onChange={(e) => this.onInputChange(e, currentForm)}
                                            onKeyPress={(e) => this.onKeyPress(e)} />

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 bg-white padding-left-cvc">
                                <label>
                                    <IntlMessages id="signUp.card.cvc" />:
                                    <span className="red">*</span>
                                </label>
                                <div className="row">
                                    <div className="col-md-11">
                                        <input
                                            value={cvc}
                                            name="cvc"
                                            className="form-control center"
                                            type="text"
                                            ref="cvc"
                                            onMouseUp={(e) => this.gText(e)}
                                            onChange={(e) => this.onInputChange(e, currentForm)}
                                            onKeyPress={(e) => this.onKeyPress(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        {errors.card_name && (
                            <ValidatorMessage className="text-center" directMessage={errors.card_name} />
                        )}
                        <Button
                            onClick={() => this.validateSubmitCard()}
                            className="form-auth__submit">
                            <IntlMessages id="common.submit" />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }


    renderFourthStep(currentForm) {
        const { isPremium, accessCode } = this.props.form.form4;
        const { checkingCodeProcess } = this.props;

        return (
            <div className="step-5-wrapper">
                <div className="gig-direct">
                    <div className="title">
                        <p>
                            <span className="title-first">
                                <img src={GigLogoBlack} alt="" />
                            </span>
                            <span className="title-third">
                                <input
                                    checked={!isPremium}
                                    onChange={() => this.onRadioChange(false, currentForm, 'isPremium')}
                                    type="radio"
                                    className="checkbox-custom"
                                    name="gigType"
                                    id="isGigDirect"
                                />
                                <label
                                    className="checkbox-custom-label step-4"
                                    htmlFor="isGigDirect" />
                            </span>
                        </p>


                    </div>
                    <div className="content">
                        <IntlMessages id="signUp.page.fifth.direct.content" />
                    </div>
                    <br />
                    <div className="footer">
                        <IntlMessages id="signUp.page.fifth.direct.footer" />
                    </div>
                </div>
                <div className="gig-premium">
                    <div className="title">
                        <p>
                            <span className="title-first">
                                <img src={GigLogoBlack} alt="" />
                            </span>
                            <span className="title-second">
                                <IntlMessages id="signUp.page.fifth.Premium" />
                            </span>
                            <span className="title-third">
                                <input
                                    onChange={() => this.onRadioChange(true, currentForm, 'isPremium')}
                                    type="radio"
                                    className="checkbox-custom"
                                    name="gigType"
                                    checked={isPremium}
                                    id="isGigPremium"
                                />
                                <label
                                    className="checkbox-custom-label step-4"
                                    htmlFor="isGigPremium" />
                            </span></p>
                    </div>
                    <div className="content">
                        <IntlMessages id="signUp.page.fifth.premium.content" />
                    </div>
                    <br />
                    <div className="footer">
                        <IntlMessages id="signUp.page.fifth.premium.footer" />
                    </div>
                </div>


                <div className="row">
                    <div className="col-xs-12 col-sm-7 col-md-7 access-code-wrapper">
                        <div className="access-code">
                            <div className="title">
                                <IntlMessages id="signUp.accessCodeTitle" />
                            </div>
                            <FormGroup>
                                <Label>
                                    <IntlMessages id="signUp.accessCode" />:
                                </Label>
                                <Input
                                    type="text"
                                    name="accessCode"
                                    value={accessCode}
                                    disabled={checkingCodeProcess.onChecking}
                                    onChange={(e) => this.onInputChange(e, currentForm)}
                                />

                                {checkingCodeProcess.verified === false && (
                                    <ValidatorMessage message="signUp.checkingCode.failure" />
                                )}

                            </FormGroup>
                        </div>
                    </div>
                </div>

                <div>
                    <Button
                        onClick={() => this.validateFouthStep()}
                        className="form-auth__submit"
                        disabled={checkingCodeProcess.onChecking}
                    >
                        {checkingCodeProcess.onChecking ? (
                            <Fragment>
                                <IntlMessages id="signUp.checkingCode" />
                                <CircularProgress className="progress-white mr-5" size={26} thickness={5} mode="determinate" value={40} min={0} max={50} />
                            </Fragment>
                        ) : (
                                <IntlMessages id="signUp.button.nextStep" />
                            )}
                    </Button>
                </div>
            </div>
        );
    }

    renderSixthStep() {
        const { isPremium } = this.props.form.form4;

        const idMessage = isPremium ? "signUp.contact.message" : "signUp.complete.message";

        return (
            <div className="step-7-wrapper complete-form">
                <div className="center bigger-font text-message-noti">
                    <strong><IntlMessages id={idMessage} /></strong>
                </div>
                <div className="center">
                    <Button
                        onClick={this.onSubmitForm}
                        className="form-auth__submit"
                    >
                        <IntlMessages id="signUp.button.bookStaffNow" />
                    </Button>
                </div>
            </div>
        );
    }

    convertBase64(value) {
        const self = this;
        const reader = new FileReader();

        reader.addEventListener('load', () => {

            const { errors } = this.state;
            if (this.convertBase64toMB(reader.result) < 1) {
                const form = self.props.form;
                form.form2.business_logo = reader.result;
                self.props.onChangeUserRegister(form);
                delete errors.image;
            } else {
                errors.image = "logo.max.size.1MB";
            }
            this.setState({
                errors
            });
        }, false);

        if (value) {
            reader.readAsDataURL(value);
        }
    }

    convertBase64toMB(base64) {
        const stringLength = base64.length - 'data:image/png;base64,'.length;
        const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        const sizeInMB = sizeInBytes / 1000000;
        return sizeInMB;
    }

    renderFirstStep(currentForm) {
        const { errors } = this.state;
        const errForeName = errors.forename;
        const errSurename = errors.surename;
        const errEmail = errors.email;
        const errPassword = errors.password;
        const errPasswordConfirm = errors.passwordConfirm;
        const {
            forename, surename, email, password, passwordConfirm,
            isAcceptTerms, isAcceptDataCollected, isAcceptedReceiveUpdateProduct
        } = this.props.form.form1;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup className="form-auth__input-wrap">
                            <label>
                                <IntlMessages id="components.forename" />:
                                <span className="red">*</span>
                            </label>
                            <Input
                                onChange={(e) => this.onInputChange(e, currentForm)}
                                className="form-control"
                                type="text"
                                name="forename"
                                value={forename}
                                required
                            />
                            {errForeName && (
                                <FormHelperText className="field-error">
                                    {errForeName && <FormattedMessage id={errForeName} />}
                                </FormHelperText>
                            )}
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup className="form-auth__input-wrap">
                            <label>
                                <IntlMessages id="components.surename" />:
                                <span className="red">*</span>
                            </label>
                            <Input
                                onChange={(e) => this.onInputChange(e, currentForm)}
                                className="form-control"
                                type="text"
                                name="surename"
                                value={surename}
                                required
                            />
                            {errSurename && (
                                <FormHelperText className="field-error">
                                    {errSurename && <FormattedMessage id={errSurename} />}
                                </FormHelperText>
                            )}
                        </FormGroup>
                    </div>
                </div>

                <FormGroup className="form-auth__input-wrap">
                    <label>
                        <IntlMessages id="widgets.emailAddress" />:
                        <span className="red">*</span>
                    </label>
                    <Input
                        onChange={(e) => this.onInputChange(e, currentForm)}
                        className="form-control"
                        type="email"
                        name="email"
                        value={email}
                        required
                    />
                    {errEmail && (
                        <FormHelperText className="field-error">
                            {errEmail && <FormattedMessage id={errEmail} />}
                        </FormHelperText>
                    )}
                </FormGroup>

                <FormGroup className="form-auth__input-wrap">
                    <label>
                        <IntlMessages id="components.password" />:
                        <span className="red">*</span>
                    </label>
                    <input
                        onChange={(e) => this.onInputChange(e, currentForm)}
                        className="form-control"
                        type="password"
                        name="password"
                        value={password}
                        required
                    />
                    {errPassword && (
                        <FormHelperText className="field-error">
                            {errPassword && (
                                <FormattedMessage id={errPassword} />
                            )}
                        </FormHelperText>
                    )}
                </FormGroup>

                <FormGroup className="form-auth__input-wrap">
                    <label>
                        <IntlMessages id="components.passwordConfirm" />:
                        <span className="red">*</span>
                    </label>
                    <input
                        onChange={(e) => this.onInputChange(e, currentForm)}
                        className="form-control"
                        type="password"
                        name="passwordConfirm"
                        autoComplete="off"
                        value={passwordConfirm}
                        required
                    />
                    {errPasswordConfirm && (
                        <FormHelperText className="field-error">
                            {errPasswordConfirm && (
                                <FormattedMessage id={errPasswordConfirm} />
                            )}
                        </FormHelperText>
                    )}
                </FormGroup>

                <div>

                    <div className="font-normal italic mt-40">
                        <IntlMessages id="signUp.guideForGPDR" />
                        <span className="red">*</span>
                    </div>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={(e) => this.onCheckboxChange(e, currentForm)}
                            type="checkbox"
                            checked={isAcceptDataCollected}
                            className="checkbox-custom"
                            name="isAcceptDataCollected"
                            id="isAcceptDataCollected"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isAcceptDataCollected">
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptDataCollectedPrefix" />
                                &nbsp;
                                <a href={AppConfig.URL_PRIVACY_POLICY}>
                                    <IntlMessages id="widgets.here" />.
                                </a>
                                &nbsp;
                                <IntlMessages id="signUp.acceptDataCollectedSuffix" />
                            </span>
                        </label>
                    </FormGroup>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={(e) => this.onCheckboxChange(e, currentForm)}
                            type="checkbox"
                            checked={isAcceptTerms}
                            className="checkbox-custom"
                            name="isAcceptTerms"
                            id="isAcceptTerms"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isAcceptTerms"
                        >
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptTermPrefix" />
                                &nbsp;
                                <a href={AppConfig.URL_TERMS_AND_CONDITIONS}>
                                    <IntlMessages id="widgets.here" />.
                                </a>
                                &nbsp;
                                <IntlMessages id="signUp.acceptTermSuffix" />
                            </span>
                        </label>
                    </FormGroup>

                    <FormGroup className="form-auth__term">
                        <input
                            onChange={(e) => this.onCheckboxChange(e, currentForm)}
                            type="checkbox"
                            checked={isAcceptedReceiveUpdateProduct}
                            className="checkbox-custom"
                            name="isAcceptedReceiveUpdateProduct"
                            id="isAcceptedReceiveUpdateProduct"
                        />
                        <label
                            className="checkbox-custom-label"
                            htmlFor="isAcceptedReceiveUpdateProduct"
                        >
                            <span className="checkbox-text">
                                <IntlMessages id="signUp.acceptReceiveUpdateProduct" />
                            </span>
                        </label>
                    </FormGroup>
                </div>
                {
                    isAcceptTerms && isAcceptDataCollected
                    && <div>
                        <Button onClick={(e) => this.validateSignUpInfo(e)}
                            className="form-auth__submit">

                            <IntlMessages id="signUp.button.startPosting" />
                        </Button>
                    </div>
                }
            </Fragment>
        );
    }

    disableWeekends = (date) => {
        date = new Date(date);
        return date.toDateString() === this.today.toDateString() || date.getDay() === 0 || date.getDay() === 6;
    }

    renderSeventhStep(currentForm) {
        const { dateCall, callAtFlag, phoneName, phoneNumber } = this.props.form[currentForm];
        const { errors } = this.state;

        return (
            <div className="step-5-wrapper">
                <div className="gig-premium step-7">

                    <div className="content font-size-page8">
                        <IntlMessages id="signUp.page.eighth.premium.content" />
                    </div>
                    <br />
                    <div className="font-size-page8">
                        <IntlMessages id="signUp.page.eighth.premium.footer" />
                    </div>
                    <div className="row margin-top-15">
                        <div className="col-md-1">
                            <input
                                onChange={() => this.onRadioChange(false, currentForm, 'callAtFlag')}
                                type="radio"
                                checked={!callAtFlag}
                                className="checkbox-custom"
                                name="callAt"
                                id="callAsSoonAsPosibile"
                            />
                            <label
                                className="checkbox-custom-label"
                                htmlFor="callAsSoonAsPosibile" />
                        </div>
                        <div className="col-md-11">
                            <IntlMessages id="signUp.page.eighth.checkbox.message" />
                        </div>
                    </div>

                    <div className="row margin-top-31">
                        <div className="col-md-1 call-at-time-check-box">
                            <input
                                onChange={() => this.onRadioChange(true, currentForm, 'callAtFlag')}
                                type="radio"
                                className="checkbox-custom"
                                name="callAt"
                                checked={callAtFlag}
                                id="callAtTime"
                            />
                            <label
                                className="checkbox-custom-label"
                                htmlFor="callAtTime" />
                        </div>
                        <div className="col-md-5 call-at-time-text">
                            <IntlMessages id="signUp.page.eighth.checkbox.message2" />
                        </div>

                        <div className="col-md-6 call-at-time-time">
                            {callAtFlag &&
                                <MuiThemeProvider
                                    theme={DatepickerWithIcon}
                                >
                                    <div className="rct-picker">
                                        <DateTimePicker
                                            value={dateCall}
                                            format="DD MMM YYYY - H:mm"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton>
                                                        <img src={DateTimePickerIcon} alt="" />
                                                    </IconButton>
                                                ),
                                            }}
                                            onChange={datetime => this.handleDateChange(datetime, currentForm)}
                                            className="gig-picker"
                                            shouldDisableDate={this.disableWeekends}
                                            disablePast
                                            leftArrowIcon={
                                                <i className="zmdi zmdi-arrow-back" />
                                            }
                                            rightArrowIcon={
                                                <i className="zmdi zmdi-arrow-forward" />
                                            }
                                            fullWidth
                                        />
                                    </div>
                                    {
                                        errors.time && (
                                            <ValidatorMessage className="text-center" message={errors.time} />
                                        )
                                    }
                                </MuiThemeProvider>
                            }
                        </div>
                    </div>

                    <div className="row margin-top-15">
                        <div className="col-md-6 margin-bottom-30">
                            <label>
                                <IntlMessages id="signUp.Name" />:
                                <span className="red">*</span>
                            </label>
                            <Input value={phoneName} type="text" name="phoneName" onChange={e => this.onInputChange(e, currentForm)} />
                            <div className="height-30">
                                {errors.phoneName ?
                                    <ValidatorMessage className="text-center" message={errors.phoneName} /> : <span className="height-1" />
                                }
                            </div>
                        </div>


                        <div className="col-md-6 margin-bottom-30">
                            <label>
                                <IntlMessages id="signUp.phone.number" />:
                                <span className="red">*</span>
                            </label>
                            <Input value={phoneNumber} type="text" name="phoneNumber" onChange={e => this.onInputChange(e, currentForm)} />
                            <div className="height-30">
                                {errors.phoneNumber ?
                                    <ValidatorMessage className="text-center" message={errors.phoneNumber} /> : <span className="height-1" />
                                }
                            </div>
                        </div>

                    </div>

                </div>
                <div>
                    <Button
                        onClick={() => this.validateEightStep()}
                        className="form-auth__submit vertical-space-smaller">
                        <IntlMessages id="common.submit" />
                    </Button>


                </div>
            </div>
        );
    }

    render() {
        const { nextStep, openPopUp } = this.state;
        const { signUpSuccess, signUpFail, responseError, loading } = this.props;
        const headerMessage = `signUp.page.title${  nextStep}`;
        const currentForm = `form${  nextStep}`;

        let classWidth = "";
        if (nextStep === MyGig.signUp.STEP1) {
            classWidth = "width-50";
        }

        const notShowHeader = [MyGig.signUp.STEP5, MyGig.signUp.STEP6, MyGig.signUp.STEP7, MyGig.signUp.STEP8, MyGig.signUp.STEP9, MyGig.signUp.STEP10];
        const totalSteps = 5;
        const currentStep = this.getCurrentStep();

        return (
            <QueueAnim type="bottom" duration={2000}>
                <div className="rct-session-wrapper drawer-wrapper">
                    <div className="sign-up-page">
                        <Spinner
                            spinnerWidth={70}
                            show={loading}
                        />

                        <div className={`sign-up-page__wrapper ${classWidth}`} style={{ width: '50vw' }}>
                            {this.renderHeader()}
                            <Card>
                                <CardContent className="sign-up-page__form-inner">
                                    <div className="group-title">
                                        {notShowHeader.indexOf(nextStep) === -1 && (
                                            <div className="sign-up-page__title font-bold">
                                                <IntlMessages id={headerMessage} />
                                                .
                                        </div>
                                        )}

                                        {notShowHeader.indexOf(nextStep) !== -1 && (nextStep !== 7 && nextStep !== 8) && (
                                            <img src={GigLogoBlack} alt="" />
                                        )}

                                        {notShowHeader.indexOf(nextStep) !== -1 && (nextStep === 7 || nextStep === 8) && (
                                            <div className="special-step-7">
                                                <img src={GigLogoBlack} alt="" />
                                                <div className="title-second font-size-3 title-step-7">
                                                    <IntlMessages id="signUp.page.fifth.Premium" />
                                                </div>
                                            </div>
                                        )}

                                        {currentStep <= MyGig.signUp.STEP5 && (
                                            <div className="step-number font-thinner">
                                                {`${currentStep}/${totalSteps}`}
                                            </div>
                                        )}

                                        {currentStep > MyGig.signUp.STEP5 && (
                                            <div className="step-number font-thinner">
                                                <IntlMessages id="button.complete" />.
                                            </div>
                                        )}

                                    </div>
                                    <Form
                                        onSubmit={e => this.onSubmit(e)}
                                        className="form-auth"
                                    >
                                        {nextStep === MyGig.signUp.STEP1 && this.renderFirstStep(currentForm)}
                                        {nextStep === MyGig.signUp.STEP2 && this.renderSecondStep(currentForm)}
                                        {nextStep === MyGig.signUp.STEP3 && this.renderThirdStep(currentForm)}
                                        {nextStep === MyGig.signUp.STEP4 && this.renderFourthStep(currentForm)}
                                        {nextStep === MyGig.signUp.STEP5 && this.renderFifthStep(currentForm)}
                                        {(nextStep === MyGig.signUp.STEP6 || nextStep === MyGig.signUp.STEP8) && this.renderSixthStep()}
                                        {nextStep === MyGig.signUp.STEP7 && this.renderSeventhStep(currentForm)}

                                    </Form>
                                </CardContent>
                            </Card>
                        </div>
                        {signUpFail && (
                            <SweetAlert
                            confirmBtnCssClass={"btn-orange"}
                            cancelBtnCssClass={"btn-black"}
                                danger
                                show={openPopUp}
                                onConfirm={this.handleClose}
                                title="Error"
                            >
                                {responseError.message}
                            </SweetAlert>
                        )}
                        {signUpSuccess && (
                            <SweetAlert
                                success
                                confirmBtnCssClass={"btn-orange"}
                                cancelBtnCssClass={"btn-black"}
                                title={
                                    <IntlMessages id="signUp.message.success" />
                                }
                                onConfirm={this.onSuccess}
                            />
                        )}
                    </div>
                </div>
            </QueueAnim>
        );
    }
}


// map state to props
const mapStateToProps = ({ authUser }) => {
    const { form,
        emailExisted,
        attempToCheckEmailExist,
        shifts,
        loading,
        checkingCodeProcess
    } = authUser;
    const { signUpSuccess, signUpFail, responseError } = authUser.signUp;
    return {
        signUpSuccess,
        signUpFail,
        responseError,
        form,
        emailExisted,
        attempToCheckEmailExist,
        shifts,
        loading,
        checkingCodeProcess
    };
};

const mapActionToProps = {
    signUpUser,
    resetSignUpStatus,
    onChangeUserRegister,
    checkCardSignUp,
    getShiftAction,
    checkEmployerEmailExist,
    getShiftOptions,
    checkAccessCode,
    resetCheckAccessCodeProcess,
    resetPropsSignUp,
    resetEmailTrackingSignUp
};

export default connect(mapStateToProps, mapActionToProps)(injectIntl(SignUp));

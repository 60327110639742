/**
 * Location Sagas
 */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
  GET_LOCATION,
  EDIT_LOCATION,
  ADD_LOCATION,
  REMOVE_LOCATION,
} from 'Actions/types';

import {
    showErrorResponse,
    getLocationSuccess,
    getLocationFailure,
    addLocationSuccess,
    addLocationFailure,
    editLocationSuccess,
    editLocationFailure,
    removeFailure,
    getLocation,
    removeSuccess
} from 'Actions';

import {
  getLocationsRequest,
  editLocationRequest,
  createLocationRequest,
  removeLocationRequest,
} from 'Services/employers/EmployerLocationService';


/**
 * Get Location From Server
 */
function* handleGetLocation() {
  try {
    const response = yield call(getLocationsRequest);
    if (isSuccess(response)) {
      yield put(getLocationSuccess(response));
    } else {
      yield put(getLocationFailure(response));
    }
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}


/**
 * Update Location from server
 */
function* handleUpdateLocation(action) {
  try {
    const newlocation = action.payload.newLocation;
    const response = yield call(editLocationRequest, newlocation);

    if (isSuccess(response)) {
        yield put(getLocation());
        yield put(editLocationSuccess());
    } else {
      yield put(getLocationFailure(response));
    }
  } catch (error) {
    yield put(getLocationFailure(error));
  }
}

/**
 * Create Location from server
 */
function* handleCreateLocation(action) {
  try {
    const newlocation = action.payload.newLocation;
    const response = yield call(createLocationRequest, newlocation);

    if (isSuccess(response)) {
        yield put(getLocation());
        yield put(addLocationSuccess())
    } else {
      yield put(getLocationFailure(response));
    }
  } catch (error) {
      yield put(getLocationFailure(error));
  }
}

/**
 * Remove Location from server
 */
function* handleRemoveLocation(action) {
    try {
        const id = action.payload.id;
        const response = yield call(removeLocationRequest, id);

        if (isSuccess(response)) {
            yield put(getLocation());
            yield put(removeSuccess());
        } else {
            yield put(removeFailure(response));
        }
    } catch (error) {
        yield put(removeFailure(error));
    }
}

/**
 * Get Location
 */
export function* watchGetLocations() {
  yield takeEvery(GET_LOCATION, handleGetLocation);
}

/**
 * Create Location
 */
export function* watchCreateLocation() {
  yield takeEvery(ADD_LOCATION, handleCreateLocation);
}

/**
 * Remove Location Saga
 */
export function* watchRemoveLocation() {
  yield takeLatest(REMOVE_LOCATION, handleRemoveLocation);
}

/**
 * Update Location
 */
export function* watchUpdateLocation() {
  yield takeEvery(EDIT_LOCATION, handleUpdateLocation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLocations),
    fork(watchCreateLocation),
    fork(watchUpdateLocation),
    fork(watchRemoveLocation),
  ]);
}

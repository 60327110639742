import API from 'Api';

export const getExcelTimecardReports = async(startDate, endDate, locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        locationId
    };
    if(!locationId) {
        delete params.locationId
    }

    return await API.get('/employers/excel/exportTimecardReports', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelFulfilmentReports = async(startDate, endDate) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
    };
  
    return await API.get('/employers/excel/exportFulfilmentReports', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelBillingReports = async(startDate, endDate) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
    };
  
    return await API.get('/employers/excel/exportBillingReports', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelAWRReports = async() => {
    
    return await API.get('/employers/excel/exportAWRReports')
        .then(response => response)
        .catch(error => error);
};

export const getWeeklyHourReports = async(week, locationId = 0) => {
    const totalWeek = {
        total_weeks: week,
        location_id: locationId
    }

    return await API.get('/employers/excel_top_seekers/by_shifts', totalWeek, true)
        .then(response => response)
        .catch(error => error);
}

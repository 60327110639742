import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IntlMessages from "Util/IntlMessages";
import DownloadFile from "Assets/img/DownloadFile.png";
// Actions
import {
  excelDownloadApplied,
  excelDownloadConfirmed,
  showRootSpinner,
} from "Actions";

class DownloadExcel extends Component {
  downloadExcelFile = () => {
    const { listType, type, positionId } = this.props;
    if (listType == "Posted") {
      this.props.excelDownloadApplied(positionId, type);
    }
    if (listType == "Filled") {
      this.props.excelDownloadConfirmed(positionId, type);
    }
    this.props.showRootSpinner();
  };

  render() {
    return (
      <div>
        <div
          className="w-100 d-flex justify-content-center"
          style={{ marginTop: "5px", alignItems: "center" }}
        >
          <button
            className="print-icon-container pointer"
            style={{ border: "none", background: "none" }}
            onClick={() => this.downloadExcelFile()}
          >
            <img
              style={{ verticalAlign: "baseline" }}
              src={DownloadFile}
              height={25}
            ></img>
          </button>
          <div style={{ marginLeft: "5px" }}>
            <IntlMessages id="jobCard.confirmed.download" />
          </div>
        </div>
      </div>
      // <Fragment>
      //     <button
      //         className='print-icon-container pointer'
      //         onClick={() => {
      //             this.props.showRootSpinner();
      //             this.props.excelDownloadConfirmed(
      //                positionId,
      //                type
      //             );
      //         }}

      //     >
      //         <img style={{verticalAlign: 'baseline'}} src={DownloadFile} height={25}></img>
      //     </button>
      // </Fragment>
    );
  }
}

const mapStateToProps = ({ pdfReducer }) => ({ ...pdfReducer });

const mapActionToProps = {
  excelDownloadApplied,
  excelDownloadConfirmed,
  showRootSpinner,
};

export default connect(null, mapActionToProps)(DownloadExcel);

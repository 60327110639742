/**
 * Location action
 */
import {
  GET_LOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  EDIT_LOCATION,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE,
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  REMOVE_LOCATION,
  REMOVE_FAILURE,
  CONFIRM_ERROR_DELETE_POPUP,
  LOAD_MAP,
  INIT_STATE_LOCATION,
  REMOVE_SUCCESS
} from './types';


export const getLocation = () => ({
  type: GET_LOCATION,
});

export const getLocationSuccess = response => ({
  type: GET_LOCATION_SUCCESS,
  payload: response,
});


export const getLocationFailure = error => ({
  type: GET_LOCATION_FAILURE,
  payload: error,
});

export const addLocation = (newLocation, history) => ({
  type: ADD_LOCATION,
  payload: { newLocation, history },
});

export const addLocationSuccess = () => ({
  type: ADD_LOCATION_SUCCESS,
});

export const addLocationFailure = error => ({
  type: ADD_LOCATION_FAILURE,
  payload: error,
});

export const editLocation = (newLocation, history) => ({
  type: EDIT_LOCATION,
  payload: { newLocation, history },
});

export const editLocationSuccess = () => ({
  type: EDIT_LOCATION_SUCCESS,
});

export const editLocationFailure = error => ({
  type: EDIT_LOCATION_FAILURE,
  payload: error,
});

export const removeLocation = (id, history) => ({
  type: REMOVE_LOCATION,
  payload: { id, history },
});

export const removeFailure = error => ({
  type: REMOVE_FAILURE,
  payload: error,
});

export const removeSuccess = () => ({
  type: REMOVE_SUCCESS
});

export const confirmErrorPopup = () => ({
  type: CONFIRM_ERROR_DELETE_POPUP,
});

export const LoadMap = () => ({
  type: LOAD_MAP,
});

export const initStateLocation = () => ({
    type: INIT_STATE_LOCATION
});

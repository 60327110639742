/**
 * Forgot Password Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { isBadRequest } from 'Helpers/ResponseStatus';

import { SEND_FORGOT_PASSWORD } from "Actions/types";

import { sendForgotPasswordSuccess, sendForgotPasswordFailure, showErrorResponse } from "Actions";

import { sendForgotPassword } from "Services/employers/ForgotPasswordService";

/**
 * start send forgot password
 */
function* startSendForgotPassword({ email }) {
  try {
    const response = yield call(sendForgotPassword, email);
    const isVerified = !isBadRequest(response);
    if (!isVerified) {
      yield put(sendForgotPasswordFailure("loginEmailVerifyFailed"));
    } else {
      yield put(sendForgotPasswordSuccess("signUp.message.success"));
    }
  } catch (error) {
    yield put(sendForgotPasswordFailure(error));
    yield put(showErrorResponse(error));
  }
}

/**
 * send forgot password
 */
export function* takeSendForgotPassword() {
  yield takeEvery(SEND_FORGOT_PASSWORD, startSendForgotPassword);
}

/**
 * Jobs Root Saga
 */
export default function* rootSaga() {
  yield all([fork(takeSendForgotPassword)]);
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import {
  GET_LIST_PROVIDER_REQUEST
} from 'Actions/types';
import {
  getListProviderSuccess,
  getListProviderFailure
} from 'Actions';
import {
  getListProviderRequest as getListProviderService
} from 'Services/employers/ProviderServices';

/**
 * handle get list provider
 */
function* handleGetListProvider({ payload }) {
  try {
    const response = yield call(getListProviderService, payload);

    if (response.data && isSuccess(response)) {
      yield put(getListProviderSuccess(response.data.data));
    } else {
      yield put(getListProviderFailure(response));
    }
  } catch (error) {
    yield put(getListProviderFailure(error));
  }

}
/**
 * get list provider
 */

export function* watchListProvider() {
  yield takeEvery(GET_LIST_PROVIDER_REQUEST, handleGetListProvider);
}

export default function* rootSaga() {
  yield all([
    fork(watchListProvider)
  ]);
}
// sidebar nav links
export default {
  mainMenu: [
    {
      menu_title: "sidebar.dashboard",
      menu_icon: "gig-icon smaller icon-dashboard",
      menu_active_icon: "gig-icon smaller icon-dashboard-white",
      path: "/app/calendar/weekly",
      child_routes: null,
    },
    {
      menu_title: "sidebar.myGigs",
      menu_icon: "gig-icon smaller icon-mygigs",
      menu_active_icon: "gig-icon smaller icon-mygigs-white",
      path: "/app/my-gigs/",
      child_routes: [
        {
          path: "/app/my-gigs/upcoming",
          menu_title: "sidebar.upcoming",
        },
        {
          path: "/app/my-gigs/pending",
          menu_title: "sidebar.myGigs.pending",
          sub_routes: [
            {
              path: "/app/my-gigs/calendar-pending",
            },
          ],
        },
        {
          path: "/app/my-gigs/completed",
          menu_title: "sidebar.completed",
        },
      ],
    },
    {
      menu_title: "sidebar.timesheets",
      menu_icon: "gig-icon smaller icon-timesheet",
      menu_active_icon: "gig-icon smaller icon-timesheet-white",
      path: "/app/timesheets/",
      child_routes: [
        {
          path: "/app/timesheets/pending/weekly",
          menu_title: "sidebar.pending",
        },
        {
          path: "/app/timesheets/approved",
          menu_title: "sidebar.approved",
        },
      ],
    },
    {
      menu_title: "sidebar.reports",
      menu_icon: "gig-icon smaller icon-report",
      menu_active_icon: "gig-icon smaller icon-report-white",
      path: "/app/reports",
      child_routes: null,
    },
    {
      menu_title: "sidebar.locations",
      menu_icon: "gig-icon smaller icon-location",
      menu_active_icon: "gig-icon smaller icon-location-white",
      path: "/app/locations",
      child_routes: null,
    },
    // {
    //   menu_title: "sidebar.billing",
    //   menu_icon: "gig-icon smaller icon-billing",
    //   menu_active_icon: "gig-icon smaller icon-billing-white",
    //   path: "/app/billing/invoicing",
    //   child_routes: null,
    // },
    {
      menu_title: "sidebar.users",
      menu_icon: "gig-icon smaller icon-users",
      menu_active_icon: "gig-icon smaller icon-users-white",
      path: "/app/users",
      child_routes: null,
    },
  ],
};

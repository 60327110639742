import API from 'Api';

/*
 * Get list of uniform from API
 *
 * @return async
 */
export const getUniformsRequest = async () =>
    await API.get('/employers/uniforms')
        .then(response => response)
        .catch(error => error);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { MuiThemeProvider, Modal } from '@material-ui/core';
import moment from 'moment';

import StoredData from 'Constants/StoredData';
import ModalAllJobsTheme from 'Components/ThemeOptions/CustomMaterial/ModalAllJobsTheme';
import Spinner from 'Components/Model/Spinner';
import FilterJob from 'Components/Model/UpcomingJob/FilterJob';
import TableHeader from 'Components/Model/UpcomingJob/TableHeader';
import NoResult from 'Components/Model/UpcomingJob/NoResult';
import Paginator from 'Components/Paginator';
import JobItem from 'Routes/dashboard/upcoming-job/JobItem';

import { validNumberOrZero } from 'Helpers/helpers';

import {
    closeModalAllJobs,
    getAllJobs,
    getCandidatesByPosition,
    getAssignmentsByPosition,
    showNudgeSeekerModal,
    showAddSeekerModal,
    openMessageModal,
    openRepostJob,
    openEditJob,
    cancelJob,
    getVacancyLocations,
    getInitJob
} from 'Actions';

import {
    logInAsRequest,
    logInAsChosenProviderRequest,
} from 'Services/omni/EmployerService';
import MyGig from 'Constants/MyGig';

const DefaultFilterHour = 48;
let start_time  = moment().unix();
let end_time = start_time + DefaultFilterHour * 3600;

let FilterData = {
    page: 1,
    status: MyGig.jobType.POSTED,
    start: start_time,
    end: end_time
};

class UpcomingJob extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenModal && nextProps.isOpenModal !== this.props.isOpenModal) {
            const { start, end } = this.getDefaultFilterTimestampRange()
            if (!this.props.initListJob) {
                this.props.getAllJobs(MyGig.jobType.POSTED, start, end);
                if (!this.props.vacancyLocations.length) {
                    this.props.getVacancyLocations();
                }
                this.props.getInitJob();
            }

        }
    }

    callLogInAsRequest = async (email, callback) => {
        StoredData.set(StoredData.KEYS.LOGGED_IN_AS_UNIVERSE, email);
        await logInAsRequest({ email });
        callback();
    }

    getDefaultFilterTimestampRange() {
        const start = moment().unix();
        const end = start + DefaultFilterHour * 3600;

        return { start, end };
    }

    onFilter = (status, start, end, location) => {
        FilterData = {
            status,
            start,
            end,
            location,
            page: 1 // Reset pagination
        };
        this.props.getAllJobs(status, start, end, location);
    }

    onPaginate = (page) => {
        const { status, start, end, location } = FilterData;
        FilterData = { ...FilterData, page };
        this.props.getAllJobs(status, start, end, location, page);
    }

    closeModal = async () => {
        const email = StoredData.get(StoredData.KEYS.LOGGED_IN_AS);

        if (email) {
            await logInAsRequest({ email });
        }

        this.props.closeModalAllJobs();
    }

    renderPageTitle() {
        return (
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2><FormattedMessage id="modalAllJobs.title" /></h2>
                </div>
            </div>
        );
    }

    renderHeader() {
        const { vacancyLocations } = this.props;        
        return (
            <div className="rct-block-title">
                <div className="wrapper">
                    <FilterJob vacancyLocations={vacancyLocations} onFilter={this.onFilter} />

                    <div className="close-icon-wrapper">
                        <i
                            className="zmdi zmdi-close-circle black font-2x pull-right on-hover"
                            onClick={this.closeModal}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderTableData() {
        const { allJobs, onLoading } = this.props;

        return (
            <Fragment>
                <TableHeader />

                {allJobs.length > 0 && allJobs.map((job, index) => (
                    <JobItem
                        hideNudge
                        hideMessage
                        hideEdit
                        hideRepost
                        handleBeforeStandardJobAction={
                            (callback) => this.callLogInAsRequest(job.user_email, callback)
                        }
                        job={job}
                        key={index}
                        status={FilterData.status}
                        {...this.props}
                    />
                ))}

                {allJobs.length === 0 && !onLoading && (
                    <NoResult />
                )}
            </Fragment>
        );
    }

    renderPaginator() {
        const { allJobs, pagination } = this.props;
        const totalPages = validNumberOrZero(pagination.totalPages);

        if (totalPages > 1 && allJobs.length > 0) {
            return (
                <Paginator
                    totalPage={totalPages}
                    currentPage={FilterData.page}
                    position={Paginator.POSITION.RIGHT}
                    showPrevButton
                    showNextButton
                    onChange={this.onPaginate}
                />
            );
        }

        return null;
    }

    render() {
        const { onLoading } = this.props;

        return (
            <Fragment>
                <SwipeableDrawer
                    anchor={"right"}
                    open={this.props.isOpenModal}
                    onOpen={() => false}
                    onClose={this.closeModal}
                    className="MuiDrawer-paper-universe"
                >
                    <div className="drawer-wrapper">
                        <Spinner show={onLoading} />

                        <div className="modal-all-jobs-container">
                            {this.renderPageTitle()}

                            <div className="panel-collapsible">
                                <div className="rct-block">
                                    {this.renderHeader()}
                                </div>
                            </div>

                            <div className="table-responsive all-jobs-table upcoming-jobs-table" style={{ borderRadius: 0 }}>
                                {this.renderTableData()}
                            </div>

                            {this.renderPaginator()}
                        </div>
                    </div>
                </SwipeableDrawer>
            </Fragment>
        );
    }
};

const mapStateToProps = ({ modalAllJobsReducer, positionReducer, vacancyReducer, nudgeSeekerReducer, addSeekerReducer }) => ({
    ...modalAllJobsReducer,
    loadingCandidates: positionReducer.loadingPosted,
    loadingAssignments: positionReducer.loadingApplied,
    candidates: positionReducer.candidates,
    assignments: positionReducer.assignments,
    nudgeSeekerPositionId: nudgeSeekerReducer.currentPositionId,
    addSeekerPositionId: addSeekerReducer.currentPositionId,
    vacancyLocations: vacancyReducer.vacancyLocations
});

const mapActionToProps = {
    closeModalAllJobs,
    getAllJobs,
    getCandidatesByPosition,
    getAssignmentsByPosition,
    showNudgeSeekerModal,
    showAddSeekerModal,
    openMessageModal,
    openRepostJob,
    openEditJob,
    cancelJob,
    getVacancyLocations,
    getInitJob
};

export default connect(mapStateToProps, mapActionToProps)(UpcomingJob);

import API from 'Api';

/**
 * Get list of skills from API
 *
 * @param {Boolean=} onEditJob - optional
 * @return async
 */
export const getAllSkillRequest = async (onEditJob) => {
    const params = {
        editJob: Number(!!onEditJob)
    };

    return await API.get('/employer/skills', params)
        .then(response => response)
        .catch(err => err);
};

/**
 * Get list of skills by group from API
 *
 * @return async
 */
export const getAllSkillsByGroupRequest = () =>
    API.get('/employer/getChargeRateBySkills')
        .then(response => response)
        .catch(err => err);


export const getSkillBySkillTypeRequest = (params) =>
    API.post('/employees/getSkillBySkillType',params)
        .then(response => response)
        .catch(err => err)
/**
 * Skills Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import {
    SEND_SKILLS_CHARGE_RATE,
    GET_ALL_SKILLS,
    GET_ALL_SKILLS_BY_GROUP,
    GET_MASTER_RATE,
    UPDATE_MASTER_RATE,
    GET_SKILL_BY_SKILL_TYPE
} from 'Actions/types';

import {
    showErrorResponse,
    sendSkillsChargeRateSuccess,
    sendSkillsChargeRateFail,
    getAllSkillsByGroupSuccess,
    getAllSkillsByGroupFail,
    getSkillsSuccess,
    getSkillsFailure,
    getMasterRateSuccess,
    getMasterRateFailure,
    updateMasterRateSuccess,
    updateMasterRateFail,
    getSkillBySkillTypeSuccess
} from 'Actions';

import {
    getAllSkillRequest,
    getAllSkillsByGroupRequest,
    getSkillBySkillTypeRequest
} from "Services/employers/SkillService";

import {
    getMasterRateRequest,
    editMasterRateRequest
} from 'Services/employers/EmployerMasterRateService';

import { postSkillsChargeRate } from "Services/omni/ChargeRateService";

/**
 * Get Skills From Server
 */
function* handleGetAllSkills({ payload }) {
    try {
        const { onEditJob } = payload;
        const response = yield call(getAllSkillRequest, onEditJob);

        if (response.data && isSuccess(response)) {
            yield put(getSkillsSuccess(response.data.data));
        } else {
            yield put(getSkillsFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getSkillsFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Get Skills By Group From Server
 */
function* handleGetAllSkillsByGroup() {
    try {
        const response = yield call(getAllSkillsByGroupRequest);

        if (response.data && isSuccess(response)) {
            yield put(getAllSkillsByGroupSuccess(response.data.data));
        } else {
            yield put(getAllSkillsByGroupFail(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getAllSkillsByGroupFail(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Post skills charge rate to server
 */
function* handlePostSkillsChargeRate({ payload }) {
    const {skillsChargeRate} = payload
    try {
        const response = yield call(postSkillsChargeRate, skillsChargeRate);
        if (response.data && isSuccess(response)) {
            yield put(sendSkillsChargeRateSuccess(payload));
        } else {
            yield put(sendSkillsChargeRateFail(response));
        }
    } catch (error) {
        yield put(sendSkillsChargeRateFail(error));
    }
}

function* handleGetMasterRate({ payload }) {
    try {
        const response = yield call(getMasterRateRequest, payload);

        if (response.data && isSuccess(response)) {
            yield put(getMasterRateSuccess(response.data.data));
        }
        else {
            yield put(getMasterRateFailure(response));
            yield put(showErrorResponse(response));
        }
    } catch (error) {
        yield put(getMasterRateSuccess(error));
        yield put(showErrorResponse(error));
    }
}

function* handleEditMasterRate({ payload }) {
    try {
        const response = yield call(editMasterRateRequest, payload);

        if (response.data && isSuccess(response)) {
            yield put(updateMasterRateSuccess());
        }
        else {
            yield put(updateMasterRateFail(response));
        }
    } catch (error) {
        yield put(updateMasterRateFail(error));
    }
}

function * handleGetSkillBySkillType({payload}) {
    try{
        const { type_id } = payload; 
        const response = yield call(getSkillBySkillTypeRequest, {type_id: type_id});
        if(isSuccess(response)) {
            yield put(getSkillBySkillTypeSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Get All Skill
 */
export function* watchAllSkills() {
    yield takeEvery(GET_ALL_SKILLS, handleGetAllSkills);
}

export function* watchAllSkillsByGroup() {
    yield takeEvery(GET_ALL_SKILLS_BY_GROUP, handleGetAllSkillsByGroup);
}

export function* watchGetMasterRate() {
    yield takeEvery(GET_MASTER_RATE, handleGetMasterRate);
}

export function* watchEditMasterRate() {
    yield takeEvery(UPDATE_MASTER_RATE, handleEditMasterRate);
}

export function* watchPostSkillsChargeRate() {
    yield takeEvery(SEND_SKILLS_CHARGE_RATE, handlePostSkillsChargeRate);
}

export function* watchGetSkillBySkillType() {
    yield takeEvery(GET_SKILL_BY_SKILL_TYPE, handleGetSkillBySkillType)
}

/**
 * Skill Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchAllSkills),
        fork(watchAllSkillsByGroup),
        fork(watchPostSkillsChargeRate),
        fork(watchGetMasterRate),
        fork(watchEditMasterRate),
        fork(watchGetSkillBySkillType)
    ]);
}

/**
* Main App
*/

// import '../ReactTotronConfig'
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import MomentUtils from '@date-io/moment';

// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { hot } from 'react-hot-loader';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';

// pick a date util library
import MomentUtils from '@date-io/moment';
// css
import './lib/reactifyCss';

// app component
import App from './container/App';

import { configureStore } from './store';

const MainApp = () => (
	<Provider store={configureStore()}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Router>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</Router>
		</MuiPickersUtilsProvider>
	</Provider>

);

export default hot(module)(MainApp);

/**
 * Card Reducer
 */
import {
    CARD_CHECK_VALID,
    CARD_CHECK_VALID_SUCCESS,
    CARD_CHECK_VALID_FAILURE,
    CREATE_INVALID_CARD,
    GET_CARD_FAILURE, CARD_UPDATE, CARD_CREATE,
    GET_CARD,
    UPDATE_CARD_SUCCESS,
    ADD_CARD_INIT,
    CREATE_CARD_SUCCESS,
    DONE_CARD_INIT,
    EXPORT_PDF,
    SEND_INVOICE_MAIL,
} from 'Actions/types';

// initial state
const INIT_STATE = {
    onLoading: false,
    cardData: null,
    isCardValid: null,
    errorCode: null,
    success: false,
    initCard: false,
    firstTimeLoadCard: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CARD_CHECK_VALID:
            return { ...state, onLoading: true };

        case CARD_CHECK_VALID_SUCCESS:
            return {
                ...state,
                cardData: action.payload.cardData,
                isCardValid: action.payload.isValid,
                onLoading: false,
                initCard: true,
                firstTimeLoadCard: true
            };

        case GET_CARD:
            return { ...state, errorCode: null, onLoading: true, success: false };

        case CARD_UPDATE:
            return { ...state, errorCode: null, onLoading: true, success: false };

        case DONE_CARD_INIT:
            return { ...state, initCard: false };

        case CARD_CREATE:
            return { ...state, errorCode: null, onLoading: true };

        case EXPORT_PDF:
            return { ...state, onLoading: true };

        case SEND_INVOICE_MAIL:
            return { ...state, onLoading: true };

        case CARD_CHECK_VALID_FAILURE:
            return { ...state };

        case GET_CARD_FAILURE:
            return { ...state, errorCode: action.payload,  onLoading: false,  initCard: true,
                firstTimeLoadCard: true};

        case CREATE_INVALID_CARD:
            return { ...state, errorCode: action.payload, onLoading: false };

        case CREATE_CARD_SUCCESS:
            return { 
                ...state, 
                errorCode: null, 
                success: true, 
                onLoading:false, 
                createFlag: true 
            };

        case UPDATE_CARD_SUCCESS:
            return { 
                ...state, 
                errorCode: null, 
                success: true, 
                onLoading:false, 
                createFlag: false 
            };
            
        case ADD_CARD_INIT:
            return { 
                ...state, 
                errorCode: null, 
                success: false, 
                onLoading: false 
            };

        default: return { ...state };
    }
};

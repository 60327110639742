/**
 * Nudge seeker Reducer
 */
import {
    NUDGE_SEEKER_SHOW_MODAL,
    NUDGE_SEEKER_HIDE_MODAL,
    NUDGE_SEEKER_GET_NUDGABLE_LIST,
    NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS,
    NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE,
    NUDGE_SEEKER_FOR_POSITION,
    NUDGE_SEEKER_FOR_POSITION_SUCCESS,
    NUDGE_SEEKER_FOR_POSITION_FAILURE,
    NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE,
    NUDGE_SEEKER_HIDE_FAILURE_POPUP,
    ADD_SEEKER_OVER_18,
    NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS
} from 'Actions/types';
import MyGig from 'Constants/MyGig';
import { validNumberOrZero } from 'Helpers/helpers';

// initial state
const INIT_STATE = {
    showNudgeSeeker  : false,
    nudgableSeekers  : [],
    nextPageAvailable: true,
    onLoading        : false,
    onLoadNextPage   : false,
    onNudging        : false,
    nudgeSuccess     : false,
    nudgeFailure     : false,
    nudgeUnavailable : false,
    nudgeGotExceeded : false,
    addSeekerForOver18: false,
    currentPositionId: null,
    nudgeFailure48h: false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NUDGE_SEEKER_SHOW_MODAL:
            return {
                ...state,
                showNudgeSeeker: true,
                currentPositionId: action.payload
            };

        case NUDGE_SEEKER_HIDE_MODAL:
            return {
                ...state,
                showNudgeSeeker: false,
                currentPositionId: null,
                nudgableSeekers: []
            };

        case NUDGE_SEEKER_GET_NUDGABLE_LIST:
            let { page } = action.payload;
            page       = validNumberOrZero(page);

            return {
                ...state,
                onLoading     : page <= 1,
                onLoadNextPage: page > 1,
                nudgeSuccess  : false,
                nudgeFailure  : false
            };

        case NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS:
            let nudgableSeekers   = action.payload;
            const nextPageAvailable = nudgableSeekers.length > 0;

            if (state.onLoadNextPage === true) {
                nudgableSeekers = state.nudgableSeekers.concat(nudgableSeekers);
            }

            return {
                ...state,
                nudgableSeekers,
                nextPageAvailable,
                onLoading     : false,
                onLoadNextPage: false
            };

        case NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE:
            return {...state, onLoading: false, onLoadNextPage: false};

        case NUDGE_SEEKER_FOR_POSITION:
            return {...state, onNudging: true};

        case NUDGE_SEEKER_FOR_POSITION_SUCCESS:
            return {...state, onNudging: false, nudgeSuccess: true};

        case NUDGE_SEEKER_FOR_POSITION_FAILURE:
            const {message} = action.payload
            const isExceed = message == MyGig.EXCEED_STUDENT_ALERT
            return {...state, onNudging: false, nudgeFailure: true, nudgeGotExceeded: isExceed};

        case NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE:
            return {...state, onNudging: false, nudgeUnavailable: true};

        case ADD_SEEKER_OVER_18:
            return {...state, onNudging: false, addSeekerForOver18: true}
        case NUDGE_SEEKER_HIDE_FAILURE_POPUP:
            return {...state, nudgeFailure: false, nudgeUnavailable: false, nudgeGotExceeded: false , addSeekerForOver18: false, nudgeFailure48h: false};
        case NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS:
              return { ...state, onNudging: false, nudgeFailure48h: true};
        default: return {...state};
    }
};

import API from 'Api';

/**
 * Call API to calculate charge rate, fees and costs from base pay rate
 * @param {Number} payRate
 * @return async
 */
export const getChargeRateByPayRate = async (payRate) =>
    await API.get('/employers/gigbasicemploymentcosts', {
            payrate: payRate
        })
        .then(response => response)
        .catch(error => error);

/**
 * Get status of instant payment of provider
 * Response data:
 * 	  0: No instant payment
 * 	  1: Must be check card before go to next step (create job/timecard, approve timesheet)
 *
 * @return async
 */
export const getWagesInstantPayment = async () =>
    await API.get('/employers/haswagesinstantpayment')
        .then(response => response)
        .catch(error => error);

/**
 * Block Actions
 */
import {
    BLOCK_EMPLOYEE_REQUEST,
    BLOCK_EMPLOYEE_SUCCESS,
    BLOCK_EMPLOYEE_FAILURE,

    UNBLOCK_EMPLOYEE_REQUEST,
    UNBLOCK_EMPLOYEE_SUCCESS,
    UNBLOCK_EMPLOYEE_FAILURE
} from './types';


/**
 * Block employee
 */
export const blockEmployee = (employeeId, successCallback, errorCallback) => ({
    type: BLOCK_EMPLOYEE_REQUEST,
    payload: { employeeId, successCallback, errorCallback }
});

/**
 * Block employee success
 */
export const blockEmployeeSuccess = (response) => ({
    type: BLOCK_EMPLOYEE_SUCCESS,
    payload: response.data
});

/**
 * Block employee failure
 */
export const blockEmployeeFailure = (error) => ({
    type: BLOCK_EMPLOYEE_FAILURE,
    payload: error
});

/**
 * Unblock employee
 */
export const unblockEmployee = (employeeId, successCallback, errorCallback) => ({
    type: UNBLOCK_EMPLOYEE_REQUEST,
    payload: { employeeId, successCallback, errorCallback }
});

/**
 * Unblock employee success
 */
export const unblockEmployeeSuccess = (response) => ({
    type: UNBLOCK_EMPLOYEE_SUCCESS,
    payload: response.data
});

/**
 * Unblock employee failure
 */
export const unblockEmployeeFailure = (error) => ({
    type: UNBLOCK_EMPLOYEE_FAILURE,
    payload: error
});

import React, {Component} from 'react';

import {DatePicker}       from '@material-ui/pickers';
import {MuiThemeProvider} from '@material-ui/core';
import DatepickerOrange   from 'Components/ThemeOptions/CustomMaterial/DatepickerOrange';

import moment from 'moment';
import $      from 'jquery';

class MonthlyYearPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yearSelected: moment().year()
        };

    }

    changeCurrentYear(year) {
        this.setState({yearSelected: year});
        this.props.onChange(year);
    }

    validYearOrCurrentYear(year) {
        return isNaN(year)
            ? moment().year()
            : year;
    }

    onOpenPicker() {
        const yearSelectors = '[class*="MuiPickersYear-root-"]';
        const self = this;

        $(document)
            .unbind()
            .on('click', yearSelectors, function() {
                const year = Number($(this).text());

                $('[class*="MuiBackdrop-root-"]').click();
                self.changeCurrentYear(self.validYearOrCurrentYear(year));
            });
    }

    render() {
        return (
            <MuiThemeProvider theme={DatepickerOrange}>
                <div className="rct-picker">
                    <DatePicker
                        views={["year"]}
                        // ref={ref => this.datepickerRef = ref}
                        label=""
                        value={moment().year(this.state.yearSelected)}
                        className="gig-picker"
                        format="[Jan-Dec] YYYY"
                        // openToYearSelection
                        onChange={dateTime => null}
                        autoOk
                        onOpen={() => this.onOpenPicker()}
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />} />
                </div>
            </MuiThemeProvider>
        );
    }
}

export default MonthlyYearPicker;

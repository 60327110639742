/**
 * Timesheet Approved Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';
import { SPLIT_SHIFTS_DO_SPLIT } from 'Actions/types';
import MyGig from "Constants/MyGig";

import {
    getUpcomingJobs,
    getFilledJobs,
    getPostedJobs,
    getPendingJobs,
    splitShiftsSuccess,
    splitShiftsFailure,
    showRootSpinner,
    hideRootSpinner
} from 'Actions';

import { splitShiftsOfPositionRequest } from "Services/employers/PositionSplitService";

/**
 * Call split shifts API
 */
function* handleSplitShiftsOfPosition({payload}) {
    yield put(showRootSpinner('splitShifts.onSplitting'));

    try {
        const {positionId, jobType} = payload;
        const response = yield call(splitShiftsOfPositionRequest, positionId, jobType);

        if (response.data && isSuccess(response)) {
            const postedPage = 1;
            yield put(hideRootSpinner());
            yield put(splitShiftsSuccess(response.data.data));
            if (jobType && jobType === MyGig.jobType.FILLED) {
                yield put(getFilledJobs(postedPage));
            } else if (jobType && jobType === MyGig.jobType.PENDING) {
                yield put(getPendingJobs(postedPage));
            } else {
                yield put(getUpcomingJobs());
            }
        } else {
            yield put(hideRootSpinner());
            yield put(splitShiftsFailure(response));
        }

    } catch (error) {
        yield put(hideRootSpinner());
        yield put(splitShiftsFailure(error));
    }
}

/**
 * Watch split shifts of position action
 */
export function* watchSplitShiftsOfPosition() {
    yield takeEvery(SPLIT_SHIFTS_DO_SPLIT, handleSplitShiftsOfPosition);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchSplitShiftsOfPosition)
    ]);
}

import API from 'Api';

export const getExcelPendingRemoteRequest = async(startDate, endDate, internal, locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        internal  : internal,
        locationId
    };
    if(!locationId) {
        delete params.locationId
    }

    return await API.get('/employers/excel/exportTimesheetPending', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelApprovedRemoteRequest = async(startDate, endDate, locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        locationId
    };
    if(!locationId) {
        delete params.locationId
    }

    return await API.get('/employers/excel/exportTimesheetApproved', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelWeeklyRemoteRequest = async(startDate, endDate) => {
    const params = {
        start: startDate,
        end : endDate,
        type: "Excel"
    };
   

    return await API.get('/employers/exportCalendarView', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelDailyUpcomingJobRequest = async(date, jobType) => {
    const params = {
        start: date,
        end : date,
        type: "Excel",
        jobType: jobType
    };
   

    return await API.get('/employers/exportUpcomingView', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelDailyPendingJobRequest = async(date) => {
    const params = {
        start: date,
        end : date,
        type: "Excel",
    };
   

    return await API.get('/employers/exportPendingJobsView', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelAppliedRequest = async(positionId, type) => {
    const params = {
        positionId: positionId,
        type  : type
    };
    if(!positionId) {
        delete params.positionId
    }

    return await API.get('/employers/excel/exportApplied', params, true)
        .then(response => response)
        .catch(error => error);
};

export const getExcelConfirmedRequest = async(positionId, type) => {
    const params = {
        positionId: positionId,
        type  : type
    };
    if(!positionId) {
        delete params.positionId
    }

    return await API.get('/employers/excel/exportConfirmed', params, true)
        .then(response => response)
        .catch(error => error);
};
/**
 * Confirm password Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { isSuccess, isExpectedError } from 'Helpers/ResponseStatus';
import StoredData            from 'Constants/StoredData';
import ConfirmPasswordHelper from 'Helpers/confirmpassword';

import {
    CONFIRM_PASSWORD_VERIFY_PASSWORD
} from 'Actions/types';

import {
    showErrorResponse,
    confirmPasswordSuccess,
    confirmPasswordFailure,
    confirmPasswordWrongPassword
} from 'Actions';

import {
    logInRequest
} from 'Services/auth/AuthenticateService';

function* handleConfirmPassword({payload}) {
    const { password } = payload;
    const username   = StoredData.get(StoredData.KEYS.EMPLOYER_EMAIL);

    try {
        const response = yield call(logInRequest, {username, password});

        if (response.data) {
            if (isSuccess(response)) {
                ConfirmPasswordHelper.storeConfirmedUnixTime();
                yield put(confirmPasswordSuccess(response.data.data));

            } else if (isExpectedError(response)) {
                yield put(confirmPasswordWrongPassword());

            } else {
                yield put(confirmPasswordFailure(response));
            }

        } else {
            yield put(confirmPasswordFailure(response));
        }
    } catch (error) {
        yield put(confirmPasswordWrongPassword());
        yield put(showErrorResponse(error));
    }
}

/**
 * Watch confirm password
 */
export function* watchConfirmPassword() {
    yield takeEvery(CONFIRM_PASSWORD_VERIFY_PASSWORD, handleConfirmPassword);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchConfirmPassword)
    ]);
}

/**
 * Card Reducer
 */
import {
    CREATE_NEW_EMPLOYER,
    CREATE_NEW_EMPLOYER_SUCCESS,
    CREATE_NEW_EMPLOYER_FAILURE,
    UPDATE_EMPLOYER,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_FAILURE,
    CLOSE_POPUP
} from 'Actions/types';

// initial state
const INIT_STATE = {
    loading: false,
    success: false,
    failure: false,
    message: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_NEW_EMPLOYER:
            return { ...state, loading: true };

        case CREATE_NEW_EMPLOYER_SUCCESS:
            return {
                ...state, message: action.payload.response.message, success: true, failure: false

            };

        case CREATE_NEW_EMPLOYER_FAILURE:
            return { ...state, message: action.payload.response.response.data.error.message, failure: true, success: false };


        case UPDATE_EMPLOYER:
            return { ...state, loading: true };

        case UPDATE_EMPLOYER_SUCCESS:
            return {
                ...state, message: action.payload.response.message, success: true, failure: false

            };

        case UPDATE_EMPLOYER_FAILURE:
            return { ...state, message: action.payload.response.response.data.error.message, failure: true, success: false };


        case CLOSE_POPUP:

            return { ...state, success: false, failure: false };

        default: return { ...state };
    }
};

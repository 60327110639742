/**
 * Language Provider Helper Component
 * Used to Display Localised Strings
 */
import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

// Injected message
const InjectMassage = props => <FormattedHTMLMessage {...props} />;


export default injectIntl(InjectMassage, {
    withRef: false
});



/**
 *  Seeker Signal Reducer
 */
import {
    GET_SEEKER_SIGNAL,
    GET_SEEKER_SIGNAL_SUCCESS,
    GET_SEEKER_SIGNAL_FAIL,
    CLEAR_LIST_SEEKER_SIGNAL
} from 'Actions/types';

import { validNumberOrZero } from 'Helpers/helpers';

// initial state
const INIT_STATE = {
    listSeeker: [],
    nextPageAvailable: true,
    onLoading: false,
    onLoadNextPage: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SEEKER_SIGNAL: {
            let { page } = action.payload;
            page = validNumberOrZero(page);

            return {
                ...state,
                onLoading: page <= 1,
                onLoadNextPage: page > 1,
            };
        }

        case GET_SEEKER_SIGNAL_SUCCESS: {
            let listSeeker = action.payload.seekers;
            const nextPageAvailable = listSeeker.length > 0;

            if (state.onLoadNextPage === true) {
                listSeeker = state.listSeeker.concat(listSeeker);
            }

            return {
                ...state,
                listSeeker,
                nextPageAvailable,
                onLoading: false,
                onLoadNextPage: false
            };
        }

        case GET_SEEKER_SIGNAL_FAIL:
            return { ...state, onLoading: false, onLoadNextPage: false };

        case CLEAR_LIST_SEEKER_SIGNAL:
            return { ...state, listSeeker: [] };

        default: return { ...state };
    }
};

import API from "Api";

export const getListSeekerRequest = async (params) =>
  await API.get("/admin/employees", params);

// export const getEmployeeDetailRequest = async  (employeeId) => await  API.get(`/admin/updateDocument`, {min: 999, max: 6000});

export const getEmployeeDetailRequest = async (employeeId) =>
  await API.get(`/admin/employees/${employeeId}`);

export const getInfoSeekerRequest = async (employeeId) =>
  await API.get(`/employers/seekerInfo/${employeeId}`);

export const updateEmployeeDetailRequest = async (employeeId, data) =>
  await API.put(`/admin/employees/${employeeId}`, data);

export const blockEmployeeDetailRequest = async (employeeId) =>
  await API.post(`/admin/blockEmployee/${employeeId}`);

export const unblockEmployeeDetailRequest = async (employeeId) =>
  await API.post(`/admin/unblockEmployee/${employeeId}`);

export const handleGetJobTypeRequest = async () =>
  await API.get(`/employee/getJobType`);

export const getEmployeeListJobApplyRequest = async (employeeId) =>
  await API.get(`/employees/getEmployeeListJobApply/${employeeId}`);

export const addNoteRequest = (body) => API.post("/employers/addNote", body);

export default {
    VALIDATION: {
        MIN_LENGTH: {
            DESCRIPTION: 100,
            SCHEDULE_TIME: 60
        }
    },
    UNIFORM_TYPES: {
        SHIRT: 1,
        JEANS: 2,
        SHOES: 3,
        BLACK_TIE:4,
        HIGH_VIS_VEST:5,
        HIGH_VIS_CAP:6,
        GLOVES: 7,
        BLAZER: 8
    },
    UNIFORM_IDS: {
        BLACK_TIE: 20,
        HIGH_VIS_VEST:21,
        HIGH_VIS_CAP:22,
        GLOVES:26,
        BLAZER:27,
        NOT_REQUIRED: 0
    },
    CREATING_PROCESS: {
        JOB     : 1,
        TIMECARD: 2
    }
};

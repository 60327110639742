/**
 * Omni Employer Reducer
 */

import {
    GET_LIST_PROVIDER_REQUEST, GET_LIST_PROVIDER_SUCCESS, GET_LIST_PROVIDER_FAILURE, UPDATE_LIST_PROVIDER_SUCCESS
} from 'Actions/types';

// initial state
const INIT_STATE = {
  listProvider: [],
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case GET_LIST_PROVIDER_REQUEST:
          return { ...state, loading: true };

      case GET_LIST_PROVIDER_SUCCESS:
          return {
            ...state,
            listProvider: action.payload,
            loading: false
          };

      case GET_LIST_PROVIDER_FAILURE:
          return {
            ...state,
            loading: false
          };

      case UPDATE_LIST_PROVIDER_SUCCESS:
          return {
              ...state, listProvider:    state.listProvider.map(provider => {
                      if (provider.employer_id === action.payload.params.employer_id) {
                          provider.fullname = action.payload.params.fullname;
                          provider.business_name = action.payload.params.name;
                          provider.business_phone = action.payload.params.business_phone;
                          provider.email = action.payload.params.email;
                      }
                      return provider;
                  }
              )
          }
      default: return { ...state};
  }
};

import { call, all, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import { GET_SEEKER_SIGNAL } from 'Actions/types';

import {
  showErrorResponse,
  getSeekerSignalSuccess,
  getSeekerSignalFail
} from 'Actions';

import { getSeekerSignalRequest } from 'Services/employers/SignalService';

function* handleGetSeekerSignal({ payload }) {
  const { page, skillId } = payload;
  try {
    const response = yield call(getSeekerSignalRequest, { page, skillId });
    if(response.data && isSuccess(response)) {
      yield put(getSeekerSignalSuccess(response.data.data));
    }
  } catch(error) {
    yield put(getSeekerSignalFail(error));
    yield put(showErrorResponse(error));
  }
}

export function* watchGetSeekerSignal() {
  yield takeEvery(GET_SEEKER_SIGNAL, handleGetSeekerSignal);
}

export default function* rootSaga() {
  yield all([
      fork(watchGetSeekerSignal),
  ]);
}

import API from "Api";

/**
 * Send forgot password email to employer
 *
 * @param  {String} email
 * @return async
 */
export const sendForgotPassword = async email => await API.post("/mywork/forgotpassword/email", { email }, false)
    .then(response => response)
    .catch(err => err);

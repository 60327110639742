import React, { Component } from "react";

import PushNotification from "PushNotification";
import StoredData from "Constants/StoredData";
import MyGig from "Constants/MyGig";
import IntlMessages from "Util/IntlMessages";
import ActionCodes from "Components/Notification/ActionCodes";
import RoutePaths from "Constants/RoutePaths";
import connect from "react-redux/es/connect/connect";
import { currentPage } from 'Helpers/RouterChecker';

import {
    getJobDetailsAfterClicked,
    hideNotificationPanel,
    showRootSpinner,
    updateNumberOfSeeker
} from "Actions";

const pushnotification = new PushNotification();

class PusherNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openNoti: false,
            card: {},
            notices: [],
        };
    }

    componentDidMount() {
        const instance = this;
        const { notices } = this.state;
        const { history } = this.props;
        const noitice = {};
        if (!window.jobResponseEventChannel) {
            pushnotification.withPusherConnection();
            pushnotification.subscribeJobResponseChanel(
                StoredData.get(StoredData.KEYS.LOGGED_IN_AS)
            );
        }

        window.jobResponseEventChannel.bind(MyGig.eventJobResponse, (data) => {
            noitice.message = data.message;
            noitice.actionCode = data.actionCode;
            noitice.actionParams = data.actionParams;
            noitice.openNoti = true;

            notices.push(noitice);
            this.props.updateNumberOfSeeker({
                jobPositionId: noitice.actionParams.positionId,
                typeOfAction: MyGig.typeOfAction.appliedSuccess,
                currentPage: currentPage(history.location)
            })

            instance.setState({
                noitices: notices,
            });

            setTimeout(() => {
                notices.shift();
                instance.setState({
                    notices,
                });
            }, MyGig.timeOut);
        });

        // Looking the event `RemoteRequestEvent`
        // Actions should be shown notifications: Check-out Remote Request
        window.jobResponseEventChannel.bind(
            MyGig.eventRemoteRequest,
            (data) => {
                if (!!data && !!data.message) {
                    noitice.message = data.message;
                    noitice.actionCode = data.actionCode;
                    noitice.actionParams = data.actionParams;
                    noitice.openNoti = true;
                    notices.push(noitice);

                    instance.setState({
                        noticeMessage: notices,
                    });

                    setTimeout(() => {
                        notices.shift();
                        instance.setState({
                            notices,
                        });
                    }, MyGig.timeOut);
                }
            }
        );
    }

    redirectToJobPage({ positionId }) {
        this.props.showRootSpinner("notification.onRedirecting");
        this.props.getJobDetailsAfterClicked(positionId, this.props.history);
    }

    handleNotificationActions(notificationObject) {
        const { actionCode, actionParams } = notificationObject;
        const { history } = this.props;
        switch (actionCode) {
            case ActionCodes.REMOTE_REQUEST_RECEIVED:
                history.push(RoutePaths.TIMESHEET.PENDING_WEEKLY);
                break;

            case ActionCodes.JOB_RESPONSE_RECEIVED:
                this.redirectToJobPage(actionParams);
                break;

            case ActionCodes.JOB_NUDGE_DECLINED:
                this.redirectToJobPage(actionParams);
                break;

            default:
                break;
        }

        this.props.hideNotificationPanel();
    }

    closeNotifi = (key) => {
        const {notices} = this.state;
        notices.splice(key,1);
        this.setState({
            notices
        });
    }

    render() {
        const { notices } = this.state;
        return (
            <div className={`notification-container`} style={{ top: "71px" }}>
                {notices.length > 0 &&
                    notices.map((notice, key) => (
                        <div style={{position:"relative"}}
                        >
                            <span>
                                <div
                                  onClick={() =>
                                    this.handleNotificationActions(notice)
                                }
                                    className="notification notification-info"
                                    style={{ background: "#f7b135" }}
                                >
                                    <div
                                        className="notification-message d-flex align-items-center"
                                        role="alert"
                                    >
                                        <h4
                                            className="title m-0"
                                            style={{
                                                color: "black",
                                                fontFamily: "museosans-500",
                                            }}
                                          
                                        >
                                            {" "}
                                            <IntlMessages id="notification" />{" "}
                                           
                                        </h4>
                                      
                                    </div>
                                </div>
                            </span>
                            <div 
                                onClick={() =>
                                    this.handleNotificationActions(notice)
                                }
                                className="noitice-background pointer thinner p-10 text-black"
                                style={{ fontFamily: "museosans-300" }}
                                dangerouslySetInnerHTML={{
                                    __html: notice.message,
                                }}
                            />
                             <div  style={{ position: "absolute", right:"2px" ,top:"0px" , cursor:"pointer" , padding: "4px 10px"}}>
                             <i
                                                className="zmdi zmdi-close "
                                                onClick={ () => this.closeNotifi(key)}
                                            />
                                 </div>
                        </div>
                        
                    ))}
            </div>
        );
    }
}

const mapStateToProps = ({ notificationReducer }) => notificationReducer;

const mapActionToProps = {
    showRootSpinner,
    hideNotificationPanel,
    getJobDetailsAfterClicked,
    updateNumberOfSeeker
};

export default connect(mapStateToProps, mapActionToProps)(PusherNotification);

import API from "Api";

/**
 * check employer exist
 *
 * @param  {String} email - check email is exist
 * @return async
 */
export const checkStaffExist = async (email) =>
  await API
    .post("/employers/staff/exists", { email }, false)
    .then(response => response)
    .catch(err => err);

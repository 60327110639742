import React, { Component } from 'react';
import {FormGroup, InputGroup, Input, Row, Col} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from '@material-ui/core/Button';
import ConversationModel from 'Model/ConversationModel';
import connect from 'react-redux/es/connect/connect';
import { createConversation} from "Actions";
import CircularProgress from "@material-ui/core/CircularProgress";

class CreateConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            subject: '',
            message: '',
            formErrors: {}
        };
    }

    handleSubmit() {
        const { fullName, subject, message } = this.state;
        const { userId } = this.props;

        const formErrors = ConversationModel.validateFields(this.state);
        if(Object.keys(formErrors).length > 0) {
            this.setState({formErrors});
        } else {
            const params = {
                message,
                title: subject,
                name: fullName,
                user_id: userId
            };
            this.props.createConversation(params);
        }

    }

    renderFields(value, placeholderKey, onChange, errorKey = null, type = 'text') {
        return (
            <FormGroup>
                <InputGroup>
                    <FormattedMessage id={placeholderKey}>
                        {(placeholder) => (
                            <Input
                                type={type}
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    </FormattedMessage>
                </InputGroup>

                {!!errorKey && (
                    <FormHelperText className="field-error">
                        <FormattedMessage id={errorKey} />
                    </FormHelperText>
                )}
            </FormGroup>
        );
    }

    render() {
        const { fullName, subject, message, formErrors } = this.state;
        const { loading } = this.props;

        return (
            <div className="create-conversation">
                <Row>
                    <Col sm={7}>
                        {this.renderFields(fullName, 'directMessage.placeholder.fullName', (e) => this.setState({ fullName: e.target.value }), formErrors.fullName)}
                        {this.renderFields(subject, 'directMessage.placeholder.subject', (e) => this.setState({ subject: e.target.value }), formErrors.subject)}
                        {this.renderFields(message, 'directMessage.placeholder.message', (e) => this.setState({ message: e.target.value }), formErrors.message, 'textarea')}

                        <Button className="gig-button ok btn-send-message" onClick={() => this.handleSubmit()}>
                            <FormattedMessage id="directMessage.button.send" />
                        </Button>
                    </Col>
                    { loading &&
                        <div className="d-flex justify-content-center loader-overlay">
                            <CircularProgress/>
                        </div>
                    }
                </Row>


            </div>
        );
    }
};

const mapStateToProps = ({ conversationReducer }) => ({
        loading: conversationReducer.loading
    });

const mapActionToProps = { createConversation };

export default connect(mapStateToProps, mapActionToProps) (CreateConversation);

/**
 * Timesheet Pending Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getTheDate, getIsoWeekRangeFromDate } from "Helpers/helpers";

import {
  GET_REMOTE_REQUEST_PENDING_LIST,
  GET_REMOTE_REQUEST_PENDING_DAILY_LIST,
  ACCEPT_REMOTE_REQUEST,
  ACCEPT_LIST_REMOTE_REQUESTS,
  DECLINE_REMOTE_REQUEST,
  DECLINE_LIST_REMOTE_REQUESTS,
  UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS,
} from "Actions/types";

import {
  showErrorResponse,
  getRemoteRequestsSuccess,
  getRemoteRequestsFailure,
  declineRemoteRequestSuccess,
  declineRemoteRequestFailure,
  acceptRemoteRequestsSuccess,
  acceptRemoteRequestsFailure,
  updateAsssignedScheduleTimeSheetsSuccess,
  updateAsssignedScheduleTimeSheetsFailure,
} from "Actions";

import {
  groupPendingRemoteRequestsByDate,
  pendingRemoteRequests,
  declineRemoteRequest,
  declineListOfRemoteRequests,
  updateScheduleOfRemoteRequest,
} from "Services/employers/RemoteRequestService";

import {
  approveRemoteRequest,
  approveListOfRemoteRequests,
  getShiftInfoByAssignedScheduleId,
} from "Services/employers/DisputeService";

import { isSuccess } from "Helpers/ResponseStatus";
import moment from "moment";

/**
 * Get pending remote request from server
 */
function* handleGetPendingRemoteRequests({ payload }) {
  const timezoneOffset = moment.tz.guess();
  try {
    if (payload.init) {
      const promise = new Promise((resolve, reject) => {
        groupPendingRemoteRequestsByDate().then((pendingGroupedResponse) => {
          let dateRange;
          let dateWithPendingRequest = [];

          if (
            pendingGroupedResponse.data &&
            pendingGroupedResponse.data.data.length > 0
          ) {
            dateWithPendingRequest = pendingGroupedResponse.data.data;
          }
          dateRange = getIsoWeekRangeFromDate(
            moment(),
            "YYYY-MM-DD",
            payload.startFromSunday
          );
          resolve({ dateRange, dateWithPendingRequest });
        });
      });
      const resolvePromise = yield Promise.all([promise]);
      const pendingListResponse = yield call(
        pendingRemoteRequests,
        resolvePromise[0].dateRange.start,
        resolvePromise[0].dateRange.end,
        timezoneOffset,
        payload.internal
      );

      if (pendingListResponse.data && isSuccess(pendingListResponse)) {
        yield put(
          getRemoteRequestsSuccess({
            requests: pendingListResponse.data.data,
            dataDate: resolvePromise[0].dateWithPendingRequest,
          })
        );
      } else {
        yield put(showErrorResponse(pendingListResponse));
      }
    } else {
      const { startDate, endDate, locationId, internal } = payload;
      const pendingListResponse = locationId
        ? yield call(
            pendingRemoteRequests,
            startDate,
            endDate,
            timezoneOffset,
            internal,
            locationId
          )
        : yield call(
            pendingRemoteRequests,
            startDate,
            endDate,
            timezoneOffset,
            internal
          );
      if (pendingListResponse.data && isSuccess(pendingListResponse)) {
        yield put(
          getRemoteRequestsSuccess({
            requests: pendingListResponse.data.data,
            dataDate: null,
          })
        );
      } else {
        yield put(showErrorResponse(pendingListResponse));
      }
    }
  } catch (error) {
    yield put(getRemoteRequestsFailure(error));
  }
}

function* handleUpdateScheduleOfRemoteRequest({ payload }) {
  try {
    const response = yield call(updateScheduleOfRemoteRequest, payload);
    if (response.data && isSuccess(response)) {
      yield put(updateAsssignedScheduleTimeSheetsSuccess(response));
    } else {
      yield put(showErrorResponse(response));
    }
  } catch (error) {
    yield put(updateAsssignedScheduleTimeSheetsFailure(error));
  }
}

/**
 * Get pending daily remote requests from server
 */
function* handleGetPendingDailyRemoteRequests({ payload }) {
  const timezoneOffset = moment.tz.guess();
  try {
    if (payload === null) {
      const promise = new Promise((resolve, reject) => {
        groupPendingRemoteRequestsByDate().then((pendingGroupedResponse) => {
          let date;
          let dateWithPendingRequest = [];

          if (
            pendingGroupedResponse.data &&
            pendingGroupedResponse.data.data.length > 0
          ) {
            dateWithPendingRequest = pendingGroupedResponse.data.data;
          }
          date = moment().subtract(1, "day").format("YYYY-MM-DD");
          resolve({ date, dateWithPendingRequest });
        });
      });
      const resolvePromise = yield Promise.all([promise]);
      const pendingListResponse = yield call(
        pendingRemoteRequests,
        resolvePromise[0].date,
        resolvePromise[0].date,
        timezoneOffset
      );

      if (pendingListResponse.data && isSuccess(pendingListResponse)) {
        yield put(
          getRemoteRequestsSuccess({
            requests: pendingListResponse.data.data,
            fetchedDate: resolvePromise[0].date,
            dataDate: resolvePromise[0].dateWithPendingRequest,
          })
        );
      } else {
        yield put(showErrorResponse(pendingListResponse));
      }
    } else {
      const { date, internal, locationId } = payload;
      const pendingListResponse = locationId
        ? yield call(
            pendingRemoteRequests,
            date,
            date,
            timezoneOffset,
            internal,
            locationId
          )
        : yield call(pendingRemoteRequests, date, date, timezoneOffset);

      if (pendingListResponse.data && isSuccess(pendingListResponse)) {
        yield put(
          getRemoteRequestsSuccess({
            requests: pendingListResponse.data.data,
            fetchedDate: date,
            dataDate: null,
          })
        );
      } else {
        yield put(showErrorResponse(pendingListResponse));
      }
    }
  } catch (error) {
    yield put(getRemoteRequestsFailure(error));
  }
}

function* handleDeclineRemoteRequest({ payload }) {
  try {
    const { remoteRequestId } = payload;
    const response = yield call(declineRemoteRequest, remoteRequestId);

    if (response.data && isSuccess(response)) {
      yield put(declineRemoteRequestSuccess(response));
    } else {
      yield put(showErrorResponse(response));
    }
  } catch (error) {
    yield put(declineRemoteRequestFailure(error));
  }
}

function* handleDeclineListOfRemoteRequests({ payload }) {
  try {
    const { listOfRequestIds } = payload;
    const response = yield call(declineListOfRemoteRequests, listOfRequestIds);

    if (response.data && isSuccess(response)) {
      yield put(declineRemoteRequestSuccess(response));
    } else {
      yield put(showErrorResponse(response));
    }
  } catch (error) {
    yield put(declineRemoteRequestFailure(error));
  }
}

function* handleAcceptRemoteRequest({ payload }) {
  const { request, callbackSuccess, callbackFailure } = payload;

  try {
    const promise = new Promise((resolve, reject) => {
      getShiftInfoByAssignedScheduleId(request.assignedScheduleId).then(
        (response) => {
          if (response.data && response.data.data) {
            response = response.data.data;
          } else {
            reject();
          }

          resolve({
            assignedScheduleId: request.assignedScheduleId,
            startTime: request.unixStart,
            endTime: request.unixEnd,
            startRemoteId: response.remoteId,
            endRemoteId: request.requestId,
            thumbsUp: request.isRated ? 1 : 0,
            employerComment: request.feedback,
            breakTime: Number(request.breakTime),
          });
        }
      );
    });

    const resolveRemoteData = yield Promise.all([promise]);
    const response = yield call(approveRemoteRequest, resolveRemoteData[0]);

    if (response.data) {
      if (isSuccess(response)) {
        yield put(acceptRemoteRequestsSuccess(true));
        callbackSuccess();
      } else {
        callbackFailure();
      }
    } else {
      callbackFailure();
    }
  } catch (error) {
    yield callbackFailure(error);
  }
}

function* handleAcceptListOfRemoteRequests({ payload }) {
  const {
    listOfRemoteRequests,
    callbackSuccess,
    callbackFailure,
    startFromSunday,
    editMode,
  } = payload;
  const remoteInfoPromises = [];

  try {
    listOfRemoteRequests.map((request) => {
      // eslint-disable-line
      if ((editMode && request.checked) || !editMode) {
        remoteInfoPromises.push(
          new Promise((resolve, reject) => {
            getShiftInfoByAssignedScheduleId(request.assignedScheduleId).then(
              (response) => {
                if (response.data && response.data.data) {
                  response = response.data.data;
                } else {
                  reject();
                }
                resolve({
                  assignedScheduleId: request.assignedScheduleId,
                  startTime: request.unixStart,
                  endTime: request.unixEnd,
                  startRemoteId: response.remoteId,
                  endRemoteId: request.remoteId,
                  thumbsUp: request.thumbsUp ? 1 : 0,
                  employerComment: request.feedback,
                  breakTime: request.breakTime,
                  startFromSunday: startFromSunday ? startFromSunday : false,
                });
              }
            );
          })
        );
      }
    });

    try {
      const responseRemoteInfo = yield Promise.all(remoteInfoPromises);

      const response = yield call(
        approveListOfRemoteRequests,
        responseRemoteInfo
      );

      if (response.data) {
        if (isSuccess(response)) {
          yield put(acceptRemoteRequestsSuccess(true));
          callbackSuccess();
        } else {
          callbackFailure();
        }
      } else {
        callbackFailure();
      }
    } catch (error) {
      yield put(acceptRemoteRequestsFailure(error));
      callbackFailure();
    }
  } catch (error) {
    yield put(acceptRemoteRequestsFailure(error));
    callbackFailure();
  }
}

function* handleUpdateScheduleOfRemoteRequest({ payload }) {
  try {
    const response = yield call(updateScheduleOfRemoteRequest, payload);
    if (response.data && isSuccess(response)) {
      yield put(updateAsssignedScheduleTimeSheetsSuccess(response));
    } else {
      yield put(showErrorResponse(response));
    }
  } catch (error) {
    yield put(updateAsssignedScheduleTimeSheetsFailure(error));
  }
}

/**
 * Taking pending remote request
 */
export function* watchPendingRemoteRequests() {
  yield takeEvery(
    GET_REMOTE_REQUEST_PENDING_LIST,
    handleGetPendingRemoteRequests
  );
}

/**
 * Taking pending daily remote request
 */
export function* watchPendingDailyRemoteRequests() {
  yield takeEvery(
    GET_REMOTE_REQUEST_PENDING_DAILY_LIST,
    handleGetPendingDailyRemoteRequests
  );
}

/**
 * Taking decline a remote request
 */
export function* watchDeclineRemoteRequest() {
  yield takeEvery(DECLINE_REMOTE_REQUEST, handleDeclineRemoteRequest);
}

/**
 * Taking decline list of remote requests
 */
export function* watchDeclineListOfRemoteRequests() {
  yield takeEvery(
    DECLINE_LIST_REMOTE_REQUESTS,
    handleDeclineListOfRemoteRequests
  );
}

/**
 * Taking accept single remote requests
 */
export function* watchAcceptRemoteRequest() {
  yield takeEvery(ACCEPT_REMOTE_REQUEST, handleAcceptRemoteRequest);
}

/**
 * Taking accept list of remote requests
 */
export function* watchAcceptListOfRemoteRequests() {
  yield takeEvery(
    ACCEPT_LIST_REMOTE_REQUESTS,
    handleAcceptListOfRemoteRequests
  );
}

/**
 * Taking update assigned schedule of remote request
 */
export function* watchUpdateScheduleOfRemoteRequest() {
  yield takeEvery(
    UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS,
    handleUpdateScheduleOfRemoteRequest
  );
}

/**
 * Remote requests Root Saga
 */
export default function* rootSaga() {
  yield all([
    fork(watchPendingRemoteRequests),
    fork(watchPendingDailyRemoteRequests),
    fork(watchDeclineRemoteRequest),
    fork(watchDeclineListOfRemoteRequests),
    fork(watchAcceptRemoteRequest),
    fork(watchAcceptListOfRemoteRequests),
    fork(watchUpdateScheduleOfRemoteRequest),
  ]);
}

import React, {Component} from 'react';

import InfoTab    from 'Components/JobCard/InfoTab';
import SeekerItem from 'Components/ListSeekers/SeekerItem';

class ListSeekers extends Component {

  constructor(props) {
    super(props);
    this.state = {
        seekerTabs: {
            anchorEl: null,
            seeker  : null
        }
    };
    this.toggleSeekerInfoTab = this.toggleSeekerInfoTab.bind(this);
  }

  getYearsExperience(year) {
    if (isNaN(year)) {
      return 0;
    }

    return year;
  }

  formatSeekerData() {
    return this.props.listSeekers.map((seeker) => {
      let seekerId = Number(seeker.id);
      const {
        employee_skills,
        employment_history,
        experiences,
        fullname,
        profile_thumbnail,
        overlaps,
        phone,
        driving_licence,
        alcohol_licence,
        scooter_licence,
        hgv_licence,
        home_office,
        languages,
        employee_id,
        is_added,
        is_confirmed
      } = seeker;
      if (this.props.isNudge && seeker.employee_id) {
        seekerId = Number(seeker.employee_id);
      }

      return {
        phone,
        overlaps,
        seekerId,
        employee_skills,
        employment_history,
        experiences,
        profile_thumbnail,
        employee_id,
        user_fullname       : fullname,
        seekerName          : fullname,
        profileThumbnail    : profile_thumbnail,
        alreadyNudged       : Number(seeker.nudged) === 1 || false,
        hasSmartPhone       : Number(seeker.smart_phone) === 1,
        gigApproved         : Number(seeker.gig_approved) === 1,
        isFavourited        : Number(seeker.favourite) === 1,
        totalAssignments    : Number(seeker.total_assignments),
        totalPositiveReviews: Number(seeker.total_positive_reviews),
        yearExperiences     : this.getYearsExperience(Number(seeker.years_experience)),
        hasQualification    : Number(seeker.has_qualification),
        isNonEmployee       : seeker.is_internal_employee,
        licences            : this.setLicences(driving_licence, alcohol_licence, scooter_licence,hgv_licence),
        isAdded             : is_added,
        isConfirmed         : is_confirmed,
        driving_licence     : seeker.driving_license,
        alcohol_licence     : seeker.alcohol_license,
        scooter_licence     : seeker.scooter_license,
        hgv_licence         : seeker.hgv_license,

        dbs_check           : seeker.dbs_check,
        food_safety_allergens : seeker.food_safety_allergens,
        fork_lift_truck     : seeker.fork_lift_truck,
        manual_handing      : seeker.manual_handing,
        home_office         : home_office ? home_office :null,
        languages           : languages
      };
    });
  }

  setLicences = (driving_licence, alcohol_licence, scooter_licence,hgv_licence)=>{
    const licences = [];
    if(driving_licence && driving_licence.length>1) {
      licences.push(
        'driving-licence'
      );
    }
    if(alcohol_licence && alcohol_licence.length>1) {
      licences.push(
        'alcohol-licence'
      );
    }
    if(scooter_licence && scooter_licence.length>1) {
      licences.push(
        'scooter-licence'
      );
    }
    if(hgv_licence && hgv_licence.length>1) {
      licences.push(
        'hgv-licence'
      );
    }
    return licences;
  }

  toggleSeekerInfoTab(anchorEl, seeker) {
    this.setState({
      seekerTabs: {
        anchorEl,
        seeker
      }
    });
  }

  render() {
    const listSeekers  = this.formatSeekerData();

    const { seekerTabs  } = this.state;
    const { isNudge, isAdd, isSeekerSignal, isAddSeekerButton, isAddTimeCard } = this.props;
    let { addedListIds, nudgedListIds } =  this.props;

    if (!addedListIds) {
      addedListIds = [];
    }

    if (!nudgedListIds) {
      nudgedListIds = [];
    }

    return (
      <div className="list-seekers">
        {listSeekers.map((seeker) => {
          const {seekerId, seekerName} = seeker;
          return (
            <SeekerItem
              key={`seeker-item-${seekerId}-${seekerName}`}
              seeker={seeker}
              isNudge={isNudge}
              isAdd={isAdd}
              isSeekerSignal={isSeekerSignal}
              isAddSeekerButton={isAddSeekerButton}
              added={addedListIds.includes(seekerId)}
              nudged={seeker.alreadyNudged}
              isAddTimeCard={isAddTimeCard}
              onSubmit={() => this.props.onSubmit(seekerId, seekerName)}
              onSubmitAddSeeker={() => this.props.onSubmitAddSeeker(seekerId)}
              onShowSeekerTabs={this.toggleSeekerInfoTab}
            />
          );
        })}

        {Boolean(seekerTabs.anchorEl) === true && (
          <InfoTab
            employee={seekerTabs.seeker}
            showProfile
            anchorEl={seekerTabs.anchorEl}
            closePopoverOptions={() => this.toggleSeekerInfoTab(null, null)}
          />
        )}
      </div>
    );
  }
}

export default ListSeekers;

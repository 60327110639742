/**
 * Business Detail Setting Reducer
 */

import {
    CLOSE_BUSINESS_DETAIL_SETTING_POPUP,
    OPEN_BUSINESS_DETAIL_SETTING_POPUP,
    GET_BUSINESS_DETAIL_SETTING,
    GET_BUSINESS_DETAIL_SETTING_SUCCESS,
    UPDATE_OBJECT_BUSINESS_DETAIL_SETTING,
    UPDATE_BUSINESS_DETAIL_SETTING,
    UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS,
    UPDATE_BUSINESS_DETAIL_SETTING_FAIL,
    CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP,
    CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP

} from 'Actions/types';

const INIT_STATE = {
    openPopUp: false,
    loading: false,
    data: {
        name: "",
        email: "",
        ba_city: "",
        ba_street: "",
        ba_building: "",
        ba_postcode: "",
        company_number: "",
        vat_number: "",
        contact_number: "",
        logo: "",
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CLOSE_BUSINESS_DETAIL_SETTING_POPUP:
            return {...state, openPopUp: false };

        case OPEN_BUSINESS_DETAIL_SETTING_POPUP:
            return {
                ...state, 
                openPopUp: true, 
                updated: false, 
                error: false 
            };

        case GET_BUSINESS_DETAIL_SETTING:
            return {
                ...state, 
                loading: true, 
                updated: false, 
                error: false 
            };

        case GET_BUSINESS_DETAIL_SETTING_SUCCESS:
            return { ...state, data: action.payload.data.employer, loading: false };

        case UPDATE_OBJECT_BUSINESS_DETAIL_SETTING:
            return { ...state, data: action.data };

        case UPDATE_BUSINESS_DETAIL_SETTING:
            return { ...state, loading: true };

        case UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS:
            return {
                ...state, 
                response: action.payload.data, 
                loading: false, 
                updated: true 
            };

        case UPDATE_BUSINESS_DETAIL_SETTING_FAIL:
            return {
                ...state, 
                response: action.payload.response.data, 
                loading: false, 
                error: true 
            };

        case CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP:
            return { ...state, error: false };

        case CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP:
            return { ...state, updated: false, openPopUp: false };

        default:
            return { ...state };
    }
};

import API from 'Api';

/**
 * Get the employer's details.
 *
 * @return async
 */
export const getBusinessDetailInfoRequest = async() => await API.get('/employers')
    .then(response => response)
    .catch(err => err);



/**
 * Update the employer's business settings.
 * @param  {!Object} params - Properties of employer need to update
 * @typedef {
        id                : integer
        name              : string
        email             : string
        ba_city           : string
        ba_street         : string
        ba_building       : string
        ba_postcode       : string
        company_number    : string
        contact_number    : string
        logo              : base64
    }
 *
 * @return async
 */
export const updateBusinessDetailInfoRequest = async(params) => await API.put(`/employers/${params.id}`, params)
        .then(response => response)
        .catch(err => err);

import Cryptog from 'OAuth/cryptog';
import Util from 'OAuth/util';
import ApiCodes from 'Constants/ApiCodes';
import MyGig from 'Constants/MyGig';

// stored data
import StoredData from 'Constants/StoredData';
import PushNotification from 'PushNotification';
import { postReauthorise } from 'Actions';

const cryptog = new Cryptog();
const util = new Util();
const pushnotification = new PushNotification();
export default class OAuth {

    setHeader(url, method)
    {
        const accessToken = StoredData.get(StoredData.KEYS.OAUTH.ACCESS_TOKEN);
        const hmacKey     = StoredData.get(StoredData.KEYS.OAUTH.HMAC_KEY);
        return this.generateAuthHeader(url, method, accessToken, hmacKey);
    }

    isAuthenticated() {
        const authenticated = StoredData.get(StoredData.KEYS.EMPLOYER_EMAIL) === null;
        return authenticated;
    }

    /**
     * Store an access token returned by the API.
     * Also calculate and store the token's expiry timestamp.
     *
     * @param {string} token
     * @param {number} lifetime - In seconds, eg: 3600
     * @private
     */
    setAccessToken(token, lifetime) {

        const currentTimestamp = Math.round((new Date()).getTime() / 1000);
        const expiryTimestamp = currentTimestamp + lifetime;

        StoredData.set(StoredData.KEYS.OAUTH.ACCESS_TOKEN, token);

        StoredData.set(StoredData.KEYS.OAUTH.ACCESS_TOKEN_EXPIRY_TIMESTAMP, expiryTimestamp);
    }

    /**
     * Store a refresh token returned by the API.
     *
     * @param {string} token
     * @private
     */
    setRefreshToken(token) {
        StoredData.set(StoredData.KEYS.OAUTH.REFRESH_TOKEN, token);
    }

    /**
     * Get the stored refresh token.
     *
     * @return {?string} - null if token doesn't exist
     * @private
     */
    getRefreshToken() {
        return (StoredData.get(StoredData.KEYS.OAUTH.REFRESH_TOKEN));
    }

    /**
     * Store an HMAC key returned by the API.
     *
     * @param {string} hmacKey
     * @private
     */
    setHmacKey(hmacKey) {
        StoredData.set(StoredData.KEYS.OAUTH.HMAC_KEY, hmacKey);
    }

    storeLocalStorageAuthenticate(response)
    {
        StoredData.set(StoredData.KEYS.EMPLOYER_EMAIL, response.email);
        this.setRefreshToken(response.refresh_token);
        this.setAccessToken(response.access_token, response.expires_in);
        this.setHmacKey(response.mac_key);

        if (response.employer_id ) {
            StoredData.set(StoredData.KEYS.EMPLOYER_LEVEL, response.level);
            StoredData.set(StoredData.KEYS.EMPLOYER_ID, response.employer_id);
            if (Number(response.level) !== this.isSuper()) {
                StoredData.set(StoredData.KEYS.EMPLOYER_LEVEL_ID, response.employer_id);
            }
        }
    }

    storeLocalStorageUserDetail(responseObject)
    {
        StoredData.set(StoredData.KEYS.EMPLOYER_NAME, responseObject.data.employer.name);
        StoredData.set(StoredData.KEYS.GIG_PREMIUM, responseObject.data.employer.gig_premium);
        StoredData.set(StoredData.KEYS.EMPLOYER_STAFF, responseObject.data.employer_staff.fullname);
        StoredData.set(StoredData.KEYS.EMPLOYER_STAFF_ID, responseObject.data.employer_staff.id);
        StoredData.set(StoredData.KEYS.EMPLOYER_CHOSEN_ID, responseObject.data.employer_staff.chosen_provider_id);
        StoredData.set(StoredData.KEYS.EMPLOYER_ROLE_ID, Number(responseObject.data.employer_staff.role_id));
        StoredData.set(StoredData.KEYS.USER_ID, responseObject.data.employer.user_id);
        StoredData.set(StoredData.KEYS.EMPLOYER_ID, responseObject.data.employer.id);
        StoredData.set(StoredData.KEYS.EMPLOYER_LOGO, responseObject.data.employer.logo_thumbnail);
        

        const { user } = responseObject.data;

        if (user && user.email) {
            StoredData.set(StoredData.KEYS.LOGGED_IN_AS, user.email);
        }

        pushnotification.withPusherConnection();
        pushnotification.subscribeJobResponseChanel(user.email);
    }

    storeLocalStorageEmployersList(responseObject)
    {
        if (Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID)) === MyGig.roles.OMNI_STAFF.ID) {
            StoredData.set(StoredData.KEYS.EMPLOYER_LIST, responseObject.data);
        }
    }

    /**
     * Log out.
     *
     * @public
     */
    logout()
    {
        StoredData.remove(StoredData.KEYS.EMPLOYER_EMAIL);
        StoredData.remove(StoredData.KEYS.EMPLOYER_LOGO);
        StoredData.remove(StoredData.KEYS.EMPLOYER_CHOSEN_ID);
        StoredData.remove(StoredData.KEYS.OAUTH.ACCESS_TOKEN);
        StoredData.remove(StoredData.KEYS.OAUTH.REFRESH_TOKEN);
        StoredData.remove(StoredData.KEYS.EMPLOYER_LEVEL);
        StoredData.remove(StoredData.KEYS.EMPLOYER_LEVEL_ID);
        StoredData.remove(StoredData.KEYS.EMPLOYER_ID);
        StoredData.remove(StoredData.KEYS.OAUTH.HMAC_KEY);
        StoredData.remove(StoredData.KEYS.OAUTH.ACCESS_TOKEN_EXPIRY_TIMESTAMP);
    }

    /**
     * Generate the "Authorization" header value for an API call.
     *
     * @param  {string} url    - Example: https://api.gig-api.co.uk/employee/1
     * @param  {string} method - One of:  GET, POST, PUT, DELETE, ...
     * @param  {string} accessToken
     * @param  {string} hmacKey
     * @return {string}
     * @public
     */
    generateAuthHeader(url, method, accessToken, hmacKey)
    {
        const PORT_HTTP  = 80;
        const PORT_HTTPS = 443;

        const timestamp  = Math.round((new Date()).getTime() / 1000);
        const nonce      = cryptog.generateRandomToken();

        const parsedUrl  = util.parseUrl(url);

        const requestUri = parsedUrl.pathname + parsedUrl.search;
        const hostname   = parsedUrl.hostname;
        let port       = (parsedUrl.protocol === 'https:' ? PORT_HTTPS : PORT_HTTP);

        if (parsedUrl.port.search(/^\d+$/) !== -1) {
            port = parsedUrl.port;
        }

        const hmacInput = [
            timestamp, nonce, method, requestUri, hostname, port
        ].join('\n');

        const hmacBase64 = cryptog.generateHmacSha256Base64(hmacKey, hmacInput);

        const fields = [
            `id="${accessToken}"`,
            `ts="${timestamp}"`,
            `nonce="${nonce}"`,
            `mac="${hmacBase64}"`
        ];

        return `MAC ${fields.join(', ')}`;
    };

    /**
     * Is the user currently logged in?
     *
     * If StoredData contains all the necessary items, then assume the user is logged in.
     * The access token may have expired; if so, it should be possible to use the refresh
     * token to automatically reauthenticate the user.
     *
     * @return {boolean}
     * @public
     */
    isLoggedIn() {
        return StoredData.isSet(StoredData.KEYS.OAUTH.ACCESS_TOKEN) &&
               StoredData.isSet(StoredData.KEYS.OAUTH.ACCESS_TOKEN_EXPIRY_TIMESTAMP) &&
               StoredData.isSet(StoredData.KEYS.OAUTH.REFRESH_TOKEN) &&
               StoredData.isSet(StoredData.KEYS.OAUTH.HMAC_KEY);
    }

    obtainAccessToken() {
        const token  = StoredData.get(StoredData.KEYS.OAUTH.ACCESS_TOKEN);
        const expiry = StoredData.get(StoredData.KEYS.OAUTH.ACCESS_TOKEN_EXPIRY_TIMESTAMP);
        const next5MinutesTimestamp = Math.round((new Date()).getTime() / 1000) + 300;

        if (typeof token === 'string' && expiry > next5MinutesTimestamp) {
            return true;
        }

        if (typeof this.getRefreshToken() !== 'string') {
            return false;
        }

        const params = {
            'refresh_token': this.getRefreshToken()
        };

        postReauthorise(params);
    }

    getErrorObjectTokenExpired() {
        return {
            response: {
                data: {
                    code: ApiCodes.AUTHENTICATION_FAILED,
                    error: {
                        messages: ''
                    }
                }
            }
        };
    }

    isOmniStaff() {
        return MyGig.roles.OMNI_STAFF.ID;
    }

    isGigPremium() {
        return MyGig.employerPremium.IS_PREMIUM;
    }

    isEmployer() {
        return MyGig.roles.EMPLOYER_ADMIN.ID;
    }

    isEmployerAdmin() {
        return MyGig.roles.NEW_PROVIDER_ADMIN.ID;
    }

    isSuper() {
        return MyGig.rolelevel.SUPER_ADMIN_LEVEL;
    }

    isAdmin() {
        return MyGig.rolelevel.ADMIN_LEVEL;
    }

    isStandard() {
        return MyGig.rolelevel.STANDARD_LEVEL;
    }

    checkCurrentIsSuper() {
        return Number(StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL)) === this.isSuper();
    }

    checkCurrentIsAdmin() {
        return Number(StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL)) === this.isAdmin();
    }

    checkCurrentIsStandard() {
        return Number(StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL)) === this.isStandard();
    }

    checkCurrentIsOmniStaff() {
        return Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID)) === this.isOmniStaff();
    }
    checkCurrentIsEmployerAdmin() {
        return Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID)) === this.isEmployerAdmin();
    }
}

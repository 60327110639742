/**
 * Job Item Components
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import GigLogo from "Assets/img/gig_large.png";
import { injectIntl, FormattedMessage } from "react-intl";
import { Progress } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "Util/IntlMessages";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "@material-ui/core/Modal";
import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { IconThreeDotCircle } from "Components/Icons/IconThreeDotCircle";
import AddSeekerModal from "Components/Model/AddSeeker";
import NudgeSeekerModal from "Components/Model/NudgeSeeker";
import JobDetail from "./JobDetail";
import className from "classnames";
import Accept from "Assets/img/Accept.png";
import Warning from "Assets/img/SweetAlert/warning.png";
import { getTheDate, validNumberOrZero } from "Helpers/helpers";
import ScheduleHelper from "Helpers/Schedule";
import ModalShift from "Components/JobCard/ModalShift";
import OAuth from "OAuth";
import SeekerInfo from "Components/Model/SeekerInfo";
import SeekerProfile from "Components/Seeker/Profile";
import MyGig from "Constants/MyGig";
import StoredData from "Constants/StoredData";
import Decline from "Assets/img/Decline.png";
import UserDefault from "Assets/img/default-user.png";
import Information from "Assets/img/Information.png";
import { Scrollbars } from "react-custom-scrollbars";

import {
    acceptCandidate,
    declineCandidate,
    openDeclinePopUp,
    closeAllPopUps,
    checkOverlaps,
    doUnpromoteJob,
    doPromoteJob,
    openDeclineAssignmentPopUp,
    declineAssignment,
} from "Actions";
import { Pagination } from "@material-ui/lab";

const oauth = new OAuth();

const styles = (theme) => ({
    borderRadiusPopOver: {
        borderRadius: 5,
    },
    marginTooltip: {
        marginTop: 40,
    },
});

class JobItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowCancelJobAlert: false,
            activeSeekerInfo: false,
            openModalShift: false,
            seekerId: null,
            collapse: false,
            collapseKey: false,
            seekerTabs: {
                anchorEl: null,
                seeker: null,
            },
            employee: {},
            currentSeekerPopupId: 0,
            openDetailInfo: false,
            anchorcalendarmodal: null,
            page: 1
        };
    }

    componentDidUpdate() {
        window.dispatchEvent(new Event("resize"));
    }

    async UNSAFE_componentWillUpdate(nextProps, nextState) {
        const { job } = this.props
        if (nextState.page !== this.state.page) {
            if (Boolean(this.state.anchorAssignmentEl)) {
                await this.props.getAssignmentsByPosition(job.position_id, nextState.page)
            } else if (Boolean(this.state.anchorCandidateEl)) {
                await this.props.getCandidatesByPosition(job.position_id, nextState.page)
            }
        }
    }

    calculatePercentStatus(filled, total) {
        const percent = (filled * 100) / total;
        let classStatus = "filled";
        let textID = "dashboard.statusFilled";

        if (percent < 50) {
            textID = "dashboard.statusPending";
            classStatus = "danger";
        } else if (percent >= 50 && percent < 99) {
            textID = "dashboard.statusGoing";
            classStatus = "warning";
        }

        return (
            <div>
                <Progress
                    color={classStatus}
                    className="mt-10 w-90"
                    value={percent}
                />
                <p className="mb-0 fs-12">
                    <IntlMessages id={textID} />
                </p>
            </div>
        );
    }

    toggleCancelJobAlert = () => {
        if (this.props.disableDelete) return false;

        this.setState({
            anchorEl: null,
            isShowCancelJobAlert: !this.state.isShowCancelJobAlert,
        });
    };

    handleShowNudgeSeeker = () => {
        this.props.showNudgeSeekerModal(this.props.job.position_id);
    };

    handleShowAddSeeker = (positionId) => {
        if (this.props.handleBeforeStandardJobAction) {
            this.props.handleBeforeStandardJobAction(() => {
                this.props.showAddSeekerModal(positionId);
            });
        } else {
            this.props.showAddSeekerModal(positionId);
        }

        this.setState({
            anchorEl: null,
        });
    };

    handleRepostJob = () => {
        if (this.props.disableRespost) return false;
        this.props.getJobById(this.props.job.position_id);
        this.props.openRepostJob();
    };

    handleEditJob = () => {
        const { disableEdit, job } = this.props;
        if (disableEdit) return false;
        this.props.getJobById(job.position_id);
        this.props.openEditJob();
    };

    handleCancelJob = () => {
        this.toggleCancelJobAlert();
        this.props.cancelJob(this.props.job);
    };

    closePopoverOptions = () => {
        this.setState({
            anchorEl: null,
            anchorAssignmentEl: null,
            anchorCandidateEl: null,
            seekerId: null,
            activeSeekerInfo: false,
            page: 1
        });
    };

    toggleSeekerInfo = (seekerId) => {
        this.setState({ seekerId });
        if (seekerId === this.state.seekerId) {
            this.setState({
                seekerId: null,
                activeSeekerInfo: false,
            });
        } else {
            this.setState({
                seekerId,
                activeSeekerInfo: true,
            });
        }
    };

    handleAcceptSeeker = (seeker) => {
        const { job } = this.props;
        this.setState({ seeker });

        this.props.checkOverlaps({
            positionId: job.position_id,
            employeeId: seeker.employee_id,
        });
    };

    handleDenyCandidate = (seeker) => {
        this.setState({ seeker });
        this.props.openDeclinePopUp(seeker.employee_id);
    };

    handleDenyAssignment = (seeker) => {
        this.setState({ seeker });
        this.props.openDeclineAssignmentPopUp(seeker.employee_id);
    };

    handleClose() {
        this.setState({
            open: false,
        });
    }

    openPopoverOptions = (event) => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    handleOpenModalShift = (e) => {
        this.setState({
            openModalShift: true,
            anchorcalendarmodal: e.currentTarget,
        });
    };

    handleCloseModalShift = () => {
        this.setState({ openModalShift: false });
    };

    jobIsFilled = () => {
        const { job } = this.props;

        return (
            validNumberOrZero(job.assignments_filled) >=
            validNumberOrZero(job.assignments_total)
        );
    };

    handlePromotedJob = (positionId, isPromoted) => {
        isPromoted
            ? this.props.doUnpromoteJob(positionId)
            : this.props.doPromoteJob(positionId);
    };

    openSeekerInfoPopup = (event, employee) => {
        this.setState({
            employee,
            currentSeekerPopupId: employee.employee_id,
            openDetailInfo: true,
        });
    };

    closeSeekerInfoPopup = () => {
        this.setState({
            openDetailInfo: false,
        });
    };

    renderMainPopover() {
        const {
            classes,
            intl,
            job,

            // Disable buttons props
            disableAdd,
            disableNudge,
            disableMessage,
            disableEdit,
            disableRespost,
            disableDelete,

            // Hide buttons props
            hideNudge,
            hideMessage,
            hideEdit,
            hideRepost,
            jobType,
        } = this.props;
        const { anchorEl } = this.state;
        const addSeekerEnable =
            !disableAdd &&
            this.jobIsFilled() === false &&
            oauth.checkCurrentIsOmniStaff();
        const nudgeSeekerEnable = !disableNudge && this.jobIsFilled() === false;
        const promoteJobEnable =
            jobType === MyGig.jobType.PENDING &&
            oauth.checkCurrentIsOmniStaff();

        return (
            <Popover
                disableEnforceFocus
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.closePopoverOptions}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{
                    paper: classes.borderRadiusPopOver,
                    paper: "MuiPopover-job-card-list-action",
                }}
            >
                <div className="job-card-list-action">
                    <div className="job-card-list-action__header text-right">
                        <IconThreeDotCircle />
                    </div>
                    <div 
                        className="job-card-item-container"
                        onClick={() => {
                            if (addSeekerEnable) {
                                this.handleShowAddSeeker(
                                    this.props.job.position_id
                                );
                            }
                        }}    
                    >
                        <i
                            className={className(
                                "gig-icon",
                                "icon-action",
                                "icon-add-seeker",
                                "bottom",
                                {
                                    inactive: addSeekerEnable === false,
                                }
                            )}
                            style={{ width: 30 }}
                        />
                        <span
                            className={className("job-card-item-text", {
                                inactive: !addSeekerEnable,
                            })}
                        >
                            Add seeker
                        </span>
                    </div>
                    {/* </Tooltip> */}

                    {!hideNudge && (
                        <div 
                            className="job-card-item-container"
                            onClick={() => {
                                if (nudgeSeekerEnable) {
                                    this.handleShowNudgeSeeker();
                                }
                            }}    
                        >
                            <i
                                className={className(
                                    "gig-icon",
                                    "icon-action",
                                    "icon-nudge-job",
                                    "bottom",
                                    {
                                        inactive: nudgeSeekerEnable === false,
                                    }
                                )}
                            />
                            <span
                                className={className("job-card-item-text", {
                                    inactive: !nudgeSeekerEnable,
                                })}
                            >
                                Nudge Seeker
                            </span>
                        </div>
                        // </Tooltip>
                    )}

                    {!hideMessage && (
                        <div 
                            className="job-card-item-container"
                            onClick={() => this.props.openMessageModal(job)}    
                        >
                            <i
                                className={className(
                                    "gig-icon",
                                    "icon-action",
                                    "icon-envelope-out",
                                    "bottom",
                                    { inactive: disableMessage }
                                )}
                            />
                            <span
                                className={className("job-card-item-text", {
                                    inactive: disableMessage,
                                })}
                            >
                                Message
                            </span>
                        </div>
                        // </Tooltip>
                    )}

                    {!hideEdit && (
                       
                        <div className="job-card-item-container" onClick={this.handleEditJob}>
                            <i
                                className={className(
                                    "gig-icon",
                                    "icon-action",
                                    "icon-edit",
                                    "bottom",
                                    { inactive: disableEdit }
                                )}
                            />
                            <span
                                className={className("job-card-item-text", {
                                    inactive: disableEdit,
                                })}
                            >
                                Edit Shift
                            </span>
                        </div>
                        
                    )}

                    {!hideRepost && (
                        
                        <div className="job-card-item-container" onClick={this.handleRepostJob}>
                            <i
                                className={className(
                                    "gig-icon",
                                    "icon-action",
                                    "icon-copy",
                                    "bottom",
                                    { inactive: disableRespost }
                                )}
                            />
                            <span
                                className={className("job-card-item-text", {
                                    inactive: disableRespost,
                                })}
                            >
                                Repost Shift
                            </span>
                        </div>
                       
                    )}

                    
                    <div className="job-card-item-container" onClick={this.toggleCancelJobAlert}>
                        <i
                            className={className(
                                "gig-icon",
                                "icon-action",
                                "icon-ban-job",
                                "bottom",
                                { inactive: disableDelete }
                            )}
                        />
                        <span
                            className={className("job-card-item-text", {
                                inactive: disableDelete,
                            })}
                        >
                            Delete
                        </span>
                    </div>

                    {promoteJobEnable && (
                        
                        <div 
                            className="job-card-item-container" 
                            onClick={() =>
                                this.handlePromotedJob(
                                    job.position_id,
                                    job.is_promoted
                                )
                            }
                        >
                            <i
                                className="gig-icon material-icons icon-action icon-promote-job bottom"
                            >
                                {job.is_promoted ? "star" : "star_border"}
                            </i>
                            <span className={className("job-card-item-text")}>
                                Promote
                            </span>
                        </div>
                        
                    )}
                </div>
            </Popover>
        );
    }

    renderSeekerDetail(seekers, loading, seekerType) {
        const {
            job,
            classes,

            // Action props
            acceptCandidate,
            declineCandidate,
            closeAllPopUps,

            // Properties props
            showAlertOverlaps,
            showAlertAccept,
            showAlertDecline,
            currentEmployeeId,
            currentDeclineId,
            showAlertDeclineAssignment,
            declineAssignment,
            currentDeclineAssignmentId,
            loadingSweetAlert,
        } = this.props;
        const {
            seekerId,
            activeSeekerInfo,
            employee,
            openDetailInfo,
            currentSeekerPopupId,
        } = this.state;
        const hasSeeker = !!seekers.length;

        const showActions = seekerType === "Posted";

        return (
            <div className="detail-box">


                {!loading && !hasSeeker && (
                    <div className="tab-empty-wrapper">
                        <IntlMessages
                            id={`listSeekerTabs.empty${seekerType}`}
                        />
                    </div>
                )}

                {hasSeeker &&
                    seekers.map((seeker, key) => (
                        <div key={key}>
                            <div
                                className={className(
                                    "row m-0 justify-content-between",
                                    "seeker-profiler-collapse",
                                    { open: seekerId === seeker.employee_id },
                                    { "no-border": key === 0 },
                                    { "no-pb": key === seekers.length - 1 }
                                )}
                            >
                                <div className="d-flex">
                                    <img
                                        src={
                                            seeker.profile_thumbnail
                                                ? seeker.profile_thumbnail
                                                : UserDefault
                                        }
                                        alt={seeker.user_fullname}
                                        className="rounded-circle avatar"

                                    />

                                    <div className="d-flex">
                                        <div className="name">
                                            <div className="seeker-profile-container">
                                                
                                                <span className="seeker-profile-container__name">
                                                    {seeker.user_fullname}
                                                </span>
                                               
                                                <SeekerProfile
                                                    seeker={seeker}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    "flexDirection": "row",
                                                    marginLeft: "4px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        "alignSelf": "center",
                                                    }}
                                                    onClick={(event) =>
                                                        this.openSeekerInfoPopup(
                                                            event,
                                                            seeker
                                                        )
                                                    }
                                                    src={Information}
                                                    height={35}
                                                    widht={35}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    {this.renderButtonAction(seeker)}
                                </div>
                            </div>
                            <div
                                style={{ marginLeft: 20 }}
                                className="d-flex justify-content-between align-items-center"
                            ></div>

                            {/* Decline seeker */}
                            <Modal
                                hideBackdrop
                                open={
                                    showAlertDecline &&
                                    seeker.employee_id === currentDeclineId
                                }
                            >
                                <SweetAlert
                                    custom
                                    showCancel
                                    customIcon={Warning}
                                    cancelBtnText={<IntlMessages id="button.cancel" />}
                                    confirmBtnText={<IntlMessages id="btn.ok" />}
                                    title={<IntlMessages id="button.decline" />}
                                    onConfirm={() =>
                                        declineCandidate(
                                            seeker.job_response_id,
                                            seeker.position_id,
                                            1,
                                            {}
                                        )
                                    }
                                    onCancel={closeAllPopUps}
                                    confirmBtnCssClass={"btn-orange"}
                                    cancelBtnCssClass={"btn-black"}
                                >
                                    <IntlMessages id="confirmation.declineRequest" />
                                    {loadingSweetAlert &&
                                        this.loadingComponent()}
                                </SweetAlert>
                            </Modal>

                            <Modal
                                hideBackdrop
                                open={
                                    showAlertOverlaps &&
                                    seeker.employee_id === currentEmployeeId
                                }
                            >
                                <SweetAlert
                                    danger
                                    cancelBtnText={
                                        <IntlMessages id="common.button.no" />
                                    }
                                    confirmBtnText={
                                        <IntlMessages id="common.button.yes" />
                                    }
                                    title={
                                        <IntlMessages id="components.jobCard.ListSeekerTab.isOverlaps.title" />
                                    }
                                    onConfirm={this.props.closeAllPopUps}
                                    confirmBtnCssClass={"btn-orange"}
                                    cancelBtnCssClass={"btn-black"}
                                >
                                    <IntlMessages id="components.jobCard.ListSeekerTab.isOverlaps.description" />
                                </SweetAlert>
                            </Modal>

                            {/* Accept Seeker */}
                            <Modal
                                hideBackdrop
                                open={
                                    showAlertAccept &&
                                    seeker.employee_id === currentEmployeeId
                                }
                            >
                                <SweetAlert
                                    custom
                                    customIcon={Warning}
                                    cancelBtnText={
                                        <IntlMessages id="button.cancel" />
                                    }
                                    confirmBtnText={
                                        <IntlMessages id="txt.confirm" />
                                    }
                                    title={<IntlMessages id="button.accept" />}
                                    showCancel
                                    onConfirm={() =>
                                        acceptCandidate({
                                            positionId: seeker.position_id,
                                            candidate: seeker,
                                            currentPage: "upcomingPage",
                                        })
                                    }
                                    onCancel={closeAllPopUps}
                                    confirmBtnCssClass={"btn-orange"}
                                    cancelBtnCssClass={"btn-black"}
                                >
                                    <IntlMessages id="confirmation.approveRequest" />
                                    {loadingSweetAlert &&
                                        this.loadingComponent()}
                                </SweetAlert>
                            </Modal>

                            {/* Decline Assignment */}
                            <Modal
                                hideBackdrop
                                open={
                                    showAlertDeclineAssignment &&
                                    seeker.employee_id ===
                                    currentDeclineAssignmentId
                                }
                            >
                                <SweetAlert
                                    custom
                                    customIcon={Warning}
                                    cancelBtnText={
                                        <IntlMessages id="button.cancel" />
                                    }
                                    confirmBtnText={
                                        <IntlMessages id="txt.confirm" />
                                    }
                                    title={<IntlMessages id="button.decline" />}
                                    showCancel
                                    onConfirm={() =>
                                        declineAssignment(
                                            seeker.id,
                                            seeker.position_id,
                                            "upcomingPage"
                                        )
                                    }
                                    onCancel={closeAllPopUps}
                                    confirmBtnCssClass={"btn-orange"}
                                    cancelBtnCssClass={"btn-black"}
                                >
                                    <IntlMessages id="confirmation.declineAssignment" />
                                    {loadingSweetAlert &&
                                        this.loadingComponent()}
                                </SweetAlert>
                            </Modal>
                        </div>
                    ))}
                {loading && (
                    <div className="tab-circle-wrapper small-width">
                        <CircularProgress
                            className="progress-warning"
                            size={40}
                            mode="determinate"
                            value={40}
                            min={0}
                            max={50}
                        />
                    </div>
                )}
                <SeekerInfo
                    skillId={job.skill_id}
                    // employee={employee}
                    currentSeekerPopupId={currentSeekerPopupId}
                    open={openDetailInfo}
                    closeSeekerInfoPopup={this.closeSeekerInfoPopup}
                />
            </div>
        );
    }

    loadingComponent() {
        return (
            <div className="d-flex justify-content-center loader-overlay">
                <CircularProgress />
            </div>
        );
    }

    renderAssignmentPopover() {
        const { anchorAssignmentEl, page } = this.state;
        const { assignments, loadingAssignments, paginationLength } = this.props;
        const hasSeeker = !!assignments.length;
        return (
            <Popover
                classes={{
                    paper: "override-transform MuiPopover-staff-allocated",
                }}
                open={Boolean(anchorAssignmentEl)}
                anchorEl={anchorAssignmentEl}
                onClose={this.closePopoverOptions}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="MuiPopover-staff-allocated__header">
                    <IconThreeDotCircle />
                </div>
                <Scrollbars
                    className="rct-scroll"
                    autoHide
                    onScroll={(event) => this.changePagination(event, paginationLength)}
                    autoHeight
                    autoHeightMax={200}
                // style={{ width: 380 }}
                >
                    <div
                        className="staff-allocated-scroll"
                        style={{ width: 380 }}
                    >
                        {this.renderSeekerDetail(
                            assignments,
                            loadingAssignments,
                            "Filled"
                        )}
                    </div>
                </Scrollbars>
            </Popover>
        );
    }

    changePagination = (e, paginationLength) => {
        if ((e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight)) {
            if(
                (Boolean(this.state.anchorAssignmentEl) && !this.props.loadingAssignments)
                ||
                (Boolean(this.state.anchorCandidateEl) && !this.props.loadingCandidates)
            ) {
                let page = this.state.page + 1
                if (page <= paginationLength) {
                    this.setState({ page })
                }
            }
        }
    }

    renderButtonAction(seeker) {
        const { status } = this.props;
        const { POSTED, FILLED } = MyGig.jobType;
        return (
            <Fragment>
                {([POSTED, FILLED].includes(status) || !status) &&
                    (seeker.assigned_by ? (
                        <div className="btn-actions">
                            <div
                                className="d-flex align-items-center"
                                style={{
                                    cursor: "pointer",
                                    flexDirection: "column",
                                    fontSize: '14px'
                                }}
                            >
                                <img
                                    onClick={() =>
                                        this.handleDenyAssignment(seeker)
                                    }
                                    src={Decline}
                                    width={25}
                                    height={25}
                                />
                                <IntlMessages id="button.decline" />
                            </div>
                        </div>
                    ) : (
                        <div className="btn-actions">
                            {/* <Button onClick={() => this.handleAcceptSeeker(seeker)} className="gig-button ok action" > */}
                            <img
                                onClick={() => this.handleAcceptSeeker(seeker)}
                                src={Accept}
                                height={25}
                            />
                            {/* <IntlMessages id="button.accept" /> */}
                            {/* </Button> */}
                            <img
                                style={{ marginLeft: 5 }}
                                onClick={() =>
                                    this.handleDenyCandidate(seeker)
                                }
                                src={Decline}
                                height={25}
                            />
                        </div>
                    ))}
            </Fragment>
        );
    }

    renderCandidatePopover() {
        const { anchorCandidateEl, page } = this.state;
        const { candidates, loadingCandidates, paginationLength } = this.props;
        const hasSeeker = !!candidates.length;
        return (
            <Popover
                classes={{
                    paper: "override-transform MuiPopover-staff-applied",
                }}
                disableEnforceFocus
                open={Boolean(anchorCandidateEl)}
                anchorEl={anchorCandidateEl}
                onClose={this.closePopoverOptions}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="MuiPopover-staff-applied__header text-right">
                    <IconThreeDotCircle />
                </div>
                <Scrollbars
                    className="rct-scroll"
                    autoHide
                    onScroll={(event) => this.changePagination(event, paginationLength)}
                    autoHeight
                    autoHeightMax={200}
                // style={{ width: 380 }}
                >
                    <div
                        className="staff-applied-scroll"
                        style={{ width: 380 }}
                    >
                        {this.renderSeekerDetail(
                            candidates,
                            loadingCandidates,
                            "Posted"
                        )}
                    </div>
                </Scrollbars>
            </Popover>
        );
    }

    openCandidatePopover = (event) => {
        const { job } = this.props;
        this.setState({
            open: true,
            anchorCandidateEl: event.currentTarget,
        });
        this.props.getCandidatesByPosition(job.position_id);
    };

    openAssignmentPopover = (event) => {
        const { job } = this.props;
        this.setState({
            open: true,
            anchorAssignmentEl: event.currentTarget,
        });
        this.props.getAssignmentsByPosition(job.position_id);
    };

    renderSchedules(schedules) {
        const length = schedules.length;
        const { job } = this.props;
        const { openModalShift } = this.state;

        if (length >= 2) {
            return (
                <Fragment>
                    <div className="multi-date">
                        {getTheDate(schedules[0].start, "D MMM")} -{" "}
                        {getTheDate(schedules[length - 1].start, "D MMM")}{" "}
                        <IconButton
                            className="multilple-shifts"
                            onClick={this.handleOpenModalShift}
                        >
                            <i
                                className="material-icons"
                                style={{ marginTop: "-4px" }}
                            >
                                info
                            </i>
                        </IconButton>
                    </div>

                    <ModalShift
                        job={job}
                        iconModal="info"
                        openModalShift={openModalShift}
                        onClose={this.handleCloseModalShift}
                        anchorcalendarmodal={this.state.anchorcalendarmodal}
                    />
                </Fragment>
            );
        }
        return schedules.map((schedule, k) => (
            <div key={k}>
                <p className="m-0">{getTheDate(schedule.start, "D MMM")}</p>
                <p className="m-0">
                    {getTheDate(schedule.start, "H:mm")
                        .replace(" ", "")
                        .toLowerCase()}{" "}
                    -{" "}
                    {getTheDate(schedule.end, "H:mm")
                        .replace(" ", "")
                        .toLowerCase()}
                </p>
            </div>
        ));
    }

    // On collapse job detail
    OnCollapseProject(event, key, candidate) {
        this.setState({
            collapse: !this.state.collapse,
            collapseKey: this.props.job.position_id,
            activeSeekerInfo: !this.state.activeSeekerInfo,
        });
        this.toggleSeekerInfoTab(event.currentTarget, candidate);
    }

    toggleSeekerInfoTab(anchorEl, seeker) {
        this.setState({
            seekerTabs: {
                anchorEl,
                seeker,
            },
        });
    }

    render() {
        const {
            job,
            addSeekerPositionId,
            nudgeSeekerPositionId,
            history,
        } = this.props;
        const {
            collapse,
            isShowCancelJobAlert,
            anchorAssignmentEl,
            anchorCandidateEl,
            anchorEl,
            collapseKey,
        } = this.state;
        return (
            <div className="job-item-wrapper">
                <table>
                    <tbody>
                        <tr>
                            <td className="date-col">
                                {this.renderSchedules(
                                    ScheduleHelper.removePastSchedules(
                                        job.schedule
                                    )
                                )}
                            </td>
                            <td className="gigLogo-col">
                                {!job.is_privated && (
                                    <img
                                        src={GigLogo}
                                        className="gig-avatar-icon"
                                        height="30px"
                                    />
                                )}
                            </td>
                            <td className="position-col">{job.skill_name}</td>
                            <td className="location-col">
                                {job.provider_name && (
                                    <strong>{job.provider_name}</strong>
                                )}
                                {job.location_name}, {job.location_street}
                            </td>
                            <td className="staff-required-col">
                                {job.assignments_total}
                            </td>
                            <td className="staff-allocated-col">
                                {job.assignments_filled < 0 ? (
                                    0
                                ) : (
                                    <Fragment>
                                        <div
                                            onClick={this.openAssignmentPopover}
                                            className={className(
                                                "job-card-three-dot",
                                                {
                                                    open: Boolean(
                                                        anchorAssignmentEl
                                                    ),
                                                }
                                            )}
                                        >
                                            <span className="number-badge">
                                                {validNumberOrZero(
                                                    job.assignments_filled
                                                )}
                                            </span>
                                            <IconThreeDotCircle />
                                        </div>
                                    </Fragment>
                                )}
                                {this.renderAssignmentPopover()}
                            </td>
                            <td className="status-col">
                                {this.calculatePercentStatus(
                                    Number(job.assignments_filled),
                                    Number(job.assignments_total)
                                )}
                            </td>
                            <td className="staff-applied-col">
                                {job.responses_pending < 0 ? (
                                    0
                                ) : (
                                    <Fragment>
                                        <div
                                            onClick={this.openCandidatePopover}
                                            className={className(
                                                "job-card-three-dot",
                                                {
                                                    open: Boolean(
                                                        anchorCandidateEl
                                                    ),
                                                }
                                            )}
                                        >
                                            <span className="number-badge">
                                                {validNumberOrZero(
                                                    job.responses_pending
                                                )}
                                            </span>
                                            <IconThreeDotCircle />
                                        </div>
                                    </Fragment>
                                )}
                                {this.renderCandidatePopover()}
                            </td>

                            <td className="action-job-col">
                                {!oauth.checkCurrentIsStandard() &&
                                    !this.props.disableStandardJobActions && (
                                        <div
                                            onClick={this.openPopoverOptions}
                                            className={className(
                                                "job-card-three-dot",
                                                { open: Boolean(anchorEl) }
                                            )}
                                        >
                                            <span>&nbsp;</span>
                                            <IconThreeDotCircle />
                                        </div>
                                    )}
                                {this.renderMainPopover()}
                            </td>
                            <td className="action-job-col">
                                <IconButton
                                    onClick={() =>
                                        this.OnCollapseProject(job.position_id)
                                    }
                                >
                                    <i className="material-icons view-job-detail">
                                        {collapse &&
                                            collapseKey === job.position_id
                                            ? "expand_less"
                                            : "expand_more"}
                                    </i>
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {collapse && collapseKey === job.position_id && (
                    <JobDetail
                        description={job.position_description}
                        specialRequirements={job.special_requirements}
                        uniform={job.uniform_name}
                        uniformOther={job.uniform_other}
                        uniformImage={job.uniform_image}
                    />
                )}

                {isShowCancelJobAlert && (
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        showCancel
                        confirmBtnText={<IntlMessages id="common.button.yes" />}
                        cancelBtnText={<IntlMessages id="common.button.no" />}
                        title={<IntlMessages id="button.delete" />}
                        onConfirm={() => this.handleCancelJob(job)}
                        onCancel={() => this.toggleCancelJobAlert()}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        {
                            <div>
                                <FormattedMessage
                                    id="messages.questions.cancelJob"
                                    values={{
                                        skillName: job.skill_name,
                                        location: job.location_name,
                                    }}
                                />
                            </div>
                        }
                    </SweetAlert>
                )}

                {nudgeSeekerPositionId === job.position_id && (
                    <NudgeSeekerModal
                        positionId={job.position_id}
                        history={history}
                    />
                )}

                {addSeekerPositionId === job.position_id && (
                    <AddSeekerModal positionId={job.position_id} />
                )}
            </div>
        );
    }
}

const mapActionToProps = {
    acceptCandidate,
    declineCandidate,
    openDeclinePopUp,
    closeAllPopUps,
    checkOverlaps,
    doUnpromoteJob,
    doPromoteJob,
    openDeclinePopUp,
    openDeclineAssignmentPopUp,
    declineAssignment,
};

const mapStateToProps = ({ positionReducer }) => ({
    showAlertOverlaps: positionReducer.showAlertOverlaps,
    showAlertAccept: positionReducer.showAlertAccept,
    showAlertDecline: positionReducer.showAlertDecline,
    currentEmployeeId: positionReducer.currentEmployeeId,
    currentDeclineId: positionReducer.currentDeclineId,
    showAlertDeclineAssignment: positionReducer.showAlertDeclineAssignment,
    currentDeclineAssignmentId: positionReducer.currentDeclineAssignmentId,
    loadingSweetAlert: positionReducer.loadingSweetAlert,
    paginationLength: positionReducer.paginationLength
});

export default withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapActionToProps)(JobItem))
);

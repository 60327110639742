/**
 * Notification reducer
 */
import {
    NOTIFICATION_SHOW_PANEL,
    NOTIFICATION_HIDE_PANEL,
    NOTIFICATION_LOAD_NOTIFICATIONS,
    NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE,
    NOTIFICATION_MARK_AS_READ,
    NOTIFICATION_MARK_AS_READ_SUCCESS,
    NOTIFICATION_MARK_AS_READ_FAILURE,
    NOTIFICATION_REMOVE_NOTIFICATION,
    NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS,
    NOTIFICATION_REMOVE_NOTIFICATION_FAILURE,
    NOTIFICATION_GET_NUMBER_UNREAD,
    NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS,
    NOTIFICATION_GET_NUMBER_UNREAD_FAILURE,
    NOTIFICATION_LOAD_JOB_DETAILS,
    NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS,
    NOTIFICATION_LOAD_JOB_DETAILS_FAILURE,
    NOTIFICATION_RESET_JOB_DETAILS
} from "Actions/types";

// initial state
const INIT_STATE = {
    showPanel: false,
    listOfNotifications: [],
    paginator: {},
    onLoading: false,
    onLoadingOlder: false,
    onLoadingJobDetails: false,
    onRemoving: false,
    lastId: null,
    unreadNotifications: 0,
    markedAsRead: [],
    clickedJobDetails: null,
};

const removeNotificationFromId = (notifications, id) =>
    notifications.filter((notification) => notification.id !== id);

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_SHOW_PANEL:
            return { ...state, showPanel: true };

        case NOTIFICATION_HIDE_PANEL:
            return { ...state, showPanel: false };

        case NOTIFICATION_LOAD_NOTIFICATIONS:
            return {
                ...state,
                onLoading: true,
                onLoadingOlder: action.payload.lastId !== null,
                lastId: action.payload.lastId,
            };

        case NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS:
            let { listOfNotifications, paginator } = action.payload;

            if (state.lastId !== null) {
                listOfNotifications = state.listOfNotifications.concat(
                    listOfNotifications
                );
            }

            return {
                ...state,
                listOfNotifications,
                paginator,
                onLoading: false,
                onLoadingOlder: false,
            };

        case NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                onLoading: false,
                onLoadingOlder: false,
            };

        case NOTIFICATION_REMOVE_NOTIFICATION:
            return { ...state, onRemoving: true };

        case NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                onRemoving: false,
                listOfNotifications: removeNotificationFromId(
                    state.listOfNotifications,
                    action.payload.notificationId
                ),
                unreadNotifications: !action.payload.isRead
                    ? state.unreadNotifications - 1
                    : state.unreadNotifications,
            };

        case NOTIFICATION_REMOVE_NOTIFICATION_FAILURE:
            return { ...state, onRemoving: false };

        case NOTIFICATION_GET_NUMBER_UNREAD:
            return { ...state };

        case NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS:
            return {
                ...state,
                unreadNotifications: Number(action.payload.total) - 1,
            };

        case NOTIFICATION_GET_NUMBER_UNREAD_FAILURE:
            return { ...state };

        case NOTIFICATION_MARK_AS_READ:
            return { ...state };

        case NOTIFICATION_MARK_AS_READ_SUCCESS:
            const { markedAsRead } = state;

            if (!markedAsRead.includes(action.payload)) {
                markedAsRead.push(action.payload);
            }

            return {
                ...state,
                markedAsRead,
                unreadNotifications: state.unreadNotifications - 1,
            };

        case NOTIFICATION_MARK_AS_READ_FAILURE:
            return { ...state };

        case NOTIFICATION_LOAD_JOB_DETAILS:
            return {
                ...state,
                clickedJobDetails: null,
                onLoadingJobDetails: true,
            };

        case NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS:
            return {
                ...state,
                clickedJobDetails: action.payload,
                onLoadingJobDetails: false,
            };

        case NOTIFICATION_LOAD_JOB_DETAILS_FAILURE:
            return { ...state, onLoadingJobDetails: false };

        case NOTIFICATION_RESET_JOB_DETAILS:
            return {...state, clickedJobDetails: null};
        default:
            return { ...state };
    }
};

import {
    OPEN_LOGIN_DETAIL_SETTING_POPUP,
    CLOSE_LOGIN_DETAIL_SETTING_POPUP,
    GET_LOGIN_DETAIL_SETTING,
    GET_LOGIN_DETAIL_SETTING_SUCCESS,
    UPDATE_OBJECT_LOGIN_DETAIL_SETTING,
    UPDATE_LOGIN_DETAIL_SETTING,
    UPDATE_LOGIN_DETAIL_SETTING_SUCCESS,
    UPDATE_LOGIN_DETAIL_SETTING_FAIL,
    CONFIRM_ERROR_SETTING_POPUP,
    CONFIRM_SUCCESS_SETTING_POPUP,
    UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS,
    LOAD_INIT_DATA_LOGIN_DETAIL_DONE
} from './types';

export const openLoginDetail = () => ({
    type: OPEN_LOGIN_DETAIL_SETTING_POPUP,
});

export const closeLoginDetail = () => ({
    type:  CLOSE_LOGIN_DETAIL_SETTING_POPUP
});

export const getLoginDetail = () => ({
    type: GET_LOGIN_DETAIL_SETTING
});

export const getLoginDetailSuccess = (response) => ({
    type: GET_LOGIN_DETAIL_SETTING_SUCCESS,
    payload: response
});

export const updateLoginDetailObject= (data) => ({
    type: UPDATE_OBJECT_LOGIN_DETAIL_SETTING,
    data
});

export const updateLoginDetail= (data) => ({
    type: UPDATE_LOGIN_DETAIL_SETTING,
    data
});

export const updateLoginDetailSuccess = (response) => ({
    type: UPDATE_LOGIN_DETAIL_SETTING_SUCCESS,
    payload: response
});

export const updateLoginDetailSendMailSuccess = (response) => ({
    type: UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS,
    payload: response
});

export const updateLoginDetailFail = (response) => ({
    type: UPDATE_LOGIN_DETAIL_SETTING_FAIL,
    payload: response
});

export const confirmLoginDetailErrorPopup = () => ({
    type: CONFIRM_ERROR_SETTING_POPUP
});

export const confirmLoginDetailSuccessPopup = () => ({
    type: CONFIRM_SUCCESS_SETTING_POPUP
});

export const loadInitDataDone = () => ({
    type: LOAD_INIT_DATA_LOGIN_DETAIL_DONE
});

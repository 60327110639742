import axios from 'axios';
import AppConfig from 'Constants/AppConfig';
import OAuth from 'OAuth';
import $ from 'jquery';

const oauth = new OAuth();
const REQUEST_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

const configureHeaders = (sender, urlPath, method) => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const apiCall = AppConfig.apiURL + urlPath;
    sender.defaults.headers.common.Accept        = AppConfig.headers.accept;
    sender.defaults.headers.common.Authorization = oauth.setHeader(apiCall, method);
    sender.defaults.headers.common.timezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    return sender;
};

const mergeWithDefaultParams = (params) => ({
    ...params,
    app_version: AppConfig.appVersionNumber,
    app_build: AppConfig.appBuildNumber,
});

const initClient = (urlPath, method, requiresAuth, responseType = null) => {
    if (requiresAuth === true && !oauth.obtainAccessToken()) {
        throw oauth.getErrorObjectTokenExpired();
    }

    let axiosOptions = {
        baseURL: AppConfig.apiURL,
        dataType: 'json',
    };

    if (responseType) {
        axiosOptions = {
            ...axiosOptions,
            responseType,
        };
    }

    const axiosInstance = axios.create(axiosOptions);
    configureHeaders(axiosInstance, urlPath, method);

    return axiosInstance;
};

const get = (urlPath, params = {}, requiresAuth = true) => {
    params = mergeWithDefaultParams(params);

    if (Object.keys(params).length > 0) {
        const queryParams = $.param(params);
        urlPath += `?${queryParams}`;
    }

    return initClient(urlPath, REQUEST_METHODS.GET, requiresAuth).get(
        urlPath,
        params,
    );
};

const getBlob = (urlPath, params = {}, requiresAuth = true) => {
    params = mergeWithDefaultParams(params);
    urlPath += `?${$.param(params)}`;

    return initClient(urlPath, REQUEST_METHODS.GET, requiresAuth, 'blob').get(
        urlPath,
        params,
    );
};

const post = async (urlPath, params, requiresAuth = true) => {
    params = mergeWithDefaultParams(params);

    return initClient(urlPath, REQUEST_METHODS.POST, requiresAuth).post(
        urlPath,
        params,
    );
};

const put = async (urlPath, params, requiresAuth = true) => {
    params = mergeWithDefaultParams(params);

    return initClient(urlPath, REQUEST_METHODS.PUT, requiresAuth).put(
        urlPath,
        params,
    );
};

const remove = async (urlPath, params, requiresAuth = true) => {
    params = mergeWithDefaultParams(params);

    return initClient(urlPath, REQUEST_METHODS.DELETE, requiresAuth).delete(
        urlPath,
        params,
    );
};

export default { get, getBlob, post, put, remove };

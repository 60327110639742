/**
 * Nudge seeker actions
 */
import {
    NUDGE_SEEKER_SHOW_MODAL,
    NUDGE_SEEKER_HIDE_MODAL,
    NUDGE_SEEKER_GET_NUDGABLE_LIST,
    NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS,
    NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE,
    NUDGE_SEEKER_FOR_POSITION,
    NUDGE_SEEKER_FOR_POSITION_SUCCESS,
    NUDGE_SEEKER_FOR_POSITION_FAILURE,
    NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE,
    NUDGE_SEEKER_HIDE_FAILURE_POPUP,
    ADD_SEEKER_OVER_18,
    NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS
} from './types';

export const showNudgeSeekerModal = (positionId) => ({
    type   : NUDGE_SEEKER_SHOW_MODAL,
    payload: positionId
});

export const hideNudgeSeekerModal = () => ({
    type: NUDGE_SEEKER_HIDE_MODAL
});

export const getNudgableSeekers = (positionId, filterParams, page) => ({
    type   : NUDGE_SEEKER_GET_NUDGABLE_LIST,
    payload: {positionId, filterParams, page}
});

export const getNudgableSeekersSuccess = (response) => ({
    type   : NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS,
    payload: response
});

export const getNudgableSeekersFailure = (error) => ({
    type   : NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE,
    payload: error
});

export const nudgeSeekerForPosition = (positionId, seekerId) => ({
    type   : NUDGE_SEEKER_FOR_POSITION,
    payload: {positionId, seekerId}
});

export const nudgeSeekerForPositionSuccess = (response) => ({
    type   : NUDGE_SEEKER_FOR_POSITION_SUCCESS,
    payload: response
});

export const nudgeSeekerForPositionFailure = (error) => ({
    type   : NUDGE_SEEKER_FOR_POSITION_FAILURE,
    payload: error
});

export const nudgeSeekerForPositionUnavailable = (error) => ({
    type   : NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE,
    payload: error
});

export const nudgeSeekerForOver18 = () => ({
    type   : ADD_SEEKER_OVER_18,
})

export const hideNudgeFailurePopup = () => ({
    type: NUDGE_SEEKER_HIDE_FAILURE_POPUP,
});

export const nudgeSeekerForPositionRestriction48hours = (error) => ({
  type   : NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS,
  payload: error
});

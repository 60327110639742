import API from 'Api';


export const getMessagesRequest = async  (params) => (
    await  API.get('/omni/messages',params)
);

export const createDirectMessageRequest = async (params) => (
    await API.post('/omni/messages', params)
);

import {
    OPEN_BUSINESS_DETAIL_SETTING_POPUP,
    CLOSE_BUSINESS_DETAIL_SETTING_POPUP,
    GET_BUSINESS_DETAIL_SETTING,
    GET_BUSINESS_DETAIL_SETTING_SUCCESS,
    UPDATE_OBJECT_BUSINESS_DETAIL_SETTING,
    UPDATE_BUSINESS_DETAIL_SETTING,
    UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS,
    UPDATE_BUSINESS_DETAIL_SETTING_FAIL,
    CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP,
    CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP
} from './types';


export const openBusinessDetail = () => ({
    type: OPEN_BUSINESS_DETAIL_SETTING_POPUP,
});

export const closeBusinessDetail = () => ({
    type:  CLOSE_BUSINESS_DETAIL_SETTING_POPUP
});

export const getBusinessDetail = (id) => ({
    type: GET_BUSINESS_DETAIL_SETTING,
    payload: id
});

export const getBusinessDetailSuccess = (response) => ({
    type: GET_BUSINESS_DETAIL_SETTING_SUCCESS,
    payload: response
});

export const updateBussinessDetailObject = (data) => ({
    type: UPDATE_OBJECT_BUSINESS_DETAIL_SETTING,
    data
});

export const updateBusinessDetail= (data) => ({
    type: UPDATE_BUSINESS_DETAIL_SETTING,
    data
});

export const updateBusinessDetailSuccess = (response) => ({
    type: UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS,
    payload: response
});

export const updateBusinessDetailFail = (response) => ({
    type: UPDATE_BUSINESS_DETAIL_SETTING_FAIL,
    payload: response
});

export const confirmBusinessDetailErrorPopup = () => ({
    type: CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP
});

export const confirmBusinessDetailSuccessPopup = () => ({
    type: CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP
});

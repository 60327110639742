/**
 * Page Title Bar Component
 * Used To Display Page Title & Breadcrumbs
 */
import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

// intl messages
import IntlMessages from 'Util/IntlMessages';

class PageTitleBar extends Component {

    /* get display string */
    getDisplayString = (sub) => {
        const arr = sub.split("-");
        if (arr.length > 1) {
            return <IntlMessages id={`sidebar.${arr[0].charAt(0) + arr[0].slice(1) + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)}`} />;
        } 
            return <IntlMessages id={`sidebar.${sub.charAt(0) + sub.slice(1)}`} />;
        

    };

    /* get url string */
    getUrlString = (path, sub, index) => {
        if (index === 0) {
            return '/';
        } 
            return `/${  path.split(sub)[0]  }${sub}`;
        
    };

    onClickBreadCrumb = (url, index) => {
        // Purpose for disable click 2nd crumb
        if(index === 1) return false;
        return this.props.history.push(url);
    }

    render() {
        const { title, match, enableBreadCrumb, ignorePathIndex, myGig } = this.props;
        const path = match.path.substr(1);
        const subPath = path.split('/');

        if (ignorePathIndex) {
            if (ignorePathIndex > -1 && typeof subPath[ignorePathIndex] !== undefined) {
                subPath.splice(ignorePathIndex, 1);
            }
        }

        return (
            <div className="page-title d-flex justify-content-between align-items-center">
                {(title || myGig) && 
                    <div className="page-title-wrap">
                        <h2 className="mygig">{myGig}</h2>
                        <span className="jobtype">{title}</span>
                    </div>
                }
                {enableBreadCrumb &&
                    <Breadcrumb className="mb-0 tour-step-7" tag="nav">
                        {subPath.map((sub, index) => <BreadcrumbItem
                                active={subPath.length === index + 1}
                                tag={subPath.length === index + 1 ? "span" : "a"} key={index}
                                onClick={() => this.onClickBreadCrumb(this.getUrlString(path, sub, index), index)}
                            >
                                {this.getDisplayString(sub)}
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>
                }
            </div>
        );
    }
}

// default props value
PageTitleBar.defaultProps = {
    enableBreadCrumb: true
};

export default PageTitleBar;

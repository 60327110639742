/**
 * Location Reducer
 */
import {
    GET_LOCATION,
    GET_LOCATION_FAILURE,
    GET_LOCATION_SUCCESS,
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAILURE,
    EDIT_LOCATION,
    EDIT_LOCATION_SUCCESS,
    EDIT_LOCATION_FAILURE,
    REMOVE_LOCATION,
    REMOVE_SUCCESS,
    REMOVE_FAILURE,
    CONFIRM_ERROR_DELETE_POPUP,
    LOAD_MAP,
    INIT_STATE_LOCATION
} from 'Actions/types';



// initial state
const INIT_STATE = {
    allLocation: null,
    errorsServer: null,
    remove_success: null,
    errorsServerDelete: null,
    loadMap: false,
    open: false,
    addNewModal: false,
    getLocationSuccess: false,
    onDeleting: false,
    loading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOCATION:
            return { ...state };

        case GET_LOCATION_SUCCESS:
            return { ...state, allLocation: action.payload.data, addNewModal: false, getLocationSuccess: true, open: false };

        case REMOVE_LOCATION:
            return { ...state, payload: action, errorsServerDelete: null, onDeleting: true };

        case GET_LOCATION_FAILURE:
            return { ...state, errorsServer: action.payload.response.data };

        case REMOVE_SUCCESS:
            return { ...state, onDeleting: false };

        case REMOVE_FAILURE:
            return { ...state, errorsServerDelete: action.payload.response.data, onDeleting: false };

        case EDIT_LOCATION:
            return { ...state,loading:true, payload: action, errorsServer: null };

        case EDIT_LOCATION_SUCCESS    :
            return {...state, loading: false}

        case EDIT_LOCATION_FAILURE:
            return { ...state,loading: false, errorsServer: action.payload.response.data };

        case ADD_LOCATION:
            return { ...state,loading:true, payload: action, errorsServer: null };

        case ADD_LOCATION_SUCCESS:
            return {...state, loading: false}
        
        case ADD_LOCATION_FAILURE:
            return { ...state,loading: false, errorsServer: action.payload.response.data };

        case CONFIRM_ERROR_DELETE_POPUP:
            return { ...state, errorsServerDelete: null };

        case LOAD_MAP:
            return { ...state, loadMap: true };

        case INIT_STATE_LOCATION:
            return { ...state, getLocationSuccess: false };
            
        default: return { ...state };
    }
};

/**
 * Favourite actions
 */
import {
  FAVOURITE_EMPLOYEE
} from './types';

export const favouriteEmployee = (employeeID, skillIDs) => ({
  type: FAVOURITE_EMPLOYEE,
  payload: { employeeID, skillIDs }
});

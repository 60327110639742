import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import IntlMessages from 'Util/IntlMessages';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from '@material-ui/core/Modal';
import {
    Modal as ReactstrapModal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import Spinner from 'Components/Model/Spinner';
import InternalEmployeeItem from './Item';
import InternalEmployee from 'Model/internalEmployee';
import UpdateInternalEmployeeForm from "./components/UpdateInternalEmployeeForm";
import DeleteInternalEmployeeDialog from "./components/DeleteInternalEmployeeDialog";

import {
    updateInternalEmployee,
    deleteInternalEmployee,
    getInternalEmployeeList, 
    getLocation
 } from "Actions";

class ListSeekers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showUpdateFormModal: false,
            openDeleteDialog: false,
            errors: {},
            selectedSkills: [],
            locationId: 0,
            editInternalEmployee: null,
            internalEmployeeId: 0,
            showInternalEmployeeSuccessAlert: false,
            showInternalEmployeeFailureAlert: false,
            messageKeySuccess: "",
            messageKeyFailure: ""
        };
    }

    formatSeekerData(employees) {
        return employees.map((employee) => InternalEmployee.createFromApiResponseData(employee));
    };

    openUpdateInternalEmployeeModal = (employee) => {
        const skillIds = [];

        employee.employeeSkills.map(skill => (
            skillIds.push(skill.skill_id)
        ));
        this.setState({
            showUpdateFormModal: true,
            editInternalEmployee: employee,
            selectedSkills: skillIds,
            oldEmail: employee.email,
            locationId: employee.location
            // locationId: 
        });
        // setTimeout(()=>this.props.handleCloseParentPopup(), 5000)
        
    };

    handleUpdateInternalEmployee = () => {
        const { editInternalEmployee, selectedSkills , oldEmail, locationId} = this.state;
        const { firstName, secondName, email, phone, internalEmployeeId } = editInternalEmployee;
        const errors = InternalEmployee.validateFields(editInternalEmployee);

        if (Object.keys(errors).length === 0) {
            const params = {
                internalEmployeeId,
                firstName: firstName.trim(),
                secondName: secondName.trim(),
                email: email.trim(),
                phone: phone.trim(),
                skills: selectedSkills,
                oldEmail,
                location: locationId
            };

            this.props.updateInternalEmployee(params,
                (messageKey) => this.showSuccessAlert(messageKey || "internalEmployee.update.success"),
                (messageKey) => this.showErrorAlert(messageKey || "internalEmployee.update.failure")
            );
        }

        this.setState({ errors });
    };

    showSuccessAlert(messageKey) {
        this.setState({
            showInternalEmployeeSuccessAlert: true,
            messageKeySuccess: messageKey
        });
        this.closeUpdateInternalEmployeeModal();
        this.props.getInternalEmployeeList();
    }

    showErrorAlert(messageKey) {
        this.setState({
            showInternalEmployeeFailureAlert: true,
            messageKeyFailure: messageKey
        });
        this.closeUpdateInternalEmployeeModal();
    }

    closeUpdateInternalEmployeeModal = () => {
        this.setState({
            showUpdateFormModal: false,
            selectedSkills: []
        });
    };

    onUpdateInternalEmployeeDetail = (key, value) => {
        const { editInternalEmployee } = this.state;
        this.setState({
            editInternalEmployee: {
                ...editInternalEmployee,
                [key]: value
            }
        });
    };

    onChangeSelectedSkills = (event, index) => {
        const { selectedSkills } = this.state;

        this.setState({
            selectedSkills: selectedSkills.slice(0, index).concat(Number(event.target.value)).concat(selectedSkills.slice(index + 1))
        });
    }
    onChangeSelectedLocation = (event) => {        
        this.setState({
            locationId : event.target.value
        })
    }
    
    addSkill = () => {
        const skillToAdd = this.props.listOfSkills.find(skill => !this.state.selectedSkills.includes(skill.id));
        if (skillToAdd) {
            this.setState({
                selectedSkills: this.state.selectedSkills.concat(skillToAdd.id)
            });
        }
    }

    removeSkill = (index) => {
        const { selectedSkills } = this.state;
        this.setState({
            selectedSkills: selectedSkills.slice(0, index).concat(selectedSkills.slice(index + 1))
        });
    }

    openDeleteDialog = (internalEmployeeId) => {
        this.setState({
            openDeleteDialog: true,
            internalEmployeeId
        });
    }

    closeDeleteDialog() {
        this.setState({
            openDeleteDialog: false,
            internalEmployeeId: null
        });
    }

    confirmDelete() {
        const { internalEmployeeId } = this.state;
        let statusAlert = {};
        this.props.deleteInternalEmployee(internalEmployeeId);
        this.closeDeleteDialog();
        
        if (this.props.hasDeleteError) {
            statusAlert = {
                showInternalEmployeeSuccessAlert: true,
                messageKeySuccess: "internalEmployee.delete.success"
            };
        } else {
            statusAlert = {
                showInternalEmployeeFailureAlert: true,
                messageKeyFailure: "internalEmployee.delete.failure"
            };
        }
        this.setState(statusAlert);
        this.props.getInternalEmployeeList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        return  nextProps.listSeekers && nextProps.listSeekers.length !== this.props.listSeekers;
    }

    render() {
        const {
            showUpdateFormModal, editInternalEmployee, errors,
            selectedSkills, openDeleteDialog,
            internalEmployeeId, messageKeySuccess, messageKeyFailure,
            showInternalEmployeeSuccessAlert, showInternalEmployeeFailureAlert,
            onLoading, locationId
        } = this.state;
        const { listOfSkills,  listOfLocations} = this.props;
        var listSeekers = [];
        if(this.props.listSeekers) {
             listSeekers  = this.formatSeekerData(this.props.listSeekers);
        }
        
        
        return (
            <div className="list-seekers">
                <Modal hideBackdrop open={openDeleteDialog}>
                    <DeleteInternalEmployeeDialog
                        openDeleteDialog
                        closeDeleteDialog={() => this.closeDeleteDialog()}
                        internalEmployeeId={internalEmployeeId}
                        confirmDelete={() => this.confirmDelete()}
                    />
                </Modal>

                <Spinner
                    spinnerWidth={70}
                    show={onLoading}
                />
                {listSeekers.map((employee) => {
                    const {employeeId, internalEmployeeId} = employee;
                    return (
                        <InternalEmployeeItem
                            key={`seeker-item-${employeeId}`}
                            employee={employee}
                            onUpdate={() => this.openUpdateInternalEmployeeModal(employee)}
                            onDelete={() => this.openDeleteDialog(internalEmployeeId)}
                        />
                    );
                })}
                <Modal hideBackdrop open={showUpdateFormModal}>
                    <Dialog open={showUpdateFormModal} isOpen onClose={() => this.closeUpdateInternalEmployeeModal()}>
                        <ModalHeader toggle={() => this.closeUpdateInternalEmployeeModal()}>
                            <span className="black font-bold user-font-size-header">
                                <IntlMessages id="internalEmployee.title.update" />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <UpdateInternalEmployeeForm
                                employee={editInternalEmployee}
                                errors={errors}
                                selectedSkills={selectedSkills}
                                locationId={locationId}
                                listOfSkills={listOfSkills}
                                listOfLocations={listOfLocations}
                                onUpdateInternalEmployeeDetail={this.onUpdateInternalEmployeeDetail.bind(this)}
                                onChangeSelectedSkills={this.onChangeSelectedSkills.bind(this)}
                                onChangeSelectedLocation ={this.onChangeSelectedLocation.bind(this)}
                                addSkill={this.addSkill.bind(this)}
                                removeSkill={this.removeSkill.bind(this)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                
                                color="primary"
                                className="text-white gig-button orange line-height-1"
                                onClick={() => this.handleUpdateInternalEmployee()}>
                                <IntlMessages id="button.update" />
                            </Button>
                            <Button
                                
                                className="text-white gig-button black line-height-1"
                                onClick={() => this.closeUpdateInternalEmployeeModal()}>
                                <IntlMessages id="button.cancel" />
                            </Button>
                        </ModalFooter>
                    </Dialog>
                </Modal>

                <Modal hideBackdrop open={showInternalEmployeeSuccessAlert}>
                    <SweetAlert
                        success
                        show
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                        title=""
                        btnSize="sm"
                        onConfirm={() => this.setState({ showInternalEmployeeSuccessAlert: false })}>
                        <IntlMessages id={messageKeySuccess} />
                    </SweetAlert>
                </Modal>

                <Modal hideBackdrop open={showInternalEmployeeFailureAlert}>
                    <SweetAlert
                        error
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                        show
                        title=""
                        btnSize="sm"
                        onConfirm={() => this.setState({ showInternalEmployeeFailureAlert: false })}>
                        <IntlMessages id={messageKeyFailure} />
                    </SweetAlert>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ internalEmployeeReducer, location }) => ({
        existEmail: internalEmployeeReducer.existEmail,
        onLoading: internalEmployeeReducer.onLoading,
        hasDeleteError: internalEmployeeReducer.hasDeleteError,
        listOfLocations: location.allLocation ?  location.allLocation.data : null ,
    });

const mapActionToProps = {
    updateInternalEmployee,
    deleteInternalEmployee,
    getInternalEmployeeList,
    getLocation
};

export default connect(mapStateToProps, mapActionToProps)(ListSeekers);

import API from 'Api';

export const sendMailPendingRemoteRequest = async(startDate, endDate, locationId) => {
    const params = {
        start_date: startDate,
        end_date  : endDate,
        locationId
    };
    if(!locationId) {
        delete params.locationId
    }

    return await API.get('/employers/excel/sendMailTimesheetPending', params, true)
        .then(response => response)
        .catch(error => error);
};

/**
 * Sidebar Content
 */
import React, { Component } from "react";
import List from "@material-ui/core/List";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NavMenuItem from "./NavMenuItem";

// redux actions
import { onToggleMenu } from "Actions";

class SidebarContent extends Component {
  toggleMenu(menu, stateCategory) {
    const data = {
      menu,
      stateCategory,
    };
    this.props.onToggleMenu(data);
  }

  render() {
    const { sidebarMenus } = this.props.sidebar;
    return (
      <div className="rct-sidebar-nav">
        <nav className="navigation">
          <List className="rct-mainMenu p-0 m-0 list-unstyled">
            {sidebarMenus.mainMenu.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "mainMenu")}
              />
            ))}
          </List>
        </nav>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ sidebar }) => ({ sidebar });

export default withRouter(
  connect(mapStateToProps, {
    onToggleMenu,
  })(SidebarContent)
);

/**
 * Modal All Jobs Reducer
 */
import {
    MODAL_ALL_JOBS_OPEN,
    MODAL_ALL_JOBS_CLOSE,
    GET_ALL_JOBS,
    GET_ALL_JOBS_SUCCESS,
    GET_ALL_JOBS_FAILURE,
    CANCEL_JOB_SUCCESS,
    ACCEPT_CANDIDATE_SUCCESS,
    DECLINE_CANDIDATE_SUCCESS,
    GET_INIT_JOB
} from 'Actions/types';

// initial state
const INIT_STATE = {
    isOpenModal: false,
    allJobs: [],
    onLoading: false,
    initListJob: false,
    pagination: {}
};

export default (state = INIT_STATE, action) => {
    let allJobs;

    switch (action.type) {
        case MODAL_ALL_JOBS_OPEN:
            return { ...state, isOpenModal: true };

        case MODAL_ALL_JOBS_CLOSE:
            return { ...state, isOpenModal: false };

        case GET_ALL_JOBS:
            return { ...state, onLoading: true, allJobs: INIT_STATE.allJobs };

        case GET_ALL_JOBS_SUCCESS:
            let { pagination } = state;

            if (action.payload.pagination) {
                pagination = action.payload.pagination;
            }

            return {
                ...state,
                pagination,
                onLoading: false,
                allJobs: action.payload.listJobs
            };

        case GET_INIT_JOB:
            return {
                ...state,
                initListJob: true
            }


        case GET_ALL_JOBS_FAILURE:
            return { ...state, onLoading: false, allJobs: INIT_STATE.allJobs };

        case CANCEL_JOB_SUCCESS:
            return {
                ...state,
                allJobs: state.allJobs.filter(job => job.position_id !== action.payload.jobId)
            };

        case ACCEPT_CANDIDATE_SUCCESS:
            const candidate = action.payload;
            allJobs         = state.allJobs.map((job) => {
                if (job.position_id === candidate.position_id) {
                    job.assignments_filled += 1;

                    if (job.responses_pending > 0) {
                        job.responses_pending -= 1;
                    }
                }

                return job;
            });

            return { ...state, allJobs };

        case DECLINE_CANDIDATE_SUCCESS:
            const { positionId } = action.payload;
            allJobs              = state.allJobs.map((job) => {
                if (job.position_id === positionId && job.responses_pending > 0) {
                    job.responses_pending -= 1;
                }

                return job;
            });

            return { ...state, allJobs };

        default: return { ...state};
    }
};

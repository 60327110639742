import React from 'react';

class BreakTime extends React.Component {
    render() {
        const options = [];
            
        for (let i = 0; i <= 60; i += 15) {
            options.push(<option key={`breakroutes-time-${i}`} value={i}>{i}</option>);
        }

        return options;
    }
}

export default BreakTime;

/**
 * Nav Menu Item
 */
import React, { Fragment, Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// intl messages
import IntlMessages from "Util/IntlMessages";

class NavMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenuOpen: false,
        };
    }

    /**
     * On Toggle Collapse Menu
     */
    onToggleCollapseMenu() {
        this.setState({ subMenuOpen: !this.state.subMenuOpen });
    }

    render() {
        let { menu, onToggleMenu } = this.props;
        const { subMenuOpen } = this.state;
        const currentPath = window.location.pathname;
        menu.open = true;

        if (menu.child_routes != null) {
            return (
                <Fragment>
                    <ListItem
                        button
                        component="li"
                        onClick={onToggleMenu}
                        className={classNames({
                            "item-active": currentPath == menu.path,
                        })}
                        style={{ padding: "12px 18.75px 12px 16px" }}
                    >
                        <ListItemIcon className="menu-icon">
                            <i
                                className={
                                    currentPath.includes(menu.path)
                                        ? menu.menu_active_icon
                                        : menu.menu_icon
                                }
                            />
                        </ListItemIcon>
                        <span className="menu" style={{fontWeight:'700'}}>
                            <IntlMessages id={menu.menu_title} />
                        </span>
                        {/* <i className={"material-icons ml-10 mr-0 pointer"}>
                            expand_more
                        </i> */}
                    </ListItem>
                    <Collapse in={menu.open} timeout="auto">
                        <List className="sub-menu list-unstyled">
                            {menu.child_routes.map((subMenu, index) => {
                                if (!subMenu.child_routes) {
                                    let isActive = currentPath == subMenu.path
                                    if (subMenu.sub_routes && !isActive) {
                                        isActive = !!subMenu.sub_routes.find(subRoute => subRoute.path === currentPath)
                                    }
                                    return (
                                        <ListItem
                                            button
                                            component="li"
                                            key={index}
                                            className={classNames({
                                                "item-active": isActive,
                                            })}
                                        >
                                            <NavLink
                                                // activeClassName="item-active"
                                                to={subMenu.path}
                                            >
                                                <span className="menu">
                                                    <IntlMessages
                                                        id={subMenu.menu_title}
                                                    />
                                                </span>
                                            </NavLink>
                                        </ListItem>
                                    );
                                }
                                return (
                                    <Fragment key={index}>
                                        <ListItem
                                            button
                                            component="li"
                                            onClick={() =>
                                                this.onToggleCollapseMenu()
                                            }
                                            className={classNames({
                                                "item-active": subMenuOpen,
                                            })}
                                        >
                                            <span className="menu">
                                                <IntlMessages
                                                    id={subMenu.menu_title}
                                                />
                                            </span>
                                            <i className="ti-angle-right side-arrow" />
                                        </ListItem>
                                        <Collapse
                                            in={subMenuOpen}
                                            timeout="auto"
                                        >
                                            {subMenu.child_routes.map(
                                                (nestedMenu, nestedKey) => (
                                                    <ListItem
                                                        button
                                                        component="li"
                                                        key={nestedKey}
                                                    >
                                                        <NavLink
                                                            // activeClassName="item-active"
                                                            to={nestedMenu.path}
                                                        >
                                                            <span className="menu pl-20">
                                                                <IntlMessages
                                                                    id={
                                                                        nestedMenu.menu_title
                                                                    }
                                                                />
                                                            </span>
                                                        </NavLink>
                                                    </ListItem>
                                                )
                                            )}
                                        </Collapse>
                                    </Fragment>
                                );
                            })}
                        </List>
                    </Collapse>
                </Fragment>
            );
        }

        let menuClassName = {
            "without-menu": true,
        };

        if (menu.path === currentPath) {
            menuClassName = {
                ...menuClassName,
                "item-active": menu.path,
            };
        }

        return (
            <ListItem
                button
                component="li"
                className={classNames(menuClassName)}
            >
                <NavLink activeClassName="item-active" to={menu.path}>
                    <ListItemIcon className="menu-icon">
                        <i
                            className={
                                menu.path === currentPath
                                    ? menu.menu_active_icon
                                    : menu.menu_icon
                            }
                        />
                    </ListItemIcon>
                    <span className="menu">
                        <IntlMessages id={menu.menu_title} />
                    </span>
                </NavLink>
            </ListItem>
        );
    }
}

export default NavMenuItem;

import ApiCodes from 'Constants/ApiCodes';

/**
 * function check all success status group of response
 * @param {*} response
 *
 * @return Boolean() based on code
 */

export const isSuccess = (response) => {

  if ( response.hasOwnProperty('response')) {
         response = response.response;
  }
  const { code } = response.data;
  const { OK, UPDATED, CREATED, DELETED } = ApiCodes;

  switch (code) {
    case OK:
    case UPDATED:
    case CREATED:
    case DELETED:
      return true;

    default:
      return false;
  }
};

/**
 * function check all expected error group of response
 * @param {*} response
 *
 * @return Boolean() based on code
 */

export const isExpectedError = (response) => {
  const { code } = response.data;

  const {
    INVALID_LOGIN, AUTHENTICATION_FAILED,
    UNSUPPORTED_APP_VERSION, STATE_CHANGE_FORBIDDEN
  } = ApiCodes;

  switch (code) {
    case INVALID_LOGIN:
    case AUTHENTICATION_FAILED:
    case UNSUPPORTED_APP_VERSION:
    case STATE_CHANGE_FORBIDDEN:
      return true;

    default:
      return false;
  }
};

/**
 * function check all unexpected error group of response
 * @param {*} response
 *
 * @return Boolean() based on code
 */

export const isUnExpectedError = (response) => {
  const { code } = response.data;

  const {
    SERVER_ERROR, AUTHORISATION_FAILED,
    NOT_FOUND, INVALID_SIGNATURE, INVALID_RELATIONSHIP,
    INVALID_API_VERSION, UNKNOWN_ERROR
  } = ApiCodes;

  switch (code) {
    case SERVER_ERROR:
    case AUTHORISATION_FAILED:
    case NOT_FOUND:
    case INVALID_SIGNATURE:
    case INVALID_RELATIONSHIP:
    case INVALID_API_VERSION:
    case UNKNOWN_ERROR:
      return true;

    default:
      return false;
  }
};

/**
 * function check bad request response
 * @param {*} response
 * @constant response is no data of response , only code
 * @return Boolean() based on code
 */

export const isBadRequest = (response) => {
  const { code } = response;
  const { BAD_REQUEST } = ApiCodes;
  switch (code) {
    case BAD_REQUEST:
      return true;
    default:
      return false;
  }
};

export default { isSuccess, isExpectedError, isUnExpectedError, isBadRequest };

/**
 * Excel Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    EXCEL_DOWNLOAD_TIMESHEET_PENDING,
    EXCEL_DOWNLOAD_TIMESHEET_APPROVED,
    EXCEL_DOWNLOAD_TIMESHEET_WEEKLY,
    EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY,
    EXCEL_DOWNLOAD_PENDING_JOB_DAILY,
    EXCEL_DOWNLOAD_APPLIED,
    EXCEL_DOWNLOAD_CONFIRMED
} from 'Actions/types';

import {
    excelDownloadTimesheetPendingSuccess,
    showErrorResponse,
    hideRootSpinner
} from 'Actions';

import DownloadHelper from 'Helpers/Download';

import {
    getExcelPendingRemoteRequest,
    getExcelApprovedRemoteRequest,
    getExcelWeeklyRemoteRequest,
    getExcelDailyUpcomingJobRequest,
    getExcelDailyPendingJobRequest,
    getExcelAppliedRequest,
    getExcelConfirmedRequest
} from 'Services/employers/EmployerExcelService';

function* handleDownloadExcelTimesheetPending({ payload }) {
    try {
        const { startDate, endDate, internal, locationId } = payload;
        const response               = +locationId ? yield call(getExcelPendingRemoteRequest, startDate, endDate, internal, locationId)
                                                   : yield call(getExcelPendingRemoteRequest, startDate, endDate, internal);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelTimesheetApproved({ payload }) {
    try {
        const { startDate, endDate, locationId } = payload;
        const response               = +locationId ? yield call(getExcelApprovedRemoteRequest, startDate, endDate,  locationId)
                                                   : yield call(getExcelApprovedRemoteRequest, startDate, endDate);
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelTimesheetWeekly({ payload }) {
    try {
        const { startDate, endDate} = payload;
        const response               = yield call(getExcelWeeklyRemoteRequest, startDate, endDate)
                                                   
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelUpcomingJobDaily({ payload }) {
    try {
        const { date, jobType} = payload;
        const response               = yield call(getExcelDailyUpcomingJobRequest, date, jobType)
                                                   
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelPendingJobDaily({ payload }) {
    try {
        const { date} = payload;
        const response               = yield call(getExcelDailyPendingJobRequest, date)
                                                   
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelApplied({ payload }) {
    try {
        const { positionId, type } = payload;
        const response               = yield call(getExcelAppliedRequest, positionId, type)
                                                   
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleDownloadExcelConfirmed({ payload }) {
    try {
        const { positionId, type } = payload;
        const response               = yield call(getExcelConfirmedRequest, positionId, type)
                                                   
        const { data }               = response.data;

        yield put(hideRootSpinner());

        DownloadHelper.forceDownload(data.content, data.file);
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}
/**
 * Watch download excel timesheet pending
 */
export function* watchDownloadExcelTimesheetPending() {
    yield takeEvery(EXCEL_DOWNLOAD_TIMESHEET_PENDING, handleDownloadExcelTimesheetPending);
}

/**
 * Watch download excel timesheet approved
 */
 export function* watchDownloadExcelTimesheetApproved() {
    yield takeEvery(EXCEL_DOWNLOAD_TIMESHEET_APPROVED, handleDownloadExcelTimesheetApproved);
}

/**
 * Watch download excel timesheet weekly
 */
export function* watchDownloadExcelTimesheetWeekly() {
    yield takeEvery(EXCEL_DOWNLOAD_TIMESHEET_WEEKLY, handleDownloadExcelTimesheetWeekly);
}

/**
 * Watch download excel upcoming job daily
 */
export function* watchDownloadExcelUpcomingJobDaily() {
    yield takeEvery(EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY, handleDownloadExcelUpcomingJobDaily);
}

/**
 * Watch download excel pending job daily
 */
export function* watchDownloadExcelPendingJobDaily() {
    yield takeEvery(EXCEL_DOWNLOAD_PENDING_JOB_DAILY, handleDownloadExcelPendingJobDaily);
}
/**
 * Watch download excel applied seeker
 */
 export function* watchDownloadExcelApplied() {
    yield takeEvery(EXCEL_DOWNLOAD_APPLIED, handleDownloadExcelApplied);
}

/**
 * Watch download excel confirmed seeker
 */
 export function* watchDownloadExcelConfirmed() {
    yield takeEvery(EXCEL_DOWNLOAD_CONFIRMED, handleDownloadExcelConfirmed);
}
/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchDownloadExcelTimesheetPending),
        fork(watchDownloadExcelTimesheetApproved),
        fork(watchDownloadExcelTimesheetWeekly),
        fork(watchDownloadExcelUpcomingJobDaily),
        fork(watchDownloadExcelPendingJobDaily),
        fork(watchDownloadExcelApplied),
        fork(watchDownloadExcelConfirmed)
    ]);
}

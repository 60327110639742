/**
 * Location Sagas
 */
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isSuccess } from 'Helpers/ResponseStatus';

import {
    CREATE_DIRECT_MESSAGE,
    GET_DIRECT_MESSAGE,


} from 'Actions/types';

import {
    getDirectMessageSuccess,
    showErrorResponse,
    createDirectMessageSuccess,
    getDirectMessage,
    getConversationList

} from 'Actions';

import {
    getMessagesRequest,
    createDirectMessageRequest

} from 'Services/omni/MessageService';


/**
 * Get Location From Server
 */
function* handleGetDirectMessage({ payload }) {
    try {
        const { conversationId ,clickMessage} = payload;
        const response = yield call(getMessagesRequest, { conversation_id: conversationId, clickMessage: clickMessage });
        if (isSuccess(response)) {
            yield put(getDirectMessageSuccess(response.data.data));
            yield put(getConversationList());
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

function* handleCreateDirectMessage({ payload }) {
    try {
        const { params } = payload;
        const response = yield call(createDirectMessageRequest, params);
        if (isSuccess(response)) {
            yield put(createDirectMessageSuccess(response.data.data));
            yield put(getDirectMessage(params.conversation_id));
        }
    } catch (error) {
        yield put(showErrorResponse(error));
    }
}

/**
 * Handle Add conversation
 */
export function* watchGetDirectMessage() {
    yield takeEvery(GET_DIRECT_MESSAGE, handleGetDirectMessage);
}

export function* watchCreateDirectMessage() {
    yield takeEvery(CREATE_DIRECT_MESSAGE, handleCreateDirectMessage);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetDirectMessage),
        fork(watchCreateDirectMessage)
    ]);
}

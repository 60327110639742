import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { injectIntl, FormattedMessage } from "react-intl";

import Warning from "Assets/img/SweetAlert/warning.png";
import moment from "moment";
import className from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import SweetAlert from "react-bootstrap-sweetalert";
import Popover from "@material-ui/core/es/Popover/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import MyGig from "Constants/MyGig";
import IntlMessages from "Util/IntlMessages";
import NudgeSeekerModal from "Components/Model/NudgeSeeker";
import AddSeekerModal from "Components/Model/AddSeeker";
import { RctCard, RctCardContent } from "Components/RctCard";
import { IconThreeDotCircle } from "Components/Icons/IconThreeDotCircle";
import gigRoles from "Helpers/gigroles";
import { currentPage } from "Helpers/RouterChecker";
import ScheduleHelper from "Helpers/Schedule";
import { roundByDecimals } from "Helpers/helpers";
import DatePickerImage from "Assets/img/datepicker.png";
import Modal from "@material-ui/core/Modal";

import JobDetails from "./JobDetails";
import ListSeekerTabs from "./ListSeekerTabs";
import ModalShift from "./ModalShift";
import AppConfig from "../../constants/AppConfig";

import {
  cancelJob,
  getCandidatesByPosition,
  getDeclinedCandidatesByPosition,
  getAssignmentsByPosition,
  openEditJob,
  openRepostJob,
  getJobById,
  sendCurrentPosition,
  showNudgeSeekerModal,
  showAddSeekerModal,
  checkOverlaps,
  closeAllPopUps,
  acceptCandidate,
  declineAssignment,
  declineCandidate,
  openMessageModal,
  openDeclinePopUp,
  openDeclineAssignmentPopUp,
  openNoShow,
  noShow,
  showSplitShiftsConfirmPopup,
  doPromoteJob,
  doUnpromoteJob,
  switchJobStatus,
  getInfoSeekerInPosition,
  hideWarningWorkingSixDay,
  checkWorkingSixDay,
  checkSeekerAcceptToJob,
} from "Actions";
import OAuth from "OAuth";

const oauth = new OAuth();
const styles = (theme) => ({
  borderRadiusPopOver: {
    borderRadius: 5,
  },
  marginTooltip: {
    marginTop: 40,
  },
});

class JobCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorOptionsEl: null,
      anchorJobDetailsEl: null,
      isShowCancelJobAlert: false,
      openModalShift: false,
      anchorcalendarmodal: null,
      isPrivate: false,
      collapse: false,
      collapseKey: false,
      showPostToGigAlert: false,
      isShowCancelAlert: false,
      isCopied: false,
    };
  }

  componentDidMount() {
    if (this.props.autoExpand === true) {
      this.onClickJobTitle();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.autoExpand === true && this.props.autoExpand === false) {
      this.onClickJobTitle();
    }
    this.setState({ isCopied: false });
  }
  openJobDetailsPopover = (event) => {
    this.setState({ anchorJobDetailsEl: event.currentTarget });
    this.forceUpdate();
  };

  closeJobDetailsPopover = () => {
    this.setState({ anchorJobDetailsEl: null });
    this.forceUpdate();
  };

  openPopoverOptions = (event) => {
    this.setState({ anchorOptionsEl: event.currentTarget });
    this.forceUpdate();
  };

  closePopoverOtions = () => {
    this.setState({ anchorOptionsEl: null });
    this.forceUpdate();
  };

  handleShowNudgeSeeker = (positionId) => {
    this.props.showNudgeSeekerModal(positionId);
    this.forceUpdate();
  };

  handleShowAddSeeker = (positionId) => {
    const { job, currentPositionId } = this.props;
    this.props.showAddSeekerModal(positionId);
    if (job.position_id !== currentPositionId) {
      this.props.sendCurrentPosition(job.position_id, false);
    }
    this.forceUpdate();
  };

  haveActiveJobAtPendingPage = () => {
    const { job, isPendingPage } = this.props;
    const { schedule = [] } = job;
    let haveActiveShift = false;

    const schedules = ScheduleHelper.removePastSchedulesMoment(schedule);

    if (schedules.length) haveActiveShift = true;

    if (isPendingPage && haveActiveShift) return true;

    return false;
  };

  attempToEditJob = () => {
    const { job, currentPositionId } = this.props;

    if (job.position_id !== currentPositionId) {
      this.props.sendCurrentPosition(job.position_id, false);
    }

    this.props.getJobById(job.position_id);
    this.props.openEditJob();
  };
  showAlert = () => {
    const { jobType } = this.props;
    if (jobType === MyGig.jobType.POSTED) {
      this.setState({
        showPostToGigAlert: true,
      });
    }
  };
  toggleCancelAlert = () => {
    this.setState({
      showPostToGigAlert: false,
    });
  };

  handleEditJob = () => {
    const { disableEdit } = this.props;

    if (this.haveActiveJobAtPendingPage()) {
      this.attempToEditJob();
    } else {
      if (disableEdit) return false;
      this.attempToEditJob();
    }
  };

  handleChangePrivate = (value) => {
    const { jobType } = this.props;
    const { is_private, shift_id } = this.props.job;
    if (jobType === MyGig.jobType.POSTED) {
      this.props.switchJobStatus({
        is_private: value,
        shift_id: shift_id,
        jobType: jobType,
        screenName: "JobCard",
      });
    }
  };

  handleRepostJob = () => {
    if (this.props.disableRespost) return false;
    this.props.getJobById(this.props.job.position_id);
    this.props.openRepostJob();
  };

  handlePromotedJob = (positionId, isPromoted) => {
    isPromoted
      ? this.props.doUnpromoteJob(positionId)
      : this.props.doPromoteJob(positionId);
  };

  toggleCancelJobAlert = () => {
    // if (this.props.disableDelete) return false;
    // if (
    //     currentPage(this.props.history.location) ===
    //     MyGig.currentPage.calendarPage
    // ) {
    //     this.closePopoverOtions();
    // }
    this.setState({
      isShowCancelJobAlert: !this.state.isShowCancelJobAlert,
    });

    this.forceUpdate();
  };

  handleCancelJob = () => {
    this.toggleCancelJobAlert();
    this.props.cancelJob(this.props.job);
  };

  handleOpenModalShift = (event) => {
    this.setState({
      openModalShift: true,
      anchorcalendarmodal: event.currentTarget,
    });

    this.forceUpdate();
  };

  handleCloseModalShift = () => {
    this.setState({ openModalShift: false });
    this.forceUpdate();
  };

  getAvailableSchedules = () => {
    const { jobType, job } = this.props;
    let { schedule } = job;

    if (
      jobType !== MyGig.jobType.COMPLETED &&
      jobType !== MyGig.jobType.PENDING
    ) {
      schedule = ScheduleHelper.removePastSchedules(schedule);
    }

    return schedule;
  };

  onClickJobTitle = () => {
    const { job, currentPositionId, disableClickTitle, currentTabIndex } =
      this.props;
    this.setState({
      collapse: !this.state.collapse,
      collapseKey: this.props.job.position_id,
    });
    if (disableClickTitle) return false;
    if (job.position_id !== currentPositionId) {
      if (currentPositionId == null) {
        switch (currentTabIndex) {
          case 0:
            this.props.getCandidatesByPosition(job.position_id);
            break;
          case 1:
            this.props.getAssignmentsByPosition(job.position_id);
            break;
          case 2:
            this.props.getDeclinedCandidatesByPosition(job.position_id);
        }
      } else {
        this.props.getCandidatesByPosition(job.position_id, 1, true);
      }
      // this.props.getDeclinedCandidatesByPosition(job.position_id);
      this.props.sendCurrentPosition(job.position_id);
    } else {
      this.props.sendCurrentPosition(null);
    }
  };

  forceUpdateParent = () => {
    this.forceUpdate();
  };

  isCurJobType = (value) => {
    const { jobType } = this.props;
    return (
      jobType === MyGig.jobType.POSTED ||
      jobType === MyGig.jobType.FILLED ||
      jobType === MyGig.jobType.PENDING
    );
  };

  handleClickCopyLink = (position_id) => {
    window.navigator.clipboard.writeText(
      `${AppConfig.apiURL}/redirectToApp/${position_id}`
    );
    this.setState({ isCopied: true });
  };

  renderListAction() {
    const {
      job,
      classes,
      intl,
      disableAdd,
      disableNudge,
      disableMessage,
      disableEdit,
      disableRespost,
      disableDelete,
      isPendingPage,
      jobType,
      loadingPosted,
    } = this.props;

    const { anchorOptionsEl, isShowCancelJobAlert } = this.state;
    const promoteJobEnable =
      jobType === MyGig.jobType.POSTED && oauth.checkCurrentIsOmniStaff();
    const addSeekerEnable = !disableAdd && oauth.checkCurrentIsOmniStaff();
    const nudgeSeekerEnable = !disableNudge;

    let inactiveEdit = disableEdit;
    if (isPendingPage) inactiveEdit = !this.haveActiveJobAtPendingPage();

    return (
      <Popover
        disableEnforceFocus
        open={Boolean(anchorOptionsEl) && !loadingPosted}
        anchorEl={anchorOptionsEl}
        onClose={this.closePopoverOtions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.borderRadiusPopOver,
          paper: "MuiPopover-job-card-list-action description",
        }}
      >
        <div className="job-card-list-action">
          {/* <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.addSeeker" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div className="job-card-list-action__header text-right">
            <IconThreeDotCircle />
          </div>
          <div
            className="job-card-item-container"
            onClick={() => {
              if (addSeekerEnable) {
                this.handleShowAddSeeker(job.position_id);
                this.closePopoverOtions();
              }
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-add-seeker",
                "bottom",
                { inactive: !addSeekerEnable }
              )}
              style={{ width: 30 }}
            />
            <span
              className={className("job-card-item-text", {
                inactive: !addSeekerEnable,
              })}
            >
              Add seeker
            </span>
          </div>
          {/* </Tooltip> */}
          {/* <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.nudge" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div
            className="job-card-item-container"
            onClick={() => {
              if (nudgeSeekerEnable) {
                this.handleShowNudgeSeeker(job.position_id);
                this.closePopoverOtions();
              }
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-nudge-job",
                "bottom",
                {
                  inactive: !nudgeSeekerEnable,
                }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: !nudgeSeekerEnable,
              })}
            >
              Nudge Seeker
            </span>
          </div>
          {/* </Tooltip> */}
          {/* <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.message" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div
            className="job-card-item-container"
            onClick={() => {
              this.props.openMessageModal(job);
              this.closePopoverOtions();
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-envelope-out",
                "bottom",
                { inactive: disableMessage }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: disableMessage,
              })}
            >
              Message
            </span>
          </div>
          {/* </Tooltip>
                    <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.edit" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div
            className="job-card-item-container"
            onClick={() => {
              this.handleEditJob();
              this.closePopoverOtions();
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-edit",
                "bottom",
                { inactive: inactiveEdit }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: inactiveEdit,
              })}
            >
              Edit Shift
            </span>
          </div>
          {/* </Tooltip>
                    <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.repost" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div
            className="job-card-item-container"
            onClick={() => {
              this.handleRepostJob();
              this.closePopoverOtions();
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-copy",
                "bottom",
                { inactive: disableRespost }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: disableRespost,
              })}
            >
              Repost Shift
            </span>
          </div>
          {/* </Tooltip>
                    <Tooltip
                        classes={{ tooltip: classes.marginTooltip }}
                        title={intl.formatMessage({ id: "tooltip.delete" })}
                        placement="bottom"
                        PopperProps={{ style: { pointerEvents: "none" } }}
                    > */}
          <div
            className="job-card-item-container"
            onClick={!disableDelete ? this.toggleCancelJobAlert : undefined}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-ban-job",
                "bottom",
                { inactive: disableDelete }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: disableDelete,
              })}
            >
              Delete
            </span>
          </div>
          <div
            className="job-card-item-container"
            onClick={() => {
              if (!disableAdd) {
                this.handleClickCopyLink(job.position_id);
              }
            }}
          >
            <i
              className={className(
                "gig-icon",
                "icon-action",
                "icon-copy-job",
                "bottom",
                { "icon-copied-job": this.state.isCopied },
                { inactive: disableAdd }
              )}
            />
            <span
              className={className("job-card-item-text", {
                inactive: disableAdd,
              })}
            >
              {!this.state.isCopied ? (
                <IntlMessages id="common.copyLink" />
              ) : (
                <IntlMessages id="common.copied" />
              )}
            </span>
          </div>
          {/* </Tooltip> */}

          {isShowCancelJobAlert && (
            <SweetAlert
              custom
              customIcon={Warning}
              showCancel
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              cancelBtnText={<IntlMessages id="common.button.no" />}
              title={<IntlMessages id="button.delete" />}
              onConfirm={() => this.handleCancelJob(job)}
              onCancel={() => this.toggleCancelJobAlert()}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              {
                <div>
                  <FormattedMessage
                    id="messages.questions.cancelJob"
                    values={{
                      skillName: job.skill_name,
                      location: job.location_name,
                    }}
                  />
                </div>
              }
            </SweetAlert>
          )}

          {promoteJobEnable && (
            // <Tooltip
            //     classes={{ tooltip: classes.marginTooltip }}
            //     title={intl.formatMessage({
            //         id: job.is_promoted
            //             ? "tooltip.unpromote"
            //             : "tooltip.promote"
            //     })}
            //     placement="bottom"
            //     PopperProps={{ style: { pointerEvents: "none" } }}
            // >
            <div
              className="promote job-card-item-container"
              onClick={() =>
                this.props.handlePromotedJob
                  ? this.props.handlePromotedJob(
                      job.position_id,
                      job.is_promoted
                    )
                  : this.handlePromotedJob(job.position_id, job.is_promoted)
              }
            >
              <i className="gig-icon material-icons icon-action icon-promote-job bottom">
                {job.is_promoted ? "star" : "star_border"}
              </i>
              <span
                className={className("job-card-item-text", {
                  inactive: disableDelete,
                })}
              >
                Promote
              </span>
            </div>
            // </Tooltip>
          )}
        </div>
      </Popover>
    );
  }

  renderJobCardHeader() {
    const { anchorOptionsEl, anchorJobDetailsEl, openModalShift } = this.state;
    const { job, jobType, intl } = this.props;
    const { is_privated } = job;
    let color;

    if (is_privated) {
      color = gigRoles.getColor(21);
    } else {
      color = gigRoles.getColor(job.skill_type);
    }

    const schedule = this.getAvailableSchedules();
    const isMultiShift = schedule.length > 1;

    const totalSeconds = schedule.reduce(
      (total, time) => total + (time.end - time.start),
      0
    );

    const totalHours = roundByDecimals(totalSeconds / 3600, 2);
    const styleColor = {
      style: {
        color,
        fill: color,
      },
    };

    const isShowJobDetails = Boolean(anchorJobDetailsEl);
    return (
      <div className="job-card-header">
        <div className="job-card-left">
          {/* <Tooltip title={job.skill_name} placement="bottom"> */}
          <div onClick={this.onClickJobTitle} className="job-card-title">
            <span>{job.skill_name || job.job_type}</span>
          </div>
          {/* </Tooltip> */}
          <div className="job-card__bottom__price">
            <div className="price-bolder">
              <IntlMessages id="jobCard.money.pound" />
              {Number(totalHours * job.chargeout_rate).toFixed(2)}
            </div>
            <div className="smaller-font thinner price-per-hours">
              <IntlMessages
                id="common.hoursWithoutSpace"
                values={{ hours: totalHours }}
              />{" "}
              <IntlMessages id="jobCard.syntaxBetween" />
              <IntlMessages id="jobCard.money.pound" />
              {job.chargeout_rate}
            </div>
          </div>

          {/* {!oauth.checkCurrentIsStandard() && (
                        <Fragment>
                            <div
                                onClick={this.openPopoverOptions}
                                className={className("job-card-three-dot", {
                                    open: Boolean(anchorOptionsEl)
                                })}
                            >
                                <IconThreeDotCircle />
                            </div>
                            {this.renderListAction()}
                        </Fragment>
                    )} */}

          {/* <Fragment>
                        <div
                            onClick={this.openJobDetailsPopover}
                            className={className("job-card-three-dot job-details-icon", {
                                open: isShowJobDetails
                            })}
                        >
                            <i className="material-icons">info_outline</i>
                        </div>

                        {isShowJobDetails && (
                            <JobDetails
                                job={job}
                                isOpen={isShowJobDetails}
                                anchorEl={anchorJobDetailsEl}
                                onClose={this.closeJobDetailsPopover}
                            />
                        )}

                    </Fragment> */}
        </div>
        {/* <div className="job-card-right">
                    {isMultiShift && jobType === MyGig.jobType.POSTED && !oauth.checkCurrentIsStandard() && (
                        <Fragment>
                            <Tooltip
                                title={intl.formatMessage({ id: 'tooltip.splitShifts' })}
                                placement="bottom"
                                PopperProps={{ style: { pointerEvents: 'none' } }}
                            >
                                <i
                                    onClick={() => this.props.showSplitShiftsConfirmPopup(job.position_id)}
                                    className="ti-split-v"
                                    style={{ marginRight: 10, fontSize: 20 }}
                                />
                            </Tooltip>
                        </Fragment>
                    )}

                    {isMultiShift && (
                        <Fragment>
                            <Tooltip
                                title={intl.formatMessage({ id: 'tooltip.viewShifts' })}
                                placement="bottom"
                                PopperProps={{ style: { pointerEvents: 'none' } }}
                            >
                                <img
                                    src={DatePickerImage}
                                    style={{ width: 20 }}
                                    onClick={this.handleOpenModalShift}
                                />
                            </Tooltip>
                            <ModalShift
                                job={job}
                                jobType={jobType}
                                openModalShift={openModalShift}
                                onClose={this.handleCloseModalShift}
                            />
                        </Fragment>
                    )}
                </div> */}
      </div>
    );
  }

  render() {
    const {
      isShowCancelJobAlert,
      isPrivate,
      anchorOptionsEl,
      anchorJobDetailsEl,
      openModalShift,
      anchorcalendarmodal,
      collapse,
      collapseKey,
      showPostToGigAlert,
    } = this.state;
    const isShowJobDetails = Boolean(anchorJobDetailsEl);

    const {
      job,
      jobSeeker,
      jobChanged,
      candidates,
      candidatesInternal,
      declinedCandidates,
      assignments,
      assignmentsInternal,
      loadingPosted,
      loadingDeclined,
      loadingApplied,
      loadingInfo,
      showAlertAccept,
      showAlertOverlaps,
      showAlertExceedStudent,
      showAlertPositionFilled,
      checkOverlaps,
      closeAllPopUps,
      acceptCandidate,
      currentEmployeeId,
      declineAssignment,
      declineCandidate,
      showAlertDecline,
      openDeclinePopUp,
      currentDeclineId,
      showAlertNoShow,
      openNoShow,
      noShow,
      notShowAction,
      nudgeSeekerPositionId,
      addSeekerPositionId,
      currentPositionId,
      history,
      disableClickTitle,
      openDeclineAssignmentPopUp,
      currentDeclineAssignmentId,
      showAlertDeclineAssignment,
      dateRange,
      isOpen,
      jobType,
      loadingSweetAlert,
      employee,
      intl,
      getCandidatesByPosition,
      getDeclinedCandidatesByPosition,
      getAssignmentsByPosition,
      getInfoSeekerInPosition,
      paginationLength,
      disableListSeeker,
      currentTabIndex,
      chosenEmployee,
      loadingCheckSeekerSixDay,
      warningSeekerOverSixDay,
      hideWarningWorkingSixDay,
      checkWorkingSixDay,
      checkSeekerAcceptToJob,
      warningSeekerStudent,
      warningSeekerIn8h,
      warningSeeker48h,
    } = this.props;
    const schedule = this.getAvailableSchedules();
    const isMultiShift = schedule.length > 1;
    const { is_privated, convert_internal_flag } = job;
    const responses_pending =
      job.responses_pending != null ? job.responses_pending : 0;
    const showListSeekersTab = job.position_id === currentPositionId && isOpen;

    const startDate = moment.unix(schedule[0].start).format("DD MMM");
    const timeRange = `${moment
      .unix(schedule[0].start)
      .format("H:mm ")} - ${moment.unix(schedule[0].end).format("H:mm ")}`;

    let endDate = null;
    if (schedule.length > 1) {
      endDate = moment
        .unix(schedule[schedule.length - 1].start)
        .format("DD MMM");
    }

    let color = job.is_privated
      ? gigRoles.getColor(21)
      : gigRoles.getColor(job.skill_type);
    const styleColor = {
      style: {
        color,
        fill: color,
      },
    };

    const fullLocation = `${job.location_name}, ${job.location_street}, ${job.location_postcode}`;
    return (
      <div>
        <RctCard
          customClasses={
            showListSeekersTab
              ? "wrapper-job-card hide-bottom-border"
              : "wrapper-job-card"
          }
        >
          <RctCardContent noPadding>
            <div
              className="collapse show"
              style={{
                // minHeight: 260,
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.23)",
                borderRadius: 12,
              }}
            >
              <div
                className="rct-block-content job-card"
                // {...styleColor}
              >
                <div
                  className={`job-card__background--${
                    is_privated ? "internal" : "public"
                  }`}
                >
                  {is_privated ? (
                    <div className={"jobcard-type-container"}>
                      <div className={"job-card-group-type"}>
                        <button className={"gig-tab-button-label"}>
                          <IntlMessages id="jobcard.internal.button" />
                        </button>
                        <div
                          style={{
                            width: "10px",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                        </div>
                        {jobType === MyGig.jobType.POSTED && (
                          <button
                            className={
                              !is_privated
                                ? "internal-tab-button"
                                : "gig-tab-button black cursor"
                            }
                            onClick={() => this.showAlert()}
                          >
                            <IntlMessages id="jobcard.posttogig.button" />
                          </button>
                        )}
                        <Modal hideBackdrop open={showPostToGigAlert}>
                          <SweetAlert
                            custom
                            customIcon={Warning}
                            showCancel
                            confirmBtnText={
                              <IntlMessages id="common.button.confirm" />
                            }
                            cancelBtnText={<IntlMessages id="button.cancel" />}
                            title={
                              <IntlMessages id="splitShifts.confirmPopup.title" />
                            }
                            onConfirm={() => this.handleChangePrivate(false)}
                            onCancel={() => this.toggleCancelAlert()}
                            confirmBtnCssClass={"btn-orange"}
                            cancelBtnCssClass={"btn-black"}
                          >
                            {
                              <div>
                                <FormattedMessage id="jobcard.posttogig.question" />
                              </div>
                            }
                          </SweetAlert>
                        </Modal>
                      </div>
                      <div className="job-card-provider-action-group">
                        {!oauth.checkCurrentIsStandard() && (
                          <Fragment>
                            <div
                              onClick={this.openPopoverOptions}
                              className={className("job-card-three-dot", {
                                open: Boolean(anchorOptionsEl),
                              })}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: "tooltip.actions",
                                })}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    pointerEvents: "none",
                                  },
                                }}
                              >
                                <IconThreeDotCircle />
                              </Tooltip>
                            </div>
                            {this.renderListAction()}
                          </Fragment>
                        )}
                        <Fragment>
                          <div
                            onClick={this.openJobDetailsPopover}
                            className={className(
                              "job-card-three-dot job-details-icon",
                              {
                                open: isShowJobDetails,
                              }
                            )}
                          >
                            <Tooltip
                              title={intl.formatMessage({
                                id: "tooltip.detail",
                              })}
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  pointerEvents: "none",
                                },
                              }}
                            >
                              <i className="material-icons">info_outline</i>
                            </Tooltip>
                          </div>

                          {isShowJobDetails && (
                            <JobDetails
                              job={job}
                              isOpen={isShowJobDetails}
                              anchorEl={anchorJobDetailsEl}
                              onClose={this.closeJobDetailsPopover}
                            />
                          )}
                          {isMultiShift &&
                            this.isCurJobType() &&
                            !oauth.checkCurrentIsStandard() && (
                              <Fragment>
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "tooltip.splitShifts",
                                  })}
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      pointerEvents: "none",
                                    },
                                  }}
                                >
                                  <i
                                    onClick={() =>
                                      this.props.showSplitShiftsConfirmPopup(
                                        job.position_id,
                                        jobType
                                      )
                                    }
                                    className="ti-split-v"
                                    style={{
                                      marginTop: -4,
                                      fontSize: 22,
                                      cursor: "pointer",
                                      color: "black",
                                      marginRight: 4,
                                      marginLeft: 16,
                                    }}
                                  />
                                </Tooltip>
                              </Fragment>
                            )}

                          {isMultiShift && (
                            <Fragment>
                              <Tooltip
                                title={intl.formatMessage({
                                  id: "tooltip.viewShifts",
                                })}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    pointerEvents: "none",
                                  },
                                }}
                              >
                                <div>
                                  <img
                                    src={DatePickerImage}
                                    style={{
                                      width: 29,
                                      marginTop: -6,
                                      marginLeft: 3,
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      this.handleOpenModalShift(e)
                                    }
                                  />
                                </div>
                              </Tooltip>
                              <ModalShift
                                job={job}
                                jobType={jobType}
                                iconModal="calendar"
                                openModalShift={openModalShift}
                                color={color}
                                onClose={this.handleCloseModalShift}
                                anchorcalendarmodal={
                                  this.state.anchorcalendarmodal
                                }
                              />
                            </Fragment>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={"jobcard-type-container"}
                      style={
                        convert_internal_flag
                          ? {
                              justifyContent: "space-between",
                            }
                          : {
                              justifyContent: "flex-end",
                            }
                      }
                    >
                      {convert_internal_flag ? (
                        <button className={`gig-tab-sticker black`}>
                          <IntlMessages id="jobcard.postedtogig.button" />
                        </button>
                      ) : null}

                      <div className="job-card-provider-action-group">
                        {!oauth.checkCurrentIsStandard() && (
                          <Fragment>
                            <div
                              onClick={this.openPopoverOptions}
                              className={className("job-card-three-dot", {
                                open: Boolean(anchorOptionsEl),
                              })}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: "tooltip.actions",
                                })}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    pointerEvents: "none",
                                  },
                                }}
                              >
                                <IconThreeDotCircle />
                              </Tooltip>
                            </div>
                            {this.renderListAction()}
                          </Fragment>
                        )}
                        <Fragment>
                          <div
                            onClick={this.openJobDetailsPopover}
                            className={className(
                              "job-card-three-dot job-details-icon",
                              {
                                open: isShowJobDetails,
                              }
                            )}
                          >
                            <Tooltip
                              title={intl.formatMessage({
                                id: "tooltip.detail",
                              })}
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  pointerEvents: "none",
                                },
                              }}
                            >
                              <i className="material-icons">info_outline</i>
                            </Tooltip>
                          </div>

                          {isShowJobDetails && (
                            <JobDetails
                              job={job}
                              isOpen={isShowJobDetails}
                              anchorEl={anchorJobDetailsEl}
                              onClose={this.closeJobDetailsPopover}
                            />
                          )}
                          {isMultiShift &&
                            this.isCurJobType() &&
                            !oauth.checkCurrentIsStandard() && (
                              <Fragment>
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "tooltip.splitShifts",
                                  })}
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      pointerEvents: "none",
                                    },
                                  }}
                                >
                                  <i
                                    onClick={() =>
                                      this.props.showSplitShiftsConfirmPopup(
                                        job.position_id,
                                        jobType
                                      )
                                    }
                                    className="ti-split-v"
                                    style={{
                                      marginTop: -4,
                                      fontSize: 22,
                                      cursor: "pointer",
                                      color: "black",
                                      marginRight: 4,
                                      marginLeft: 16,
                                    }}
                                  />
                                </Tooltip>
                              </Fragment>
                            )}

                          {isMultiShift && (
                            <Fragment>
                              <Tooltip
                                title={intl.formatMessage({
                                  id: "tooltip.viewShifts",
                                })}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    pointerEvents: "none",
                                  },
                                }}
                              >
                                <div>
                                  <img
                                    src={DatePickerImage}
                                    style={{
                                      width: 29,
                                      marginTop: -6,
                                      marginLeft: 3,
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      this.handleOpenModalShift(e)
                                    }
                                    anchorcalendarmodal={
                                      this.state.anchorcalendarmodal
                                    }
                                  />
                                </div>
                              </Tooltip>
                              <ModalShift
                                job={job}
                                iconModal="calendar"
                                jobType={jobType}
                                openModalShift={openModalShift}
                                onClose={this.handleCloseModalShift}
                                anchorcalendarmodal={
                                  this.state.anchorcalendarmodal
                                }
                              />
                            </Fragment>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  )}
                  {this.renderJobCardHeader()}
                </div>
                <div className="job-card__bottom__background">
                  {/* <Tooltip
                                    title={fullLocation}
                                    placement="bottom"
                                > */}
                  <div className="job-card-location">{fullLocation}</div>
                  {/* </Tooltip> */}

                  <div
                    className="job-card__bottom row"
                    // style={
                    //     schedule.length > 1
                    //         ? { paddingTop: 5 }
                    //         : { paddingTop: 0 }
                    // }
                  >
                    {schedule.length === 1 ? (
                      <div className="job-card__schedule col-8 d-flex">
                        <p className="bigger-font m-0">
                          {startDate}
                          {":"}&nbsp;
                        </p>
                        <p className="smaller-font m-0">{timeRange}</p>
                      </div>
                    ) : (
                      <div
                        className="job-card__schedule col-8"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className={"nowrap"}>
                          {startDate} - {endDate}
                        </div>
                      </div>
                    )}

                    <div className="job-card__slot col-4 d-flex">
                      {(responses_pending === 0 || responses_pending) && (
                        <div className="job-card__slot__left">
                          <p className="syntax m-0">
                            <IntlMessages id="jobCard.syntaxPending" />
                          </p>
                          <p className="number">{job.responses_pending || 0}</p>
                        </div>
                      )}
                      {(responses_pending === 0 || responses_pending) && (
                        <div className="job-card__slot__right">
                          <p className="syntax m-0">
                            <IntlMessages id="jobCard.syntaxFilled" />
                          </p>
                          <p className="number">
                            {job.assignments_filled}/{job.assignments_total}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row m-0 expand-more">
                    <i
                      size={40}
                      onClick={this.onClickJobTitle}
                      className="material-icons expand-more-icon"
                    >
                      {collapse && collapseKey === job.position_id
                        ? "expand_less"
                        : "expand_more"}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </RctCardContent>
        </RctCard>
        {collapse && collapseKey === job.position_id && (
          <Collapse
            in={(disableClickTitle || showListSeekersTab) && !disableListSeeker}
            className={showListSeekersTab ? "collapse-list" : ""}
          >
            <ListSeekerTabs
              jobType={jobType}
              jobChanged={jobChanged}
              currentIndex={currentTabIndex}
              isListSeekersOpened={showListSeekersTab}
              chosenEmployee={chosenEmployee}
              listCandidates={candidates}
              listCandidatesInternal={candidatesInternal}
              listDeclinedCandidates={declinedCandidates}
              listAssignments={assignments}
              listAssignmentsInternal={assignmentsInternal}
              skillId={job.skill_id}
              positionName={job.skill_name}
              positionId={job.position_id}
              loadingPosted={loadingPosted}
              loadingApplied={loadingApplied}
              loadingDeclined={loadingDeclined}
              loadingInfo={loadingInfo}
              showAlertAccept={showAlertAccept}
              showAlertOverlaps={showAlertOverlaps}
              showAlertExceedStudent={showAlertExceedStudent}
              showAlertPositionFilled={showAlertPositionFilled}
              checkOverlaps={checkOverlaps}
              closeAllPopUps={closeAllPopUps}
              acceptCandidate={acceptCandidate}
              declineAssignment={declineAssignment}
              declineCandidate={declineCandidate}
              currentEmployeeId={currentEmployeeId}
              showAlertDecline={showAlertDecline}
              openDeclinePopUp={openDeclinePopUp}
              currentDeclineId={currentDeclineId}
              showAlertNoShow={showAlertNoShow}
              openNoShow={openNoShow}
              noShow={noShow}
              notShowAction={notShowAction}
              currentPage={currentPage(history.location)}
              openDeclineAssignmentPopUp={openDeclineAssignmentPopUp}
              currentDeclineAssignmentId={currentDeclineAssignmentId}
              showAlertDeclineAssignment={showAlertDeclineAssignment}
              dateRange={dateRange}
              loadingSweetAlert={loadingSweetAlert}
              employee={employee}
              getCandidatesByPosition={getCandidatesByPosition}
              getDeclinedCandidatesByPosition={getDeclinedCandidatesByPosition}
              getAssignmentsByPosition={getAssignmentsByPosition}
              currentPositionId={currentPositionId}
              paginationLength={paginationLength}
              jobSeeker={jobSeeker}
              loadingCheckSeekerSixDay={loadingCheckSeekerSixDay}
              warningSeekerOverSixDay={warningSeekerOverSixDay}
              hideWarningWorkingSixDay={hideWarningWorkingSixDay}
              checkWorkingSixDay={checkWorkingSixDay}
              checkSeekerAcceptToJob={checkSeekerAcceptToJob}
              warningSeekerStudent={warningSeekerStudent}
              warningSeekerIn8h={warningSeekerIn8h}
              warningSeeker48h={warningSeeker48h}
            />
          </Collapse>
        )}
        {nudgeSeekerPositionId === job.position_id && (
          <NudgeSeekerModal positionId={job.position_id} history={history} />
        )}

        {addSeekerPositionId === job.position_id && (
          <AddSeekerModal
            dateRange={dateRange}
            positionId={job.position_id}
            forceUpdateParent={this.forceUpdateParent}
            history={history}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  positionReducer,
  nudgeSeekerReducer,
  addSeekerReducer,
  seekerReducer,
}) => ({
  candidates: positionReducer.candidates,
  candidatesInternal: positionReducer.candidatesInternal,
  declinedCandidates: positionReducer.declinedCandidates,
  assignments: positionReducer.assignments,
  assignmentsInternal: positionReducer.assignmentsInternal,
  jobChanged: positionReducer.jobChanged,
  currentPositionId: positionReducer.currentPositionId,
  isOpen: positionReducer.isOpen,
  currentTabIndex: positionReducer.tabIndex,
  chosenEmployee: positionReducer.chosenEmployee,
  loadingPosted:
    positionReducer.loadingPosted ||
    positionReducer.loadingApplied ||
    positionReducer.loadingDeclined,
  nudgeSeekerPositionId: nudgeSeekerReducer.currentPositionId,
  addSeekerPositionId: addSeekerReducer.currentPositionId,
  showAlertAccept: positionReducer.showAlertAccept,
  showAlertOverlaps: positionReducer.showAlertOverlaps,
  showAlertExceedStudent: positionReducer.showAlertExceedStudent,
  showAlertPositionFilled: positionReducer.showAlertPositionFilled,
  currentEmployeeId: positionReducer.currentEmployeeId,
  showAlertDecline: positionReducer.showAlertDecline,
  currentDeclineId: positionReducer.currentDeclineId,
  currentDeclineAssignmentId: positionReducer.currentDeclineAssignmentId,
  showAlertDeclineAssignment: positionReducer.showAlertDeclineAssignment,
  showAlertNoShow: positionReducer.showAlertNoShow,
  jobDetailsPopup: positionReducer.jobDetailsPopup,
  loadingSweetAlert: positionReducer.loadingSweetAlert,
  paginationLength: positionReducer.paginationLength,
  warningSeekerOverSixDay: positionReducer.warningSeekerOverSixDay,
  loadingCheckSeekerSixDay: positionReducer.loadingCheckWarningSixDay,
  warningSeekerStudent: positionReducer.warningSeekerStudent,
  warningSeekerIn8h: positionReducer.warningSeekerIn8h,
  warningSeeker48h: positionReducer.warningSeeker48h,
});

const mapActionToProps = {
  cancelJob,
  getCandidatesByPosition,
  getDeclinedCandidatesByPosition,
  getAssignmentsByPosition,
  openEditJob,
  openRepostJob,
  getJobById,
  sendCurrentPosition,
  switchJobStatus,
  showNudgeSeekerModal,
  showAddSeekerModal,
  checkOverlaps,
  closeAllPopUps,
  acceptCandidate,
  declineAssignment,
  declineCandidate,
  openDeclinePopUp,
  openMessageModal,
  openNoShow,
  noShow,
  showSplitShiftsConfirmPopup,
  openDeclineAssignmentPopUp,
  doPromoteJob,
  doUnpromoteJob,
  getInfoSeekerInPosition,
  hideWarningWorkingSixDay,
  checkWorkingSixDay,
  checkSeekerAcceptToJob,
};

export default withStyles(styles)(
  injectIntl(withRouter(connect(mapStateToProps, mapActionToProps)(JobCard)))
);

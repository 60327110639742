import React, {Component, Fragment} from "react";


import {
    Modal,
    ModalHeader,
    ModalBody, Form, FormGroup, Label, Input, ModalFooter

} from 'reactstrap';

import BusinessDetailModel from 'Model/BusinessDetailModel';
import connect from "react-redux/es/connect/connect";
import { isValidPostcode , isEmail, minLength, maxLength, matchCompanyNumber, matchCharacter } from "Helpers/validators";
import {
    openBusinessDetail,
    closeBusinessDetail,
    getBusinessDetail,
    getBusinessDetailSuccess,
    updateBussinessDetailObject,
    updateBusinessDetail,
    confirmBusinessDetailErrorPopup,
    confirmBusinessDetailSuccessPopup
} from "Actions";
import IntlMessages from "Util/IntlMessages";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";

import UserDefault from 'Assets/img/default-user.png';

import OAuth from "OAuth";

import ValidatorMessage from "Components/ValidatorMessage";

const oauth = new OAuth();
class BusinessDetail extends Component {

    constructor(props) {
        super(props);
        this.state  = {
            errors: {}
        };

        this.formRefs = {};
    }

    toggleAddBussinessDetailModal = () => {
        const {openPopUp} = this.props.businessDetailSettingReducer;
        if (!openPopUp) {
            this.props.getBusinessDetail();
            this.props.openBusinessDetail();
            this.initForEdit();
        } else {
            this.props.closeBusinessDetail();
        }
    }

    onChangeLoginDetail = (e, key) => {
        const {data} = this.props.loginDetailSettingReducer;
        data[key] = e.target.value;
        this.props.updateLoginDetailObject(data);
    }

    initForEdit = () => {
        this.setState({
            errors: {},
        });
    }

    convertBase64(value) {
        const self = this;
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            const { errors } = this.state;
            if (this.convertBase64toMB(reader.result) < 1) {
                const {data} = self.props.businessDetailSettingReducer;
                data.logo_thumbnail = reader.result;
                self.props.updateBussinessDetailObject(data);
                delete errors.image;
                this.setState({
                });
            } else {
                errors.image = "businessDetail.max.size.1MB";
            }
            this.setState({
                errors
            });
        }, false);

        if (value) {
            reader.readAsDataURL(value);
        }
    }

    convertBase64toMB(base64) {
        const stringLength = base64.length - 'data:image/png;base64,'.length;
        const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        const sizeInMB = sizeInBytes / 1000000;
        return sizeInMB;
    }


    onChangeBusinessDetail = (e, key) => {
        if (key === "logo_thumbnail") {
            this.convertBase64(e.target.files[0]);
        } else {
            const {data} = this.props.businessDetailSettingReducer;
            data[key] = e.target.value;
            this.props.updateBussinessDetailObject(data);
        }
    }

    focusErrorFields(errors) {
        if (errors.name) {
            return this.formRefs.businessName.focus();
        }

        if (errors.email) {
            return this.formRefs.businessEmail.focus();
        }

        if (errors.ba_city) {
            return this.formRefs.baCity.focus();
        }

        if (errors.ba_street) {
            return this.formRefs.baStreet.focus();
        }

        if (errors.ba_building) {
            return this.formRefs.baBuilding.focus();
        }

        if (errors.ba_postcode) {
            return this.formRefs.baPostCode.focus();
        }

        if (errors.company_number) {
            return this.formRefs.companyNumber.focus();
        }

        if (errors.vat_number) {
            return this.formRefs.vatNumber.focus();
        }

        if (errors.contact_number) {
            return this.formRefs.contactNumber.focus();
        }
    }

    async updateBusinessDetail() {

        const {loginDetailSettingReducer, businessDetailSettingReducer} = this.props;
        const loginDetailData = loginDetailSettingReducer.data;
        const businessDetailData = businessDetailSettingReducer.data;

        const {id, name, email, ba_city, ba_building, ba_postcode, ba_street, company_number, vat_number, contact_number, logo_thumbnail} = businessDetailData;
        const {email: emailUser, fullname} = loginDetailData;
        const errorImage = this.state.errors.image;
        const   errors = await BusinessDetailModel.validateFields({loginDetailData,businessDetailData});

        this.setState({errors});

        if (Object.keys(errors).length > 0 || errorImage) {
            this.focusErrorFields(errors);
        } else {
            const businessDetail = {
                id,
                name,
                email,
                ba_city,
                ba_street,
                ba_building,
                ba_postcode,
                company_number,
                contact_number,
                vat_number,
                logo: logo_thumbnail
            };

            const loginDetail = {
                fullname,
                email: emailUser,
                current_password: "",
                password: "",
                password_confirmation: ""
            };

            this.props.updateBusinessDetail({businessDetail, loginDetail});
        }
    }

    onConfirmError = () => {
        this.props.confirmBusinessDetailErrorPopup();
    }

    onConfirmSuccess = () => {
        this.props.confirmBusinessDetailSuccessPopup();
    }



    render() {
        const {popUpName, className, loginDetailSettingReducer, businessDetailSettingReducer} = this.props;
        const loginDetailData = loginDetailSettingReducer.data;
        const businessDetailData = BusinessDetailModel.createFromApiResponseData(businessDetailSettingReducer.data) ;
        const {openPopUp} = businessDetailSettingReducer;
        const {loading, error, response, updated} = businessDetailSettingReducer;
        const isProviderStandard = oauth.checkCurrentIsStandard();

        const {errors} = this.state;

        return (
            <Fragment>
                <a href={null} onClick={() => this.toggleAddBussinessDetailModal()}>
                    <i className={`ti ${className}`} />
                    {popUpName}
                </a>
                <Modal className="businessDetailModal" isOpen={openPopUp} toggle={this.toggleAddBussinessDetailModal}>
                    <ModalHeader toggle={this.toggleAddBussinessDetailModal}>
                        <span className="text-uppercase orange location-font-size-header">
                            <IntlMessages id="business.detail.title"/>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label className="text-uppercase" for="business-name">
                                    <IntlMessages id="business.detail.name"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="business-name"
                                    id="business-name"
                                    innerRef={(e) => this.formRefs.businessName = e}
                                    value={businessDetailData.name}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'name')}
                                    disabled={isProviderStandard}
                                />
                                {errors.name && (
                                    <ValidatorMessage message={errors.name}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label
                                    className="text-uppercase"
                                    for="business-email"><IntlMessages
                                    id="business.detail.email"/></Label>
                                <Input
                                    type="text"
                                    name="business-email"
                                    id="business-email"
                                    innerRef={(e) => this.formRefs.businessEmail = e}
                                    value={businessDetailData.email}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'email')}
                                    disabled={isProviderStandard}
                                />
                                {errors.email && (
                                    <ValidatorMessage message={errors.email}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label
                                    className="text-uppercase"
                                    for="business-city"><IntlMessages id="business.detail.city"/></Label>
                                <Input
                                    type="text"
                                    name="ba_city"
                                    id="business-city"
                                    innerRef={(e) => this.formRefs.baCity = e}
                                    value={businessDetailData.ba_city}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'ba_city')}
                                    disabled={isProviderStandard}/>
                                {errors.ba_city && (
                                    <ValidatorMessage message={errors.ba_city}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-street">
                                    <IntlMessages id="business.detail.street"/>
                                </Label>
                                <Input type="text"
                                       name="ba_street"
                                       id="business-street"
                                       innerRef={(e) => this.formRefs.baStreet = e}
                                       value={businessDetailData.ba_street}
                                       onChange={(e) => this.onChangeBusinessDetail(e, 'ba_street')}
                                       disabled={isProviderStandard}/>
                                {errors.ba_street && (
                                    <ValidatorMessage message={errors.ba_street}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-building">
                                    <IntlMessages id="business.detail.building"/>
                                </Label>
                                <Input type="text"
                                       name="ba_building"
                                       id="business-building"
                                       innerRef={(e) => this.formRefs.baBuilding = e}
                                       value={businessDetailData.ba_building}
                                       onChange={(e) => this.onChangeBusinessDetail(e, 'ba_building')}
                                       disabled={isProviderStandard}/>
                                {errors.ba_building && (
                                    <ValidatorMessage message={errors.ba_building}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-postcode">
                                    <IntlMessages id="business.detail.postcode"/>
                                </Label>
                                <Input type="text"
                                       name="ba_postcode"
                                       id="business-postcode"
                                       value={businessDetailData.ba_postcode}
                                       innerRef={(e) => this.formRefs.baPostCode = e}
                                       onChange={(e) => this.onChangeBusinessDetail(e, 'ba_postcode')}
                                       disabled={isProviderStandard}/>
                                {errors.ba_postcode && (
                                    <ValidatorMessage message={errors.ba_postcode}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-company-number">
                                    <IntlMessages id="business.detail.companyNumber"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="company_number"
                                    id="business-company-number"
                                    innerRef={(e) => this.formRefs.companyNumber = e}
                                    value={businessDetailData.company_number}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'company_number')}
                                    disabled={isProviderStandard}/>
                                {errors.company_number && (
                                    <ValidatorMessage message={errors.company_number}/>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-vat-number">
                                    <IntlMessages id="business.detail.vatNumber"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="vat_number"
                                    id="business-vat-number"
                                    innerRef={(e) => this.formRefs.vatNumber = e}
                                    value={businessDetailData.vat_number}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'vat_number')}
                                    disabled={isProviderStandard}/>
                                {errors.vat_number && (
                                    <ValidatorMessage message={errors.vat_number}/>
                                )}
                            </FormGroup>
                            <div className="text-uppercase modal-title black">
                                <h3>
                                    <b>
                                        <IntlMessages id="businessDetail.accountOwner"/>
                                    </b>
                                </h3>
                            </div>
                            <FormGroup>
                                <Label className="text-uppercase" for="business-fullname">
                                    <IntlMessages id="business.detail.fullname"/>
                                </Label>
                                <Input type="text" name="fullname" id="fullname" value={loginDetailData.fullname}
                                       disabled/>
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-email">
                                    <IntlMessages id="business.detail.userEmail"/>
                                </Label>
                                <Input type="text"
                                       name="userEmail"
                                       id="business-email"
                                       value={loginDetailData.email}
                                       disabled/>
                            </FormGroup>

                            <FormGroup>
                                <Label className="text-uppercase" for="business-contactNumber">
                                    <IntlMessages id="business.detail.contactNumber"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="contactNumber"
                                    id="business-contactNumber"
                                    innerRef={(e) => this.formRefs.contactNumber = e}
                                    value={businessDetailData.contact_number}
                                    onChange={(e) => this.onChangeBusinessDetail(e, 'contact_number')}
                                    disabled={isProviderStandard}/>
                                {errors.contact_number && (
                                    <ValidatorMessage message={errors.contact_number}/>
                                )}
                            </FormGroup>
                            <Input type="file" name="image"
                                   onChange={e => this.onChangeBusinessDetail(e, 'logo_thumbnail')} accept="image/*"
                                   disabled={isProviderStandard}/>
                            {errors.image && (
                                <ValidatorMessage message={errors.image}/>
                            )}
                            <FormGroup/>

                            <FormGroup>
                                <img
                                    src={businessDetailData.logo_thumbnail ? businessDetailData.logo_thumbnail : UserDefault}
                                    alt={businessDetailData.name}
                                    className="rounded-circle"
                                    height="100" width="100"/>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    {!isProviderStandard &&
                    <ModalFooter>
                        <Button
                            
                            className="gig-button grey text-white line-height-1"
                            onClick={() => this.updateBusinessDetail()}>
                            <IntlMessages id="button.save"/>
                        </Button>
                        <Button
                            
                            className="gig-button red text-white line-height-1"
                            onClick={() => this.toggleAddBussinessDetailModal()}>
                            <IntlMessages id="button.cancel"/>
                        </Button>
                    </ModalFooter>
                    }
                    {loading && (
                        <div className="d-flex justify-content-center loader-overlay">
                            <CircularProgress/>
                        </div>
                    )}

                    {error &&
                    <SweetAlert
                        error
                        show={response.error.message}
                        title=""
                        btnSize="sm"
                        onConfirm={() => this.onConfirmError()}>
                        <div className="text-uppercase">
                            <span>{response.error.message}</span>
                        </div>
                    </SweetAlert>
                    }
                    {updated &&
                    <SweetAlert
                        success
                        show={updated}
                        title=""
                        btnSize="sm"
                        onConfirm={() => this.onConfirmSuccess()}>
                        <div className="text-uppercase">
                            <IntlMessages id="loginDetail.update.success"/>
                        </div>
                    </SweetAlert>
                    }
                </Modal>
            </Fragment>
        );
    }
}


const mapStateToProps = ({businessDetailSettingReducer, loginDetailSettingReducer}) => ({businessDetailSettingReducer, loginDetailSettingReducer});

const mapActionToProps = {
    openBusinessDetail,
    closeBusinessDetail,
    getBusinessDetail,
    getBusinessDetailSuccess,
    updateBussinessDetailObject,
    updateBusinessDetail,
    confirmBusinessDetailErrorPopup,
    confirmBusinessDetailSuccessPopup
};

export default connect(mapStateToProps, mapActionToProps) (BusinessDetail);

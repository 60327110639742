import React, {Component}        from 'react';
import {connect}                 from 'react-redux';
import {FormattedMessage}        from 'react-intl';
import {Input, Label, FormGroup} from 'reactstrap';

import Button           from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText   from '@material-ui/core/FormHelperText';
import IconButton       from '@material-ui/core/IconButton';

import ListSeekers from 'Components/ListSeekers/ListSeekers';
import BreakTime   from 'Components/Model/CreateEditJob/BreakTime';
import KeyCodes    from 'Constants/KeyCodes';
import {
  searchSeekers
} from 'Actions';

class Timecard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searched       : false,
            searchText     : '',
            breakTime      : 0,
            addedSeekers   : {},
            listSeekers    : [],
            validationError: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.onLoading === true) {
        this.setState({searched: true});
        }

        this.setState({
        listSeekers: nextProps.listSeekers
        });
    }

    setBreakTime = (event) => {
        this.setState({
            breakTime: Number(event.target.value)
        });
    }

    showValidationError(messageKey) {
        this.setState({validationError: messageKey});
    }

    doCreateTimecard() {
        const {addedSeekers, breakTime} = this.state;
        const listSeekerIds             = Object.keys(addedSeekers).map(Number);

        if (Object.keys(addedSeekers).length === 0) {
            this.showValidationError('createTimecard.validate.emptySeeker');
            this.props.onValidation();
        } else {
            this.props.onSaving(listSeekerIds, breakTime);
        }
    }

    handleSearchInput(event) {
        const key = event.which || event.keyCode;

        if (this.state.validationError) {
            this.setState({validationError: false});
        }

        if (key === KeyCodes.ENTER) {
            if (this.state.searchText.length < 1) {
                this.showValidationError('createTimecard.validate.searchMinLength');
            } else {
                this.props.searchSeekers(this.state.searchText, 0,0,true);
            }
        }
    }

    removeSeekerId(seekerId) {
        const {addedSeekers} = this.state;

        delete addedSeekers[seekerId];
        this.setState({addedSeekers});
    }

    addSeekerId(seekerId, seekerName) {
        const {addedSeekers} = this.state;

        addedSeekers[seekerId] = seekerName;
        this.setState({addedSeekers});
    }

  render() {
    const { addedSeekers, validationError, searched, breakTime, listSeekers} = this.state;
    const { onLoading } = this.props;

    return (
      <div className="vertical-space-smaller w-100 mr-1">
        <h3 className="normal-font font-bold uppercase">
          <FormattedMessage id="createTimecard.label" />
        </h3>

        <div className="row w-100">
            <div className="col-sm-12 col-md-12 col-xl-4">
                <FormGroup>
                    <Label>
                        <FormattedMessage id="createTimecard.breakTime" />
                    </Label>

                    <Input
                        type="select"
                        id="job-Time-Card"
                        value={breakTime}
                        onChange={this.setBreakTime}
                    >
                        <BreakTime />
                    </Input>
                </FormGroup>
            </div>

            <div className="col-sm-12 col-md-12 col-xl-4">
                <FormGroup>
                    <Label>
                        <FormattedMessage id="createTimecard.inputSearch.placeholder" />
                    </Label>

                    <div className="position-relative">
                        <FormattedMessage id="createTimecard.inputSearch.placeholder">
                            {(placeholder) => (
                                <Input
                                type="text"
                                placeholder={placeholder}
                                onChange={(e) => this.setState({searchText: e.target.value})}
                                disabled={onLoading === true}
                                onKeyPress={(e) => this.handleSearchInput(e)} />
                            )}
                        </FormattedMessage>

                        {onLoading === true && (
                            <CircularProgress className="progress-warning spinner-loading" size={28} mode="determinate" />
                        )}

                        {validationError !== false && (
                            <FormHelperText className="field-error">
                                <FormattedMessage id={validationError} />
                            </FormHelperText>
                        )}
                    </div>
                </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-4">
            <FormGroup className="align-self-end">              
                <div className="pull-right mb-0">
                    <Button className="gig-button black bigger-font pull-right" fullWidth onClick={() => this.doCreateTimecard()}>
                        <FormattedMessage id="createTimecard.saveTimecard" />
                    </Button>
                </div>
            </FormGroup>
    
            </div>
        </div>

        {Object.keys(addedSeekers).length > 0 && (
            <div className="added-seekers-wrapper">
                {Object.keys(addedSeekers).map((seekerId) => {
                    const seekerName = addedSeekers[seekerId];

                    return (
                        <div className="added-seeker-item" key={`added-seeker-${seekerId}`}>
                            <div className="seeker-ref-no">{`#${seekerId}`}</div>
                            <div className="seeker-name">{seekerName}</div>
                            <IconButton onClick={() => this.removeSeekerId(seekerId)}>
                                <i className="material-icons">cancel</i>
                            </IconButton>
                        </div>
                    );
                })}
            </div>
        )}

        {listSeekers.length === 0 && searched && onLoading === false && (
            <div className="uppercase font-bold center vertical-space-small">
                <FormattedMessage id="createTimecard.searchNoSeekers" />
            </div>
        )}

        {listSeekers.length > 0 && (
            <div className="searched-seekers-wrapper">
                <ListSeekers
                    listSeekers={listSeekers}
                    isAddSeekerButton={true}
                    isNudge={false}
                    isAddTimeCard={true}
                    isAdd={false}
                    addedListIds={Object.keys(addedSeekers).map(Number)}
                    onSubmit={(seekerId, seekerName) => this.addSeekerId(seekerId, seekerName)}
                />
            </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({searchSeekerReducer}) => {
  const {listSeekers, onLoading} = searchSeekerReducer;

  return {listSeekers, onLoading};
};

const mapActionToProps = {
    searchSeekers
};

export default connect(mapStateToProps, mapActionToProps)(Timecard);

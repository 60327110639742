import API from "Api";
import MyGig from "Constants/MyGig";

/**
 * Create position
 *
 * @param  {Object} params
 * @typedef {{
        skill_id            : number,
        payrate             : number,
        charge_rate         : number,
        description         : string,
        special_requirements: string,
        schedule            : Array<schedule>,
        total_assignments   : number,
        uniform_other       : string,
        uniform_ids         : Array<Uniform ID>
    }}
 * @return async
 */
export const createPositionRequest = async (params) =>
  await API.post(`/employers/shifts/${params.shift_id}/positions`, {
    skill_id: params.skill_id,
    experience: 0,
    payrate: params.payrate,
    charge_rate: params.charge_rate,
    status: MyGig.status.POSITION_OPEN,
    description: params.description,
    special_requirements: params.special_requirements,
    schedule: params.schedule,
    total_assignments: params.total_assignments,
    requested_number: params.requested_number,
    break_time: 0,
    uniform_other: params.other_uniform,
    uniform_ids: params.uniform_ids,
    is_over18: params.is_over18,
  })
    .then((response) => response)
    .catch((err) => err);

/**
 * Update position
 * @return async
 */
export const editPositionRequest = async (params) =>
  await API.put(`/employers/positions/${params.position_id}`, {
    skill_id: params.skill_id,
    experience: 0,
    payrate: params.payrate,
    charge_rate: params.charge_rate,
    status: MyGig.status.POSITION_OPEN,
    description: params.description,
    special_requirements: params.special_requirements,
    schedule: params.schedule,
    total_assignments: params.total_assignments,
    requested_number: params.requested_number,
    break_time: 0,
    uniform_other: params.other_uniform,
    uniform_ids: params.uniform_ids,
    is_over18: params.is_over18,
  })
    .then((response) => response)
    .catch((err) => err);

/**
 * Get the candidates for the specified job position ID.
 *
 * An array of Candidate model objects is returned by the API.
 *
 * @param  {number} positionId
 * @return async
 */
export const getCandidatesByPositionRequest = async (
  positionId,
  page,
  searchKey
) =>
  await API.get(`/employers/positions/${positionId}/candidates-list`, {
    page: page,
    searchKey: searchKey,
  })
    .then((response) => response)
    .catch((err) => err);

/**
 * Get list of declined candidates for a specified position.
 *
 * An array of Candidate model objects is returned by the API.
 *
 * @param  {number} positionId
 * @return async
 */
export const getDeclinedCandidatesByPositionRequest = (
  positionId,
  page,
  searchKey
) =>
  API.get(`/employers/positions/${positionId}/declined-list`, {
    page: page,
    searchKey: searchKey,
  })
    .then((response) => response)
    .catch((err) => err);

/**
 * check employee overlap
 * @param  {number} positionId
 * @param  {number} employeeId
 * @return async
 */
export const getCheckOverlapsEmployeeRequest = ({ positionId, employeeId }) =>
  API.get(`/employers/positions/${positionId}/checkoverlap/${employeeId}`)
    .then((response) => response)
    .catch((err) => err);

/**
 * check assignment request
 * @param  {number} positionId
 * @param  {number} employeeId
 * @return async
 */
export const acceptAssignmentsRequest = ({
  positionId,
  responseId,
  isSelectAll,
}) =>
  API.post(`/employers/positions/${positionId}/assignments`, {
    response_ids: responseId,
    isSelectAll: isSelectAll,
  })
    .then((response) => response)
    .catch((err) => err);

export const updatePrivateJobRequest = (params) => {
  return API.post(
    `/employers/shifts/updatePrivated/${params.shift_id}`,
    params
  );
};
export const checkSeekerWorkSixdayRequest = (
  employee_ids,
  isAll,
  positionId
) => {
  return API.post(`/employers/check_seeker_work_6_day`, {
    employee_ids,
    isAll,
    positionId,
  });
};

export const checkSeekerNotToJob = (employeeId, positionId) => {
  return API.post(`/employers/positions/checkSeekerNotToJob`, {
    employee_id: employeeId,
    position_id: positionId,
  });
};

export const checkSeekerAcceptToJobRequest = (employee_ids, positionId) => {
  return API.post(
    `/employers/assignments/checkSeekerAcceptToJob?app_version=1.9.2&app_build=1`,
    {
      employee_ids: employee_ids,
      position_id: positionId,
    }
  );
};

/**
 * Favourite Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import { FAVOURITE_EMPLOYEE } from 'Actions/types';

import {
  showErrorResponse
} from 'Actions';

import {
  favouriteEmployeeRequest
} from "Services/employers/FavouriteService";

function* handleFavouriteEmployee({ payload }) {
  const { employeeID, skillIDs } = payload;

  try {
    yield call(favouriteEmployeeRequest, employeeID, skillIDs);
  } catch (error) {
    yield put(showErrorResponse(error));
  }
}

export function* watchFavouriteEmployee() {
  yield takeEvery(FAVOURITE_EMPLOYEE, handleFavouriteEmployee);
}

export default function* rootSaga() {
  yield all([
    fork(watchFavouriteEmployee)
  ]);
}

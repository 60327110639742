/**
 * Search Seekers Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';
import MyGig from 'Constants/MyGig';
import ApiCodes from 'Constants/ApiCodes';

import { ADD_SEEKER_FOR_POSITION } from 'Actions/types';

import { get } from 'lodash'

import {
    getCalendarJobs,
    updateNumberOfSeeker,
    getAssignmentsByPosition,
    showErrorResponse,
    addSeekerForPositionSuccess,
    addSeekerForPositionFailure,
    addSeekerForPositionExceeded,
    addSeekerForPositionUnavailable,
    addSeekerForOver18,
    addSeekerForPositionRestriction48hours,
} from 'Actions';

import {
    addSeekerForPositionRequest
} from "Services/employers/JobService";

/**
 * Search seekers from server
 */
function* handleAddSeekerForPosition({ payload }) {
    const { positionId, seekerId, currentPage, dateRange } = payload;
    const { calendarPage } = MyGig.currentPage;

    try {
        const response = yield call(addSeekerForPositionRequest, positionId, seekerId);
        if (response.data && isSuccess(response)) {
            yield put(addSeekerForPositionSuccess(response.data.data));

            yield put(getAssignmentsByPosition(positionId));

            yield put(updateNumberOfSeeker({
                jobPositionId: positionId,
                typeOfAction: MyGig.typeOfAction.addSeekerSuccess,
                currentPage,
                seekerList: [seekerId]
            }));

            // if(currentPage === calendarPage) {
            //     yield put(getCalendarJobs(dateRange.start, dateRange.end));
            // }

        } else {
            const code = response.data ?  response.data.code : response.code
            const message = response.data ?  response.data.error.message : response.error.message
            if (code === ApiCodes.INVALID_SIGNATURE) {
                yield put(addSeekerForPositionUnavailable());
            } else if (code === ApiCodes.UNKNOWN_ERROR && message != MyGig.RESTRICTION_48_HOURS) {
                yield put(addSeekerForPositionExceeded());
            } else if (code === ApiCodes.OVER_18) {
                yield put(addSeekerForOver18());
            } else if (code === ApiCodes.UNKNOWN_ERROR && message === MyGig.RESTRICTION_48_HOURS) {
                yield put(addSeekerForPositionRestriction48hours());
            } else {
                yield put(addSeekerForPositionFailure(response));
            }
            throw new Error(message);
        }
    } catch (error) {
        const errorMessage = get(error, 'response.data.error.message', 'Error adding seeker');
        yield put(addSeekerForPositionFailure(errorMessage));
        yield put(showErrorResponse(error));
        throw error;
    }
}

/**
 * Take add seekers action
 */
export function* watchAddSeekerForPosition() {
    yield takeEvery(ADD_SEEKER_FOR_POSITION, handleAddSeekerForPosition);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchAddSeekerForPosition)
    ]);
}

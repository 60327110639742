/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from "react";
import Loadable from "react-loadable";

// rct page loader
import RctPageLoader from "Components/RctPageLoader/RctPageLoader";

// upcoming jobs dashboard
const AsyncMyDashboardComponent = Loadable({
    loader: () => import("Routes/dashboard/upcoming-job"),
    loading: () => <RctPageLoader />
});

// upcoming jobs dashboard
const AsyncWeeklyCalendarComponent = Loadable({
    loader: () => import("Routes/calendar/weekly-calendar"),
    loading: () => <RctPageLoader />
});

const AsyncMonthlyCalendarComponent = Loadable({
    loader: () => import("Routes/calendar/monthly-calendar"),
    loading: () => <RctPageLoader />
});
// my jobs
const AsyncUpcomingComponents = Loadable({
    loader: () => import("Routes/my-gigs/upcoming"),
    loading: () => <RctPageLoader />
});

const AsyncPostedMyJobsComponents = Loadable({
    loader: () => import("Routes/my-gigs/posted"),
    loading: () => <RctPageLoader />
});

const AsyncFilledMyJobsComponents = Loadable({
    loader: () => import("Routes/my-gigs/filled"),
    loading: () => <RctPageLoader />
});

const AsyncPendingMyJobsComponents = Loadable({
    loader: () => import("Routes/my-gigs/pending"),
    loading: () => <RctPageLoader />
});

const AsyncCompletedMyJobsComponents = Loadable({
    loader: () => import("Routes/my-gigs/completed"),
    loading: () => <RctPageLoader />
});

// Dashboard > Calendar
const AsyncCalendarJobsComponent = Loadable({
    loader: () => import("Routes/dashboard/calendar-job"),
    loading: () => <RctPageLoader />
});

// My job posted > Calendar
const AsyncCalendarPostedComponent = Loadable({
    loader: () => import("Routes/my-gigs/calendar-posted"),
    loading: () => <RctPageLoader />
});

// My job posted > Filled
const AsyncCalendarFilledComponent = Loadable({
    loader: () => import("Routes/my-gigs/calendar-filled"),
    loading: () => <RctPageLoader />
});

// My job posted > Pending
const AsyncCalendarPendingComponent = Loadable({
    loader: () => import("Routes/my-gigs/calendar-pending"),
    loading: () => <RctPageLoader />
});

// location
const AsyncLocationComponent = Loadable({
    loader: () => import("Routes/location"),
    loading: () => <RctPageLoader />
});

// invoice
const AsyncInvoiceComponent = Loadable({
    loader: () => import("Routes/invoice"),
    loading: () => <RctPageLoader />
});

const AsyncNotFoundComponent = Loadable({
    loader: () => import("Routes/404"),
    loading: () => <RctPageLoader />
});

// users
const AsyncUserComponent = Loadable({
    loader: () => import("Routes/users"),
    loading: () => <RctPageLoader />
});

/* ---------------- Timesheet ------------------*/

// Timesheet pending weekly page
const AsyncTimesheetWeeklyComponent = Loadable({
    loader: () => import("Routes/timesheet/weekly"),
    loading: () => <RctPageLoader />
});

// Timesheet pending internal page
const AsyncTimesheetInternalComponent = Loadable({
    loader: () => import("Routes/timesheet/internal"),
    loading: () => <RctPageLoader />
});

// Timesheet pending daily page
const AsyncTimesheetDailyComponent = Loadable({
    loader: () => import("Routes/timesheet/daily"),
    loading: () => <RctPageLoader />
});

// Timesheet approved
const AsyncTimesheetApprovedComponent = Loadable({
    loader: () => import("Routes/timesheet/approved"),
    loading: () => <RctPageLoader />
});


export {
    AsyncMyDashboardComponent,
    AsyncWeeklyCalendarComponent,
    AsyncLocationComponent,
    AsyncTimesheetWeeklyComponent,
    AsyncMonthlyCalendarComponent,
    AsyncTimesheetInternalComponent,
    AsyncTimesheetDailyComponent,
    AsyncTimesheetApprovedComponent,
    AsyncUserComponent,
    AsyncUpcomingComponents,
    AsyncCompletedMyJobsComponents,
    AsyncPostedMyJobsComponents,
    AsyncFilledMyJobsComponents,
    AsyncPendingMyJobsComponents,
    AsyncCalendarJobsComponent,
    AsyncCalendarPostedComponent,
    AsyncCalendarFilledComponent,
    AsyncCalendarPendingComponent,
    AsyncInvoiceComponent,
    AsyncNotFoundComponent,
};

import API from "Api";
import MyGig from "Constants/MyGig";
import { validNumberOrZero } from "Helpers/helpers";
import { param } from "jquery";
import moment from "moment";

/**
 * get job
 *
 * @param  {Number} page - for pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */
const getJobRequestParams = (page, startDate, endDate, locationId) => {
    let params = {};
    const paged = validNumberOrZero(page);

    if (paged > 0) {
        params = { ...params, paged };
    }

    if (startDate && endDate) {
        params = {
            ...params,
            start: startDate,
            end: endDate
        };
    }
    if (locationId) {
        params.locationId = locationId
    }

    return params;
};

/**
 * List the employee's upcoming jobs.
 *
 * @param  {Number} page -  pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */

export const getUpcomingJobRequest = async (params) => {
    return await API.get("/employers/upcomingjobs", params)
        .then((response) => response)
        .catch((err) => err);
};

export const getTodayJobRequest = async (page) =>
    await API.get("/employers/todayjobs", getJobRequestParams(page))
        .then((response) => response)
        .catch((err) => err);

/**
 * List the employer's posted jobs.
 * @param  {Number} page -  pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */
export const getPostedJobsRequest = async (
    page,
    startDate,
    endDate,
    locationId,
) =>
    await API.get(
        "/employers/postedjobs",
        getJobRequestParams(page, startDate, endDate, locationId),
    )
        .then((response) => response)
        .catch((err) => err);

/**
 * List the employer's current jobs.
 * @param  {Number} page -  pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */
export const getFilledJobRequest = async (
    page,
    startDate,
    endDate,
    locationId,
) =>
    await API.get(
        "/employers/currentjobs",
        getJobRequestParams(page, startDate, endDate, locationId),
    )
        .then((response) => response)
        .catch((err) => err);

/**
 * List the employer's completed jobs.
 * @param  {Number} page -  pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */
export const getCompletedJobRequest = async (page, startDate, endDate, locationId) =>
    await API.get('/employers/completedjobs', getJobRequestParams(page, startDate, endDate, locationId))
        .then(response => response)
        .catch(err => err);

/**
 * List the employer's pending jobs.
 * @param  {Number} page -  pagination
 * @param  {Date}   startDate - date range start
 * @param  {Date}   endDate   - date range end
 * @return async
 */
export const getPendingJobRequest = async (
    page,
    startDate,
    endDate,
    locationId,
) =>
    await API.get(
        "/employers/pendingjobs",
        getJobRequestParams(page, startDate, endDate, locationId),
    )
        .then((response) => response)
        .catch((err) => err);

/**
 * Search list of seekers corespond with position
 * This function used in Create Timecard & Add Seeker modules
 *
 * @param  {String}   searchText - The text for search seeker's name & email
 * @param  {Number}   positionId - Set as 0 to search all
 * @param  {!Number=} page - Page of results (Optional)
 * @return async
 */
export const searchSeekersForPositionRequest = async (
    searchText,
    positionId,
    page,
    isPostTimeCard,
) => {
    if (!positionId) {
        positionId = 0;
    }

    const params = {
        search_term: searchText.trim(),
        position_id: Number(positionId),
    };

    if (isPostTimeCard !== undefined) {
        params["isPostTimeCard"] = isPostTimeCard;
    }

    if (page) {
        params.page = validNumberOrZero(page);
    }

    return await API.get("/employers/seekerSearch", params)
        .then((response) => response)
        .catch((err) => err);
};

/**
 * get job detail by position Id
 * @param  Oject
 * @typedef {{
 *     positionId : Number
 * }}
 * @return async
 */
export const getJobDetailRequest = (params) =>
    API.get(`/employers/job/${params.positionId}`)
        .then((response) => response)
        .catch((err) => err);

/**
 * get job
 * @param  Oject
 * @typedef {{
 *     positionId : Number
 * }}
 * @return async
 */
export const getJobByIdRequest = (params) =>
    API.get(`/employers/getPosition/${params.positionId}`)
        .then((response) => response)
        .catch((err) => err);

/**
 * get job
 * @param  Oject
 * @typedef {{
 *     positionId : Number
 * }}
 * @return async
 */
export const getJobMoreInfoRequest = (params) =>
    API.get(`/employers/positions/${params.positionId}/more-info`)
        .then((response) => response)
        .catch((err) => err);
/**
 * get job
 * @param  Oject
 * @typedef {{
 *     positionId : Number
 * }}
 * @return async
 */
export const getJobInformationRequest = (params) =>
    API.get(`/employers/get-all-information-jobs/${params.positionId}`)
        .then((response) => response)
        .catch((err) => err);

/**
 * get job by candidate
 * @param  {Number} jobId
 * @return async
 */
export const getJobCandidatesRequest = (jobId) =>
    API.get(`/employers/positions/${jobId}/candidates`)
        .then((response) => response)
        .catch((err) => err);

/**
 * Get list of nudgable seekers corespond with position
 *
 * @param  {Number} positionId
 * @param  {Object} filterParams
 * @param  {Number} page - Default: 1
 * @return async
 */
export const getNudgableSeekersRequest = async (
    positionId,
    filterParams,
    page,
) => {
    let params = {};

    if (!page) {
        page = 1;
    }

    if (filterParams && Object.keys(filterParams).length > 0) {
        params = filterParams;
    }

    params = { ...params, page };

    return await API.get(`/employers/nudgable_seekers/${positionId}`, params)
        .then((r) => r)
        .catch((e) => e);
};

/**
 * Nudge seeker for position
 *
 * @param  {Number} positionId
 * @param  {Number} employeeId
 * @return async
 */
export const nudgeSeekerForPositionRequest = async (positionId, employeeId) =>
    await API.put(
        `/employers/nudgable_seekers/${positionId}/nudge/${employeeId}`,
    )
        .then((r) => r)
        .catch((e) => e);

/**
 * Add seeker for position
 *
 * @param  {Number} positionId
 * @param  {Number} employeeId
 * @return async
 */
export const addSeekerForPositionRequest = (positionId, seekerId) =>
    API.put(`/employers/AddSeekerToJob`, {
        position_id: positionId,
        employee_id: seekerId,
    })
        .then((r) => r)
        .catch((e) => e);

/**
 *  Cancel job
 *
 * @param  {Number} positionId
 * @return async
 */
export const cancelJobRequest = (job) => API.remove(`/employers/positions/${job.position_id}/cancel`)
    .then(r => r)
    .catch(e => e);

/**
 *  get candidate detail
 *
 * @param  {Number} candidateId
 * @return async
 */
export const getCandidateDetailsRequest = ({ candidateId }) =>
    API.put(`/employers/candidate/${candidateId}`)
        .then((r) => r)
        .catch((e) => e);

/**
 * Search list of seekers corespond with position
 * This function used in Create Timecard & Add Seeker modules
 *
 * @param  {Date} startDate - Format: YYYY-MM-DD
 * @param  {Date} endDate - Format: YYYY-MM-DD
 * @return async
 */
export const getCalendarViewJobsRequest = async (
    startDate,
    endDate,
    locationId,
) => {
    const params = {
        start: startDate,
        end: endDate,
        locationCalendar: locationId,
    };

    return await API.get("/employers/jobsforcalendar", params)
        .then((response) => response)
        .catch((err) => err);
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PushNotification from "PushNotification";
import NotificationWrapper from "Components/Notification/NotificationWrapper";
import { maxNumberPlus } from "Helpers/helpers";
import IntlMessages from "Util/IntlMessages";
import StoredData from "Constants/StoredData";
import MyGig from "Constants/MyGig";

import {
    showNotificationPanel,
    hideNotificationPanel,
    getNotifications,
    getNumberUnread,
} from "Actions";

const NOTIFICATION_PER_PAGE = 15;
const pushnotification = new PushNotification();
class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiCalled: false,
        };
    }

    componentDidMount() {
        if (!window.directMessage) {
            pushnotification.withPusherConnection();
            pushnotification.subscribeDirectMessage(
                StoredData.get(StoredData.KEYS.LOGGED_IN_AS)
                );
            }
            
        let self = this;
        window.directMessage.bind(MyGig.evenDirectMessage, (data) => {
            self.props.getNumberUnread();
        });
        this.props.getNumberUnread();
    }
    
    togglePanel() {
        const { showPanel, onLoading } = this.props;
        const { apiCalled } = this.state;

        if (showPanel && !onLoading) {
            this.props.hideNotificationPanel();
            this.setState({ apiCalled: false });
        } else {
            this.props.showNotificationPanel();

            if (!apiCalled) {
                this.props.getNotifications(NOTIFICATION_PER_PAGE, null);
                this.setState({ apiCalled: true });
            }
        }
    }

    showOlderNotifications(lastId) {
        this.props.getNotifications(NOTIFICATION_PER_PAGE, lastId);
    }

    render() {
        const {
            showPanel,
            listOfNotifications,
            paginator,
            unreadNotifications,
            intl,
            adminAccount
        } = this.props;

        const isEmptyNotification =
            !this.props.onLoading &&
            listOfNotifications.length === 0 &&
            this.state.apiCalled === true;
        const spinnerWrapper =
            (this.props.onLoading && !this.props.onLoadingOlder) ||
            this.props.onLoadingJobDetails;

        return (
            <Dropdown
                nav
                className="list-inline-item list-inline-item--v2 notification-icon"
                isOpen={showPanel}
                toggle={() => this.togglePanel()}
            >
                <DropdownToggle nav className="p-0">
                    {/* <Tooltip
                        title={intl.formatMessage({
                            id: "notification.dropdownTooltip",
                        })}
                        placement="bottom"
                    > */}
                        <div className="header-icon-container">
                            <IconButton
                                className={
                                    unreadNotifications > 0
                                        ? "header-icon-container-height"
                                        : "header-icon-container-height"
                                }
                                aria-label="bell"
                            >
                                <i className="zmdi zmdi-notifications-active header-icon-container-icon" />
                                {unreadNotifications > 0 && (
                                    <Badge
                                        color="orange"
                                        className="badge-xs badge-top-right rct-notify"
                                    >
                                        {maxNumberPlus(unreadNotifications, 99)}
                                    </Badge>
                                )}
                            </IconButton>
                            <p className="header-icon-container-content">
                                Notifications
                            </p>
                        </div>
                    {/* </Tooltip> */}
                </DropdownToggle>

                <DropdownMenu
                    right
                    className={`notification-panel notification-panel--v2 ${adminAccount ? `notification-admin`: ``}`}
                >
                    <div>
                        <i
                            className={`zmdi zmdi-notifications-active header-icon-container-icon ${adminAccount ? ``: `zmdi-right`}`}
                            style={{
                                padding: "20px 20px 10px",
                                fontSize: "22px",
                            }}
                        />

                        {spinnerWrapper && (
                            <div
                                className="center d-flex align-items-center justify-content-center"
                                style={{ height: "88px" }}
                            >
                                <CircularProgress
                                    className="progress-warning"
                                    size={30}
                                    mode="determinate"
                                />
                            </div>
                        )}

                        {!spinnerWrapper && listOfNotifications.length > 0 && (
                            <NotificationWrapper
                                notifications={listOfNotifications}
                                showOlderAvailable={
                                    paginator.currentPage < paginator.lastPage
                                }
                                onShowOlder={(lastId) =>
                                    this.showOlderNotifications(lastId)
                                }
                                history={this.props.history}
                            />
                        )}

                        {isEmptyNotification && (
                            <div className="empty-notification">
                                <IntlMessages id="notification.emptyData" />
                            </div>
                        )}
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ notificationReducer }) => notificationReducer;

const mapActionToProps = {
    showNotificationPanel,
    hideNotificationPanel,
    getNotifications,
    getNumberUnread,
};

export default injectIntl(
    connect(mapStateToProps, mapActionToProps)(Notification)
);

/**
 * Api Config File
 */
//https://www.gig-api.co.uk prod
//https://staging-vnm.gig-api.co.uk

const ApiConfig = {
  BaseURL: process.env.API,
  BasePORT: 3000,
};

module.exports = ApiConfig;

import API from "Api";
import MyGig from "Constants/MyGig";

/**
 * Get pending remote requests which have been grouped by date
 *
 * @return async
 */
export const groupPendingRemoteRequestsByDate = async () =>
  await API.get("/employers/remoterequests/pending/groupByDate")
    .then((response) => response)
    .catch((err) => err);

/**
 * Get approved remote requests which have been grouped by date
 *
 * @return async
 */
export const groupApprovedRemoteRequestsByDate = async () =>
  await API.get("/employers/remoterequests/approved/groupByDate")
    .then((response) => response)
    .catch((err) => err);

/**
 * Get list pending remote requests from API
 *
 * @param  {date} startDate - Format: YYYY-MM-DD
 * @param  {date} endDate   - Format: YYYY-MM-DD
 * @return async
 */
export const pendingRemoteRequests = async (
  startDate,
  endDate,
  timezone,
  internal,
  locationId
) => {
  const params = {
    start: startDate,
    end: endDate,
    timezone,
    internal: internal,
    locationId,
  };
  if (!locationId) {
    delete params.locationId;
  }
  return await API.get("/employers/remoterequests/pending", params)
    .then((response) => response)
    .catch((err) => err);
};
/**
 * Get list approved remote requests from API
 *
 * @param  {date} startDate - Format: YYYY-MM-DD
 * @param  {date} endDate   - Format: YYYY-MM-DD
 * @return async
 */
export const approvedRemoteRequests = async (
  startDate,
  endDate,
  locationId
) => {
  const params = {
    start: startDate,
    end: endDate,
    locationId,
  };

  return await API.get("/employers/remoterequests/approved", params)
    .then((response) => response)
    .catch((err) => err);
};

/**
 * Call API to decline a remote request
 *
 * @param  {number} remoteRequestId
 * @return async
 */
export const declineRemoteRequest = async function (remoteRequestId) {
  const params = {
    status: MyGig.status.REMOTE_CHECK_DECLINED,
    positive_employee_review: 0,
  };

  return await API.put(`/employers/remoterequests/${remoteRequestId}`, params)
    .then((response) => response)
    .catch((err) => err);
};

/**
 * Call API to decline list of remote requests
 *
 * @param  {Array<number>} listOfRequestIds
 * @return async
 */
export const declineListOfRemoteRequests = async function (listOfRequestIds) {
  const params = {
    requests: listOfRequestIds.map((requestId) => ({
      requestId,
      status: MyGig.status.REMOTE_CHECK_DECLINED,
      positive_employee_review: 0,
    })),
  };

  return await API.put("/employers/remoterequests/declineRequestsList", params)
    .then((response) => response)
    .catch((err) => err);
};

/**
 * Call API to decline list of remote requests
 *
 * @param  {object} params
 * @return async
 */
export const updateScheduleOfRemoteRequest = async function (params) {
  return await API.put("/employers/remoterequests/updateSchedule", params)
    .then((response) => response)
    .catch((err) => err);
};

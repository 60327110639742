import API from "Api";
import axios from "axios"


export const presignedUrl = async ({fileName}) =>
  await API.get(`/presigned_url/${fileName}`);

export const uploadFileS3Service = async ({ url, file }) => {
  try {
    const options = {
      headers: { "Content-Type": file.type, acl: "public-read" },
    };

    const response = await axios.put(url, file, options);
    return response;
  } catch (error) {

  }
};

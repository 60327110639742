/**
 * Uniforms Actions
 */

import {
    GET_UNIFORMS,
    GET_UNIFORMS_SUCCESS,
    GET_UNIFORMS_FAILURE
} from './types';


/**
 * Action Get list of uniforms
 */
export const getUniforms = () => ({
    type: GET_UNIFORMS
});

/**
 * Action Get list of uniforms Success
 */
export const getUniformsSuccess = (response) => ({
    type: GET_UNIFORMS_SUCCESS,
    payload: response
});

/**
 * Action Get list of uniforms Failure
 */
export const getUniformsFailure = (error) => ({
    type: GET_UNIFORMS_FAILURE,
    payload: error
});
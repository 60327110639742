import API from "Api";

/**
 * Call API to approve single remote request
 *
 * @param {Object} request - Remote request object
 * @typedef {
        assignedScheduleId: integer|unsigned
        startTime         : date - YYYY-MM-DD HH:mm:ss
        endTime           : date - YYYY-MM-DD HH:mm:ss
        startRemoteId     : integer|unsigned
        endRemoteId       : integer|unsigned
        thumbsUp          : enum(0, 1)
        employerComment   : string
        breakTime         : integer|unsigned
    }
 * @return async
 */
export const approveRemoteRequest = async function (request) {
    const params = {
        assigned_schedule_id: request.assignedScheduleId,
        start_time: request.startTime,
        end_time: request.endTime,
        start_remote_id: request.startRemoteId,
        end_remote_id: request.endRemoteId,
        thumbs_up: request.thumbsUp,
        employer_comment: request.employerComment,
        break_time: request.breakTime,
        is_not_utc: 1,
    };

    return await API.put("/employers/acceptShiftTimes", params)
        .then((response) => response)
        .catch((err) => err);
};

/**
 * Call API to approve list of remote request
 *
 * @param {Array<Object>} listOfRemoteRequests
 * @typedef [{
        assignedScheduleId: integer|unsigned
        startTime         : date - YYYY-MM-DD HH:mm:ss
        endTime           : date - YYYY-MM-DD HH:mm:ss
        startRemoteId     : integer|unsigned
        endRemoteId       : integer|unsigned
        thumbsUp          : enum(0, 1)
        employerComment   : string
        breakTime         : integer|unsigned
    }]
 * @return async
 */
export const approveListOfRemoteRequests = async function (
    listOfRemoteRequests,
) {
    const params = {
        approveRequests: listOfRemoteRequests.map((request) => ({
            assigned_schedule_id: request.assignedScheduleId,
            start_time: request.startTime,
            end_time: request.endTime,
            start_remote_id: request.startRemoteId,
            end_remote_id: request.endRemoteId,
            thumbs_up: request.thumbsUp,
            employer_comment: request.employerComment,
            break_time: request.breakTime,
            is_not_utc: 1,
            start_from_sunday: request.startFromSunday,
        })),
    };

    console.log("approve param", params);

    return await API.put("/employers/acceptShiftTimeLists", params)
        .then((response) => response)
        .catch((err) => err);
};

/**
 * Get shift infor by Assigned schedule ID from API
 * Property `remoteId` from respond data is used to send as `startRemoteId` in array param of `approveListOfRemoteRequests` above
 *
 * @param {number} assignedScheduleId
 * @return async
 */
export const getShiftInfoByAssignedScheduleId = async (assignedScheduleId) =>
    await API.get(
        `/employers/getShiftInfoAssignedScheduleId/${assignedScheduleId}`,
    )
        .then((response) => response)
        .catch((err) => err);

import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IntlMessages from "Util/IntlMessages";

class InternalEmployeeItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { employee } = this.props;
        const { employeeSkills } = employee;

        return (
            <div className="seeker-item align-middle">
                <div className="col-sm-7 col-md-7 col-xl-7">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <p className="seeker-name uppercase font-bolder m-0">
                            {employee.employeeName}
                        </p>
                        <div
                            className=" position-relative clearfix"
                            style={{
                                display: "flex",
                            }}
                        >
                            <p
                                className="seeker-fullname m-0 mr-10"
                                style={{ fontSize: "16px" }}
                            >
                                {employee.email}
                            </p>

                            <p
                                className="seeker-phone m-0"
                                style={{ fontSize: "16px" }}
                            >
                                {employee.phone}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    className="col-sm-3 col-md-3 col-xl-3"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    {employeeSkills.length > 0 &&
                        employeeSkills.map((skill) => (
                            <div
                                key={skill.skill_id}
                                style={{ padding: 0, margin: 0 }}
                            >
                                {skill.skill_name}
                            </div>
                        ))}
                </div>
                <div
                    className="col-sm-2 col-md-2 col-xl-2"
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                    }}
                >
                    <div className={"button-update-delete-internal-seeker"}>
                        <a onClick={() => this.props.onUpdate()}>
                            <IntlMessages id="internalEmployee.button.edit" />
                        </a>
                    </div>
                    <div className={"button-update-delete-internal-seeker"}>
                        <a onClick={() => this.props.onDelete()}>
                            <IntlMessages id="internalEmployee.button.delete" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default InternalEmployeeItem;

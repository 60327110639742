/**
 * Search seeker Reducer
 */
import {
    SEEKER_SEARCH,
    SEEKER_SEARCH_SUCCESS,
    SEEKER_SEARCH_FOR_ADD_SUCCESS,
    SEEKER_SEARCH_FAILURE,
    SEEKER_SEARCH_RESET_RESULTS,
} from "Actions/types";

import { validNumberOrZero } from "Helpers/helpers";

// initial state
const INIT_STATE = {
    listSeekers: [],
    listSeekersForAdd: [],
    onLoading: false,
    onLoadNextPage: false,
    nextPageAvailable: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEEKER_SEARCH:
            let { page } = action.payload;
            page = validNumberOrZero(page);

            return {
                ...state,
                onLoading: page <= 1,
                onLoadNextPage: page > 1,
            };

        case SEEKER_SEARCH_SUCCESS:
            let listSeekers = action.payload;
            const nextPageAvailable = listSeekers.length > 0;

            if (state.onLoadNextPage === true) {
                listSeekers = state.listSeekers.concat(listSeekers);
            }

            return {
                ...state,
                listSeekers,
                nextPageAvailable,
                onLoading: false,
                onLoadNextPage: false,
            };
        case SEEKER_SEARCH_FOR_ADD_SUCCESS:
            let listSeekersForAdd = action.payload;
            const nextPageAvailableForAdd = listSeekersForAdd.length > 0;

            if (state.onLoadNextPage === true) {
                listSeekersForAdd = state.listSeekersForAdd.concat(state.listSeekers);
            }

            return {
                ...state,
                listSeekersForAdd,
                nextPageAvailable: nextPageAvailableForAdd,
                onLoading: false,
                onLoadNextPage: false,
            };
        case SEEKER_SEARCH_FAILURE:
            return { ...state, onLoading: false, onLoadNextPage: false };

        case SEEKER_SEARCH_RESET_RESULTS:
            return { ...state, listSeekers: [], listSeekersForAdd: [] };

        default:
            return { ...state };
    }
};

import React, {Component} from 'react';

import ListItem           from '@material-ui/core/ListItem';
import ListItemText       from '@material-ui/core/ListItemText';

class FakeRows extends Component {
    render() {
        const { number, keyPrefix } = this.props;
        const rows = [];
        let start = 0;

        if (number > 0) {
            start = 6 - number;

            for (let i = start; i <= 5; i++) {
                rows.push(
                    <ListItem className="no-padding" key={`${keyPrefix}-${i}`}>
                        {/* <span className="list-numerical-order">{i}</span> */}
                        <ListItemText className="report-fake-row" />
                    </ListItem>
                );
            }
        }

        return rows;
    }
}

export default FakeRows;

import React, { Component } from 'react';
import Popover from '@material-ui/core/es/Popover/Popover';
import { RctCard, RctCardContent } from 'Components/RctCard';
import SeekerProfile from 'Components/Seeker/Profile';
import SeekerTab from 'Components/Seeker/Tab';


class InfoTab extends Component {
    renderQualifications() {
        const { employee, skillId } = this.props;
        const sameSkill = (employee.employee_skills || []).find(e => e.skill_id === skillId);
        return (sameSkill || {}).qualifications;
    }


    render() {
        const { employee, anchorEl, showProfile, closePopoverOptions } = this.props;

        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closePopoverOptions}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{ marginLeft: -25 }}
            >
                <RctCard customClasses="mb-2">
                    <RctCardContent>
                        {showProfile && <SeekerProfile seeker={employee} />}
                        <div style={{ maxWidth: '300px' }}>{this.renderQualifications()}</div>
                        <div className="job-seeker-history-popover">
                            <SeekerTab seeker={employee} />
                        </div>
                    </RctCardContent>
                </RctCard>
            </Popover>
        );
    }

}

export default InfoTab;

/**
 * Confirm password actions
 */
import {
    AMENDING_HOURS_SHOW_FORM,
    AMENDING_HOURS_HIDE_FORM,
    AMENDING_HOURS_ADD_COMMENT,
    AMENDING_HOURS_ADD_COMMENT_SUCCESS,
    AMENDING_HOURS_ADD_COMMENT_FAILURE,
} from './types';


export const showAmendingHourForm = (assignedScheduleId, assignmentId, comment) => ({
    type   : AMENDING_HOURS_SHOW_FORM,
    payload: {assignedScheduleId,assignmentId, comment}
});

export const hideAmendingHourForm = () => ({
    type: AMENDING_HOURS_HIDE_FORM
});

export const amendingHourComment = (comment, assignedScheduleId, locationId, start, end) => ({
    type   : AMENDING_HOURS_ADD_COMMENT,
    payload: {comment, assignedScheduleId, locationId, start, end}
});

export const amendingHourCommentSuccess = (response) => ({
    type   : AMENDING_HOURS_ADD_COMMENT_SUCCESS,
    payload: response
});

export const amendingHourCommentFailure = (error) => ({
    type   : AMENDING_HOURS_ADD_COMMENT_FAILURE,
    payload: error
});


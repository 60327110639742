import API from 'Api';
/**
 * Get charge rate for the current employer
 *
 * @return {!jQuery.deferred}
 * @public
 */
export const getChargeRatesRequest = async () => await API.get('/omni/employer/charge_rates')
    .then(response => response)
    .catch(err => err);

/**
* post charge rate to API
*
* @return async
*/

export const postSkillsChargeRate = (SkillsChargeRate) =>
    API.post('/omni/employer/charge_rates', {
        charge_rates: SkillsChargeRate
    })
        .then(response => response)
        .catch(error => error);

import React, { Component, Fragment } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { roundByDecimals } from "Helpers/helpers";
import IntlMessages from "Util/IntlMessages";
import DownloadExcelReport from "Routes/reports/partials/DownloadExcelReport";

class FulfilmentReport extends Component {
  calculatePercent = (totalRequest, totalAssignment) => {
    return roundByDecimals((totalAssignment / totalRequest) * 100, 2);
  };
  getPercentColorCode = (percentage) => {
    if (percentage < 90) return "#E16B5F";
    if (percentage >= 90 && percentage < 98) return "#F7B135";
    if (percentage >= 98) return "#89CF2F";
  };

  renderRows() {
    const { fulfilmentStatistics } = this.props;
    const rows = [];
    if (Object.keys(fulfilmentStatistics).length > 0) {
      fulfilmentStatistics.unixWeeks.map((unixWeek, index) => {
        const totalRequest = fulfilmentStatistics.totalRequest[index];
        const totalAssignment = fulfilmentStatistics.totalAssignment[index];
        const totalAssignmentReal =
          fulfilmentStatistics.totalAssignmentReal[index];
        const totalAttended = fulfilmentStatistics.totalAttended[index]
        const percentage = this.calculatePercent(
          totalRequest,
          totalAttended
        );
        const weekStart = moment(unixWeek.week_start_date);
        const weekEnd = moment(unixWeek.week_end_date);

        const percentColor = this.getPercentColorCode(percentage);
        const locationId = null;
        if (totalAssignment > 0 ) {
          rows.push(
            <TableRow hover key={`weekly-report-row-${index}`}>
              <TableCell className="flex-group">
                <span className="week-range">
                  {weekStart.format("DD MMM")} - {weekEnd.format("DD MMM")}
                </span>

                <span className="totalProvided">
                  <IntlMessages
                    id="common.totalProvided"
                    values={{ provided: totalAttended }}
                  />
                </span>
              </TableCell>

              <TableCell className="percentage" style={{ color: percentColor }}>
                {percentage}
                <IntlMessages id="common.percent" />
              </TableCell>
              <TableCell className="">
                <DownloadExcelReport
                  start={weekStart}
                  end={weekEnd}
                  locationId={locationId}
                  reportType={"fulfilment"}
                />
              </TableCell>
            </TableRow>
          );
        }

        return true;
      });
    }

    if (rows.length === 0 && this.props.loading === false) {
      return (
        <div className="">
          <p className="empty-data-report">
            <IntlMessages id="reports.empty.weeklyReports" />
          </p>
        </div>
      );
    }

    if (this.props.loading === true) {
      return (
        <div className="wrapper">
          <CircularProgress
            className="progress-warning"
            size={70}
            mode="determinate"
            value={40}
            min={0}
            max={50}
          />
        </div>
      );
    }

    return (
      <Table>
        <TableHead className="report-table" style={{ backgroundColor: "#fff" }}>
          <Fragment>
            <TableRow>
              <TableCell className="flex-group">
                <span className="week-range">Week</span>

                <span className="hours">Total Staff Attended</span>
              </TableCell>
              <TableCell className="revenue">Percentage</TableCell>
              <TableCell className=""></TableCell>
            </TableRow>
          </Fragment>
        </TableHead>
        <TableBody className="report-table">
          <Fragment>{rows}</Fragment>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div className="report-table-container weekly-report-wrapper">
        {this.renderRows()}
      </div>
    );
  }
}

export default FulfilmentReport;

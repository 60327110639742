import BaseModel from "Model/BaseModel";
import {isEmail, isValidPostcode, matchCharacter, matchCompanyNumber, maxLength, minLength} from "Helpers/validators";

export default class BusinessDetailModel extends BaseModel {

    static createFromApiResponseData(data) {
        const model = new BusinessDetailModel;
        model.name = data.name;
        model.email = data.email;
        model.ba_city = data.ba_city;
        model.ba_building = data.ba_building;
        model.ba_street = data.ba_street;
        model.ba_postcode = data.ba_postcode;
        model.vat_number = data.vat_number;
        model.company_number = data.company_number;
        model.contact_number = data.contact_number;
        return model;

    }

    static async validateFields (params) {
        const errors = {};
        const {id, name, email, ba_city, ba_building, ba_postcode, ba_street, company_number, vat_number, contact_number, logo_thumbnail} = params.businessDetailData;

        if (!name) {
            errors.name = 'businessDetail.name.empty';
        }

        if (!email) {
            errors.email = 'businessDetail.email.empty';
        }
        if (!errors.email && !isEmail(email)) {
            errors.email = 'businessDetail.emai.invalid';
        }

        if (!ba_city) {
            errors.ba_city = 'businessDetail.ba_city.empty';
        }

        if (!ba_building) {
            errors.ba_building = 'businessDetail.ba_building.empty';
        }

        if (!ba_street) {
            errors.ba_street = 'businessDetail.ba_street.empty';
        }

        if (!ba_postcode) {
            errors.ba_postcode = 'businessDetail.ba_postcode.empty';
        }
        if (!errors.ba_postcode &&  minLength(ba_postcode, 11)) {
            errors.ba_postcode = "businessDetail.ba_postcode.max";
        }

        const checkPostCode = await isValidPostcode(ba_postcode);
        if (!errors.ba_postcode && !checkPostCode) {
            errors.ba_postcode = "businessDetail.ba_postcode.invalid";
        }

        if (!vat_number) {
            errors.vat_number = 'businessDetail.vat_number.empty';
        }
        if (!errors.vat_number && !!this.validateVATNumber(vat_number)) {
            errors.vat_number = this.validateVATNumber(vat_number);
        }

        if (!company_number) {
            errors.company_number = 'businessDetail.company_number.empty';
        }

        if (!errors.company_number && !matchCompanyNumber(company_number)) {
            errors.company_number = "businessDetail.company_number.invalid";
        }

        if (!contact_number) {
            errors.contact_number = "businessDetail.contactNumber.empty";
        }
        if (!errors.contact_number &&  minLength(contact_number,17)) {
            errors.contact_number = "businessDetail.contactNumber.max";
        }
        if (!errors.contact_number && maxLength(contact_number,6)) {
            errors.contact_number = "businessDetail.contactNumber.min";
        }
        if (!errors.contact_number && matchCharacter(contact_number)) {
            errors.contact_number = "businessDetail.contactNumber.invalid";
        }

        return errors;


    }

    static validateVATNumber (vatNumber) {
        const multipliers = [8, 7, 6, 5, 4, 3, 2];

        const vatDigits = vatNumber.replace(/\D+/g, '');

        if (vatDigits.length !== 9) {
            return 'businessDetail.vat_number.9.digit';
        }
        const checkDigits = parseInt(vatDigits.slice(-2), 10);
        let checkDigitTotal = 0;
        for (let i = 0; i < 7; i++) {
            checkDigitTotal += (vatDigits[i] * multipliers[i]);
        }
        const mod97check = -((checkDigitTotal % 97) - 97);
        const mod9755check = -(((checkDigitTotal + 55) % 97) - 97);

        if (!((checkDigits === mod97check) || (checkDigits === mod9755check))) {
            return 'businessDetail.vat_number.invalid';
        }
        return null;
    };
}

/**
 * Pdf Reducer
 */

import {
    PDF_OPEN_VIEWER,
    PDF_CLOSE_VIEWER,
    PDF_GET_TIMESHEET_PENDING,
    PDF_GET_TIMESHEET_PENDING_SUCCESS,
    PDF_GET_TIMESHEET_PENDING_FAILURE,
    PDF_GET_TIMESHEET_APPROVED,
    PDF_GET_TIMESHEET_APPROVED_SUCCESS,
    PDF_GET_TIMESHEET_APPROVED_FAILURE,
    PDF_GET_APPLIED,
    PDF_GET_APPLIED_SUCCESS,
    PDF_GET_APPLIED_FAILURE,
    PDF_GET_CONFIRMED,
    PDF_GET_CONFIRMED_SUCCESS,
    PDF_GET_CONFIRMED_FAILURE,
    PDF_GET_WEEKLY_TIMESHEET_FAILURE,
    PDF_GET_WEEKLY_TIMESHEET,
    PDF_GET_WEEKLY_TIMESHEET_SUCCESS,
    PDF_GET_DAILY_UPCOMING_JOB,
    PDF_GET_DAILY_UPCOMING_JOB_SUCCESS,
    PDF_GET_DAILY_UPCOMING_JOB_FAILURE,
    PDF_GET_DAILY_PENDING_JOB_SUCCESS,
    PDF_GET_DAILY_PENDING_JOB_FAILURE,
    EXCEL_GET_WEEKLY_TIMESHEET,
    EXCEL_GET_WEEKLY_TIMESHEET_FAILURE,
    EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS,
} from "Actions/types";

// initial state
const INIT_STATE = {
    pdfContent: {
        timesheetPending: null,
        timesheetApproved: null,
        applied: null,
        confirmed: null,
        weeklyTimesheet: null,
        upcomingJob: null,
    },
    openViewer: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PDF_OPEN_VIEWER:
            return { ...state, openViewer: true };

        case PDF_CLOSE_VIEWER:
            return {
                ...state,
                openViewer: false,
                pdfContent: {
                    ...state.pdfContent,
                    [action.payload.key]: null,
                },
            };

        case PDF_GET_TIMESHEET_PENDING:
            return { ...state };

        case PDF_GET_TIMESHEET_PENDING_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    timesheetPending: action.payload,
                },
            };

        case PDF_GET_TIMESHEET_PENDING_FAILURE:
            return { ...state };

        case PDF_GET_TIMESHEET_APPROVED:
            return { ...state };

        case PDF_GET_TIMESHEET_APPROVED_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    timesheetApproved: action.payload,
                },
            };

        case PDF_GET_TIMESHEET_APPROVED_FAILURE:
            return { ...state };

        case PDF_GET_APPLIED_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    applied: action.payload,
                },
            };

        case PDF_GET_APPLIED_FAILURE:
            return { ...state };

        case PDF_GET_CONFIRMED_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    confirmed: action.payload,
                },
            };

        case PDF_GET_CONFIRMED_FAILURE:
            return { ...state };

        case PDF_GET_WEEKLY_TIMESHEET:
            return { ...state };

        case PDF_GET_WEEKLY_TIMESHEET_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    weeklyTimesheet: action.payload,
                },
            };

        case PDF_GET_WEEKLY_TIMESHEET_FAILURE:
            return { ...state };

        case EXCEL_GET_WEEKLY_TIMESHEET:
            return { ...state };
        case EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS:
            return { ...state };
        case EXCEL_GET_WEEKLY_TIMESHEET_FAILURE:
            return { ...state };
        case PDF_GET_DAILY_UPCOMING_JOB:
            return { ...state };

        case PDF_GET_DAILY_UPCOMING_JOB_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    listJob: action.payload,
                },
            };

        case PDF_GET_DAILY_UPCOMING_JOB_FAILURE:
            return { ...state };

        case PDF_GET_DAILY_PENDING_JOB_SUCCESS:
            return {
                ...state,
                pdfContent: {
                    ...state.pdfContent,
                    listJob: action.payload,
                },
            };

        case PDF_GET_DAILY_PENDING_JOB_FAILURE:
            return { ...state };

        default:
            return { ...state };
    }
};

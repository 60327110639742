/**
 * Invoice action
 */
import {
    GET_INVOICES,
    GET_INVOICES_SUCCESS,
    EXPORT_PDF,
    GET_INVOICE_PDF_LINK_SUCCESS,
    RESET_INVOICE_POPUP,
    SEND_INVOICE_MAIL,
    SEND_INVOICE_EMAILL_SUCCESS,
    UNMOUNT_INVOICE,
    DONE_LOADING_INVOICE,
    GET_ALL_INVOICES,
    INIT_LOADING,
} from 'Actions/types';


export const getInvoices = duration => ({
    type: GET_INVOICES,
    payload: { duration },
});

export const getAllInvoices = () => ({
    type: GET_ALL_INVOICES,

});

export const initLoading = () => ({
    type: INIT_LOADING
});

export const getInvoicesSuccess = response => ({
    type: GET_INVOICES_SUCCESS,
    payload: response,
});

export const exportPdf = invoiceId => ({
    type: EXPORT_PDF,
    payload: { invoiceId },
});

export const sendInvoice = invoiceId => ({
    type: SEND_INVOICE_MAIL,
    payload: { invoiceId },
});

export const getPdfInvoiceSuccess = response => ({
    type: GET_INVOICE_PDF_LINK_SUCCESS,
    payload: response,
});

export const sendEmailInvoiceSuccess = response => ({
    type: SEND_INVOICE_EMAILL_SUCCESS,
    payload: response,
});

export const resetInvoicePopUp = () => ({
    type: RESET_INVOICE_POPUP,
});

export const unmountInvoice = () => ({
    type: UNMOUNT_INVOICE
});

export const doneLoadingInvoice = () => ({
    type: DONE_LOADING_INVOICE
});

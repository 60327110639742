/**
 * Uniform Reducer
 */
import {
    GET_UNIFORMS,
    GET_UNIFORMS_SUCCESS,
    GET_UNIFORMS_FAILURE
} from 'Actions/types';

// initial state
const INIT_STATE = {
    uniforms: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_UNIFORMS:
            return { ...state };

        case GET_UNIFORMS_SUCCESS:
            return { ...state, uniforms: action.payload };

        case GET_UNIFORMS_FAILURE:
            return { ...state };

        default: return { ...state};
    }
};

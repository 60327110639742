import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import moment             from 'moment';

class TimeAgo extends Component {
    render() {
        const { time, formatTime } = this.props;
        const diffMins = moment().diff(time, 'minutes');

        if (diffMins < 60) {
            return time.fromNow();
        }

        // If its today
        if (time.clone().startOf('day').isSame(moment().startOf('day'))) {
            return (
                <FormattedMessage id="common.timeAgo.today.onlyTime" values={{time: time.format('HH:mm ')}} />
            );
        }


        // If its yesterday
        if (time.clone().startOf('day').isSame(moment().subtract(1, 'days').startOf('day'))) {
            return (
                <FormattedMessage id="common.timeAgo.yesterday" values={{time: time.format('HH:mm')}} />
            );
        }
        if (formatTime) {
            return time.format(formatTime);
        } 
            return time.format('YYYY/MM/DD - HH:mm');
        
    }
}

export default TimeAgo;

/**
 * Report Reducer
 */

import {
  GET_BLOCKED_SEEKERS_SUCCESS,
  GET_BLOCKED_SEEKERS_FAILURE,
  GET_RATING_OF_SEEKERS_SUCCESS,
  GET_RATING_OF_SEEKERS_FAILURE,
  GET_TOP_SEEKERS_BY_SHIFTS,
  GET_TOP_SEEKERS_BY_SHIFTS_REQUEST,
  GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS,
  GET_TOP_SEEKERS_BY_SHIFTS_FAILURE,
  GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS,
  GET_BILLING_REPORT,
  GET_BILLING_REPORT_SUCCESS,
  GET_BILLING_REPORT_FAILURE,
  GET_WEEKLY_FULFILMENT_REPORT,
  GET_WEEKLY_FULFILMENT_REPORT_SUCCESS,
  GET_WEEKLY_FULFILMENT_REPORT_FAILURE,
  GET_WEEKLY_TIMECARD_REPORT,
  GET_WEEKLY_TIMECARD_REPORT_SUCCESS,
  GET_WEEKLY_TIMECARD_REPORT_FAILURE,
  GET_MONTHLY_TIMECARD_REPORT,
  GET_MONTHLY_TIMECARD_REPORT_SUCCESS,
  GET_MONTHLY_TIMECARD_REPORT_FAILURE,
  GET_POSITIONS_STATISTICS_SUCCESS,
  GET_POSITIONS_STATISTICS_FAILURE,
} from "Actions/types";
import moment from "moment";

const INIT_STATE = {
  blockedSeekers: [],
  topSeekerLoading: false,
  topSeekers: [],
  topSeekersOfMonth: [],
  rating: {
    total: 0,
    average: 0,
  },
  spentTotal: {
    allTime: 0,
    thisWeek: 0,
    lastWeek: 0,
  },
  hourTotal: {
    allTime: 0,
    thisWeek: 0,
    lastWeek: 0,
  },
  weeklyStatistics: {},
  monthlyStatistics: {},
  fulfilmentStatistics: {},
  billingStatistics: {},
  weeklyStatsLoading: false,
  monthlyChartLoading: false,
  fulfilmentStatsLoading: false,
  billingStatsLoading: false,
  listPositions: [],
  error: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BLOCKED_SEEKERS_SUCCESS:
      return { ...state, blockedSeekers: action.payload.data.data };

    case GET_BLOCKED_SEEKERS_FAILURE:
      return { ...state, errors: action.error };

    case GET_RATING_OF_SEEKERS_SUCCESS:
      const total = action.payload.data.data.length;
      const average =
        Math.round(
          (action.payload.data.data.reduce(
            (accumulator, rating) => accumulator + rating.points,
            0
          ) /
            total) *
            2
        ) / 2;
      return { ...state, rating: { total, average } };

    case GET_RATING_OF_SEEKERS_FAILURE:
      return { ...state, errors: action.error };

    case GET_TOP_SEEKERS_BY_SHIFTS_REQUEST:
      return { ...state, topSeekerLoading: true, topSeekersOfMonth: [] };

    case GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS:
      return {
        ...state,
        topSeekers: action.payload.data.data,
        topSeekerLoading: false,
      };

    case GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS:
      return {
        ...state,
        topSeekersOfMonth: action.payload.data.data,
        topSeekerLoading: false,
      };

    case GET_TOP_SEEKERS_BY_SHIFTS_FAILURE:
      return { ...state, errors: action.error, topSeekerLoading: false };

    case GET_WEEKLY_FULFILMENT_REPORT: {
      return {
        ...state,
        fulfilmentStatsLoading: true,
        fulfilmentStatistics: {},
      };
    }

    case GET_WEEKLY_FULFILMENT_REPORT_SUCCESS: {
      return {
        ...state,
        fulfilmentStatsLoading: false,
        fulfilmentStatistics: {
          unixWeeks: action.payload.labels,
          totalRequest: action.payload.totalRequested,
          totalAssignment: action.payload.totalAssignment,
          totalAssignmentReal: action.payload.totalAssignmentReal,
          totalAttended: action.payload.totalAttended
        },
      };
    }

    case GET_WEEKLY_FULFILMENT_REPORT_FAILURE:
      return { ...state, errors: action.error, fulfilmentStatsLoading: false };

    case GET_WEEKLY_TIMECARD_REPORT: {
      return {
        ...state,
        weeklyStatsLoading: true,
        spentTotal: {
          allTime: 0,
          thisWeek: 0,
          lastWeek: 0,
        },
        hourTotal: {
          allTime: 0,
          thisWeek: 0,
          lastWeek: 0,
        },
        weeklyStatistics: {},
      };
    }

    case GET_WEEKLY_TIMECARD_REPORT_SUCCESS:
      const currentIsoWeek = moment().isoWeek();
      const previousIsoWeek = moment().subtract(1, "weeks").isoWeek();

      return {
        ...state,
        weeklyStatsLoading: false,
        // spentTotal: {
        //     allTime: Number(action.payload.totalGross),
        //     thisWeek: Number(action.payload.dataSets.gross[currentIsoWeek - 1]),
        //     lastWeek: Number(action.payload.dataSets.gross[previousIsoWeek - 1])
        // },
        // hourTotal: {
        //     allTime: Number(action.payload.totalDuration) / 3600,
        //     thisWeek: Number(action.payload.dataSets.time[currentIsoWeek - 1]) / 3600,
        //     lastWeek: Number(action.payload.dataSets.time[previousIsoWeek - 1]) / 3600
        // },
        weeklyStatistics: {
          unixWeeks: action.payload.labels,
          // hours: action.payload.dataSets.time,
          // revenues: action.payload.dataSets.gross
        },
      };

    case GET_WEEKLY_TIMECARD_REPORT_FAILURE:
      return { ...state, errors: action.error, weeklyStatsLoading: false };

    case GET_MONTHLY_TIMECARD_REPORT:
      return { ...state, monthlyChartLoading: true };

    case GET_MONTHLY_TIMECARD_REPORT_SUCCESS:
      return {
        ...state,
        monthlyChartLoading: false,
        monthlyStatistics: {
          unixMonths: action.payload.labels,
          hours: action.payload.dataSets.time,
          revenues: action.payload.dataSets.gross,
        },
      };

    case GET_MONTHLY_TIMECARD_REPORT_FAILURE:
      return { ...state, errors: action.error };

    case GET_BILLING_REPORT: {
      return {
        ...state,
        billingStatsLoading: true,
        billingStatistics: {},
      };
    }

    case GET_BILLING_REPORT_SUCCESS: {
      return {
        ...state,
        billingStatsLoading: false,
        billingStatistics: {
          unixWeeks: action.payload.labels,
          totalHours: action.payload.totalHours,
          totalCharge: action.payload.totalCharge,
          isWincanTon: action.payload.isWincanTon,
        },
      };
    }

    case GET_BILLING_REPORT_FAILURE:
      return { ...state, errors: action.error, billingStatsLoading: false };

    case GET_POSITIONS_STATISTICS_SUCCESS:
      return { ...state, listPositions: action.payload };

    case GET_POSITIONS_STATISTICS_FAILURE:
      return { ...state, errors: action.error };

    default:
      return { ...state };
  }
};

import API from 'Api';

/**
 * Get login detail of user
 * * @param {Object} request - Remote request object
 * @typedef {
        employerLevelId   : integer
    }
 *
 * @return async
 */
export const getQrCodeRequest = async(params) => await API.get('/employers/getqrcode', params)
    .then(response => response)
    .catch(err => err);
    /**
 * Call api to update business detail
 * * @param {Object} Login detail object
 * @typedef {
    id                      : integer,
    employer_id             : string,
    qr_code                 : string,
}
*
* @return async
*/
export const refreshQrCodeRequest = async(params) => await API.post('/employers/createqrcode', params)
    .then(response => response)
    .catch(err => err);

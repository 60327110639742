/**
 * Search Seekers Sagas
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
    PDF_GET_TIMESHEET_PENDING,
    PDF_GET_TIMESHEET_APPROVED,
    PDF_GET_DAILY_UPCOMING_JOB,
    PDF_GET_DAILY_PENDING_JOB,
    PDF_GET_APPLIED,
    PDF_GET_CONFIRMED,
} from "Actions/types";

import {
    pdfGetTimesheetPendingSuccess,
    pdfGetTimesheetPendingFailure,
    pdfGetTimesheetApprovedSuccess,
    pdfGetTimesheetApprovedFailure,
    showErrorResponse,
    pdfGetConfirmedSuccess,
    pdfGetConfirmedFailure,
    pdfGetWeeklyTimesheetSuccess,
    pdfGetWeeklyTimesheetFailure,
    pdfGetDailyUpcomingJobSuccess,
    pdfGetDailyUpcomingJobFailure,
    pdfGetDailyPendingJobSuccess,
    pdfGetDailyPendingJobFailure,
    hideRootSpinner,
} from "Actions";
import DownloadHelper from "Helpers/Download";
import {
    getPdfPendingRemoteRequest,
    getPdfApprovedRemoteRequest,
    exportPdfAppliedRequest,
    exportPdfConfirmedRequest,
    getPdfCalendarRequest,
    getPdfUpcomingRequest,
    getPdfPendingRequest,
    getExcelCalendarRequest,
} from "Services/employers/EmployerPdfService";
import {
    PDF_GET_WEEKLY_TIMESHEET,
    EXCEL_GET_WEEKLY_TIMESHEET,
} from "../actions/types";

const readerBlobData = (blob) => {
    const reader = new FileReader();
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
    });

    reader.onload = function (e) {
        deferred.resolve(reader.result);
    };

    reader.readAsDataURL(blob);

    return deferred.promise;
};

/**
 * Get pdf timesheet pending from server
 */
function* handleGetPdfTimesheetPending({ payload }) {
    try {
        const { startDate, endDate, internal, locationId } = payload;
        const response = +locationId
            ? yield call(
                  getPdfPendingRemoteRequest,
                  startDate,
                  endDate,
                  internal,
                  locationId,
              )
            : yield call(
                  getPdfPendingRemoteRequest,
                  startDate,
                  endDate,
                  internal,
              );
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );

        yield put(pdfGetTimesheetPendingSuccess(objectURL));
    } catch (error) {
        yield put(pdfGetTimesheetPendingFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf timesheet approved from server
 */
function* handleGetPdfTimesheetApproved({ payload }) {
    try {
        const { startDate, endDate, locationId } = payload;
        const response = +locationId
            ? yield call(
                  getPdfApprovedRemoteRequest,
                  startDate,
                  endDate,
                  locationId,
              )
            : yield call(getPdfApprovedRemoteRequest, startDate, endDate);
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );

        yield put(pdfGetTimesheetApprovedSuccess(objectURL));
    } catch (error) {
        yield put(pdfGetTimesheetApprovedFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf applied tab of detail job
 */
function* handlePdfGetApplied({ payload }) {
    const { positionId, jobType } = payload;
    try {
        const response = yield call(
            exportPdfAppliedRequest,
            positionId,
            jobType,
        );
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );
        yield put(pdfGetConfirmedSuccess(objectURL));
    } catch (error) {
        yield pdfGetConfirmedFailure(error);
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf confimed tab of detail job
 */
function* handlePdfGetConfirmed({ payload }) {
    const { positionId, jobType } = payload;
    try {
        const response = yield call(
            exportPdfConfirmedRequest,
            positionId,
            jobType,
        );
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );
        yield put(pdfGetConfirmedSuccess(objectURL));
    } catch (error) {
        yield pdfGetConfirmedFailure(error);
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf weekly timesheet of calendar job
 */
function* handleGetPdfWeeklyTimesheetPending({ payload }) {
    try {
        const { startDate, endDate } = payload;
        const response = yield call(getPdfCalendarRequest, startDate, endDate);
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );

        yield put(pdfGetWeeklyTimesheetSuccess(objectURL));
    } catch (error) {
        yield put(pdfGetWeeklyTimesheetFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf daily job of upcoming
 */
function* handleGetPdfDailyUpcomingJob({ payload }) {
    try {
        const { date, jobType } = payload;
        const response = yield call(getPdfUpcomingRequest, date, jobType);
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );

        yield put(pdfGetDailyUpcomingJobSuccess(objectURL));
    } catch (error) {
        yield put(pdfGetDailyUpcomingJobFailure(error));
        yield put(showErrorResponse(error));
    }
}

/**
 * Get pdf daily job of pending
 */
function* handleGetPdfDailyPendingJob({ payload }) {
    try {
        const { date } = payload;

        const response = yield call(getPdfPendingRequest, date);
        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/pdf" }),
        );

        yield put(pdfGetDailyPendingJobSuccess(objectURL));
    } catch (error) {
        yield put(pdfGetDailyPendingJobFailure(error));
        yield put(showErrorResponse(error));
    }
}

function* handleGetExcelWeeklyTimesheetPending({ payload }) {
    try {
        const { startDate, endDate } = payload;
        const response = yield call(
            getExcelCalendarRequest,
            startDate,
            endDate,
        );

        const objectURL = yield call(
            readerBlobData,
            new Blob([response.data], { type: "application/vnd.ms-excel" }),
        );
        DownloadHelper.forceDownload(objectURL, "Calendar-download.xls");
        yield put(hideRootSpinner());
    } catch (error) {
        yield put(hideRootSpinner());
    }
}

export function* watchPdfGetApplied() {
    yield takeEvery(PDF_GET_APPLIED, handlePdfGetApplied);
}

export function* watchPdfGetConfirmed() {
    yield takeEvery(PDF_GET_CONFIRMED, handlePdfGetConfirmed);
}

/**
 * Watch get pdf timesheet pending
 */
export function* watchGetPdfTimesheetPending() {
    yield takeEvery(PDF_GET_TIMESHEET_PENDING, handleGetPdfTimesheetPending);
}

/**
 * Watch get pdf timesheet approved
 */
export function* watchGetPdfTimesheetApproved() {
    yield takeEvery(PDF_GET_TIMESHEET_APPROVED, handleGetPdfTimesheetApproved);
}

export function* watchGetPdfWeeklyTimesheetPending() {
    yield takeEvery(
        PDF_GET_WEEKLY_TIMESHEET,
        handleGetPdfWeeklyTimesheetPending,
    );
}

export function* watchGetPdfDailyUpcomingJob() {
    yield takeEvery(PDF_GET_DAILY_UPCOMING_JOB, handleGetPdfDailyUpcomingJob);
}

export function* watchGetPdfDailyPendingJob() {
    yield takeEvery(PDF_GET_DAILY_PENDING_JOB, handleGetPdfDailyPendingJob);
}

export function* watchGetExcelWeeklyTimesheetPending() {
    yield takeEvery(
        EXCEL_GET_WEEKLY_TIMESHEET,
        handleGetExcelWeeklyTimesheetPending,
    );
}
/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(watchGetPdfTimesheetPending),
        fork(watchGetPdfTimesheetApproved),
        fork(watchPdfGetApplied),
        fork(watchPdfGetConfirmed),
        fork(watchGetPdfWeeklyTimesheetPending),
        fork(watchGetExcelWeeklyTimesheetPending),
        fork(watchGetPdfDailyUpcomingJob),
        fork(watchGetPdfDailyPendingJob),
    ]);
}

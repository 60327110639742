/**
 * App Routes
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import { FormattedMessage } from "react-intl";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import Header from "Components/Header/Header";
import SidebarContent from "Components/Sidebar";
import Footer from "Components/Footer/Footer";

// preload Components
import PreloadHeader from "Components/PreloadLayout/PreloadHeader";
import PreloadSidebar from "Components/PreloadLayout/PreloadSidebar";

// app config
import AppConfig from "Constants/AppConfig";

// actions
import { collapsedSidebarAction, startUserTour } from "Actions";

import "./style.scss";
import classNames from "classnames";

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingHeader: true,
      loadingSidebar: true,
      windowWidth: 0,
      windowHeight: 0,
      isShowSidebar: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    const { windowWidth } = this.state;
    window.addEventListener("resize", this.updateDimensions);
    if (AppConfig.enableUserTour && windowWidth > 600) {
      setTimeout(() => {
        this.props.startUserTour();
      }, 2000);
    }
    setTimeout(() => {
      this.setState({ loadingHeader: false, loadingSidebar: false });
    }, 114);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: $(window).width(),
      windowHeight: $(window).height(),
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  renderPage() {
    const { pathname } = this.props.location;
    const { children } = this.props;

    if (pathname === "/app/chat" || pathname.startsWith("/app/mail")) {
      return <div className="rct-page-content">{children}</div>;
    }

    return (
      <Scrollbars
        className="rct-scroll"
        autoHide
        autoHideDuration={100}
        style={this.getScrollBarStyle()}
      >
        <div className="rct-page-content">
          {children}
          <Footer />
        </div>
      </Scrollbars>
    );
  }

  // render header
  renderHeader() {
    const { loadingHeader } = this.state;
    const { history } = this.props;

    if (loadingHeader) {
      return <PreloadHeader />;
    }
    return <Header history={history} />;
  }

  // render Sidebar
  renderSidebar() {
    const { loadingSidebar } = this.state;
    if (loadingSidebar) {
      return <PreloadSidebar />;
    }
    return <SidebarContent />;
  }

  // Scrollbar height
  getScrollBarStyle() {
    return {
      height: "calc(100vh - 50px)",
    };
  }

  render() {
    const {
      navCollapsed,
      rtlLayout,
      miniSidebar,
      showRootSpinner,
      messageRootSpinner,
    } = this.props.settings;
    const { windowWidth, isShowSidebar } = this.state;
    return (
      <div className="app">
        <div className="app-main-container">
          {showRootSpinner && (
            <div className="root-spinner">
              <CircularProgress
                className="progress-warning"
                size={80}
                mode="determinate"
                value={40}
                min={0}
                max={50}
              />

              {messageRootSpinner && (
                <div className="p-10 center uppercase font-bold">
                  <FormattedMessage id={messageRootSpinner} />
                </div>
              )}
            </div>
          )}

          <Sidebar
            sidebar={this.renderSidebar()}
            open={isShowSidebar}
            docked={windowWidth > 1199 ? isShowSidebar : false}
            pullRight={rtlLayout}
            onSetOpen={() => this.props.collapsedSidebarAction(false)}
            styles={{ content: { overflowY: "" } }}
            contentClassName={classnames({
              "app-conrainer-wrapper": miniSidebar,
            })}
          >
            <div className="app-container">
              <div className="rct-app-content">
                <div className="app-header">{this.renderHeader()}</div>
                <div className="rct-page">
                  {this.renderPage()}
                  <button
                    className={classNames([
                      "sidebar-toggle",
                      {
                        active:
                          windowWidth > 1199 ? !isShowSidebar : isShowSidebar,
                      },
                    ])}
                    onClick={() =>
                      this.setState({ isShowSidebar: !isShowSidebar })
                    }
                  >
                    <i class="sidebar-toggle__arrow"></i>
                  </button>
                </div>
              </div>
            </div>
          </Sidebar>

          {/* <ThemeOptions /> */}
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => ({ settings });

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
    startUserTour,
  })(MainApp)
);

import React from "react";

module.exports = {
    emptyText: " ",
    "common.hoursWithoutSpace":
        "{hours, plural, =0 {0hrs} one {1hr} other {{hours}hrs}}",
    "common.hoursWithSpace":
        "{hours, plural, =0 {0hrs} one {1hr} other {{hours} hrs}}",
    "common.totalProvided": `{provided}`,
    "common.percent": `%`,
    "common.GBP": `£`,
    "common.moneyGBP": `£{money}`,
    "common.breakMinutes": "{minutes}m break",
    "common.button.yes": "Yes",
    "common.button.no": "No",
    "common.button.confirm": "Confirm",
    "common.button.ok": "Ok",
    "common.dateOfWeek.1": "Monday",
    "common.dateOfWeek.2": "Tuesday",
    "common.dateOfWeek.3": "Wednesday",
    "common.dateOfWeek.4": "Thursday",
    "common.dateOfWeek.5": "Friday",
    "common.dateOfWeek.6": "Saturday",
    "common.dateOfWeek.7": "Sunday",
    "common.monthOfYear.1": "January",
    "common.monthOfYear.2": "February",
    "common.monthOfYear.3": "March",
    "common.monthOfYear.4": "April",
    "common.monthOfYear.5": "May",
    "common.monthOfYear.6": "June",
    "common.monthOfYear.7": "July",
    "common.monthOfYear.8": "August",
    "common.monthOfYear.9": "September",
    "common.monthOfYear.10": "October",
    "common.monthOfYear.11": "November",
    "common.monthOfYear.12": "December",
    "common.timeAgo.today": "Today, {time}",
    "common.timeAgo.today.onlyTime": "{time}",
    "common.timeAgo.yesterday": "Yesterday, {time}",
    "common.yearExperiences":
        "{years, plural, =0 {} one {One year experience} other {{years} years experience}} ",
    "common.spinner.loadMore": "Loading more jobs...",
    "common.at": "at",
    "common.submit": "Submit",
    "common.confirm": "Confirm",
    "common.optional": "Optional",
    "common.errorServer": "500 Internal Server Error",
    "common.errorSessionExpired": "Your session has expired. Please re-login",
    "common.copyLink": "Copy link",
    "common.copied": "Copied",

    "sidebar.app": "App",
    "sidebar.general": "General",
    "sidebar.component": "Component",
    "sidebar.features": "Features",
    "sidebar.applications": "Applications",
    "sidebar.login": "Login",
    "sidebar.register": "Register",
    "sidebar.forgotPassword": "Forgot Password",
    "sidebar.404": "404",
    "sidebar.500": "500",
    "sidebar.uiComponents": "UI Components",
    "sidebar.chip": "Chip",
    "sidebar.dialog": "Dialog",
    "sidebar.dividers": "Dividers",
    "sidebar.drawers": "Drawers",
    "sidebar.popover": "Popover",
    "sidebar.expansionPanel": "Expansion Panel",
    "sidebar.gridList": "Grid List",
    "sidebar.list": "List",
    "sidebar.menu": "Menu",
    "sidebar.popoverAndToolTip": "Pop Over & ToolTip",
    "sidebar.progress": "Progress",
    "sidebar.snackbar": "Snackbar",
    "sidebar.selectionControls": "Selection Controls",
    "sidebar.advanceUiComponents": "Advance UI Components",
    "sidebar.dateAndTimePicker": "Date & Time Picker",
    "sidebar.tabs": "Tabs",
    "sidebar.stepper": "Stepper",
    "sidebar.notification": "Notification",
    "sidebar.sweetAlert": "Sweet Alert",
    "sidebar.calendar": "Calendar",
    "sidebar.cultures": "Cultures",
    "sidebar.dnd": "Dnd",
    "sidebar.selectable": "Selectable",
    "sidebar.customRendering": "Custom Rendering",
    "sidebar.chat": "Chat",
    "sidebar.editor": "Editor",
    "sidebar.wysiwygEditor": "WYSIWYG Editor",
    "sidebar.quillEditor": "Quill Editor",
    "sidebar.reactAce": "React Ace",
    "sidebar.dragAndDrop": "Drag And Drop",
    "sidebar.reactDragula": "React Dragula",
    "sidebar.reactDnd": "React Dnd",
    "sidebar.blogManagement": "Blog Management",
    "sidebar.ecommerce": "My Dashboard",
    "sidebar.shopList": "Shop List",
    "sidebar.shopGrid": "Shop Grid",
    "sidebar.invoice": "Invoice",
    "sidebar.multilevel": "Multilevel",
    "sidebar.sublevel": "Sublevel",
    "widgets.ratings": "Ratings",
    "widgets.logIn": "Log In",
    "widgets.signUp": "Sign Up",
    "widgets.switches": "Swiches",
    "widgets.dateAndTimePicker": "Date And Time Picker",
    "widgets.defaultPicker": "Default Picker",
    "widgets.timePicker": "Time Picker",
    "widgets.weekPicker": "Week Picker",
    "widgets.defaultDatePicker": "Default Date Picker",
    "widgets.customPicker": "Custom Picker",
    "widgets.tabs": "Tabs",
    "widgets.fixedTabs": "Fixed Tabs",
    "widgets.basicTab": "Basic Tab",
    "widgets.wrappedLabels": "Wrapped Labels",
    "widgets.centeredLabels": "Centered Labels",
    "widgets.forcedScrolledButtons": "Forced Scrolled Buttons",
    "widgets.iconsTabs": "Icons Tabs",
    "widgets.withDisableTabs": "With Disable Tabs",
    "widgets.iconWithLabel": "Icon With Label",
    "widgets.stepper": "Stepper",
    "widgets.verticalStepper": "Vertical Stepper",
    "widgets.descriptionAlert": "Description Alert",
    "widgets.customIconAlert": "Custom Icon Alert",
    "widgets.withHtmlAlert": "With Html Alert",
    "widgets.promptAlert": "Prompt Alert",
    "widgets.inlineForm": "Inline Form",
    "widgets.inputSizing": "Input Sizing",
    "widgets.inputGridSizing": "Input Grid Sizing",
    "widgets.hiddenLabels": "Hidden Labels",
    "widgets.formValidation": "Form Validation",
    "widgets.number": "Number",
    "widgets.time": "Time",
    "widgets.color": "Color",
    "widgets.search": "Search",
    "compenets.username": "Username",
    "compenets.passwords": "Passwords",
    "widgets.forgetPassword": "Forget Password",
    "compenets.signIn": "Sign In",
    "compenets.enterUserName": "Enter User Name",
    "compenets.enterEmailAddress": "Enter Email Address",
    "compenets.confirmPasswords": "Confirm Passwords",
    "components.enterYourPassword": "Enter Your Password",
    "components.unlock": "Unlock",
    "components.enterPasswords": "Enter Passwords",
    "components.resestPassword": "Resest Password",
    "components.pageNotfound": "Page not Found",
    "components.goToHomePage": "Go To Home Page",
    "components.sorryServerGoesWrong": "Sorry Server Goes Wrong",
    "components.persistentDrawer": "Persistent Drawer",
    "components.withHtml": "With Html",
    "components.prompt": "Prompt",
    "components.withDescription": "With Description",
    "components.success": "Success",
    "components.passwordPrompt": "Password Prompt",
    "widgets.username": "Username",
    "widgets.password": "Password",
    "widgets.signIn": "Sign In",
    "widgets.enterYourPassword": "Enter Your Password",
    "widgets.all": "All",
    "tab.posted": "Applied",
    "tab.filled": "Confirmed",
    "tab.declined": "Declined",
    "tab.noGig": "This seeker does not have any <span class='text-dark'>gigs</span>",
    "tab.notLoadedJob": "Not Loaded",
    "tab.noPermission": "No Permission",
    "tab.noApplied": "This seeker does not have any <span class='text-dark'>applied</span>",
    "tab.noConfirmed": "This seeker does not have any <span class='text-dark'>confirmed</span>",
    "tab.noExperience": "This seeker does not have any <span class='text-dark'>experience </span>",
    "tab.experienceAt": `<b>{position}</b> at <b>{company}</b>`,
    "tab.noComment": "This seeker does not have any <span class='text-dark'>comments </span>",
    "tab.noNote": "This seeker does not have any <span class='text-dark'>notes </span>",
    "tab.noLicences": "This seeker does not have any <span class='text-dark'>licences or training</span>",
    "button.cancel": "Cancel",
    "button.cancelQuestion": "Cancel?",
    "button.add": "Add",
    "button.update": "Update",
    "button.createAccount": "Create Account",
    "button.reply": "Reply",
    "button.delete": "Delete",
    "button.yes": "Yes",
    "button.markNoShow": "Mark As No Show",
    "button.noShow": "No Show",
    "button.viewAll": "View All",
    "button.like": "Like",
    "button.assignNow": "Assign Now",
    "button.seeInsights": "See Insights",
    "button.loadMore": "Load More",
    "button.editJob": "Edit",
    "button.saveJob": "Save",
    "button.repostJob": "Repost",
    "button.cancelAction": "Cancel",
    "button.checkIn": "Check In",
    "button.checkOut": "Check Out",
    "sidebar.dateTimePicker": "Date & Time Picker",
    "components.summary": "Summary",
    "hint.whatAreYouLookingFor": "What are You Looking For",
    "components.yesterday": "Yesterday",
    "components.last7Days": "Last 7 Days",
    "components.last1Month": "Last 1 Month",
    "components.last6Month": "Last 6 Month",
    "components.spaceUsed": "Space Used",
    "components.followers": "Follower",
    "components.trending": "Trending",
    "components.paid": "Paid",
    "components.refunded": "Refunded",
    "components.done": "Done",
    "components.pending": "Pending",
    "components.cancelled": "Cancelled",
    "components.approve": "Approve",
    "components.week": "Week",
    "components.month": "Month",
    "components.year": "Year",
    "components.today": "Today",
    "components.email": "Email",
    "components.forename": "Forename",
    "components.surename": "Surname",
    "components.password": "Password",
    "components.passwordConfirm": "Confirm password",
    "widgets.ourLocations": "Our Locations",
    "widgets.fullScreenDialogs": "Full Screen Dialogs",
    "widgets.formDialogs": "Form Dialogs",
    "widgets.user": "User",
    "widgets.admin": "Admin",
    "widgets.notifications": "Notifications",
    "widgets.logOut": "Logout",
    "widgets.loginDetail": "Login Detail",
    "widgets.bussinessDetail": "Business Detail",
    "button.sendMessage": "Send Message",
    "button.search": "Search",
    "button.primary": "Primary",
    "button.secondary": "Secondary",
    "button.danger": "Danger",
    "button.info": "Info",
    "button.success": "Success",
    "button.warning": "Warning",
    "button.link": "Link",
    "button.button": "Button",
    "button.save": "Save",
    "button.openMenu": "Open Menu",
    "button.openPopover": "Open Popover",
    "button.accept": "Accept?",
    "button.decline": "Decline?",
    "button.checkIn": "Check-in",
    "button.checkOut": "Check-out",
    "button.click": "Click",
    "button.complete": "Complete",
    "button.back": "Back",
    "button.next": "Next",
    "button.error": "Error",
    "button.addNewUser": "Add New User",
    "button.updateUser": "Update User",
    "button.refresh.qr": "Refresh QR",
    "button.more": "More",
    "sidebar.user": "Users",
    "sidebar.miscellaneous": "Miscellaneous",
    "sidebar.promo": "Promo",
    "themeOptions.themeColor": "Theme Color",
    "components.cardNumber": "Card Number",
    "components.expiryDate": "Expiry Date",
    "components.cvv": "CVV",
    "components.nameOnCard": "Name On Card",
    "components.confirmPayment": "Confirm Payment",
    "components.currency": "£",
    "components.jobCard.ListSeekerTab.isOverlaps.title":
        "seeker already booked",
    "components.jobCard.ListSeekerTab.isOverlaps.description":
        "Unfortunately this seeker has now been accepted for another gig at the same time",
    "components.jobCard.ListSeekerTab.cannotAcceptAll.title":
        "Seekers Unavailable",
    "components.jobCard.ListSeekerTab.cannotAcceptAll.description":
        "One or more of the selected seekers is already booked, or have reached their max student hours.",
    "components.jobCard.ListSeekerTab.studentExceed.title":
        "seeker is student",
    "components.jobCard.ListSeekerTab.studentExceed.description":
        "This seeker is studying and has reached their weekly working hour limit",
    "components.jobCard.ListSeekerTab.positionFilled.title":
        "Error",
    "components.jobCard.ListSeekerTab.positionFilled.description":
        'There are not enough spaces available for the number of seekers selected. \n\nPlease check the list and select the seeker you wish to add.',
    "sidebar.saas": "SAAS",
    "sidebar.multiLevel": "MultiLevel",
    "sidebar.level1": "Level 1",
    "sidebar.level2": "Level 2",

    "pagination.prevIcon": "&laquo;",
    "pagination.nextIcon": "&raquo;",

    // GIG LOCALES
    btnStartlogin: "Login",
    loginForgetPassword: "Forgot password?",
    loginNoAccount: "Don't have an account?",
    loginSignup: "Sign up",
    loginEmailVerifyFailed: "Your email address could not be verified.",
    loginValid: "Your email address or password is incorrect",
    loginEmailRequired: "Email is required",
    loginEmailValid: "Email is valid",
    loginPasswordRequired: "Password is required",
    enterEmailAddress: "Enter Email Address",
    enterPassword: "Password",

    "signUp.page.title1": "Sign up",
    "signUp.page.title2": "Business settings",
    "signUp.page.title3": "Shift details",
    "signUp.creditcard.detail": "Credit card details",
    "signUp.guideForGPDR":
        "In order to use GIGs services you must review and accept the way in which we collect and handle your data:",
    "signUp.button.startPosting": "Start Posting",
    "signUp.acceptDataCollectedPrefix": "Our full privacy policy can be viewed",
    "widgets.here": "here",
    "signUp.business.name": "Business name",
    "signUp.business.email": "Business email",
    "signUp.location.of.business": "Business location",
    "signUp.card.cvc": "CVC",
    "signUp.business.street": "Business address",
    "signUp.business.city": "City",
    "signUp.business.postcode": "Business postcode",
    "signUp.business.phone": "Business phone",
    "signUp.upload.logo": "Upload logo",
    "signUp.upload.chooseFile": "Choose file...",
    "signUp.upload.logo.empty": "Please upload a logo",
    "signUp.acceptDataCollectedSuffix":
        "Please verify that you consent to how your personal data may be collected, used, disclosed and processed.",
    "signUp.acceptTermPrefix": "Our full terms and conditions can be viewed",
    "signUp.acceptTermSuffix":
        "Please verify that you accept the terms and conditions of our service.",
    "signUp.acceptReceiveUpdateProduct":
        "From time to time we would like to contact you via email/sms regarding updates to our product and our service. Please accept to opt in to receiving these updates.",
    "signUp.message.success": "Please check your email and verify your address",
    "signUp.button.nextStep": "Next step",
    "signUp.button.bookStaffNow": "Book staff now",
    "signUp.page.title4": "Choose a service",
    "signUp.page.fifth.Gig": "Gig",
    "signUp.shift.name.of.business": "Nature of Business",
    "signUp.shift.type.of.staff": "Type of staff",
    "signUp.shift.number.of.staff": "Number of staff you need",
    "signUp.shift.gig.duration": "Gig duration",
    "signUp.shift.how.did.you.hear": "How did you hear about us?",
    "signUp.page.fifth.Direct": "Direct",
    "signUp.page.fifth.Premium": "Premium",
    "signUp.page.fifth.direct.content":
        '<p><strong>GIG\'s self-service platform gives employers access to a skilled, flexible and on-demand workforce who can service a number of roles and positions.</strong></p> <br> <p class="payment-description">Providers can post and advertise their positions, communicate with candidates, and organise pay, rotas and shift details. This service is ideal for small to medium sized business who are looking for temp staff on tap.</p>',
    "signUp.page.fifth.direct.footer":
        "<p>All you need is your company credit card details and you're good to go!</p>",
    "signUp.page.fifth.premium.content":
        "<p><b>A more comprehensive service that incorporates GIG's Account Management team, who are on-hand to deal wit any enquiries, requests and last-minute demands for temporary staff.</b> </p> <br> <p class='payment-description'> As an end-to-end service, this is ideal for large scale business who have large, complex rotas and need assistance in filling multiple positions.</p>",
    "signUp.page.fifth.premium.footer":
        "<p>To get set up with a Premium account, you will need to speak with a member of our sales team.</p>",
    "signUp.page.eighth.premium.content":
        "<p>To get a better understanding of your business and </p> <p>staffing needs, a member of our team will be in touch.</p> <p>Schedule a call bellow:</p>",
    "signUp.page.eighth.premium.footer": "<p>I would like to be contacted:</p>",
    "signUp.page.eighth.checkbox.message": "As soon as posible",
    "signUp.page.eighth.checkbox.message2": "As more convenient time",
    "signUp.page.eighth.name.empty": "Name is required",
    "signUp.page.eighth.dateCall.empty": "Please set time for contact",
    "signUp.accessCodeTitle": "Already Spoken to a Member of the Team?",
    "signUp.accessCode":
        "Enter you access code below to continue with your request",
    "signUp.checkingCode": "Verifying your code...",
    "signUp.checkingCode.failure":
        "Your access code is incorrect. Please try again!",
    "signUp.Phone.empty": "Phone Number is required",
    "signUp.Name.empty": "Name is required",
    "signUp.Phone.max": "Phone Number must not be longer than 16 characters",
    "signUp.Phone.min": "Phone Number must be at least 6 characters",
    "signUp.Phone.invalid": "Invalid Phone number",
    "signUp.profile.complete": "Profile Complete",
    "signUp.payment.not.complete":
        "Payment will not be processed until the shift is complete",
    "signUp.name.on.card": "Name on card:",
    "signUp.card.number": "Card number:",
    "signUp.card.type": "Card type:",
    "signUp.visa.debit": "Visa debit",
    "signUp.master.debit": "Master debit",
    "signUp.Expires": "Expires:",
    "signUp.cvc": "CVC",
    "signUp.Name": "Name",
    "signUp.phone.number": "Phone Number",
    "signUp.businessName.empty": "Please enter Business Name",
    "signUp.businessEmail.empty": "Please enter Business Email",
    "signUp.businessStreet.empty": "Please enter Business Address",
    "signUp.businessCity.empty": "Please enter City",
    "signUp.businessPostcode.empty": "Please enter a valid Business postcode",
    "signUp.businessPhone.empty": "Please enter a valid phone",
    "signUp.datetime.contact.error": "Time can only fall between 9am and 6pm",
    "signUp.Name.min": "Namer must be at least 3 characters",

    "signUp.contact.message":
        "A member of our team will be in touch as soon as posible.",
    "signUp.complete.message": "Your profile is now complete.",
    "widgets.emailAddress": "Email address",

    // **** Validation ****
    "validation.email.required": "Email is required",
    "validation.email.invalid": "Email is invalid",
    "validation.name.required": "Name is required",
    "validation.password.required": "Password is required",
    "validation.password.notBlank": "Password can not be blank",
    "validation.comment.notBlank": "Comment can not be blank",
    "validation.passwordRepeat.invalid":
        "Password confirm is not match password",
    "validation.password.atLeastSixCharacters":
        "Password length must be at least 6 characters",
    "validation.termsAndConditions.notAccepted":
        "Terms and conditions not accepted",
    "validation.forename.required": "Forename is required",
    "validation.surename.required": "Surname is required",

    "widgets.back": "back",
    "widgets.date": "Date",
    "dashboard.date": "Date",
    "dashboard.hour": "Hours",
    "dashboard.position": "Position",
    "dashboard.location": "Location",
    "dashboard.posted": "Posted",
    "dashboard.staffRequired": "Requested",
    "dashboard.staffConfirmed": "Confirmed",
    "dashboard.status": "Status",
    "dashboard.statusFilled": "Filled",
    "dashboard.statusPending": "Pending",
    "dashboard.statusGoing": "On Going",
    "dashboard.staffApplied": "Applied",
    "dashboard.staffDeclined": "Declined",
    "dashboard.startTime": "Start",
    "dashboard.finishTime": "Finish",
    "dashboard.jobDescription": "Job Description",
    "dashboard.specialRequirements": "Special Requirements",
    "dashboard.uniform": "Uniform",
    "dashboard.action": "Actions",

    "sidebar.weeklyCalendar": "Weekly",
    "sidebar.monthlyCalendar": "Monthly",
    "sidebar.dashboard": "Calendar",
    "sidebar.myGigs": "My Gigs",
    "sidebar.upcoming": "Posted/Filled",
    "sidebar.posted": "Posted",
    "sidebar.filled": "Filled",
    "sidebar.myGigs.pending": "Pending/In Progress",
    "sidebar.completed": "Completed",
    "sidebar.timesheets": "Timesheets",
    "sidebar.pending": "Pending",
    "sidebar.weekly": "Weekly",
    "sidebar.daily": "Daily",
    "sidebar.approved": "Approved",
    "sidebar.reports": "Reports",

    "sidebar.billing": "Billing",
    "sidebar.invoicing": "Invoicing",
    "sidebar.cardManagement": "Card Management",
    "sidebar.users": "Users",

    // location
    "label.user.placeholder.name": "Enter Name",
    "label.user.placeholder.email": "Enter Email",
    "label.user.placeholder.phone": "Enter Phone",
    "label.user.placeholder.position": "Enter Position",
    "label.user.placeholder.location": "Enter Location",
    "label.loation.name": "Location Name",
    "label.loation.city": "City",
    "label.loation.street": "Street",
    "label.loation.no_building": "Building Number",
    "label.loation.postcode": "Postcode",
    "label.loation.country": "Country",
    "head.popup.errors.title": "Error",
    "widgets.location": "Location",
    "sidebar.locations": "Locations/Depts",
    "head.location": "Locations",
    "button.AddLocation": "Add New +",
    "button.EditLocation": "Edit",
    "button.DeleteLocation": "Delete",
    "components.addNewLocation": "Add New Location",
    "components.editLocation": "Edit Location",
    "empty.street.location": "Street is required",
    "empty.city.location": "City is required",
    "empty.building.location": "No./Building is required",
    "empty.name.location": "Location name is required",
    "empty.postcode.location": "Postcode is required",
    "postcode.invalid": "Postcode: Please specify a valid UK postcode",
    "invalid.location":
        "We could not find your location. Please verify if the given address is correct.",
    "settings.locations.save.api.error.2020":
        "Location with the same name already exists.",
    "settings.locations.save.api.error.2021":
        "Location can not be changed as it is already connected to a live gig.",
    "settings.locations.save.api.error.2001":
        "Your session has expired. Please re-login",
    "message.popup.delete": "The location will be deleted",
    "overlap.message":
        "Please note that {employeeName} is booked on another gig that clashes with your edit. By clicking confirm edit they will be removed from this gig posting and the position will be re-advertised",
    "btn.ok": "Ok",
    "txt.confirm": "Confirm",
    "button.confirm.edit": "Confirm",
    "button.cancel.edit": "Cancel",
    "empty.location.message": `Your location <br/>will display here. <br/><br/>To Add a Location<br/>click add new above`,

    "tooltip.addSeeker": "Add Seeker/Employee",
    "tooltip.add_new_gig": "Add New Gig",
    "tooltip.messages": "Messages",
    "tooltip.nudge": "Nudge",
    "tooltip.message": "Message",
    "tooltip.edit": "Edit",
    "tooltip.repost": "Repost",
    "tooltip.delete": "Delete",
    "tooltip.splitShifts": "Split Shifts",
    "tooltip.viewShifts": "View Shifts",
    "tooltip.promote": "Promote",
    "tooltip.unpromote": "Un-Promote",
    "tooltip.detail": "Detail",
    "tooltip.actions": "Actions",

    "listSeekerTabs.emptyPosted":
        "There are currently  <span class='no-uniform-requirement'>no live applications</span> for this Gig.",
    "listSeekerTabs.emptyFilled":
        "There are currently <span class='no-uniform-requirement'>no seekers</span> added to this Gig.",
    "listSeekerTabs.emptyDeclined":
        "There are currently <span class='no-uniform-requirement'>no declined</span> seekers for this Gig.",

    "jobCard.syntaxPending": "A",
    "jobCard.syntaxFilled": "F",
    "jobCard.money.pound": "£",
    "jobCard.syntaxBetween": "@",
    "jobCard.selectAll": "Select All",
    "jobCard.listSeekerTab.noShowDescription":
        "You are about to mark {employeeName} as No Show for {positionName} shift starting {dateShift} {startShift}. You will not be charged for this shift",
    "jobCard.confirmed.print": "Print Seekers List",
    "jobCard.confirmed.download": "Download Seekers List",
    "infoTab.organizer": "Organizer",

    "repostGig.title": "Repost Gig",
    "repostGig.skillDisabled":
        "{skillName} position is no longer available. Please choose other one.",

    "editGig.title": "Edit Gig",
    "editGig.success": "Edit Gig Successfully",
    "editGig.failure": "Can not edit Gig currently. Please try again!",
    "editGig.overlap": "Overlap datetime for employee {employeeName}",

    "chargeRate.emptyPayRate": "Empty pay rate",
    "chargeRate.emptyChargeRate": "Empty charge rate",
    "chargeRate.chargeRateSmallerPayRate":
        "Charge rate must be larger than pay rate",
    "chargeRate.minimumPayrate": "Pay rate must be larger or equal to {min}",
    "chargeRate.errorType": "Form has some errors please check again! ",
    "chargeRate.updateSuccess": "Charge Rate is updated successfully !",
    "chargeRate.updateFail": "Charge Rate is updated fail",
    "createGig.uniformAddition": "Uniform Additional",
    "createGig.title": "Create New Gig",
    "createGig.chooseLocation": "Choose Location",
    "createGig.chooseView": "Choose View",
    "createGig.printUpcoming": "Print or Download a Day",
    "createGig.positionNeeded": "Position Needed",
    "createGig.numberOfSeekers": "Number of Seekers",
    "createGig.postedSeekers": "Posted",
    "createGig.requestedSeekers": "Requested",
    "createGig.uniform": "Uniform",
    "createGig.jobDescription": "Job Description",
    "createGig.specialRequirements": "Special Requirements",
    "createGig.gigShifts": "Gig Shifts",
    "createGig.gigShiftsNo": "Gig Shift #",
    "createGig.schedule.start": "Start Date",
    "createGig.schedule.end": "End Date",
    "createGig.schedule.startTime": "Start Time",
    "createGig.schedule.endTime": "End Time",
    "createGig.schedule.breakTime": "Break Time",
    "createGig.addShift": "Add Shift",
    "createGig.payRate": "Pay Rate",
    "createGig.chargeRate": "Charge Rate",
    "createGig.placeholder.uniform.shirt": "Choose Shirt/T-Shirt",
    "createGig.placeholder.uniform.jeans": "Choose Trousers/Jeans",
    "createGig.placeholder.uniform.shoes": "Choose Shoes",
    "createGig.placeholder.uniform.other": "Other...",
    "createGig.addBlackTie": "Black Tie",
    "createGig.addHighVisVest": "High Vis Vest",
    "createGig.addHighVisCap": "High Vis Cap",
    "createGig.addBlazer": "Blazer",
    "createGig.addExtraUniform": "Add Extra Uniform",
    "createGig.addGloves": "Gloves",
    "createGig.placeholder.jobDescription":
        "Busy high street restaurant requires an outgoing and enthusiastic waiter with at least 2 years experience to help assist with taking/placing orders as well as serving and setting/cleaning down tables",
    "createGig.placeholder.specialRequirements":
        "Please arrive at the service entrance which is located xxxx and ask for xxxx who will prepare you for your shift",
    "createGig.button.addShift": "Add New Shift",
    "createGig.button.saveGig": "Post Shift",
    "updateGig.button.saveGig": "Update Gig Shift",
    "createGig.input.breakTime": "Min",
    "createGig.note.postGig": "Once posted Seekers will be able to apply",
    "createGig.validate.payRate.minValue":
        "The pay rate must be at least £{minimumPayrate}",
    "createGig.validate.description.required": "Job Description is required",
    "createGig.validate.description.minLength":
        "Job Description must be at least {number} characters",
    "createGig.validate.specialRequirements.required":
        "Special Requirements is required",
    "createGig.validate.schedules.inPast":
        "The job must have End Time later than Start Time",
    "createGig.validate.schedules.minDuration":
        "Job duration must be larger or equal to {hour} {hour, plural, one {hr} other {hrs}}",
    "createGig.validate.schedules.maxDuration":
        "Maximum duration is only {hour} {hour, plural, one {hr} other {hrs}}",
    "createGig.validate.schedules.breakTime":
        "Working time should be more than {min} mins",
    "createGig.validate.schedules.overlapped":
        "The job has overlapping schedules",
    "createGig.success": "Your new Gig was created successfully",
    "createGig.failure": "Can not save new Gig currently. Please try again!",
    "createGig.missing.card":
        "You must have a valid card added to your account before being able to post gig",
    "createGig.missing.location":
        "You must have at least location before being able to post gig",
    "createGig.privateJob": "Internal Job",
    "createGig.privateJobDescription":
        "Internal jobs are for your internal employees only, you can let GIG seekers view this job at any time.",
    "createTimecard.label": "Post as timecard",
    "createTimecard.breakTime": "Break Time (Mins)",
    "createTimecard.inputSearch.placeholder": "Search seeker",
    "createTimecard.saveTimecard": "Post as timecard",
    "createTimecard.searchNoSeekers": "‘No seekers found",
    "createTimecard.validate.searchMinLength":
        "Please type at least 1 characters to search seekers",
    "createTimecard.validate.emptySeeker":
        "Must add at least one seeker to create timecard",
    "createTimecard.validate.future":
        "Job must be past job to post as timecard",
    "createTimecard.success": "Create timecard successfully",
    "createTimecard.failure":
        "Can not create timecard currently. Please try again!",

    "timesheets.pageTitle": "Timesheets",
    "timesheets.tab.weekly": "Weekly",
    "timesheets.tab.daily": "Daily",
    "timesheets.tab.gig": "Gig",
    "timesheets.tab.internal": "Internal",
    "timesheets.seeker": "Seeker",
    "timesheets.request.timeIn": "Time In",
    "timesheets.request.timeOut": "Time Out",
    "timesheets.request.breakTime": "Breaks",
    "timesheets.request.breakTime.mins": "Mins",
    "timesheets.request.dateOut": "Date Out",
    "timesheets.request.totalHours": "Total Hours",
    "timesheets.tooltips.approve": "Approve",
    "timesheets.tooltips.decline": "Decline",
    "timesheets.tooltips.approveAll": "Approve All",
    "timesheets.tooltips.selectAll" : "Select All",
    "timesheets.tooltips.unSelectAll" : "UnSelect All",
    "timesheets.tooltips.select" : "Select",
    "timesheets.tooltips.unSelect" : "UnSelect",
    "timesheets.tooltips.approveSelected": "Approve Selected",
    "timesheets.tooltips.declineSelected": "Decline Selected",
    "timesheets.tooltips.declineAll": "Decline All",
    "timesheets.tooltips.editBtn": "Edit",
    "timesheets.tooltips.thumbsUp": "Thumbs up",
    "timesheets.tooltips.favourite": "Favourite",
    "timesheets.tooltips.feedback": "Feedback",
    "timesheets.tooltips.block": "Block seeker",
    "timesheets.tooltips.unblock": "Un-block seeker",
    "timesheets.buttons.decline": "Decline",
    "timesheets.buttons.noShow": "No Show",
    "timesheets.validation.minLength":
        "Please type at least 1 characters to search seekers",
    "timesheets.validate.minPay":
        "Must pay minimum {hour} {hour, plural, one {hr} other {hrs}} for {seekerName} on {dateOfWeek}",
    "timesheets.validate.maxPay":
        "Must pay maximum {hour} {hour, plural, one {hr} other {hrs}} for {seekerName} on {dateOfWeek}",
    "timesheets.validate.minPayWithoutDate":
        "Must pay minimum {hour} {hour, plural, one {hr} other {hrs}}",
    "timesheets.validate.maxPayWithoutDate":
        "Must pay maximum {hour} {hour, plural, one {hr} other {hrs}}",
    "timesheets.validate.approveAll.minPay":
        "Must pay minimum {hour} {hour, plural, one {hr} other {hrs}} for {seekerName}",
    "timesheets.validate.approveAll.maxPay":
        "Must pay maximum {hour} {hour, plural, one {hr} other {hrs}} for {seekerName}",
    "timesheets.approve.success": "This payment request has now been approved",
    "timesheets.approve.failure":
        "Can not approve payment request currently. Please try again!",
    "timesheets.decline.success": "This payment request has now been declined",
    "timesheets.decline.failure":
        "Can not decline payment request currently. Please try again!",
    "timesheets.weekly.emptyTimecards": `<div class="header-empty-message">
No pending <br>timecards
    </div>
<div class="body-empty-message">
Within the time<br>period selected.
    </div>`,
    "timesheets.daily.emptyTimecards": `<div class="header-empty-message">No pending <br/>timecards  </div> <div class="body-empty-message">In the selected date. </div>`,
    "timesheets.spinner.onApprovingSingle": "Approving this seeker...",
    "timesheets.spinner.onApprovingList": "Approving all seekers...",
    "timesheets.spinner.onApprovingChecked": "Approving all seekers selected...",
    "timesheets.printPdf": "Print",
    "timesheets.downloadXls": "Download Xls",

    "timesheets.approved.panelTitle": "Approved",
    "timesheets.approved.totalCosts": "Total Costs",
    "timesheets.approved.totalHours": "Total Hours",
    "timesheets.approved.seekers": "Total Seekers",
    "timesheets.approved.VAT": "VAT",
    "timesheets.approved.emptyTimecards": `<div class="header-empty-message"> No timecards have<br/>been approved<br/> </div> <div class="body-empty-message">Within the time<br/>period selected. </div>`,

    "feedbackForm.title": "Leave Feedback",
    "feedbackForm.summary":
        "Do you have any additional comments about your shift? Seekers value your feedback and these comments will appear on their profile when applying for future work.",
    "feedbackForm.choiceTitle":
        "Choose one of the options below or personalise a message:",
    "feedbackForm.firstChoice":
        "{seekerName} was punctual, presentable and did what he was asked",
    "feedbackForm.secondChoice":
        "{seekerName} performed well throughout the shift and we would welcome him/her back",
    "feedbackForm.thirdChoice":
        "{seekerName} went above and beyond what was asked for him. Highly recommend!",
    "feedbackForm.messagePlaceholder": "Type message here",
    "feedbackForm.validate.required":
        "Please select one radio button at a time or type the message to the box above",

    "confirmation.blockEmployee":
        "Please confirm that you wish to block this worker",
    "confirmation.unBlockEmployee":
        "Please confirm that you wish to un-block this worker",
    "confirmation.giveThumbsUp":
        "Please confirm that you wish to give this worker a thumbs up",
    "confirmation.approveRequest":
        `Are you sure you want to accept <div class="text-bolder">this Seeker</div>`,
    "confirmation.approveRequestMultiple":
        `Are you sure you want to accept <div class="text-bolder">all selected Seekers</div>`,
    "confirmation.approveAllRequest":
        "Please confirm that you want to approve all workers",
    "confirmation.approveSelectedRequest":
        `Are you sure you want to Approve This Selected Timesheets?`,
    "confirmation.approveAllSelectedRequest":
        `Are you sure you want to Approve All Selected Timesheets?`,
    "confirmation.declineSelectedRequest":
        `Are you sure you want to Decline This Selected Timesheets?`,
        "confirmation.declineAllSelectedRequest":
        `Are you sure you want to Decline All Selected Timesheets?`,
    "confirmation.declineRequestTimesheet":
        "Are you sure you want to decline this seeker",
    "confirmation.declineRequest":
        `Are you sure you want to decline <div class="text-bolder">this seeker</div>`,
    "confirmation.declineRequestMultiple":
        `Are you sure you want to decline <div class="text-bolder">all selected seekers</div>`,
    "confirmation.checkInRequestMultiple":
        "Are you sure you want to Check in these selected workers at:",
    "confirmation.checkInRequest":
        "Are you sure you want to Check in this worker at:",
    "confirmation.checkOutRequestMultiple":
        "Are you sure you want to Check out these selected workers at:",
    "confirmation.checkOutRequest":
        "Are you sure you want to Check out this worker at:",
    "confirmation.declineAllRequest":
        "Please confirm that you want to decline all workers",
    "confirmation.sendMailTimesheet": "Send mail successfully",
    "confirmation.declineAssignment":
        `Are you sure you want to decline <div class="text-bolder">this seeker</div>`,
    "confirmation.workingSixDay":
        `This shift will take {seeker} over 6 days working out of 7. Are you sure you want to add those workers ?`,
    "confirmation.declineAssignmentMultiple":
        `Are you sure you want to decline <div class="text-bolder">all selected seekers</div>`,
    "approveRequest.success.message":
        "This payment request has now been approved",
    "declineRequest.success.message":
        "This payment request has now been declined",
    "blockEmployee.success.message": "This worker has been blocked.",
    "blockEmployee.failure.message":
        "Can not block this worker. Please try again!",
    "unBlockEmployee.success.message": "This worker has been un-blocked.",
    "unBlockEmployee.failure.message":
        "Can not un-block this worker. Please try again!",

    // User
    "user.role.id.provider": "Account Provider",
    "user.role.id.manager": "Account Manager",
    "user.level.standard": "Standard",
    "user.level.admin": "Admin",

    "upcomingJob.blank.first": "You have no <br /> upcoming gigs.",
    "upcomingJob.blank.second":
        "To start posting, click <br/> the <span class='black'> Add New Gig</span> button <br/>",
    "upcomingJob.blank.third": "on the nav bar above.",

    "posted.blank.first": "Your posted gig shifts <br /> will display here.",
    "posted.blank.second":
        "To start posting, click <br/> <span class='black font-add-new'> Add New Gig</span> on ",
    "posted.blank.third": "the nav bar above.",
    "upcomingJob.empty.specialRequirements":
        "There are currently no special requirements for this Gig",
    "upcomingJob.empty.uniforms":
        "There are currently <div class='no-uniform-requirement'> no uniform requirements </div> for this Gig",

    "filled.blank.first": "Your filled gig shifts <br /> will display here.",
    "filled.blank.second":
        "To start posting, click <br/> <span class='black font-add-new'> Add New Gig</span> on ",
    "filled.blank.third": "the nav bar above.",

    "completed.blank.first":
        "Your completed gig shifts <br /> will display here.",
    "completed.blank.second":
        "To start posting, click <br/> <span class='black font-add-new'> Add New Gig</span> on ",
    "completed.blank.third": "the nav bar above.",

    "pending.blank.first": "Your pending gig shifts <br /> will display here.",
    "pending.blank.second":
        "To start posting, click <br/> <span class='black font-add-new'> Add New Gig</span> on ",
    "pending.blank.third": "the nav bar above.",

    "calendarJob.jobType.open": "Open",
    "calendarJob.jobType.filled": "Filled",
    "calendarJob.jobType.completed": "Completed",
    "calendarJob.jobType.pending": "Pending",

    "error.requiredField": "This field is required",
    "user.name.empty": "Name is required",
    "user.email.empty": "Email Address is required",
    "user.password.empty": "Password id required",
    "user.password.confirmation.empty": "Password confirmation is required",
    "user.phone.empty": "Tel is required",
    "user.position.empty": "Location is required",
    "user.position.min": "Position must be longer than 3 character",
    "user.tooltip.show": "Show",
    "user.tooltip.edit": "Edit",
    "user.tooltip.delete": "Delete",
    "user.password.not.match": "Password and confirmation password not match",
    "user.phone.valid":
        "Tel must be longer than 10 characters and less than 50 .",
    "user.phone.must.be.number": "Invalid contact number",
    "user.message.popup.delete": "Please confirm you wish to delete this user",
    "user.delete.error": "Error during delete",
    "user.role.level.admin": "Admin",
    "user.role.level.standard": "Standard",
    "user.delete.all": "Delete All",
    "user.message.confirm.delete.all": "The selected user will be deleted",
    "user.minimum.password.length": "Password must be at least 6 character",
    "user.minimum.password.confirmation.length":
        "Password confirmation must be at least 6 character",
    "user.already.exist.message": "Email already Exists",
    "user.head.title.user": "User",
    "user.head.title.position": "Position",
    "user.head.title.location": "Location",
    "user.head.title.email": "Email",
    "user.head.title.tel": "Tel",
    "user.head.title.status": "Status",
    "user.head.title.level": "Level",
    "user.head.title.date_created": "Date Created",
    "user.minimum.name": "Please enter at least 6 character",
    "user.message.validation": "Cannot delete users",
    "user.label.name": "Name",
    "user.label.email": "Email",
    "user.label.tel": "Tel",
    "user.label.location": "Location",
    "user.label.position": "Position",
    "user.label.location": "Location",
    "user.label.level": "Level",
    "user.label.password": "Password",
    "user.label.confirm_password": "Confirm Password",
    "user.label.logo_thumbnail": "Upload Logo",
    "user.login.status.1": "Online",
    "user.login.status.2": "Offline",
    "user.login.status.3": "Inactive",
    "users.blank": `<div class="header-empty-message">Your users will<br/>display here.</div><div class="body-empty-message">To Add a User click<br/>the add new button<br/>on the nav bar above.</div>`,
    "users.create.success": "Created successfully",
    "users.update.success": "Updated Successfully",

    "reports.panelHeader.totalSpent": "Total Spent",
    "reports.panelHeader.totalHours": "Total Hours",
    "reports.panelHeader.totalRatings": "Total Ratings",
    "reports.panelHeader.monthly": "Monthly",
    "reports.panelHeader.weekly": "Weekly",
    "reports.panelHeader.mostPopularPositions": "Most Popular Positions",
    "reports.panelHeader.seekers": "Seekers",
    "reports.seekers.topSeekers": "All time greats",
    "reports.seekers.topSeekersOfMonth": "Last 4 weeks",
    "reports.seekers.topSeekersOf12Week": "Last 12 weeks",
    "reports.seekers.topSeekersOf6Month": "Last 6 months",
    "reports.seekers.topSeekersOf9Month": "Last 9 months",
    "reports.seekers.topSeekersOf12Month": "Last 12 months",
    "reports.seekers.blockedSeekers": "Blocked Seekers",
    "reports.seekers.shifts": "Shifts:",
    "reports.seekers.lastShift": "Last shift:",
    "reports.chartMonthly.hours": "Hours",
    "reports.chartMonthly.expenses": "Expenses",
    "reports.total": "Total",
    "reports.thisWeekLastWeek": "Last Week",
    "reports.ratings": "ratings",
    "reports.unblock": "Unblock",
    "reports.empty.popularPositions": `You do not have<br>any popular<br>positions yet`,
    "reports.empty.weeklyReports": `There are no<br>weekly reports`,
    "reports.empty.monthlyReports": `There are no<br>monthly reports`,
    "reports.topseeker.lastshift": "Last shift:",
    "reports.topseeker.totalShifts": "Shifts:",

    "messages.title": "Messages",
    "messages.inputMessage.placeholder": "Type your message",
    "messages.emptyMessages.firstLine": "You have no<br/> messages.",
    "messages.emptyMessages.secondLine":
        "When you do they <br/>will <span class='page-blank-message_bold'> display here</span>.",

    "words.gigs": "gigs",
    "words.experience": "experience",
    "words.comments": "comments",
    "words.role": "role",
    "words.provider": "provider",
    "words.homeOffice": "Home office:",
    "words.phoneNumber": "Phone Number:",
    "messages.questions.cancelJob":
        "Are you sure you want to delete {skillName} position at {location}?",
    "seekerList.hasSmartPhone": "Has smart phone",
    "seekerList.buttons.addSeekerToJob": "Add seeker to job",
    "seekerList.buttons.alreadyAdded": "Added",
    "seekerList.buttons.nudgeSeeker": "Nudge",
    "seekerList.buttons.nudgeUnavailable": "Unavailable",
    "seekerList.buttons.addSeeker": "Add",
    "seekerList.buttons.addSeekerToTimeCard": "Add Seeker",
    "seekerList.buttons.addUnavailable": "Unavailable",
    "seekerList.buttons.employeeDetails": "Employee Details",

    "card.name.label": "Name On Card",
    "card.name.label1": "Name On Card",
    "card.number.label": "Card Number",
    "card.expire.label": "Expiry Date",
    "card.cvc.label": "CVC",
    "card.create.button": "Add Card",
    "card.errors.70": "sorry, we could not get your card details.",
    "card.errors.50": "Invalid Card Number",
    "card.errors.60": "Customer alredy add card",
    "card.errors.80": "Could not add card",
    "card.errors.invalid_expiry_year": "Your card's expiration year is invalid",
    "card.errors.incorrect_number": "Your card number is incorrect",
    "card.errors.parameter_missing": "Could not find payment information",
    "card.errors.empty_card_name": "Please add card name",
    "card.info.create.success": "Create card successful",
    "card.info.update.success": "Update card successful",
    "card.powered.by": "Powered by",
    "card.stripe": "stripe",
    "card.name.empty": "Card Name is required",

    "invoice.add.new.card.button": "Add new card",
    "invoice.edit.card.button": "Update Card",
    "invoice.button.addCard": "Add card",
    "invoice.email.button": "Email",
    "invoice.print.button": "Print",
    "invoice.head.title": "Invoicing",
    "invoice.view.all": "View all",
    "invoice.empty.message": "You have no <br/> live invoices.",

    "seekerSignal.emptySeeker": "Have no any seeker signal found.",
    "seekerSignal.title": "Seeker Signal",

    "nudgeSeeker.title": "Nudge Seekers",
    "nudgeSeeker.gigFavourite": "Gig favourite",
    "nudgeSeeker.placeholders.experience": "Experienced in...",
    "nudgeSeeker.placeholders.numberGig": "Number of gig completed...",
    "nudgeSeeker.placeholders.distance": "Distance ...",
    "nudgeSeeker.placeholders.searchInput": "Search...",
    "nudgeSeeker.numberGigThresholds.range": "From {min} to {max}",
    "nudgeSeeker.numberGigThresholds.higher": "{min}+",
    "nudgeSeeker.numberGigThresholds.lower": "Under {max}",
    "nudgeSeeker.doNudge.success": "This seeker has been nudged.",
    "nudgeSeeker.emptySeeker": "No seekers matching the criteria can be found",
    "nudgeSeeker.doNudge.failure":
        "Can not nudge this seeker currently. Please try again!",
    "nudgeSeeker.doAdd.unavailable":
        "This Seeker/Employee has marked themselves as unavailable within this Gig's schedule.",
    "nudgeSeeker.doAdd.over18":
        "You cannot nudge this seeker as they are under 18. <br />Please edit the job if you wish to nudge under 18's.",
    "nudgeSeeker.doAdd.working6Day":
        "This shift will take this seeker over 6 days working out of 7. Are you sure you want to nudge this workers ?",
    "nudgeSeeker.distance.5mile": "5 miles",
    "nudgeSeeker.distance.10mile": "10 miles",
    "nudgeSeeker.distance.20mile": "20 miles",
    "nudgeSeeker.distance.30mile": "30 miles",
    "nudgeSeeker.distance.40mile": "40 miles+",
    "nudgeSeeker.doNudge.restriction48hours.title": "Maximum Hours Reached",
    "nudgeSeeker.doNudge.restriction48hours.message": "The Seeker reached their maximum working hours according to the WTD and cannot work any more hours this week. <br/>The Seeker can update this through their App at any time if they wish to Opt Out of the WTD and work more than 48 hours per week.",

    "addSeeker.title": "Add Seekers/Employees to Gig",
    "addSeeker.validation.minLength":
        "Please type at least 3 characters to search seekers",
    "addSeeker.confirmAddPopup":
        "Please confirm you want to add this seeker to position",
    "addSeeker.empty.searchResult": "No seekers found",
    "addSeeker.doAdd.success": "This seeker has been added.",
    "addSeeker.doAdd.exceeded":
        "This seeker is studying and has reached their weekly working hour limit.",
    "addSeeker.doAdd.failure":
        "Can not add this seeker currently. Shift is filled or They are already booked on another shift.",
    "addSeeker.doAdd.unavailable":
        "This Seeker/Employee has marked themselves as unavailable within this Gig's schedule.",
    "addSeeker.doAdd.working6Day":
        "This shift will take this seeker over 6 days working out of 7. Are you sure you want to add this workers?",
    "addSeeker.doAdd.working6Day":
        "This shift will take this seeker over 6 days working out of 7. Are you sure you want to add this workers?",
    "addSeeker.doAdd.in8h":
        "If you add this Seeker they will have less than 8 hours of rest between shifts. Please check their other booked shifts.  Are you sure you want to add this worker?",
    "addSeeker.doAdd.over18":
        "You cannot add this seeker as they are under 18. <br />Please edit the job if you wish to add under 18's.",
    "addSeeker.doAdd.restriction48hours.title": "Maximum Hours Reached",
    "addSeeker.doAdd.restriction48hours.message": "The Seeker reached their maximum working hours according to the WTD and cannot work any more hours this week. <br/>The Seeker can update this through their App at any time if they wish to Opt Out of the WTD and work more than 48 hours per week.",
    "invoice.total.net.amount": "Total Net Amount",
    "invoice.carriage.net": "Carriage Net",
    "invoice.total.tax.amount": "Total Tax Amount",
    "invoice.total": "Invoice Total",
    "invoice.description.message": "FOR SERVICES PROVIDED",
    "invoice.description": "Description",
    "invoice.vat.no": "VAT No: ",
    "invoice.bank.details": "Bank Details:",
    "invoice.account.no": "Account No: ",
    "invoice.sort.code": "Sort Code: ",
    "invoice.hour": "HOUR",
    "invoice.per.hour": "PER HOUR",

    "notification.panelTitle": "Notifications",
    "notification.dropdownTooltip": "Notifications",
    "notification.button.viewAll": "VIEW ALL",
    "notification.button.showOlder": "Show Older",
    "notification.emptyData": "You do not have any recent notifications",
    notification: "Notification",
    "notification.onRedirecting": "Redirecting to your GIG...",

    "confirmPassword.wrongPassword": "Your entered password is incorrect",
    "confirmPassword.summaryText": "Enter your password to confirm",
    "confirmPassword.inputPassword.placeholder": "Confirm password",
    "amendingHours.Title": "Amending hours?",
    "amendingHours.Summary": "Please comment to explain the reason for the change in hours.",
    "amendingHours.placeholderText": "Enter your comment",
    "settings.chargeRates": "Charge Rates",
    "settings.provider": "Provider",
    "settings.seeker": "Seeker",
    "settings.seekerSignal": "Seeker Signal",
    "404.message": "Sorry, page not found",
    "404.code": "404",
    "404.oops": "Oops..",
    "404.go.to.login.page": "Go to Login",
    "404.go.to.home.page": "Go to Home Page",
    "chargeRateModal.title": "Rates",
    "chargeRateModal.masterRate": "Master Rate",
    "chargeRateModal.holidayPay": "Holiday Pay",
    "chargeRateModal.pensionAndApprentice": "Pension & Apprentice",
    "chargeRateModal.employerNi": "Employers NI",
    "chargeRateModal.marginFixed": "Margin Fixed £",
    "chargeRateModal.marginPercent": "Margin %",
    "chargeRateModal.otherCosts": "Other costs",

    "chargeRateModa.errorMasterRateEmpty": "Empty Master Rate",
    "chargeRateModa.errorMasterRateOverFlow":
        "Master Rate must be equal or smaller than 100%",
    "chargeRateModal.updateMasterRateSuccess":
        "Master rate is updated successfully !",
    "chargeRateModal.updateMasterRateFail": "Master rate is updated fail !",
    "chargeRateModal.otherCostValidationFail" : "Other cost must be number and not empty",
    "chargeRateModal.pensionLevyValidationFail" : "Pension and Apprentice must be number and not empty",


    "splitShifts.confirmPopup.title": "Confirm?",
    "splitShifts.confirmPopup.message":
        "Are you sure you want <br/> to Split these shifts?",
    "splitShifts.onSplitting": "Splitting shifts...",

    "footer.topTips": "Top Tips",
    "footer.termsAndConditions": "Terms and Conditions",
    "footer.contactUs": "Contact Us",
    "footer.privacyPolicy": "Privacy Policy",
    "qr.code.employer": "QR Code Employer",

    "user.login.detail.title": "Login Details",
    "user.login.detail.fullname": "Full Name",
    "user.login.detail.email": "Email",
    "user.login.detail.current.password": "Current Password",
    "user.login.detail.new.password": "New Password",
    "user.login.detail.confirm.password": "Confirm Password",

    "loginDetail.name.empty": "Fullname is required",
    "loginDetail.update.success": "Updated success",
    "loginDetail.email.empty": "Email is required",
    "loginDetail.currentPassword.empty": "Current password is required",
    "loginDetail.minimum.currentPassword.length":
        "Current password must be at least 6 character",
    "loginDetail.newPassword.empty": "New Password is required",
    "loginDetail.confirmPassword.empty": "Confirm password is required",
    "loginDetail.password.not.match":
        "Password and confirmation password not match",
    "loginDetail.minimum.password.length":
        "Password must be at least 6 character",
    "loginDetail.emai.invalid": "Email is invalid",
    "loginDetail.sendMail.success": "Please verify new mail",
    "loginDetail.minimum.password.confirmation.length":
        "Password confirmation must be at least 6 character",

    "business.detail.title": "Business Details",
    "business.detail.name": "Company Name",
    "business.detail.email": "Business Email",
    "business.detail.city": "City",
    "business.detail.street": "street",
    "business.detail.building": "building",
    "business.detail.postcode": "postcode",
    "business.detail.companyNumber": "company number",
    "business.detail.vatNumber": "vat number",
    "business.detail.fullname": "Full Name",
    "business.detail.userEmail": "Email",
    "business.detail.contactNumber": "Contact Number",
    "businessDetail.name.empty": "Company Name is required",
    "businessDetail.ba_postcode.max":
        "Postcode must not be longer than 10 character",
    "businessDetail.email.empty": "Email is required",
    "businessDetail.ba_city.empty": "City is required",
    "businessDetail.ba_building.empty": "Building is required",
    "businessDetail.ba_postcode.empty": "Postcode is required",
    "businessDetail.ba_postcode.invalid": "Postcode is invalid",
    "businessDetail.ba_street.empty": "Street is required",
    "businessDetail.company_number.max":
        "Company number must not be longer than 50 characters",
    "businessDetail.vat_number.empty": "Vat number is required",
    "businessDetail.company_number.empty": "Company number is required",
    "businessDetail.company_number.invalid": "Company number invalid",
    "businessDetail.vat_number.9.digit": "VAT Number must contain 9 digits",
    "businessDetail.vat_number.invalid": "This is not a valid VAT Number",
    "businessDetail.contactNumber.empty": "Contact Number is required",
    "businessDetail.contactNumber.max":
        "Company number must not be longer than 16 characters",
    "businessDetail.contactNumber.min":
        "Company number must be at least 6 characters",
    "businessDetail.emai.invalid": "Email is invalid",
    "businessDetail.accountOwner": "Account owner",
    "businessDetail.contactNumber.invalid": "Invalid contact number",
    "businessDetail.max.size.1MB": "Image can not larger than 1MB",
    "logo.max.size.1MB": "Logo can not larger than 1MB",

    "businessDetail.phone.empty": "Business Phone is required",
    "businessDetail.phone.max":
        "Business Phone must not be longer than 16 characters",
    "businessDetail.Phone.min": "Business Phone must be at least 6 characters",
    "businessDetail.Phone.invalid": "Invalid Business phone",

    "jobDetails.jobDescription": "Job Description",
    "jobDetails.specialRequirements": "Special Requirements",
    "jobDetails.uniforms": "Uniform",
    "jobDetails.empty.specialRequirements": "No Special Requirements",
    "jobDetails.empty.uniforms": "No Uniform Required",
    "jobDetails.button.internal": "Internal",

    "internalEmployee.tooltip.standardAccount": "Employees",
    "internalEmployee.title.standardAccount": "Add Employee",
    "internalEmployee.title.update": "Update Employee",
    "internalEmployee.uploadCSV": "Upload CSV",
    "internalEmployee.uploadCSV.sample": "Download Sample CSV",
    "internalEmployee.uploadCSV.empty": "Empty or invalid CSV file",
    "internalEmployee.uploadCSV.invalidHeader": "Missing/invalid header fields",
    "internalEmployee.uploadCSV.success":
        "Internal Employee imported successfully!",
    "internalEmployee.uploadCSV.failure":
        "Can not import new Internal Employees currently. Please try again!",
    "internalEmployee.uploadCSV.invalidRole": "{role} is not a valid role",
    "internalEmployee.uploadCSV.emptyRoles":
        "Each employee must have at least one role",
    "internalEmployee.uploadCSV.rowError": "Error(s) occurred at row {row}:",
    "internalEmployee.firstName": "First Name",
    "internalEmployee.firstName.empty": "First name is required",
    "internalEmployee.secondName": "Second Name",
    "internalEmployee.secondName.empty": "Second name is required",
    "internalEmployee.email": "Email",
    "internalEmployee.phone": "Phone Number",
    "internalEmployee.phone.empty": "Phone Number is required",
    "internalEmployee.phone.must.be.number": "Invalid Phone Number",
    "internalEmployee.phone.valid":
        "Phone Number must be longer than 10 characters and less than 50 .",
    "internalEmployee.roles": "Roles",
    "internalEmployee.addRole": "Add role",
    "internalEmployee.button.add": "Add Employee",
    "internalEmployee.add.success": "Internal Employee created successfully!",
    "internalEmployee.add.failure":
        "Can not save new Internal Employee currently. Please try again!",
    "internalEmployee.add.failure.exist": "Email already Exists",
    "internalEmployee.update.success":
        "Internal Employee updated successfully!",
    "internalEmployee.update.failure":
        "Can not update Internal Employee currently. Please try again!",
    "internalEmployee.message.popup.delete":
        "Please confirm you wish to delete this internal employee",
    "internalEmployee.delete.success":
        "Internal Employee deleted successfully!",
    "internalEmployee.delete.failure":
        "Can not delete Internal Employee currently. Please try again!",
    "internalEmployee.empty": "Your employees will display here.",
    "internalEmployee.button.edit": "Edit",
    "internalEmployee.button.delete": "Delete",
    "internalEmployee.none": "Seeker",

    "modalAllJobs.tooltip": "Universe",
    "modalAllJobs.title": "My Dashboard",
    "modalAllJobs.default.startIn": "Starts in:",
    "modalAllJobs.default.status": "Status:",
    "modalAllJobs.default.location": "Location:",
    "modalAllJobs.location.other": "Other...",
    "modalAllJobs.location.placeholder": "Enter Location",
    "modalAllJobs.tableHeader.date": "Date",
    "modalAllJobs.tableHeader.position": "Position",
    "modalAllJobs.tableHeader.location": "Location",
    "modalAllJobs.tableHeader.staffRequired": "Staff Required",
    "modalAllJobs.tableHeader.staffAllocated": "Staff Allocated",
    "modalAllJobs.tableHeader.staffApplied": "Staff Applied",
    "modalAllJobs.emptyResults": "Not found any jobs",
    "modalAllJobs.tooltip.toogleDetail": "Toogle Detail",
    "modalAllJobs.startIn.hour":
        "{hour, number} {hour, plural, one {hour} other {hours}}",
    "modalAllJobs.startIn.day":
        "{day, number} {day, plural, one {day} other {days}}",
    "modalAllJobs.status.job-posted": "Posted",
    "modalAllJobs.status.job-filled": "Filled",
    "modalAllJobs.status.job-completed": "Completed",
    "modalAllJobs.status.job-pending": "Pending",

    "directMessage.tooltip": "Direct Message",
    "directMessage.title": "Direct Message",
    "directMessage.placeholder.search": "Search...",
    "directMessage.placeholder.fullName": "Your name",
    "directMessage.placeholder.subject": "Subject",
    "directMessage.placeholder.message": "Message",
    "directMessage.button.send": "Send",
    "directMessage.button.back": "Back to conversations",
    "directMessage.button.close": "Close",
    "directMessage.button.delete": "Delete",
    "directMessage.confirmDelete":
        "Are you sure you want to delete this conversation?",
    "directMessage.deleteSucces":
        "You have successfully deleted the conversation",
    "directMessage.button.open": "Open",
    "directMessage.confirmClose":
        "Are you sure you want to close this conversation?",
    "directMessage.confirmOpen":
        "Are you sure you want to open this conversation?",
    "directMessage.emptyConversations": "You do not have any conversations",
    "empty.fullname.conversation": "Fullname is required",
    "empty.subject.conversation": "Subject is required",
    "empty.message.conversation": "Messages is required",

    "providerModal.title": "Providers",
    "providerModal.placeholders.searchInput": "Search...",

    "seekerModal.title": "Seekers",
    "seekerModal.placeholders.searchInput": "Search...",

    // validate employer field
    "employer.name.empty": " Please enter a valid Fullname",
    "employer.email.invalid": " Please enter a valid email",
    "employer.title.createNewAccount": "Create New Account",
    "employer.chooseFile": "Choose File",
    "employer.deleteFile": "Choose File",
    "employer.noFileChosen": "No file chosen",
    "employer.email.exists": "Email already Exists",
    "employer.accessCode.invalid": "Invalid access code",
    "employer.title.addNew": "Add New",

    "employer.blockAccount": "Block Account",
    "employer.unblockAccount": "Unlock Account",
    "employer.saveChanges": "Save Changes",
    "employer.cancelChange": "Cancel Changes",

    "document.loading": "Loading",

    "fullNameBank.empty": "Please enter name of bank",
    "accountHolder.empty": "Please enter bank account name",
    "accountNo.empty": "Please enter bank account number",
    "sortCode.empty": "Please enter bank sort code",
    "title.empty": "Please select a title",
    "maritialStatus.empty": "Please select a Marital Status",
    "nationality.empty": "Please select a nationality",
    "firstName.empty": "Please enter the firstname",
    "lastName.empty": "Please enter the lastname",
    "nationalInsuranceNo.invalid": "NI format must be AB123456C",
    "phone.invalid": "Invalid phone number",
    "delete.experience.title": "Delete Experience",
    "delete.experience.description": "Do you want to delete this experience?",
    "delete.history.title": "Delete History",
    "delete.history.description": "Do you want to delete this history?",

    //validate experience
    "experience.pos.empty": "Position is required",
    "experience.company.empty": "Company name is required",
    "experience.responsibility.empty": "Responsibility name is required",
    "experience.startdate.empty": "Start date is require",
    "experience.endate.empty": "End date is require",
    "experience.endDateStartDate.greaterThan":
        "Finish date greater than start date",

    "report.block": "Block",
    "modal.block.search.seeker": "Search Seekers",
    "jobcard.internal.button": "Internal",
    "jobcard.posttoGIG.button": "Post to GIG +",
    "jobcard.postedtoGIG.button": "Posted to GIG",
    "jobcard.posttogig.button": "Post to Gig +",
    "jobcard.posttogig.question": "Are you sure you want to post to GIG?",
    "jobcard.postedtogig.button": "Posted to Gig",

    "header.search.seeker.validation.empty": "No seeker found.",
    "header.search.seeker.validation.minlength": "Please enter at least 3 characters.",

    //file max size 25MB

    "message.file.max": "Attachment is too big. Max file size: 25 MB",
    "message.file.duration": "Attachment is too big. Max file duration: 90 seconds",
    "message.note.attachment": "Note: All attachment(s) will only be available for 2 weeks",
    "checkinTime.validate.early": "You cannot check-in over 2 hours",
    "checkinTime.validate.late": "You cannot check-in after the end of the shift",
    "checkoutTime.validate.late": "You cannot check-out over 5 hours",
    "checkoutTime.validate.early": "You cannot check-out before the shift start",
    "acceptSeeker.working6Day":
    "This shift will take this seeker over 6 days working out of 7. Are you sure you want to accept this workers?",
    "acceptSeeker.working6Day":
    "This shift will take this seeker over 6 days working out of 7. Are you sure you want to accept this workers?",
    'jobHasBeenDeleted': "This job has been deleted"
};

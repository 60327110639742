import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import { FormGroup, Input, Label } from "reactstrap";

import Warning from "Assets/img/SweetAlert/warning.png";
import Moment from "moment";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import PayChargeRate from "Components/Model/CreateEditJob/PayChargeRate_v2";
import PostJobConstants from "Components/Model/CreateEditJob/PostJobConstants";
import Schedule from "Components/Model/CreateEditJob/Schedule";
import Timecard from "Components/Model/CreateEditJob/Timecard";
import Spinner from "Components/Model/Spinner";
import AppConfig from "Constants/AppConfig";
import MyGig from "Constants/MyGig";
import RoutePaths from "Constants/RoutePaths";

import PostedInternalPopup from "../CreateEditJob/PostedInternalPopup";

import { KeyboardArrowDown, Close } from "@material-ui/icons";

import {
    closeCreateJobModal,
    getUniforms,
    getAllSkills,
    getLocation,
    getChargeRateByPayRate,
    createNewShift,
    createNewPosition,
    createTimecard,
    checkCardValid,
    searchSeekersResetResults,
    changeChargeRate,
    getInternalEmployeeList,
} from "Actions";

import OAuth from "OAuth";
import moment from "moment";
import IntlMessages from "Util/IntlMessages";
import NumberOfSeeker from "./NumberOfSeeker";
import ListOfLocations from "./ListOfLocations";
import debounce from "lodash.debounce";

const oauth = new OAuth();
const MISSING_DATA = {
    LOCATION: "location",
    CARD: "card",
};
const { UNIFORM_TYPES, VALIDATION, CREATING_PROCESS, UNIFORM_IDS } =
    PostJobConstants;

class CreateEditJob extends Component {
    state = {};

    constructor(props) {
        Moment.locale("en");

        super(props);

        const uniformIds = {};

        uniformIds[UNIFORM_TYPES.SHIRT] = 0;
        uniformIds[UNIFORM_TYPES.JEANS] = 0;
        uniformIds[UNIFORM_TYPES.SHOES] = 0;
        uniformIds[UNIFORM_TYPES.BLACK_TIE] = 0;
        uniformIds[UNIFORM_TYPES.HIGH_VIS_VEST] = 0;
        uniformIds[UNIFORM_TYPES.HIGH_VIS_CAP] = 0;
        uniformIds[UNIFORM_TYPES.GLOVES] = 0;

        this.state = {
            uniformIds,
            formValidation: {},
            payRateValidatedFailed: false,
            hasError: false,
            apiCalled: false,
            onSavingGig: false,
            schedules: [],
            locationId: 0,
            description: "",
            specialRequirements: "",
            totalAssignment: 1,
            totalRequested: 1,
            skillId: null,
            otherUniform: "",
            privateJob: false,
            over18Only: false,
            postedInternalPopupTarget: null,
            openPostedInternalPopup: false,
            missingAlerts: {
                show: false,
                messageKey: "",
                redirect: "",
            },
            blackTie: false,
            highVisVest: false,
            hightVisCap: false,
            gloves: false,
            blazer: false,
            openExtraUniform: false,
            isLoaded: false,
            // payRate and chargeRate that are used to display
            payRate: null,
            chargeRate: null,
            minPayRate: null,
            disabledInputPayrate: false,
        };

        this.descriptionInput = createRef();
        this.closeModal = this.closeModal.bind(this);
        this.addSchedule = this.addSchedule.bind(this);
        this.removeSchedule = this.removeSchedule.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.debounceUpdateChargeRate = debounce(this.updateChargeRate, 1000);
    }

    componentDidMount() {
        this.addSchedule();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { apiCalled, locationId, skillId, isLoaded } = this.state;
        const {
            showCreateJob,
            isMissingLocation,
            isMissingCard,
            defaultLocationId,
            chargeRate: newChargeRate,
        } = nextProps;

        const { listOfSkills } = this.props;
        if (showCreateJob) {
            if (isMissingLocation) this.showMissingAlert(MISSING_DATA.LOCATION);
            if (isMissingCard) this.showMissingAlert(MISSING_DATA.CARD);

            if (!apiCalled) {
                this.props.getUniforms();
                this.props.getAllSkills();
                this.props.getLocation();
                this.props.checkCardValid();
                this.props.getInternalEmployeeList();
                this.setState({ apiCalled: true });
            }

            if (!isLoaded && listOfSkills.length && skillId === null) {
                const newSkillId = Number(listOfSkills[0].id);
                this.handleChangePosition(newSkillId);
                this.setState({ isLoaded: true, skillId: newSkillId });
            }
            if (newChargeRate) {
                if (this.props.chargeRate !== newChargeRate)
                    this.setState({ chargeRate: newChargeRate });
            }
        }

        if (locationId === 0 && defaultLocationId) {
            this.setState({ locationId: defaultLocationId });
        }
    }

    getNext15Minutes(momentObject) {
        if (momentObject === null) {
            momentObject = moment().clone();
        }

        let remainingMinutes = 15 - (momentObject.minute() % 15);

        if (remainingMinutes < 5) {
            remainingMinutes += 15;
        }

        return momentObject.add(remainingMinutes, "minutes").second(0);
    }

    addSchedule() {
        let nextMoment;
        const { schedules } = this.state;
        const scheduleLength = schedules.length;

        if (scheduleLength === 0) {
            nextMoment = this.getNext15Minutes(null);
        } else {
            nextMoment = schedules[scheduleLength - 1].end
                .clone()
                .add(1, "hours");
        }

        schedules.push({
            start: nextMoment,
            end: nextMoment.clone().add(1, "hours"),
            original_index: null,
            editable: true,
            breakTime: 0,
        });

        this.setState({ schedules });
    }

    removeSchedule(scheduleIndex) {
        const { schedules } = this.state;
        schedules.splice(scheduleIndex, 1);
        this.setState({ schedules });
    }

    handleDateChange(dateTime, scheduleIndex, objectKey) {
        const { schedules } = this.state;
        schedules[scheduleIndex][objectKey] = dateTime;

        this.setState({ schedules });
    }

    handleChangeBreakTime = (value, scheduleIndex) => {
        const { schedules } = this.state;
        schedules[scheduleIndex].breakTime = value;
        this.setState({ schedules });
    };

    // handleChangeBreakTime = (value, scheduleIndex) => {
    //     const { schedules } = this.state;
    //     schedules[scheduleIndex].breakTime = value;
    //     this.setState({ schedules });
    // }

    handlePayrateChange(payRate) {
        // changePrivateJob;
        if (payRate !== this.state.payRate && !isNaN(payRate)) {
            if (payRate < 0) {
                payRate = MyGig.payrate.MINIMUM_LIMIT;
            }
            this.updateChargeRate(payRate);
        }
    }

    updatePayRate(payRate) {
        this.setState({ payRate, chargeRate: -1 });
        this.debounceUpdateChargeRate(payRate);
    }

    updateChargeRate(payRate) {
        const {
            chargeRate: oldChargeRate,
            skillId,
            minPayRate,
            formValidation,
            privateJob,
        } = this.state;

        const newState = {
            payRate,
            chargeRate: oldChargeRate,
            formValidation,
        };

        if (Number(payRate) >= minPayRate && formValidation.payRate) {
            const { payRate, ...newFormValidation } = formValidation;
            newState.formValidation = newFormValidation;
        }

        if (Number(payRate) === MyGig.PAY_RATE_PRIVATE) {
            newState.chargeRate = MyGig.CHARGE_RATE_PRIVATE;
        } else {
            const skill = this.getPayRateAndChargeRateFromSkill(
                skillId,
                privateJob,
            );

            if (skill && Number(payRate) === skill.newPayRate)
                newState.chargeRate = skill.newChargeRate;
            else {
                newState.chargeRate = this.props.chargeRate;
                this.props.getChargeRateByPayRate(Number(payRate));
            }
        }
        this.setState(newState);
        // setTimeout(() => {
        //     this.setState({
        //         disabledInputPayrate: false
        //     })
        // }, 500);
    }

    setUniformStyle(uniformId, styleId) {
        const { uniformIds } = this.state;
        uniformIds[styleId] = Number(uniformId);

        this.setState({ uniformIds });
    }

    getUniformStyle(styleId) {
        const { uniformIds } = this.state;

        return uniformIds[styleId];
    }

    getScheduleUnix() {
        const { schedules } = this.state;

        return schedules.map((schedule) => ({
            start: schedule.start.unix(),
            end: schedule.end.unix(),
            original_index: schedule.original_index,
            break_time: Number.parseInt(schedule.breakTime),
        }));
    }

    checkSchedulesOverlapped() {
        let overlapped = false;
        const { schedules } = this.state;

        schedules.map((schedule, index) =>
            schedules.map((scheduleToCompare, indexToCompare) => {
                if (index !== indexToCompare) {
                    if (
                        schedule.start.unix() > scheduleToCompare.end.unix() ||
                        scheduleToCompare.start.unix() > schedule.end.unix()
                    ) {
                        // intervals are valid
                    } else {
                        overlapped = true;
                    }
                }

                return true;
            }),
        );

        return overlapped;
    }

    formValidation(creatingProcess) {
        const formValidation = {};
        const {
            payRateValidatedFailed,
            schedules,
            payRate,
            minPayRate,
            privateJob,
            skillId,
        } = this.state;

        const description = this.descriptionInput.current.value;
        if (payRateValidatedFailed) {
            return false;
        }

        if (
            payRate < minPayRate &&
            !privateJob &&
            skillId !==
                MyGig.TRAINING_SKILL_ID /*Training can set payrate at 0*/
        ) {
            formValidation.payRate = {
                key: "createGig.validate.payRate.minValue",
                params: {
                    minimumPayrate: minPayRate.toFixed(2),
                },
            };
        }

        if (description.length === 0) {
            formValidation.description = {
                key: "createGig.validate.description.required",
            };
        }

        if (
            creatingProcess === CREATING_PROCESS.JOB &&
            description.length < VALIDATION.MIN_LENGTH.DESCRIPTION
        ) {
            formValidation.description = {
                key: "createGig.validate.description.minLength",
                params: {
                    number: VALIDATION.MIN_LENGTH.DESCRIPTION,
                },
            };
        }

        if (this.checkSchedulesOverlapped() === true) {
            formValidation.schedules = {
                key: "createGig.validate.schedules.overlapped",
            };
        }

        schedules.map((schedule) => {
            if (
                creatingProcess === CREATING_PROCESS.JOB &&
                (schedule.start.isBefore(moment()) ||
                    schedule.end.isBefore(moment()))
            ) {
                return (formValidation.schedules = {
                    key: "createGig.validate.schedules.inPast",
                });
            }

            if (
                creatingProcess === CREATING_PROCESS.TIMECARD &&
                (schedule.start.isAfter(moment()) ||
                    schedule.end.isAfter(moment()))
            ) {
                return (formValidation.schedules = {
                    key: "createTimecard.validate.future",
                });
            }

            if (
                (schedule.end.diff(schedule.start, "second") + 1) / 60 <
                AppConfig.jobMinDuration.minutes
            ) {
                return (formValidation.schedules = {
                    key: "createGig.validate.schedules.minDuration",
                    params: {
                        hour: AppConfig.jobMinDuration.hours,
                    },
                });
            }

            if (
                schedule.end.diff(schedule.start, "minutes") >
                AppConfig.jobMaxDuration.minutes
            ) {
                return (formValidation.schedules = {
                    key: "createGig.validate.schedules.maxDuration",
                    params: {
                        hour: AppConfig.jobMaxDuration.hours,
                    },
                });
            }

            if (
                (schedule.end.diff(schedule.start, "second") + 1) / 60 -
                    schedule.breakTime <
                VALIDATION.MIN_LENGTH.SCHEDULE_TIME
            ) {
                return (formValidation.schedules = {
                    key: "createGig.validate.schedules.breakTime",
                    params: {
                        min: VALIDATION.MIN_LENGTH.SCHEDULE_TIME,
                    },
                });
            }

            return true;
        });

        this.setState({ formValidation });

        return Object.keys(formValidation).length === 0;
    }

    getUniformNameAndImage(uniformIds, listOfUniforms) {
        let uniformName = "";
        let uniformImage = "";

        const listUniformFiltered = listOfUniforms.filter((uniform) => {
            return uniformIds.some((item) => item === uniform.id);
        });

        listUniformFiltered.forEach((uniform) => {
            uniformName += ` ${uniform.name},`;
            uniformImage += `${uniform.slug},`;
        });

        uniformName = uniformName.replace(/^\s|\,$/g, "");
        uniformImage = uniformImage.replace(/\,$/g, "");
        return { uniformName, uniformImage };
    }

    saveShiftAndPosition() {
        const {
            locationId,
            skillId,
            payRate,
            chargeRate,
            specialRequirements,
            totalAssignment,
            totalRequested,
            otherUniform,
            uniformIds,
            privateJob,
            over18Only,
        } = this.state;

        const { listOfLocations, listOfSkills, listOfUniforms } = this.props;

        const description = this.descriptionInput.current.value;

        const location = !locationId
            ? listOfLocations[0]
            : listOfLocations.find(
                  (location) => location.id === Number(locationId),
              );
        const skill = listOfSkills.find((skill) => skill.id === skillId);

        const { uniformName, uniformImage } = this.getUniformNameAndImage(
            Object.values(uniformIds),
            listOfUniforms,
        );
        if (chargeRate >= 0) {
            if (this.formValidation(CREATING_PROCESS.JOB)) {
                this.setState({
                    hasError: false,
                    onSavingGig: true,
                });

                this.props.createNewShift(
                    {
                        location_id: location.id,
                        location_name: location.name,
                        location_postcode: location.postcode,
                        location_street: location.street,
                        position_description: description,
                        description,
                        skill_id: skillId,
                        skill_name: skill.name,
                        skill_type: skill.type,
                        chargeout_rate: chargeRate,
                        payrate: payRate,
                        charge_rate: chargeRate,
                        special_requirements: specialRequirements,
                        schedule: this.getScheduleUnix(),
                        total_assignments: totalAssignment,
                        requested_number: totalRequested,
                        other_uniform: otherUniform,
                        uniform_ids: Object.values(uniformIds),
                        uniform_name: uniformName,
                        uniform_image: uniformImage,
                        is_privated: privateJob ? 1 : 0,
                        is_over18: over18Only,
                    },
                    this.props.history,
                    () => this.showSuccessAlert("createGig.success"),
                    () => this.showErrorAlert("createGig.failure"),
                );
            } else {
                this.setState({ hasError: true });
            }
        }
    }

  saveTimecard = (listSeekerIds, breakTime) => {
    const {
      locationId,
      description,
      skillId,
      payRate,
      specialRequirements,
      totalAssignment,
      otherUniform,
      uniformIds,
      over18Only,
      chargeRate,
      totalRequested,
    } = this.state;

        if (this.formValidation(CREATING_PROCESS.TIMECARD)) {
            this.setState({
                hasError: false,
                onSavingGig: true,
            });

      this.props.createTimecard(
        {
          breakTime,
          locationId,
          description,
          skillId,
          payrate: payRate,
          chargeRate: chargeRate,
          specialRequirements,
          schedule: this.getScheduleUnix(),
          assignmentsTotal: totalAssignment,
          other_uniform: otherUniform,
          uniform_ids: Object.values(uniformIds),
          is_over18: over18Only,
          totalRequested,
        },
        listSeekerIds,
        () => this.showSuccessAlert("createTimecard.success"),
        (message = "createTimecard.failure") => this.showErrorAlert(message)
      );
    } else {
      this.setState({ hasError: true });
    }
  };

    handleRemoveSchedule = (index) => {
        let { schedules } = this.state;
        schedules.splice(index, 1);
        this.setState({
            shedules: schedules,
        });
    };

    changePrivateJob = () => {
        const { privateJob, payRate, chargeRate, skillId } = this.state;

        const newState = {
            privateJob: !privateJob,
            payRate,
            chargeRate,
        };

        if (newState.privateJob) {
            newState.payRate = MyGig.PAY_RATE_PRIVATE;
            newState.chargeRate = MyGig.CHARGE_RATE_PRIVATE;
        } else {
            const skill = this.getPayRateAndChargeRateFromSkill(
                skillId,
                newState.privateJob,
            );
            const { newPayRate, newChargeRate } =
                this.getNewPayAndChargeRateBySkill(skill);
            newState.payRate = newPayRate;
            newState.chargeRate = newChargeRate;
        }

        return this.setState(newState);
    };

    changeOver18Only = () => {
        const { over18Only: currentStatus } = this.state;

        return this.setState({ over18Only: !currentStatus });
    };

    getNewPayAndChargeRateBySkill(
        skill,
        defaultPayRate = MyGig.payrate.MINIMUM_LIMIT,
    ) {
        const { payRate, chargeRate } = this.state;
        const newState = { payRate, chargeRate };
        if (skill) {
            newState.payRate = skill.newPayRate;
            newState.chargeRate = skill.newChargeRate;
        } else {
            newState.payRate = defaultPayRate;
            newState.chargeRate = this.props.chargeRate;
            this.props.getChargeRateByPayRate(newState.payRate);
        }

        return {
            newPayRate: newState.payRate,
            newChargeRate: newState.chargeRate,
        };
    }

    showMissingAlert(type) {
        const { missingAlerts } = this.state;

        switch (type) {
            case MISSING_DATA.LOCATION:
                missingAlerts.messageKey = "createGig.missing.location";
                missingAlerts.redirect = RoutePaths.LOCATIONS;
                break;

            case MISSING_DATA.CARD:
                missingAlerts.messageKey = "createGig.missing.card";
                missingAlerts.redirect = RoutePaths.BILLING.INVOICING;
                break;

            default:
                return;
        }

        missingAlerts.show = true;

        this.setState({ missingAlerts });
    }

    hideMissingAlert() {
        const { missingAlerts } = this.state;

        missingAlerts.show = false;

        this.setState({ missingAlerts });
        this.closeModal();
    }

    showSuccessAlert(messageKey) {
        this.setState({ onSavingGig: false });
        this.props.onSuccess(messageKey);
        this.closeModal();
    }

    showErrorAlert(messageKey) {
        this.setState({ onSavingGig: false });
        this.props.onError(messageKey);
    }

    closeModal() {
        this.setState(
            {
                apiCalled: false,
                formValidation: {},
                description: "",
                schedules: [],
                specialRequirements: "",
                otherUniform: "",
                locationId: 0,
                isLoaded: false,
            },
            () => this.addSchedule(),
        );
        this.props.closeCreateJobModal();
        this.props.searchSeekersResetResults();
    }

    uniformsByStyleId(styleId) {
        const { listOfUniforms } = this.props;

        return listOfUniforms.filter((uniform) => uniform.style_id === styleId);
    }

    getChargeOutRate() {
        const { chargeoutRate } = this.state;
        return chargeoutRate ? Number(chargeoutRate) : 0;
    }

    changeBlackTie = async (event) => {
        await this.setState({
            blackTie: event.target.checked,
        });
        this.state.blackTie === true
            ? this.setUniformStyle(
                  UNIFORM_IDS.BLACK_TIE,
                  UNIFORM_TYPES.BLACK_TIE,
              )
            : this.setUniformStyle(
                  UNIFORM_IDS.NOT_REQUIRED,
                  UNIFORM_TYPES.BLACK_TIE,
              );
    };

    changeHighVisVest = async (event) => {
        await this.setState({
            highVisVest: event.target.checked,
        });
        this.state.highVisVest === true
            ? this.setUniformStyle(
                  UNIFORM_IDS.HIGH_VIS_VEST,
                  UNIFORM_TYPES.HIGH_VIS_VEST,
              )
            : this.setUniformStyle(
                  UNIFORM_IDS.NOT_REQUIRED,
                  UNIFORM_TYPES.HIGH_VIS_VEST,
              );
    };

    changeHighVisCap = async (event) => {
        await this.setState({
            highVisCap: event.target.checked,
        });
        this.state.highVisCap === true
            ? this.setUniformStyle(
                  UNIFORM_IDS.HIGH_VIS_CAP,
                  UNIFORM_TYPES.HIGH_VIS_CAP,
              )
            : this.setUniformStyle(
                  UNIFORM_IDS.NOT_REQUIRED,
                  UNIFORM_TYPES.HIGH_VIS_CAP,
              );
    };

    changeGloves = async (event) => {
        await this.setState({
            gloves: event.target.checked,
        });
        this.state.gloves === true
            ? this.setUniformStyle(UNIFORM_IDS.GLOVES, UNIFORM_TYPES.GLOVES)
            : this.setUniformStyle(
                  UNIFORM_IDS.NOT_REQUIRED,
                  UNIFORM_TYPES.GLOVES,
              );
    };
    changeBlazer = async (event) => {
        await this.setState({
            blazer: event.target.checked,
        });
        this.state.blazer === true
            ? this.setUniformStyle(UNIFORM_IDS.BLAZER, UNIFORM_TYPES.BLAZER)
            : this.setUniformStyle(
                  UNIFORM_IDS.NOT_REQUIRED,
                  UNIFORM_TYPES.BLAZER,
              );
    };

    toggleExtraUniform = () => {
        const { openExtraUniform } = this.state;
        this.setState({ openExtraUniform: !openExtraUniform });
    };

    handleChangeChargeRate = (rate) => {
        this.props.changeChargeRate(rate);
    };

    handleOpenPostedInternalPopup(e) {
        this.setState({
            postedInternalPopupTarget: e.target,
            openPostedInternalPopup: true,
        });
    }

    handleClosePopup(e) {
        this.setState({
            openPostedInternalPopup: false,
        });
    }

    getPayRateAndChargeRateFromSkill(skillId, isPrivate) {
        const { payRate: currentPayRate, chargeRate: currentChargeRate } =
            this.state;

        const result = {
            newPayRate: Number(currentPayRate),
            newChargeRate: Number(currentChargeRate),
        };

        if (isPrivate) {
            result.newPayRate = MyGig.PAY_RATE_PRIVATE;
            result.newChargeRate = MyGig.CHARGE_RATE_PRIVATE;
        } else {
            const { listOfSkills } = this.props;

            const skillById = listOfSkills.find((item) => {
                return item.pay_rate && item.charge_rate && item.id === skillId;
            });

            if (!skillById) return skillById; // return null

            result.newPayRate = Number(skillById.pay_rate);
            result.newChargeRate = Number(skillById.charge_rate);
        }
        return result;
    }

  handleChangePosition(skillId) {
    const { privateJob } = this.state;

    const newState = {
      skillId,
      payRate: null,
      chargeRate: null,
    };
    const skill = this.getPayRateAndChargeRateFromSkill(skillId, privateJob);

    const { newPayRate, newChargeRate } =
      this.getNewPayAndChargeRateBySkill(skill);

    newState.payRate = newPayRate;
    newState.chargeRate = newChargeRate;
    // if (this.state.isLoaded)
    newState.minPayRate =
      Number(skillId) === MyGig.WAITING_STAFF_UNDER_18_ID
        ? MyGig.payrate.WAITING_STAFF_MINIMUM_LIMIT
        : newState.payRate;
    this.setState(newState);
  }

    render() {
        const {
            missingAlerts,
            skillId,
            totalAssignment,
            totalRequested,
            otherUniform,
            specialRequirements,
            locationId,
            privateJob,
            over18Only,
            blackTie,
            highVisVest,
            highVisCap,
            gloves,
            blazer,
            openExtraUniform,
            payRate,
            chargeRate,
            hasError,
            formValidation,
            minPayRate,
            disabledInputPayrate,
        } = this.state;
        const {
            showCreateJob,
            isMissingLocation,
            isMissingCard,
            listOfLocations,
            listOfSkills,
            listInternalEmployees,
        } = this.props;

        const isOpenDrawer =
            showCreateJob && !isMissingLocation && !isMissingCard;

        return (
            <div
                className="post-gig-outer-wrapper"
                style={{
                    display: showCreateJob ? "block" : "none",
                }}
            >
                <SwipeableDrawer
                    anchor={"right"}
                    open={isOpenDrawer}
                    onOpen={() => false}
                    onClose={() => this.closeModal()}
                    className="MuiDrawer-modal-post-gig-container"
                >
                    <div className="drawer-wrapper">
                        <Spinner
                            spinnerWidth={70}
                            show={this.state.onSavingGig}
                        />

                        <div className="post-gig-wrapper">
                            <h1 className="post-gig-title  font-normal">
                                <IntlMessages id="createGig.title" />
                                <i
                                    className="zmdi zmdi-close black font-2x pull-right on-hover"
                                    onClick={() => this.closeModal()}
                                />
                            </h1>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-4 d-block">
                  <FormGroup className="mb-3">
                    <Label for="Job-Location">
                      <IntlMessages id="createGig.chooseLocation" />
                    </Label>
                    <Input
                      type="select"
                      id="Job-Location"
                      value={locationId}
                      onChange={(event) =>
                        this.setState({
                          locationId: event.target.value,
                          minPayRate: MyGig.payrate.WAITING_STAFF_MINIMUM_LIMIT,
                        })
                      }
                    >
                      <ListOfLocations locations={listOfLocations} />
                    </Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-4">
                  <FormGroup className="">
                    <Label for="Job-Skill">
                      <IntlMessages id="createGig.positionNeeded" />
                    </Label>
                    <Input
                      type="select"
                      id="Job-Skill"
                      value={skillId}
                      onChange={(event) =>
                        this.handleChangePosition(Number(event.target.value))
                      }
                    >
                      {listOfSkills.map((skill) => (
                        <option key={`skill-${skill.id}`} value={skill.id}>
                          {skill.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div
                  className="col-sm-12 col-md-12 col-xl-4"
                  style={{ marginTop: "-2rem" }}
                >
                  <Label for="Job-Total-Assignments">
                    <IntlMessages id="createGig.numberOfSeekers" />
                  </Label>
                  <div className="row">
                    <FormGroup className="col-sm-12 col-md-12 col-xl-6">
                      <Label for="Job-Total-Assignments">
                        <IntlMessages id="createGig.postedSeekers" />
                      </Label>
                      <Input
                        type="select"
                        id="Job-Total-Assignments"
                        value={totalAssignment}
                        onChange={(event) =>
                          this.setState({
                            totalAssignment: Number(event.target.value),
                            totalRequested: Number(event.target.value),
                          })
                        }
                      >
                        <NumberOfSeeker />
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-sm-12 col-md-12 col-xl-6">
                      <Label for="Job-Total-Requested">
                        <IntlMessages id="createGig.requestedSeekers" />
                      </Label>
                      <Input
                        type="number"
                        id="Job-Total-Requested"
                        value={totalRequested}
                        min={1}
                        onChange={(event) =>
                          this.setState({
                            totalRequested: Number(event.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <Label>
                <IntlMessages id="createGig.uniform" />
              </Label>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-4">
                  <FormGroup className="mb-3">
                    <Input
                      type="select"
                      id="Job-Uniform-Shirt"
                      value={this.getUniformStyle(UNIFORM_TYPES.SHIRT)}
                      onChange={(e) =>
                        this.setUniformStyle(
                          e.target.value,
                          UNIFORM_TYPES.SHIRT
                        )
                      }
                    >
                      <FormattedMessage id="createGig.placeholder.uniform.shirt">
                        {(uniformPlaceHolder) => (
                          <option key={`uniform-1-default`}>
                            {uniformPlaceHolder}
                          </option>
                        )}
                      </FormattedMessage>

                                            {this.uniformsByStyleId(
                                                UNIFORM_TYPES.SHIRT,
                                            ).map((uniform) => (
                                                <option
                                                    key={`uniform-${uniform.style_id}-${uniform.id}`}
                                                    value={uniform.id}
                                                >
                                                    {uniform.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-4">
                                    <FormGroup className="">
                                        <Input
                                            type="select"
                                            id="Job-Uniform-Skirt"
                                            value={this.getUniformStyle(
                                                UNIFORM_TYPES.JEANS,
                                            )}
                                            onChange={(e) =>
                                                this.setUniformStyle(
                                                    e.target.value,
                                                    UNIFORM_TYPES.JEANS,
                                                )
                                            }
                                        >
                                            <FormattedMessage id="createGig.placeholder.uniform.jeans">
                                                {(uniformPlaceHolder) => (
                                                    <option
                                                        key={`uniform-2-default`}
                                                    >
                                                        {uniformPlaceHolder}
                                                    </option>
                                                )}
                                            </FormattedMessage>

                                            {this.uniformsByStyleId(
                                                UNIFORM_TYPES.JEANS,
                                            ).map((uniform) => (
                                                <option
                                                    key={`uniform-${uniform.style_id}-${uniform.id}`}
                                                    value={uniform.id}
                                                >
                                                    {uniform.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-4">
                                    <FormGroup className="">
                                        <Input
                                            type="select"
                                            id="Job-Uniform-Shoes"
                                            value={this.getUniformStyle(
                                                UNIFORM_TYPES.SHOES,
                                            )}
                                            onChange={(e) =>
                                                this.setUniformStyle(
                                                    e.target.value,
                                                    UNIFORM_TYPES.SHOES,
                                                )
                                            }
                                        >
                                            <FormattedMessage id="createGig.placeholder.uniform.shoes">
                                                {(uniformPlaceHolder) => (
                                                    <option
                                                        key={`uniform-3-default`}
                                                    >
                                                        {uniformPlaceHolder}
                                                    </option>
                                                )}
                                            </FormattedMessage>

                                            {this.uniformsByStyleId(
                                                UNIFORM_TYPES.SHOES,
                                            ).map((uniform) => (
                                                <option
                                                    key={`uniform-${uniform.style_id}-${uniform.id}`}
                                                    value={uniform.id}
                                                >
                                                    {uniform.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <Label>
                                <IntlMessages id="createGig.uniformAddition" />
                            </Label>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-4">
                                    <div className="">
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                marginBottom: "20px",
                                            }}
                                            id="choose-uniform"
                                            className="d-flex align-items-center justify-content-between"
                                            onClick={this.toggleExtraUniform}
                                        >
                                            <span>Choose</span>
                                            {openExtraUniform ? (
                                                <Close />
                                            ) : (
                                                <KeyboardArrowDown />
                                            )}
                                        </div>
                                        {openExtraUniform && (
                                            <div className="pl-3">
                                                <FormGroup className="mb-3 d-flex justify-content-between">
                                                    <label
                                                        className={`checkbox-custom-label w-100 d-flex justify-content-between ${
                                                            gloves
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-add-gloves"
                                                    >
                                                        <IntlMessages id="createGig.addGloves" />
                                                    </label>

                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-add-gloves"
                                                        checked={!!gloves}
                                                        onChange={
                                                            this.changeGloves
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3 d-flex justify-content-between">
                                                    <label
                                                        className={`checkbox-custom-label w-100 d-flex justify-content-between ${
                                                            blackTie
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-add-black-tie"
                                                    >
                                                        <IntlMessages id="createGig.addBlackTie" />
                                                    </label>

                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-add-black-tie"
                                                        checked={!!blackTie}
                                                        onChange={
                                                            this.changeBlackTie
                                                        }
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mb-3 d-flex justify-content-between">
                                                    <label
                                                        className={`checkbox-custom-label w-100 d-flex justify-content-between ${
                                                            highVisVest
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-add-high-vis-vest"
                                                    >
                                                        <IntlMessages id="createGig.addHighVisVest" />
                                                    </label>

                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-add-high-vis-vest"
                                                        checked={!!highVisVest}
                                                        onChange={
                                                            this
                                                                .changeHighVisVest
                                                        }
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mb-3 d-flex justify-content-between">
                                                    <label
                                                        className={`checkbox-custom-label w-100 d-flex justify-content-between ${
                                                            highVisCap
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-add-high-vis-cap"
                                                    >
                                                        <IntlMessages id="createGig.addHighVisCap" />
                                                    </label>

                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-add-high-vis-cap"
                                                        checked={!!highVisCap}
                                                        onChange={
                                                            this
                                                                .changeHighVisCap
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3 d-flex justify-content-between">
                                                    <label
                                                        className={`checkbox-custom-label w-100 d-flex justify-content-between ${
                                                            blazer
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-add-blazer"
                                                    >
                                                        <IntlMessages id="createGig.addBlazer" />
                                                    </label>

                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-add-blazer"
                                                        checked={!!blazer}
                                                        onChange={
                                                            this.changeBlazer
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-8">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-xl-7">
                                            <FormGroup className="">
                                                <FormattedMessage id="createGig.placeholder.uniform.other">
                                                    {(uniformOtherText) => (
                                                        <Input
                                                            type="text"
                                                            id="Job-Uniform-Other"
                                                            placeholder={
                                                                uniformOtherText
                                                            }
                                                            value={otherUniform}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    otherUniform:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormattedMessage>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xl-5 d-flex align-items-center">
                                            <FormGroup className="d-flex form-group align-items-center mt-1">
                                                <label
                                                    className={`checkbox-custom-over-18  ${
                                                        over18Only
                                                            ? "checked"
                                                            : ""
                                                    }`}
                                                    htmlFor="checkbox-over-18-only"
                                                ></label>
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-custom"
                                                    id="checkbox-over-18-only"
                                                    checked={over18Only}
                                                    onChange={
                                                        this.changeOver18Only
                                                    }
                                                />
                                                <p style={{ marginBottom: 0 }}>
                                                    Over 18 Only
                                                </p>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="Job-Description">
                                            <IntlMessages id="createGig.jobDescription" />
                                        </Label>
                                        <div className="background-textarea">
                                            <textarea
                                                className="form-control"
                                                ref={this.descriptionInput}
                                                placeholder={
                                                    MyGig.PLACEHOLDER_JOB_DESCRIPTION
                                                }
                                            />
                                        </div>
                                        {this.state.hasError &&
                                            this.state.formValidation
                                                .description && (
                                                <FormHelperText
                                                    className="field-error"
                                                    id="pwd-error-text"
                                                >
                                                    <FormattedMessage
                                                        id={
                                                            this.state
                                                                .formValidation
                                                                .description.key
                                                        }
                                                        values={
                                                            this.state
                                                                .formValidation
                                                                .description
                                                                .params
                                                        }
                                                    />
                                                </FormHelperText>
                                            )}
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <FormGroup>
                                        <Label for="Job-Special-Requirements">
                                            <IntlMessages id="createGig.specialRequirements" />
                                        </Label>
                                        <div className="background-textarea">
                                            <FormattedMessage id="createGig.placeholder.specialRequirements">
                                                {(text) => (
                                                    <Input
                                                        type="textarea"
                                                        id="Job-Special-Requirements"
                                                        placeholder={text}
                                                        value={
                                                            specialRequirements
                                                        }
                                                        onChange={(event) =>
                                                            this.setState({
                                                                specialRequirements:
                                                                    event.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <h3 className="gig-shift">
                                <IntlMessages id="createGig.gigShifts" />
                            </h3>

                            <div
                                className={
                                    "row ml-0 mr-0 mt-20 mb-20 schedule-info"
                                }
                            >
                                {this.state.schedules.map((schedule, index) => (
                                    <div className={"col-md-6"} key={index}>
                                        <div
                                            className={`row time-info ${
                                                index % 2 == 0 ? "mr-0" : "ml-0"
                                            }`}
                                        >
                                            <div>{`Shift ${index + 1}`}</div>
                                            <div>{`${schedule.start.format(
                                                "D MMM",
                                            )} - ${schedule.start
                                                .format("H:mm ")
                                                .replace(
                                                    " ",
                                                    "",
                                                )} to ${schedule.end
                                                .format("H:mm ")
                                                .replace(" ", "")}`}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="clearfix mb-20 row">
                                <div className="col-sm-12 col-md-12 col-xl-9 pl-0">
                                    <div
                                        className="row"
                                        style={{ paddingLeft: "29px" }}
                                    >
                                        <div className="col-sm-12 col-md-12 col-xl">
                                            <Label for="Shift-Start">
                                                <IntlMessages id="createGig.schedule.start" />
                                            </Label>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xl">
                                            <Label for="Shift-End">
                                                <IntlMessages id="createGig.schedule.startTime" />
                                            </Label>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xl">
                                            <Label for="Shift-End">
                                                <IntlMessages id="createGig.schedule.end" />
                                            </Label>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xl">
                                            <Label for="Shift-End">
                                                <IntlMessages id="createGig.schedule.endTime" />
                                            </Label>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xl">
                                            <Label for="Break-Out">
                                                <IntlMessages id="createGig.schedule.breakTime" />
                                            </Label>
                                        </div>
                                    </div>
                                    <Schedule
                                        handleRemoveSchedule={(index) =>
                                            this.handleRemoveSchedule(index)
                                        }
                                        showPastShift
                                        schedules={this.state.schedules}
                                        disablePast={false}
                                        removeSchedule={this.removeSchedule}
                                        handleDateChange={this.handleDateChange}
                                        handleChangeBreakTime={
                                            this.handleChangeBreakTime
                                        }
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-3 ">
                                    <div className="row-6">
                                        <Label></Label>
                                    </div>
                                    <div className="row-6">
                                        {this.state.hasError && (
                                            <FormHelperText
                                                className="field-error"
                                                id="pwd-error-text"
                                            >
                                                {this.state.formValidation
                                                    .schedules && (
                                                    <FormattedMessage
                                                        id={
                                                            this.state
                                                                .formValidation
                                                                .schedules.key
                                                        }
                                                        values={
                                                            this.state
                                                                .formValidation
                                                                .schedules
                                                                .params
                                                        }
                                                    />
                                                )}
                                            </FormHelperText>
                                        )}
                                        {/* <Tooltip
                                            title={
                                                <FormattedMessage id="createGig.button.addShift" />
                                            }
                                            placement="bottom"
                                        > */}
                                        <Button
                                            className="gig-button btn-add-shift black bigger-font pull-right text-capitalize"
                                            onClick={() => this.addSchedule()}
                                            style={{
                                                height: "fit-content",
                                                padding: "3px 0 3px 10px",
                                            }}
                                        >
                                            <IntlMessages id="createGig.addShift" />
                                            <i className="material-icons">
                                                keyboard_arrow_right
                                            </i>
                                        </Button>
                                        {/* </Tooltip> */}
                                    </div>
                                </div>
                            </div>
                            {/* <hr /> */}
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xl-6">
                                    <PayChargeRate
                                        selectedSkill={Number(skillId)}
                                        listOfSkills={listOfSkills}
                                        chargeRate={chargeRate}
                                        payRate={payRate}
                                        handleChangeChargeRate={(value) =>
                                            this.handleChangeChargeRate(value)
                                        }
                                        handleChangePayRate={(newPayRate) => {
                                            //this.setState({ disabledInputPayrate: true })

                                            this.updatePayRate(newPayRate);
                                        }}
                                        hasError={
                                            hasError && formValidation.payRate
                                        }
                                        minPayRate={minPayRate}
                                        disabled={disabledInputPayrate}
                                        onChange={(
                                            payRate,
                                            validatedFail,
                                            chargeRate,
                                        ) => {
                                            this.setState({
                                                payRate,
                                                payRateValidatedFailed:
                                                    validatedFail,
                                                chargeoutRate: chargeRate,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-xl-6"></div>
                            </div>

                            <div className="row div-all-job ">
                                <div className="row div-job">
                                    <div className="col-sm-12 col-md-12 col-xl-4 my-auto">
                                        <FormGroup className="d-flex form-group my-auto flex-baseline">
                                            <label
                                                className={`checkbox-custom-internal  ${
                                                    !privateJob ? "checked" : ""
                                                }`}
                                                htmlFor="checkbox-private-job"
                                            ></label>
                                            <input
                                                type="checkbox"
                                                className="checkbox-custom"
                                                id="checkbox-private-job"
                                                checked={!privateJob}
                                                onChange={this.changePrivateJob}
                                            />
                                            <p>GIG posted job</p>
                                        </FormGroup>
                                    </div>
                                    {listInternalEmployees ? (
                                        <Fragment>
                                            <div className="col-sm-12 col-md-12 col-xl-4 my-auto">
                                                <FormGroup className="d-flex form-group my-auto flex-baseline">
                                                    <label
                                                        className={`checkbox-custom-internal  ${
                                                            privateJob
                                                                ? "checked"
                                                                : ""
                                                        }`}
                                                        htmlFor="checkbox-private-job"
                                                    ></label>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        id="checkbox-private-job"
                                                        checked={privateJob}
                                                        onChange={
                                                            this
                                                                .changePrivateJob
                                                        }
                                                    />
                                                    <p
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Internal posted job
                                                    </p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-xl-1 my-auto flex-baseline">
                                                <div className="d-flex form-group my-auto">
                                                    <i
                                                        className="material-icons"
                                                        onClick={(e) =>
                                                            this.handleOpenPostedInternalPopup(
                                                                e,
                                                            )
                                                        }
                                                    >
                                                        info_outline
                                                    </i>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="col-sm-12 col-md-12 col-xl-4 my-auto">
                                            <FormGroup className="d-flex form-group my-auto flex-baseline"></FormGroup>
                                        </div>
                                    )}

                                    <div className="col-sm-12 col-md-12 col-xl-3 my-auto">
                                        <div className="col-xl-11 col-md-12">
                                            <div className="pull-right text-center">
                                                <Button
                                                    readonly={
                                                        chargeRate < 0
                                                            ? true
                                                            : false
                                                    }
                                                    fullWidth
                                                    className={`gig-button bigger-font pull-right black ${
                                                        chargeRate < 0
                                                            ? "job-disabled"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.saveShiftAndPosition()
                                                    }
                                                >
                                                    <IntlMessages id="createGig.button.saveGig" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {oauth.checkCurrentIsOmniStaff() && (
                                    <div className="row w-100 div-time-card">
                                        {/* <hr /> */}
                                        <Timecard
                                            onValidation={() =>
                                                this.formValidation(
                                                    CREATING_PROCESS.TIMECARD,
                                                )
                                            }
                                            onSaving={this.saveTimecard}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>

                <SweetAlert
                    custom
                    customIcon={Warning}
                    btnSize="sm"
                    className="missing-data-alert"
                    show={missingAlerts.show}
                    cancelBtnText={<IntlMessages id="button.cancel" />}
                    showCancel
                    title=""
                    onConfirm={() => {
                        this.hideMissingAlert();
                        this.props.history.push(missingAlerts.redirect);
                    }}
                    onCancel={() => this.hideMissingAlert()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <FormattedMessage id={missingAlerts.messageKey} />
                </SweetAlert>

                <PostedInternalPopup
                    open={this.state.openPostedInternalPopup}
                    onClose={() => this.handleClosePopup()}
                    anchorPostedInternalModal={
                        this.state.postedInternalPopupTarget
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = ({
    jobApp,
    uniformReducer,
    skillReducer,
    location,
    chargeRateByPayRateReducer,
    shiftReducer,
    cardReducer,
    internalEmployeeReducer,
}) => {
    let listOfUniforms = [];
    let listInternalEmployees = undefined;
    let listOfSkills = [];
    let listOfLocations = [];
    let defaultSkillId = 0;
    let defaultLocationId = 0;
    // let chargeRate;
    let chargeoutRate;
    let shiftId = null;
    let chargeRate = 0;

    if (chargeRateByPayRateReducer.chargeRate) {
        chargeRate = chargeRateByPayRateReducer.chargeRate;
    }

    listOfSkills = skillReducer.skills;

    if (uniformReducer.uniforms && uniformReducer.uniforms) {
        listOfUniforms = uniformReducer.uniforms;
    }

    if (listOfSkills.length > 0) {
        defaultSkillId = listOfSkills[0].id;
    }

    if (location.allLocation && location.allLocation.data) {
        listOfLocations = location.allLocation.data;
        if (listOfLocations.length <= 1 && listOfLocations.length > 0) {
            defaultLocationId = listOfLocations[0].id;
        }
        if (defaultLocationId == 0) {
            defaultLocationId =
                listOfLocations.length > 0 ? listOfLocations[0].id : 0;
        }
    }

    if (
        chargeRateByPayRateReducer.chargeRate &&
        chargeRateByPayRateReducer.chargeRate.data
    ) {
        // chargeRate = chargeRateByPayRateReducer.chargeRate.data;
        chargeoutRate = chargeRate.chargeoutRate;
    }

    if (shiftReducer.newShift && shiftReducer.newShift.data) {
        shiftId = shiftReducer.newShift.data.id;
    }

    // listInternalEmployees:  internalEmployeeReducer.listInternalEmployees,

    if (internalEmployeeReducer.listInternalEmployees) {
        listInternalEmployees =
            internalEmployeeReducer.listInternalEmployees.length > 0;
    }

    return {
        listOfUniforms,
        listOfSkills,
        listOfLocations,
        chargeRate,
        chargeoutRate,
        shiftId,
        defaultSkillId,
        defaultLocationId,
        listInternalEmployees,
        showCreateJob: jobApp.showCreateJob,
        isMissingLocation:
            location.allLocation !== null && listOfLocations.length === 0,
        isMissingCard: cardReducer.isCardValid === false,
    };
};

const mapActionToProps = {
    closeCreateJobModal,
    getUniforms,
    getAllSkills,
    getLocation,
    getChargeRateByPayRate,
    createNewShift,
    createNewPosition,
    createTimecard,
    checkCardValid,
    searchSeekersResetResults,
    changeChargeRate,
    getInternalEmployeeList,
};

export default connect(mapStateToProps, mapActionToProps)(CreateEditJob);


import AppConfig    from 'Constants/AppConfig';
import MyGig from 'Constants/MyGig';
import Pusher from 'pusher-js';

export default class PushNotification {

    withPusherConnection(options) {
        let defaultsOptions = {
            cluster: 'ap1',
            encrypted: true
        };
        defaultsOptions = this.extend(defaultsOptions, options);

        window.pusher = new Pusher(AppConfig.pusherKey, defaultsOptions);

        window.pusher.connection.bind('error', (err) => {
            if (err.error.data.code === MyGig.pusherErrorCode) {
                window.console.log('>>> detected limit error');
            }
        });
    };

    subscribeJobResponseChanel (id) {
        if (typeof window.pusher === 'undefined') {
            throw new Error(MyGig.pusheUndefinedConnection);
        }
        window.jobResponseEventChannel = window.pusher.subscribe(MyGig.channel + id);
        const instance = this;

        instance.id = id;
    };

    subscribeDirectMessage (id) {
        if (typeof window.pusher === 'undefined') {
            throw new Error(MyGig.pusheUndefinedConnection);
        }
        window.directMessage = window.pusher.subscribe(id);
        const instance = this;

        instance.id = id;
    }

    unsubscribeJobResponseChanel (id) {
        if (typeof window.pusher === 'undefined') {
            throw new Error(MyGig.pusheUndefinedConnection);
        }
        window.pusher.unsubscribe(MyGig.channel + id);
    };

    extend(oldOption, newOption ) {
        for (const key in newOption)
            if(newOption.hasOwnProperty(key))
                oldOption[key] = newOption[key];
        return oldOption;
    }
}

import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {
    FormattedMessage,
    injectIntl
} from "react-intl";

import SweetAlert from 'react-bootstrap-sweetalert';
import {
    FormGroup,
    Button
} from 'reactstrap';
import Warning from 'Assets/img/SweetAlert/warning.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl      from '@material-ui/core/FormControl';
import FormHelperText   from "@material-ui/core/FormHelperText";

import IntlMessages from "Util/IntlMessages";

import {
    hideConfirmPasswordForm,
    confirmPassword
} from 'Actions';

class ConfirmPassword extends Component {


    constructor(props) {
        super(props);
        this.state = {
            formElements: {
                inputPassword: ''
            },
            formValidation: {
                inputPassword: '',
                isValidated  : true
            }
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.confirmSuccess === true && typeof nextProps.callbackSuccess === 'function') {
            nextProps.callbackSuccess();
        }
    }

    validatePassword() {
        const {
            formElements,
            formValidation
        } = this.state;

        formValidation.isValidated   = true;
        formValidation.inputPassword = '';

        if (!formElements.inputPassword) {
            formValidation.inputPassword = 'validation.password.notBlank';

        } else if (formElements.inputPassword.length < 6) {
            formValidation.inputPassword = 'validation.password.atLeastSixCharacters';
        }

        formValidation.isValidated = (formValidation.inputPassword === '');

        this.setState({formValidation});

        return formValidation.isValidated;
    }

    handleConfirmPassword() {
        if (this.validatePassword()) {
            this.props.confirmPassword(this.state.formElements.inputPassword);
        }
    }

    cancelConfirmPassword() {
        this.setState({
            formElements: {
                inputPassword: ''
            },
            formValidation: {
                ...this.state.formValidation,
                isValidated: true
            }
        });
        this.props.hideConfirmPasswordForm();
    }

    render() {
        const {
            isShowForm,
            isWrongPassword,
            onLoading,
            intl
        } = this.props;
        const {
            formElements,
            formValidation
        } = this.state;

        return (
            <SweetAlert
            custom
            customIcon={Warning}
                show={isShowForm}
                showCancel={false}
                showConfirm={false}
              
                title={
                    <div className="uppercase font-bold bigger-font">
                        <IntlMessages id="txt.confirm" />
                    </div>
                }
                onConfirm={() => null}
                confirmBtnCssClass={"btn-orange"}
                cancelBtnCssClass={"btn-black"}
            >
                <div className="confirm-password-wrapper col-sm-12">
                    <h2>
                        <IntlMessages id="confirmPassword.summaryText" />
                    </h2>

                    <FormGroup className="position-relative">
                        <FormControl fullWidth>
                            <input
                                ref={(input) => (input && input.focus())}
                                type="password"
                                name="password"
                                className="form-control"
                                disabled={onLoading}
                                placeholder={intl.formatMessage({ id: 'confirmPassword.inputPassword.placeholder' })}
                                value={formElements.inputPassword}
                                onChange={(e) => this.setState({
                                    formElements: {
                                        ...this.state.formElements,
                                        inputPassword: e.target.value
                                    }
                                })}
                            />

                            {!onLoading && !formValidation.isValidated && formValidation.inputPassword && (
                                <FormHelperText className="field-error">
                                    <FormattedMessage id={formValidation.inputPassword} />
                                </FormHelperText>
                            )}

                            {!onLoading && isWrongPassword && (
                                <FormHelperText className="field-error">
                                    <FormattedMessage id="confirmPassword.wrongPassword" />
                                </FormHelperText>
                            )}

                            {onLoading && (
                                <CircularProgress className="progress-warning confirm-spinner" size={28} />
                            )}
                        </FormControl>
                    </FormGroup>

                    <FormGroup>
                        <Button
                            className="mr-10 gig-button btn-orange"
                            disabled={onLoading}
                            onClick={() => this.handleConfirmPassword()}
                        >
                            <IntlMessages id="common.submit" />
                        </Button>

                        <Button
                            className="gig-button btn-black"
                            onClick={() => this.cancelConfirmPassword()}
                        >
                            <IntlMessages id="button.cancel" />
                        </Button>
                    </FormGroup>
                </div>
            </SweetAlert>
        );
    }
}

const mapStateToProps = ({confirmPasswordReducer}) => confirmPasswordReducer;

export default injectIntl(connect(
    mapStateToProps,
    {
        hideConfirmPasswordForm,
        confirmPassword
    }
)(ConfirmPassword));

/**
 * App Reducers
 */
import { combineReducers }    from 'redux';
import settings               from './settings';
import sidebar                from './Sidebar';
import authUser               from './AuthUser';
import jobApp                 from './JobApp';
import uniform                from './Uniform';
import location               from './Location';
import skill                  from './Skill';
import chargeRate             from './ChargeRate';
import chargeRateByPayRate    from './ChargeRateByPayRate';
import shift                  from './Shift';
import position               from './Position';
import remoteRequest          from './RemoteRequest';
import report                 from './Report';
import blockEmployee          from './BlockEmployee';
import user                   from './User';
import omniEmployer           from './OmniEmployer';
import message                from './Message';
import directMessage          from './DirectMessage';
import card                   from './Card';
import forgotPassword         from './ForgotPassword';
import searchSeeker           from './SearchSeeker';
import invoice                from './Invoice';
import nudgeSeeker            from './NudgeSeeker';
import confirmPassword        from './ConfirmPassword';
import amendingHourComment    from './AmendingHourComment';
import notification           from './Notification';
import loginDetailSetting     from './LoginDetailSetting';
import splitShifts            from './SplitShifts';
import businessDetailSetting  from './BusinessDetailSetting';
import addSeeker              from './AddSeeker';
import seekerSignal           from './SeekerSignal';
import pdf                    from './Pdf';
import internalEmployee       from './InternalEmployee';
import modalAllJobs           from './ModalAllJobs';
import vacancy                from './Vacancy';
import conversation           from './Conversation';
import employer               from './Employer';
import provider               from './Provider';
import seeker                 from './Seeker';
import jobOfEmployee          from './JobOfEmployee'
import documentEmployee       from './DocumentOfEmployee'
import qrCode                 from './QrCode'
//import QrCode from '../components/Header/QrCode';
  
// Connect all reducer using
const rootReducer = combineReducers({
    settings,
    sidebar,
    authUser,
    jobApp,
    uniformReducer                  : uniform,
    location,
    skillReducer                    : skill,
    chargeRateReducer               : chargeRate,
    chargeRateByPayRateReducer      : chargeRateByPayRate,
    shiftReducer                    : shift,
    positionReducer                 : position,
    remoteRequestReducer            : remoteRequest,
    report,
    blockEmployeeReducer            : blockEmployee,
    users                           : user,
    omniEmployerReducer             : omniEmployer,
    messageReducer                  : message,
    directMessageReducer            : directMessage,
    cardReducer                     : card,
    searchSeekerReducer             : searchSeeker,
    forgotPasswordReducer           : forgotPassword,
    nudgeSeekerReducer              : nudgeSeeker,
    invoiceReducer                  : invoice,
    confirmPasswordReducer          : confirmPassword,
    amendingHourCommentReducer      : amendingHourComment,
    notificationReducer             : notification,
    loginDetailSettingReducer       : loginDetailSetting,
    splitShiftsReducer              : splitShifts,
    businessDetailSettingReducer    : businessDetailSetting,
    addSeekerReducer                : addSeeker,
    seekerSignalReducer             : seekerSignal,
    pdfReducer                      : pdf,
    internalEmployeeReducer         : internalEmployee,
    modalAllJobsReducer             : modalAllJobs,
    vacancyReducer                  : vacancy,
    conversationReducer             : conversation,
    EmployerReducer                 : employer,
    providerReducer                 : provider,
    seekerReducer                   : seeker,
    jobOfEmployeeReducer            : jobOfEmployee,
    documentEmployeeReducer         : documentEmployee,
    qrCode                          : qrCode
});

const reducers  = (state, action) => {
    if (action.type === 'REMOVE_REDUCER') {
        state = undefined;
    }
    return rootReducer(state, action);
};

export default reducers;

/**
 * Remote request Actions
 */

 import {
    GET_REMOTE_REQUEST_PENDING_LIST,
    GET_REMOTE_REQUEST_PENDING_DAILY_LIST,
    GET_REMOTE_REQUEST_APPROVED_LIST,
    GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE,

    ACCEPT_REMOTE_REQUEST,
    ACCEPT_LIST_REMOTE_REQUESTS,
    DECLINE_REMOTE_REQUEST,
    DECLINE_LIST_REMOTE_REQUESTS,

    GET_REMOTE_REQUEST_SUCCESS,
    GET_REMOTE_REQUEST_FAILURE,
    ACCEPT_REMOTE_REQUESTS_SUCCESS,
    ACCEPT_REMOTE_REQUESTS_FAILURE,
    DECLINE_REMOTE_REQUESTS_SUCCESS,
    DECLINE_REMOTE_REQUESTS_FAILURE,
    SEND_MAIL_TIMESHEET_PENDING,
    SEND_MAIL_TIMESHEET_PENDING_SUCCESS,
    SEARCH_SEEKER_TIMESHEETS,
    UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS,
    UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_SUCCESS,
    UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_FAILURE,
} from './types';


/**
 * Action Get All Pending remote request
 */
export const getPendingRemoteRequest = function (startDate, endDate, internal, locationId, startFromSunday = 0, init = false) {
    let payload = {};

    payload.init = init;
    if (startDate && endDate) {
        payload = {startDate, endDate};
    }
    if (+locationId) {
        payload.locationId = +locationId
    }

    if(startFromSunday) {
        payload.startFromSunday = startFromSunday
    }
    if(internal)
    {
        payload.internal = internal
    }
    return {
        type: GET_REMOTE_REQUEST_PENDING_LIST,
        payload,
    };
};

/**
 * Action Get All Pending remote request of specific date
 */
export const getPendingDailyRemoteRequest = function (date,locationId) {
    let payload = null;
    if (date) {
        payload = {date};
    }

    if(+locationId) {
        payload.locationId = +locationId
    }
    return {
        type: GET_REMOTE_REQUEST_PENDING_DAILY_LIST,
        payload
    };
};

/**
 * Action Get approved remote requests of specific date
 */
export const getApprovedRemoteRequest = function (startDate, endDate, locationId) {
    let payload = null;
    if (startDate && endDate) {
        payload = {startDate, endDate};
    }
    
    if(locationId) {
        payload = {startDate, endDate, locationId: +locationId};
    }

    return {
        type: GET_REMOTE_REQUEST_APPROVED_LIST,
        payload
    };
};

/**
 * Action Get remote requests Success
 */
export const getRemoteRequestsSuccess = (response) => ({
    type: GET_REMOTE_REQUEST_SUCCESS,
    payload: response
});

/**
 * Action Get remote requests Failure
 */
export const getRemoteRequestsFailure = (error) => ({
    type: GET_REMOTE_REQUEST_FAILURE,
    payload: error
});

/**
 * Action Get remote info
 */
export const getRemoteInfoFromAssignedSchedule = (assignedScheduleId) => ({
    type: GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE,
    payload: {assignedScheduleId}
});

/**
 * Action Accept remote request
 */
export const acceptRemoteRequest = (request, callbackSuccess, callbackFailure) => ({
    type: ACCEPT_REMOTE_REQUEST,
    payload: {request, callbackSuccess, callbackFailure}
});

/**
 * Action Accept list of remote requests
 */
export const acceptListOfRemoteRequests = (listOfRemoteRequests, callbackSuccess, callbackFailure, startFromSunday,editMode = false) => ({
    type: ACCEPT_LIST_REMOTE_REQUESTS,
    payload: { listOfRemoteRequests, callbackSuccess, callbackFailure, startFromSunday,editMode}
});

/**
 * Accept remote request success
 */
export const acceptRemoteRequestsSuccess = (response) => ({
    type: ACCEPT_REMOTE_REQUESTS_SUCCESS,
    payload: response
});

/**
 * Accept remote request failure
 */
export const acceptRemoteRequestsFailure = (error) => ({
    type: ACCEPT_REMOTE_REQUESTS_FAILURE,
    payload: error
});

/**
 * Action Decline remote request
 */
export const declineRemoteRequest = (remoteRequestId) => ({
    type: DECLINE_REMOTE_REQUEST,
    payload: {remoteRequestId}
});

/**
 * Action Decline list of remote requests
 */
export const declineListOfRemoteRequests = (listOfRequestIds) => ({
    type: DECLINE_LIST_REMOTE_REQUESTS,
    payload: {listOfRequestIds}
});

/**
 * Decline remote request success
 */
export const declineRemoteRequestSuccess = (response) => ({
    type: DECLINE_REMOTE_REQUESTS_SUCCESS,
    payload: response.data
});

/**
 * Decline remote request failure
 */
export const declineRemoteRequestFailure = (error) => ({
    type: DECLINE_REMOTE_REQUESTS_FAILURE,
    payload: error
});
export const sendMailTimesheetPending = (startDate, endDate, locationId) => ({
    type   : SEND_MAIL_TIMESHEET_PENDING    ,
    payload: {startDate, endDate, locationId}
});

export const sendMailTimesheetPendingSuccess = (response) => ({
    type   : SEND_MAIL_TIMESHEET_PENDING_SUCCESS,
    payload: response
});

export const searchSeekersTimeSheets = (searchText) => ({
    type: SEARCH_SEEKER_TIMESHEETS,
    payload: searchText
});

export const updateAsssignedScheduleTimeSheets = (payload) => ({
    type: UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS,
    payload: payload
});

export const updateAsssignedScheduleTimeSheetsSuccess = (response) => ({
    type: UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_SUCCESS,
    payload: response
});

export const updateAsssignedScheduleTimeSheetsFailure = (err) => ({
    type: UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_FAILURE,
    payload: err
});
import API                   from 'Api';
import MyGig                 from 'Constants/MyGig';
import { validNumberOrZero } from 'Helpers/helpers';

/**
 * Create timecard for seekers
 *
 * @param {Object} positionObject
 * @typedef {{
    breakTime          : Number
    skillId            : Number
    payrate            : Number
    chargeRate         : Number
    name               : String
    description        : String
    specialRequirements: String
    schedule           : Array<schedule>
    assignmentsTotal   : Number
    breakTime          : Number
    locationId         : Number
    totalRequested     : Number
  }}
 *
 * @param {Array<Number>} - Array of seeker IDs
 * @return async
 */
export const createTimecard = async (positionObject, seekerIds) => {
  const params = {
    skill_id: positionObject.skillId,
    experience: 0,
    payrate: positionObject.payrate,
    charge_rate: positionObject.chargeRate,
    status: MyGig.status.POSITION_OPEN,
    name: "",
    is_over18: positionObject.is_over18,
    description: positionObject.description,
    special_requirements: positionObject.specialRequirements,
    schedule: positionObject.schedule,
    total_assignments: seekerIds.length,
    break_time: validNumberOrZero(positionObject.breakTime),
    location_id: positionObject.locationId,
    seekers: seekerIds,
    requested_number: positionObject.totalRequested,
  };

  return await API.post('/employers/createtimecard', params)
    .then(response => response)
    .catch(error => error);
};

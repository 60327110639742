import API from 'Api';
import MyGig from "Constants/MyGig";

/**
 * Split multi-shifts position to positions with single shift
 *
 * @param {Number} positionId
 * @param {String} jobType
 * @return async
 */
export const splitShiftsOfPositionRequest = async (positionId, jobType) => {
    if (jobType && jobType === MyGig.jobType.FILLED) {
        return await API.put(`/employers/splitPositionFilled/${positionId}`)
        .then(response => response)
        .catch(err => err);
    } else if(jobType && jobType === MyGig.jobType.PENDING) {
        return await API.put(`/employers/splitPositionPending/${positionId}`)
        .then(response => response)
        .catch(err => err);
    } else {
        return await API.put(`/employers/splitPosition/${positionId}`)
        .then(response => response)
        .catch(err => err);
    }
}

import API from 'Api';

/**
 * Get/Filter all jobs from all employers
 *
 * @param  {Object} params
 * {
 *    status: <MyGig.jobType>
 *    start : <Integer|String> Start Unix timestamp for filter
 *    end   : <Integer|String> End Unix timestamp for filter
 *    paged : <Integer> The page need to response from API
 * }
 * @return async
 */
export const getAllJobsRequest = async (params) => await API.get('/omni/getAllJobs', params)
    .then(response => response)
    .catch(err => err);

/**
 * Request API to promote a job
 *
 * @param  {Integer} positionId
 * @param  {Integer} status
 * @return async
 */
export const updatePromotedStatusRequest = async (positionId, status) => await API.post(`/omni/updatePromotedStatus/${positionId}`, { status })
    .then(response => response)
    .catch(err => err);


export const getJobOfEmployeeRequest = async (employeeId, params) => await  API.get(`/employees/getEmployeeListJob/${employeeId}`, params)

export const getDocumentOfEmployeeRequest = async (omniEmployeeId) => await  API.get(`/admin/employees/checkFileExist/${omniEmployeeId}`)


export const deleteEmployeeExperienceRequest = async  (params) => await API.post('/admin/employee/deleteEmploymentById', params )

export const addHistoryRequest = async  (params) => await API.post('/admin/employee/addHistory', params )

export const deleteHistoryRequest = async  (params) => await API.post('/admin/employee/deleteHistory', params )
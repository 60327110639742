import React, { Component } from "react";

export class IconThreeDotCircle extends Component {
    render() {
        return (
            <svg
                className="svg-icon-three-dots"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
            >
                <g>
                    <circle cx="256" cy="256.198" r="39.659" />
                </g>
                <g>
                    <circle cx="374.978" cy="256.198" r="39.659" />
                </g>
                <g>
                    <circle cx="137.022" cy="256.198" r="39.659" />
                </g>
                <g>
                    <path
                        d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
                                  c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.046,216.341,216.341
                                  S375.275,472.341,256,472.341z"
                    />
                </g>
            </svg>
        );
    }
}

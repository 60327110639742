/**
 * App Header
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import screenfull from "screenfull";
import SweetAlert from "react-bootstrap-sweetalert";
import AppLogo from "Assets/img/gig-logo-black.png";
import Money from "Assets/img/Money.png";
import SeekerSignalPng from "Assets/img/SeekerSignal.png";
import InternalEmployeePng from "Assets/img/internal-employee.png";
import StoredData from "Constants/StoredData";
import { withRouter } from "react-router";
import SearchIcon from "@material-ui/icons/Search";
import MyGig from "Constants/MyGig";
import SeekerInfo from "Components/Model/SeekerInfo";
import Information from "Assets/img/Information.png";
import { checkValidSrcIcon } from "Helpers/helpers";
import debounce from "lodash.debounce"
import $ from "jquery";
import OAuth from "OAuth";

// actions
import {
    getAllSkills,
    collapsedSidebarAction,
    openCreateJobModal,
    openEditJob,
    getPostedJobs,
    openMessageModal,
    openDirectMessageModal,
    getAllSkillsByGroup,
    getMasterRate,
    getSeekerSignal,
    searchJob,
    getInternalEmployeeList,
    openModalAllJobs,
    getConversationList,
    getListProvider,
    getListSeeker,
    getAllMessages,
    searchSeekers,
    getLocation
} from "Actions";

// components
import Notification from "Components/Notification";
import DashboardOverlay from "../DashboardOverlay/DashboardOverlay";
import ClientProvider from "./ClientProvider";
import CreateEditJobModal from "Components/Model/CreateEditJob";
import SeekerSignal from "Components/Model/SeekerSignal";
import MessageModal from "Components/Model/Message";
import DirectMessageModal from "Components/Model/DirectMessage";
import PusherNotification from "Components/PusherNotification";
import ChargeRateModal from "Components/Model/ChargeRate";
import InternalEmployee from "Components/Model/InternalEmployee";
import UpcomingJobModal from "Components/Model/UpcomingJob";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import {
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';

// intl messages
import IntlMessages from "Util/IntlMessages";
const oauth = new OAuth();

const ICON_HEIGHT = 27;
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateEditJobModal: false,
            showChargeRateModal: false,
            showCreateGigSuccess: false,
            showCreateGigError: false,
            showCreateGigMessage: "",
            showSeekerSignalModal: false,
            showInternalEmployeeModal: false,
            showAddInternalEmployeeSuccess: false,
            showAddInternalEmployeeError: false,
            showProviderModal: false,
            showSeekerModal: false,
            showSearchSeekerModal: false,
            showAddInternalEmployeeMessage: "",
            inputGetProviders: {
                alphabet_start: "a",
                alphabet_end: "f",
                keyword: "",
            },
            inputGetSeekers: {
                alphabet_start: "a",
                alphabet_end: "f",
                keyword: "",
            },
            unReadDirectMessage: 0,
            unReadMessage: 0,
            validationSearchText: true,
            hoverItemSearch: null,
            popUpSeekerInfo: {
                open: false,
                employee: {},
                currentSeekerPopupId: undefined
            },
        };

        this.debounceSearchSeeker = debounce(this.searchSeekers, 500)
        this.searchSeekerRef = React.createRef();
    }

    componentDidMount = async () => {
        // this.userRole = await StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID);
        // document.addEventListener('click', (e)=>this.handleOutClickSearch((e)))
        // if (
        //     Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID)) ===
        //     oauth.isOmniStaff()
        // ) {
        // this.props.getConversationList(true);
        // this.props.getAllMessages();
        // }
        this.userRole = await StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID);
        document.addEventListener('click', (e) => this.handleOutClickSearch((e)))
        this.props.getConversationList(true);
        this.props.getAllMessages();
    };

    // componentWillUnmount() {
    //     document.removeEventListener('click', (e)=>this.handleOutClickSearch((e)))
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { unReadDirectMessage, unReadMessage } = this.state;
        if (
            unReadDirectMessage !==
            this.countUnReadDirectMessage(nextProps.conversations)
        ) {
            this.setState({
                unReadDirectMessage: this.countUnReadDirectMessage(
                    nextProps.conversations
                ),
            });
        }
        if (unReadMessage !== this.countUnReadMessage(nextProps.conversations)) {
            this.setState({
                unReadMessage: this.countUnReadMessage(nextProps.conversations),
            });
        }
    }

    handleOutClickSearch = (e) => {
        if (!this.state.showSearchSeekerModal) {
            return
        }
        if (this.searchSeekerRef && this.searchSeekerRef.current && !this.searchSeekerRef.current.contains(e.target)) {
            this.setState({
                showSearchSeekerModal: false
            })
        }
    }
    // function to change the state of collapsed sidebar
    onToggleNavCollapsed = (event) => {
        const val = !this.props.navCollapsed;
        this.props.collapsedSidebarAction(val);
    };

    // open dashboard overlay
    openDashboardOverlay() {
        $(".dashboard-overlay").toggleClass("d-none");
        $(".dashboard-overlay").toggleClass("show");
        if ($(".dashboard-overlay").hasClass("show")) {
            $("body").css("overflow", "hidden");
        } else {
            $("body").css("overflow", "");
        }
    }

    // close dashboard overlay
    closeDashboardOverlay() {
        $(".dashboard-overlay").removeClass("show");
        $(".dashboard-overlay").addClass("d-none");
        $("body").css("overflow", "");
    }

    // toggle screen full
    toggleScreenFull() {
        screenfull.toggle();
    }

    handleOpenChargeRateModal = () => {
        this.setState({ showChargeRateModal: true });
        this.props.getAllSkillsByGroup();
        this.props.getMasterRate();
    };

    handleOpenProviderModal = () => {
        const { inputGetProviders } = this.state;
        this.props.getListProvider(inputGetProviders);
        this.setState({ showProviderModal: true });
    };

    handleOpenSeekerModal = () => {
        const { inputGetSeekers, showSeekerModal } = this.state;
        this.props.getListSeeker(inputGetSeekers);

        this.setState({ showSeekerModal: !showSeekerModal });
    };

    handleOpenSeekerSignalModal = () => {
        const { listSkill } = this.props;
        if (!listSkill.length) {
            // this.props.getAllSkills();
            this.props.getAllSkillsByGroup();
        }
        this.props.getSeekerSignal(null, 1);
        this.setState({ showSeekerSignalModal: true });
    };

    handleOpenInternalEmployeeModal = () => {
        this.setState({
            showInternalEmployeeModal: !this.state.showInternalEmployeeModal,
        });
        this.props.getInternalEmployeeList();
    };

    afterCreateGigSuccess() {
        this.setState({
            showCreateGigSuccess: !this.state.showCreateGigSuccess,
        });
    }

    renderClientProvider() {
        const employerRole = Number(StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID));
        if (employerRole == oauth.isOmniStaff() || employerRole == oauth.isEmployerAdmin()) {
            return <ClientProvider history={this.props.history} />;
        }
    }

    countUnReadDirectMessage = (conversations) => {
        let number = 0;
        conversations.forEach((element) => {
            if (element.count_unread > 0) {
                number += 1;
            }
        });
        return number;
    };

    countUnReadMessage = (conversations) => {
        let number = 0;
        conversations.forEach((element) => {
            if (element.count_unread > 0) {
                number += 1;
            }
        });
        return number;
    };

    searchSeekers = (searchText) => {
        if (searchText.trim().length == 0) {
            this.setState({ showSearchSeekerModal: false })
        }

        if (searchText.trim().length >= 3) {
            this.props.searchSeekers(searchText)
            this.setState({ validationSearchText: false })
        }

        if (searchText.trim().length >= 1) {
            this.setState({ showSearchSeekerModal: true })
        }

        if (searchText.trim().length < 3)
            this.setState({ validationSearchText: true })
    }

    closeSeekerInfoPopup = () => {
        this.setState({
            popUpSeekerInfo: {
                open: false,
                employee: {},
                currentSeekerPopupId: undefined
            }
        })
    }

    openSeekerInfoPopup = (employee) => {
        this.setState({
            popUpSeekerInfo: {
                employee: {
                    ...employee,
                    user_fullname: employee.fullname,
                    home_office: {
                        name: employee.home_office
                    }
                },
                currentSeekerPopupId: employee.employee_id,
                open: true,
            }
        })
    }

    listSearch = () => {
        const { listSeekers, loading } = this.props
        const { validationSearchText, hoverItemSearch } = this.state
        return (
            <div
                role="presentation"
                className={`pop-up-search`}
            >
                {validationSearchText &&
                    <div>
                        <FormattedMessage id="header.search.seeker.validation.minlength" />
                    </div>
                }
                {(!loading && !!listSeekers.length && !validationSearchText) &&
                    <List>
                        {listSeekers.map((seeker, index) => {
                            return (
                                <ListItem button key={seeker.id} className="row"
                                    onClick={() => this.openSeekerInfoPopup(seeker)}
                                    onMouseEnter={() => { this.setState({ hoverItemSearch: seeker.id }) }}
                                    onMouseLeave={() => { this.setState({ hoverItemSearch: null }) }}
                                >
                                    <div className="col-2">
                                        <Avatar src={seeker.profile_thumbnail}>{seeker.fullname[0]}</Avatar>
                                    </div>
                                    <div className='col-8'>
                                        <ListItemText primary={seeker.fullname} />
                                        <div className={"d-flex job-icon"}>
                                            <i className="gig-icon icon-jobs smaller bottom no-pointer" />
                                            <span className="ml-2">
                                                {seeker.total_assignments || 0}
                                            </span>

                                            <i className="gig-icon icon-thumbs-up smaller bottom no-pointer ml-5" />
                                            <span className="ml-2">
                                                {seeker.total_positive_reviews ||
                                                    0}
                                            </span>

                                            <i className="gig-icon icon-favourite smaller bottom no-pointer ml-5" />
                                            {seeker.favourite > 0 && (
                                                <div>
                                                    <i className="gig-icon icon-favourite smaller bottom no-pointer" />
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {
                                                MyGig.listTrainingLicenseIcons.map((icon, index) => {
                                                    if (checkValidSrcIcon(seeker[icon.keyLink]) || (seeker.has_qualification && icon.qualificationValue > seeker.has_qualification)) {
                                                        return (
                                                            <img
                                                                key={`${icon.keyLink}-${index}`}
                                                                className="skill-icon mr-2"
                                                                src={seeker.id === hoverItemSearch ? icon.srcHoverIcon : icon.srcIcon}
                                                                height={20}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <img
                                            src={Information}
                                            height={30}
                                            widht={30}
                                        />
                                    </div>
                                </ListItem>
                            )
                        })}
                    </List>
                }
                {(!loading && !listSeekers.length && !validationSearchText) &&
                    <div>
                        <FormattedMessage id="header.search.seeker.validation.empty" />
                    </div>
                }
            </div>
        )
    }

    searchSeekerName = () => { }

    render() {
        const {
            horizontalMenu,
            history,
            searchJob,
            keyword,
            conversations,
            popUpName,
            className,
            openPopUp,
            loading,
            listOfLocationDatas
        } = this.props;
        const { unReadDirectMessage, unReadMessage, showSearchSeekerModal, popUpSeekerInfo } = this.state;

        let userId = 0;

        if (listOfLocationDatas) {
            listOfLocationDatas.data.map((location) => (
                userId = location.user_id
            ));
        }

        $("body").click(() => {
            $(".dashboard-overlay").removeClass("show");
            $(".dashboard-overlay").addClass("d-none");
            $("body").css("overflow", "");
        });

        return (
            <AppBar position="static" className="rct-header">
                <Toolbar className="d-flex justify-content-between w-100 pl-0">
                    <div className="d-flex align-items-center">
                        {horizontalMenu && (
                            <div className="site-logo bg-primary">
                                <Link to="/" className="logo-mini">
                                    <img
                                        src={AppLogo}
                                        className="mr-15"
                                        alt="site logo"
                                        width="50"
                                    />
                                </Link>
                                <Link to="/" className="logo-normal">
                                    <img
                                        src={AppLogo}
                                        className="img-fluid"
                                        alt="site-logo"
                                        width="100"
                                        height="17"
                                    />
                                </Link>
                            </div>
                        )}
                        <PusherNotification history={this.props.history} />
                        <ul className="list-inline mb-0 navbar-left header-display-flex">
                            {(oauth.checkCurrentIsOmniStaff() || oauth.checkCurrentIsEmployerAdmin()) && (
                                <Fragment>
                                    <li className="list-inline-item list-inline-item--v2 all-jobs-icon d-inline-block">
                                        {oauth.checkCurrentIsOmniStaff() && (
                                            <UpcomingJobModal
                                                history={this.props.history}
                                            />
                                        )}
                                        {/* <Tooltip
                                            title={
                                                <FormattedMessage id="modalAllJobs.tooltip" />
                                            }
                                            placement="bottom"
                                            disableFocusListener
                                        > */}
                                        {oauth.checkCurrentIsOmniStaff() && (
                                            <div className="header-icon-container">
                                                <IconButton
                                                    className="header-icon-container-height"
                                                    onClick={() =>
                                                        this.props.openModalAllJobs()
                                                    }
                                                >
                                                    <i className="material-icons header-icon-container-icon">
                                                        public
                                                    </i>
                                                </IconButton>
                                                <p className="header-icon-container-content">
                                                    Universe
                                                </p>
                                            </div>
                                        )}
                                        {/* </Tooltip> */}
                                    </li>

                                    {/* <li
                                        className={`list-inline-item list-inline-item--v2  messages-icon d-inline-block ${
                                            unReadDirectMessage > 0 && "shaker"
                                        }`}
                                    > */}
                                    {/* <Tooltip
                                            title={
                                                <FormattedMessage id="directMessage.tooltip" />
                                            }
                                            placement="bottom"
                                            disableFocusListener
                                        > */}
                                    {/* <div
                                            className="header-icon-container"
                                            onClick={() =>
                                                this.props.openDirectMessageModal()
                                            }
                                        >
                                            <IconButton className="header-icon-container-height">
                                                <img
                                                    src={DirectMessage}
                                                    width={38}
                                                />
                                                {unReadDirectMessage > 0 && (
                                                    <div className="count-unread-direct-message">
                                                        {unReadDirectMessage >
                                                        99
                                                            ? `99+`
                                                            : unReadDirectMessage}
                                                    </div>
                                                )}
                                            </IconButton>
                                            <p className="header-icon-container-content">
                                                Direct messsage
                                            </p>
                                        </div> */}
                                    {/* </Tooltip> */}
                                    {/* </li> */}
                                    {this.renderClientProvider()}

                                    {Number(
                                        StoredData.get(
                                            StoredData.KEYS.EMPLOYER_ROLE_ID
                                        )
                                    ) === oauth.isOmniStaff() && (
                                            <li className="list-inline-item list-inline-item--v2  charge-rates-icon">
                                                {/* <Tooltip
                                                title={
                                                    <FormattedMessage id="settings.chargeRates" />
                                                }
                                                placement="bottom"
                                                disableFocusListener
                                            > */}
                                                <div
                                                    className="header-icon-container"
                                                    onClick={
                                                        this
                                                            .handleOpenChargeRateModal
                                                    }
                                                >
                                                    <IconButton
                                                        aria-label="charge-rate"
                                                        className="header-icon-container-height"
                                                    >
                                                        <img
                                                            src={Money}
                                                            width={ICON_HEIGHT}
                                                            height={ICON_HEIGHT}
                                                        />
                                                    </IconButton>
                                                    <p className="header-icon-container-content">
                                                        Charge Rates
                                                    </p>
                                                </div>
                                                {/* </Tooltip> */}
                                                <ChargeRateModal
                                                    isOpen={
                                                        this.state
                                                            .showChargeRateModal
                                                    }
                                                    onClose={() =>
                                                        this.setState({
                                                            showChargeRateModal: false,
                                                        })
                                                    }
                                                />
                                            </li>
                                        )}
                                    {/* {Number(
                                        StoredData.get(
                                            StoredData.KEYS.EMPLOYER_ROLE_ID
                                        )
                                    ) === oauth.isOmniStaff() && (
                                        <li className="list-inline-item list-inline-item--v2  seeker-icon">
                                            <div
                                                className="header-icon-container"
                                                onClick={
                                                    this.handleOpenProviderModal
                                                }
                                            >
                                                <IconButton
                                                    className="header-icon-container-height"
                                                    aria-label="provider"
                                                >
                                                    <img
                                                        src={Provider}
                                                        width={ICON_HEIGHT}
                                                    />
                                                </IconButton>
                                                <p className="header-icon-container-content">
                                                    Provider
                                                </p>
                                            </div>
                                            <ProviderModal
                                                isOpen={
                                                    this.state.showProviderModal
                                                }
                                                onClose={() =>
                                                    this.setState({
                                                        showProviderModal: false,
                                                    })
                                                }
                                            />
                                        </li>
                                    )}
                                    {Number(
                                        StoredData.get(
                                            StoredData.KEYS.EMPLOYER_ROLE_ID
                                        )
                                    ) === oauth.isOmniStaff() && (
                                        <li className="list-inline-item list-inline-item--v2  seeker-icon">
                                            <div
                                                className="header-icon-container"
                                                onClick={
                                                    this.handleOpenSeekerModal
                                                }
                                            >
                                                <IconButton
                                                    className="header-icon-container-height"
                                                    aria-label="seeker"
                                                >
                                                    <img
                                                        src={Seeker}
                                                        height={ICON_HEIGHT}
                                                    />
                                                </IconButton>
                                                <p className="header-icon-container-content">
                                                    Seeker
                                                </p>
                                            </div>
                                            <SeekerModal
                                                handleOpenSeekerModal={() =>
                                                    this.handleOpenSeekerModal()
                                                }
                                                isOpen={
                                                    this.state.showSeekerModal
                                                }
                                                onClose={() =>
                                                    this.setState({
                                                        showSeekerModal: false,
                                                    })
                                                }
                                            />
                                        </li>
                                    )} */}
                                </Fragment>
                            )}
                        </ul>
                    </div>

                    {userId != MyGig.LIMIT_PROVIDER && (
                        <div className="search-seeker__header d-flex align-items-center" ref={this.searchSeekerRef}>
                            <FormGroup className="form-group__search-seeker">
                                <InputGroup>
                                    <Input
                                        placeholder={"Search..."}
                                        onChange={(e) => this.debounceSearchSeeker(e.target.value)}
                                        onFocus={() => this.setState({ showSearchSeekerModal: true })}
                                    />

                                    <SearchIcon className="search-icon" />
                                </InputGroup>
                            </FormGroup>
                            <SeekerInfo
                                employee={popUpSeekerInfo.employee}
                                currentSeekerPopupId={popUpSeekerInfo.currentSeekerPopupId}
                                open={popUpSeekerInfo.open}
                                closeSeekerInfoPopup={this.closeSeekerInfoPopup}
                            />
                            {showSearchSeekerModal && !loading && <div className={`model-list-search-seeker shadow px-2 `}>
                                {this.listSearch()}
                            </div>}
                        </div>
                    )}

                    <ul className="navbar-right list-inline header-ul-list">
                        {!oauth.checkCurrentIsStandard() && userId != MyGig.LIMIT_PROVIDER && (
                            <li className="list-inline-item list-inline-item--v2  seeker-signal-icon">
                                {/* <Tooltip
                                title={
                                    <FormattedMessage id="settings.seekerSignal" />
                                }
                                placement="bottom"
                                disableFocusListener
                            > */}
                                <div
                                    className="header-icon-container"
                                    onClick={
                                        this
                                            .handleOpenSeekerSignalModal
                                    }
                                >
                                    <IconButton
                                        className="header-icon-container-height"
                                        aria-label="seeker-signal"
                                    >
                                        <img
                                            src={
                                                SeekerSignalPng
                                            }
                                            height={ICON_HEIGHT}
                                        />
                                    </IconButton>

                                    <p className="header-icon-container-content">
                                        Seeker signal
                                    </p>
                                </div>
                                {/* </Tooltip> */}
                                <SeekerSignal
                                    isOpen={
                                        this.state
                                            .showSeekerSignalModal
                                    }
                                    onClose={() =>
                                        this.setState({
                                            showSeekerSignalModal: false,
                                        })
                                    }
                                />
                            </li>
                        )}
                        <Notification history={this.props.history}
                            adminAccount={oauth.checkCurrentIsAdmin() ||
                                oauth.checkCurrentIsSuper()}
                        />

                        {(oauth.checkCurrentIsAdmin() ||
                            oauth.checkCurrentIsSuper()) && (
                                <li className="list-inline-item list-inline-item--v2 add-new-job-icon">
                                    {/* <Tooltip
                                    title={
                                        <IntlMessages id="tooltip.add_new_gig" />
                                    }
                                    placement="bottom"
                                    disableFocusListener
                                > */}
                                    <div
                                        className="header-icon-container"
                                        onClick={() =>
                                            this.props.openCreateJobModal()
                                        }
                                    >
                                        <IconButton className="header-icon-container-height">
                                            <i className="material-icons header-icon-container-icon">
                                                add_circle
                                            </i>
                                        </IconButton>
                                        <p className="header-icon-container-content">
                                            Add New Gig
                                        </p>
                                    </div>
                                    {/* </Tooltip> */}
                                </li>
                            )}

                        {!oauth.checkCurrentIsStandard() && (
                            <li
                                className={`list-inline-item list-inline-item--v2 messages-icon d-inline-block`}
                            // ${
                            //     unReadMessage > 0 && "shaker"
                            // }
                            >
                                {/* <Tooltip
                                    title={
                                        <IntlMessages id="tooltip.messages" />
                                    }
                                    placement="bottom"
                                    disableFocusListener
                                > */}
                                <div
                                    className="header-icon-container"
                                    onClick={() =>
                                        this.props.openMessageModal()
                                    }
                                >
                                    <IconButton
                                        aria-label="settings"
                                        className="header-icon-container-height"
                                    >
                                        <i className="zmdi zmdi-comment header-icon-container-icon" />
                                        {unReadMessage > 0 && (
                                            <div className="count-unread-direct-message">
                                                {unReadMessage > 99
                                                    ? `99+`
                                                    : unReadMessage}
                                            </div>
                                        )}
                                    </IconButton>
                                    <p className="header-icon-container-content">
                                        Messages
                                    </p>
                                </div>
                                {/* </Tooltip> */}
                            </li>
                        )}

                        {!oauth.checkCurrentIsStandard() && (
                            <li className="list-inline-item list-inline-item--v2 internal-employee-icon">
                                {/* <Tooltip
                                    title={
                                        <FormattedMessage id="internalEmployee.tooltip.standardAccount" />
                                    }
                                    placement="bottom"
                                    disableFocusListener
                                > */}
                                <div
                                    className="header-icon-container"
                                    onClick={
                                        this.handleOpenInternalEmployeeModal
                                    }
                                >
                                    <IconButton
                                        className="header-icon-container-height"
                                        aria-label="internal-employee"
                                    >
                                        <img
                                            src={InternalEmployeePng}
                                            height={ICON_HEIGHT}
                                        />
                                    </IconButton>
                                    <p className="header-icon-container-content">
                                        Employees
                                    </p>
                                </div>
                                {/* </Tooltip> */}

                                {
                                    <InternalEmployee
                                        title={
                                            <FormattedMessage id="internalEmployee.title.standardAccount" />
                                        }
                                        isOpen={
                                            this.state.showInternalEmployeeModal
                                        }
                                        onClose={() =>
                                            this.setState({
                                                showInternalEmployeeModal: false,
                                            })
                                        }
                                        onSuccess={(messageKey) =>
                                            this.setState({
                                                showAddInternalEmployeeSuccess: true,
                                                showAddInternalEmployeeMessage: messageKey,
                                            })
                                        }
                                        onError={(messageKey) =>
                                            this.setState({
                                                showAddInternalEmployeeError: true,
                                                showAddInternalEmployeeMessage: messageKey,
                                            })
                                        }
                                    />
                                }
                            </li>
                        )}
                    </ul>

                    <MessageModal
                        onCloseModal={() => this.props.getAllMessages()}
                    />

                    <DirectMessageModal
                        onCloseModal={() =>
                            this.props.getConversationList(true)
                        }
                    />
                </Toolbar>

                <CreateEditJobModal
                    onSuccess={(messageKey) =>
                        this.setState({
                            showCreateGigSuccess: true,
                            showCreateGigMessage: messageKey,
                        })
                    }
                    onError={(messageKey) =>
                        this.setState({
                            showCreateGigError: true,
                            showCreateGigMessage: messageKey
                        })
                    }
                    history={this.props.history}
                />

                <DashboardOverlay
                    onClose={() => this.closeDashboardOverlay()}
                />
                <SweetAlert
                    success
                    show={this.state.showCreateGigSuccess}
                    title=""
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => this.afterCreateGigSuccess()}
                >
                    <h2 style={{ color: 'black' }}>Success</h2>
                    <IntlMessages id={this.state.showCreateGigMessage} />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.state.showCreateGigError}
                    title={this.state.showCreateGigMessage === "addSeeker.doAdd.over18" ? <b style={{color:"#000"}}>Over 18's Only</b> : ""}
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() =>
                        this.setState({ showCreateGigError: false })
                    }
                >
                    <IntlMessages id={this.state.showCreateGigMessage} />
                </SweetAlert>

                <SweetAlert
                    success
                    show={this.state.showAddInternalEmployeeSuccess}
                    title=""
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() =>
                        this.setState({ showAddInternalEmployeeSuccess: false })
                    }
                >
                    <IntlMessages
                        id={this.state.showAddInternalEmployeeMessage}
                    />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.state.showAddInternalEmployeeError}
                    title=""
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() =>
                        this.setState({ showAddInternalEmployeeError: false })
                    }
                >
                    <IntlMessages
                        id={this.state.showAddInternalEmployeeMessage}
                    />
                </SweetAlert>
            </AppBar>
        );
    }
}

// map state to props
const mapStateToProps = ({
    jobApp,
    settings,
    skillReducer,
    conversationReducer,
    messageReducer,
    searchSeekerReducer,
    location
}) => ({
    ...settings,
    listSeekers: searchSeekerReducer.listSeekers,
    loading: searchSeekerReducer.onLoading,
    listSkill: skillReducer.skills,
    keyword: jobApp.keyword,
    conversations: conversationReducer.conversations,
    messages: messageReducer.listPositions,
    listOfLocationDatas: location.allLocation
});

const mapActionToProps = {
    collapsedSidebarAction,
    openCreateJobModal,
    openEditJob,
    getPostedJobs,
    openMessageModal,
    openDirectMessageModal,
    getAllSkillsByGroup,
    getMasterRate,
    getSeekerSignal,
    getAllSkills,
    searchJob,
    getInternalEmployeeList,
    openModalAllJobs,
    getConversationList,
    getListProvider,
    getListSeeker,
    getAllMessages,
    searchSeekers,
    getLocation
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Header));

import API from 'Api';

/**
 * Get employer's invoices by date range
 *
 * @param  {Object} duration
 * @typedef {{
        start: date - YYYY-MM-DD
        end  : date - YYYY-MM-DD
    }}
 * @return async
 */
export const getInvoicesRequest = async duration => await API.post('/employers/getInvoices', duration)
    .then(response => response)
    .catch(err => err);

/**
 * Export invoice as PDF by invoice ID
 *
 * @param  {Object} param
 * @typedef {{
        invoiceId: Number
    }}
 * @return async
 */
export const exportInvoiceRequest = async param => await API.post('/employers/exportPdf', param)
    .then(response => response)
    .catch(err => err);

/**
 * Send email invoice
 *
 * @param  {Number} param - invoice ID
 * @return async
 */
export const sendInvoiceRequest = async param => await API.post('/employers/sendMail', param)
    .then(response => response)
    .catch(err => err);

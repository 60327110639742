/**
 * Position Reducer
 */

import MyGig from "Constants/MyGig";
import {
  OPEN_DECLINE_ASSIGNMENT_POPUP,
  OPEN_NO_SHOW,
  NO_SHOW,
  NO_SHOW_SUCCESS,
  NO_SHOW_FAIL,
  OPEN_ACCEPT_POPUP,
  OPEN_DECLINE_POPUP,
  OPEN_CHECKIN_OUT_POPUP,
  CHECK_OVERLAPS,
  CLOSE_ALL_POPUPS,
  IS_OVERLAPS,
  NOT_OVERLAPS,
  DECLINE_CANDIDATE_SUCCESS,
  DECLINE_CANDIDATE_FAIL,
  ACCEPT_CANDIDATE_SUCCESS,
  ACCEPT_CANDIDATE_FAIL,
  DECLINE_ASSIGNMENT_SUCCESS,
  DECLINE_ASSIGNMENT_FAIL,
  CHECK_IN_ASSIGNMENT,
  CHECK_IN_ASSIGNMENT_SUCCESS,
  CHECK_IN_ASSIGNMENT_FAIL,
  CHECK_OUT_ASSIGNMENT,
  CHECK_OUT_ASSIGNMENT_SUCCESS,
  CHECK_OUT_ASSIGNMENT_FAIL,
  SEND_CURRENT_POSITION,
  CREATE_NEW_POSITION,
  CREATE_NEW_POSITION_SUCCESS,
  CREATE_NEW_POSITION_FAILURE,
  EDIT_POSITION,
  EDIT_POSITION_SUCCESS,
  EDIT_POSITION_FAILURE,
  GET_CANDIDATES_BY_POSITION,
  GET_CANDIDATES_BY_POSITION_SUCCESS,
  GET_CANDIDATES_BY_POSITION_FAILURE,
  GET_DECLINED_CANDIDATES_BY_POSITION,
  GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS,
  GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE,
  GET_ASSIGNMENTS_BY_POSITION,
  GET_ASSIGNMENTS_BY_POSITION_SUCCESS,
  GET_ASSIGNMENTS_BY_POSITION_FAILURE,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_FAILURE,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS,
  CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_FAILURE,
  ACCEPT_CANDIDATE,
  DECLINE_ASSIGNMENT,
  DECLINE_CANDIDATE,
  WARNING_SEEKER_OVER_SIX_DAY,
  HIDE_WARNING_SEEKER_OVER_SIX_DAY,
  CHECK_WORKING_OVER_SIX_DAY,
  CHECK_WORKING_OVER_SIX_DAY_SUCCESS,
  HIDE_WARNING_SEEKER_IS_STUDENT,
  WARNING_ACCEPT_SEEKER_TO_JOB,
  HIDE_WARNING_ACCEPT_SEEKER_TO_JOB,
  CHECK_SEEKER_ACCEPT_TO_JOB_SUCCEESS,
} from "Actions/types";
import { TrendingUp } from "@material-ui/icons";
import {
  CHECK_SEEKER_NOT_TO_JOB,
  CHECK_SEEKER_NOT_TO_JOB_FAILURE,
  CHECK_SEEKER_NOT_TO_JOB_SUCCESS,
  WARNING_SEEKER_48_HOURS,
  HIDE_WARNING_SEEKER_48_HOURS,
  WARNING_SEEKER_IS_STUDENT,
  WARNING_SEEKER_IN_8_HOURS,
  HIDE_WARNING_SEEKER_IN_8_HOURS,
  WARNING_SEEKER_IS_OVERLAPS,
  HIDE_WARNING_SEEKER_IS_OVERLAPS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  addSeekerPositionId: null,
  currentPositionId: null,
  isOpen: true,
  showAlertOverlaps: false,
  showAlertCannotAcceptAll: false,
  showAlertExceedStudent: false,
  showAlertPositionFilled: false,
  showAlertAccept: false,
  showAlertDecline: false,
  showAlertNoShow: false,
  showAlertCheckInOut: false,
  currentDeclineId: null,
  currentDeclineAssignmentId: null,
  showAlertDeclineAssignment: false,
  newPosition: null,
  currentEmployeeId: null,
  loadingPosted: false, // Loading data status of API get candidates process
  loadingApplied: false, // Loading data status of API get assignments process
  loadingDeclined: false, // Loading data status of API get declined candidates process
  editedPosition: null,
  candidates: [],
  declinedCandidates: [],
  assignments: [],
  loadingSweetAlert: false,
  paginationLength: 0,
  tabIndex: 0,
  jobChanged: false,
  chosenEmployee: {},
  checkTypeAlert: "checkIn",
  checkTime: {
    hour: 0,
    minute: 0,
  },
  isAllCheckedIn: false,
  isAllCheckedOut: false,
  openFromJobCard: false,
  isNotOverLaps: false,
  isLoadingPagination: false,
  warningSeekerOverSixDay: false,
  showAlertRestriction48hours: false,
  loadingCheckNotToJob: false,
  warningSeekerStudent: false,
  warningSeekerOverlaps: false,
  warningSeeker48h: false,
  warningSeekerIn8h: false,
  successList: [],
  errorList: [],
  errorDeclineSeeker: "",
};
const NUMBER_SEEKER_PER_PAGE = 5;
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_ACCEPT_POPUP:
      return { ...state, showAlertAccept: true, openFromJobCard: false };

    case OPEN_DECLINE_POPUP:
      const employee = action.payload.employee;
      return {
        ...state,
        showAlertDecline: true,
        currentDeclineId: action.payload.currentDeclineId,
        chosenEmployee: employee,
        openFromJobCard: action.payload.fromJobCard,
      };

    case OPEN_DECLINE_ASSIGNMENT_POPUP:
      const employee_assignment = action.payload.employee;

      return {
        ...state,
        showAlertDeclineAssignment: true,
        currentDeclineAssignmentId: action.payload.currentDeclineAssignmentId,
        chosenEmployee: employee_assignment,
        openFromJobCard: action.payload.fromJobCard,
      };

    case OPEN_CHECKIN_OUT_POPUP:
      const { checkType, hour, minute } = action.payload;
      return {
        ...state,
        showAlertCheckInOut: true,
        checkTypeAlert: checkType,
        checkTime: {
          hour: hour,
          minute: minute,
        },
      };

    case CLOSE_ALL_POPUPS:
      return {
        ...state,
        showAlertNoShow: false,
        showAlertOverlaps: false,
        showAlertAccept: false,
        showAlertDecline: false,
        showAlertDeclineAssignment: false,
        showAlertExceedStudent: false,
        showAlertPositionFilled: false,
        showAlertCannotAcceptAll: false,
        showAlertCheckInOut: false,
        loadingPosted: INIT_STATE.loadingPosted,
        showAlertRestriction48hours: false,
        warningAcceptSeekerToJob: false,
        warningSeeker48h: false,
        warningSeekerIn8h: false,
        warningSeekerStudent: false,
        warningSeekerOverlaps: false,
        errorDeclineSeeker: "",
      };

    case OPEN_NO_SHOW:
      return { ...state, showAlertNoShow: true };
    case NO_SHOW:
      return { ...state, loadingSweetAlert: true };

    case NO_SHOW_SUCCESS:
      return {
        ...state,
        showAlertNoShow: false,
        loadingSweetAlert: INIT_STATE.loadingSweetAlert,
      };

    case NO_SHOW_FAIL:
      return {
        ...state,
        showAlertNoShow: false,
        loadingSweetAlert: INIT_STATE.loadingSweetAlert,
      };

    case CHECK_OVERLAPS:
      return {
        ...state,
        currentEmployeeId: action.payload.employeeId,
        loadingPosted: true,
      };

    case IS_OVERLAPS:
      return {
        ...state,
        showAlertOverlaps: true,
        loadingPosted: INIT_STATE.loadingPosted,
      };

    case NOT_OVERLAPS:
      const candidate_employee = action.payload.employee;
      const { checkFromJobCard } = action.payload;
      return {
        ...state,
        showAlertOverlaps: false,
        showAlertAccept: true,
        openFromJobCard: checkFromJobCard,
        loadingPosted: INIT_STATE.loadingPosted,
        chosenEmployee: candidate_employee,
      };

    case ACCEPT_CANDIDATE:
      return {
        ...state,
        loadingSweetAlert: true,
      };

    case ACCEPT_CANDIDATE_SUCCESS: {
      const status = action.payload.statusAction;
      const response = action.payload.response_ids;
      let candidates = [...state.candidates];
      response.map((r) => {
        candidates = candidates.filter(
          (candidate) => candidate.job_response_id !== r
        );
      });

      if (status == MyGig.ACCEPT_SEEKER_RESULT.ACCEPT_ALL) {
        return {
          ...state,
          showAlertAccept: false,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
          candidates: candidates,
        };
      }

      if (status == MyGig.ACCEPT_SEEKER_RESULT.NOT_ACCEPT_ALL) {
        return {
          ...state,
          showAlertAccept: false,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
          showAlertCannotAcceptAll: true,
          candidates: candidates,
        };
      }
    }

    case ACCEPT_CANDIDATE_FAIL:
      if (action.payload.error.message == MyGig.EXCEED_STUDENT_ALERT) {
        return {
          ...state,
          showAlertAccept: false,
          showAlertExceedStudent: true,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
        };
      } else if (action.payload.error.message == MyGig.POSITION_FILLED_ALERT) {
        return {
          ...state,
          showAlertAccept: false,
          showAlertPositionFilled: true,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
        };
      } else if (
        action.payload.error.message == MyGig.EXCEED_MAXIMUM_HOURS_REACHED
      ) {
        return {
          ...state,
          showAlertAccept: false,
          showAlertRestriction48hours: true,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
        };
      } else {
        return {
          ...state,
          showAlertAccept: false,
          showAlertExceedStudent: false,
          showAlertPositionFilled: false,
          loadingSweetAlert: INIT_STATE.loadingSweetAlert,
        };
      }

    case DECLINE_ASSIGNMENT_SUCCESS:
      let assignmentIds = action.payload;
      let assignments = [...state.assignments];
      assignmentIds.map((r) => {
        assignments = assignments.filter((assignment) => assignment.id !== r);
      });
      return {
        ...state,
        showAlertDeclineAssignment: false,
        currentDeclineAssignmentId: null,
        assignments: assignments,
        loadingSweetAlert: false,
      };

    case DECLINE_ASSIGNMENT:
      return { ...state, loadingSweetAlert: true };

    case DECLINE_ASSIGNMENT_FAIL:
      return {
        ...state,
        loadingSweetAlert: false,
        errorDeclineSeeker: action.payload,
        showAlertDecline: false,
      };

    case DECLINE_CANDIDATE_SUCCESS:
      const { candidateIds } = action.payload;
      let candidates = [...state.candidates];
      candidateIds.map((r) => {
        candidates = candidates.filter(
          (candidate) => candidate.job_response_id !== r
        );
      });
      return {
        ...state,
        candidates: candidates,
        showAlertDecline: false,
        currentDeclineId: null,
        loadingSweetAlert: false,
        errorDeclineSeeker: "",
      };

    case DECLINE_CANDIDATE:
      return {
        ...state,
        loadingSweetAlert: true,
      };

    case DECLINE_CANDIDATE_FAIL:
      return {
        ...state,
        showAlertDecline: false,
        currentDeclineId: null,
        loadingSweetAlert: false,
      };

    case CHECK_IN_ASSIGNMENT: {
      return { ...state, loadingSweetAlert: true };
    }

    case CHECK_IN_ASSIGNMENT_SUCCESS: {
      return { ...state, loadingSweetAlert: false, showAlertCheckInOut: false };
    }

    case CHECK_IN_ASSIGNMENT_FAIL: {
      return { ...state, loadingSweetAlert: false };
    }

    case CHECK_OUT_ASSIGNMENT: {
      return { ...state, loadingSweetAlert: true };
    }

    case CHECK_OUT_ASSIGNMENT_SUCCESS: {
      return { ...state, loadingSweetAlert: false, showAlertCheckInOut: false };
    }

    case CHECK_OUT_ASSIGNMENT_FAIL: {
      return { ...state, loadingSweetAlert: false };
    }

    case SEND_CURRENT_POSITION:
      return {
        ...state,
        currentPositionId: action.payload.positionId,
        isOpen: action.payload.isOpen,
        candidates: [],
        assignments: [],
      };

    case CREATE_NEW_POSITION:
      return { ...state };

    case CREATE_NEW_POSITION_SUCCESS:
      return { ...state, newPosition: action.payload };

    case CREATE_NEW_POSITION_FAILURE:
      return { ...state };

    case EDIT_POSITION:
      return { ...state };

    case EDIT_POSITION_SUCCESS:
      return { ...state, editedPosition: action.payload };

    case EDIT_POSITION_FAILURE:
      return { ...state };

    case GET_CANDIDATES_BY_POSITION:
      let listCandidate = [...state.candidates];
      if (action.payload.page == 1) {
        listCandidate = [];
      }
      return {
        ...state,
        loadingPosted: true,
        candidates: listCandidate,
        jobChanged: action.payload.jobChanged,
        isLoadingPagination: true,
      };

    case GET_CANDIDATES_BY_POSITION_SUCCESS:
      let current_candidates = [...state.candidates];
      if (action.payload.currentPage == 1) {
        current_candidates = action.payload.data;
      } else {
        current_candidates = current_candidates.concat(action.payload.data);
      }
      return {
        ...state,
        candidates: current_candidates,
        loadingPosted: false,
        paginationLength: action.payload.totalPage,
        tabIndex: 0,
        isLoadingPagination: false,
      };
    case GET_CANDIDATES_BY_POSITION_FAILURE:
      return { ...state, loadingPosted: false };

    case GET_DECLINED_CANDIDATES_BY_POSITION:
      let listDecline = [...state.declinedCandidates];
      if (action.payload.page == 1) {
        listDecline = [];
      }
      return {
        ...state,
        loadingDeclined: true,
        declinedCandidates: listDecline,
        jobChanged: false,
      };

    case GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS:
      let current_declined = [...state.declinedCandidates];
      if (action.payload.currentPage == 1) {
        current_declined = action.payload.data;
      } else {
        current_declined = current_declined.concat(action.payload.data);
      }
      return {
        ...state,
        declinedCandidates: current_declined,
        loadingDeclined: false,
        paginationLength: action.payload.totalPage,
        tabIndex: 2,
      };

    case GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE:
      return { ...state, loadingDeclined: false };

    case GET_ASSIGNMENTS_BY_POSITION:
      let listAssignment = [...state.assignments];
      if (action.payload.page == 1) {
        listAssignment = [];
      }
      return {
        ...state,
        loadingApplied: true,
        assignments: listAssignment,
        jobChanged: false,
      };

    case GET_ASSIGNMENTS_BY_POSITION_SUCCESS:
      let current_assignment = [...state.assignments];
      if (action.payload.currentPage == 1) {
        current_assignment = action.payload.data.filter(
          (assignment) => assignment.response_id !== null
        );
      } else {
        current_assignment = current_assignment.concat(
          action.payload.data.filter(
            (assignment) => assignment.response_id !== null
          )
        );
      }
      return {
        ...state,
        loadingApplied: false,
        assignments: current_assignment,
        paginationLength: action.payload.totalPage,
        tabIndex: 1,
      };

    case GET_ASSIGNMENTS_BY_POSITION_FAILURE:
      return { ...state, loadingApplied: false };

    case CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN: {
      return { ...state };
    }

    case CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS: {
      const status = action.payload.status;
      return { ...state, isAllCheckedIn: status };
    }

    case CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT: {
      return { ...state };
    }

    case CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS: {
      const status = action.payload.status;
      return { ...state, isAllCheckedOut: status };
    }
    case WARNING_SEEKER_OVER_SIX_DAY:
      return {
        ...state,
        warningSeekerOverSixDay: true,
        listSeekerWarningSixDay: action.payload,
      };

    case HIDE_WARNING_SEEKER_OVER_SIX_DAY:
      return {
        ...state,
        warningSeekerOverSixDay: false,
        listSeekerWarningSixDay: [],
        loadingCheckWarningSixDay: false,
      };

    case CHECK_WORKING_OVER_SIX_DAY: {
      return { ...state, loadingCheckWarningSixDay: true };
    }

    case WARNING_SEEKER_48_HOURS:
      return { ...state, warningSeeker48h: true };

    case HIDE_WARNING_SEEKER_48_HOURS:
      return { ...state, warningSeeker48h: false };

    case CHECK_WORKING_OVER_SIX_DAY_SUCCESS: {
      return { ...state, loadingCheckWarningSixDay: false };
    }

    case CHECK_SEEKER_NOT_TO_JOB: {
      return { ...state, loadingCheckNotToJob: true };
    }

    case CHECK_SEEKER_NOT_TO_JOB_SUCCESS: {
      return { ...state, loadingCheckNotToJob: false };
    }

    case CHECK_SEEKER_NOT_TO_JOB_FAILURE: {
      return { ...state, loadingCheckNotToJob: false };
    }

    case WARNING_SEEKER_IS_STUDENT:
      return { ...state, warningSeekerStudent: true };

    case HIDE_WARNING_SEEKER_IS_STUDENT:
      return { ...state, warningSeekerStudent: false };

    case WARNING_SEEKER_IS_OVERLAPS:
      return { ...state, warningSeekerOverlaps: true };

    case HIDE_WARNING_SEEKER_IS_OVERLAPS:
      return { ...state, warningSeekerOverlaps: false };

    case WARNING_SEEKER_IN_8_HOURS:
      return { ...state, warningSeekerIn8h: true };

    case HIDE_WARNING_SEEKER_IN_8_HOURS:
      return { ...state, warningSeekerIn8h: false };

    case WARNING_ACCEPT_SEEKER_TO_JOB:
      const { errorList, successList } = action.payload;

      return {
        ...state,
        warningAcceptSeekerToJob: true,
        errorList: errorList,
        successList: successList,
      };
    case HIDE_WARNING_ACCEPT_SEEKER_TO_JOB:
      return {
        ...state,
        warningAcceptSeekerToJob: false,
      };
    case CHECK_SEEKER_ACCEPT_TO_JOB_SUCCEESS: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

/**
 * Internal Employee Reducer
 */
import {
    GET_INTERNAL_EMPLOYEE_LIST,
    GET_INTERNAL_EMPLOYEE_LIST_SUCCESS,
    GET_INTERNAL_EMPLOYEE_LIST_FAILURE,
    ADD_INTERNAL_EMPLOYEE,
    ADD_INTERNAL_EMPLOYEE_SUCCESS,
    ADD_INTERNAL_EMPLOYEE_FAILURE,
    UPDATE_INTERNAL_EMPLOYEE,
    UPDATE_INTERNAL_EMPLOYEE_SUCCESS,
    UPDATE_INTERNAL_EMPLOYEE_FAILURE,
    DELETE_INTERNAL_EMPLOYEE,
    DELETE_INTERNAL_EMPLOYEE_SUCCESS,
    DELETE_INTERNAL_EMPLOYEE_FAILURE,
    CSV_IMPORT_INTERNAL_EMPLOYEES,
    CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS,
    CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE
} from "Actions/types";

const INIT_STATE = {
    listInternalEmployees: undefined,
    onLoading: false,
    onSaving: false,
    hasDeleteError: true
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_INTERNAL_EMPLOYEE_LIST: {
            return { ...state, onLoading: !INIT_STATE.onLoading };
        }
        case GET_INTERNAL_EMPLOYEE_LIST_SUCCESS: {
            return { ...state, listInternalEmployees: action.payload, onLoading: INIT_STATE.onLoading };
        }
        case GET_INTERNAL_EMPLOYEE_LIST_FAILURE: {
            return { ...state, onLoading: INIT_STATE.onLoading };
        }
        case ADD_INTERNAL_EMPLOYEE: {
            return { ...state, onLoading: !INIT_STATE.onLoading };
        }
        case ADD_INTERNAL_EMPLOYEE_SUCCESS: {
            return { ...state, onLoading: INIT_STATE.onLoading };
        }
        case ADD_INTERNAL_EMPLOYEE_FAILURE: {
            return { ...state, onLoading: INIT_STATE.onLoading };
        }
        case UPDATE_INTERNAL_EMPLOYEE: {
            return { ...state, onSaving: !INIT_STATE.onSaving };
        }
        case UPDATE_INTERNAL_EMPLOYEE_SUCCESS: {
            return { ...state, onSaving: INIT_STATE.onSaving };
        }
        case UPDATE_INTERNAL_EMPLOYEE_FAILURE: {
            return { ...state, onSaving: INIT_STATE.onLoading };
        }
        case DELETE_INTERNAL_EMPLOYEE: {
            return { ...state };
        }
        case DELETE_INTERNAL_EMPLOYEE_SUCCESS: {
            return { ...state };
        }
        case DELETE_INTERNAL_EMPLOYEE_FAILURE: {
            return { ...state, hasDeleteError: false };
        }
        case CSV_IMPORT_INTERNAL_EMPLOYEES: {
            return { ...state, onLoading: !INIT_STATE.onLoading };
        }
        case CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS: {
            return { ...state, onLoading: INIT_STATE.onLoading };
        }
        case CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE: {
            return { ...state, onLoading: INIT_STATE.onLoading };
        }
        default: return { ...state };
    }
};

import API from 'Api';

/**
 * @param  {Integer} userId - User ID of employee
 * @param  {String} name
 * @param  {String} title
 * @param  {String} message
 * @return async
 */
export const createConversationRequest = async (params) => (
    await API.post('/omni/conversation', params)
        .then(response => response)
        .catch(err => err)
);


export const getConversationRequest = async  (params) => (
     await  API.get('/omni/conversation', params)
);

export const closeConversationRequest = async (conversationId) => (
    await  API.remove(`/omni/conversation/${conversationId}`)
);

export const deleteConversationRequest = async (conversationId) => (
    await  API.post(`/omni/conversation/deleteConversation`, {
        conversation_id: conversationId
    })
        .then(response => response)
        .catch(err => err)
);
export const openConversationRequest = async (conversationId) => (
    await API.post(`/omni/conversation/openConversation`, {
        conversation_id: conversationId
    })
        .then(response => response)
        .catch(err => err)
);

import React from 'react';
import moment from 'moment'
import MatButton from '@material-ui/core/Button';


const ItemSeeker = ({item,handleBlockSeeker,index,length}) => {
    const borderItemStyle = index === length - 1 ? '' : 'border-item'

    return(
    <div key={index} className={`${borderItemStyle}`} style={{flexDirection: "row", marginLeft: 12,paddingBottom:10}} key={item.employee_id}>
        <div className="w-100 mt-2 align-items-center justify-content-between d-flex">
            <div className="d-flex w-80 align-items-center">
                <div className='w-50'>
                    <p className='m-0 font-weight-bold' style={{fontSize:22}}>{item.fullname}</p>
                </div>
                <div className='w-20'>
                    <p className='m-0'>{moment(item.date_of_birth).format('DD-MM-YYYY')}</p>
                </div>
                <div className='w-30'>
                    <p className='m-0'>{item.phone}</p>
                </div>
            </div>
            <MatButton 
                                                     
                className="gig-button ok bigger-font pull-right"
                onClick={()=>handleBlockSeeker(item.id)}>
                Block
            </MatButton>
        </div>
    </div>
)};

export default ItemSeeker;
import React, { Component } from 'react';
import CircularProgress     from '@material-ui/core/CircularProgress';

const SPINNER_DEFAULT_SIZE = 70;

class Spinner extends Component {
    render() {
        let { show, spinnerWidth } = this.props;

        if (!spinnerWidth) {
            spinnerWidth = SPINNER_DEFAULT_SIZE;
        }

        return (
            <div className={`spinner-wrapper ${  show ? '' : 'hide'}`}>
                <div className={`spinner spinner-${  spinnerWidth}`}>
                    <CircularProgress className="progress-warning mr-30 mb-10" size={spinnerWidth} mode="determinate" value={40} min={0} max={50} />
                </div>
            </div>
        );
    }
}

export default Spinner;

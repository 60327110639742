/**
 * Search Seekers Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { isSuccess } from 'Helpers/ResponseStatus';

import { SEEKER_SEARCH } from 'Actions/types';

import {
    searchSeekersSuccess,
    searchSeekersForAddSuccess,
    searchSeekersFailure
} from 'Actions';

import { searchSeekersForPositionRequest } from "Services/employers/JobService";

/**
 * Search seekers from server
 */
function* handleSearchSeekers({payload}) {
    const { searchText, positionId, page, isPostTimeCard} = payload;

    try {
        const response = yield call(searchSeekersForPositionRequest, searchText, positionId, page ,isPostTimeCard);
        if (response.data && isSuccess(response)) {
            if(positionId) {
                yield put(searchSeekersForAddSuccess(response.data.data));   
            } else {
                yield put(searchSeekersSuccess(response.data.data));
            }
        } else {
            yield put(searchSeekersFailure(response));
        }
    } catch (error) {
        yield put(searchSeekersFailure(error));
    }
}

/**
 * Take search seekers action
 */
export function* takeSearchSeekers() {
    yield takeEvery(SEEKER_SEARCH, handleSearchSeekers);
}

/**
 * Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(takeSearchSeekers)
    ]);
}

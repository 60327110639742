import API from 'Api';
import StoredData from 'Constants/StoredData';

/**
 * get all list options of shift when register
 */
export const getShiftOptionsRequest = () =>
  API.get('/register/employer/getShiftOptions', {}, false)
    .then(response => response)
    .catch(error => error);


/**
 * Check employer email verified
 * @param  string email
 * @return boolean
 */
export const checkEmployerEmailVerifiedRequest = (params) => API.post('/employers/is-verified/email', params, false);

/**
 * Log in as another employer.
 *
 * @return {!jQuery.deferred}
 * @public
 */
export const logInRequest = (params) => API.post('/authenticate/employer', params, false);

/**
* Log out
*
* @return {!jQuery.deferred}
* @public
*/
export const logOutRequest = (params) => API.post('/authenticate/logout', params, false);

/**
 * Register a new employer.
 *
 * @param  {!omni.mywork.employer.model.SignUp} model
 * @return {!jQuery.deferred}
 * @public
 */
export const signUpUserRequest = params => API.post('/register/employer', params, false)
  .then(response => response)
  .catch(error => error);

/**
 * getEmployerDetails
 *
 * @return {!jQuery.deferred}
 * @public
 */
export const getEmployerDetailsRequest = async () => {
  let url = '/employers';
  if (StoredData.isSet(StoredData.KEYS.EMPLOYER_ID)) {
    url = `/employers`;
  }
    return await API.get(url, {'employer_id':   StoredData.get(StoredData.KEYS.EMPLOYER_ID)}, true)
    .then(response => response)
    .catch(error => error);
};

/**
 * verifyEmail
 *
 * @return {!jQuery.deferred}
 * @public
 */
export const verifyEmail = () => API.post('/employers/verify/email');

export const refreshTokenGrantRequest = (params) => API.post('/reauthorize', params)
.then(response => response)
.catch(error => error);

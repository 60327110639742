import React from "react";
import { connect } from "react-redux";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Spinner from "Components/Model/Spinner";
import { FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";
import MatButton from "@material-ui/core/Button";
import { blockEmployee } from "Actions/BlockEmployeeActions";

import { getBlockedSeekers } from "Actions/ReportActions";

import ItemSeeker from "./ItemSeeker";

import { searchSeekersForPositionRequest as searchSeekers } from "../../../services/employers/JobService";

class ModalSeeker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listSeekerNonBlocked: [],
            searchText: "",
            loading: false,
        };
    }

    handleBlockSeeker = (id) => {
        this.setLoading();
        this.props.blockEmployee(
            id,
            () => {
                const listSeekerNonBlocked = this.state.listSeekerNonBlocked.filter(
                    (seeker) => seeker.id !== id
                );
                this.setState({ listSeekerNonBlocked });
                this.props.getBlockedSeekers();
                this.setLoading();
            },
            () => {}
        );
    };

    setLoading = () => {
        const { loading } = this.state;
        this.setState({ loading: !loading });
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.handleSearch();
        }
    };

    handleSearch = () => {
        const { searchText } = this.state;
        this.setLoading();
        searchSeekers(searchText)
            .then((res) => {
                if (res.data) {
                    const listSeeker = res.data.data;
                    const { blockedSeekers } = this.props;
                    const listIdBlockedSeeker = blockedSeekers.map(
                        (seeker) => seeker.employeeId
                    );
                    const listSeekerNonBlocked = listSeeker.filter(
                        (seeker) => !listIdBlockedSeeker.includes(seeker.id)
                    );

                    this.setState({
                        listSeekerNonBlocked,
                    });
                }
                this.setLoading();
            })
            .catch(() => this.setLoading());
    };

    onChangeText = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    };

    closeModal = () => {
        this.props.onClose();
    };

    render() {
        const { isModalVisible } = this.props;
        const { listSeekerNonBlocked, loading } = this.state;
        return (
            <div
                // className='charge-rate-gig-outer-wrapper'
                className="nudge-seeker-outer-wrapper"
                style={{ display: isModalVisible ? "block" : "none" }}
            >
                <SwipeableDrawer
                    anchor={"right"}
                    open={isModalVisible}
                    onOpen={() => false}
                    onClose={this.closeModal}
                >
                    <div className="drawer-wrapper">
                        {/* <div className='seeker-wrapper'> */}
                        <div className="nudge-seeker-inner-wrapper">
                            <div className="modal-header-container">
                                <p className="seeker-title font-bold bigger-font">
                                    Search Seekers
                                </p>
                                <i
                                    className="zmdi zmdi-close-circle orange font-2x pull-right on-hover"
                                    onClick={this.closeModal}
                                />
                            </div>
                            <FormGroup className="col-6 search-container">
                                <InputGroup className="input-group">
                                    <InputGroupAddon
                                        addonType="prepend"
                                        className="icon-search d-flex align-items-center"
                                    >
                                        <i className="material-icons">search</i>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Something"
                                        onChange={this.onChangeText}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                </InputGroup>
                                {/* <MatButton 
                                    className="gig-button ok bigger-font pull-right"
                                     
                                    onClick={this.handleSearch}>
                                    Search
                                </MatButton> */}
                            </FormGroup>
                            <Spinner spinnerWidth={70} show={loading} />
                            {listSeekerNonBlocked.length ? (
                                listSeekerNonBlocked.map((item, index) => (
                                    <ItemSeeker
                                        key={item.id}
                                        item={item}
                                        index={index}
                                        handleBlockSeeker={
                                            this.handleBlockSeeker
                                        }
                                        length={listSeekerNonBlocked.length}
                                    />
                                ))
                            ) : (
                                <span>Search Seekers</span>
                            )}
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = ({ searchSeekerReducer, report }) => ({
    listSeeker: searchSeekerReducer.listSeekers,
    blockedSeekers: report.blockedSeekers,
});

const mapActionToProps = {
    blockEmployee,
    getBlockedSeekers,
};

export default connect(mapStateToProps, mapActionToProps)(ModalSeeker);

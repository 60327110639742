/**
 * Vacancy Actions
 */
import {
    GET_VACANCY_LOCATIONS,
    GET_VACANCY_LOCATIONS_SUCCESS,
    GET_VACANCY_LOCATIONS_FAILURE
} from './types';

export const getVacancyLocations = () => ({
    type: GET_VACANCY_LOCATIONS,
});

export const getVacancyLocationsSuccess = response => ({
    type: GET_VACANCY_LOCATIONS_SUCCESS,
    payload: response,
});

export const getVacancyLocationsFailure = error => ({
    type: GET_VACANCY_LOCATIONS_FAILURE,
    payload: error,
});

/**
 * Redux Report Actions
 */
import {
    GET_BLOCKED_SEEKERS_REQUEST,
    GET_BLOCKED_SEEKERS_SUCCESS,
    GET_BLOCKED_SEEKERS_FAILURE,
    GET_RATING_OF_SEEKERS_REQUEST,
    GET_RATING_OF_SEEKERS_SUCCESS,
    GET_RATING_OF_SEEKERS_FAILURE,
    GET_TOP_SEEKERS_BY_SHIFTS_REQUEST,
    GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS,
    GET_TOP_SEEKERS_BY_SHIFTS_FAILURE,
    GET_WEEKLY_FULFILMENT_REPORT,
    GET_WEEKLY_FULFILMENT_REPORT_SUCCESS,
    GET_WEEKLY_FULFILMENT_REPORT_FAILURE,
    GET_WEEKLY_TIMECARD_REPORT,
    GET_WEEKLY_TIMECARD_REPORT_SUCCESS,
    GET_WEEKLY_TIMECARD_REPORT_FAILURE,
    GET_MONTHLY_TIMECARD_REPORT,
    GET_MONTHLY_TIMECARD_REPORT_SUCCESS,
    GET_MONTHLY_TIMECARD_REPORT_FAILURE,
    GET_BILLING_REPORT,
    GET_BILLING_REPORT_SUCCESS,
    GET_BILLING_REPORT_FAILURE,
    GET_POSITIONS_STATISTICS_REQUEST,
    GET_POSITIONS_STATISTICS_SUCCESS,
    GET_POSITIONS_STATISTICS_FAILURE,
    GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS,
    EXCEL_DOWNLOAD_WEEKLY_HOUR
} from './types';

/**
 * Redux Action To GET list blocked seekers 
 */
export const getBlockedSeekers = () => ({
    type: GET_BLOCKED_SEEKERS_REQUEST
});

export const getBlockedSeekersSuccess = (payload) => ({
    type: GET_BLOCKED_SEEKERS_SUCCESS,
    payload
});

export const getBlockedSeekersFailure = (error) => ({
    type: GET_BLOCKED_SEEKERS_FAILURE,
    error
});

/**
 * Redux Action To GET list seeker's ratings 
 */
export const getRatingOfSeekers = () => ({
    type: GET_RATING_OF_SEEKERS_REQUEST
});

export const getRatingOfSeekersSuccess = (payload) => ({
    type: GET_RATING_OF_SEEKERS_SUCCESS,
    payload
});

export const getRatingOfSeekersFailure = (error) => ({
    type: GET_RATING_OF_SEEKERS_FAILURE,
    error
});


/**
 * Redux Action To GET list top seekers by shifts 
 */
export const getTopSeekersByShifts = (params= "all") => ({
    type: GET_TOP_SEEKERS_BY_SHIFTS_REQUEST,
    payload: params
});

export const getTopSeekersByShiftsSuccess = (payload) => ({
    type: GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS,
    payload
});

export const getTopSeekersByShiftsFailure = (error) => ({
    type: GET_TOP_SEEKERS_BY_SHIFTS_FAILURE,
    error
});

export const getTopSeekersMonthByShiftsSuccess = (payload) => ({
    type: GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS,
    payload
});

/**
 * Action To get weekly fulfilment report
 */
export const getWeeklyFulfilmentReport = (params) => ({
    type: GET_WEEKLY_FULFILMENT_REPORT,
    params
});

export const getWeeklyFulfilmentReportSuccess = (payload) => ({
    type: GET_WEEKLY_FULFILMENT_REPORT_SUCCESS,
    payload
});

export const getWeeklyFulfilmentReportFailure = (error) => ({
    type: GET_WEEKLY_FULFILMENT_REPORT_FAILURE,
    error
});

/**
 * Action To get billing report
 */
 export const getBillingReport = (params) => ({
    type: GET_BILLING_REPORT,
    params
});

export const getBillingReportSuccess = (payload) => ({
    type: GET_BILLING_REPORT_SUCCESS,
    payload
});

export const getBillingFailure = (error) => ({
    type: GET_BILLING_REPORT_FAILURE,
    error
});

/**
 * Action To get "weekly" timecard report
 */
export const getWeeklyTimecardReport = (params) => ({
    type: GET_WEEKLY_TIMECARD_REPORT,
    params
});

export const getWeeklyTimecardReportSuccess = (payload) => ({
    type: GET_WEEKLY_TIMECARD_REPORT_SUCCESS,
    payload
});

export const getWeeklyTimecardReportFailure = (error) => ({
    type: GET_WEEKLY_TIMECARD_REPORT_FAILURE,
    error
});

/**
 * Action To get "monthly" timecard report
 */
export const getMonthlyTimecardReport = (params) => ({
    type: GET_MONTHLY_TIMECARD_REPORT,
    params
});

export const getMonthlyTimecardReportSuccess = (payload) => ({
    type: GET_MONTHLY_TIMECARD_REPORT_SUCCESS,
    payload
});

export const getMonthlyTimecardReportFailure = (error) => ({
    type: GET_MONTHLY_TIMECARD_REPORT_FAILURE,
    error
});

/**
 * Redux Action To GET position statistics 
 */
export const getPositionsStatistics = () => ({
    type: GET_POSITIONS_STATISTICS_REQUEST
});

export const getPositionsStatisticsSuccess = (payload) => ({
    type: GET_POSITIONS_STATISTICS_SUCCESS,
    payload
});

export const getPositionsStatisticsFailure = (error) => ({
    type: GET_POSITIONS_STATISTICS_FAILURE,
    error
});

export const excelDownloadWeeklyHours = (week, locationId) => ({
    type:   EXCEL_DOWNLOAD_WEEKLY_HOUR,
    payload: { week, locationId }
});


import React, { Component, Fragment } from 'react';
import classNames         from 'classnames';

import Dialog           from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const VIEWER_SIZES  = {
    portrait : [600, 700],
    landscape: [1100, 550]
};

class PdfViewer extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: true }
    }
    componentWillReceiveProps(nextProps) {
        const { urlStream } = nextProps
        if (urlStream !== this.props.urlStream) {
            this.setState({ loading: false })
        }
    }
    getViewerSize = () => {
        const { orientation = 'landscape' } = this.props;

        return {
            width : VIEWER_SIZES[orientation][0],
            height: VIEWER_SIZES[orientation][1],
        };
    }

    renderViewer(width, height) {
        const { urlStream } = this.props;

        return (
            <Fragment>
                {urlStream && !this.state.loading ? (
                    <object data={urlStream} type='application/pdf' width={width} height={height} />
                ) : (
                    <CircularProgress className="progress-warning" size={70} mode="determinate" value={40} min={0} max={50}/>
                )}
            </Fragment>
        );
    }

    render() {
        const { open, onClose, classes, orientation = 'landscape' } = this.props;
        let { width, height } = this.getViewerSize();

        height += 50;

        const viewerTheme = createMuiTheme({
            overrides: {
                MuiDialog: {
                    paperWidthSm: {
                        width,
                        height,
                        maxWidth: width,
                        maxHeight: height,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }
            }
        });

        return (
            <MuiThemeProvider theme={viewerTheme}>
                <Dialog
                    disableEnforceFocus
                    disableAutoFocus
                    open={open}
                    onClose={() => onClose()}
                >
                    {this.renderViewer(width, height)}
                </Dialog>
            </MuiThemeProvider>
        );
    }
}

export default PdfViewer;

import React, { Fragment } from "react";
import { RctCard } from "Components/RctCard";
import { Collapse } from "reactstrap";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import Modal from "@material-ui/core/Modal";
import JobCard from "Components/JobCard";
import moment from "moment";
import Star from "Assets/img/gig-icons/star-01.png";
import NoneGig from "Assets/img/none-gig.png";
import NoneComment from "Assets/img/none-comments.png";
import NoneExperience from "Assets/img/none-experience.png";
import IntlMessages from "Util/IntlMessages";
import { getTheDate, checkValidSrcIcon } from "Helpers/helpers";
import MyGig from "Constants/MyGig";
import OAuth from "OAuth";
import StoredData from "Constants/StoredData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Fade } from "@material-ui/core";

import "./style.scss";

const oauth = new OAuth();

class Seeker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: "training",
      jobIdShowPopUp: null,
      createNote: {
        isShow: false,
        value: "",
      },
    };
  }

  UNSAFE_componentWillMount = () => {
    const { seeker } = this.props;
    this.props.getEmployeeListJobApply(seeker.employee_id);
  };

  toggle = (value) => this.setState((state) => ({ collapse: value }));

  getGigDateFormat = (unixTime) => {
    if (!unixTime) return "";
    return moment.unix(unixTime).format("DD/MM/YY");
  };

  getAppliedDateFormat = (schedule) => {
    const startDate = moment.unix(schedule[0].start).format("DD MMM");
    const timeRange = `${moment
      .unix(schedule[0].start)
      .format("H:mm")} - ${moment.unix(schedule[0].end).format("H:mm")}`;

    let endDate = null;
    if (schedule.length > 1) {
      endDate = moment
        .unix(schedule[schedule.length - 1].start)
        .format("DD MMM");
    }
    return {
      startDate,
      endDate,
      timeRange,
    };
  };

  getNoteDateFormat = (date) => {
    const stillUtc = moment.utc(date).toDate();
    const localTime = moment(stillUtc).local().format("H:mm Do MMM");

    return localTime;
  };

  renderQualifications() {
    const { seeker, skillId } = this.props;
    const sameSkill = (seeker.employee_skills || []).find(
      (e) => e.skill_id === skillId
    );
    return (sameSkill || {}).qualifications;
  }

  renderTabGig(hasHistory) {
    const { seeker } = this.props;
    let historyList = seeker.employment_history;
    const providerId = StoredData.get("employer-id");
    const chosenEmployerIds = StoredData.get("employer-chosen-id");
    if (
      !oauth.checkCurrentIsOmniStaff() &&
      !oauth.checkCurrentIsEmployerAdmin()
    ) {
      historyList = historyList.filter((e) => e.employer_id == providerId);
    }
    if (oauth.checkCurrentIsEmployerAdmin()) {
      historyList = historyList.filter((e) =>
        chosenEmployerIds.includes(e.employer_id)
      );
    }
    return (
      <div className="gigs-table-wrapper max-height-250 auto-scroll-y only">
        {!hasHistory && (
          <h3 className="col-6 my-3 text-secondary">
            <IntlMessages id="tab.noGig" />
          </h3>
        )}
        {hasHistory && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {
                    <IntlMessages
                      className="text-secondary font-info-tab"
                      id="widgets.date"
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <IntlMessages
                      className="text-secondary font-info-tab"
                      id="words.role"
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <IntlMessages
                      className="text-secondary font-info-tab"
                      id="words.provider"
                    />
                  }
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(historyList || []).map((element) => (
                <TableRow key={Math.random()}>
                  <TableCell
                    className="text-secondary font-info-tab"
                    component="th"
                    scope="row"
                  >
                    {this.getGigDateFormat(element.start_unix_timestamp)}
                  </TableCell>
                  <TableCell className="text-secondary font-info-tab">
                    {element.skill_name}
                  </TableCell>
                  <TableCell className="text-secondary font-info-tab">
                    {element.provider_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }

  openPopupSeekeInfo = (position_id) => {
    this.setState({ jobIdShowPopUp: position_id });
    this.props.getCandidatesByPosition(position_id);
    // this.props.getDeclinedCandidatesByPosition(position_id)
    // this.props.getAssignmentsByPosition(position_id)
  };

  renderTabApplied() {
    let appliedStatuses = [200, 207, 208];
    const { listJob, listJobAppiled } = this.props;
    let listJobApplied = listJobAppiled.filter((item) =>
      appliedStatuses.includes(item.job_status)
    );
    const hasJobApplied = !!listJobApplied.length;
    const { jobIdShowPopUp } = this.state;
    const job = listJobAppiled.find((e) => e.position_id === jobIdShowPopUp);
    let canShowJob = true;
    const chosenEmployerIds = StoredData.get("employer-chosen-id");
    if (
      !oauth.checkCurrentIsOmniStaff() &&
      !oauth.checkCurrentIsEmployerAdmin()
    ) {
      if (!listJob.find((e) => e.position_id === jobIdShowPopUp)) {
        canShowJob = false;
      }
    }

    if (oauth.checkCurrentIsEmployerAdmin()) {
      if (job && !chosenEmployerIds.includes(job.provider_id)) {
        canShowJob = false;
      }
    }

    const providerId = StoredData.get("employer-id");
    let disableAction = true;
    if (job && job.provider_id == providerId) {
      disableAction = false;
    }

    const jobType =
      job && job.assignments_filled == job.assignments_total
        ? MyGig.jobType.FILLED
        : MyGig.jobType.POSTED;
    return (
      <div>
        <Modal
          open={jobIdShowPopUp}
          onClose={() => {
            this.setState({ jobIdShowPopUp: null });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {canShowJob ? (
            <div className="tab-applied-modal__content">
              <JobCard
                colClasses="position-relative h-100"
                job={job}
                jobType={jobType}
                autoExpand={false}
                jobSeeker={true}
                disableClickTitle
                disableNudge={disableAction}
                disableMessage={disableAction}
                disableAdd={disableAction}
                disableEdit={disableAction}
                disableRespost={disableAction}
                disableDelete={disableAction}
              />
            </div>
          ) : (
            <RctCard>
              <div className="tab-applied-modal__not-loaded">
                <IntlMessages id="tab.noPermission" />
              </div>
            </RctCard>
          )}
        </Modal>

        <div className="applied-table-wrapper max-height-250 auto-scroll-y only">
          {!hasJobApplied && (
            <h3 className="col-6 my-3 text-secondary">
              <IntlMessages id="tab.noApplied" />
            </h3>
          )}
          {!!hasJobApplied && (
            <Table>
              <TableBody>
                {(listJobApplied || []).map((element) => {
                  let schedule = this.getAppliedDateFormat(element.schedule);
                  return (
                    <Fragment>
                      <TableRow
                        onClick={() =>
                          this.openPopupSeekeInfo(element.position_id)
                        }
                        key={Math.random()}
                        className={`table-row__custom mb-1 ${
                          jobIdShowPopUp == element.position_id ? "choose" : ""
                        } `}
                      >
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          {schedule.endDate ? (
                            <span className="text-dark font-weight-bold">
                              {schedule.startDate} - {schedule.endDate}
                            </span>
                          ) : (
                            <div className="applied-table-wrapper__schedule">
                              <span className="text-dark font-weight-bold">
                                {schedule.startDate}:{" "}
                              </span>
                              <span>{schedule.timeRange}</span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          <span className="text-dark font-weight-bold">
                            {element.job_type}
                          </span>
                        </TableCell>
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          {element.provider_name}
                        </TableCell>
                      </TableRow>
                      <div className="spacer"></div>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }

  renderTabConfirmed() {
    const { listJob, listJobAppiled } = this.props;
    let listJobConfirmed = listJobAppiled.filter(
      (item) => item.job_status === 201
    );
    const { jobIdShowPopUp } = this.state;
    const hasJobConfirmed = !!listJobConfirmed.length;
    const job = listJobAppiled.find((e) => e.position_id === jobIdShowPopUp);
    let canShowJob = true;
    const chosenEmployerIds = StoredData.get("employer-chosen-id");

    if (
      !oauth.checkCurrentIsOmniStaff() &&
      !oauth.checkCurrentIsEmployerAdmin()
    ) {
      if (!listJob.find((e) => e.position_id === jobIdShowPopUp)) {
        canShowJob = false;
      }
    }

    if (oauth.checkCurrentIsEmployerAdmin()) {
      if (job && !chosenEmployerIds.includes(job.provider_id)) {
        canShowJob = false;
      }
    }
    const providerId = StoredData.get("employer-id");
    let disableAction = true;
    if (job && job.provider_id == providerId) {
      disableAction = false;
    }
    const jobType =
      job && job.assignments_filled == job.assignments_total
        ? MyGig.jobType.FILLED
        : MyGig.jobType.POSTED;
    return (
      <div>
        <Modal
          open={jobIdShowPopUp}
          onClose={() => {
            this.setState({ jobIdShowPopUp: null });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {canShowJob ? (
            <div className="tab-applied-modal__content">
              <JobCard
                colClasses="position-relative h-100"
                job={job}
                jobType={jobType}
                autoExpand={false}
                jobSeeker={true}
                disableClickTitle
                disableNudge={disableAction}
                disableMessage={disableAction}
                disableAdd={disableAction}
                disableEdit={disableAction}
                disableRespost={disableAction}
                disableDelete={disableAction}
              />
            </div>
          ) : (
            <RctCard>
              <div className="tab-applied-modal__not-loaded">
                <IntlMessages id="tab.noPermission" />
              </div>
            </RctCard>
          )}
        </Modal>

        <div className="applied-table-wrapper max-height-250 auto-scroll-y only">
          {!hasJobConfirmed && (
            <h3 className="col-6 my-3 text-secondary">
              <IntlMessages id="tab.noConfirmed" />
            </h3>
          )}
          {!!hasJobConfirmed && (
            <Table>
              <TableBody>
                {(listJobConfirmed || []).map((element) => {
                  let schedule = this.getAppliedDateFormat(element.schedule);
                  return (
                    <Fragment>
                      <TableRow
                        onClick={() =>
                          this.openPopupSeekeInfo(element.position_id)
                        }
                        key={Math.random()}
                        className={`table-row__custom mb-1 ${
                          jobIdShowPopUp == element.position_id ? "choose" : ""
                        } `}
                      >
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          {schedule.endDate ? (
                            <span className="text-dark font-weight-bold">
                              {schedule.startDate} - {schedule.endDate}
                            </span>
                          ) : (
                            <div className="applied-table-wrapper__schedule">
                              <span className="text-dark font-weight-bold">
                                {schedule.startDate}:{" "}
                              </span>
                              <span>{schedule.timeRange}</span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          <span className="text-dark font-weight-bold">
                            {element.job_type}
                          </span>
                        </TableCell>
                        <TableCell className="applied-table-cell text-secondary font-info-tab">
                          {element.provider_name}
                        </TableCell>
                      </TableRow>
                      <div className="spacer"></div>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }

  renderTabExperience() {
    const { seeker } = this.props;
    const experiences = [];
    (seeker.experiences || []).forEach((ex) => {
      if (ex.employment_position !== "") experiences.push(ex);
    });
    const hasExperience = !!experiences.length;
    const skills = seeker.experiences || [];

    return (
      <div>
        <ul className="list-group list-group-flush list-experience max-height-150 auto-scroll-y only max-height-250">
          {!hasExperience && (
            <h3 className="col-6 my-3 text-secondary">
              <IntlMessages id="tab.noExperience" />
            </h3>
          )}
          {hasExperience &&
            experiences.map((ele, index) => (
              <li
                key={index}
                className="list-group-item smaller-font font-info-tab"
                style={{ paddingBottom: 5, paddingTop: 5 }}
              >
                {!!ele.employment_position && (
                  <div
                    style={{
                      fontSize: 14,
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    <IntlMessages
                      id="tab.experienceAt"
                      values={{
                        position: ele.employment_position,
                        company: ele.employment_company,
                      }}
                    />
                    <span>
                      {" "}
                      ({moment(ele.employment_start).format("YYYY")})
                    </span>{" "}
                    &nbsp;&nbsp;
                    {ele.isVerifyExperience == 1 && (
                      <img
                        src={Star}
                        className="gig-icon smaller bottom no-pointer"
                      />
                    )}
                  </div>
                )}
                <p
                  className="text-secondary my-3 "
                  style={{ fontSize: 12, marginBottom: 0 }}
                >
                  {ele.employment_leave}
                </p>
              </li>
            ))}
        </ul>
      </div>
    );
  }

  renderTabComment() {
    const { seeker } = this.props;
    const listComment = [];
    (seeker.employment_history || []).forEach((element) => {
      if (element.employer_comment) listComment.push(element);
    });
    const hasComment = !!listComment.length;

    return (
      <ul className="list-group list-group-flush list-comments max-height-150 auto-scroll-y only max-height-250">
        {!hasComment && (
          <h3 className="col-6 my-3 text-secondary">
            <IntlMessages id="tab.noComment" />
          </h3>
        )}
        {hasComment &&
          listComment.map((ele, index) => (
            <li
              key={index}
              className="list-group-item smaller-font"
              style={{ paddingBottom: 5, paddingTop: 5 }}
            >
              <div className="row item-comment ">
                <div className="image-comment">
                  <img src={ele.employer_thumbnail} className="comment-image" />
                </div>
                <div className="content-comment font-info-tab">
                  <strong style={{ color: "#000", fontSize: 15 }}>
                    {ele.skill_name} at {ele.provider_name}
                  </strong>
                  <p className="text-secondary" style={{ fontSize: 12 }}>
                    {ele.employer_comment}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    );
  }

  renderTabNotes() {
    const { seeker } = this.props;
    const {
      createNote: { value, isShow },
    } = this.state;
    const listNote = [];
    (seeker.admin_notes || []).forEach((element) => {
      if (element.content) listNote.push(element);
    });
    const hasNote = !!listNote.length;

    return (
      <React.Fragment>
        <div className="collapse-seeker__create-note">
          {!isShow && (
            <button
              className="collapse-seeker__note__new-btn"
              type="button"
              onClick={() =>
                this.setState({
                  createNote: {
                    value: "",
                    isShow: true,
                  },
                })
              }
            >
              New
            </button>
          )}
          {isShow && (
            <React.Fragment>
              <textarea
                className="collapse-seeker__create-note__textarea"
                value={value}
                onChange={(e) =>
                  this.setState({
                    createNote: { value: e.target.value, isShow: true },
                  })
                }
              />
              <div>
                <button
                  className="collapse-seeker__create-note__btn-cancel"
                  type="button"
                  onClick={() =>
                    this.setState({
                      createNote: {
                        value: "",
                        isShow: false,
                      },
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className="collapse-seeker__create-note__btn-save"
                  type="button"
                  onClick={() => {
                    this.props.addNote(seeker.employee_id, value);

                    this.setState({
                      createNote: {
                        value: "",
                        isShow: false,
                      },
                    });
                  }}
                >
                  Save
                </button>
              </div>
            </React.Fragment>
          )}
        </div>

        <ul className="list-group list-group-flush list-comments max-height-150 auto-scroll-y only max-height-250">
          {!hasNote && (
            <h3 className="col-6 my-3 text-secondary">
              <IntlMessages id="tab.noNote" />
            </h3>
          )}
          {hasNote &&
            listNote.map((ele, index) => (
              <li
                key={index}
                className="list-group-item smaller-font"
                style={{ paddingBottom: 5, paddingTop: 5 }}
              >
                <div className="content-comment font-info-tab">
                  <strong style={{ color: "#000", fontSize: 15 }}>
                    {ele.fullname} - {getTheDate(ele.created_at, "H:mm Do MMM Y")}
                  </strong>
                  <p className="text-secondary" style={{ fontSize: 12 }}>
                    {ele.content}
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </React.Fragment>
    );
  }

  renderTabTraining() {
    const { seeker } = this.props;
    const result = MyGig.listTrainingLicenseIcons
      .map((icon, index) => {
        if (
          checkValidSrcIcon(seeker[icon.keyLink]) ||
          (seeker.has_qualification &&
            0 < icon.qualificationValue &&
            icon.qualificationValue <= seeker.has_qualification)
        ) {
          return (
            <div key={`tabtraininglicense-${index}`} className={`col-6`}>
              <div className="row align-items-center my-1">
                <img
                  src={icon.srcIcon}
                  className={`${
                    icon.keyLink === "dbs_check" ? "" : "gig-icon"
                  } no-pointer smaller mr-1`}
                  width={icon.keyLink === "dbs_check" ? "16em" : "8em"}
                  // height = "4em"
                />
                <h5 className="text-secondary pt-2">{icon.name}</h5>
              </div>
            </div>
          );
        }
      })
      .filter((item) => item);
    return result.length ? (
      result
    ) : (
      <h3 className="col-6 my-3 text-secondary">
        <IntlMessages id="tab.noLicences" />
      </h3>
    );
  }

  render() {
    const {
      seeker,
      showSeekerProfile,
      buttonActions,
      userRole,
      listJobAppiled,
      loadingListJobSeeker,
    } = this.props;
    const { collapse } = this.state;
    const hasHistory = !!(seeker.employment_history || []).length;
    return (
      <RctCard
        customClasses={showSeekerProfile ? "mb-2" : "mb-2 none-boxshadow"}
      >
        <div>
          <div
            className="row item-profile"
            onClick={() => this.toggle("training")}
          >
            <strong className="title-collapse">License/ Training</strong>
            <i
              className={
                collapse === "trainging"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "training"}>
            <ul className="list-group list-group-flush list-comments max-height-150 auto-scroll-y only max-height-250">
              <div className="row m-0">{this.renderTabTraining()}</div>
            </ul>
          </Collapse>
        </div>
        <div>
          <div
            className="row item-profile"
            onClick={() => this.toggle("applied")}
          >
            <strong className="title-collapse">Applied</strong>
            <i
              className={
                collapse === "applied"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "applied"}>
            {loadingListJobSeeker ? (
              <div className="tab-circle-wrapper small-width">
                <CircularProgress
                  className="progress-warning"
                  size={40}
                  mode="determinate"
                  value={40}
                  min={0}
                  max={50}
                />
              </div>
            ) : (
              this.renderTabApplied()
            )}
          </Collapse>
        </div>
        <div>
          <div
            className="row item-profile"
            onClick={() => this.toggle("confirmed")}
          >
            <strong className="title-collapse">Confirmed</strong>
            <i
              className={
                collapse === "confirmed"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "confirmed"}>
            {loadingListJobSeeker ? (
              <div className="tab-circle-wrapper small-width">
                <CircularProgress
                  className="progress-warning"
                  size={40}
                  mode="determinate"
                  value={40}
                  min={0}
                  max={50}
                />
              </div>
            ) : (
              this.renderTabConfirmed()
            )}
          </Collapse>
        </div>
        <div>
          <div className="row item-profile" onClick={() => this.toggle("gigs")}>
            <strong className="title-collapse">Gigs</strong>
            <i
              className={
                collapse === "gigs"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "gigs"}>
            {this.renderTabGig(hasHistory)}
          </Collapse>
        </div>
        <div>
          <div
            className="row item-profile"
            onClick={() => this.toggle("experience")}
          >
            <strong className="title-collapse">Experience</strong>
            <i
              className={
                collapse === "experience"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "experience"}>
            {this.renderTabExperience()}
          </Collapse>
        </div>
        <div>
          <div
            className="row item-profile"
            onClick={() => this.toggle("comments")}
          >
            <strong className="title-collapse">Comments</strong>
            <i
              className={
                collapse === "comments"
                  ? "fa fa-angle-up fa-2x icon-collapse"
                  : "fa fa-angle-down fa-2x icon-collapse"
              }
            />
          </div>
          <Collapse isOpen={collapse === "comments"}>
            {this.renderTabComment()}
          </Collapse>
        </div>
        {Number(userRole) === oauth.isOmniStaff() && (
          <div>
            <div
              className="row item-profile"
              onClick={() => this.toggle("notes")}
            >
              <strong className="title-collapse">Notes</strong>
              <i
                className={
                  collapse === "notes"
                    ? "fa fa-angle-up fa-2x icon-collapse"
                    : "fa fa-angle-down fa-2x icon-collapse"
                }
              />
            </div>
            <Collapse isOpen={collapse === "notes"}>
              {this.renderTabNotes()}
            </Collapse>
          </div>
        )}
      </RctCard>
    );
  }
}

export default Seeker;

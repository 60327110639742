import API from 'Api';

export const getEmployersListRequest = async () => await API.get('/omni/employer/list')
    .then(response => response)
    .catch(err => err);

export const getChosenEmployersListRequest = async () => await API.get('/employers/get_list_chosen_employers')
    .then(response => response)
    .catch(err => err);
/**
 * Log in as another employer.
 *
 * @return {!jQuery.deferred}
 * @public
 */
//route used for super admin
export const logInAsRequest = async (params) => await API.post('/omni/employer/log_in_as', params)
    .then(response => response)
    .catch(err => err);

//route used for admin provider
export const logInAsChosenProviderRequest = async (params) => await API.post('/employer/log_in_as_chosen_provider', params)
    .then(response => response)
    .catch(err => err);

export const newEmployerRequest = async (params) => await API.post('/admin/employers', params);

export const updateEmployerRequest = async (params) => await API.put(`/admin/employers/${params.employer_id}`, params);

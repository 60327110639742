/**
 * Charge Rate Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    GET_CHARGERATES,
} from 'Actions/types';

import {
    showErrorResponse,
    getChargeRatesSuccess,
    getChargeRatesFailure
} from 'Actions';

import {
    getChargeRateByPayRate
} from 'Services/employers/WageService';

function* handleGetChargeRate({ payload }) {
    try {
        const response = yield call(getChargeRateByPayRate, payload.payRate);

        yield put(getChargeRatesSuccess(response));

    } catch (error) {
        yield put(getChargeRatesFailure(error));
        yield put(showErrorResponse(error));
    }
}

export function* watchChargeRate() {
    yield takeEvery(GET_CHARGERATES, handleGetChargeRate);
}

export default function* rootSaga() {
    yield all([
        fork(watchChargeRate)
    ]);
}

import API from 'Api';
import MyGig from "Constants/MyGig";

/**
 * Get the assignments for a specific position
 *
 * An array of Assignment model objects is returned by the API.
 *
 * @param  {number} positionId
 * @return async
 */
export const getAssignmentsByPositionRequest = (positionId, page, searchKey) => API.get(`/employers/positions/${positionId}/assignments`, {
    page: page,
    searchKey: searchKey
})
    .then(response => response)
    .catch(error => error);

/**
* check are all the assignments checked in or not
*
* An array of Assignment model objects is returned by the API.
*
* @param  {number} positionId
* @return async
*/
export const checkAreAllAssignmentsCheckedInRequest = async (positionId) => await API.get(`/employers/positions/${positionId}/check-all-employees-is-checkIn`)
    .then(response => response)
    .catch(error => error);

/**
* check are all the assignments checked in or not
*
* An array of Assignment model objects is returned by the API.
*
* @param  {number} positionId
* @return async
*/
export const checkAreAllAssignmentsCheckedOutRequest = async (positionId) => await API.get(`/employers/positions/${positionId}/check-all-employees-is-checkOut`)
    .then(response => response)
    .catch(error => error);

/**
 * Remove staff from Assignment list
 *
 * @param  {Number} assignmentId
 * @return async
 */

export const declineAssignmentsRequest = (positionId, assignmentIds, isSelectAll) => API.put(`/employers/positions/${positionId}/unaccept`, {
    assignment_ids: assignmentIds,
    isSelectAll: isSelectAll
})
    .then(response => response)
    .catch(error => error);

/**
* CheckIn staff from Assignment list
*
* @param  {Number} assignmentId
* @return async
*/
export const checkInAssignmentsRequest = (positionId, assignmentIds, isSelectAll, dateTime) => API.post(`/employers/request-checkin`, {
    assignment_ids: assignmentIds,
    isSelectAll: isSelectAll,
    position_id: positionId,
    date_time_request: dateTime
})
    .then(response => response)
    .catch(error => error);

export const checkOutAssignmentsRequest = (positionId, assignmentIds, isSelectAll, dateTime) => API.post(`/employers/request-checkout`, {
    assignment_ids: assignmentIds,
    isSelectAll: isSelectAll,
    position_id: positionId,
    date_time_request: dateTime
})
    .then(response => response)
    .catch(error => error);
/**
 * Mark an assigned schedule of employee as No Sho
 *
 * @param  {Number} assignedScheduleId
 * @return async
 */
export const noShowAssignmentsRequest = (assignedScheduleId) => API.put(`/employers/assignments/${assignedScheduleId}/handle-no-show`)

export const addAmendingComment = (comment, assignedScheduleId) => API.put(`/employers/assigned/add-amending-comment`, {
    assigned_schedule_id: assignedScheduleId,
    comment: comment,
})
    .then(response => response)
    .catch(error => error);

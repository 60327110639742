import React, { Component, Fragment } from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import connect from "react-redux/es/connect/connect";
import UserDefault from "Assets/img/default-user.png";
import SeekerCollapse from "Components/Seeker/Collapse";
import { RctCardContent } from "Components/RctCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ICONS, getLanguages } from "Helpers/helpers";
import IntlMessages from "Util/IntlMessages";
import StoredData from "Constants/StoredData";
import GigLogo from "Assets/img/gig_large.png";
import OAuth from "OAuth";
import AppConfig from "Constants/AppConfig";
import icons_under18 from "Assets/img/image_under_18.png";

import {
  getEmployeeListJobApply,
  getPostedJobs,
  getFilledJobs,
  getCandidatesByPosition,
  getDeclinedCandidatesByPosition,
  getAssignmentsByPosition,
  getInfoSeekerInPosition,
  addNote,
} from "Actions";
import moment from "moment";

import {
  getFilledJobRequest,
  getPostedJobsRequest,
} from "Services/employers/JobService";

class SeekerInfo extends Component {
  UNSAFE_componentWillMount = () => {
    if (this.props.open && this.props.currentSeekerPopupId)
      this.props.getInfoSeekerInPosition(this.props.currentSeekerPopupId);
  };
  componentDidMount = async () => {
    this.userRole = await StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID);
  };

  UNSAFE_componentWillUpdate = (nextProps) => {
    if (nextProps.open === true && nextProps.open !== this.props.open) {
      this.props.getInfoSeekerInPosition(nextProps.currentSeekerPopupId);
    }
  };

  renderQualifications() {
    const { employee, skillId } = this.props;
    const sameSkill = (employee.employee_skills || []).find(
      (e) => e.skill_id === skillId
    );
    return (sameSkill || {}).qualifications;
  }

  render() {
    const {
      employee,
      currentSeekerPopupId,
      open,
      closeSeekerInfoPopup,
      loadingListJobSeeker,
    } = this.props;
    const languages = employee.languages
      ? getLanguages(employee.languages)
      : [];

    const isUnder18 = moment().diff(employee.date_of_birth, "years", true) < 18;

    const homeOffice = employee.home_office || {};
    const phone = employee.phone;
    const listLicense = [];
    const oauth = new OAuth();
    const adminPanelUrl =
      AppConfig.URL_GIG_ADMIN + "/seeker-profile/" + employee.id;
    if (employee.driving_license != "" && employee.driving_license != "0") {
      listLicense["driving_license"] = 1;
    }
    return (
      <div className="seeker-info-outer-wrapper">
        <SwipeableDrawer
          id="modal-add-right"
          anchor={"right"}
          open={open}
          onOpen={() => false}
          onClose={() => closeSeekerInfoPopup()}
          className="MuiDrawer-modal-seeker-info"
        >
          <div className="drawer-wrapper">
            <div className="seeker-info-inner-wrapper">
              <i
                className="zmdi zmdi-close font-2x pull-right on-hover"
                onClick={() => closeSeekerInfoPopup()}
              />
              {employee.id === currentSeekerPopupId ? (
                <Fragment>
                  <div className="list-summary d-flex">
                    <div className="avatar">
                      {employee.role_id === 1 && (
                        <img
                          src={GigLogo}
                          className=" gig-avatar-icon"
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {employee.profile_thumbnail ? (
                        <img
                          className="pull-left img-profile"
                          style={{
                            borderRadius: "50%",
                            width: "130px",
                            height: "130px",
                          }}
                          src={
                            employee.profile_thumbnail +
                            "?" +
                            new Date().getTime()
                          }
                          alt={employee.user_fullname}
                        />
                      ) : (
                        <img
                          className=" pull-left img-profile"
                          src={UserDefault}
                          alt={employee.user_fullname}
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      )}
                    </div>
                    <div className="content-info w-100">
                      <h1 className="fullname mb-0">
                        {oauth.checkCurrentIsOmniStaff() && (
                          <a
                            href={adminPanelUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {employee.user_fullname}
                          </a>
                        )}
                        {!oauth.checkCurrentIsOmniStaff() &&
                          employee.user_fullname}
                      </h1>
                      <div className="align-items-center list-favourite mt-0 row ">
                        <div>
                          <i className="gig-icon icon-jobs smaller bottom no-pointer" />
                          <span>
                            {employee.total_assignments ||
                              employee.totalAssignments ||
                              0}
                          </span>
                        </div>
                        <div>
                          <i className="gig-icon icon-thumbs-up smaller bottom no-pointer" />
                          <span>
                            {employee.total_positive_reviews ||
                              employee.totalPositiveReviews ||
                              0}
                          </span>
                        </div>
                        {employee.employer_favourite > 0 && (
                          <div>
                            <i className="gig-icon icon-favourite smaller bottom no-pointer" />
                          </div>
                        )}
                        {employee.gig_approved > 0 && (
                          <div>
                            <i className="gig-icon icon-gig-approved smaller bottom no-pointer" />
                          </div>
                        )}
                        {isUnder18 && (
                          <div>
                            <img
                              src={icons_under18}
                              className="gig-icon smaller bottom no-pointer"
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="list-favourite w-40">
                          {homeOffice.name && (
                            <Fragment>
                              <IntlMessages id="words.homeOffice" />

                              <p
                                className="m-0"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                }}
                              >
                                {homeOffice.name}
                              </p>
                            </Fragment>
                          )}
                          {oauth.checkCurrentIsSuper() && phone && (
                            <Fragment>
                              <IntlMessages id="words.phoneNumber" />

                              <p
                                className="m-0"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                }}
                              >
                                {phone}
                              </p>
                            </Fragment>
                          )}
                        </div>
                        {!!languages.length && (
                          <div className="list-favourite w-60">
                            <span>Languages:</span>
                            <div className="d-flex flex-wrap">
                              {languages.map((item) => {
                                return (
                                  <div
                                    style={{
                                      margin: "0 15px 0 0",
                                    }}
                                    key={item.language_name}
                                  >
                                    {ICONS.languageIcons[item.language_name] ? (
                                      <img
                                        className="language-icon"
                                        src={
                                          ICONS.languageIcons[
                                            item.language_name
                                          ]
                                        }
                                      />
                                    ) : (
                                      `${item.language_name} : `
                                    )}
                                    <span
                                      style={{
                                        fontWeight: "700",
                                      }}
                                    >
                                      {item.language_level_text}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="experience-yourself">
                    {employee.experience_yourself}
                  </p>

                  <RctCardContent>
                    <div className="image-padding clearfix">
                      <div className="qualification">
                        {this.renderQualifications()}
                      </div>
                    </div>
                    <div className="job-seeker-history-popover">
                      {
                        <SeekerCollapse
                          seeker={this.props.employee}
                          getEmployeeListJobApply={
                            this.props.getEmployeeListJobApply
                          }
                          addNote={this.props.addNote}
                          loadingListJobSeeker={loadingListJobSeeker}
                          userRole={this.userRole}
                          listJob={this.props.listJob}
                          listJobAppiled={this.props.listJobAppiled}
                          getCandidatesByPosition={
                            this.props.getCandidatesByPosition
                          }
                          getDeclinedCandidatesByPosition={
                            this.props.getDeclinedCandidatesByPosition
                          }
                          getAssignmentsByPosition={
                            this.props.getAssignmentsByPosition
                          }
                        />
                      }
                    </div>
                  </RctCardContent>
                </Fragment>
              ) : (
                <div className="tab-circle-wrapper small-width">
                  <CircularProgress
                    className="progress-warning"
                    size={40}
                    mode="determinate"
                    value={40}
                    min={0}
                    max={50}
                  />
                </div>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

const mapStateToProps = ({ positionReducer, jobApp, seekerReducer }) => {
  const assignments = positionReducer.assignments;
  const listJob =
    jobApp.upcomingJobs.length > 0 ? jobApp.upcomingJobs : jobApp.calendarJobs;
  const listJobAppiled = seekerReducer.listJobApply;
  const employee = seekerReducer.seekerInfo;
  const loadingInfoSeeker = seekerReducer.loadingInfo;
  const loadingListJobSeeker = seekerReducer.loadingListJob;

  return {
    assignments,
    listJob,
    listJobAppiled,
    employee,
    loadingInfoSeeker,
    loadingListJobSeeker,
  };
};

const mapActionToProps = {
  getEmployeeListJobApply,
  getPostedJobs,
  getFilledJobs,
  getCandidatesByPosition,
  getDeclinedCandidatesByPosition,
  getAssignmentsByPosition,
  getInfoSeekerInPosition,
  addNote,
};

export default connect(mapStateToProps, mapActionToProps)(SeekerInfo);

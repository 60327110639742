import React            from 'react';
import {injectIntl}     from 'react-intl';
import {Bar}            from 'react-chartjs-2';
import ChartConfig      from 'Constants/chart-config';
import AppConfig        from 'Constants/AppConfig';
import IntlMessages     from 'Util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment           from 'moment';

const data = {
    labels: [],
    datasets: [
        {
            label               : '',
            type                :'line',
            fill                : false,
            borderColor         : AppConfig.appColors.black,
            backgroundColor     : AppConfig.appColors.black,
            hoverBackgroundColor: AppConfig.appColors.black,
            hoverBorderColor    : AppConfig.appColors.black,
            data                : [],
            yAxisID             : 'y-axis-1'
        },
        {
            label                    : '',
            fill                     : false,
            borderColor              : ChartConfig.color.warning,
            backgroundColor          : ChartConfig.color.warning,
            pointBorderColor         : ChartConfig.color.warning,
            pointBackgroundColor     : ChartConfig.color.warning,
            pointHoverBackgroundColor: ChartConfig.color.warning,
            pointHoverBorderColor    : ChartConfig.color.warning,
            data                     : [],
            yAxisID                  : 'y-axis-2'
        }
    ]
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
        mode: 'label'
    },
    elements: {
        line: {
            fill: false
        }
    },
    scales: {
        yAxes: [
            {
                type    : 'linear',
                display : true,
                position: 'left',
                id      : 'y-axis-1',
                ticks   : {
                    beginAtZero: true
                },
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            },
            {
                type    : 'linear',
                display : true,
                position: 'right',
                id      : 'y-axis-2',
                ticks   : {
                    beginAtZero: true
                },
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            }
        ]
    }
};

class MonthlyChart extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            data,
            options,
            isEmptyData: true
        };
        this.state.data.datasets[0].label = props.intl.formatMessage({id: 'reports.chartMonthly.hours'});
        this.state.data.datasets[1].label = props.intl.formatMessage({id: 'reports.chartMonthly.expenses'});

        this.state.options.tooltips.callbacks = {
            label(tooltipItem, data) {
                const itemIndex = tooltipItem.index;
                const datasetIndex = tooltipItem.datasetIndex;

                if (datasetIndex === 0) {
                    return `${data.datasets[datasetIndex].label  }: ${  props.intl.formatMessage({id: 'common.hoursWithoutSpace'}, {
                        hours: data.datasets[datasetIndex].data[itemIndex]
                    })}`;
                }

                return `${data.datasets[datasetIndex].label  }: ${  props.intl.formatHTMLMessage({id: 'common.moneyGBP'}, {
                    money: data.datasets[datasetIndex].data[itemIndex]
                })}`;
            }
        };
    }

    UNSAFE_componentWillMount() {
        this.updateChartData(this.props.monthlyStatistics);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateChartData(nextProps.monthlyStatistics);
    }

    updateChartData(monthlyStatistics) {
        const dataSetsHours    = [];
        const dataSetsRevenues = [];
        const listLabels       = [];
        let isEmptyData      = true;

        if (Object.keys(monthlyStatistics).length > 0) {
            monthlyStatistics.unixMonths.map((unixMonths, index) => {
                const hour    = Math.round(monthlyStatistics.hours[index] / 3600);
                const revenue = Number(monthlyStatistics.revenues[index]);

                dataSetsHours.push(hour);
                dataSetsRevenues.push(revenue);
                listLabels.push(moment.unix(unixMonths).format('MMM'));

                if (hour > 0 || revenue > 0) {
                    isEmptyData = false;
                }

                return true;
            });
        }

        const {data} = this.state;
        data.labels = listLabels;
        data.datasets[0].data = dataSetsHours;
        data.datasets[1].data = dataSetsRevenues;

        this.setState({data, isEmptyData});
    }

    render() {
        const { isEmptyData, data, options } = this.state;
        const { loading } = this.props;
        return (
            <div className="monthly-chart-spinner">
                {loading === true && (
                    <div className="wrapper">
                        <CircularProgress className="progress-warning" size={70} mode="determinate" value={40} min={0} max={50} />
                    </div>
                )}

                {loading === false && isEmptyData === false && (
                    <Bar data={data} options={options} height={235} />
                )}

                {loading === false && isEmptyData === true && (
                    <div className="">
                        <p className="empty-data-report">
                            <IntlMessages id="reports.empty.monthlyReports" />
                        </p>
                    </div>
                )}
            </div>
        );
    }
};

export default injectIntl(MonthlyChart);

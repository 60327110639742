import API from 'Api';

/**
 * Favourite employee by skills
 *
 * @param {Integer}  employeeID
 * @param {Array}    skillIDs
 * @return async
 */
export const favouriteEmployeeRequest = async (employeeID, skillIDs) => {
  const params = {
    employee_id: employeeID,
    skill_id: skillIDs
  };

  return await API.post('/employers/favourites', params)
    .then(response => response)
    .catch(error => error);
};

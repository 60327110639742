export default {
    AUTH: {
        SIGN_IN: '/signin',
        SIGN_UP: '/signup'
    },
    CALENDAR: {
        WEEKLY: '/app/calendar/weekly',
        MONTHLY: '/app/calendar/monthly'
    },
    DASHBOARD: {
        UPCOMING_JOB: '/app/calendar/weekly',
        CALENDAR: '/app/dashboard/calendar-job'
    },
    TIMESHEET: {
        PENDING_WEEKLY: '/app/timesheets/pending/weekly',
        PENDING_DAILY : '/app/timesheets/pending/daily',
        PENDING_INTERNAL: '/app/timesheets/pending/internal'
    },
    MY_GIGS: {
        UPCOMING : '/app/my-gigs/upcoming',
        POSTED   : '/app/my-gigs/posted',
        FILLED   : '/app/my-gigs/filled',
        PENDING  : '/app/my-gigs/pending',
        CALENDAR_POSTED   : '/app/my-gigs/calendar-posted',
        CALENDAR_FILLED   : '/app/my-gigs/calendar-filled',
        CALENDAR_PENDING  : '/app/my-gigs/calendar-pending',
        COMPLETED: '/app/my-gigs/completed'
    },
    LOCATIONS: '/app/locations',
    BILLING: {
        INVOICING: '/app/billing/invoicing'
    },
    byPositionId: (path, positionId) => `${path}/${positionId}`
};

import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import DownloadIcon from '../../../assets/img/gig-icons/DownloadIcon.png';

// Actions
// import {
//     excelDownloadReports,
// } from 'Actions/ReportActions';
// import {
//     showRootSpinner
// } from 'Actions';
import {
    excelDownloadReports,
    excelDownloadFulfilmentReports,
    excelDownloadBillingReports,
    excelDownloadAWRReports,
    showRootSpinner
} from 'Actions';
import moment from 'moment';

class DownloadExcelReport extends Component {
    downloadReportOnType = (start, end, locationId) =>{
        const {reportType} = this.props
        switch(reportType) {
            case 'fulfilment':
                this.props.excelDownloadFulfilmentReports(start,end);
                break;
            case 'billing':
                this.props.excelDownloadBillingReports(start,end)
                break;
            case 'timecard':
                this.props.excelDownloadReports(start,end, locationId)
                break;
            case 'awr':
                this.props.excelDownloadAWRReports()
                break;
        }
    }

    render() {
        const {
            disabled,
            dateRange,
            locationId,
        } = this.props;
        let start = this.props.start ? this.props.start : moment() 
        let end = this.props.end ? this.props.end : moment() 
        return (
            <Fragment>
                <button
                 
                    className='print-icon-container'
                    onClick={() => {
                       this.props.showRootSpinner();

                        this.downloadReportOnType(
                            start.format('YYYY-MM-DD'),
                            end.format('YYYY-MM-DD'),
                            locationId
                        );
                    }}
                    disabled={disabled}
                >
                    <img style={{verticalAlign: 'baseline', height: 30, width: 'auto'}} className="gig-icon" src={DownloadIcon}></img>
                </button>
            </Fragment>
        );
    }
};

const mapStateToProps = ({ pdfReducer }) => ({ ...pdfReducer });

const mapActionToProps = {
    excelDownloadReports,
    excelDownloadFulfilmentReports,
    excelDownloadBillingReports,
    excelDownloadAWRReports,
    showRootSpinner
};


export default connect(null, mapActionToProps)(DownloadExcelReport);

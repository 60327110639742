import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import className from 'classnames';
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Collapse from '@material-ui/core/Collapse';
import Spinner from "Components/Model/Spinner";
import MyGig from 'Constants/MyGig';
import IntlMessages from "Util/IntlMessages";
import { injectIntl } from 'react-intl';
import { roundByDecimals, validNumberOrZero } from 'Helpers/helpers';
import ConfirmPassword from 'Components/SweetAlert/ConfirmPassword';
import { FormGroup, Input } from "reactstrap";
import ConfirmPasswordHelper from "Helpers/confirmpassword";

import {
    setFieldSkillForm,
    setErrorToSkill,
    sendSkillsChargeRate,
    closeAllAlert,
    handleChangeMasterRate,
    updateMasterRate,
    getAllSkillsByGroupSuccess,
    showConfirmPasswordForm,
    hideConfirmPasswordForm,
    acceptRemoteRequest,

} from 'Actions';
import { map } from "jquery";

class ChargeRateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMasterRate: null,
            openListSkill: false,
            currentSkillTypeId: null,
            pension_and_levy: null,
            other_cost: null,
            is_fixed_type: null,
            margin_fixed: null,
            margin_percent: null,

        };
    }

    onCloseModal = () => {
        this.props.getAllSkillsByGroupSuccess([]); // clear old data when close modal
        this.props.onClose();
        this.setState({
            errorMasterRate: null,
            openListSkill: false,
            currentSkillTypeId: null,
        });
    }

    onChangeField = (typeId, skillId, rate, value) => {
        if (value < 0) return false;
        this.props.setFieldSkillForm(typeId, skillId, rate, value);
    }

    onSaveSkill = (typeId) => {
        const { skillGroup, intl } = this.props;
        const currentType = skillGroup.find(element => element.type_id === typeId);
        const { skills } = currentType;

        for (const skill of skills) {
            const error = this.checkEmpty(skill);
            skill.error = error;

            if (error && typeof error === 'object') {
                if (error.key === 'chargeRate.minimumPayrate') {
                    skill.errorPayRate = true;
                }
            } else {
                if (error === "chargeRate.emptyChargeRate") skill.errorChargeRate = true;
                if (error === "chargeRate.emptyPayRate") skill.errorPayRate = true;
                if (error === "chargeRate.chargeRateSmallerPayRate") {
                    skill.errorChargeRate = true;
                    skill.errorPayRate = true;
                }
            }

            /* No error */
            if (!error) {
                skill.errorChargeRate = null;
                skill.errorPayRate = null;
            }
        }

        this.props.setErrorToSkill(currentType);

        if (this.checkValidate(skills)) {
            return this.props.sendSkillsChargeRate(this.formatChargeRates(skills),typeId);
        }
        const textError = intl.formatMessage({ id: 'chargeRate.errorType' });
        alert(textError);
        return false;


    }

    roundPercent = (percent) => {
        return (percent * 100).toFixed(2) + '%';
    }
    handleCheckRadio(value) {
        // const {  } = this.props.masterRate
        // is_fixed_type = value
        this.setState({ is_fixed_type: value });
    }


    formatChargeRates = (skills) => {
        const chargeRates = [];
        skills.forEach(skill => {
            chargeRates.push({
                id: skill.id,
                skill_id: skill.skill_id,
                charge_rate: skill.charge_rate,
                pay_rate: skill.pay_rate
            });
        });

        return chargeRates;
    }

    checkValidate = (skills) => {
        for (const skill of skills) {
            if (skill.error !== null) {
                return false;
            }
        }

        return true;
    }

    checkEmpty = (skill) => {
        if (skill.pay_rate && !skill.charge_rate) {
            return 'chargeRate.emptyChargeRate';
        }

        if (skill.charge_rate && !skill.pay_rate) {
            return 'chargeRate.emptyPayRate';
        }

        if (skill.pay_rate &&
            skill.charge_rate &&
            Number(skill.charge_rate) <= Number(skill.pay_rate)
        ) {
            return 'chargeRate.chargeRateSmallerPayRate';
        }

        if (
          skill.pay_rate &&
          validNumberOrZero(skill.pay_rate) < MyGig.payrate.MINIMUM_LIMIT
        ) {
          return {
            key: "chargeRate.minimumPayrate",
            params: {
              min: MyGig.payrate.MINIMUM_LIMIT,
            },
          };
        }

        return null;
    }

    toggleSkill = (currentSkillTypeId) => {
        if (currentSkillTypeId === this.state.currentSkillTypeId) {
            this.setState({
                currentSkillTypeId: null,
                openListSkill: false
            });
        }
        else {
            this.setState({
                currentSkillTypeId,
                openListSkill: true
            });
        }
    }
    onChangeMasterRate = (index, value) => {
        this.props.handleChangeMasterRate(index, value);
    }
    onChangePensionAndLevy = (value) => {
        const result = value.replace('%', '')
        this.setState({ pension_and_levy: result / 100 });
    }
    onChangeOtherCost = (value) => {
        const result = value.replace('£', '')
        this.setState({ other_cost: result });
    }
    onChangeMarginFixed = (value) => {
        const result = value.replace('£', '')
        this.setState({ margin_fixed: result });
    }
    onChangeMarginPercent = (value) => {
        const result = value.replace('%', '')
        this.setState({ margin_percent: result / 100 });
    }

    onUpdateMasterRate = () => {
        const { updateMasterRate } = this.props;
        const { other_cost, pension_and_levy, is_fixed_type, margin_percent, margin_fixed } = this.state
        var dataUpdate =
        {
            other_cost: other_cost,
            pension_and_levy: pension_and_levy,
            is_margin_fixed: is_fixed_type,
            margin_percent: margin_percent,
            margin_fixed: margin_fixed
        }
        for (const e in dataUpdate) {
            if (dataUpdate[e] == null) {
                if(e == 'is_margin_fixed') {
                    dataUpdate[e] = this.props.masterRate.margin_pay.is_fixed_type
                }else if(e == 'margin_percent') {
                    dataUpdate[e] = this.props.masterRate.margin_pay.margin_percent
                }else if(e == 'margin_fixed') {
                    dataUpdate[e] = this.props.masterRate.margin_pay.margin_cost_fix
                }else{
                    dataUpdate[e] = this.props.masterRate[e];
                }
            }
        }
        if (dataUpdate.pension_and_levy <= 0 || isNaN(dataUpdate.pension_and_levy)) {
            return this.setState({ errorMasterRate: "chargeRateModal.pensionLevyValidationFail" });
        }
        if (isNaN(dataUpdate.other_cost)) {
            return this.setState({ errorMasterRate: "chargeRateModal.otherCostValidationFail" });
        }
        if (dataUpdate.pension_and_levy == this.props.masterRate.pension_and_levy) {
            this.setState({ errorMasterRate: '' })
            return updateMasterRate(dataUpdate)
        }
        const callBackUpdate = () => {
            this.props.hideConfirmPasswordForm()
            this.setState({ errorMasterRate: '' })
            updateMasterRate(dataUpdate)
        }
        this.props.showConfirmPasswordForm(callBackUpdate)
    }

    renderButtonModal = (openThis) => {
        let icon = "add_circle";
        if (openThis) icon = "remove_circle";
        return (
            <i className="material-icons drop-btn" style={{ "color": "black" }}>{icon}</i>
        );
    }

    renderListSkill(skill, typeId) {
        return (
          <Fragment key={skill.skill_id}>
            <tr>
              <td>{skill.skill_name}</td>
              <td>
                <FormGroup>
                  <Input
                    invalid={skill.errorPayRate}
                    type="number"
                    min={MyGig.payrate.MINIMUM_LIMIT}
                    max="99.99"
                    placeholder="00.00"
                    value={skill.pay_rate !== null ? skill.pay_rate : ""}
                    onChange={(event) =>
                      this.onChangeField(
                        typeId,
                        skill.skill_id,
                        "pay_rate",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <Input
                    invalid={skill.errorChargeRate}
                    type="number"
                    min={MyGig.payrate.MINIMUM_LIMIT}
                    max="99.99"
                    placeholder="00.00"
                    value={skill.charge_rate !== null ? skill.charge_rate : ""}
                    onChange={(event) =>
                      this.onChangeField(
                        typeId,
                        skill.skill_id,
                        "charge_rate",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </td>
            </tr>
            {!!skill.error && (
              <tr>
                <td colSpan="3" className="error">
                  {typeof skill.error === "object" ? (
                    <IntlMessages
                      id={skill.error.key}
                      values={skill.error.params}
                    />
                  ) : (
                    <IntlMessages id={skill.error} />
                  )}
                </td>
              </tr>
            )}
          </Fragment>
        );
    }

    renderTablePosition = (typeId, skills, isLeft) => (
        <table className={className('table', 'custom-table', { left: isLeft })}>
            {
                !!skills.length && (
                    <Fragment>
                        <thead>
                            <tr>
                                <th scope="col" />
                                <th scope="col" className="smaller-font">
                                    <IntlMessages id="createGig.payRate" />
                                </th>
                                <th scope="col" className="smaller-font">
                                    {/* <IntlMessages id="createGig.chargeRate" /> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {skills.map(skill => this.renderListSkill(skill, typeId))}
                        </tbody>
                    </Fragment>
                )
            }
        </table>
    )

    renderSkillParent = (type, index) => {
        const { loading } = this.props;
        const { openListSkill, currentSkillTypeId } = this.state;
        const openThis = openListSkill && (type.type_id === currentSkillTypeId);

        /**
         * Split array skill into 2 array
         */
        const { skills = [] } = type;
        const leftSkills = [];
        const rightSkills = [];
        let i;
        const lengthSkills = skills.length;
        const sizeOfSkills = Math.ceil(lengthSkills / 2);

        for (i = 0; i < sizeOfSkills; i++) {
            leftSkills.push(skills[i]);
        }

        for (i = sizeOfSkills; i < lengthSkills; i++) {
            rightSkills.push(skills[i]);
        }
        return (
            <div key={index} className={className('row', 'parent-dropdown', { first: index === 0 })}>
                <div
                    onClick={() => this.toggleSkill(type.type_id)}
                    className="col-sm-12 col-md-12 child-dropdown dropdown-action"
                >
                    <div className="drop-title big-font" style={{ color: 'black' }}>
                        {type.type_name}
                    </div>
                    {this.renderButtonModal(openThis)}
                </div>
                <Collapse in={openThis} style={{ width: '100%' }}>
                    <Fragment>
                        <div className="col-sm-12 col-md-12 child-dropdown d-flex flex-row">
                            <div className="col-sm-6 col-md-6 child-dropdown">
                                {this.renderTablePosition(type.type_id, leftSkills, true)}
                            </div>
                            <div className="col-sm-6 col-md-6 child-dropdown">
                                {this.renderTablePosition(type.type_id, rightSkills)}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 child-dropdown btn-save">
                            <Button
                                className="gig-button font-bolder font-thinner"
                                size="small"
                                disabled={loading}
                                onClick={() => this.onSaveSkill(type.type_id)}
                                style={{
                                    backgroundColor: "black",
                                    borderRadius: "5px"
                                }}
                            >
                                <IntlMessages id="button.save" />
                            </Button>
                        </div>
                    </Fragment>
                </Collapse>
            </div>
        );
    }

    renderMasterRate() {
        const { errorMasterRate } = this.state;
        const { masterRate } = this.props
        const holiday_pay = masterRate.holiday_pay
        const pension_and_levy = masterRate.pension_and_levy
        const margin_pay = masterRate.margin_pay
        const other_cost = masterRate.other_cost
        const employersNi = masterRate.employersNi


        return (
            <div className="row">
                <div className="col-sm-6 col-md-12 col-xl-12">
                    <FormGroup>

                        <div className="master-rate col-12 col-lg-5">
                            <div className="big-font charge-rate-title">
                                <IntlMessages id="chargeRateModal.holidayPay" />:
                            </div>
                            <Input
                                type="text"
                                value={this.roundPercent(holiday_pay)}
                                disabled={true}
                            />
                        </div>
                        <div className="master-rate col-12 col-lg-5">
                            <div className="font-normal big-font charge-rate-title">
                                <IntlMessages id="chargeRateModal.pensionAndApprentice" />:
                            </div>
                            <label className="append-percent" >
                                <Input
                                    type="text"
                                    Value={this.roundPercent(pension_and_levy)}
                                    style={{ color: 'red' }}
                                    onChange={(event) => this.onChangePensionAndLevy(event.target.value)}
                                />
                            </label>

                        </div>
                        <div className="master-rate col-12 col-lg-5">
                            <div className="font-normal big-font charge-rate-title">
                                <IntlMessages id="chargeRateModal.employerNi" />:
                            </div>
                            <Input
                                type="text"
                                Value={this.roundPercent(employersNi)}
                                disabled={true}
                            />
                        </div>
                        <div className="section-margin flex-column flex-lg-row">
                            <div className="master-rate col-12 col-lg-6 px-0">
                            <div className="col-10 d-flex flex-row justify-content-between align-items-center">
                                <div className="font-normal big-font charge-rate-title">
                                    <IntlMessages id="chargeRateModal.marginFixed" />:
                                </div>
                                <Input
                                    type="text"
                                    Value={'£' + margin_pay.margin_cost_fix}
                                    onChange={(event) => this.onChangeMarginFixed(event.target.value)}
                                />
                            </div>
                            <div className="inputRadio col-2 p-1">
                                <label>
                                <Input
                                    type="radio"
                                    name="margin"
                                    value={1}
                                    defaultChecked={margin_pay.is_fixed_type}
                                    onClick={(event) => this.handleCheckRadio(event.target.value)}
                                />
                                </label>
                            </div>
                            </div>
                            <div className="master-rate col-12 col-lg-6 px-0">
                                <div className="col-10 d-flex flex-row justify-content-between align-items-center">
                                    <div className="font-normal big-font charge-rate-title-percent text-start text-lg-center">
                                        <IntlMessages id="chargeRateModal.marginPercent" />:
                                    </div>
                                    <Input
                                        type="text"
                                        Value={this.roundPercent(margin_pay.margin_percent)}
                                        onChange={(event) => this.onChangeMarginPercent(event.target.value)}
                                    />

                                </div>
                                <div className="inputRadio col-2 p-1">
                                    <label>
                                        <Input
                                            name="margin"
                                            type="radio"
                                            value={0}
                                            defaultChecked={!margin_pay.is_fixed_type}
                                            onClick={(event) => this.handleCheckRadio(event.target.value)}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="master-rate col-12 col-lg-5">
                            <div className="font-normal big-font charge-rate-title">
                                <IntlMessages id="chargeRateModal.otherCosts" />:
                            </div>
                            <Input
                                type="text"
                                Value={'£' + other_cost.toFixed(2)}
                                onChange={(event) => this.onChangeOtherCost(event.target.value)}
                                style={{ color: 'red' }}
                            />
                        </div>
                        <div className="master-rate">
                            <Button
                                className="gig-button font-bolder font-thinner charge-button"
                                size="small"
                                onClick={this.onUpdateMasterRate}
                                style={{
                                    backgroundColor: "black",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    position: "absolute",
                                    right: '12px'
                                }}
                            >
                                <IntlMessages id="button.update" />
                            </Button>
                        </div>
                        {errorMasterRate && (
                            <div style={{ color: 'red' }}>
                                <IntlMessages id={errorMasterRate} />
                            </div>
                        )}
                    </FormGroup>
                </div>
            </div>
        );
    }

    render() {
        const { isOpen, loading, skillGroup, showSuccess, showError, showErrorMasterRate, showSuccessMasterRate, closeAllAlert } = this.props;

        return (
            <div
                className="charge-rate-gig-outer-wrapper"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <SwipeableDrawer
                    anchor={"right"}
                    open={isOpen}
                    onOpen={() => false}
                    onClose={this.onCloseModal}
                    className="MuiDrawer-paper-charge-rate"
                >
                    <div className="drawer-wrapper">
                        <Spinner
                            spinnerWidth={70}
                            show={loading}
                        />

                        <div className="charge-rate-post-gig-wrapper">
                            <h1 className="post-gig-title black font-thinner bigger-font" style={{ fontWeight: "700" }}>
                                <IntlMessages id="chargeRateModal.title" />
                                <i
                                    className="zmdi zmdi-close font-2x pull-right on-hover"
                                    onClick={this.onCloseModal}
                                />
                            </h1>

                            {skillGroup.map((type, index) => {
                                if (type.skills.length > 0) {
                                    return this.renderSkillParent(type, index);
                                } return (<Fragment key={index} />);
                            })}
                        </div>
                    </div>
                    <ConfirmPassword />
                </SwipeableDrawer>
                <SweetAlert
                    success
                    title=""
                    show={showSuccess}
                    onConfirm={closeAllAlert}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="chargeRate.updateSuccess" />
                </SweetAlert>

                <SweetAlert
                    danger
                    title=""
                    show={showError}
                    onConfirm={closeAllAlert}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="chargeRate.updateFail" />
                </SweetAlert>


                <SweetAlert
                    success
                    title=""
                    show={showSuccessMasterRate}
                    onConfirm={closeAllAlert}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="chargeRateModal.updateMasterRateSuccess" />
                </SweetAlert>

                <SweetAlert
                    danger
                    title=""
                    show={showErrorMasterRate}
                    onConfirm={closeAllAlert}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="chargeRateModal.updateMasterRateFail" />
                </SweetAlert>

            </div>
        );
    }
}

const mapStateToProps = ({ skillReducer }) => ({
    loading: skillReducer.loading,
    skillGroup: skillReducer.skillGroup,
    showSuccess: skillReducer.showSuccess,
    showError: skillReducer.showError,
    showErrorMasterRate: skillReducer.showErrorMasterRate,
    showSuccessMasterRate: skillReducer.showSuccessMasterRate,
    masterRate: skillReducer.masterRate,
});

const mapActionToProps = {
    setFieldSkillForm,
    setErrorToSkill,
    sendSkillsChargeRate,
    closeAllAlert,
    handleChangeMasterRate,
    updateMasterRate,
    getAllSkillsByGroupSuccess,
    showConfirmPasswordForm,
    hideConfirmPasswordForm,
    acceptRemoteRequest
};

export default injectIntl(connect(mapStateToProps, mapActionToProps)(ChargeRateModal));

import React, { Component } from "react";

import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { connect } from "react-redux";
import StoredData from "Constants/StoredData";
import UserDefault from "Assets/img/default-user.png";

class MessageBlock extends Component {
    render() {
        const {
            messageObject,
            internalName,
            seekerName,
            seekerThumbnail,
        } = this.props;

        if (
            StoredData.get(StoredData.KEYS.EMPLOYER_STAFF_ID) ==
            messageObject.sender_id
        ) {
            return (
                <div className="d-flex flex-nowrap">
                    <Tooltip title={internalName} placement="bottom">
                        <Avatar
                            alt="user profile"
                            src={UserDefault}
                            className="img-fluid rounded-circle mr-15 align-self-center"
                        />
                    </Tooltip>

                    <div className="chat-bubble even">
                        <span className="text-right">
                            {moment.unix(messageObject.send_at).format("H:mm")}
                        </span>
                        <p className="mb-0 message-text">
                            {messageObject.message}
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex flex-nowrap flex-row-reverse">
                <Tooltip title={seekerName} placement="bottom">
                    <Avatar
                        alt="user profile"
                        src={seekerThumbnail}
                        className="img-fluid rounded-circle ml-15 align-self-center"
                    />
                </Tooltip>
                <div className="chat-bubble odd">
                    <span className="text-left">
                        {moment.unix(messageObject.send_at).format("H:mm")}
                    </span>
                    <p className="mb-0 message-text">{messageObject.message}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ conversationReducer, directMessageReducer }) => {
    const { conversationId } = directMessageReducer;
    const { conversations } = conversationReducer;
    const conversation = conversations.filter(
        (conversation) => conversation.id === conversationId
    )[0];
    return {
        internalName: conversation.name,
        seekerName: conversation.seeker_name,
        seekerThumbnail: conversation.profile_thumbnail,
    };
};

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(MessageBlock);

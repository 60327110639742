import API from 'Api';

/**
 * Get the seeker signal
 *
 * An array of employee model objects is returned by the API.
 *
 * @param page default = 1
 * @param skillId skillId default = "", null, 0
 * @return async
 */
export const getSeekerSignalRequest = ({ page, skillId }) => API.get(`/employers/getSignalledEmployees`,
    {
        page,
        skill_id: skillId
    }).then(response => response)
    .catch(err => err);

/**
 * Wage Actions
 */
import {
    GET_CHARGERATE_BY_PAYRATE,
    GET_CHARGERATE_BY_PAYRATE_SUCCESS,
    GET_CHARGERATE_BY_PAYRATE_FAILURE,
    CHANGE_CHARGERATE
} from './types';


/**
 * Action Get Charge rate by Pay rate
 */
export const getChargeRateByPayRate = (payRate) => ({
    type: GET_CHARGERATE_BY_PAYRATE,
    payload: { payRate }
});

export const changeChargeRate = (chargeRate) => ({
    type: CHANGE_CHARGERATE,
    payload: {chargeRate}
})

/**
 * Action Get Charge rate by Pay rate Success
 */
export const getChargeRateByPayRateSuccess = (response) => ({
    type: GET_CHARGERATE_BY_PAYRATE_SUCCESS,
    payload: response.data
});

/**
 * Action Get Charge rate by Pay rate Failure
 */
export const getChargeRateByPayRateFailure = (error) => ({
    type: GET_CHARGERATE_BY_PAYRATE_FAILURE,
    payload: error
});
/**
 * Login Detail Setting Reducer
 */
import {
    OPEN_LOGIN_DETAIL_SETTING_POPUP,
    CLOSE_LOGIN_DETAIL_SETTING_POPUP,
    GET_LOGIN_DETAIL_SETTING,
    GET_LOGIN_DETAIL_SETTING_SUCCESS,
    UPDATE_OBJECT_LOGIN_DETAIL_SETTING,
    UPDATE_LOGIN_DETAIL_SETTING,
    UPDATE_LOGIN_DETAIL_SETTING_SUCCESS,
    UPDATE_LOGIN_DETAIL_SETTING_FAIL,
    CONFIRM_ERROR_SETTING_POPUP,
    CONFIRM_SUCCESS_SETTING_POPUP,
    UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS,
    LOAD_INIT_DATA_LOGIN_DETAIL_DONE

} from 'Actions/types';


const INIT_STATE = {
    openPopUp: false,
    loading: false,
    data:  {
        id: "",
        fullname: "",
        email: "",
        currentPassword: "",
        confirmPassword: "",
        newPassword:    ""
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CLOSE_LOGIN_DETAIL_SETTING_POPUP:
            return {...state, openPopUp: false };

        case OPEN_LOGIN_DETAIL_SETTING_POPUP:
            return {...state, openPopUp: true, updated: false , error: false };

        case GET_LOGIN_DETAIL_SETTING:
            return {...state, loading: true, updated: false , error: false };

        case GET_LOGIN_DETAIL_SETTING_SUCCESS:
            return {...state, data: action.payload.data, loading: false, fetchData: true };

        case UPDATE_OBJECT_LOGIN_DETAIL_SETTING:
            return {...state, data: action.data };

        case UPDATE_LOGIN_DETAIL_SETTING:
            return {...state, loading: true };

        case UPDATE_LOGIN_DETAIL_SETTING_SUCCESS:
            return {...state, response: action.payload.data, loading: false, updated: true, message: "loginDetail.update.success" };

        case UPDATE_LOGIN_DETAIL_SETTING_FAIL:
            return {...state, response: action.payload.response.data, loading: false, error: true };

        case CONFIRM_ERROR_SETTING_POPUP:
            return {...state, error: false };

        case CONFIRM_SUCCESS_SETTING_POPUP:
            return {...state, updated: false, openPopUp: false };

        case UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS:
            return {...state, response: action.payload.data, loading: false, updated: true, message: "loginDetail.sendMail.success" };

        case LOAD_INIT_DATA_LOGIN_DETAIL_DONE:
            return {...state, fetchData: false };
            
        default: return { ...state };

    }
};

import React, { Component, Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { roundByDecimals } from 'Helpers/helpers';
import IntlMessages from 'Util/IntlMessages';
import DownloadExcelReport from 'Routes/reports/partials/DownloadExcelReport';

class BillingReport extends Component {
    renderRows() {
        const { billingStatistics } = this.props;
        const rows = [];
        
        if (Object.keys(billingStatistics).length > 0) {
            billingStatistics.unixWeeks.map((unixWeek, index) => {
                const hour = roundByDecimals(billingStatistics.totalHours[index], 2);
                const revenue = Number(billingStatistics.totalCharge[index]).toFixed(2);
                const weekStart = (billingStatistics.isWincanTon) ? moment(unixWeek) :  moment.unix(unixWeek).startOf('isoWeek');
                const weekEnd = (billingStatistics.isWincanTon) ? moment(unixWeek).add(6,'days') :  moment.unix(unixWeek).endOf('isoWeek') ;
                const weekPickerRange = {};
                const locationId = null;
                if (hour > 0 || revenue > 0) {
                    rows.push(
                        <TableRow hover key={`weekly-report-row-${index}`}>
                            <TableCell className="flex-group">
                                <span className="week-range">
                                    {weekStart.format('DD MMM')} - {weekEnd.format('DD MMM')}
                                </span>

                                <span className="hours">
                                    <IntlMessages id="common.hoursWithoutSpace" values={{ hours: hour }} />
                                </span>
                            </TableCell>

                            <TableCell className="revenue">
                                <IntlMessages id="common.GBP" />
                                {revenue}
                            </TableCell>
                            <TableCell className=''>
                                <DownloadExcelReport
                                    start={weekStart}
                                    end={weekEnd}
                                    locationId={locationId}
                                    reportType={'billing'}
                                />
                            </TableCell>
                        </TableRow>
                    );
                }

                return true;
            });
        }

        if (rows.length === 0 && this.props.loading === false) {
            return (
                <div className="">
                    <p className="empty-data-report"><IntlMessages id="reports.empty.weeklyReports" /></p>
                </div>
            );
        }

        if (this.props.loading === true) {
            return (
                <div className="wrapper">
                    <CircularProgress className="progress-warning" size={70} mode="determinate" value={40} min={0} max={50} />
                </div>
            );
        }

        return (
            <Table>
                <TableHead className="report-table" style={{backgroundColor: '#fff'}}>
                    <Fragment>
                        <TableRow>
                            <TableCell className="flex-group">
                                <span className="week-range">
                                    Week
                                </span>

                                <span className="hours">
                                    Total Hours
                                </span>
                            </TableCell>
                            <TableCell className="revenue">NET Charge</TableCell>
                            <TableCell className=""></TableCell>
                        </TableRow>
                    </Fragment>
                </TableHead>
                <TableBody className="report-table">
                    <Fragment>
                        {rows}
                    </Fragment>
                </TableBody>
            </Table>
        );
    }

    render() {
        return (
            <div className="report-table-container weekly-report-wrapper">
                {this.renderRows()}
            </div>
        );
    }
}

export default BillingReport;

import API from 'Api';

/**
 * Get list of vacancy locations from API
 *
 * @return async
 */
export const getVacancyLocationsRequest = async () =>
    await API.get('/employers/getVacancyLocations')
        .then(response => response)
        .catch(error => error);
